import AddIcon from "@mui/icons-material/Add"
import { FormattedMessage } from "react-intl"

import { IArgumentType } from "api/types/argumentTypes"

import Button from "ds/Button"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentTypeCreateMutation } from "components/App/Playbook/queries/argumentTypeQueries"

interface Props {
  onAddArgumentType: (argumentType: IArgumentType) => void
}

export default function AddArgumentType({ onAddArgumentType }: Props) {
  const { playbook } = usePlaybook()
  const createMutation = useArgumentTypeCreateMutation(playbook.id)

  const onClick = () =>
    createMutation.mutate(playbook.id, {
      onSuccess: ({ argumentType }) => onAddArgumentType(argumentType),
    })

  return (
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      onClick={onClick}
      disabled={createMutation.isLoading}
    >
      <FormattedMessage
        id="playbookEdit.argumentTypes.add"
        defaultMessage="Add tab"
      />
    </Button>
  )
}
