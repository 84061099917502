// Use the `useSearchParam` hook to synchronize a state with a query param.
// Initially, the state will be set to the query param, if present.
// It also returns a setter, that will update the URL with the new query param/
// Note: Qs is used to serialize the query param.
// Note: `useSearchParam` accepts a generic type that constrains the type of the argument of the setter.
// Note: It is the responsibility of the caller of `useSearchParam` to validate the type of the param returned by the hook.
import omit from "lodash/omit"
import Qs from "qs"
import {
  Navigate,
  Path,
  Search,
  useLocation,
  useSearchParams,
} from "react-router-dom"

export function parseSearch(search: Search): Qs.ParsedQs {
  return Qs.parse(search, { ignoreQueryPrefix: true })
}

function stringifyParams(params: Qs.ParsedQs["param"]): string {
  return Qs.stringify(params, { addQueryPrefix: true, arrayFormat: "brackets" })
}

// location [Object] of react-router
// key [String] the search param to replace
// value [String] to set for the given key
// returns [Object] location with the new search
export function replaceSearchParam({
  location,
  key,
  value,
}: {
  location: Partial<Path>
  key: string
  value: Qs.ParsedQs["param"]
}): Partial<Path> {
  const { search } = location
  const params = parseSearch(search || "")
  const newParams = { ...params, [key]: value }
  const newSearch = stringifyParams(newParams)
  return { ...location, search: newSearch }
}

export default function useSearchParam<
  T extends Qs.ParsedQs["param"] = Qs.ParsedQs["param"]
>(
  key: string
): [
  Qs.ParsedQs["param"],
  (value: T | undefined) => void,
  React.ComponentType<{ value: T | undefined; replace?: boolean }>
] {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const params = parseSearch(searchParams.toString())
  const param = params[key]

  function setParam(value: T | undefined): void {
    const newParams =
      value === undefined ? omit(params, [key]) : { ...params, [key]: value }
    const newSearch = stringifyParams(newParams)
    setSearchParams(new URLSearchParams(newSearch))
  }

  function NavigateToParam({
    value,
    replace,
  }: {
    value: T | undefined
    replace?: boolean
  }) {
    return (
      <Navigate
        to={replaceSearchParam({ location, key, value })}
        replace={replace}
      />
    )
  }

  return [param, setParam, NavigateToParam]
}
