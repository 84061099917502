import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded"
import ListItemButton from "@mui/material/ListItemButton"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

interface Props {
  onClick: React.MouseEventHandler<HTMLDivElement>
  icon?: React.ElementType
  title: React.ReactNode
  selected?: boolean
}

export default function NavEntry({
  onClick,
  icon: Icon,
  title,
  selected = false,
}: Props) {
  return (
    <ListItemButton
      onClick={onClick}
      selected={selected}
      sx={{
        borderRadius: 1,
        "&.Mui-selected": { bgcolor: (theme) => theme.palette.primary[50] },
      }}
    >
      {Icon && (
        <ListItemIcon
          sx={{
            minWidth: "28px",
            "& svg": {
              height: "20px",
              fill: (theme) => theme.palette.primary.main,
            },
          }}
        >
          <Icon />
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="baseMed"
              color={(theme) => theme.palette.primary.main}
            >
              {title}
            </Typography>

            {/* Using visibility instead of conditional rendering in order to have  a constant width */}
            <PlayArrowRoundedIcon
              fontSize="small"
              color="primary"
              sx={{ visibility: selected ? "initial" : "hidden" }}
            />
          </Stack>
        }
      />
    </ListItemButton>
  )
}
