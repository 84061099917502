import MuiListItemText, { ListItemTextProps } from "@mui/material/ListItemText"
import { forwardRef } from "react"

export type { ListItemTextProps }

export default forwardRef(function ListItemText(
  props: ListItemTextProps,
  ref: React.Ref<HTMLSpanElement>
) {
  return <MuiListItemText {...props} ref={ref} />
})
