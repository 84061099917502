import { FormattedMessage } from "react-intl"

import { IOrganizationPendingInvitation } from "api/types/organizationInvitations"

import Paper from "ds/Paper"
import Table from "ds/Table"
import TableBody from "ds/TableBody"
import TableCell from "ds/TableCell"
import TableContainer from "ds/TableContainer"
import TableHead from "ds/TableHead"
import TableRow from "ds/TableRow"

import RoleLabel from "components/common/RoleLabel"

import InvitationActions from "./InvitationActions"

interface Props {
  invitations: IOrganizationPendingInvitation[]
}

export default function OrganizationInvitationsTable({ invitations }: Props) {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: 800, boxShadow: 6 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="organizations.users.field.email"
                defaultMessage="Email"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="organizations.users.field.role"
                defaultMessage="Role"
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invitations.map((invitation) => (
            <TableRow key={invitation.id}>
              <TableCell>{invitation.recipientEmail}</TableCell>
              <TableCell>
                <RoleLabel role={invitation.role} />
              </TableCell>
              <TableCell>
                <InvitationActions invitation={invitation} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
