// Check if 2 elements have the same id
export const compareIds = <T extends { id: unknown }>(
  el1: T,
  el2: T
): boolean => el1.id === el2.id

// Updates the elements that match the predicate with updateFn
export const updateByPredicate = <TElement>(
  array: TElement[],
  predicate: (element: TElement) => boolean,
  updateFn: (element: TElement) => TElement
): TElement[] =>
  array.map((element) => (predicate(element) ? updateFn(element) : element))

// Updates the element with the given id with updateFn
export const updateById = <TId, TElement extends { id: TId }>(
  array: TElement[],
  elementId: TId,
  updateFn: (element: TElement) => TElement
): TElement[] =>
  updateByPredicate(array, (element) => element.id === elementId, updateFn)

// Replaces the elements that match the predicate with the given element
export const replaceByPredicate = <TElement>(
  array: TElement[],
  predicate: (element: TElement) => boolean,
  element: TElement
): TElement[] => updateByPredicate(array, predicate, () => element)

// Replaces the element with the same id as the given element with the given element
export const replaceById = <TElement extends { id: unknown }>(
  array: TElement[],
  element: TElement
): TElement[] =>
  replaceByPredicate(
    array,
    (originalElement) => originalElement.id === element.id,
    element
  )

export const replaceAllById = <TElement extends { id: unknown }>(
  array: TElement[],
  elements: TElement[]
): TElement[] =>
  array.map(
    (originalElement) =>
      elements.find((newElement) => newElement.id === originalElement.id) ||
      originalElement
  )

// Removes the element with the given id
export const removeById = <TId, TElement extends { id: TId }>(
  array: TElement[],
  elementId: TId
): TElement[] => array.filter((element) => element.id !== elementId)

export const insertAtIndex = <TElement>(
  array: TElement[],
  index: number,
  element: TElement
): TElement[] => [...array.slice(0, index), element, ...array.slice(index)]
