import { useState } from "react"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import { emailNotificationPreferenceOptions } from "api/types/me"

import useMe, { useMeMutation } from "authentication/useMe"

import FormControl from "ds/FormControl"
import { RadioFormControlLabel } from "ds/FormControlLabel"
import Radio from "ds/Radio"
import RadioGroup from "ds/RadioGroup"
import { AlertSnackbar, BasicErrorSnack } from "ds/Snackbar"

import EmailNotificationsRadioLabel from "./EmailNotificationsRadioLabel"

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`

export default function Notifications() {
  const user = useMe()
  const meMutation = useMeMutation()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)

  const submit = (newEmailPreference: string) =>
    meMutation.mutate(
      { emailNotificationPreference: newEmailPreference },
      {
        onSuccess: () => setSuccessSnackOpen(true),
        onError: () => setErrorSnackOpen(true),
      }
    )

  return (
    <div>
      <FormControl>
        <StyledRadioGroup
          value={user.emailNotificationPreference}
          name="email-notifications-radio-buttons-group"
          onChange={(e) => submit(e.target.value)}
        >
          {emailNotificationPreferenceOptions.map((preferenceOption) => (
            <RadioFormControlLabel
              sx={{ alignItems: "start" }}
              key={preferenceOption}
              value={preferenceOption}
              disabled={meMutation.isLoading}
              control={<Radio />}
              label={
                <EmailNotificationsRadioLabel
                  title={
                    <FormattedMessage
                      id={`settings.emailNotifications.${preferenceOption}.title`}
                      defaultMessage={preferenceOption}
                    />
                  }
                  description={
                    <FormattedMessage
                      id={`settings.emailNotifications.${preferenceOption}.description`}
                      defaultMessage={preferenceOption}
                    />
                  }
                />
              }
            />
          ))}
        </StyledRadioGroup>
      </FormControl>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <AlertSnackbar
        severity="success"
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      >
        <FormattedMessage id="settings.emailNotifications.PreferenceUpdateSuccess" />
      </AlertSnackbar>
    </div>
  )
}
