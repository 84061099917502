import { IMeeting } from "api/types/meetings"

import Paper from "ds/Paper"
import Table from "ds/Table"
import TableBody from "ds/TableBody"
import TableContainer from "ds/TableContainer"

import MeetingTableHead from "./MeetingTable.js/MeetingTableHead"
import MeetingTableRow from "./MeetingTable.js/MeetingTableRow"

interface Props {
  meetings: IMeeting[]
  showMeeting: (meetingId: string) => void
}

export default function MeetingsTable({ meetings, showMeeting }: Props) {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 6, maxWidth: "800px" }}>
      <Table>
        <MeetingTableHead />
        <TableBody>
          {meetings.map((meeting) => (
            <MeetingTableRow
              key={meeting.id}
              meeting={meeting}
              onClick={() => showMeeting(meeting.id)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
