import BusinessIcon from "@mui/icons-material/Business"
import SearchIcon from "@mui/icons-material/Search"
import { FormattedMessage } from "react-intl"

import BasicNav from "ds/BasicNav"

export default function SuperAdminNav() {
  return (
    <BasicNav
      entries={[
        {
          title: (
            <FormattedMessage
              id="superadmin.organizations.title"
              defaultMessage="Organizations"
            />
          ),
          href: "organizations",
          icon: BusinessIcon,
        },
        {
          title: (
            <FormattedMessage
              id="superadmin.indexation.title"
              defaultMessage="Indexation"
            />
          ),
          href: "indexation",
          icon: SearchIcon,
        },
      ]}
    />
  )
}
