export const IS_CHROME_EXTENSION = !!globalThis.chrome?.runtime?.id

// In the content script, we want the layout to always match mobile
export const EXTENSION_BREAKPOINT_VALUES = {
  xs: 0,
  sm: 4001,
  md: 4002,
  lg: 4003,
  xl: 4004,
}

export const IFRAME_SINGLE_PANEL_WIDTH = "598px" // 550 (panel width) + 24 (margin) * 2
export const IFRAME_DOUBLE_PANEL_WIDTH = "1132px" // 2 * 550 (2 panels) - 16 (padding merged) + 8 (right) + 40 (left)

export function getIndexUrl() {
  return chrome.runtime.getURL("index.html")
}

export function isValidChromeRuntime(): boolean {
  // It turns out that chrome.runtime.getManifest() returns undefined when the
  // runtime has been reloaded.
  try {
    return chrome.runtime && !!chrome.runtime.getManifest()
  } catch {
    return false
  }
}

const CALL_BUTTON_SELECTOR_HUBSPOT =
  '[data-test-id="create-engagement-call-button"]'
const CALL_BUTTON_SELECTOR_SALESFORCE = "lightning-formatted-phone"

function elementInCallButton(element: Element): boolean {
  return (
    !!element.closest(CALL_BUTTON_SELECTOR_HUBSPOT) ||
    !!element.closest(CALL_BUTTON_SELECTOR_SALESFORCE)
  )
}

export function listenToCallButtonClick(onClick: () => void) {
  document.addEventListener("click", (event) => {
    if (!(event.target instanceof Element)) return
    if (!elementInCallButton(event.target)) return
    onClick()
  })
}

const OPEN_PANEL_ON_CLICK_CALL_BUTTON_KEY = "OPEN_PANEL_ON_CLICK_CALL_BUTTON"

export async function getOpenPanelOnClickCallButtonSetting(): Promise<boolean> {
  const rawValue = (
    await chrome.storage.sync.get(OPEN_PANEL_ON_CLICK_CALL_BUTTON_KEY)
  )[OPEN_PANEL_ON_CLICK_CALL_BUTTON_KEY]
  return !!rawValue
}

export async function setOpenPanelOnClickCallButtonSetting(setting: boolean) {
  return chrome.storage.sync.set({
    [OPEN_PANEL_ON_CLICK_CALL_BUTTON_KEY]: setting,
  })
}

const CLOSE_PANEL_UPON_MEETING_END_SETTING =
  "CLOSE_PANEL_UPON_MEETING_END_SETTING"

export async function getClosePanelUponMeetingEndSetting(): Promise<boolean> {
  const rawValue = (
    await chrome.storage.sync.get(CLOSE_PANEL_UPON_MEETING_END_SETTING)
  )[CLOSE_PANEL_UPON_MEETING_END_SETTING]
  return !!rawValue
}

export async function setClosePanelUponMeetingEndSetting(setting: boolean) {
  return chrome.storage.sync.set({
    [CLOSE_PANEL_UPON_MEETING_END_SETTING]: setting,
  })
}

// Get the current URL from the Popup
export async function getCurrentUrl(): Promise<string | undefined> {
  const tab = (
    await chrome.tabs.query({ active: true, currentWindow: true })
  )[0]
  if (!tab) return undefined
  return tab.url
}

export function canActivateWebsite(url: string) {
  return url.startsWith("http")
}
