import ArchiveIcon from "@mui/icons-material/Archive"
import { FormattedMessage } from "react-intl"

import { IOrganization } from "api/types/organizations"

import FormattedTime from "ds/FormattedTime"
import Link from "ds/Link"
import Stack from "ds/Stack"
import TableCell from "ds/TableCell"
import TableRow from "ds/TableRow"
import Tooltip from "ds/Tooltip"

import { getManagePlaybooksBusinessUnitPath } from "services/businessUnits"
import { getManageOrganizationPath } from "services/organizations"

import OrganizationActions from "./OrganizationActions/OrganizationActions"

interface Props {
  organization: IOrganization
}

export default function OrganizationsTableRow({ organization }: Props) {
  return (
    <TableRow sx={{ opacity: organization.discarded ? 0.5 : 1.0 }}>
      <TableCell>
        <Link to={getManageOrganizationPath(organization.id)}>
          {organization.name}
        </Link>
      </TableCell>

      <TableCell>
        <Stack>
          {organization.businessUnits.map((businessUnit) => (
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              key={businessUnit.id}
            >
              <Link
                to={getManagePlaybooksBusinessUnitPath(
                  organization.id,
                  businessUnit.id
                )}
                sx={{ opacity: businessUnit.archived ? 0.5 : 1.0 }}
              >
                {businessUnit.name || (
                  <FormattedMessage id="organizations.businessUnits.title" />
                )}
              </Link>
              {businessUnit.archived && (
                <Tooltip
                  title={
                    <FormattedMessage id="superadmin.organizations.businessUnits.archived" />
                  }
                >
                  <ArchiveIcon fontSize="small" color="disabled" />
                </Tooltip>
              )}
            </Stack>
          ))}
        </Stack>
      </TableCell>

      <TableCell>
        <FormattedTime value={organization.createdAt} format="longTime" />
      </TableCell>

      <TableCell>
        <OrganizationActions organization={organization} />
      </TableCell>
    </TableRow>
  )
}
