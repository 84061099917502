export function getOrganizationPath(organizationId: string) {
  return `/org/${organizationId}`
}

export function getManageOrganizationPath(organizationId: string) {
  return `${getOrganizationPath(organizationId)}/manage`
}

export function getManageUsersOrganizationPath(organizationId: string) {
  return `${getManageOrganizationPath(organizationId)}/users`
}
