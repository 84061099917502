import { useIntl } from "react-intl"

import OutlinedInput from "ds/OutlinedInput"
import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { MyValue } from "ds/RichTextNew/types"

interface Props {
  id: string
  inputRef: React.MutableRefObject<HTMLInputElement | null>
  endAdornment?: React.ReactNode
  value: MyValue
  onChange: (newValue: MyValue, newRawValue: string) => void
  onKeyDown: React.KeyboardEventHandler
}

export default function ArgumentPersonalNoteInput({
  id,
  value,
  onChange,
  inputRef,
  onKeyDown,
  endAdornment,
}: Props) {
  const intl = useIntl()
  return (
    <OutlinedInput
      fullWidth
      placeholder={intl.formatMessage({
        id: "playbook.argumentPersonalNote.cta",
      })}
      ref={inputRef}
      // @ts-ignore // TODO RichText typing
      inputComponent={RichTextEditor}
      sx={{
        "& .MuiOutlinedInput-input": {
          p: 1,
          pb: 0,
          flex: 1,
          height: "auto",
        },
      }}
      id={id}
      value={value}
      // @ts-ignore // TODO RichText typing
      onChange={onChange}
      inputProps={{
        autoFocus: true,
        onKeyDown,
      }}
      endAdornment={endAdornment}
      notched
    />
  )
}
