import { DraggableProvidedDragHandleProps } from "@hello-pangea/dnd"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"

import IconButton from "ds/IconButton"

interface DragAndDropHandleProps {
  disabled?: boolean
  dragHandleProps: DraggableProvidedDragHandleProps | null
}

export default function DragAndDropHandle({
  disabled,
  dragHandleProps,
}: DragAndDropHandleProps) {
  return (
    <IconButton
      disabled={disabled}
      aria-label="drag"
      opacityTransitionOnHover
      {...dragHandleProps}
    >
      <DragIndicatorIcon fontSize="small" />
    </IconButton>
  )
}
