import Divider from "ds/Divider"

export default function ToolbarDivider() {
  return (
    <Divider
      orientation="vertical"
      flexItem
      variant="middle"
      sx={{ mx: 0.5 }}
    />
  )
}
