import { useTheme } from "@mui/material"
import { MouseEventHandler } from "react"
import styled from "styled-components"

import IconButton, { IconButtonProps } from "ds/IconButton"
import Shortcut from "ds/Shortcut"
import Tooltip from "ds/Tooltip"

const TooltipWithShortcuts = styled.div`
  text-align: center;
`

export interface PillIconButtonProps extends IconButtonProps {
  pressed?: boolean
  label?: string
  shortcut?: string[]
  onMouseDown: MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
}

const PillIconButton = ({
  pressed,
  label,
  shortcut,
  disabled,
  ...props
}: PillIconButtonProps) => {
  const theme = useTheme()

  const button = (
    <IconButton
      color="inherit"
      opacityTransitionOnHover={!pressed}
      disabled={disabled}
      {...props}
    />
  )

  if (disabled) return button

  // Warning: You are providing a disabled `button` child to the Tooltip componen
  // Wrap in span to allow tooltip to listen to events
  return (
    <Tooltip
      title={
        <TooltipWithShortcuts>
          <div>{label}</div>
          {shortcut && (
            <Shortcut
              keyStyle={{ backgroundColor: theme.palette.grey["800"] }}
              keys={shortcut}
            />
          )}
        </TooltipWithShortcuts>
      }
    >
      {button}
    </Tooltip>
  )
}

export default PillIconButton
