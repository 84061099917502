import { FormattedMessage } from "react-intl"

import { IArgumentGroup } from "api/types/argumentGroups"

import MenuItem from "ds/MenuItem"

import { copyArgumentGroup } from "services/copyPaste"

interface Props {
  argumentGroup: IArgumentGroup
  onSuccess: () => void
}

export default function CopyArgumentGroup({ argumentGroup, onSuccess }: Props) {
  async function onClick() {
    copyArgumentGroup(argumentGroup.id)
    onSuccess()
  }

  return (
    <MenuItem onClick={onClick}>
      <FormattedMessage id="playbookEdit.argumentGroups.copy" />
    </MenuItem>
  )
}
