import { IArgumentFieldValue } from "api/types/argumentFieldValues"
import { IArgumentField } from "api/types/argumentFields"
import { IArgument } from "api/types/arguments"
import { IPlaybook } from "api/types/playbooks"

import changeArrayPosition from "utils/changeArrayPosition"
import normalizeString from "utils/normalizeString"

export function getCustomFields(playbook: IPlaybook) {
  return playbook.argumentFields.filter((x) => x.type === "CUSTOM")
}

export function hasFieldsOutsideTitleAndDescription(
  argument: IArgument,
  playbook: IPlaybook
) {
  // TODO memoize
  const nonArchivedArgumentFieldIds = new Set(
    playbook.argumentFields
      .filter((argumentField) => !argumentField.archived)
      .map((argumentField) => argumentField.id)
  )
  return argument.argumentFieldValues.some(
    (argumentFieldValue) =>
      nonArchivedArgumentFieldIds.has(argumentFieldValue.argumentFieldId) &&
      argumentFieldValue.argumentFieldType !== "TITLE" &&
      argumentFieldValue.argumentFieldType !== "SHORT_DESCRIPTION" &&
      !!argumentFieldValue.value
  )
}

export function buildFieldValue(
  argumentField: IArgumentField
): IArgumentFieldValue {
  return {
    id: null,
    value: null,
    rawValue: null,
    argumentFieldId: argumentField.id,
    argumentFieldType: argumentField.type,
  }
}

export function getGenericFieldValue(argument: IArgument, type: string) {
  return argument.argumentFieldValues.find((x) => x.argumentFieldType === type)
}

export function getCustomFieldValue(
  argument: IArgument,
  argumentField: IArgumentField
) {
  return argument.argumentFieldValues.find(
    (x) => x.argumentFieldId === argumentField.id
  )
}

export function getFieldValue(
  argument: IArgument,
  argumentField: IArgumentField
) {
  return argumentField.type === "CUSTOM"
    ? getCustomFieldValue(argument, argumentField)
    : getGenericFieldValue(argument, argumentField.type)
}

export function getCustomFieldValues(argument: IArgument) {
  return argument.argumentFieldValues.filter(
    (argumentFieldValue) => argumentFieldValue.argumentFieldType === "CUSTOM"
  )
}

export function getTitle(argument: IArgument) {
  return getGenericFieldValue(argument, "TITLE")?.value || null
}

export function getRawTitle(argument: IArgument) {
  return getGenericFieldValue(argument, "TITLE")?.rawValue || null
}

export function getShortDescription(argument: IArgument) {
  return getGenericFieldValue(argument, "SHORT_DESCRIPTION")?.value || null
}

export function getRawDescription(argument: IArgument) {
  return getGenericFieldValue(argument, "SHORT_DESCRIPTION")?.rawValue || null
}

// We can always rely on the name because we don't filter on generic fields
export function filterArgumentFields(
  argumentFields: IArgumentField[],
  searchTerm: string
) {
  if (searchTerm.length === 0) {
    return argumentFields
  }
  const normalizedSearchTerm = normalizeString(searchTerm)
  return argumentFields.filter(
    (argumentField) =>
      argumentField.name &&
      normalizeString(argumentField.name).includes(normalizedSearchTerm)
  )
}

export function moveArgumentField(
  argumentFields: IArgumentField[],
  argumentFieldId: string,
  relativeArgumentFieldId: string
) {
  const oldPosition = argumentFields.findIndex((x) => x.id === argumentFieldId)
  let newPosition = argumentFields.findIndex(
    (x) => x.id === relativeArgumentFieldId
  )
  return changeArrayPosition(argumentFields, oldPosition, newPosition)
}
