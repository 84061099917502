import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import MicIcon from "@mui/icons-material/Mic"
import MicNoneIcon from "@mui/icons-material/MicNone"
import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import Popover from "ds/Popover"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { DialogClassName } from "../../DialogClassName"
import { SpeechToTextLanguagePicker } from "./SpeechToTextLanguagePicker"
import { BCP47Lang } from "./bcp47"

interface Props {
  anchorEl: Element | null
  isOpen: boolean
  onClose: () => void
  isListening: boolean
  lang: BCP47Lang
  setLang: (selectedLang: BCP47Lang) => void
  startListening: () => void
  stopListening: () => void
  onInsert: () => void
  results: SpeechRecognitionResult[]
}

const PulsatingMicIcon = styled(MicIcon)({
  animation: "pulsating 2s linear infinite",
  "@keyframes pulsating": {
    "0%": {
      transform: "scale(0.8)",
    },
    "50%": {
      transform: "scale(1.2)",
    },
    "100%": {
      transform: "scale(0.8)",
    },
  },
})

export default function SpeechToTextPopover({
  anchorEl,
  isOpen,
  onClose,
  isListening,
  lang,
  setLang,
  startListening,
  stopListening,
  results,
  onInsert,
}: Props) {
  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      className={DialogClassName}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <Stack direction="column" p={2} gap={2} width="min(500px, 90vw)">
        <Stack direction="row" gap={2}>
          <SpeechToTextLanguagePicker
            value={lang}
            onChange={setLang}
            disabled={isListening}
          />
          <Button
            variant="contained"
            startIcon={
              isListening ? (
                <PulsatingMicIcon fontSize="small" />
              ) : (
                <MicNoneIcon fontSize="small" />
              )
            }
            color={isListening ? "error" : "success"}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault()
              isListening ? stopListening() : startListening()
            }}
          >
            {isListening ? (
              <FormattedMessage id="richtext.speechToText.listening" />
            ) : (
              <FormattedMessage id="richtext.speechToText.startDictating" />
            )}
          </Button>
        </Stack>

        <Box
          p={1}
          border={(theme) => `1px solid ${theme.palette.grey[300]}`}
          borderRadius={1}
          bgcolor={(theme) => theme.palette.grey[100]}
        >
          <Typography variant="smNormal">
            {results.length > 0 &&
              results.map((result) => result[0].transcript).join(" ")}
            {results.length === 0 && isListening && (
              <Box color={(theme) => theme.palette.text.secondary}>
                <FormattedMessage id="richtext.speechToText.startDictating" />
              </Box>
            )}
            {results.length === 0 && !isListening && (
              <Box color={(theme) => theme.palette.text.secondary}>
                <FormattedMessage id="richtext.speechToText.needToStart" />
              </Box>
            )}
          </Typography>
        </Box>

        <Stack direction="row" gap={2}>
          <Button
            onClick={onInsert}
            color="primary"
            variant="contained"
            startIcon={<ExitToAppIcon fontSize="small" />}
            disabled={results.length === 0}
          >
            <FormattedMessage id="richtext.speechToText.insert" />
          </Button>

          <Button color="neutral" variant="outlined" onClick={onClose}>
            <FormattedMessage id="common.cancel" />
          </Button>
        </Stack>
      </Stack>
    </Popover>
  )
}
