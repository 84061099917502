import { Navigate, Route, Routes } from "react-router-dom"

import PlaybookLoaded from "components/App/Playbook/PlaybookLoaded"

import {
  NavigateToLastAppliedSegments,
  NavigateToLastVisitedArgumentType,
} from "../NavigateToLastAppliedParameters"
import PlaybookEditLayout from "./PlaybookEditLayout"
import PlaybookMatrix from "./PlaybookMatrix"

export default function PlaybookEdit() {
  return (
    <PlaybookEditLayout>
      <Routes>
        <Route
          path="view/*"
          element={
            <NavigateToLastVisitedArgumentType>
              <NavigateToLastAppliedSegments>
                <PlaybookLoaded />
              </NavigateToLastAppliedSegments>
            </NavigateToLastVisitedArgumentType>
          }
        />
        <Route
          path="matrix/*"
          element={
            <NavigateToLastVisitedArgumentType>
              <PlaybookMatrix />
            </NavigateToLastVisitedArgumentType>
          }
        />
        <Route path="/" element={<Navigate to="view" replace />} />
      </Routes>
    </PlaybookEditLayout>
  )
}
