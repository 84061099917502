import { createConsumer as _createConsumer } from "@rails/actioncable"

import apiHost from "api/apiHost"

import { getGetToken } from "authentication/globalGetToken"

export async function createConsumer() {
  const socketURL = await getWebSocketURL()
  return _createConsumer(socketURL)
}

async function getWebSocketURL(): Promise<string> {
  const getToken = getGetToken()
  const token = await getToken()
  return `${apiHost}/cable?token=${token}`
}
