import { IBarePlaybook } from "api/types/playbooks"

import Stack from "ds/Stack"

import CreatePlaybook from "./CreatePlaybook"
import ImportPlaybook from "./ImportPlaybook/ImportPlaybook"

interface Props {
  businessUnitId: string
  onPlaybookCreation: (playbook: IBarePlaybook) => void
}

export default function CreatePlaybookCTAs({
  businessUnitId,
  onPlaybookCreation,
}: Props) {
  return (
    <Stack direction="row" spacing={2}>
      <ImportPlaybook onPlaybookCreation={onPlaybookCreation} />
      <CreatePlaybook onPlaybookCreation={onPlaybookCreation} />
    </Stack>
  )
}
