import axios from "./axios"
import {
  IBarePlaybook,
  ICreatePlaybookParams,
  ICreatePlaybookResponse,
  IGetPlaybookResponse,
  IImportPlaybookParams,
  IImportPlaybookResponse,
  IUpdatePlaybookParams,
} from "./types/playbooks"
import { IUser } from "./types/users"

export const apiGetPlaybook = (
  id: string,
  { signal }: { signal?: AbortSignal }
): Promise<IGetPlaybookResponse> =>
  axios.get(`api/playbooks/${id}`, { signal }).then((res) => res.data)

export const apiGetPlaybookUsers = (
  playbookId: string,
  { signal }: { signal?: AbortSignal }
): Promise<IUser[]> =>
  axios
    .get(`api/playbooks/${playbookId}/users`, { signal })
    .then((res) => res.data)

export const apiCreatePlaybook = (
  params: ICreatePlaybookParams
): Promise<ICreatePlaybookResponse> =>
  axios.post("api/playbooks", params).then((res) => res.data)

export const apiUpdatePlaybook = (
  params: IUpdatePlaybookParams
): Promise<undefined> =>
  axios.patch(`api/playbooks/${params.id}`, params).then((res) => res.data)

export const apiGetImportablePlaybooks = ({
  signal,
}: {
  signal?: AbortSignal
}): Promise<IBarePlaybook[]> =>
  axios
    .get("api/playbooks/index_importable", { signal })
    .then((res) => res.data)

export const apiImportPlaybook = (
  params: IImportPlaybookParams
): Promise<IImportPlaybookResponse> =>
  axios.post("api/playbooks/import", params).then((res) => res.data)

export const apiArchivePlaybook = (playbookId: string): Promise<undefined> =>
  axios.patch(`api/playbooks/${playbookId}/archive`).then((res) => res.data)

export const apiRestorePlaybook = (playbookId: string): Promise<undefined> =>
  axios.patch(`api/playbooks/${playbookId}/restore`).then((res) => res.data)

export const apiGetNavigablePlaybooks = (): Promise<IBarePlaybook[]> =>
  axios.get("api/playbooks/navigable").then((res) => res.data)
