import FormatItalicIcon from "@mui/icons-material/FormatItalic"
import { useIntl } from "react-intl"

import { mod } from "../../hotkeys"
import MarkButton from "./MarkButton"

interface Props {
  disabled: boolean
}

export default function ItalicButton({ disabled }: Props) {
  const intl = useIntl()

  return (
    <MarkButton
      label={intl.formatMessage({
        id: "richtext.italic",
        defaultMessage: "Italic",
      })}
      shortcut={[mod, "I"]}
      disabled={disabled}
      nodeType="italic"
    >
      <FormatItalicIcon sx={{ height: "16px", width: "16px" }} />
    </MarkButton>
  )
}
