import MoreHoriz from "@mui/icons-material/MoreHoriz"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { IOrganization } from "api/types/organizations"

import Button from "ds/Button"
import Menu from "ds/Menu"
import MenuItem from "ds/MenuItem"

import {
  useOrganizationArchiveMutation,
  useOrganizationRestoreMutation,
} from "components/App/Organization/organizationQueries"

import useAnchorEl from "utils/hooks/useAnchorEl"

import RenameOrganization from "./RenameOrganization"

interface Props {
  organization: IOrganization
}

export default function OrganizationActions({ organization }: Props) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const [isRenameOpen, setIsRenameOpen] = useState(false)
  const archiveMutation = useOrganizationArchiveMutation()
  const restoreMutation = useOrganizationRestoreMutation()

  return (
    <>
      <Button
        onClick={handleClick}
        aria-label={intl.formatMessage({ id: "organizations.actions" })}
      >
        <MoreHoriz />
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose()
            setIsRenameOpen(true)
          }}
        >
          <FormattedMessage id="organizations.actions.rename" />
        </MenuItem>

        {organization.discarded ? (
          <MenuItem
            onClick={() =>
              restoreMutation.mutate(organization.id, {
                onSuccess: () => handleClose(),
              })
            }
            disabled={restoreMutation.isLoading}
          >
            <FormattedMessage id="organizations.actions.restore" />
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() =>
              archiveMutation.mutate(organization.id, {
                onSuccess: () => handleClose(),
              })
            }
            disabled={archiveMutation.isLoading}
          >
            <FormattedMessage id="organizations.actions.archive" />
          </MenuItem>
        )}
      </Menu>

      {isRenameOpen && (
        <RenameOrganization
          organization={organization}
          onClose={() => setIsRenameOpen(false)}
        />
      )}
    </>
  )
}
