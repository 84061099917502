import { Box, useTheme } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"

import { IBarePlaybook } from "api/types/playbooks"

import Stack from "ds/Stack"
import Typography from "ds/Typography"

import CreatePlaybookCTAs from "./CreatePlaybookCTAs"

interface Props {
  businessUnitId: string
  onPlaybookCreation: (playbook: IBarePlaybook) => void
}

export default function NoPlaybookCreationCTAs({
  businessUnitId,
  onPlaybookCreation,
}: Props) {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Stack
      spacing={2}
      alignItems="center"
      sx={{
        p: 2,
        maxWidth: "600px",
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 1,
      }}
    >
      <Typography variant="lgSemi">
        <FormattedMessage id="businessUnits.playbooks.empty.title" />
      </Typography>

      <Box sx={{ alignSelf: "start" }}>
        <Box>
          <FormattedMessage id="businessUnits.playbooks.empty.explanation" />
        </Box>
      </Box>

      <Box sx={{ alignSelf: "start" }}>
        <FormattedMessage
          id="businessUnits.playbooks.empty.tutorial"
          values={{
            link: (chunks) => (
              <a
                target="_blank"
                href={intl.formatMessage({ id: "navigation.tutorialHref" })}
                rel="noreferrer"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </Box>

      <Box>
        <CreatePlaybookCTAs
          businessUnitId={businessUnitId}
          onPlaybookCreation={onPlaybookCreation}
        />
      </Box>
    </Stack>
  )
}
