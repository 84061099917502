import { FormattedMessage, useIntl } from "react-intl"

import { IMeeting } from "api/types/meetings"

import Link from "ds/Link"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import {
  convertMeetingNotesToClipboardText,
  getMeetingPath,
} from "services/meetings"

interface Props {
  ongoingMeeting: IMeeting
}

export default function ViewNotes({ ongoingMeeting }: Props) {
  const intl = useIntl()
  const ongoingMeetingURL = getMeetingPath(ongoingMeeting)

  return (
    <Stack spacing={1}>
      <Typography variant="baseSemi">
        <FormattedMessage id="playbook.meeting.endDialog.successMessage" />
      </Typography>

      <Stack direction="row" spacing={1}>
        <Link
          to={"/"}
          onClick={(e) => {
            e.preventDefault()
            navigator.clipboard.writeText(
              convertMeetingNotesToClipboardText(ongoingMeeting, intl)
            )
          }}
        >
          <FormattedMessage id="playbook.meeting.endDialog.copyToClipboard" />
        </Link>

        <Link to={ongoingMeetingURL}>
          <FormattedMessage id="playbook.meeting.endDialog.viewNotes" />
        </Link>
      </Stack>
    </Stack>
  )
}
