import { FormattedMessage, useIntl } from "react-intl"

import {
  IMeetingNoteConfigurationFieldType,
  meetingNoteConfigurationFieldTypes,
} from "api/types/meetingNoteConfigurations"

import MenuItem from "ds/MenuItem"
import Select from "ds/Select"

interface Props {
  value: IMeetingNoteConfigurationFieldType | ""
  onSelect: (newValue: IMeetingNoteConfigurationFieldType | "") => void
}

export default function ArgumentAnswerFormTypeSelector({
  value,
  onSelect,
}: Props) {
  const intl = useIntl()

  const selectTypeLabel = intl.formatMessage({
    id: "playbookEdit.argumentAnswer.form.typeSelect.placeholder",
    defaultMessage: "Select answer type",
  })

  return (
    <Select<IMeetingNoteConfigurationFieldType>
      labelId="select-type-label"
      label={selectTypeLabel}
      value={value}
      onChange={(e) => {
        const rawValue = e.target.value
        const safeValue =
          meetingNoteConfigurationFieldTypes.find((x) => x === rawValue) || ""
        onSelect(safeValue)
      }}
    >
      {meetingNoteConfigurationFieldTypes.map((fieldType) => (
        <MenuItem value={fieldType} key={fieldType}>
          <FormattedMessage
            id={`playbookEdit.argumentAnswer.form.typeSelect.${fieldType}`}
            defaultMessage={fieldType}
          />
        </MenuItem>
      ))}
    </Select>
  )
}
