import Loader from "ds/Loader"
import UncontrolledError from "ds/UncontrolledError"

import { useOrganizationsQuery } from "components/App/Organization/organizationQueries"

import OrganizationsTable from "./OrganizationsTable"

export default function OrganizationsIndex({ name }: { name: string }) {
  const {
    isLoading,
    isError,
    error,
    data: organizations,
  } = useOrganizationsQuery(name)

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <UncontrolledError error={error} />
  }

  return <OrganizationsTable organizations={organizations} />
}
