import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import { IMeeting } from "api/types/meetings"

import Alert from "ds/Alert"
import Button from "ds/Button"
import Loader from "ds/Loader"
import Stack from "ds/Stack"

import MeetingsTable from "./MeetingsTable"

function NoResults() {
  return (
    <Alert severity="info">
      <FormattedMessage
        id="meetings.index.results.none"
        defaultMessage="No results"
      />
    </Alert>
  )
}

interface Props {
  meetings: IMeeting[]
  isFetchingNextPage: boolean
  hasNextPage: boolean
  fetchNextPage: () => void
  showMeeting: (meetingId: string) => void
}

export default function MeetingsIndexResults({
  meetings,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  showMeeting,
}: Props) {
  if (meetings.length === 0) return <NoResults />

  return (
    <Stack flexDirection="column" spacing={1} alignSelf="flex-start">
      {meetings.length > 0 && (
        <MeetingsTable meetings={meetings} showMeeting={showMeeting} />
      )}
      {isFetchingNextPage && <Loader />}
      {hasNextPage && !isFetchingNextPage && (
        <Box>
          <Button onClick={() => fetchNextPage()}>
            <FormattedMessage id="meetings.index.loadMore" />
          </Button>
        </Box>
      )}
    </Stack>
  )
}
