import { Link as RouterLink } from "react-router-dom"

import LinkRouterless, {
  LinkProps as LinkRouterlessProps,
} from "./LinkRouterless"

export type LinkProps = Omit<
  LinkRouterlessProps<typeof RouterLink>,
  "component"
>

export default function Link(props: LinkProps) {
  return (
    <LinkRouterless
      component={RouterLink}
      {...props}
      sx={{ textDecoration: "none", ...props.sx }}
    />
  )
}
