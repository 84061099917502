import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import Breadcrumbs from "ds/Breadcrumbs"
import Loader from "ds/Loader"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import { useBusinessUnit } from "./BusinessUnitProvider"
import { useDashboardQuery } from "./dashboardQueries"

export default function BusinessUnitDashboard() {
  const businessUnit = useBusinessUnit()
  const { data, isLoading, isError, error } = useDashboardQuery(businessUnit.id)

  if (isLoading) return <Loader />
  if (isError) return <UncontrolledError error={error} />

  return (
    <Stack direction="column" sx={{ p: 2, height: "100%" }}>
      <Breadcrumbs>
        <Typography variant="smMed">{businessUnit.name}</Typography>
        <Typography variant="smMed">
          <FormattedMessage id="businessUnits.dashboard.title" />
        </Typography>
      </Breadcrumbs>

      <Box alignSelf="flex-end">
        <Typography variant="xsNormal">
          <FormattedMessage id="businessUnits.dashboard.refreshHint" />
        </Typography>
      </Box>
      <iframe
        title="dashboard"
        src={data.embedUrl}
        width="100%"
        style={{ flexGrow: 1, border: "none" }}
      />
    </Stack>
  )
}
