import Box, { BoxProps } from "@mui/material/Box"
import styled from "styled-components"

import Header from "./Header"

export const PageContainer = styled(Box)`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.palette.grey[50]};
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled(Box)`
  flex-grow: 1;
  min-height: 0; // Avoid overflowing container
  min-width: 0; // Avoid overflowing container
`

interface Props {
  children: React.ReactNode
  header?: React.ReactNode
  contentProps?: Omit<BoxProps, "children">
}

export default function SimpleLayout({
  children,
  header,
  contentProps = {},
}: Props) {
  return (
    <PageContainer>
      {header || <Header />}
      <ContentContainer {...contentProps}>{children}</ContentContainer>
    </PageContainer>
  )
}
