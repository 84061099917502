import { forwardRef } from "react"
import styled, { css } from "styled-components"

import { ReactComponent as HubspotIcon } from "images/hubspot.svg"
import { ReactComponent as SalesforceIcon } from "images/salesforce.svg"

const commonStyle = css`
  width: 16px;
  flex-shrink: 0;
`

const StlyedHubspotIcon = styled(HubspotIcon)`
  ${commonStyle}
`

const StlyedSalesforceIcon = styled(SalesforceIcon)`
  ${commonStyle}
`

interface Props extends React.SVGProps<SVGSVGElement> {
  integrationName: string
  style?: React.CSSProperties
}

export default forwardRef<SVGSVGElement, Props>(function IntegrationIcon(
  { integrationName, ...props },
  ref
) {
  if (integrationName === "hubspot")
    return <StlyedHubspotIcon {...props} ref={ref} />
  if (integrationName === "salesforce")
    return <StlyedSalesforceIcon {...props} ref={ref} />
  return null
})
