import {
  ELEMENT_TODO_LI,
  TTodoListItemElement,
  isBlock,
  setNodes,
} from "@udecode/plate"

import { MyAutoformatRule } from "ds/RichTextNew/types"

export const autoformatLists: MyAutoformatRule[] = [
  {
    mode: "block",
    type: ELEMENT_TODO_LI,
    match: "[] ",
  },
  {
    mode: "block",
    type: ELEMENT_TODO_LI,
    match: "[x] ",
    format: (editor) =>
      setNodes<TTodoListItemElement>(
        editor,
        { type: ELEMENT_TODO_LI, checked: true },
        {
          match: (n) => isBlock(editor, n),
        }
      ),
  },
]
