import { uniqueId } from "lodash"
import { FormattedMessage } from "react-intl"

import MenuItem from "ds/MenuItem"
import Select from "ds/Select"

interface Props {
  value?: boolean | ""
  onSelect: (newValue: boolean) => void
}

export default function ArgumentAnswerFormCategorySelector({
  value,
  onSelect,
}: Props) {
  const labelId = uniqueId()

  const toSelectValue = (value?: boolean | "") => {
    if (value === "") return ""
    if (value) return "yes"
    if (!value) return "no"
    return ""
  }

  return (
    <Select
      labelId={labelId}
      value={toSelectValue(value)}
      label={
        <FormattedMessage id="playbookEdit.argumentAnswer.form.syncToCRM.label" />
      }
      onChange={(e) => onSelect(e.target.value === "yes")}
    >
      <MenuItem value="yes">
        <FormattedMessage id="common.yes" />
      </MenuItem>
      <MenuItem value="no">
        <FormattedMessage id="common.no" />
      </MenuItem>
    </Select>
  )
}
