import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { ICreateOrganizationResponse } from "api/types/organizations"

import Button from "ds/Button"
import Modal from "ds/Modal"

import CreateOrganizationForm from "./CreateOrganizationForm"

interface Props {
  addCurrentUser: boolean
  onCreate?: (data: ICreateOrganizationResponse) => void
}

export default function CreateOrganization(props: Props) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        color="primary"
        onClick={() => setIsOpen(true)}
        variant="contained"
      >
        <FormattedMessage
          id="organizations.create.cta"
          defaultMessage="Create new organization"
        />
      </Button>

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <CreateOrganizationForm
          onCreate={(data) => {
            setIsOpen(false)
            props.onCreate?.(data)
          }}
          addCurrentUser={props.addCurrentUser}
        />
      </Modal>
    </>
  )
}
