import { Box } from "@mui/material"

export default function RequiredCue() {
  return (
    <Box component="span" sx={{ color: "error.main" }}>
      *
    </Box>
  )
}
interface SuffixRequiredCueProps {
  children: React.ReactNode
  showCue?: boolean
}

export function SuffixRequiredCue({
  children,
  showCue = true,
}: SuffixRequiredCueProps) {
  if (!showCue) return <>{children}</>
  return (
    <>
      {children}&nbsp;
      <RequiredCue />
    </>
  )
}
