import styled from "styled-components"

import { IArgumentComment } from "api/types/argumentComments"

import FormattedTime from "ds/FormattedTime"
import Tooltip from "ds/Tooltip"
import Typography from "ds/Typography"

import { abbreviatedEmail, firstNameAndInitial } from "utils/initials"

import CommentResolvedCue from "./CommentResolvedCue"

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  > :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`

const Name = styled.span`
  font-weight: 500;
`

interface Props {
  comment: IArgumentComment
}

export default function CommentInfo({ comment }: Props) {
  return (
    <Container>
      {!!comment.resolvedByUser && <CommentResolvedCue />}
      <Name>
        {comment.user.name ? (
          firstNameAndInitial(comment.user.name)
        ) : (
          <Tooltip title={comment.user.email}>
            <div>{abbreviatedEmail(comment.user.email)}</div>
          </Tooltip>
        )}
      </Name>

      <Typography variant="xsNormal" component="span">
        <FormattedTime value={comment.createdAt} format="shortTime" />
      </Typography>
    </Container>
  )
}
