import { FormattedMessage } from "react-intl"

import { IArgumentSegmentRuleOperator } from "api/types/arguments"

interface Props {
  operator: IArgumentSegmentRuleOperator
}

export default function SegmentRuleOperator({ operator }: Props) {
  return (
    <FormattedMessage
      id={`playbookEdit.argument.segmentRules.operator.${operator}`}
      defaultMessage={operator}
    />
  )
}
