import { IArgumentGroup } from "api/types/argumentGroups"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import ArgumentGroupNameDisplay from "./ArgumentGroupNameDisplay"
import EditableArgumentGroupName from "./EditableArgumentGroupName"

interface Props {
  argumentGroup: IArgumentGroup
}

export default function ArgumentGroupName({ argumentGroup }: Props) {
  const { editMode } = usePlaybook()

  if (editMode) {
    return (
      // Prevent propagation that would collapse the group
      <div onClick={(e) => e.stopPropagation()}>
        <EditableArgumentGroupName argumentGroup={argumentGroup} />
      </div>
    )
  }

  return <ArgumentGroupNameDisplay argumentGroup={argumentGroup} />
}
