import { useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"

import Typography from "ds/Typography"

import VoteAnimation from "./VoteAnimation"

interface Props {
  votesCount: number
}

export default function VoteCount({ votesCount }: Props) {
  const intl = useIntl()
  const [isAnimating, setIsAnimating] = useState(false)
  const votesCountRef = useRef<number>(votesCount)

  // Animate when the count increase (from swipe animation on mobile)
  useEffect(() => {
    if (votesCount > votesCountRef.current) setIsAnimating(true)
    votesCountRef.current = votesCount
  }, [votesCount])

  return (
    <Typography
      variant="xxsSemi"
      component="span"
      aria-label={intl.formatMessage({
        id: "arguments.voteCountLabel",
        defaultMessage: "Vote count",
      })}
    >
      {votesCount && votesCount !== 0 ? votesCount : <>&nbsp;</>}

      {/*
        At some point rendering the animation caused a vertical offset of 2px.
        This is caused by the spacing prop on the container, even when the element is outside the flow
        The css rule adds a margin-top to his next sibling.
        To avoid this, we moved the animation inside the Count component.
      */}
      {isAnimating && (
        <VoteAnimation onAnimationEnd={() => setIsAnimating(false)} />
      )}
    </Typography>
  )
}
