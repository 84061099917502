import { FormattedMessage } from "react-intl"

export default function DefaultTitle() {
  return (
    <FormattedMessage
      id="playbookEdit.argument.defaultTitle"
      defaultMessage="Title"
    />
  )
}
