import { useParams } from "react-router-dom"

import Loader from "ds/Loader"
import UncontrolledError from "ds/UncontrolledError"

import MeetingProvider from "./Playbook/Meeting/MeetingProvider"
import PlaybookLayout from "./Playbook/PlaybookLayout"
import PlaybookLoaded from "./Playbook/PlaybookLoaded"
import PlaybookProvider from "./Playbook/PlaybookProvider"
import {
  usePlaybookQuery,
  usePlaybookUsersQuery,
} from "./Playbook/queries/playbookQueries"

export default function PublicPlaybookPage() {
  const params = useParams()
  const playbookId = params.playbookId!
  const playbookQuery = usePlaybookQuery(playbookId)
  const playbookUsersQuery = usePlaybookUsersQuery(playbookId)

  if (playbookQuery.isLoading || playbookUsersQuery.isLoading) {
    return <Loader />
  }
  if (playbookQuery.isError || playbookUsersQuery.isError)
    return (
      <UncontrolledError
        error={playbookQuery.error || playbookUsersQuery.error}
      />
    )

  return (
    <PlaybookProvider
      playbook={playbookQuery.data.playbook}
      canEdit={playbookQuery.data.canEdit}
      users={playbookUsersQuery.data}
      readOnly
    >
      <MeetingProvider
        ongoingMeeting={null}
        playbook={playbookQuery.data.playbook}
      >
        <PlaybookLayout>
          <PlaybookLoaded />
        </PlaybookLayout>
      </MeetingProvider>
    </PlaybookProvider>
  )
}
