import styled from "styled-components"

import SanitizeHTML from "ds/SanitizeHTML"

const StyledSanitizeHTML = styled(SanitizeHTML)`
  display: inline;
  .argument-search-highlight {
    background-color: ${({ theme }) => theme.palette.warning.light};
  }
`

interface Props {
  html: string
}

export default function HighlightedText({ html }: Props) {
  return (
    <StyledSanitizeHTML
      html={html}
      options={{
        allowedTags: ["span"],
        allowedAttributes: { span: ["class"] },
      }}
    />
  )
}
