import { ToggleMarkPlugin } from "@udecode/plate"
import { createUnderlinePlugin } from "@udecode/plate-basic-marks"

import Underline from "../components/Underline"
import { MyEditor, MyValue } from "../types"

export const underlinePlugin = createUnderlinePlugin<
  ToggleMarkPlugin,
  MyValue,
  MyEditor
>({ component: Underline })
