import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IBarePlaybook } from "api/types/playbooks"

import Button from "ds/Button"
import Modal from "ds/Modal"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import CreatePlaybookForm from "./CreatePlaybookForm"

interface Props {
  onPlaybookCreation: (playbook: IBarePlaybook) => void
}

export default function CreatePlaybook({ onPlaybookCreation }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const cta = <FormattedMessage id="businessUnits.playbooks.actions.create" />

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => setIsOpen(true)}
      >
        {cta}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        data-testid="create-playbook-modal"
      >
        <Stack spacing={2}>
          <Typography variant="baseSemi">{cta}</Typography>

          <CreatePlaybookForm
            onSuccess={(playbook) => {
              setIsOpen(false)
              onPlaybookCreation(playbook)
            }}
          />
        </Stack>
      </Modal>
    </>
  )
}
