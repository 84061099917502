import { Box } from "@mui/material"
import { PlateElement } from "@udecode/plate"

import { useCloudAttachmentElementState } from "ds/RichTextNew/plugins/cloud"

import { CloudAttachmentProps } from "./CloudAttachmentProps"

// At this point we know that the attachment is uploaded
// This would not be the case fi we were using the cloudImagePlugin or the the imagePlugin from @udecode/plate-media

export default function CloudVideoAttachment(props: CloudAttachmentProps) {
  const { children, element } = props
  const { focused, selected } = useCloudAttachmentElementState({
    element,
  })

  return (
    <PlateElement {...props}>
      <Box
        contentEditable={false}
        sx={{
          userSelect: "none",
          "> video": {
            maxHeight: "400px",
            maxWidth: "100%",
            outline: (theme) =>
              focused && selected
                ? `2px solid ${theme.palette.primary[300]}`
                : undefined,
            m: 0,
            my: 1,
            display: "block",
            borderRadius: 1,
          },
        }}
        draggable
      >
        <video
          onContextMenu={(event) => event.preventDefault()}
          controls
          disablePictureInPicture
          controlsList="nodownload noplaybackrate"
          onClick={(e) => e.stopPropagation()}
        >
          {/* t=0.01 to force webkit to display a preview */}
          <source src={`${element.url}#t=0.01`} />
        </video>
      </Box>
      {children}
    </PlateElement>
  )
}
