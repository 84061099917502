import styled from "styled-components"

export const firstColumnWidth = "450px"

interface MatrixGridProps {
  $nbColumns: number
}

const MatrixGrid = styled.div<MatrixGridProps>`
  display: grid;
  grid-template-columns: ${firstColumnWidth} 30px 30px repeat(
      ${(props) => props.$nbColumns},
      230px
    );
  grid-column-gap: ${({ theme }) => theme.spacing(1)};
  justify-items: center;
  align-items: center;
`

export default MatrixGrid
