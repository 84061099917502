import { useEffect } from "react"
import { FormattedMessage } from "react-intl"

import { apiCreateLog } from "api/logs"

import ControlledError from "ds/ControlledError"
import Loader from "ds/Loader"
import { useIsSmallerThanMD } from "ds/Media"
import Modal from "ds/Modal"
import UncontrolledError from "ds/UncontrolledError"

import { usePlaybook } from "../PlaybookProvider"
import { useArgumentQuery } from "../queries/argumentQueries"
import ArgumentPage from "./ArgumentPage"

interface Props {
  argumentId: string
  onClose: () => void
  forceSingleColumnLayout?: boolean
}

export default function ArgumentModal({
  argumentId,
  onClose,
  forceSingleColumnLayout = false,
}: Props) {
  const { playbook } = usePlaybook()
  const argumentQuery = useArgumentQuery(argumentId)
  const isSmallerThanMD = useIsSmallerThanMD()
  const singleColumnLayout = forceSingleColumnLayout || isSmallerThanMD

  useEffect(() => {
    apiCreateLog({
      action: "VISIT_ARGUMENT_PAGE",
      countsAsActivity: true,
      playbookId: playbook.id,
    })
  }, [argumentId, playbook.id])

  if (argumentQuery.isLoading) return <Loader />
  if (argumentQuery.isError)
    return <UncontrolledError error={argumentQuery.error} />

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      data-testid="ArgumentModal"
      heightVariant="big"
      widthVariant={isSmallerThanMD ? "big" : "fitContent"}
      hideClosingCross
      noPadding
    >
      {argumentQuery.data ? (
        <ArgumentPage
          argument={argumentQuery.data}
          onClose={onClose}
          singleColumnLayout={singleColumnLayout}
        />
      ) : (
        <ControlledError>
          <FormattedMessage
            id="arguments.notFound"
            defaultMessage="We could not find this talking point 🤔"
          />
        </ControlledError>
      )}
    </Modal>
  )
}
