import { DirectUpload } from "@rails/activestorage"

import {
  ActiveStorageBlob,
  apiGetPresignedUrl,
  directUploadUrl,
} from "api/activestorage"

import { GenericFileHandlers } from "../cloud/cloud"
import { alertMaxSizeExceeded, belowSizeLimit } from "./sizeLimit"

export default function uploadFileDirectly(
  file: any,
  id: string,
  handlers: GenericFileHandlers,
  uploadsHost: string
) {
  if (!belowSizeLimit(file)) return alertMaxSizeExceeded()

  const upload = new DirectUpload(file, directUploadUrl, {
    directUploadWillStoreFileWithXHR(request: XMLHttpRequest) {
      request.upload.onloadstart = () => {
        handlers.onStart?.({
          type: "generic",
          id,
          file,
          url: id,
        })
      }
      request.upload.addEventListener("progress", (event) => {
        handlers.onProgress?.({
          sentBytes: event.loaded,
          totalBytes: event.total,
          type: "generic",
          id,
          file,
          url: id,
        })
      })
    },
  })

  // The current API of DirectUpload does not allow specifying the type of the blob, so we cast
  upload.create((err, untypedBlob) => {
    const blob = untypedBlob as ActiveStorageBlob

    if (err) {
      handlers.onError?.({
        type: "generic",
        file,
        id,
        message: err.message,
        url: id,
      })
    } else {
      const rawUrl = `${uploadsHost}/${encodeURI(blob.key)}`
      apiGetPresignedUrl(rawUrl).then((presignedUrl) => {
        handlers.onSuccess?.({
          type: "generic",
          file,
          id,
          url: presignedUrl,
        })
      })
    }
  })
}
