import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import MenuItem from "ds/MenuItem"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentsRestoreMutation } from "components/App/Playbook/queries/argumentQueries"

interface Props {
  argument: IArgument
  onSuccess: () => void
}

export default function ArgumentRestore({ argument, onSuccess }: Props) {
  const { activeTypeId } = usePlaybook()
  const restoreMutation = useArgumentsRestoreMutation(activeTypeId)

  const onClick = () => restoreMutation.mutate([argument.id], { onSuccess })

  return (
    <MenuItem
      onClick={onClick}
      disabled={restoreMutation.isLoading}
      sx={{ color: "error.main" }}
    >
      <FormattedMessage
        id="playbookEdit.argument.restore"
        defaultMessage="Restore"
      />
    </MenuItem>
  )
}
