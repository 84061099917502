import Typography from "ds/Typography"

interface Props {
  title: React.ReactNode
  description: React.ReactNode
}

export default function EmailNotificationRadioLabel({
  title,
  description,
}: Props) {
  return (
    <div>
      <div>
        <Typography variant="smNormal">{title}</Typography>
      </div>
      <div>
        <Typography
          variant="smNormal"
          color={(theme) => theme.palette.text.secondary}
        >
          {description}
        </Typography>
      </div>
    </div>
  )
}
