import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { useIntl } from "react-intl"

import { IArgumentField } from "api/types/argumentFields"
import { IArgument } from "api/types/arguments"

import IconButton from "ds/IconButton"
import Tooltip from "ds/Tooltip"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentFieldValueUpdateMutation } from "components/App/Playbook/queries/argumentQueries"

import useConfirm from "utils/hooks/useConfirm"

interface Props {
  argument: IArgument
  argumentField: IArgumentField
  afterDelete: () => void
}

export default function DeleteArgumentFieldValue({
  argument,
  argumentField,
  afterDelete,
}: Props) {
  const { activeTypeId } = usePlaybook()
  const updateMutation = useArgumentFieldValueUpdateMutation(activeTypeId)
  const confirm = useConfirm()
  const intl = useIntl()

  const onClick = () => {
    if (!confirm()) return

    updateMutation.mutate(
      {
        argumentId: argument.id,
        argumentFieldId: argumentField.id,
        value: null,
        rawValue: null,
      },
      { onSuccess: () => afterDelete() }
    )
  }

  const deleteLabel = intl.formatMessage({
    id: "playbookEdit.argument.argumentFields.delete",
  })

  return (
    <Tooltip title={deleteLabel}>
      <IconButton
        disabled={updateMutation.isLoading}
        onClick={onClick}
        color="error"
      >
        <DeleteOutlineIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}
