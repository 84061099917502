import { useAuth0 } from "@auth0/auth0-react"

import EmailNotVerified from "authentication/EmailNotVerified"
import { setGetToken } from "authentication/globalGetToken"

import Loader from "ds/Loader"

import TriggerLogin from "components/TriggerLogin"

interface Props {
  children: JSX.Element
}

export default function WebAppLoginGuard(props: Props) {
  const {
    getAccessTokenSilently,
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    user,
  } = useAuth0()

  setGetToken(getAccessTokenSilently) // Hack to set global function that retrieves JWT token

  if (isLoading) {
    return <Loader />
  }

  if (isAuthenticated && user && user.email && !user.email_verified) {
    return <EmailNotVerified email={user.email} />
  }

  if (isAuthenticated && user) {
    return props.children
  }

  return <TriggerLogin triggerLogin={loginWithRedirect} />
}
