import MenuIcon from "@mui/icons-material/Menu"

import IconButton from "ds/IconButton"

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

// TODO Add aria-label
export default function NavOpener({ onClick }: Props) {
  return (
    <IconButton onClick={onClick}>
      <MenuIcon
        fontSize="small"
        sx={{ fill: (theme) => theme.palette.common.white }}
      />
    </IconButton>
  )
}
