import { Box } from "@mui/material"
import { Dictionary } from "lodash"
import { FormattedMessage, useIntl } from "react-intl"

import { IPlaybook } from "api/types/playbooks"

import Alert from "ds/Alert"
import ClosingCross from "ds/Modal/ClosingCross"
import SearchInput from "ds/SearchInput"
import Stack from "ds/Stack"

import ArgumentSegmentations from "components/App/Playbook/ArgumentSegmentations"

import { filterNonArchived } from "services/archivable"
import {
  filterArgumentSearchResults,
  getNbArgumentSearchResultsBySegment,
} from "services/arguments"

import SearchResults from "./SearchResults"
import useArgumentSearch from "./useArgumentSearch"
import useArgumentSearchSegmentFilters from "./useArgumentSearchSegmentFilters"

interface Props {
  playbook: IPlaybook
  initialSegmentFilters: Dictionary<string[]>
  queryParam: string
  setQueryParam: (newQueryParam: string) => void
  onClose: () => void
}

export default function SearchModalContent({
  playbook,
  initialSegmentFilters,
  queryParam,
  setQueryParam,
  onClose,
}: Props) {
  const intl = useIntl()
  const [segmentFilters, pickSegment] = useArgumentSearchSegmentFilters(
    initialSegmentFilters
  )
  const { results, isSearching } = useArgumentSearch(playbook.id, queryParam)

  const filteredResults = filterArgumentSearchResults(
    results,
    segmentFilters,
    playbook
  )

  const nbArgumentsBySegment = getNbArgumentSearchResultsBySegment(
    results,
    segmentFilters,
    playbook
  )

  const nbResultsTotal = results.length
  const nbResultsFiltered = filteredResults.length
  const nbResultsHidden = nbResultsTotal - nbResultsFiltered

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box flexGrow={1}>
          <SearchInput
            value={queryParam}
            onChange={(e) => setQueryParam(e.target.value)}
            placeholder={intl.formatMessage({
              id: "playbook.search.placeholder",
              defaultMessage: "Search your Playbook",
            })}
            clear={() => setQueryParam("")}
          />
        </Box>

        <Box>
          <ClosingCross onClick={onClose} />
        </Box>
      </Stack>

      <ArgumentSegmentations
        argumentSegmentations={filterNonArchived(
          playbook.argumentSegmentations
        )}
        segmentFilters={segmentFilters}
        onPickArgumentSegment={pickSegment}
        nbArgumentsBySegment={nbArgumentsBySegment}
      />

      {nbResultsHidden > 0 && (
        <Alert severity="warning">
          <FormattedMessage
            id="playbook.search.results.hidden"
            defaultMessage="Hidden result(s)"
            values={{ count: nbResultsHidden }}
          />
        </Alert>
      )}

      {!isSearching && queryParam.length > 0 && (
        <SearchResults results={filteredResults} />
      )}
    </Stack>
  )
}
