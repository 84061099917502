import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material/Checkbox"
import { forwardRef } from "react"

export type CheckboxProps = {
  checked?: MuiCheckboxProps["checked"]
  onChange?: MuiCheckboxProps["onChange"]
  onMouseDown?: MuiCheckboxProps["onMouseDown"]
  disabled?: MuiCheckboxProps["disabled"]
  className?: MuiCheckboxProps["className"]
  inputProps?: MuiCheckboxProps["inputProps"]
  sx?: MuiCheckboxProps["sx"]
}

export default forwardRef(function Checkbox(
  props: CheckboxProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return <MuiCheckbox size="small" {...props} ref={ref} />
})
