import { Box } from "@mui/material"
import { truncate } from "lodash"
import { useEffect, useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import styled from "styled-components"

import { IArgumentCommentCategory } from "api/types/argumentComments"
import { IArgument } from "api/types/arguments"
import { IUser } from "api/types/users"

import Loader from "ds/Loader"
import { MyValue } from "ds/RichTextNew/types"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import UserMention from "components/App/User/UserMention"

import { filterComments } from "services/argumentComments"
import { getRawTitle } from "services/argumentFields"

import ArgumentKindIndicator from "../ArgumentCard/ArgumentKindIndicator/ArgumentKindIndicator"
import CategorySelector from "./CategorySelector"
import CommentForm from "./CommentForm"
import Comments from "./Comments"
import ResolvedFilter from "./ResolvedFilter"
import {
  useArgumentCommentCreateMutation,
  useArgumentCommentsQuery,
} from "./argumentCommentQueries"

const ScrollContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  // Scroll to the bottom by default
  display: flex;
  min-height: 0;
  flex-direction: column-reverse;
`

interface Props {
  argument: IArgument
  backButton: React.ReactNode
  showArgumentTitle: boolean
}

export default function CommentsPanel({
  argument,
  backButton,
  showArgumentTitle,
}: Props) {
  const { playbook, readOnly } = usePlaybook()
  const commentsQuery = useArgumentCommentsQuery(argument.id, playbook.id)
  const createMutation = useArgumentCommentCreateMutation(argument.id)
  const [categoryFilter, setCategoryFilter] =
    useState<IArgumentCommentCategory | null>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [lastAddedCommentAddedAt, setLastCommentAddedAt] =
    useState<Date | null>(null)
  const [showResolvedComments, setShowResolvedComments] = useState(false)
  const { users } = usePlaybook()
  const intl = useIntl()
  const argumentTitle =
    getRawTitle(argument) ||
    intl.formatMessage({
      id: "playbookEdit.argument.defaultTitle",
      defaultMessage: "Title",
    })

  const richTextProps = {
    mentionees: users,
    renderMention(user: IUser, props: React.HTMLAttributes<HTMLSpanElement>) {
      return <UserMention user={user} {...props} />
    },
  }

  // Scroll down the comments tab when adding a comment
  useEffect(() => {
    scrollContainerRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [lastAddedCommentAddedAt])

  if (commentsQuery.isLoading) return <Loader />
  if (commentsQuery.error)
    return <UncontrolledError error={commentsQuery.error} />

  async function createArgumentComment(
    argumentId: string,
    value: MyValue,
    rawValue: string,
    category: string | null
  ) {
    await createMutation.mutateAsync(
      {
        argumentId,
        value,
        rawValue,
        category,
      },
      { onSuccess: () => setLastCommentAddedAt(new Date()) }
    )
  }

  const filteredComments = filterComments(commentsQuery.data || [], {
    category: categoryFilter,
    showResolved: showResolvedComments,
  })

  const categoryFilterLabel = (
    <FormattedMessage
      id="argumentComments.category.filterLabel"
      defaultMessage="Filter by"
    />
  )

  const categoryFilterNullLabel = (
    <FormattedMessage id="argumentComments.category.all" defaultMessage="All" />
  )

  return (
    <Stack height="100%">
      <Stack spacing={1} direction="row" alignItems="center" p={2}>
        {backButton}
        <Typography variant="lgSemi">
          <FormattedMessage id="argumentComments.title" />
        </Typography>
      </Stack>

      <Stack flexGrow={1} minHeight={0}>
        <Stack spacing={1} direction="row" alignItems="center" px={2}>
          <CategorySelector
            id={`category-filter-${argument.id}`}
            value={categoryFilter}
            onChange={setCategoryFilter}
            label={categoryFilterLabel}
            nullLabel={categoryFilterNullLabel}
          />

          <ResolvedFilter
            showResolvedComments={showResolvedComments}
            toggleResolvedArgumentComments={() =>
              setShowResolvedComments((prev) => !prev)
            }
          />
        </Stack>

        {showArgumentTitle && (
          <Box px={2} mt={2}>
            <Stack
              sx={{
                p: 2,
                backgroundColor: (theme) => theme.palette.common.white,
                borderRadius: 1,
                boxShadow: 6,
              }}
              title={argumentTitle}
              direction="row"
              alignItems="start"
              spacing={2}
            >
              <ArgumentKindIndicator argument={argument} />

              <Typography variant="baseBold">
                {truncate(argumentTitle, { length: 80 })}
              </Typography>
            </Stack>
          </Box>
        )}

        <ScrollContainer ref={scrollContainerRef}>
          <Comments comments={filteredComments} richTextProps={richTextProps} />
        </ScrollContainer>

        <Box p={2}>
          <CommentForm
            categorySelectorId={`comment-form-category-argument-${argument.id}`}
            resourceId={argument.id}
            onSubmit={createArgumentComment}
            isSubmitting={createMutation.isLoading}
            richTextProps={richTextProps}
            disabled={readOnly}
          />
        </Box>
      </Stack>
    </Stack>
  )
}
