import { useState } from "react"
import { FormattedDate, FormattedMessage } from "react-intl"

import { apiPullCRMDeal, apiSearchCRMDeals } from "api/crmDeals"
import { ICRMDeal, ICRMDealSearchResult } from "api/types/CRMDeals"

import AsyncAutocomplete, { AsyncAutocompleteProps } from "ds/AsyncAutocomplete"
import { AlertSnackbar } from "ds/Snackbar"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

function InputLabel() {
  return <FormattedMessage id="meetings.deal" />
}

export interface CRMDealSelectorProps
  extends Omit<
    AsyncAutocompleteProps<ICRMDealSearchResult, false, false, false>,
    "getOptions" | "onChange" | "inputLabel"
  > {
  value: ICRMDealSearchResult | null
  businessUnitId: string
  externalCompanyId: string | null
  inputLabel?: React.ReactNode
  onChange: (newValue: ICRMDeal | null) => void
}

export default function CRMDealSelector({
  value,
  businessUnitId,
  externalCompanyId,
  onChange,
  inputLabel = <InputLabel />,
  ...props
}: CRMDealSelectorProps) {
  const [searchErrorSnackOpen, setSearchErrorSnackOpen] = useState(false)
  const [pullErrorSnackOpen, setPullErrorSnackOpen] = useState(false)

  const onChangeAutocomplete = async (
    newValue: ICRMDealSearchResult | null
  ) => {
    if (!newValue) return onChange(null)

    try {
      const { crmDeal } = await apiPullCRMDeal({
        businessUnitId,
        externalId: newValue.id,
      })
      return onChange(crmDeal || null)
    } catch {
      setPullErrorSnackOpen(true)
    }
  }

  return (
    <>
      <AsyncAutocomplete
        value={value}
        inputLabel={inputLabel}
        multiple={false}
        getOptions={(name) =>
          apiSearchCRMDeals({
            businessUnitId,
            name,
            externalCompanyId,
          })
        }
        onGetOptionsFailure={() => setSearchErrorSnackOpen(true)}
        getOptionLabel={(option) => option.name}
        onChange={(_event, newValue) => onChangeAutocomplete(newValue)}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              minWidth={0}
              width="100%"
              spacing={1}
            >
              <Typography
                variant="smNormal"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {option.name}
              </Typography>
              <Typography
                variant="xsNormal"
                whiteSpace="nowrap"
                overflow="visible"
              >
                <FormattedDate value={option.createdAt} />
              </Typography>
            </Stack>
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        {...props}
      />

      <AlertSnackbar
        severity="error"
        open={searchErrorSnackOpen}
        onClose={() => setSearchErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbook.meeting.information.crmDeal.search.error" />
      </AlertSnackbar>
      <AlertSnackbar
        severity="error"
        open={pullErrorSnackOpen}
        onClose={() => setPullErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbook.meeting.information.crmDeal.pull.error" />
      </AlertSnackbar>
    </>
  )
}
