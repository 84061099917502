import { Box } from "@mui/material"
import { PlateElement } from "@udecode/plate-common"

import { MyPlateElementProps } from "../types"

export default function Blockquote(props: MyPlateElementProps) {
  return (
    <PlateElement asChild {...props}>
      <Box
        component="blockquote"
        sx={{
          borderLeft: "4px solid #ccc",
          m: 0,
          pl: (theme) => theme.spacing(2),
          fontStyle: "italic",
        }}
      >
        {props.children}
      </Box>
    </PlateElement>
  )
}
