import Stack, { StackProps } from "ds/Stack"

import isMobileDevice from "utils/isMobileDevice"

import AttachmentButton from "./Toolbar/AttachmentButton"
import BlockquoteButton from "./Toolbar/BlockquoteButton"
import BoldButton from "./Toolbar/BoldButton"
import EmojiButton from "./Toolbar/Emoji/EmojiButton"
import FontColorButton from "./Toolbar/FontColorButton"
import HighlightButton from "./Toolbar/HighlightButton"
import ItalicButton from "./Toolbar/ItalicButton"
import LinkButton from "./Toolbar/LinkButton"
import OrderedListButton from "./Toolbar/OrderedListButton"
import SpeechToTextButton from "./Toolbar/SpeechToText/SpeechToTextButton"
import TodoListButton from "./Toolbar/TodoListButton"
import ToggleButton from "./Toolbar/ToggleButton"
import ToolbarDivider from "./Toolbar/ToolbarDivider"
import UnderlineButton from "./Toolbar/UnderlineButton"
import UnorderedListButton from "./Toolbar/UnorderedListButton"

export interface ToolbarProps {
  containerProps?: Partial<StackProps>
  disabled?: boolean
  highlightSpeechToText?: boolean
}

export default function Toolbar({
  disabled = false,
  containerProps = {},
  highlightSpeechToText = false,
}: ToolbarProps) {
  const isMobile = isMobileDevice()
  const divider = <ToolbarDivider />

  return (
    <Stack
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      useFlexGap
      {...containerProps}
      sx={{ cursor: "default", ...containerProps.sx }}
    >
      <BoldButton disabled={disabled} />
      <ItalicButton disabled={disabled} />
      <UnderlineButton disabled={disabled} />
      <FontColorButton disabled={disabled} />
      <HighlightButton disabled={disabled} />
      {divider}
      <BlockquoteButton disabled={disabled} />
      <OrderedListButton disabled={disabled} />
      <UnorderedListButton disabled={disabled} />
      <TodoListButton disabled={disabled} />
      {divider}
      <LinkButton disabled={disabled} />
      <ToggleButton disabled={disabled} />
      {!isMobile && <EmojiButton disabled={disabled} />}
      <AttachmentButton disabled={disabled} />
      <SpeechToTextButton
        disabled={disabled}
        highlight={highlightSpeechToText}
      />
    </Stack>
  )
}
