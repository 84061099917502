import FormatQuoteIcon from "@mui/icons-material/FormatQuote"
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate"
import { useIntl } from "react-intl"

import {
  useBlockToolbarButton,
  useBlockToolbarButtonState,
} from "ds/RichTextNew/helpers"

import { space } from "../../hotkeys"
import PillIconButton from "./PillIconButton"

interface Props {
  disabled: boolean
}

export default function BlockquoteButton({ disabled }: Props) {
  const intl = useIntl()

  const state = useBlockToolbarButtonState({ nodeType: ELEMENT_BLOCKQUOTE })
  const { props } = useBlockToolbarButton(state)

  return (
    <PillIconButton
      label={intl.formatMessage({ id: "richtext.blockQuote" })}
      shortcut={["|", space]}
      disabled={disabled}
      {...props}
    >
      <FormatQuoteIcon sx={{ height: "16px", width: "16px" }} />
    </PillIconButton>
  )
}
