import ReplyIcon from "@mui/icons-material/Reply"
import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import Button from "ds/Button"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { getRawTitle } from "services/argumentFields"

import { scrollToElementWithDelay } from "utils/scrollToElement"

import { argumentCardId } from "../../Argument/ArgumentCard"
import AnswerIndicator from "../../Argument/ArgumentCard/ArgumentKindIndicator/AnswerIndicator"
import { usePlaybook } from "../../PlaybookProvider"

interface Props {
  argument: IArgument
  onReplyClick: () => void
}

export default function MissingAnswer({ argument, onReplyClick }: Props) {
  const { viewSettings } = usePlaybook()
  function onClick() {
    onReplyClick()
    argument.argumentGroupId &&
      viewSettings.expandGroups(argument.argumentTypeId, [
        argument.argumentGroupId,
      ])
    scrollToElementWithDelay(argumentCardId(argument.id))
  }

  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <div>
        <Button
          color="primary"
          variant="outlined"
          startIcon={<ReplyIcon />}
          onClick={onClick}
          size="small"
        >
          <FormattedMessage id="common.fill" />
        </Button>
      </div>

      <AnswerIndicator />

      <div>
        <Typography variant="smSemi">
          {getRawTitle(argument) || (
            <FormattedMessage id="playbookEdit.argument.defaultTitle" />
          )}
        </Typography>
      </div>
    </Stack>
  )
}
