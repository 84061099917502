import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import MenuItem from "ds/MenuItem"
import { BasicErrorSnack } from "ds/Snackbar"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentsDuplicateMutation } from "components/App/Playbook/queries/argumentQueries"

interface Props {
  argument: IArgument
  onSuccess: () => void
}

export default function DuplicateArgument({ argument, onSuccess }: Props) {
  const { activeTypeId } = usePlaybook()
  const duplicateMutation = useArgumentsDuplicateMutation(activeTypeId)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const onClick = () =>
    duplicateMutation.mutate([argument.id], {
      onSuccess,
      onError: () => setErrorSnackOpen(true),
    })

  return (
    <>
      <MenuItem onClick={onClick} disabled={duplicateMutation.isLoading}>
        <FormattedMessage
          id="playbookEdit.argument.duplicate"
          defaultMessage="Duplicate"
        />
      </MenuItem>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </>
  )
}
