import axios from "./axios"
import {
  ICRMPropertiesRefreshParams,
  ICRMProperty,
} from "./types/CRMProperties"

export const apiIndexCRMProperties = (
  businessUnitId: string,
  { signal }: { signal?: AbortSignal }
): Promise<ICRMProperty[]> =>
  axios
    .get("api/crm/properties", { params: { businessUnitId }, signal })
    .then((res) => res.data)

export const apiRefreshCRMProperties = ({
  businessUnitId,
}: ICRMPropertiesRefreshParams): Promise<undefined> =>
  axios
    .post("api/crm/properties/refresh", { businessUnitId })
    .then((res) => res.data)
