import { Box } from "@mui/material"
import { PlateElement } from "@udecode/plate-common"

import { MyPlateElementProps } from "../types"

export default function Paragraph(props: MyPlateElementProps) {
  return (
    <PlateElement asChild {...props}>
      <Box>{props.children}</Box>
    </PlateElement>
  )
}
