import MuiPaper, { PaperProps } from "@mui/material/Paper"
import { forwardRef } from "react"

export type { PaperProps }

export default forwardRef(function Paper(
  props: PaperProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiPaper {...props} ref={ref} />
})
