import { Navigate } from "react-router-dom"

import { ICurrentUser } from "api/types/me"
import { IOrganization } from "api/types/organizations"

import useMe from "authentication/useMe"

import SimpleLayout from "components/layouts/SimpleLayout"

import {
  getBusinessUnitPath,
  getValidLastVisitedBusinessUnitId,
} from "services/businessUnits"
import { isOrganizationAdmin } from "services/organizationMemberships"

import NoBusinessUnit from "../BusinessUnit/NoBusinessUnit"
import { useOrganization } from "./OrganizationProvider"

function findActiveBusinessUnitIds(
  user: ICurrentUser,
  organization: IOrganization
) {
  if (user.isSuperAdmin || isOrganizationAdmin(user, organization.id)) {
    return organization.businessUnits
      .filter((businessUnit) => !businessUnit.archived)
      .map(({ id }) => id)
  }

  const activeBusinessUnitIds = user.businessUnitMemberships.map(
    (x) => x.businessUnitId
  )

  return organization.businessUnits
    .filter(
      (businessUnit) =>
        !businessUnit.archived &&
        activeBusinessUnitIds.includes(businessUnit.id)
    )
    .map((businessUnit) => businessUnit.id)
}

export default function OrganizationRoot() {
  const user = useMe()
  const organization = useOrganization()

  const activeBusinessUnitIdsWithinOrg = findActiveBusinessUnitIds(
    user,
    organization
  )

  if (activeBusinessUnitIdsWithinOrg.length === 0) {
    return (
      <SimpleLayout>
        <NoBusinessUnit organizationId={organization.id} />
      </SimpleLayout>
    )
  }

  const lastBusinessUnitId = getValidLastVisitedBusinessUnitId(
    activeBusinessUnitIdsWithinOrg
  )

  if (lastBusinessUnitId) {
    return <Navigate to={getBusinessUnitPath(lastBusinessUnitId)} replace />
  }

  return (
    <Navigate
      to={getBusinessUnitPath(activeBusinessUnitIdsWithinOrg[0])}
      replace
    />
  )
}
