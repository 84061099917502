import { Box } from "@mui/material"
import axios from "axios"
import { useEffect } from "react"
import { FormattedMessage } from "react-intl"

import Alert from "ds/Alert"
import AlertTitle from "ds/AlertTitle"
import Button from "ds/Button"
import Drawer from "ds/Drawer"
import Stack from "ds/Stack"

import { ReactComponent as BadRequestIcon } from "images/errors/bad_request.svg"
import { ReactComponent as ForbiddenIcon } from "images/errors/forbidden.svg"
import { ReactComponent as NotFoundIcon } from "images/errors/not_found.svg"
import { ReactComponent as UnauthorizedIcon } from "images/errors/unauthorized.svg"
import { ReactComponent as UnexpectedIcon } from "images/errors/unexpected.svg"

import CenteringContainer from "./CenteringContainer"

interface UncontrolledErrorProps {
  error: unknown
}

export default function UncontrolledError({ error }: UncontrolledErrorProps) {
  useEffect(() => {
    console.error(error)
  }, [error])

  if (isApiError(error, 400)) return <BadRequestError error={error} />
  if (isApiError(error, 401)) return <UnauthorizedError error={error} />
  if (isApiError(error, 403)) return <ForbiddenError error={error} />
  if (isApiError(error, 404)) return <NotFoundError error={error} />
  return <UnexpectedError error={error} />
}

export function isApiError(error: unknown, status: number) {
  return (
    axios.isAxiosError(error) &&
    !!error.response &&
    !!error.response.status &&
    error.response.status === status
  )
}

function DefaultErrorCTA() {
  return (
    <Button onClick={() => window.location.reload()}>
      <FormattedMessage id="common.reload" defaultMessage="Reload" />
    </Button>
  )
}

function ErrorIllustration({
  error,
  ErrorIcon,
  errorText,
  errorCTA,
}: {
  error: unknown
  ErrorIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  errorText: React.ReactNode
  errorCTA?: React.ReactNode
}) {
  return (
    <>
      <CenteringContainer sx={{ bgcolor: "background.paper" }}>
        <Stack spacing={2} alignItems="center">
          <div>
            <ErrorIcon style={{ height: "300px" }} />
          </div>

          <Alert severity="error">{errorText}</Alert>

          <div>{errorCTA || <DefaultErrorCTA />}</div>
        </Stack>
      </CenteringContainer>

      {process.env.NODE_ENV === "development" && (
        <Drawer anchor="bottom" open sx={{ zIndex: 10000 }}>
          <Box
            sx={{
              maxHeight: "200px",
              overflow: "auto",
            }}
          >
            <Alert severity="error">
              <AlertTitle>For developers 👇🏻</AlertTitle>

              <div>
                <code style={{ whiteSpace: "pre-wrap" }}>
                  {JSON.stringify(error, null, 2)}
                </code>
              </div>
            </Alert>
          </Box>
        </Drawer>
      )}
    </>
  )
}

export function BadRequestError({ error }: { error: unknown }) {
  return (
    <ErrorIllustration
      error={error}
      ErrorIcon={BadRequestIcon}
      errorText={
        <FormattedMessage
          id="common.error.bad_request"
          defaultMessage="Bad request"
        />
      }
    />
  )
}

export function UnauthorizedError({ error }: { error: unknown }) {
  return (
    <ErrorIllustration
      error={error}
      ErrorIcon={UnauthorizedIcon}
      errorText={
        <FormattedMessage
          id="common.error.unauthorized"
          defaultMessage="Unauthorized"
        />
      }
    />
  )
}

export function ForbiddenError({
  error,
  errorCTA,
}: {
  error: unknown
  errorCTA?: React.ReactNode
}) {
  return (
    <ErrorIllustration
      error={error}
      ErrorIcon={ForbiddenIcon}
      errorText={
        <FormattedMessage
          id="common.error.forbidden"
          defaultMessage="Forbidden"
        />
      }
      errorCTA={errorCTA}
    />
  )
}

export function NotFoundError({ error }: { error: unknown }) {
  return (
    <ErrorIllustration
      error={error}
      ErrorIcon={NotFoundIcon}
      errorText={
        <FormattedMessage
          id="common.error.not_found"
          defaultMessage="Not found"
        />
      }
    />
  )
}

export function UnexpectedError({ error }: { error: unknown }) {
  return (
    <ErrorIllustration
      error={error}
      ErrorIcon={UnexpectedIcon}
      errorText={
        <FormattedMessage
          id="common.error.unexpected"
          defaultMessage="Unexpected error"
        />
      }
    />
  )
}
