import clamp from "lodash/clamp"
import { useState } from "react"
import styled, { keyframes } from "styled-components"

import { IArgument } from "api/types/arguments"

import { BasicErrorSnack } from "ds/Snackbar"
import Swipeable from "ds/Swipeable"

import { useMeeting } from "components/App/Playbook/Meeting/MeetingProvider"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentVoteMutation } from "components/App/Playbook/queries/argumentQueries"

import { hasVotesEnabled } from "services/arguments"
import { getMeetingVoteOnArgument } from "services/meetings"
import { getVoteCountDelta, getVoteValue } from "services/votes"

import isTouchDevice from "utils/isTouchDevice"

import ArgumentCard from "./ArgumentCard"

const textVibrationAnimation = keyframes`
  50% {
    transform: scale(1.1);
  }
`

const threshold = 75
const SwipeBackground = styled.div`
  transition: 200ms ease-out all 0s;
  position: absolute;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  display: flex;
  align-items: center;
  color: white;

  &.active {
    font-size: 1.5em;
    & > span {
      animation: ${textVibrationAnimation} 300ms ease-in-out forwards;
    }
  }
`

interface SwipeContentProps {
  $deltaX: number
}

const LeftSwipeBackground = styled(SwipeBackground).attrs<SwipeContentProps>(
  ({ $deltaX }) => ({
    style: { opacity: clamp($deltaX / 75, 0, 1) },
  })
)<SwipeContentProps>`
  background-color: ${({ theme }) => theme.palette.success.light};
`

const RightSwipeBackground = styled(SwipeBackground).attrs<SwipeContentProps>(
  ({ $deltaX }) => ({
    style: { opacity: clamp(-$deltaX / 75, 0, 1) },
  })
)<SwipeContentProps>`
  background-color: ${({ theme }) => theme.palette.error.light};
  justify-content: flex-end;
`

interface Props {
  argument: IArgument
  richText?: boolean
}

export default function ArgumentCardWithSwipe({ argument, richText }: Props) {
  const { playbook, activeTypeId, segmentFilters, readOnly } = usePlaybook()
  const argumentVoteMutation = useArgumentVoteMutation(
    playbook.id,
    activeTypeId
  )
  const { ongoingMeeting } = useMeeting()
  const { upvoted: upvotedDuringMeeting, downvoted: downvotedDuringMeeting } =
    getMeetingVoteOnArgument(argument, ongoingMeeting)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  function onVote(direction: "up" | "down") {
    const value = getVoteValue(
      direction,
      upvotedDuringMeeting,
      downvotedDuringMeeting
    )
    const delta = getVoteCountDelta(
      direction,
      upvotedDuringMeeting,
      downvotedDuringMeeting
    )
    argumentVoteMutation.mutate(
      {
        meetingId: ongoingMeeting?.id,
        argumentId: argument.id,
        count: value,
        segmentIds: Object.values(segmentFilters).flat(),
        delta,
      },
      { onError: () => setErrorSnackOpen(true) }
    )
  }

  function onArgumentSwipe(swipeDirection: "left" | "right") {
    onVote(swipeDirection === "left" ? "up" : "down")
  }

  const card = <ArgumentCard argument={argument} richText={richText} />

  if (!hasVotesEnabled(argument) || !isTouchDevice() || readOnly) {
    return card
  }

  return (
    <>
      <Swipeable
        threshold={threshold}
        left={(deltaX, active) => (
          <LeftSwipeBackground
            $deltaX={deltaX}
            className={active ? "active" : ""}
          >
            <span>+1</span>
          </LeftSwipeBackground>
        )}
        right={(deltaX, active) => (
          <RightSwipeBackground
            $deltaX={deltaX}
            className={active ? "active" : ""}
          >
            <span>-1</span>
          </RightSwipeBackground>
        )}
        onSwipe={(direction) => onArgumentSwipe(direction)}
      >
        {card}
      </Swipeable>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </>
  )
}
