import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import { ICRMProperty } from "api/types/CRMProperties"

import Tooltip from "ds/Tooltip"

import IntegrationIcon from "components/common/IntegrationIcon"

interface Props {
  crmProperty: ICRMProperty
}

export default function CRMPropertyTooltip({ crmProperty }: Props) {
  return (
    <Tooltip
      title={
        <FormattedMessage
          id="playbook.meeting.argument.answer.crmHint"
          values={{
            integrationName: crmProperty.integrationName,
            objectType: crmProperty.objectType,
            propertyName: crmProperty.name,
          }}
        />
      }
      placement="top-start"
    >
      <Box display="flex">
        <IntegrationIcon integrationName={crmProperty.integrationName} />
      </Box>
    </Tooltip>
  )
}
