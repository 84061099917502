import { IMeetingArgumentNoteMultiSelect } from "api/types/meetingArgumentNotes"
import {
  IMeetingNoteConfiguration,
  IMeetingNoteConfigurationOption,
} from "api/types/meetingNoteConfigurations"

import SelectForm from "./SelectForm"

interface Props {
  note: IMeetingArgumentNoteMultiSelect
  onChange: (newValue: IMeetingNoteConfigurationOption[]) => void
  onAdditionalInfoChange: (newAdditionalInfo: string) => void
  configuration: IMeetingNoteConfiguration
  isSaved: boolean
  disabled: boolean
}

export default function MultiSelectForm({
  note,
  onChange,
  onAdditionalInfoChange,
  configuration,
  isSaved,
  disabled,
}: Props) {
  const { hasAdditionalInfo, hasOptionOther, options } = configuration

  return (
    <SelectForm
      multiple
      value={note.value}
      onChange={(_e, newValue) => {
        const formattedNewValue = newValue.map((element) => {
          if (typeof element === "string") {
            // User input another option
            return { label: element, value: element }
          }
          return element
        })
        onChange(formattedNewValue)
      }}
      hasAdditionalInfo={hasAdditionalInfo}
      additionalInfo={note.additionalInfo || ""}
      onAdditionalInfoChange={onAdditionalInfoChange}
      options={options}
      isSaved={isSaved}
      creatable={hasOptionOther}
      disabled={disabled}
    />
  )
}
