import { DraggableProvided } from "@hello-pangea/dnd"
import DeleteIcon from "@mui/icons-material/DeleteOutline"
import FlagIcon from "@mui/icons-material/Flag"
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined"
import InputAdornment from "@mui/material/InputAdornment"
import { useState } from "react"
import { createPortal } from "react-dom"
import { useIntl } from "react-intl"
import { useDebouncedCallback } from "use-debounce"

import { IArgumentSegment } from "api/types/argumentSegments"

import DragAndDropHandle from "ds/DragAndDropHandle"
import IconButton from "ds/IconButton"
import { BasicErrorSnack } from "ds/Snackbar"
import TextField from "ds/TextField"
import Tooltip from "ds/Tooltip"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import {
  useArgumentSegmentDeleteMutation,
  useArgumentSegmentUpdateMutation,
} from "components/App/Playbook/queries/argumentSegmentQueries"

import useConfirm from "utils/hooks/useConfirm"

import ArgumentSegmentColorIndicator from "./ArgumentSegmentColorIndicator"

// Portal allows using Drag and Drop inside a modal
const portal = document.createElement("div")
document.body.appendChild(portal)

const debounceWaitMs = 250

interface Props {
  argumentSegmentationId: string
  argumentSegment: IArgumentSegment
  inputRef: React.RefObject<HTMLInputElement> | null
  providedDraggable: DraggableProvided
  isDragging: boolean
  disabled?: boolean
  onClickDefaultSegment: () => void
  isDefaultSegment: boolean
}

export default function ArgumentSegmentInput({
  argumentSegmentationId,
  argumentSegment,
  inputRef,
  providedDraggable,
  isDragging,
  disabled,
  onClickDefaultSegment,
  isDefaultSegment,
}: Props) {
  const intl = useIntl()
  const initialName = argumentSegment.name || ""
  const [name, setName] = useState(initialName)
  const { playbook } = usePlaybook()
  const updateMutation = useArgumentSegmentUpdateMutation(
    playbook.id,
    argumentSegmentationId
  )
  const deleteMutation = useArgumentSegmentDeleteMutation(
    playbook.id,
    argumentSegmentationId
  )
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const confirm = useConfirm()

  function submit(name: string) {
    updateMutation.mutate(
      { id: argumentSegment.id, name },
      { onError: () => setErrorSnackOpen(true) }
    )
  }
  const debouncedDubmit = useDebouncedCallback(submit, debounceWaitMs)

  function setNameAndSubmit(name: string) {
    setName(name)
    debouncedDubmit(name)
  }

  const defaultSegmentToggle = (
    <Tooltip
      title={intl.formatMessage({
        id: "playbookEdit.argumentSegmentations.defaultSegment.tooltip",
      })}
    >
      <IconButton
        color={isDefaultSegment ? "success" : "default"}
        opacityTransitionOnHover={!isDefaultSegment}
        onClick={() => onClickDefaultSegment()}
        aria-pressed={isDefaultSegment}
      >
        {isDefaultSegment ? (
          <FlagIcon fontSize="small" />
        ) : (
          <FlagOutlinedIcon fontSize="small" />
        )}
      </IconButton>
    </Tooltip>
  )

  const onDelete = () => {
    if (!confirm()) return
    deleteMutation.mutate(argumentSegment.id)
  }

  const deleteCTA = (
    <IconButton
      aria-label={intl.formatMessage({
        id: "playbookEdit.argumentSegmentations.segments.delete",
        defaultMessage: "Delete",
      })}
      color="error"
      opacityTransitionOnHover
      onClick={onDelete}
      disabled={disabled || deleteMutation.isLoading}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  )

  const startAdornment = (
    <InputAdornment position="start">
      <DragAndDropHandle
        disabled={disabled}
        dragHandleProps={providedDraggable.dragHandleProps}
      />
      <ArgumentSegmentColorIndicator argumentSegment={argumentSegment} />
    </InputAdornment>
  )

  const endAdornment = (
    <InputAdornment position="end">
      {defaultSegmentToggle}
      {deleteCTA}
    </InputAdornment>
  )

  const node = (
    <>
      <TextField
        ref={providedDraggable.innerRef}
        id={`argument-segment-name-${argumentSegment.id}`}
        sx={{ bgcolor: "background.paper" }}
        value={name}
        onChange={(e) => setNameAndSubmit(e.target.value)}
        size="small"
        InputProps={{ startAdornment, endAdornment }}
        onFocus={(e) => e.target.select()}
        inputRef={inputRef}
        disabled={disabled}
        {...providedDraggable.draggableProps}
      />

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </>
  )

  if (!isDragging) {
    return node
  }

  return createPortal(node, portal)
}
