export const MEETING_SYNCED_ENTITY = "MEETING"
export const CALL_SYNCED_ENTITY = "CALL"

export const SYNCED_INTEGRATION_TYPES = [
  MEETING_SYNCED_ENTITY,
  CALL_SYNCED_ENTITY,
] as const

export type ISyncedEntityType = typeof SYNCED_INTEGRATION_TYPES[number]

export interface IIntegration {
  id: string
  status: "in_progress" | "success" | "failure" | "revoked"
  name: string
  workspaceId: string
  syncedEntityType: ISyncedEntityType
  isSyncedEntityTypeForced: boolean
  personAccounts: boolean
}

export interface ICreateIntegrationParams {
  businessUnitId: string
  name: string
  sandbox: boolean
  authentication: {
    authorizationCode: string
  }
}

export interface IGetCurrentIntegrationParams {
  businessUnitId: string
}
