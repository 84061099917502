import React, { useEffect } from "react"

function eventTargetIsOutsideRef(
  ref: React.MutableRefObject<Element | null>,
  event: MouseEvent | TouchEvent
): boolean {
  return (
    !!ref.current &&
    event.target instanceof Element &&
    !ref.current.contains(event.target)
  )
}

const useOnClickOutside = (
  ref: React.MutableRefObject<Element | null>,
  handler: (event: MouseEvent | TouchEvent) => void
): void => {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent): void => {
        if (eventTargetIsOutsideRef(ref, event)) {
          handler(event)
        }
      }
      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)
      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

export default useOnClickOutside
