import { useState } from "react"
import { FormattedMessage } from "react-intl"

import useMe, { useMeMutation } from "authentication/useMe"

import Button from "ds/Button"
import Stack from "ds/Stack"
import TextField from "ds/TextField"
import Typography from "ds/Typography"

export default function EditName() {
  const user = useMe()
  const meMutation = useMeMutation()
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(user.name || "")
  const isDirty = value !== (user.name || "")
  const isValid = !!value

  const nameLabel = <FormattedMessage id="profile.name" defaultMessage="Name" />
  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    meMutation.mutate({ name: value }, { onSuccess: () => setIsEditing(false) })
  }

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="smSemi">
          <Typography variant="smSemi">{nameLabel}</Typography>
        </Typography>

        <Button
          onClick={() => setIsEditing(true)}
          disabled={isEditing}
          variant="text"
        >
          <FormattedMessage
            id="profile.name.edit"
            defaultMessage="Change name"
          />
        </Button>
      </Stack>

      {!isEditing ? (
        <Typography variant="smNormal">{user.name || "-"}</Typography>
      ) : (
        <form onSubmit={onSubmit}>
          <Stack spacing={1}>
            <TextField
              id="my-profile-name"
              value={value}
              size="small"
              label={nameLabel}
              onChange={(e) => setValue(e.target.value)}
            />

            <Stack spacing={1} direction="row">
              <Button
                size="small"
                variant="outlined"
                onClick={() => setIsEditing(false)}
              >
                <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
              </Button>

              <Button
                size="small"
                variant="contained"
                color="success"
                type="submit"
                disabled={!isValid || !isDirty || meMutation.isLoading}
              >
                <FormattedMessage id="common.submit" defaultMessage="Submit" />
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
    </Stack>
  )
}
