import truncate from "lodash/truncate"
import ReactLinkify from "react-linkify"

function buildComponentDecorator(truncateLength: number) {
  return (decoratedHref: string, decoratedText: string, key: number) => (
    // Hack to allow nested <a> tags. Remove once we removed the nesting
    <object key={key}>
      <a
        target="_blank"
        href={decoratedHref}
        onClick={(e) => e.stopPropagation()}
        rel="noreferrer"
      >
        {truncateLength
          ? truncate(decoratedText, { length: truncateLength })
          : decoratedText}
      </a>
    </object>
  )
}

interface LinkifyProps {
  children: React.ReactNode
  truncateLength?: number
}

export default function Linkify({
  children,
  truncateLength = 50,
}: LinkifyProps) {
  return (
    <ReactLinkify componentDecorator={buildComponentDecorator(truncateLength)}>
      {children}
    </ReactLinkify>
  )
}
