import { ComboboxItemProps, ComboboxProps } from "@udecode/plate-combobox"
import { getPluginOptions, useEditorRef } from "@udecode/plate-common"
import {
  ELEMENT_MENTION,
  MentionPlugin,
  getMentionOnSelectItem,
} from "@udecode/plate-mention"
import { deburr } from "lodash"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"

import { IUser } from "api/types/users"

import Stack from "ds/Stack"
import Typography from "ds/Typography"

import Avatar from "components/common/Avatar"

import { MENTION_ALL, MentionItemData } from "../types"
import Combobox from "./Combobox"

export function MentionCombobox({
  pluginKey = ELEMENT_MENTION,
  id = pluginKey,
  ...props
}: Partial<ComboboxProps<MentionItemData>> & {
  pluginKey?: string
}) {
  const editor = useEditorRef()
  const intl = useIntl()

  const { trigger } = getPluginOptions<MentionPlugin>(editor, pluginKey)

  return (
    <Combobox<MentionItemData>
      onRenderItem={onRenderItem}
      id={id}
      trigger={trigger!}
      controlled
      onSelectItem={getMentionOnSelectItem<MentionItemData>({
        key: pluginKey,
      })}
      filter={(search) => (item) =>
        item.data === MENTION_ALL
          ? matchesAllItem(search, intl)
          : filter(search, item.data)}
      {...props}
    />
  )
}

function filter(search: string, user: IUser): boolean {
  return (
    matches(search, user.handle) ||
    matches(search, user.name || "") ||
    matches(search, user.email)
  )
}

function matchesAllItem(search: string, intl: IntlShape) {
  return matches(
    search,
    intl.formatMessage({ id: "richtext.mention.all.title" })
  )
}

function matches(search: string, string: string) {
  return normalize(string).includes(normalize(search))
}

function normalize(string: string): string {
  return deburr(string.toLowerCase())
}

function onRenderItem(props: ComboboxItemProps<MentionItemData>) {
  if (props.item.data === MENTION_ALL) {
    return (
      <Typography component="span" variant="smSemi">
        <FormattedMessage id="richtext.mention.all.title" />
        {" - "}
        <FormattedMessage id="richtext.mention.all.explanation" />
      </Typography>
    )
  }

  const user = props.item.data

  return (
    <Stack
      component="span"
      spacing={1}
      direction="row"
      alignItems="center"
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
    >
      <Avatar
        userName={user.name}
        userEmail={user.email}
        userAvatarUrl={user.thumbnailAvatarUrl}
        userDefaultAvatarColorCode={user.defaultAvatarColorCode}
        sx={{ width: 30, height: 30, fontSize: "1rem" }}
      />
      <Typography component="span" variant="smSemi">
        {user.handle}
      </Typography>
      <Typography component="span" variant="smNormal">
        {user.name || user.email}
      </Typography>
    </Stack>
  )
}
