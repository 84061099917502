import { FormattedMessage } from "react-intl"

import { IArgumentField } from "api/types/argumentFields"

interface Props {
  argumentField: IArgumentField
}

export default function ArgumentFieldName({ argumentField }: Props) {
  if (argumentField.type === "CUSTOM") return <>{argumentField.name}</>
  return (
    <FormattedMessage
      id={`argumentFields.generic.${argumentField.type}`}
      defaultMessage={argumentField.type}
    />
  )
}
