import { useEffect } from "react"
import { useParams } from "react-router-dom"

import Loader from "ds/Loader"
import UncontrolledError from "ds/UncontrolledError"

import { setLastVisitedOrganizationId } from "../Organization/lastVisit"
import BusinessUnitProvider from "./BusinessUnitProvider"
import BusinessUnitRouter from "./BusinessUnitRouter"
import { useBusinessUnitQuery } from "./businessUnitQueries"
import { setLastVisitedBusinessUnitId } from "./lastVisit"

export default function BusinessUnit() {
  const params = useParams()
  const businessUnitId = params.businessUnitId!
  const { isLoading, isError, error, data } =
    useBusinessUnitQuery(businessUnitId)
  useEffect(() => {
    if (data) {
      setLastVisitedBusinessUnitId(data.businessUnit.id)
      setLastVisitedOrganizationId(data.businessUnit.organizationId)
    }
  }, [data])

  if (isLoading) return <Loader />
  if (isError) return <UncontrolledError error={error} />

  return (
    <BusinessUnitProvider businessUnit={data.businessUnit}>
      <BusinessUnitRouter />
    </BusinessUnitProvider>
  )
}
