export function getCurrentUrlCrmInfo(url: string): {
  provider: string | null
  workspaceId: string | null
  contactId: string | null
  companyId: string | null
  dealId: string | null
} {
  if (isHubSpotUrl(url))
    return {
      provider: "hubspot",
      workspaceId: extractWorkspaceIdFromHubSpotUrl(url),
      contactId: extractContactIdFromHubSpotUrl(url),
      companyId: extractCompanyIdFromHubSpotUrl(url),
      dealId: extractDealIdFromHubSpotUrl(url),
    }
  if (isSalesForceUrl(url))
    return {
      provider: "salesforce",
      workspaceId: extractWorkspaceIdFromSalesForceUrl(url),
      contactId: extractContactIdFromSalesForceUrl(url),
      companyId: extractCompanyIdFromSalesForceUrl(url),
      dealId: extractDealIdFromSalesForceUrl(url),
    }
  return {
    provider: null,
    workspaceId: null,
    contactId: null,
    companyId: null,
    dealId: null,
  }
}

function extractContactIdFromHubSpotUrl(url: string) {
  const regexes = [
    /(?:\/record\/0-1\/)([a-zA-Z0-9-]+)/i,
    /(?:\/contact\/)([a-zA-Z0-9]+)/i,
  ]

  const successfulRegex = regexes.find((regex) => url.match(regex))
  if (!successfulRegex) return null
  const match = url.match(successfulRegex)

  return match && match[1]
}

function extractCompanyIdFromHubSpotUrl(url: string) {
  const regexes = [
    /(?:\/record\/0-2\/)([a-zA-Z0-9-]+)/i,
    /(?:\/company\/)([a-zA-Z0-9]+)/i,
  ]

  const successfulRegex = regexes.find((regex) => url.match(regex))
  if (!successfulRegex) return null
  const match = url.match(successfulRegex)

  return match && match[1]
}

function extractDealIdFromHubSpotUrl(url: string) {
  const regexes = [
    /(?:\/record\/0-3\/)([a-zA-Z0-9-]+)/i,
    /(?:\/deal\/)([a-zA-Z0-9]+)/i,
  ]

  const successfulRegex = regexes.find((regex) => url.match(regex))
  if (!successfulRegex) return null
  const match = url.match(successfulRegex)

  return match && match[1]
}

function extractContactIdFromSalesForceUrl(url: string) {
  const regex = /(?:\/lightning\/r\/contact\/)([a-zA-Z0-9]+)/i
  const match = url.match(regex)
  return match && match[1]
}

function extractCompanyIdFromSalesForceUrl(url: string) {
  const regex = /(?:\/lightning\/r\/account\/)([a-zA-Z0-9]+)/i
  const match = url.match(regex)
  return match && match[1]
}

function extractDealIdFromSalesForceUrl(url: string) {
  const regex = /(?:\/lightning\/r\/opportunity\/)([a-zA-Z0-9]+)/i
  const match = url.match(regex)
  return match && match[1]
}

function extractWorkspaceIdFromHubSpotUrl(url: string) {
  const regex = /hubspot\.com\/\D+\/(\d+)/i
  const match = url.match(regex)
  return match && match[1]
}

function extractWorkspaceIdFromSalesForceUrl(url: string) {
  const regex = /https:\/\/(.+)\.lightning/i
  const match = url.match(regex)
  return match && match[1]
}

function isHubSpotUrl(url: string) {
  const domainNameRegex = /([^\s.]+)\.com/i
  const domainNameMatch = url.match(domainNameRegex)

  if (!domainNameMatch) return false

  return domainNameMatch[1] === "hubspot"
}

function isSalesForceUrl(url: string) {
  const domainNameRegex = /([^\s.]+)\.com/i
  const domainNameMatch = url.match(domainNameRegex)

  if (!domainNameMatch) return false

  return domainNameMatch[1] === "force"
}
