import MuiFormControl, { FormControlProps } from "@mui/material/FormControl"
import { forwardRef } from "react"

export type { FormControlProps }

export default forwardRef(function FormControl(
  props: FormControlProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiFormControl {...props} ref={ref} />
})
