import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import { requestCurrentUrl } from "chrome_extension/messaging"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import Fab from "ds/Fab"
import { useIsSmallerThanMD } from "ds/Media"
import { AlertSnackbar } from "ds/Snackbar"
import { isApiError } from "ds/UncontrolledError"

import { useBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"
import { useCurrentMeetingStartMutation } from "components/App/CRM/Meetings/meetingQueries"
import { useApplicationIntegrationQuery } from "components/App/Organization/ApplicationsIntegration/applicationIntegrationsQueries"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { filterNonArchived } from "services/archivable"
import { crmIntegrationMismatch } from "services/crmIntegrations"
import {
  getMeetingSyncedEntityTypeFromLS,
  selectPickedSegmentFromMeeting,
} from "services/meetings"

import { getCurrentUrlCrmInfo } from "utils/crmUrlParser"

import EndMeetingModal from "./MeetingFab/EndMeetingModal"
import { useMeeting } from "./MeetingProvider"

export default function MeetingFab() {
  const { ongoingMeeting } = useMeeting()
  const { playbook, pickSegments, storePreMeetingSegments } = usePlaybook()
  const businessUnit = useBusinessUnit()
  const { data: currentCRMIntegration } = useApplicationIntegrationQuery(
    businessUnit.id
  )

  const startMutation = useCurrentMeetingStartMutation(playbook.id)
  const [cannotStartMeetingSnackOpen, setCannotStartMeetingSnackOpen] =
    useState(false)
  const [crmMistmatchSnackOpen, setCrmMistmatchSnackOpen] = useState(false)

  const isSmallerThanMD = useIsSmallerThanMD()
  const intl = useIntl()
  const [endMeetingModalOpen, setEndMeetingModalOpen] = useState(false)
  const startMeetingFabLabel = intl.formatMessage({
    id: "playbook.meetingFab.title.start",
  })

  const endMeetingFabLabel = intl.formatMessage({
    id: "playbook.meetingFab.title.end",
  })

  function startMeeting({
    crmContactExternalId,
    crmCompanyExternalId,
    crmDealExternalId,
  }: {
    crmContactExternalId?: string | null
    crmCompanyExternalId?: string | null
    crmDealExternalId?: string | null
  } = {}) {
    storePreMeetingSegments()
    startMutation.mutate(
      {
        playbookId: playbook.id,
        crmContactExternalId,
        crmCompanyExternalId,
        crmDealExternalId,
        syncedEntityType: getMeetingSyncedEntityTypeFromLS(),
      },
      {
        onSuccess: (meeting) => {
          pickSegments(
            selectPickedSegmentFromMeeting(
              meeting,
              filterNonArchived(playbook.argumentSegmentations)
            )
          )
        },
        onError: (error) => {
          if (isApiError(error, 403)) {
            setCannotStartMeetingSnackOpen(true)
          }
        },
      }
    )
  }

  if (!ongoingMeeting) {
    return (
      <>
        <Fab
          color="primary"
          aria-label={startMeetingFabLabel}
          variant={isSmallerThanMD ? "circular" : "extended"}
          onClick={async () => {
            if (!IS_CHROME_EXTENSION || !currentCRMIntegration) {
              return startMeeting()
            }
            const url = await requestCurrentUrl()
            if (!url) return startMeeting()
            const { provider, workspaceId, contactId, companyId, dealId } =
              getCurrentUrlCrmInfo(url)
            if (
              crmIntegrationMismatch(
                currentCRMIntegration,
                provider,
                workspaceId
              )
            ) {
              setCrmMistmatchSnackOpen(true)
              startMeeting()
            } else {
              startMeeting({
                crmContactExternalId: contactId,
                crmCompanyExternalId: companyId,
                crmDealExternalId: dealId,
              })
            }
          }}
          disabled={startMutation.isLoading}
        >
          <MeetingRoomIcon />
          {!isSmallerThanMD && startMeetingFabLabel}
        </Fab>

        <AlertSnackbar
          severity="error"
          open={cannotStartMeetingSnackOpen}
          onClose={() => setCannotStartMeetingSnackOpen(false)}
        >
          <FormattedMessage id="meetings.cannotStart" />
        </AlertSnackbar>

        <AlertSnackbar
          severity="error"
          open={crmMistmatchSnackOpen}
          onClose={() => setCrmMistmatchSnackOpen(false)}
        >
          <FormattedMessage id="chrome_extension.meeting.workspaceIdMismatch" />
        </AlertSnackbar>
      </>
    )
  }

  return (
    <>
      <Fab
        color="success"
        aria-label={startMeetingFabLabel}
        variant={isSmallerThanMD ? "circular" : "extended"}
        onClick={async () => {
          setEndMeetingModalOpen((prevState) => !prevState)
        }}
      >
        <MeetingRoomIcon />
        {!isSmallerThanMD && endMeetingFabLabel}
      </Fab>

      <EndMeetingModal
        isOpen={endMeetingModalOpen}
        onClose={() => setEndMeetingModalOpen(false)}
      />
    </>
  )
}
