import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import Button from "ds/Button"
import Checkbox from "ds/Checkbox"
import FormControlLabel from "ds/FormControlLabel"
import { BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"

import CRMPropertySelector from "components/App/CRM/properties/CRMPropertySelector"
import CrmPropertyRefresh from "components/App/CRM/properties/CrmPropertyRefresh"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import {
  useMeetingNoteConfigurationRemoveMutation,
  useMeetingNoteConfigurationUpsertFromCRMMutation,
} from "components/App/Playbook/queries/meetingNoteConfigurationQueries"

import { isCRMPropertyAnswerable } from "services/meetingNoteConfigurations"

interface Props {
  argument: IArgument
  onSuccess: () => void
}

export function CrmPropertyForm({ argument, onSuccess }: Props) {
  const { playbook } = usePlaybook()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const [selectedCrmPropertyId, setSelectedPropertyId] = useState<
    string | null
  >(argument.meetingNoteConfiguration?.crmProperty?.id || null)

  const [isMandatory, setIsMandatory] = useState(
    argument.meetingNoteConfiguration?.isMandatory || false
  )

  const [appearsInSummary, setAppearsInSummary] = useState(
    argument.meetingNoteConfiguration?.appearsInSummary || false
  )

  const upsertMutation = useMeetingNoteConfigurationUpsertFromCRMMutation()
  const removeMutation = useMeetingNoteConfigurationRemoveMutation()

  function submit(e: React.FormEvent) {
    e.preventDefault()
    if (selectedCrmPropertyId === null) return
    upsertMutation.mutate(
      {
        argumentId: argument.id,
        crmPropertyId: selectedCrmPropertyId,
        isMandatory,
        appearsInSummary,
      },
      {
        onSuccess: () => onSuccess(),
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  function remove() {
    removeMutation.mutate(
      { argumentId: argument.id },
      {
        onSuccess: () => onSuccess(),
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <>
      <form onSubmit={submit}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center">
            <CRMPropertySelector
              businessUnitId={playbook.businessUnitId}
              fullWidth
              crmPropertyId={selectedCrmPropertyId}
              onChange={(_, newProperty) => {
                setSelectedPropertyId(newProperty?.id || null)
              }}
              restrictOptions={(properties) =>
                properties.filter(isCRMPropertyAnswerable)
              }
            />

            <CrmPropertyRefresh />
          </Stack>

          <div>
            <FormControlLabel
              checked={isMandatory}
              control={
                <Checkbox onChange={(e) => setIsMandatory(e.target.checked)} />
              }
              label={
                <FormattedMessage id="playbookEdit.argumentAnswer.form.isMandatory" />
              }
            />
          </div>

          <div>
            <FormControlLabel
              checked={appearsInSummary}
              control={
                <Checkbox
                  onChange={(e) => setAppearsInSummary(e.target.checked)}
                />
              }
              label={
                <FormattedMessage id="playbookEdit.argumentAnswer.form.appearsInSummary" />
              }
            />
          </div>

          <Stack direction="row" spacing={1}>
            {argument.meetingNoteConfiguration && (
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="error"
                disabled={removeMutation.isLoading}
                onClick={() => remove()}
              >
                <FormattedMessage id="playbookEdit.argumentAnswer.remove" />
              </Button>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!selectedCrmPropertyId || upsertMutation.isLoading}
            >
              <FormattedMessage id="common.submit" />
            </Button>
          </Stack>
        </Stack>
      </form>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </>
  )
}
