import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { apiSearchCRMContacts } from "api/CRMContacts"
import { ICRMContactSearchResult } from "api/types/CRMContacts"

import AsyncAutocomplete, { AsyncAutocompleteProps } from "ds/AsyncAutocomplete"
import { AlertSnackbar } from "ds/Snackbar"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import IntegrationIcon from "components/common/IntegrationIcon"

function InputLabel() {
  return <FormattedMessage id="meetings.contact" defaultMessage="Contact" />
}

export interface CRMContactSelectorProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  TOptionAlternative = never
> extends Omit<
    AsyncAutocompleteProps<
      ICRMContactSearchResult | TOptionAlternative,
      Multiple,
      DisableClearable,
      false
    >,
    "getOptions" | "getOptionLabel" | "inputLabel"
  > {
  businessUnitId: string
  companyId: string | null
  externalSearchDisabled?: boolean
  inputLabel?: React.ReactNode
  isOptionAlternative?: (
    option: ICRMContactSearchResult | TOptionAlternative
  ) => option is TOptionAlternative // Must be provided if not all options are contacts
}

export default function CRMContactSelector<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  TOptionAlternative extends {
    origin: "DB"
    id: string
    name: string
    integrationName?: string
  } = never
>({
  businessUnitId,
  externalSearchDisabled = false,
  inputLabel = <InputLabel />,
  isOptionAlternative = (option): option is TOptionAlternative => false,
  companyId,
  ...props
}: CRMContactSelectorProps<Multiple, DisableClearable, TOptionAlternative>) {
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  return (
    <>
      <AsyncAutocomplete
        inputLabel={inputLabel}
        getOptions={(name) =>
          apiSearchCRMContacts({
            businessUnitId,
            name,
            externalSearchDisabled,
            companyId,
          })
        }
        onGetOptionsFailure={() => setErrorSnackOpen(true)}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option) => (
          <li {...props} key={`${option.origin}-${option.id}`}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              minWidth={0}
              width="100%"
              spacing={1}
              data-testid={`contact-option-${option.origin}-${option.id}`}
            >
              <Stack minWidth={0} flexGrow={1}>
                <Typography
                  variant="smNormal"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {option.name}
                </Typography>

                {!isOptionAlternative(option) && option.email && (
                  <Typography
                    variant="xsNormal"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {option.email}
                  </Typography>
                )}
              </Stack>

              {option.integrationName && (
                <IntegrationIcon integrationName={option.integrationName} />
              )}
            </Stack>
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        {...props}
      />

      <AlertSnackbar
        severity="error"
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbook.meeting.information.crmContacts.search.error" />
      </AlertSnackbar>
    </>
  )
}
