// Redirect to login
// We pass the current pathname and search to redirect there after login,
//   see authentication/Provider.js
// In case the user is trying to accept an invitation,
//  we redirect to the sign up page by default
import { Auth0ContextInterface } from "@auth0/auth0-react"
import { useEffect } from "react"
import { useMatch } from "react-router-dom"

interface Props {
  triggerLogin: Auth0ContextInterface["loginWithRedirect"]
}

export default function TriggerLogin({ triggerLogin }: Props) {
  const invitationMatch = useMatch("/accept_invitation/:invitationToken")
  useEffect(() => {
    triggerLogin({
      appState: {
        returnTo: `${window.location.pathname}${window.location.search}`,
      },
      screen_hint: invitationMatch ? "signup" : "login",
    })
  }, [triggerLogin, invitationMatch])

  return null
}
