import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_TODO_LI,
  ELEMENT_TOGGLE,
} from "@udecode/plate"
import { IndentPlugin, createIndentPlugin } from "@udecode/plate-indent"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"

import { MyEditor, MyValue } from "../types"
import { ELEMENT_CLOUD_ATTACHMENT } from "./cloud"

export const indentPlugin = createIndentPlugin<IndentPlugin, MyValue, MyEditor>(
  {
    inject: {
      props: {
        validTypes: [
          ELEMENT_PARAGRAPH,
          ELEMENT_TOGGLE,
          ELEMENT_BLOCKQUOTE,
          ELEMENT_CLOUD_ATTACHMENT,
          ELEMENT_TODO_LI,
          // ELEMENT_H1,
        ],
      },
    },
  }
)
