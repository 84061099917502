import LinkIcon from "@mui/icons-material/Link"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import NotesIcon from "@mui/icons-material/Notes"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { Box, InputAdornment } from "@mui/material"
import {
  UseVirtualFloatingOptions,
  flip,
  offset,
  useEditorRef,
} from "@udecode/plate"
import {
  LinkFloatingToolbarState,
  LinkOpenButton,
  useFloatingLinkEdit,
  useFloatingLinkEditState,
  useFloatingLinkInsert,
  useFloatingLinkInsertState,
  useFloatingLinkUrlInput,
  useFloatingLinkUrlInputState,
  validateUrl,
} from "@udecode/plate-link"
import { useState } from "react"
import { useIntl } from "react-intl"

import Button from "ds/Button"
import ButtonGroup from "ds/ButtonGroup"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

const floatingOptions: UseVirtualFloatingOptions = {
  placement: "bottom-start",
  middleware: [
    offset(12),
    flip({
      padding: 12,
      fallbackPlacements: ["bottom-end", "top-start", "top-end"],
    }),
  ],
}

export default function LinkFloatingToolbar({
  state,
}: {
  state?: LinkFloatingToolbarState
}) {
  const intl = useIntl()
  const insertState = useFloatingLinkInsertState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  })
  const {
    props: insertProps,
    ref: insertRef,
    hidden,
    textInputProps,
  } = useFloatingLinkInsert(insertState)

  const editState = useFloatingLinkEditState({
    ...state,
    floatingOptions: {
      ...floatingOptions,
      ...state?.floatingOptions,
    },
  })
  const {
    props: editProps,
    ref: editRef,
    editButtonProps,
    unlinkButtonProps,
  } = useFloatingLinkEdit(editState)

  const urlInputState = useFloatingLinkUrlInputState()
  const { props: urlInputProps, ref } = useFloatingLinkUrlInput(urlInputState)

  // There might be a better way to do this
  const [urlDuplicateValue, setUrlDuplicateValue] = useState(
    urlInputProps.defaultValue
  )
  const editor = useEditorRef()

  if (hidden) return null

  const input = (
    <Stack spacing={2} p={1}>
      <TextField
        {...urlInputProps}
        onChange={(event) => {
          urlInputProps.onChange(event as React.ChangeEvent<HTMLInputElement>)
          setUrlDuplicateValue(event.target.value)
        }}
        ref={ref}
        autoFocus
        placeholder={intl.formatMessage({
          id: "richtext.link.url.placeholder",
        })}
        error={!!urlDuplicateValue && !validateUrl(editor, urlDuplicateValue)}
        InputProps={{
          autoFocus: true,
          startAdornment: (
            <InputAdornment position="start">
              <LinkIcon />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        {...textInputProps}
        // Make sure that this input is re-rendered when the default value changes
        key={textInputProps.defaultValue}
        placeholder={intl.formatMessage({
          id: "richtext.link.text.placeholder",
        })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <NotesIcon />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  )

  const editContent = editState.isEditing ? (
    input
  ) : (
    <ButtonGroup variant="outlined">
      <Button {...editButtonProps}>Edit link</Button>

      <Button
        sx={{
          "& svg": { fill: (theme) => theme.palette.primary.main },
          "& a": { lineHeight: 0 },
        }}
      >
        <LinkOpenButton>
          <OpenInNewIcon fontSize="small" />
        </LinkOpenButton>
      </Button>

      <Button {...unlinkButtonProps}>
        <LinkOffIcon fontSize="small" />
      </Button>
    </ButtonGroup>
  )

  return (
    <>
      <Box
        boxShadow={12}
        borderRadius={1}
        bgcolor={(theme) => theme.palette.background.paper}
        ref={insertRef}
        {...insertProps}
      >
        {input}
      </Box>

      <Box
        boxShadow={12}
        borderRadius={1}
        bgcolor={(theme) => theme.palette.background.paper}
        ref={editRef}
        {...editProps}
      >
        {editContent}
      </Box>
    </>
  )
}
