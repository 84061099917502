import isEmpty from "lodash/isEmpty"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IBarePlaybook } from "api/types/playbooks"

import Button from "ds/Button"
import { BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

import { useBusinessUnit } from "../BusinessUnitProvider"
import { useBusinessUnitPlaybookCreateMutation } from "./businessUnitPlaybookQueries"

function validate({ name }: { name: string }) {
  const errors: { name?: string } = {}
  if (!name) {
    errors.name = "missing"
  }
  return errors
}

interface Props {
  onSuccess: (playbook: IBarePlaybook) => void
}

export default function CreatePlaybookForm({ onSuccess }: Props) {
  const businessUnit = useBusinessUnit()
  const [name, setName] = useState("")

  const createMutation = useBusinessUnitPlaybookCreateMutation(
    businessUnit.organizationId
  )
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const validationErrors = validate({ name })
  const isInvalid = !isEmpty(validationErrors)

  const submit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    createMutation.mutate(
      { playbook: { businessUnitId: businessUnit.id, name } },
      {
        onSuccess: ({ playbook }) => onSuccess(playbook),
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <form onSubmit={submit}>
      <Stack spacing={2}>
        <TextField
          id="create-playbook-name-input"
          label={
            <FormattedMessage
              id="businessUnits.playbooks.fields.name"
              defaultMessage="Name"
            />
          }
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <Button
          color="primary"
          type="submit"
          disabled={createMutation.isLoading || isInvalid}
        >
          <FormattedMessage
            id="businessUnits.playbooks.actions.form.submit"
            defaultMessage="Create"
          />
        </Button>
      </Stack>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </form>
  )
}
