import Box from "@mui/material/Box"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"

import Button from "ds/Button"
import Loader from "ds/Loader"
import { BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import UncontrolledError from "ds/UncontrolledError"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentsWithLinkableConfigurationQuery } from "components/App/Playbook/queries/argumentQueries"
import { useArgumentSegmentationLinkToMeetingNoteConfigurationMutation } from "components/App/Playbook/queries/argumentSegmentationQueries"

import CRMMeetingNoteConfigurationSelector from "./MeetingNoteConfigurationSelector"

interface FormProps {
  argumentSegmentation: IArgumentSegmentation
  onSuccess: () => void
}

export default function MeetingNoteConfigurationForm(props: FormProps) {
  const { playbook } = usePlaybook()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const [
    selectedMeetingNoteConfigurationId,
    setSelectedMeetingNoteConfigurationId,
  ] = useState<string | null>(
    props.argumentSegmentation.meetingNoteConfiguration?.id || null
  )

  const argumentsWithLinkableConfigurationQuery =
    useArgumentsWithLinkableConfigurationQuery(playbook.id)
  const linkMutation =
    useArgumentSegmentationLinkToMeetingNoteConfigurationMutation(playbook.id)

  function submit(e: React.FormEvent) {
    e.preventDefault()
    if (selectedMeetingNoteConfigurationId === null) return
    linkMutation.mutate(
      {
        id: props.argumentSegmentation.id,
        meetingNoteConfigurationId: selectedMeetingNoteConfigurationId,
      },
      {
        onSuccess: () => props.onSuccess(),
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  if (argumentsWithLinkableConfigurationQuery.isLoading) return <Loader />
  if (argumentsWithLinkableConfigurationQuery.isError)
    return (
      <UncontrolledError
        error={argumentsWithLinkableConfigurationQuery.error}
      />
    )

  return (
    <Box>
      <form onSubmit={submit}>
        <Stack spacing={1}>
          <CRMMeetingNoteConfigurationSelector
            meetingNoteConfigurationId={selectedMeetingNoteConfigurationId}
            arguments={argumentsWithLinkableConfigurationQuery.data}
            onChange={(_, selectedOption) => {
              setSelectedMeetingNoteConfigurationId(
                selectedOption?.value || null
              )
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={
              !selectedMeetingNoteConfigurationId || linkMutation.isLoading
            }
          >
            <FormattedMessage id="common.submit" />
          </Button>
        </Stack>
      </form>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </Box>
  )
}
