import { isEqual } from "lodash"
import { IntlShape } from "react-intl"

import { IUpcomingCall } from "api/types/CRMCalls"
import { ICRMCompany } from "api/types/CRMCompanies"
import { ICRMContact } from "api/types/CRMContacts"
import { ICRMDeal } from "api/types/CRMDeals"
import { ICRMMeetingType } from "api/types/CRMMeetingTypes"
import {
  CRM_PROPERTY_COMPANY_OBJECT_TYPE,
  CRM_PROPERTY_CONTACT_OBJECT_TYPE,
  CRM_PROPERTY_DEAL_OBJECT_TYPE,
  ICrmPropertyObjectType,
} from "api/types/CRMProperties"
import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IPickedSegment } from "api/types/argumentSegments"
import { IArgument } from "api/types/arguments"
import {
  CALL_SYNCED_ENTITY,
  ISyncedEntityType,
  MEETING_SYNCED_ENTITY,
} from "api/types/integrations"
import { IMeetingArgumentNote } from "api/types/meetingArgumentNotes"
import {
  IMeeting,
  IMeetingLinkedToExternalCRM,
  IMeetingLinkedToExternalCRMCall,
  IUpcomingMeeting,
  isMeetingLinkedToExternalCRM,
  isMeetingLinkedToExternalCRMCall,
} from "api/types/meetings"
import { IUser } from "api/types/users"
import { IVote } from "api/types/votes"

import { buildNodeFromText } from "ds/RichTextNew/helpers"

import { getCurrentUrlCrmInfo } from "utils/crmUrlParser"

import { getRawTitle } from "./argumentFields"
import { SEGMENT_DEFAULT } from "./argumentSegmentations"
import { getBusinessUnitPath } from "./businessUnits"
import {
  findCrmCompanyProperty,
  selectSegmentIdsMatchingCompanyProperties,
} from "./crmCompanies"
import {
  findCrmContactProperty,
  selectSegmentIdsMatchingContactProperties,
} from "./crmContacts"
import {
  findCrmDealProperty,
  selectSegmentIdsMatchingDealProperties,
} from "./crmDeals"
import { formatMeetingArgumentNote } from "./meetingArgumentNotes"
import { getDownvotes, getUpvotes } from "./votes"

export function getMeetingsPath(businessUnitId: string) {
  return `${getBusinessUnitPath(businessUnitId)}/crm/meetings`
}

export function getMeetingPath(meeting: IMeeting) {
  return `${getMeetingsPath(meeting.businessUnitId)}?meetingId=${meeting.id}`
}

export function getMeetingVoteOnArgument(
  argument: IArgument,
  meeting: IMeeting | null
) {
  const voteDuringMeeting = meeting
    ? meeting.votes.find((vote) => vote.argument.id === argument.id)
    : undefined

  return {
    upvoted: !!voteDuringMeeting && voteDuringMeeting.count > 0,
    downvoted: !!voteDuringMeeting && voteDuringMeeting.count < 0,
  }
}

export function insertOrUpdateVote(
  meeting: IMeeting,
  newVote: IVote
): IMeeting {
  let newVotes
  const prevVotes = meeting.votes
  const oldVote = prevVotes.find((vote) => vote.id === newVote.id)
  if (!oldVote) newVotes = [...prevVotes, newVote]
  else
    newVotes = prevVotes.map((vote) =>
      vote.id === newVote.id ? newVote : vote
    )
  return { ...meeting, votes: newVotes }
}

export function insertOrUpdateArgumentNote(
  meeting: IMeeting,
  newNote: IMeetingArgumentNote
): IMeeting {
  let newNotes
  const prevNotes = meeting.meetingArgumentNotes
  const oldNote = prevNotes.find((note) => note.id === newNote.id)
  if (!oldNote) newNotes = [...prevNotes, newNote]
  else
    newNotes = prevNotes.map((note) =>
      note.id === newNote.id ? newNote : note
    )
  return { ...meeting, meetingArgumentNotes: newNotes }
}

function convertCrmCompanyToClipboardText(
  crmCompany: ICRMCompany | null,
  intl: IntlShape
) {
  let clipboardText = ""

  if (crmCompany) {
    const crmCompanySectionName = intl.formatMessage({
      id: "playbook.meeting.information.crmCompany.title",
      defaultMessage: "Account",
    })

    clipboardText += `${crmCompanySectionName} -> ${crmCompany.name}`
  }

  return clipboardText
}

function convertCrmContactsToClipboardText(
  crmContacts: ICRMContact[],
  intl: IntlShape
) {
  let clipboardText = ""

  if (crmContacts.length > 0) {
    const crmContactsSectionName = intl.formatMessage({
      id: "playbook.meeting.information.crmContacts.title",
      defaultMessage: "Contact(s)",
    })

    const crmContactNames = crmContacts.map(({ name }) => name).join(", ")

    clipboardText += `${crmContactsSectionName} -> ${crmContactNames}`
  }

  return clipboardText
}

function convertCrmDealToClipboardText(
  crmDeal: ICRMDeal | null,
  intl: IntlShape
) {
  let clipboardText = ""

  if (crmDeal) {
    const crmDealSectionName = intl.formatMessage({
      id: "playbook.meeting.information.crmDeal.title",
      defaultMessage: "Deal",
    })

    clipboardText += `${crmDealSectionName} -> ${crmDeal.name}`
  }

  return clipboardText
}

function convertCrmMeetingTypeToClipboardText(
  crmMeetingType: ICRMMeetingType | null,
  intl: IntlShape
) {
  let clipboardText = ""

  if (crmMeetingType) {
    const crmMeetingTypeSectionName = intl.formatMessage({
      id: "playbook.meeting.information.crmMeetingType.title",
      defaultMessage: "Meeting type",
    })

    clipboardText += `${crmMeetingTypeSectionName} -> ${crmMeetingType.name}`
  }

  return clipboardText
}

function convertUserToClipboardText(user: IUser, intl: IntlShape) {
  let clipboardText = ""

  const userSectionName = intl.formatMessage({
    id: "meetings.index.meetingAuthor",
    defaultMessage: "Held by",
  })
  clipboardText += `${userSectionName} -> ${user.name || user.email}`

  return clipboardText
}

function convertMeetingInformationToClipboardText(
  {
    crmCompany,
    crmContacts,
    crmDeal,
    crmMeetingType,
    user,
  }: {
    crmCompany: ICRMCompany | null
    crmContacts: ICRMContact[]
    crmDeal: ICRMDeal | null
    crmMeetingType: ICRMMeetingType | null
    user: IUser
  },
  intl: IntlShape
) {
  let clipboardText = ""

  const crmMeetingInformationSectionName = intl.formatMessage({
    id: "playbook.meeting.information.title",
    defaultMessage: "Meeting information",
  })

  clipboardText += "--- ℹ️ "
  clipboardText += crmMeetingInformationSectionName
  clipboardText += " ℹ️ ---"
  clipboardText += "\n"

  clipboardText += [
    convertCrmCompanyToClipboardText(crmCompany, intl),
    convertCrmContactsToClipboardText(crmContacts, intl),
    convertCrmDealToClipboardText(crmDeal, intl),
    convertCrmMeetingTypeToClipboardText(crmMeetingType, intl),
    convertUserToClipboardText(user, intl),
  ]
    .filter((section) => section && section.length > 0)
    .map((section) => indent(section, 3))
    .join("\n")

  return clipboardText
}

function convertVoteToClipboardText(vote: IVote, intl: IntlShape) {
  let clipboardText = ""

  const argumentTitle = getRawTitle(vote.argument)

  clipboardText += `🗣️ ${argumentTitle || ""} [${vote.argumentType.name || ""}]`

  if (vote.note) {
    clipboardText += "\n"
    clipboardText += indent(`(${vote.note})`, 5)
  }

  return clipboardText
}

function convertVotesToClipboardText(meeting: IMeeting, intl: IntlShape) {
  let upvoteClipboardText = ""
  let downvoteClipboardText = ""
  const upvotes = getUpvotes(meeting.votes)
  const downvotes = getDownvotes(meeting.votes)

  if (upvotes.length > 0) {
    const upvotedArgumentsSectionName = intl.formatMessage({
      id: "playbook.meeting.upvotedArguments",
      defaultMessage: "Talking points voted up",
    })

    upvoteClipboardText += "--- ⬆️ "
    upvoteClipboardText += upvotedArgumentsSectionName
    upvoteClipboardText += " ⬆️ ---"
    upvoteClipboardText += "\n"
    upvoteClipboardText += upvotes
      .map((vote) => indent(convertVoteToClipboardText(vote, intl), 3))
      .join("\n")
  }

  if (downvotes.length > 0) {
    const downvotedArgumentsSectionName = intl.formatMessage({
      id: "playbook.meeting.downvotedArguments",
      defaultMessage: "Talking points voted down",
    })

    downvoteClipboardText += "--- ⬇️ "
    downvoteClipboardText += downvotedArgumentsSectionName
    downvoteClipboardText += " ⬇️ ---"
    downvoteClipboardText += "\n"
    downvoteClipboardText += downvotes
      .map((vote) => indent(convertVoteToClipboardText(vote, intl), 3))
      .join("\n")
  }

  return [upvoteClipboardText, downvoteClipboardText]
    .filter((clipboardText) => clipboardText && clipboardText.length > 0)
    .join("\n\n")
}

function convertGeneralNoteToClipboardText(meeting: IMeeting, intl: IntlShape) {
  let clipboardText = ""

  if (!meeting.generalNote || !meeting.rawGeneralNote) return clipboardText

  const generalNotesSectionName = intl.formatMessage({
    id: "playbook.meeting.generalNote.form.title",
    defaultMessage: "General meeting notes",
  })

  clipboardText += "--- 📝 "
  clipboardText += generalNotesSectionName
  clipboardText += " 📝 ---"
  clipboardText += "\n"
  clipboardText += indent(meeting.rawGeneralNote, 3)

  return clipboardText
}

function isArgumentNoteEmpty(argumentNote: IMeetingArgumentNote) {
  if (!!argumentNote.additionalInfo) return false
  return (
    argumentNote.value === null ||
    isEqual(argumentNote.value, []) ||
    argumentNote.value === "" ||
    isEqual(argumentNote.value, buildNodeFromText(""))
  )
}

function convertArgumentNoteToClipboardText(
  argumentNote: IMeetingArgumentNote,
  intl: IntlShape
) {
  let clipboardText = ""

  const argumentTitle = getRawTitle(argumentNote.argument)

  clipboardText += `❓ ${argumentTitle || "-"}`
  clipboardText += "\n"
  clipboardText += indent(
    formatMeetingArgumentNote(argumentNote, intl) || "-",
    5
  )
  if (argumentNote.additionalInfo) {
    clipboardText += "\n"
    clipboardText += indent(`(${argumentNote.additionalInfo})`, 5)
  }

  return clipboardText
}

function convertArgumentNotesToClipboardText(
  meeting: IMeeting,
  intl: IntlShape
) {
  let clipboardText = ""

  if (meeting.meetingArgumentNotes.length === 0) return clipboardText

  const argumentNotesSectionName = intl.formatMessage({
    id: "playbook.meeting.argumentNotes",
    defaultMessage: "Answers to questions",
  })

  clipboardText += "--- 📝 "
  clipboardText += argumentNotesSectionName
  clipboardText += " 📝 ---"
  clipboardText += "\n"
  clipboardText += meeting.meetingArgumentNotes
    .filter((note) => !isArgumentNoteEmpty(note))
    .map((argumentNote) =>
      indent(convertArgumentNoteToClipboardText(argumentNote, intl), 3)
    )
    .join("\n")

  return clipboardText
}

export function convertMeetingNotesToClipboardText(
  meeting: IMeeting,
  intl: IntlShape
) {
  return [
    convertMeetingInformationToClipboardText(meeting, intl),
    convertGeneralNoteToClipboardText(meeting, intl),
    convertArgumentNotesToClipboardText(meeting, intl),
    convertVotesToClipboardText(meeting, intl),
  ]
    .filter((section) => section && section.length > 0)
    .join("\n\n")
}

// slice a meeting to get its external origin
export function extractCRMMeeting(
  meeting: IMeetingLinkedToExternalCRM
): IUpcomingMeeting {
  return {
    externalId: meeting.externalId,
    title: meeting.title,
    startTime: meeting.startTime,
    integrationName: meeting.integrationName,
    workspaceId: meeting.workspaceId,
  }
}

export function extractCRMCallFromMeeting(
  meeting: IMeetingLinkedToExternalCRMCall
): IUpcomingCall {
  return {
    externalId: meeting.externalCallId,
    title: meeting.title,
    startTime: meeting.startTime,
    integrationName: meeting.integrationName,
  }
}

export function defineCRMMeeting(meeting: IMeeting | null) {
  if (!meeting) return null
  if (isMeetingLinkedToExternalCRM(meeting)) return extractCRMMeeting(meeting)
  return null
}

export function defineCRMCall(meeting: IMeeting | null) {
  if (!meeting) return null
  if (isMeetingLinkedToExternalCRMCall(meeting))
    return extractCRMCallFromMeeting(meeting)
  return null
}

const SYNCED_ENTITY_TYPE_LS_KEY = "salespath-meeting-synced-entity-type"

export function saveMeetingSyncedEntityTypeInLS(
  syncedEntityType: ISyncedEntityType
) {
  localStorage.setItem(SYNCED_ENTITY_TYPE_LS_KEY, syncedEntityType)
}

export function getMeetingSyncedEntityTypeFromLS(): ISyncedEntityType | null {
  const LSValue = localStorage.getItem(SYNCED_ENTITY_TYPE_LS_KEY)
  if (LSValue === CALL_SYNCED_ENTITY || LSValue === MEETING_SYNCED_ENTITY) {
    return LSValue
  }
  return null
}

function isContactOnCurrentContactPageInContacts(
  contactOnCurrentPageExternalId: string,
  contacts: ICRMContact[]
) {
  return contacts.some(
    (contact) => contact.externalId === contactOnCurrentPageExternalId
  )
}

export function contactOnCurrentContactPageMismatch(
  url: string | undefined,
  contacts: ICRMContact[]
) {
  if (!url || contacts.length === 0) return false
  const { contactId } = getCurrentUrlCrmInfo(url)
  if (!contactId) return false
  return !isContactOnCurrentContactPageInContacts(contactId, contacts)
}

export function findExternalProperty(
  meeting: IMeeting,
  externalPropertyId: string,
  objectType: ICrmPropertyObjectType
) {
  if (objectType === CRM_PROPERTY_CONTACT_OBJECT_TYPE) {
    const contactWithExternalProperty = meeting.crmContacts.find((crmContact) =>
      findCrmContactProperty(crmContact, externalPropertyId)
    )

    return contactWithExternalProperty
      ? findCrmContactProperty(contactWithExternalProperty, externalPropertyId)
      : null
  } else if (objectType === CRM_PROPERTY_COMPANY_OBJECT_TYPE) {
    return meeting.crmCompany
      ? findCrmCompanyProperty(meeting.crmCompany, externalPropertyId)
      : null
  } else if (objectType === CRM_PROPERTY_DEAL_OBJECT_TYPE) {
    return meeting.crmDeal
      ? findCrmDealProperty(meeting.crmDeal, externalPropertyId)
      : null
  }

  return null
}

export function selectPickedSegmentFromMeeting(
  meeting: IMeeting,
  segmentations: IArgumentSegmentation[]
) {
  let matchingSegments: IPickedSegment[] = segmentations.map(
    (segmentation) => ({
      argumentSegmentationId: segmentation.id,
      argumentSegmentIds: [SEGMENT_DEFAULT],
    })
  )
  const lastAddedContact = meeting.crmContacts.at(-1)
  if (lastAddedContact) {
    matchingSegments = selectSegmentIdsMatchingContactProperties(
      lastAddedContact,
      segmentations
    )
  }

  if (meeting.crmCompany) {
    matchingSegments = [
      ...matchingSegments,
      ...selectSegmentIdsMatchingCompanyProperties(
        meeting.crmCompany,
        segmentations
      ),
    ]
  }

  if (meeting.crmDeal) {
    matchingSegments = [
      ...matchingSegments,
      ...selectSegmentIdsMatchingDealProperties(meeting.crmDeal, segmentations),
    ]
  }

  return matchingSegments
}

const INDENT_SPACES = 2
const indent = (text: string, by = 1) => {
  return text
    .split("\n")
    .map((line) => " ".repeat(INDENT_SPACES * by) + line)
    .join("\n")
}
