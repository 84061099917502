import { FormattedMessage } from "react-intl"

import { IArgumentField } from "api/types/argumentFields"

import MenuItem from "ds/MenuItem"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentFieldRestoreMutation } from "components/App/Playbook/queries/argumentFieldQueries"

interface Props {
  argumentField: IArgumentField
}

export default function RestoreArgumentField({ argumentField }: Props) {
  const { playbook } = usePlaybook()
  const restoreMutation = useArgumentFieldRestoreMutation(playbook.id)

  const onClick = () => restoreMutation.mutate(argumentField.id)

  return (
    <MenuItem onClick={onClick} disabled={restoreMutation.isLoading}>
      <FormattedMessage
        id="playbookEdit.argumentFields.restore"
        defaultMessage="Restore"
      />
    </MenuItem>
  )
}
