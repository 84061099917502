import { useTheme } from "@mui/material"
import MuiButton, { ButtonProps } from "@mui/material/Button"
import { ForwardedRef, forwardRef } from "react"

export type { ButtonProps }

function Button<RootComponent extends React.ElementType>(
  props: ButtonProps<RootComponent, { component?: RootComponent }>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const theme = useTheme()
  return (
    <MuiButton
      size="small"
      {...props}
      sx={{
        // To account for the 1px border
        py: (theme) =>
          props.variant === "outlined"
            ? `calc(${theme.spacing(0.75)} - 1px)`
            : theme.spacing(0.75),
        px: 2,
        minWidth: 0,
        "& .MuiButton-startIcon": {
          mr: 0.5,
        },
        "& .MuiButton-endIcon": {
          ml: 0.5,
        },
        color:
          props.variant === "contained"
            ? (theme) => theme.palette.common.white
            : undefined,
        ...theme.typography.smMed,
        ...props.sx,
      }}
      ref={ref}
    >
      {props.children}
    </MuiButton>
  )
}

// https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop
export default forwardRef(Button) as typeof Button
