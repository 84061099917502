import Popover from "ds/Popover"
import Stack from "ds/Stack"

import useAnchorEl from "utils/hooks/useAnchorEl"

import { usePlaybook } from "../PlaybookProvider"
import ArchivedViewSetting from "./ArchivedViewSetting"
import StalenessViewSetting from "./StalenessViewSetting"
import ViewSettingsOpener from "./ViewSettingsOpener"

export default function ViewSettingsControls() {
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const { viewSettings } = usePlaybook()

  return (
    <>
      <ViewSettingsOpener onClick={handleClick} />

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Stack p={1}>
          <ArchivedViewSetting viewSettings={viewSettings} />

          <StalenessViewSetting viewSettings={viewSettings} />
        </Stack>
      </Popover>
    </>
  )
}
