import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"

import Button from "ds/Button"
import Modal from "ds/Modal"

import MeetingNoteConfigurationForm from "./LinkArgumentSegmentationMeetingNoteConfiguration/CRMMeetingNoteConfigurationForm"

interface Props {
  argumentSegmentation: IArgumentSegmentation
}

export default function LinkArgumentSegmentationMeetingNoteConfiguration(
  props: Props
) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button size="small" onClick={() => setIsOpen(true)}>
        <FormattedMessage id="playbookEdit.argumentSegmentations.linkToMeetingNoteConfiguration" />
      </Button>

      {isOpen && (
        <Modal isOpen onClose={() => setIsOpen(false)}>
          <Box sx={{ p: 2, minWidth: "min(90vh, 400px)" }}>
            <MeetingNoteConfigurationForm
              argumentSegmentation={props.argumentSegmentation}
              onSuccess={() => setIsOpen(false)}
            />
          </Box>
        </Modal>
      )}
    </>
  )
}
