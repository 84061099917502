import { FormattedMessage } from "react-intl"

import { IOrganizationMembership } from "api/types/organizationMemberships"

import FormControlLabel from "ds/FormControlLabel"
import Switch from "ds/Switch"

import { useOrganizationMembershipUpdateCanEditPlaybooksMutation } from "./organizationMembershipQueries"

interface Props {
  organizationMembership: IOrganizationMembership
}

export default function CanEditPlaybooksForm({
  organizationMembership,
}: Props) {
  const updateQuery = useOrganizationMembershipUpdateCanEditPlaybooksMutation(
    organizationMembership.organizationId
  )
  const userIsAdmin = organizationMembership.role === "ADMIN"
  const canEditPlaybooks =
    userIsAdmin || organizationMembership.canEditPlaybooks

  const label = canEditPlaybooks ? (
    <FormattedMessage id="common.on" defaultMessage="On" />
  ) : (
    <FormattedMessage id="common.off" defaultMessage="Off" />
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCanEditPlaybooks = e.target.checked
    updateQuery.mutate({
      id: organizationMembership.id,
      canEditPlaybooks: newCanEditPlaybooks,
    })
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={canEditPlaybooks}
          disabled={updateQuery.isLoading || userIsAdmin}
          onChange={onChange}
        />
      }
      label={label}
    />
  )
}
