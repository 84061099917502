import axios from "./axios"
import {
  IArgument,
  IArgumentBulkUpdateParams,
  IArgumentBulkUpdateResponse,
  IArgumentCreateParams,
  IArgumentMoveParams,
  IArgumentResponse,
  IArgumentSearchResult,
  IArgumentUpdateKindParams,
  IArgumentUpdateParams,
  IArgumentWithMeetingNoteConfiguration,
  IArgumentsArchiveResponse,
  IArgumentsDuplicateResponse,
  IArgumentsPasteParams,
  IArgumentsPasteResponse,
  IArgumentsRestoreResponse,
} from "./types/arguments"

export const apiGetArgument = (
  argumentId: string,
  { signal }: { signal?: AbortSignal }
): Promise<IArgument | null> =>
  axios.get(`api/arguments/${argumentId}`, { signal }).then((res) => res.data)

export const apiGetArguments = (
  argumentTypeId: string,
  { signal }: { signal?: AbortSignal }
): Promise<IArgument[]> =>
  axios
    .get("api/arguments", { params: { argumentTypeId }, signal })
    .then((res) => res.data)

export const apiUpdateArgument = (
  params: IArgumentUpdateParams
): Promise<IArgumentResponse> =>
  axios.patch(`api/arguments/${params.id}`, params).then((res) => res.data)

export const apiUpdateKindArgument = (
  params: IArgumentUpdateKindParams
): Promise<IArgumentResponse> =>
  axios
    .patch(`api/arguments/${params.id}/update_kind`, params)
    .then((res) => res.data)

export const apiBulkUpdateArguments = (
  params: IArgumentBulkUpdateParams
): Promise<IArgumentBulkUpdateResponse> =>
  axios.patch("api/arguments/bulk_update", params).then((res) => res.data)

export const apiMoveArguments = (
  params: IArgumentMoveParams
): Promise<undefined> =>
  axios.patch("api/arguments/move", params).then((res) => res.data)

export const apiArchiveArguments = (
  ids: string[]
): Promise<IArgumentsArchiveResponse> =>
  axios.patch("api/arguments/archive", { ids }).then((res) => res.data)

export const apiRestoreArguments = (
  ids: string[]
): Promise<IArgumentsRestoreResponse> =>
  axios.patch("api/arguments/restore", { ids }).then((res) => res.data)

export const apiUpdateArgumentPosition = ({
  id,
  relativeId,
  argumentGroupId,
}: {
  id: string
  relativeId: string
  argumentGroupId: string | null
}): Promise<undefined> =>
  axios
    .patch(`api/arguments/${id}/update_position`, {
      relativeId,
      argumentGroupId,
    })
    .then((res) => res.data)

export const apiCreateArgument = (
  params: IArgumentCreateParams
): Promise<IArgumentResponse> =>
  axios.post("api/arguments", params).then((res) => res.data)

export const apiDuplicateArgument = (
  ids: string[]
): Promise<IArgumentsDuplicateResponse> =>
  axios.post("api/arguments/duplicate", { ids }).then((res) => res.data)

export const apiSearchArguments = (
  query: string,
  playbookId: string,
  argumentTypeId?: string
): Promise<IArgumentSearchResult[]> =>
  axios
    .get("api/arguments/search", {
      params: { query, playbookId, argumentTypeId },
    })
    .then((res) => res.data)

export const apiIndexAll = (): Promise<undefined> =>
  axios.patch("api/arguments/index_all").then((res) => res.data)

export const apiRefreshArgument = (
  argumentId: string
): Promise<{ fieldsLastTouchedAt: string }> =>
  axios.patch(`api/arguments/${argumentId}/refresh`).then((res) => res.data)

export const apiPasteArguments = (
  params: IArgumentsPasteParams
): Promise<IArgumentsPasteResponse> =>
  axios.post(`api/arguments/paste`, params).then((res) => res.data)

export const apiGetArgumentsUsingField = (
  argumentFieldId: string,
  { signal }: { signal?: AbortSignal }
): Promise<IArgument[]> =>
  axios
    .get("api/arguments/using_field", { params: { argumentFieldId }, signal })
    .then((res) => res.data)

export const apiGetArgumentsWithLinkableConfiguration = (
  playbookId: string,
  { signal }: { signal?: AbortSignal }
): Promise<IArgumentWithMeetingNoteConfiguration[]> =>
  axios
    .get("api/arguments/with_linkable_configuration", {
      params: { playbookId },
      signal,
    })
    .then((res) => res.data)
