import { KeyboardEvent } from "react"

// Takes a key and a handler, and returns an event handler that calls the

type Handler = (event: React.KeyboardEvent) => void

//  provided handler only if the key pressed matches the provided key
const onKeyDown =
  (key: string, handler: Handler): Handler =>
  (e: React.KeyboardEvent): void => {
    if (e.key === key) {
      handler(e)
    }
  }

export const onKeyDownEnter = (handler: Handler): Handler =>
  onKeyDown("Enter", handler)
export const onKeyDownEscape = (handler: Handler): Handler =>
  onKeyDown("Escape", handler)

export default onKeyDown
