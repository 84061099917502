import sanitizeHTML from "sanitize-html"

interface SanitizeHTMLProps {
  html: string
  options?: sanitizeHTML.IOptions
}

export default function SanitizeHTML({
  html,
  options = {},
  ...props
}: SanitizeHTMLProps) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizeHTML(html, options) }}
      {...props}
    />
  )
}
