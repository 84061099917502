import { ICurrentUser } from "api/types/me"

function findOrganizationMembership(
  user: ICurrentUser,
  organizationId: string
) {
  return user.organizationMemberships.find(
    (x) => x.organization.id === organizationId
  )
}

export function isOrganizationAdmin(
  user: ICurrentUser,
  organizationId: string
) {
  const membership = findOrganizationMembership(user, organizationId)
  return !!membership && membership.role === "ADMIN"
}
