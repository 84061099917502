import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import Loader from "ds/Loader"
import ClosingCross from "ds/Modal/ClosingCross"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import Notification from "./Notification"
import { useNotificationsQuery } from "./notificationQueries"

const Container = styled(Stack)`
  width: min(90vw, 400px);
  min-height: 200px;
  max-height: 90vh;
  padding: ${({ theme }) => theme.spacing(2)};
`

interface Props {
  onClose: () => void
}

export default function NotificationsPanel(props: Props) {
  const notificationsQuery = useNotificationsQuery()

  if (notificationsQuery.isLoading)
    return (
      <Container>
        <Loader />
      </Container>
    )

  if (notificationsQuery.isError)
    return (
      <Container>
        <UncontrolledError error={notificationsQuery.error} />
      </Container>
    )

  return (
    <Container spacing={2}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="baseSemi">
          <FormattedMessage
            id="notifications.title"
            defaultMessage="Notifications"
          />
        </Typography>

        <ClosingCross onClick={props.onClose} />
      </Stack>

      <Stack spacing={2}>
        {notificationsQuery.data.map((notification) => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </Stack>
    </Container>
  )
}
