import styled from "styled-components"

import { firstColumnWidth } from "./MatrixGrid"

// ⚠️ Tricky manual calculation
// We stick the checkboxes based on the width of the first column + a spacing
export default styled.div`
  background-color: inherit;
  position: sticky;
  left: ${({ theme }) => `calc(${theme.spacing(1)} + ${firstColumnWidth})`};
`
