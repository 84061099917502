import { Box } from "@mui/material"
import { useState } from "react"

import { IArgumentGroup } from "api/types/argumentGroups"
import { IArgumentSearchResult } from "api/types/arguments"

import ClosingCross from "ds/Modal/ClosingCross"
import SearchInput from "ds/SearchInput"
import Stack from "ds/Stack"

import { useArgumentUpdateMutation } from "components/App/Playbook//queries/argumentQueries"
import useArgumentSearch from "components/App/Playbook/PlaybookLayout/Header/Search/useArgumentSearch"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { addSegmentsIntersectionToSegmentRules } from "services/argumentSegmentRules"

import Results from "./AddGroupToSearch/Results"

interface Props {
  argumentGroup: IArgumentGroup | null
  onClose: () => void
}

export default function AddToGroupSearch({ argumentGroup, onClose }: Props) {
  const { playbook, activeTypeId, segmentFilters } = usePlaybook()
  const [searchTerm, setSearchTerm] = useState("")
  const { results, isSearching } = useArgumentSearch(
    playbook.id,
    searchTerm,
    activeTypeId
  )
  const updateArgumentMutation = useArgumentUpdateMutation(activeTypeId)

  function onClickResult(result: IArgumentSearchResult) {
    const argument = result.argument
    if (!argument) return // TODO enqueue error instead
    const newArgumentGroupId = argumentGroup ? argumentGroup.id : null
    const newSegmentRules = addSegmentsIntersectionToSegmentRules(
      argument.segmentRules,
      segmentFilters
    )
    updateArgumentMutation.mutate(
      {
        id: argument.id,
        argumentGroupId: newArgumentGroupId,
        segmentRules: newSegmentRules,
      },
      { onSuccess: () => onClose() }
    )
  }

  return (
    // TODO replace with an Autocomplete
    <Box sx={{ width: "500px", minHeight: "400px" }}>
      <Stack direction="row" alignItems="center">
        <Box flexGrow={1}>
          <SearchInput
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            disabled={updateArgumentMutation.isLoading}
            clear={() => setSearchTerm("")}
          />
        </Box>

        <Box>
          <ClosingCross onClick={onClose} />
        </Box>
      </Stack>

      {!isSearching && (
        <Results
          results={results}
          onClickResult={onClickResult}
          disabled={updateArgumentMutation.isLoading}
        />
      )}
    </Box>
  )
}
