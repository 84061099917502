import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd"
import { Box } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgumentType } from "api/types/argumentTypes"

import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentTypeUpdatePositionMutation } from "components/App/Playbook/queries/argumentTypeQueries"

import { filterArchived, filterNonArchived } from "services/archivable"

import AddArgumentType from "./AddArgumentType"
import ArgumentTypeInput from "./ArgumentTypeInput"
import ShowArchivedToggle from "./ShowArchivedToggle"

export default function ArgumentTypesForm() {
  const { playbook } = usePlaybook()
  const updatePositionMutation = useArgumentTypeUpdatePositionMutation(
    playbook.id
  )
  const [showArchived, setShowArchived] = useState(false)

  const argumentTypesToShow = showArchived
    ? playbook.argumentTypes
    : filterNonArchived(playbook.argumentTypes)
  const hasArchived = filterArchived(playbook.argumentTypes).length > 0

  // Focus on newly created type input
  const [lastCreatedArgumentTypeId, setLastCreatedArgumentTypeId] = useState<
    string | null
  >(null)
  const lastCreatedArgumentTypeRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    lastCreatedArgumentTypeRef.current?.focus()
  }, [lastCreatedArgumentTypeId])

  function onAddArgumentType(argumentType: IArgumentType) {
    setLastCreatedArgumentTypeId(argumentType.id)
  }

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return
    }
    const argumentTypeId = result.draggableId
    const oldPosition = result.source.index
    const newPosition = result.destination.index
    if (oldPosition === newPosition) {
      return
    }
    const relativeArgumentType = argumentTypesToShow[newPosition]
    updatePositionMutation.mutate({
      id: argumentTypeId,
      relativeId: relativeArgumentType.id,
    })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Stack spacing={2}>
        <Typography variant="lgSemi">
          <FormattedMessage id="playbookEdit.argumentTypes.edit" />
        </Typography>

        <Droppable droppableId={`argumentTypes-${playbook.id}`}>
          {(providedDroppable) => (
            <Stack
              spacing={2}
              width="100%"
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              {argumentTypesToShow.map((argumentType, index) => (
                <Draggable
                  draggableId={argumentType.id}
                  index={index}
                  key={argumentType.id}
                >
                  {(providedDraggable, dragSnapshot) => (
                    <ArgumentTypeInput
                      argumentType={argumentType}
                      providedDraggable={providedDraggable}
                      isDragging={dragSnapshot.isDragging}
                      inputRef={
                        argumentType.id === lastCreatedArgumentTypeId
                          ? lastCreatedArgumentTypeRef
                          : null
                      }
                    />
                  )}
                </Draggable>
              ))}

              {providedDroppable.placeholder}
            </Stack>
          )}
        </Droppable>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            {hasArchived && (
              <ShowArchivedToggle
                checked={showArchived}
                onChange={(e) => setShowArchived(e.target.checked)}
              />
            )}
          </Box>
          <AddArgumentType onAddArgumentType={onAddArgumentType} />
        </Stack>
      </Stack>
    </DragDropContext>
  )
}
