import snakeCase from "lodash/snakeCase"

import deepMapKeys from "./deepMapKeys"

// Snake case recursively all the keys of the object
const deepSnakeCaseKeys = (object: object): object =>
  deepMapKeys(
    object,
    // If the key has an underscore before it (like _destroy) we keep it
    (_value, key) => `${key.charAt(0) === "_" ? "_" : ""}${snakeCase(key)}`
  )

export default deepSnakeCaseKeys
