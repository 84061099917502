import apiHost from "./apiHost"
import axios from "./axios"
import {
  ICreateReferencesImportParams,
  ICreateReferencesImportResponse,
} from "./types/referencesImports"

export const apiCreateReferencesImport = (
  params: ICreateReferencesImportParams
): Promise<ICreateReferencesImportResponse> =>
  axios
    .post("api/references_imports", params, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((res) => res.data)

export const apiGetReferencesImportExampleCsvUrl = `${apiHost}/api/references_imports/example_csv`
