import { Dictionary, difference, isEqual, union, uniqWith } from "lodash"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import {
  IArgument,
  IArgumentSegmentRule,
  IArgumentSegmentRuleMultiple,
} from "api/types/arguments"
import { IPlaybook } from "api/types/playbooks"

import { filterNonArchived } from "./archivable"

export const operatorsWithSelect = ["ONLY", "EXCEPT"]
export const updatableOperators = ["ONLY", "EXCEPT", "ALL"]

function defaultSegmentRule(
  argumentSegmentation: IArgumentSegmentation
): IArgumentSegmentRule {
  return {
    argumentSegmentationId: argumentSegmentation.id,
    operator: "ALL",
  }
}

function multipleSegmentRule(
  argumentSegmentation: IArgumentSegmentation
): IArgumentSegmentRuleMultiple {
  return {
    argumentSegmentationId: argumentSegmentation.id,
    operator: "MULTIPLE",
  }
}

// Fallback on a default segment rule
export function getSegmentRule(
  argument: IArgument,
  argumentSegmentation: IArgumentSegmentation
) {
  return (
    argument.segmentRules.find(
      (x) => x.argumentSegmentationId === argumentSegmentation.id
    ) || defaultSegmentRule(argumentSegmentation)
  )
}

function getSegmentRules(
  _arguments: IArgument[],
  argumentSegmentation: IArgumentSegmentation
) {
  return _arguments.map((argument) =>
    getSegmentRule(argument, argumentSegmentation)
  )
}

export function findSegmentRule(
  segmentRules: IArgumentSegmentRule[],
  segmentationId: string
) {
  return segmentRules.find(
    (segmentRule) => segmentRule.argumentSegmentationId === segmentationId
  )
}

export function buildInitialSegmentRules(
  _arguments: IArgument[],
  playbook: IPlaybook
): (IArgumentSegmentRule | IArgumentSegmentRuleMultiple)[] {
  return playbook.argumentSegmentations.map((argumentSegmentation) => {
    const allRules = getSegmentRules(_arguments, argumentSegmentation)
    const areAllRulesTheSame = uniqWith(allRules, isEqual).length === 1

    if (areAllRulesTheSame) {
      return allRules[0]
    } else {
      return multipleSegmentRule(argumentSegmentation)
    }
  })
}

// When creating an argument to the current view,
//  the argument's segment rules match the current segment selection
export function buildSegmentRulesFromSegmentFilters(
  segmentFilters: Dictionary<string[]>
): IArgumentSegmentRule[] {
  return Object.entries(segmentFilters).map(
    ([argumentSegmentationId, argumentSegmentIds]) => ({
      argumentSegmentationId,
      operator: "ONLY",
      argumentSegmentIds: argumentSegmentIds,
    })
  )
}

// When adding an existing argument to the current view
export function addSegmentsIntersectionToSegmentRules(
  segmentRules: IArgumentSegmentRule[],
  segmentFilters: Dictionary<string[]>
): IArgumentSegmentRule[] {
  // We don't care about segmentations without an existing rule, because they match by default
  return segmentRules.map((segmentRule) => {
    const argumentSegmentIds =
      segmentFilters[segmentRule.argumentSegmentationId]
    if (!argumentSegmentIds || argumentSegmentIds.length === 0) {
      // We already match this segment, the rule doesn't change
      return segmentRule
    }
    if (segmentRule.operator === "ALL") {
      // We already match this segment, the rule doesn't change
      return segmentRule
    }
    if (segmentRule.operator === "ONLY") {
      // We add the segment to the rule, if not already present
      return {
        ...segmentRule,
        argumentSegmentIds: union(
          segmentRule.argumentSegmentIds,
          argumentSegmentIds
        ),
      }
    }
    if (segmentRule.operator === "EXCEPT") {
      return {
        ...segmentRule,
        argumentSegmentIds: difference(
          segmentRule.argumentSegmentIds,
          argumentSegmentIds
        ),
      }
    }
    return segmentRule // Should never get here
  })
}

export function filterUpdatableSegmentRules(
  segmentRules: (IArgumentSegmentRule | IArgumentSegmentRuleMultiple)[]
) {
  return segmentRules.filter(({ operator }) =>
    updatableOperators.includes(operator)
  )
}

export function filterArgumentSegments(
  argumentSegmentation: IArgumentSegmentation,
  argumentSegmentRule: IArgumentSegmentRule
) {
  return filterNonArchived(argumentSegmentation.argumentSegments).filter(
    (argumentSegment) =>
      (argumentSegmentRule.argumentSegmentIds || []).includes(
        argumentSegment.id
      )
  )
}
