import { ICRMCompany } from "./CRMCompanies"
import { ICRMExternalProperty } from "./CRMProperties"

export interface ICRMContact {
  id: string
  name: string
  email: string | null
  integrationName: string
  primaryCrmCompany: ICRMCompany | null
  externalId: string | null
  properties: ICRMExternalProperty[]
}

export interface ICRMContactsSearchParams {
  businessUnitId: string
  name: string
  externalSearchDisabled: boolean
  companyId: string | null
}

interface ICRMContactSearchResultCommon {
  id: string
  name: string
  email: string | null
  integrationName: string
  primaryCrmCompany: ICRMCompany | null
}

interface ICRMInternalContactSearchResult
  extends ICRMContactSearchResultCommon {
  origin: "DB"
  crmContact: ICRMContact
}

interface ICRMExternalContactSearchResult
  extends ICRMContactSearchResultCommon {
  origin: "EXTERNAL_CRM"
}

export type ICRMContactSearchResult =
  | ICRMInternalContactSearchResult
  | ICRMExternalContactSearchResult

export interface ICRMContactPullParams {
  businessUnitId: string
  contactExternalIds: string[]
}

export interface ICRMContactCreateParams {
  name: string
  email: string | null
  businessUnitId: string
  companyId: string | null
}

export interface ICRMContactResponse {
  crmContact: ICRMContact
}

export interface ICRMContactPullResponse {
  crmContacts: ICRMContact[]
}

export function isExternalContactSearchResult(
  contact: ICRMContactSearchResult
): contact is ICRMExternalContactSearchResult {
  return contact.origin === "EXTERNAL_CRM"
}
