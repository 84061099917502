import { Box, FormGroup } from "@mui/material"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgumentType } from "api/types/argumentTypes"

import Button from "ds/Button"
import Checkbox from "ds/Checkbox"
import FormControlLabel from "ds/FormControlLabel"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { filterArchived, filterNonArchived } from "services/archivable"
import { copyArgumentTypes } from "services/copyPaste"

import ShowArchivedToggle from "../ShowArchivedToggle"

interface Props {
  onSuccess: () => void
}

export default function CopyArgumentTypesSelector({ onSuccess }: Props) {
  const {
    playbook: { argumentTypes },
  } = usePlaybook()
  const [showArchived, setShowArchived] = useState(false)
  const [argumentTypeIdsToCopy, setArgumentTypeIdsToCopy] = useState<string[]>(
    []
  )

  const argumentTypesToShow = showArchived
    ? argumentTypes
    : filterNonArchived(argumentTypes)
  const hasArchived = filterArchived(argumentTypes).length > 0

  const toggleArgumentTypeFromCopyList = (
    addToList: boolean,
    argumentTypeId: string
  ) => {
    if (addToList) {
      setArgumentTypeIdsToCopy((prevState) => [...prevState, argumentTypeId])
    } else {
      setArgumentTypeIdsToCopy((prevState) =>
        prevState.filter((id) => id !== argumentTypeId)
      )
    }
  }

  const copy = () => {
    copyArgumentTypes(argumentTypeIdsToCopy)
    onSuccess()
  }

  return (
    <Stack spacing={2}>
      <Typography variant="lgSemi">
        <FormattedMessage id="playbookEdit.argumentTypes.copy" />
      </Typography>

      <FormGroup>
        {argumentTypesToShow.map((argumentType: IArgumentType) => (
          <FormControlLabel
            key={argumentType.id}
            sx={{ opacity: argumentType.discarded ? 0.5 : 1 }}
            control={
              <Checkbox
                onChange={(e) =>
                  toggleArgumentTypeFromCopyList(
                    e.target.checked,
                    argumentType.id
                  )
                }
              />
            }
            label={argumentType.name}
          />
        ))}
      </FormGroup>

      <Stack direction="row" justifyContent="space-between">
        <Box>
          {hasArchived && (
            <ShowArchivedToggle
              checked={showArchived}
              onChange={(e) => setShowArchived(e.target.checked)}
            />
          )}
        </Box>

        <Button onClick={copy} variant="contained">
          <FormattedMessage
            id="playbookEdit.argumentTypes.copy"
            defaultMessage="Copy tabs"
          />
        </Button>
      </Stack>
    </Stack>
  )
}
