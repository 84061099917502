import {
  CALL_SYNCED_ENTITY,
  MEETING_SYNCED_ENTITY,
} from "api/types/integrations"

import CRMMeetingSelector from "components/App/CRM/Meetings/CRMMeetingSelector"
import CRMCallSelector from "components/App/CRM/calls/CRMCallSelector"

import { useMeeting } from "../MeetingProvider"

export default function ExternalCRMEventSelector() {
  const {
    ongoingMeeting,
    isSubmitting,
    syncedEntityType,
    handleCRMMeetingChange,
    handleCRMCallChange,
    CRMMeeting,
    CRMCall,
  } = useMeeting()

  if (!ongoingMeeting) return <></>

  if (syncedEntityType === MEETING_SYNCED_ENTITY)
    return (
      <CRMMeetingSelector<false>
        value={CRMMeeting}
        onChange={handleCRMMeetingChange}
        businessUnitId={ongoingMeeting.businessUnitId}
        disabled={isSubmitting}
      />
    )

  if (syncedEntityType === CALL_SYNCED_ENTITY)
    return (
      <CRMCallSelector<false>
        value={CRMCall}
        onChange={handleCRMCallChange}
        businessUnitId={ongoingMeeting.businessUnitId}
        disabled={isSubmitting}
      />
    )

  return <></>
}
