import LaunchIcon from "@mui/icons-material/Launch"
import { Popper, autocompleteClasses } from "@mui/material"
import { sortBy } from "lodash"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import Autocomplete from "ds/Autocomplete"
import LinkRouterless from "ds/LinkRouterless"
import Stack from "ds/Stack"
import TextField from "ds/TextField"
import Tooltip from "ds/Tooltip"

import { useConfig } from "components/Config"

import { getPublicPlaybookPath } from "services/playbooks"

import { useImportablePlaybooksQuery } from "../businessUnitPlaybookQueries"

interface Props {
  onSelect: (playbookId: string) => void
  businessUnitId: string
}

const StyledPopper = styled(Popper)`
  .${autocompleteClasses.groupLabel} {
    background-color: ${({ theme }) => theme.palette.grey[200]};
    line-height: 40px;
  }
`

export default function ImportablePlaybooksSelector({
  onSelect,
  businessUnitId,
}: Props) {
  const {
    data: importablePlaybooks,
    isLoading,
    error,
  } = useImportablePlaybooksQuery()

  const config = useConfig()
  if (error) return <div>Oops</div>

  const options = (importablePlaybooks || []).map((importablePlaybook) => ({
    label: importablePlaybook.name,
    id: importablePlaybook.id,
    isPublic: importablePlaybook.isPublic,
    businessUnitName: importablePlaybook.businessUnitName || "-",
    businessUnitId: importablePlaybook.businessUnitId,
  }))

  const sortedOptions = sortBy(options, (option) => [
    option.isPublic ? 1 : 0,
    option.businessUnitName,
    option.businessUnitId,
    option.label,
  ])

  return (
    <Autocomplete
      fullWidth
      options={sortedOptions}
      PopperComponent={StyledPopper}
      groupBy={(option) => option.businessUnitName}
      loading={isLoading}
      multiple={false}
      freeSolo={false}
      value={null}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
          >
            <div>{option.label}</div>

            <Tooltip
              title={
                <FormattedMessage id="businessUnits.playbooks.actions.import.preview" />
              }
            >
              <LinkRouterless
                lineHeight={0}
                // Warning: businessId is the id of the current bu, not the playbook's bu
                // Regular users don't have access to the business unit of the public playbooks
                href={`${config.appUrl}${getPublicPlaybookPath(
                  businessUnitId,
                  option.id
                )}`}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                <LaunchIcon fontSize="small" />
              </LinkRouterless>
            </Tooltip>
          </Stack>
        </li>
      )}
      onChange={(_event, option) => !!option && onSelect(option.id)}
      inputLabel="Playbook"
      renderInput={(params) => <TextField {...params} autoFocus />}
    />
  )
}
