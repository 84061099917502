import axios from "./axios"
import { IInviteUserParams } from "./types/organizationInvitations"
import {
  ICreateOrganizationParams,
  ICreateOrganizationResponse,
  IGetOrganizationResponse,
  IGetOrganizationsParams,
  IGetOrganizationsResponse,
  IOrganizationMembershipsSearchResponse,
  ISearchOrganizationMembershipsParams,
  ISearchPendingInvitationsParams,
  ISearchPendingInvitationsResponse,
  IUpdateOrganizationParams,
  IUpdateOrganizationResponse,
} from "./types/organizations"

export const apiGetOrganization = (
  organizationId: string,
  {
    signal,
  }: {
    signal?: AbortSignal | undefined
  }
): Promise<IGetOrganizationResponse> =>
  axios
    .get(`api/organizations/${organizationId}`, { signal })
    .then((res) => res.data)

export const apiGetOrganizations = (
  params: IGetOrganizationsParams,
  {
    signal,
  }: {
    signal?: AbortSignal | undefined
  }
): Promise<IGetOrganizationsResponse> =>
  axios
    .get("api/organizations/search", { params, signal })
    .then((res) => res.data)

export const apiCreateOrganization = (
  params: ICreateOrganizationParams
): Promise<ICreateOrganizationResponse> =>
  axios.post("api/organizations", params).then((res) => res.data)

export const apiUpdateOrganization = (
  params: IUpdateOrganizationParams
): Promise<IUpdateOrganizationResponse> =>
  axios.patch(`api/organizations/${params.id}`, params).then((res) => res.data)

export const apiArchiveOrganization = (
  organizationId: string
): Promise<undefined> =>
  axios
    .patch(`api/organizations/${organizationId}/archive`)
    .then((res) => res.data)

export const apiRestoreOrganization = (
  organizationId: string
): Promise<undefined> =>
  axios
    .patch(`api/organizations/${organizationId}/restore`)
    .then((res) => res.data)

export const apiSearchOrganizationMemberships = (
  params: ISearchOrganizationMembershipsParams,
  { signal }: { signal?: AbortSignal }
): Promise<IOrganizationMembershipsSearchResponse> =>
  axios
    .get(`api/organizations/${params.organizationId}/search_memberships`, {
      params: params,
      signal,
    })
    .then((res) => res.data)

export const apiSearchPendingInvitations = (
  params: ISearchPendingInvitationsParams,
  { signal }: { signal?: AbortSignal }
): Promise<ISearchPendingInvitationsResponse> =>
  axios
    .get(`api/organizations/${params.organizationId}/pending_invitations`, {
      params: params,
      signal,
    })
    .then((res) => res.data)

export const inviteUser = ({
  organizationId,
  user,
}: {
  organizationId: string
  user: IInviteUserParams
}): Promise<undefined> =>
  axios
    .post(`api/organizations/${organizationId}/invite_user`, user)
    .then((res) => res.data)
