import { useEffect } from "react"
import { useParams } from "react-router-dom"

import Loader from "ds/Loader"
import UncontrolledError from "ds/UncontrolledError"

import { useBusinessUnit } from "./BusinessUnit/BusinessUnitProvider"
import {
  useCurrentMeetingQuery,
  useSubscriptionToPlaybookMeeting,
} from "./CRM/Meetings/meetingQueries"
import { useArgumentPersonalNoteGetAllQuery } from "./Playbook/Argument/ArgumentPersonalNote/argumentPersonalNoteQueries"
import MeetingProvider from "./Playbook/Meeting/MeetingProvider"
import {
  NavigateToLastAppliedSegments,
  NavigateToLastVisitedArgumentType,
} from "./Playbook/NavigateToLastAppliedParameters"
import PlaybookLayout from "./Playbook/PlaybookLayout"
import PlaybookLoaded from "./Playbook/PlaybookLoaded"
import PlaybookProvider from "./Playbook/PlaybookProvider"
import { setLastVisitedPlaybookId } from "./Playbook/lastVisit"
import {
  usePlaybookQuery,
  usePlaybookUsersQuery,
} from "./Playbook/queries/playbookQueries"

function usePersistLatestPlaybookInLS(
  businessUnitId: string,
  playbookId: string
) {
  useEffect(() => {
    setLastVisitedPlaybookId(businessUnitId, playbookId)
  }, [businessUnitId, playbookId])
}

export default function PlaybookRoot(): JSX.Element {
  const params = useParams()
  const businessUnit = useBusinessUnit()
  const playbookId = params.playbookId!

  return (
    <NavigateToLastVisitedArgumentType>
      <NavigateToLastAppliedSegments>
        <PlaybookPage
          businessUnitId={businessUnit.id}
          playbookId={playbookId}
        />
      </NavigateToLastAppliedSegments>
    </NavigateToLastVisitedArgumentType>
  )
}

interface Props {
  businessUnitId: string
  playbookId: string
}

function PlaybookPage({ businessUnitId, playbookId }: Props) {
  const playbookQuery = usePlaybookQuery(playbookId)
  const playbookUsersQuery = usePlaybookUsersQuery(playbookId)
  const ongoingMeetingQuery = useCurrentMeetingQuery(playbookId)
  //TODO test useSubscriptionToPlaybookMeeting
  useSubscriptionToPlaybookMeeting(playbookId)
  usePersistLatestPlaybookInLS(businessUnitId, playbookId)
  const argumentPersonalNotesQuery =
    useArgumentPersonalNoteGetAllQuery(playbookId)

  if (
    playbookQuery.isLoading ||
    playbookUsersQuery.isLoading ||
    ongoingMeetingQuery.isLoading ||
    argumentPersonalNotesQuery.isLoading
  ) {
    return <Loader />
  }
  if (
    playbookQuery.isError ||
    playbookUsersQuery.isError ||
    ongoingMeetingQuery.isError ||
    argumentPersonalNotesQuery.isError
  )
    return (
      <UncontrolledError
        error={
          playbookQuery.error ||
          playbookUsersQuery.error ||
          ongoingMeetingQuery.error ||
          argumentPersonalNotesQuery.error
        }
      />
    )

  return (
    <PlaybookProvider
      playbook={playbookQuery.data.playbook}
      canEdit={playbookQuery.data.canEdit}
      users={playbookUsersQuery.data}
      argumentPersonalNotes={
        argumentPersonalNotesQuery.data.argumentPersonalNotes
      }
    >
      <MeetingProvider
        ongoingMeeting={ongoingMeetingQuery.data}
        playbook={playbookQuery.data.playbook}
      >
        <PlaybookLayout>
          <PlaybookLoaded />
        </PlaybookLayout>
      </MeetingProvider>
    </PlaybookProvider>
  )
}
