import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import CreateOrganizationForm from "components/App/Organization/CreateOrganizationForm"
import UserOrganizationsList from "components/common/AvatarMenu/OrganizationToggle/MenuItems"

import { getOrganizationPath } from "services/organizations"

interface Props {
  onOrganizationCreate: () => void
}
export default function OrganizationSwitch({ onOrganizationCreate }: Props) {
  const [showCreateForm, setShowCreateForm] = useState(false)

  if (showCreateForm)
    return (
      <Stack>
        <CreateOrganizationForm
          addCurrentUser={true}
          onCreate={(data) => {
            onOrganizationCreate()
            // Hard refresh to reload the user
            if (IS_CHROME_EXTENSION) {
              window.open(getOrganizationPath(data.organization.id), "_blank")
            } else {
              window.location.assign(getOrganizationPath(data.organization.id))
            }
          }}
        />
        <Button onClick={() => setShowCreateForm(false)} color="neutral">
          <FormattedMessage id="common.back" />
        </Button>
      </Stack>
    )

  return (
    <Stack spacing={2}>
      <Typography variant="lgBold">
        <FormattedMessage id="navigation.organizationSwitch" />
      </Typography>

      <UserOrganizationsList />

      <Button onClick={() => setShowCreateForm(true)}>
        <FormattedMessage id="organizations.create.cta" />
      </Button>
    </Stack>
  )
}
