import { IArgumentGroup } from "api/types/argumentGroups"
import { IPlaybook } from "api/types/playbooks"

import changeArrayPosition from "utils/changeArrayPosition"
import normalizeString from "utils/normalizeString"

export function getArgumentGroup(playbook: IPlaybook, argumentGroupId: string) {
  return playbook.argumentGroups.find(
    (argumentGroup) => argumentGroup.id === argumentGroupId
  )
}

export function filterArgumentGroups(
  argumentGroups: IArgumentGroup[],
  searchTerm: string
) {
  if (searchTerm.length === 0) {
    return argumentGroups
  }
  const normalizedSearchTerm = normalizeString(searchTerm)
  return argumentGroups.filter(
    (argumentGroup) =>
      argumentGroup.rtRawName &&
      normalizeString(argumentGroup.rtRawName).includes(normalizedSearchTerm)
  )
}

export function filterArgumentGroupsInArgumentType(
  argumentGroups: IArgumentGroup[],
  argumentTypeId: string
) {
  return argumentGroups.filter(
    (argumentGroup) => argumentGroup.argumentTypeId === argumentTypeId
  )
}

export function moveArgumentGroup(
  argumentGroups: IArgumentGroup[],
  argumentGroupId: string,
  relativeArgumentGroupId: string
) {
  const oldPosition = argumentGroups.findIndex((x) => x.id === argumentGroupId)
  let newPosition = argumentGroups.findIndex(
    (x) => x.id === relativeArgumentGroupId
  )
  return changeArrayPosition(argumentGroups, oldPosition, newPosition)
}
