import { ICRMProperty } from "api/types/CRMProperties"
import { IArgument } from "api/types/arguments"
import { IMeetingNoteConfiguration } from "api/types/meetingNoteConfigurations"

import { updateById } from "utils/mutationHelpers"

export function replaceMeetingNoteConfiguration(
  _arguments: IArgument[],
  argumentId: string,
  newConfig: IMeetingNoteConfiguration | null
) {
  return updateById(_arguments, argumentId, (argument) => ({
    ...argument,
    meetingNoteConfiguration: newConfig,
  }))
}

export function isCRMPropertyAnswerable(property: ICRMProperty): boolean {
  return !property.readOnly
}
