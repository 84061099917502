import AddIcon from "@mui/icons-material/Add"
import { FormattedMessage, useIntl } from "react-intl"

import { IArgumentGroup } from "api/types/argumentGroups"

import Fab from "ds/Fab"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentCreateMutation } from "components/App/Playbook/queries/argumentQueries"

import { buildSegmentRulesFromSegmentFilters } from "services/argumentSegmentRules"

interface Props {
  argumentGroup: IArgumentGroup | null
}

export default function CreateArgument({ argumentGroup }: Props) {
  const intl = useIntl()
  const { playbook, segmentFilters, activeTypeId } = usePlaybook()
  const argumentCreateMutation = useArgumentCreateMutation(activeTypeId)

  return (
    <>
      <Fab
        variant="extended"
        size="small"
        onClick={() =>
          argumentCreateMutation.mutate({
            playbookId: playbook.id,
            argumentTypeId: activeTypeId,
            argumentGroupId: argumentGroup?.id,
            segmentRules: buildSegmentRulesFromSegmentFilters(segmentFilters),
          })
        }
        disabled={argumentCreateMutation.isLoading}
        sx={{
          color: "text.primary",
          bgcolor: "background.paper",
          whiteSpace: "nowrap",
        }}
        title={intl.formatMessage({
          id: "playbookEdit.arguments.create",
          defaultMessage: "New argument",
        })}
      >
        <AddIcon />
        {!argumentGroup && (
          <FormattedMessage
            id="playbookEdit.arguments.create"
            defaultMessage="New argument"
          />
        )}
      </Fab>
    </>
  )
}
