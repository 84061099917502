import truncate from "lodash/truncate"
import { FormattedMessage } from "react-intl"

import { IArgumentCommentNotification } from "api/types/notifications"

import Stack from "ds/Stack"
import Typography from "ds/Typography"
import { UnstyledRouterLink } from "ds/UnstyledLink"

import CommentResolvedCue from "components/App/Playbook/Argument/Comments/CommentResolvedCue"
import { getArgumentPath } from "components/App/Playbook/Argument/routing"
import Avatar from "components/common/Avatar"

import NotificationContainer from "../NotificationContainer"
import NotificationTimestamp from "../NotificationTimestamp"

const maxLengthComment = 70

interface Props {
  notification: IArgumentCommentNotification
}

export default function ArgumentCommentNotification({ notification }: Props) {
  const argumentId = notification.argumentComment.argumentId
  const playbookId = notification.argumentComment.playbookId
  const businessUnitId = notification.argumentComment.businessUnitId
  const argumentLocation = getArgumentPath(
    businessUnitId,
    playbookId,
    argumentId,
    "comments"
  )

  return (
    <UnstyledRouterLink to={argumentLocation}>
      <NotificationContainer notification={notification}>
        <Stack direction="row" spacing={2}>
          <Avatar
            userName={notification.argumentComment.user.name}
            userEmail={notification.argumentComment.user.email}
            userAvatarUrl={notification.argumentComment.user.thumbnailAvatarUrl}
            userDefaultAvatarColorCode={
              notification.argumentComment.user.defaultAvatarColorCode
            }
          />

          <div>
            <Typography variant="smNormal">
              {!!notification.argumentComment.resolvedByUser && (
                <CommentResolvedCue />
              )}
              <FormattedMessage
                id="notifications.notificationTypes.argumentComment.title"
                defaultMessage="<b>{userName}</b> commented on talking point <b>{argumentTitle}</b>"
                values={{
                  b: (chunks) => (
                    <Typography variant="smMed">{chunks}</Typography>
                  ),
                  userName:
                    notification.argumentComment.user.name ||
                    notification.argumentComment.user.email,
                  argumentTitle:
                    notification.argumentComment.argumentTitle || "-",
                }}
              />
            </Typography>

            <NotificationTimestamp notification={notification} />

            <Typography variant="smNormal">
              {truncate(notification.argumentComment.rawValue || "", {
                length: maxLengthComment,
              })}
            </Typography>
          </div>
        </Stack>
      </NotificationContainer>
    </UnstyledRouterLink>
  )
}
