import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt"
import { useState } from "react"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IArgumentSegment } from "api/types/argumentSegments"
import { IArgument } from "api/types/arguments"

import Chip from "ds/Chip"
import Stack from "ds/Stack"
import Tooltip from "ds/Tooltip"

import { filterNonArchived } from "services/archivable"
import {
  filterArgumentSegments,
  findSegmentRule,
} from "services/argumentSegmentRules"

import { useArgumentSegmentName } from "../../ArgumentSegmentation/ArgumentSegmentName"
import { usePlaybook } from "../../PlaybookProvider"
import EditArgumentSegmentRules from "./EditArgumentSegmentRules"
import SegmentRuleOperator from "./SegmentRuleOperator"

interface Props {
  argument: IArgument
}
export function SegmentRulesCues({ argument }: Props) {
  const { playbook } = usePlaybook()
  const [editSegmentRulesIsOpen, setEditSegmentRulesIsOpen] = useState(false)

  const segmentationLines = filterNonArchived(
    playbook.argumentSegmentations
  ).flatMap((segmentation) => {
    const rule = findSegmentRule(argument.segmentRules, segmentation.id)
    if (!rule) return []
    const segments = filterArgumentSegments(segmentation, rule)
    if (segments.length === 0) return []

    return [
      <Stack
        key={segmentation.id}
        direction="row"
        alignItems="center"
        spacing={0.5}
        rowGap={0.5}
        flexWrap="wrap"
        useFlexGap
      >
        {rule.operator === "EXCEPT" && (
          <Tooltip title={<SegmentRuleOperator operator="EXCEPT" />}>
            <DoNotDisturbAltIcon fontSize="small" />
          </Tooltip>
        )}
        {segments.map((segment) => (
          <SegmentRuleChip
            key={segment.id}
            segmentation={segmentation}
            segment={segment}
            onClick={() => setEditSegmentRulesIsOpen(true)}
          />
        ))}
      </Stack>,
    ]
  })

  return (
    <>
      <Stack spacing={1}>
        {segmentationLines.length !== 0 ? (
          segmentationLines
        ) : (
          <Chip
            sx={{ borderRadius: 1 }}
            onClick={() => setEditSegmentRulesIsOpen(true)}
            size="small"
            label={<SegmentRuleOperator operator="ALL" />}
          />
        )}
      </Stack>

      {editSegmentRulesIsOpen && (
        <EditArgumentSegmentRules
          arguments={[argument]}
          close={() => setEditSegmentRulesIsOpen(false)}
        />
      )}
    </>
  )
}

interface SegmentRuleChipProps {
  segmentation: IArgumentSegmentation
  segment: IArgumentSegment
  onClick: () => void
}

function SegmentRuleChip({
  segmentation,
  segment,
  onClick,
}: SegmentRuleChipProps) {
  const getSegmentName = useArgumentSegmentName()

  return (
    <Tooltip title={`${segmentation.name} - ${getSegmentName(segment)}`}>
      <Chip
        onClick={onClick}
        label={getSegmentName(segment)}
        sx={{
          borderRadius: 1,
          maxWidth: "105px", // Magic value to fit 3 chips in one line
          backgroundColor: segment.colorCode,
          "&:hover": {
            backgroundColor: segment.colorCode,
            opacity: 0.5,
          },
        }}
      />
    </Tooltip>
  )
}
