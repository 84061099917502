import { IVote } from "api/types/votes"

// How much the total vote count changes
export function getVoteCountDelta(
  direction: "up" | "down",
  upvotedDuringMeeting: boolean,
  downvotedDuringMeeting: boolean
) {
  if (!upvotedDuringMeeting && !downvotedDuringMeeting)
    return direction === "up" ? 1 : -1
  if (direction === "up" && upvotedDuringMeeting) return -1
  if (direction === "down" && downvotedDuringMeeting) return 1
  if (direction === "up" && downvotedDuringMeeting) return 2
  if (direction === "down" && upvotedDuringMeeting) return -2
  return 0 // should never get here
}

// What is the value of the current vote given the last one
export function getVoteValue(
  direction: "up" | "down",
  upvotedDuringMeeting: boolean,
  downvotedDuringMeeting: boolean
) {
  if (upvotedDuringMeeting && direction === "up") return 0
  if (downvotedDuringMeeting && direction === "down") return 0
  return direction === "up" ? 1 : -1
}

export function getUpvotes(votes: IVote[]) {
  return votes.filter((vote) => vote.count > 0)
}

export function getDownvotes(votes: IVote[]) {
  return votes.filter((vote) => vote.count < 0)
}
