import AttachmentIcon from "@mui/icons-material/Attachment"
import { useRef } from "react"
import { useIntl } from "react-intl"

import { uploadFiles } from "ds/RichTextNew/plugins/cloud"
import { useMyEditorRef } from "ds/RichTextNew/types"

import PillIconButton from "./PillIconButton"

interface Props {
  disabled: boolean
}

export default function AttachmentButton({ disabled }: Props) {
  const editor = useMyEditorRef()
  const intl = useIntl()
  const inputRef = useRef<HTMLInputElement>(null)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (!files) return
    uploadFiles(editor, files)
    // we set target value to null because if two uploaded files have the same name,
    // the upload of the second file will not trigger the `onChange` callback.
    event.target.value = ""
  }

  return (
    <>
      <PillIconButton
        label={intl.formatMessage({
          id: "richtext.upload",
          defaultMessage: "Attach a file (Maximum size: 100Mb)",
        })}
        onMouseDown={(e) => {
          e.preventDefault()
          if (inputRef.current) inputRef.current.click()
        }}
        disabled={disabled}
      >
        <input
          type="file"
          ref={inputRef}
          onChange={onChange}
          hidden
          aria-hidden="true"
          disabled={disabled}
          multiple
        />
        <AttachmentIcon sx={{ height: "16px", width: "16px" }} />
      </PillIconButton>
    </>
  )
}
