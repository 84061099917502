import { FormattedMessage } from "react-intl"

import Button from "ds/Button"

import { useCustomLogout } from "./Provider"

export default function LogOutButton() {
  const customLogout = useCustomLogout()

  return (
    <Button color="error" onClick={() => customLogout()}>
      <FormattedMessage id="navigation.logout" defaultMessage="Log out" />
    </Button>
  )
}
