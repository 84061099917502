import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import { SxProps, Theme } from "@mui/material"
import { useIntl } from "react-intl"
import { Path } from "react-router-dom"

import { getPlaybookEditViewPath, getPlaybookPath } from "services/playbooks"

import useSearchParam, { replaceSearchParam } from "utils/hooks/useSearchParam"

import { paramKey as argumentIdKey } from "./Argument/routing"
import { usePlaybook } from "./PlaybookProvider"
import { segmentsFiltersKey } from "./useSegmentsParam"
import { activeTypeFiltersKey } from "./useTypeIdParam"

export function useEditModeToggle() {
  const { playbook, editMode, activeTypeId, segmentFilters } = usePlaybook()
  const [argumentId] = useSearchParam(argumentIdKey)
  let toggleEditModeLocation: Partial<Path> = {
    pathname: editMode
      ? getPlaybookPath(playbook.businessUnitId, playbook.id)
      : getPlaybookEditViewPath(playbook.businessUnitId, playbook.id),
  }

  if (argumentId) {
    toggleEditModeLocation = replaceSearchParam({
      location: toggleEditModeLocation,
      key: argumentIdKey,
      value: argumentId,
    })
  }

  toggleEditModeLocation = replaceSearchParam({
    location: toggleEditModeLocation,
    key: activeTypeFiltersKey,
    value: activeTypeId,
  })

  toggleEditModeLocation = replaceSearchParam({
    location: toggleEditModeLocation,
    key: segmentsFiltersKey,
    value: segmentFilters,
  })

  return { editMode, toggleEditModeLocation }
}

export function useEditModeTogglelabel(editMode: boolean) {
  const intl = useIntl()
  return editMode
    ? intl.formatMessage({ id: "playbook.editModeToggle.view" })
    : intl.formatMessage({ id: "playbook.editModeToggle.edit" })
}

export function EditModeToggleIcon({
  editMode,
  sx,
}: {
  editMode: boolean
  sx?: SxProps<Theme>
}) {
  return editMode ? (
    <VisibilityOutlinedIcon sx={sx} fontSize="small" />
  ) : (
    <EditOutlinedIcon sx={sx} fontSize="small" />
  )
}
