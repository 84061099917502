import { PlateElement, useElement } from "@udecode/plate-common"
import { useLink } from "@udecode/plate-link"
import { forwardRef } from "react"
import styled from "styled-components"

import LinkRouterless from "ds/LinkRouterless"
import Tooltip from "ds/Tooltip"

import { MyLinkElement, MyPlateElementProps } from "../types"

export default forwardRef<HTMLDivElement, MyPlateElementProps>(function Link(
  props,
  ref
) {
  const element = useElement<MyLinkElement>()
  const { props: linkProps } = useLink({ element })
  const onClick = linkProps.onClick as React.MouseEventHandler | undefined

  return (
    <PlateElement
      asChild
      ref={ref}
      // `as any` is copied from the plate-ui component
      {...(linkProps as any)}
      onClick={(e) => {
        onClick?.(e)
        e.stopPropagation()
      }}
      {...props}
    >
      <Tooltip
        enterDelay={1000}
        title={<LinkUrl>{element.url}</LinkUrl>}
        placement="top"
      >
        <LinkRouterless target="_blank">{props.children}</LinkRouterless>
      </Tooltip>
    </PlateElement>
  )
})

const LinkUrl = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
