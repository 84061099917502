import { useIntl } from "react-intl"
import styled from "styled-components"

import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import { MyEditor, MyValue } from "ds/RichTextNew/types"

import isMobileDevice from "utils/isMobileDevice"
import { onKeyDownEnter } from "utils/onKeyDown"

interface StyleProps {
  disabled: boolean
}

const Container = styled.div<StyleProps>`
  padding: ${({ theme }) => theme.spacing(1)};
  padding-bottom: 0;
  font-size: ${({ theme }) => theme.typography.smNormal.fontSize};
  line-height: ${({ theme }) => theme.typography.smNormal.lineHeight};
  padding-bottom: 0;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1.0)};
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.grey[200] : theme.palette.common.white};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: none;
`

interface Props {
  value: MyValue
  rawValue: string
  onChange: (value: MyValue, rawValue: string) => void
  onSubmit: () => void
  disabled?: boolean
  richTextProps: Partial<RichTextProps>
  editorRef: React.MutableRefObject<MyEditor | null>
}

export default function CommentTextarea({
  value,
  rawValue,
  onChange,
  onSubmit,
  disabled = false,
  richTextProps,
  editorRef,
}: Props) {
  const isMobile = isMobileDevice()
  const intl = useIntl()
  return (
    <Container disabled={disabled}>
      <RichTextEditor
        value={value}
        {...richTextProps}
        disabled={disabled}
        onChange={onChange}
        // Shift + Enter adds a new line, which is the default behaviour
        // Enter without shift submits
        onKeyDown={onKeyDownEnter((e) => {
          if (isMobile) return
          if (e.isDefaultPrevented()) return
          if (e.shiftKey) return
          e.preventDefault()
          if (rawValue.length !== 0) {
            onSubmit()
          }
        })}
        placeholder={intl.formatMessage({
          id: "argumentComments.placeholder",
          defaultMessage: "Write a comment...",
        })}
        autoFocus
        editorRef={editorRef}
        toolbarProps={{ containerProps: { sx: { pr: "60px" } } }}
      />
    </Container>
  )
}
