import { useQuery } from "@tanstack/react-query"

import {
  apiIndexCRMProperties,
  apiRefreshCRMProperties,
} from "api/CRMProperties"

import { useInvalidatingMutation } from "utils/hooks/mutations"

function buildCurrentIntegrationPropertiesKey(businessUnitId: string) {
  return ["currentIntegration", businessUnitId, "properties"]
}

export const useCrmPropertiesQuery = (businessUnitId: string) =>
  useQuery(buildCurrentIntegrationPropertiesKey(businessUnitId), ({ signal }) =>
    apiIndexCRMProperties(businessUnitId, { signal })
  )

export function useCrmPropertiesRefreshMutation(businessUnitId: string) {
  return useInvalidatingMutation(
    buildCurrentIntegrationPropertiesKey(businessUnitId),
    apiRefreshCRMProperties
  )
}
