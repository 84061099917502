import { useTheme } from "@mui/material"
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { format, parseISO } from "date-fns"

import { textFieldCustomizations } from "ds/TextField"

import { useDateFnsLocale } from "translations/TranslationsProvider"

import mergeSx from "utils/mergeSx"

export interface DatePickerProps<TDate>
  extends Omit<
    MuiDatePickerProps<TDate>,
    "value" | "onChange" | "renderInput"
  > {
  value: string | null
  onChange: (newValue: string | null) => void
  fullWidth?: boolean
}

// The value is a string YYYY-MM-DD or the string "Invalid Date", though under the hood, we manipulate date-fns objects
export default function DatePicker({
  value,
  onChange,
  fullWidth = false,
  ...props
}: DatePickerProps<Date>) {
  const dateFnsLocale = useDateFnsLocale()
  const theme = useTheme()

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateFnsLocale}
    >
      <MuiDatePicker
        value={value && value !== "Invalid Date" ? parseISO(value) : null}
        onChange={(dateFnsValue) => {
          onChange(
            dateFnsValue
              ? dateFnsValue.toString() === "Invalid Date"
                ? "Invalid Date"
                : format(dateFnsValue, "yyyy-MM-dd")
              : null
          )
        }}
        {...props}
        slotProps={{ textField: { fullWidth } }}
        sx={mergeSx(textFieldCustomizations(theme), props.sx)}
      />
    </LocalizationProvider>
  )
}
