import { Box } from "@mui/material"
import { useToggleButton, useToggleButtonState } from "@udecode/plate"
import { PlateElement, useElement } from "@udecode/plate-common"

import { apiCreateLog } from "api/logs"

import AnchorDiv from "ds/AnchorDiv"

import { ReactComponent as CaretUp } from "images/caret-up.svg"

import { MyPlateElementProps, MyToggleElement } from "../types"

export default function Toggle(props: MyPlateElementProps) {
  const element = useElement<MyToggleElement>()
  const state = useToggleButtonState(element.id!)
  const { open, buttonProps } = useToggleButton(state)
  return (
    <PlateElement asChild {...props}>
      <AnchorDiv
        sx={{ pl: 3, position: "relative" }}
        onClick={(e) => {
          if (e.isDefaultPrevented()) return
          e.preventDefault()
          e.stopPropagation()
          buttonProps.onClick(e)
        }}
      >
        <ToggleIconContainer
          {...buttonProps}
          onClick={(e) => {
            buttonProps.onClick(e)
            apiCreateLog({
              action: state.open ? "COLLAPSE_TOGGLE" : "EXPAND_TOGGLE",
              countsAsActivity: true,
            })
          }}
        >
          <ToggleIcon isOpen={open} />
        </ToggleIconContainer>
        {props.children}
      </AnchorDiv>
    </PlateElement>
  )
}

function ToggleIconContainer({
  onClick,
  onMouseDown,
  children,
}: {
  onClick: (e: React.MouseEvent) => void
  onMouseDown: (e: React.MouseEvent) => void
  children: React.ReactNode
}) {
  return (
    <Box
      component="span"
      contentEditable={false}
      sx={{
        userSelect: "none",
        position: "absolute",
        top: 2,
        left: 6, // Magical value to align toggles with list markers
        cursor: "pointer",
        p: 0.5,
        transition: (theme) => theme.transitions.create("background-color"),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2px",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.grey[200],
        },
      }}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation()
        onClick(e)
      }}
      onMouseDown={onMouseDown}
    >
      {children}
    </Box>
  )
}

function ToggleIcon({ isOpen }: { isOpen: boolean }) {
  return (
    <Box
      component="span"
      sx={{
        transition: (theme) => theme.transitions.create("transform"),
        transform: `rotate(${isOpen ? "180deg" : "90deg"})`,
        lineHeight: 0,
      }}
    >
      <CaretUp width="8px" />
    </Box>
  )
}
