import Drawer from "ds/Drawer"
import { useIsSmallerThanMD } from "ds/Media"

import useToggle from "utils/hooks/useToggle"

import NavOpener from "./NavOpener"

interface Props {
  nav: React.ReactNode
  responsive: boolean
  closeOnClick?: boolean
}

// If `responsive` is true, the menu is not displayed in desktop
export default function NavMenu({
  nav,
  responsive = false,
  closeOnClick = true,
}: Props) {
  const isSmallerThanMD = useIsSmallerThanMD()
  const [isOpen, { toggleOn, toggleOff }] = useToggle(false)

  if (responsive && !isSmallerThanMD) {
    return null
  }

  return (
    <>
      <NavOpener onClick={toggleOn} />
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={toggleOff}
        variant="temporary"
        onClick={() => {
          if (closeOnClick) {
            toggleOff()
          }
        }}
      >
        {nav}
      </Drawer>
    </>
  )
}
