import InputAdornment from "@mui/material/InputAdornment"
import { useIntl } from "react-intl"

import { IMeetingArgumentNoteProbability } from "api/types/meetingArgumentNotes"
import { IMeetingNoteConfiguration } from "api/types/meetingNoteConfigurations"

import Stack from "ds/Stack"
import TextField from "ds/TextField"

import AdditionalInfo from "components/common/AdditionalInfo"

interface Props {
  note: IMeetingArgumentNoteProbability
  onChange: (newValue: number | null) => void
  onAdditionalInfoChange: (newValue: string) => void
  configuration: IMeetingNoteConfiguration
  isSaved: boolean
  disabled: boolean
  error: boolean
}

function toFormValue(value: number) {
  return Math.round(value * 100)
}

function toValue(value: string) {
  if (value === "") return null
  return parseFloat(value) / 100
}

export default function ProbabilityForm({
  note,
  onChange,
  onAdditionalInfoChange,
  configuration,
  isSaved,
  disabled,
  error,
}: Props) {
  const { hasAdditionalInfo } = configuration
  const intl = useIntl()
  return (
    <Stack direction="row">
      <TextField
        sx={{ width: "100%" }}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        placeholder={intl.formatMessage({
          id: "playbookEdit.argumentAnswer.form.typeSelect.probability",
        })}
        type="number"
        size="small"
        value={note.value ? toFormValue(note.value) : ""}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => onChange(toValue(e.target.value))}
        disabled={disabled}
        helperText={
          error
            ? intl.formatMessage({
                id: "playbookEdit.argumentAnswer.form.probabilityBuilder.description",
              })
            : ""
        }
        error={error}
      />

      {hasAdditionalInfo && (
        <AdditionalInfo
          value={note.additionalInfo || ""}
          onChange={onAdditionalInfoChange}
          isSaved={isSaved}
          disabled={disabled}
        />
      )}
    </Stack>
  )
}
