import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IBarePlaybook } from "api/types/playbooks"

import Breadcrumbs from "ds/Breadcrumbs"
import Divider from "ds/Divider"
import Link from "ds/Link"
import { AlertSnackbar } from "ds/Snackbar"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { getPlaybookPath } from "services/playbooks"

import { useBusinessUnit } from "../BusinessUnitProvider"
import BusinessUnitPlaybooksTable from "./BusinessUnitPlaybooksTable"
import CreatePlaybookCTAs from "./CreatePlaybookCTAs"
import NoPlaybookCreationCTAs from "./NoPlaybookCreationCTAs"

export default function BusinessUnitPlaybooks() {
  const businessUnit = useBusinessUnit()
  const [successSnackPlaybook, setSuccessSnackPlaybook] =
    useState<IBarePlaybook | null>(null)
  const livePlaybooks = businessUnit.playbooks.filter(
    (playbook) => !playbook.archived
  )
  const archivedPlaybooks = businessUnit.playbooks.filter(
    (playbook) => playbook.archived
  )

  if (businessUnit.playbooks.length === 0) {
    return (
      <Box sx={{ p: 2, mt: 16, display: "flex", justifyContent: "center" }}>
        <NoPlaybookCreationCTAs
          businessUnitId={businessUnit.id}
          onPlaybookCreation={(playbook) => setSuccessSnackPlaybook(playbook)}
        />
      </Box>
    )
  }

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Breadcrumbs>
        <Typography variant="smMed">{businessUnit.name}</Typography>
        <Typography variant="smMed">
          <FormattedMessage id="businessUnits.playbooks.title" />
        </Typography>
      </Breadcrumbs>

      <CreatePlaybookCTAs
        businessUnitId={businessUnit.id}
        onPlaybookCreation={(playbook) => setSuccessSnackPlaybook(playbook)}
      />

      {livePlaybooks.length > 0 && (
        <BusinessUnitPlaybooksTable playbooks={livePlaybooks} />
      )}

      {archivedPlaybooks.length > 0 && (
        <>
          <Divider>
            <FormattedMessage id="businessUnits.playbooks.archives" />
          </Divider>

          <Box sx={{ opacity: 0.5 }}>
            <BusinessUnitPlaybooksTable playbooks={archivedPlaybooks} />
          </Box>
        </>
      )}

      <AlertSnackbar
        open={!!successSnackPlaybook}
        onClose={() => setSuccessSnackPlaybook(null)}
        severity="success"
      >
        {!!successSnackPlaybook ? (
          <SuccessMessage playbook={successSnackPlaybook} />
        ) : undefined}
      </AlertSnackbar>
    </Stack>
  )
}

interface SuccessMessageProps {
  playbook: IBarePlaybook
}

export function SuccessMessage({ playbook }: SuccessMessageProps) {
  return (
    <Stack spacing={1} justifyContent="start">
      <Typography variant="baseSemi">
        <FormattedMessage id="businessUnits.playbooks.actions.create.success" />
      </Typography>

      <Link to={getPlaybookPath(playbook.businessUnitId, playbook.id)}>
        <FormattedMessage id="businessUnits.playbooks.actions.create.success.cta" />
      </Link>
    </Stack>
  )
}
