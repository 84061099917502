// TODO rename methods to be more explicit
import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"

// Component to allow displaying a different component for each media
// Usage:
// <Media
//    desktop={<div>desktop view</div>}
//    mobile={<div>Mobile view</div>}
// />

export function useIsSmallerThanMD() {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("md"))
}

export const useIsSmallerThanSM = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down("sm"))
}

interface MediaProps {
  desktop?: React.ReactNode
  mobile?: React.ReactNode
}

// `tablet` behaviour is the same as mobile
const Media = ({ desktop = null, mobile = null }: MediaProps) => {
  const isSmallerThanMDOrMobile = useIsSmallerThanMD()
  if (isSmallerThanMDOrMobile) {
    return <>{mobile}</>
  } else {
    return <>{desktop}</>
  }
}

export default Media
