import { Box } from "@mui/material"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import {
  IArgumentSegmentRule,
  IArgumentSegmentRuleMultiple,
  IArgumentSegmentRuleOperator,
} from "api/types/arguments"

import FormControl from "ds/FormControl"
import Typography from "ds/Typography"

import ArgumentSegmentationName from "components/App/Playbook/ArgumentSegmentation/ArgumentSegmentationName"

import { filterNonArchived } from "services/archivable"
import { operatorsWithSelect } from "services/argumentSegmentRules"

import SegmentRuleOperatorSelect from "./SegmentRuleOperatorSelect"
import SegmentRuleSegmentsSelect from "./SegmentRuleSegmentsSelect"

interface Props {
  argumentSegmentation: IArgumentSegmentation
  segmentRule: IArgumentSegmentRule | IArgumentSegmentRuleMultiple
  setSegmentRule: React.Dispatch<
    React.SetStateAction<IArgumentSegmentRule | IArgumentSegmentRuleMultiple>
  >
}

export default function SegmentRule({
  argumentSegmentation,
  segmentRule,
  setSegmentRule,
}: Props) {
  // we want "MULTIPLE" to be displayed but not available in the selector options
  // however the MUI select components does not allow this
  // therefore we need to tweak the behavior and give an empty value to the component
  // in the case where the segment rule is "MULTIPLE"
  function defineSegmentRuleOperatorValue(
    operator: IArgumentSegmentRuleOperator
  ) {
    if (operator === "MULTIPLE") return ""
    return operator
  }

  return (
    <>
      <Box overflow="hidden" textOverflow="ellipsis">
        <Typography variant="xsNormal">
          <ArgumentSegmentationName
            argumentSegmentation={argumentSegmentation}
          />
        </Typography>
      </Box>

      <SegmentRuleOperatorSelect
        value={defineSegmentRuleOperatorValue(segmentRule.operator)}
        onChange={(e) => {
          const newOperator = e.target.value as IArgumentSegmentRuleOperator
          if (newOperator === "ALL") {
            setSegmentRule((prev) => ({
              argumentSegmentationId: prev.argumentSegmentationId,
              operator: newOperator,
            }))
          } else {
            setSegmentRule((prev) => ({
              ...prev,
              operator: newOperator,
              argumentSegmentIds: [],
            }))
          }
        }}
      />

      {operatorsWithSelect.includes(segmentRule.operator) ? (
        <FormControl>
          <SegmentRuleSegmentsSelect
            value={segmentRule.argumentSegmentIds || []}
            onChange={(e) => {
              const value = e.target.value
              const newArgumentSegmentIds =
                typeof value === "string" ? value.split(",") : value
              // On autofill we get a the stringified value.
              setSegmentRule((prev) => ({
                ...prev,
                argumentSegmentIds: newArgumentSegmentIds,
              }))
            }}
            argumentSegments={filterNonArchived(
              argumentSegmentation.argumentSegments
            )}
          />
        </FormControl>
      ) : (
        <div /> // Empty div for the grid
      )}
    </>
  )
}
