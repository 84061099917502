import { FormattedMessage, useIntl } from "react-intl"

import { IArgumentWithMeetingNoteConfiguration } from "api/types/arguments"

import Autocomplete from "ds/Autocomplete"

import { getRawTitle } from "services/argumentFields"

interface SelectorProps {
  arguments: IArgumentWithMeetingNoteConfiguration[]
  meetingNoteConfigurationId: string | null
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: IOption | null
  ) => void
}

interface IOption {
  label: string
  value: string
}

export default function CRMMeetingNoteConfigurationSelector({
  arguments: _arguments,
  meetingNoteConfigurationId,
  onChange,
}: SelectorProps) {
  const intl = useIntl()

  function buildOptions(): IOption[] {
    return _arguments.map((arg) => buildOption(arg))
  }

  function buildOption(argument: IArgumentWithMeetingNoteConfiguration) {
    return {
      label:
        getRawTitle(argument) ||
        intl.formatMessage({
          id: "playbookEdit.argument.defaultTitle",
        }),
      value: argument.meetingNoteConfiguration.id,
    }
  }

  function buildOptionFromValue(value: string | null) {
    if (!value) return null

    const argWithValue = _arguments.find(
      (arg) => arg.meetingNoteConfiguration?.id === value
    )

    if (!argWithValue) return null

    return buildOption(argWithValue)
  }

  return (
    <Autocomplete<IOption, false, false, false>
      fullWidth
      options={buildOptions()}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <li {...props}>
          <div>{option.label}</div>
        </li>
      )}
      inputLabel={
        <FormattedMessage id="playbookEdit.argumentSegmentations.linkToMeetingNoteConfiguration" />
      }
      value={buildOptionFromValue(meetingNoteConfigurationId || null)}
      onChange={onChange}
    />
  )
}
