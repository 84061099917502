import { FormattedMessage, useIntl } from "react-intl"

import Breadcrumbs from "ds/Breadcrumbs"
import Loader from "ds/Loader"
import Paper from "ds/Paper"
import Stack from "ds/Stack"
import Table from "ds/Table"
import TableBody from "ds/TableBody"
import TableCell from "ds/TableCell"
import TableContainer from "ds/TableContainer"
import TableHead from "ds/TableHead"
import TableRow from "ds/TableRow"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import { useBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"
import { useConfig } from "components/Config"
import IntegrationIcon from "components/common/IntegrationIcon"

import ApplicationIntegrationActions from "./ApplicationIntegrationActions"
import ApplicationsIntegrationStatusCue from "./ApplicationsIntegrationStatusCue"
import { useApplicationIntegrationQuery } from "./applicationIntegrationsQueries"

export default function ApplicationsIntegrations() {
  const intl = useIntl()
  const businessUnit = useBusinessUnit()
  const { availableCrmIntegrations } = useConfig()

  const {
    isLoading,
    isError,
    error,
    data: currentCRMIntegration,
  } = useApplicationIntegrationQuery(businessUnit.id)

  if (isLoading) return <Loader />
  if (isError) return <UncontrolledError error={error} />

  return (
    <Stack spacing={2} p={2}>
      <Breadcrumbs>
        <Typography variant="smMed">{businessUnit.name}</Typography>
        <Typography variant="smMed">
          <FormattedMessage id="navigation.integrations" />
        </Typography>
      </Breadcrumbs>

      <TableContainer
        component={Paper}
        sx={{ boxShadow: 6, maxWidth: "850px" }}
      >
        <Table
          aria-label={intl.formatMessage({
            id: "settings.applicationsIntegration.actions",
            defaultMessage: "Applications integration actions",
          })}
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="settings.applicationsIntegration.table.applicationName"
                  defaultMessage="Application name"
                />
              </TableCell>

              <TableCell>
                <FormattedMessage
                  id="settings.applicationsIntegration.table.status"
                  defaultMessage="Status"
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {availableCrmIntegrations.map((availableIntegration) => (
              <TableRow key={availableIntegration.name}>
                <TableCell scope="row" style={{ textTransform: "capitalize" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="baseSemi">
                      {availableIntegration.name}
                    </Typography>
                    <IntegrationIcon
                      integrationName={availableIntegration.name}
                    />
                  </Stack>
                </TableCell>

                <TableCell>
                  <ApplicationsIntegrationStatusCue
                    status={
                      availableIntegration.name === currentCRMIntegration?.name
                        ? currentCRMIntegration.status
                        : null
                    }
                    workspaceId={currentCRMIntegration?.workspaceId}
                  />
                </TableCell>

                <TableCell>
                  <ApplicationIntegrationActions
                    availableIntegration={availableIntegration}
                    integration={currentCRMIntegration}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
