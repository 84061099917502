import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { createPath, useNavigate } from "react-router-dom"

import IconButton from "ds/IconButton"

import { useConfig } from "components/Config"

import {
  EditModeToggleIcon,
  useEditModeToggle,
  useEditModeTogglelabel,
} from "../useEditModeToggle"

export default function EditModeToggle() {
  const config = useConfig()

  const { editMode, toggleEditModeLocation } = useEditModeToggle()
  const label = useEditModeTogglelabel(editMode)
  const navigate = useNavigate()

  return (
    <IconButton
      aria-label={label}
      onClick={(e) => {
        e.preventDefault()
        if (IS_CHROME_EXTENSION) {
          window.open(
            `${config.appUrl}${createPath(toggleEditModeLocation)}`,
            "_blank"
          )
        } else {
          // TODO keep scroll position
          navigate(toggleEditModeLocation)
        }
      }}
    >
      <EditModeToggleIcon editMode={editMode} />
    </IconButton>
  )
}
