import axios from "./axios"
import {
  IBusinessUnit,
  IBusinessUnitMembershipsSearchResponse,
  ICreateBusinessUnitParams,
  ICreateBusinessUnitResponse,
  IGetBusinessUnitResponse,
  ISearchBusinessUnitMembershipsParams,
  ISearchPendingInvitationsParams,
  ISearchPendingInvitationsResponse,
  IUpdateBusinessUnitParams,
} from "./types/businessUnits"
import { IUser } from "./types/users"

export const apiGetNavigableBusinessUnits = (
  organizationId: string,
  {
    signal,
  }: {
    signal?: AbortSignal | undefined
  } = {}
): Promise<IBusinessUnit[]> =>
  axios
    .get("api/business_units/navigables", {
      params: { organizationId },
      signal,
    })
    .then((res) => res.data)

export const apiGetBusinessUnit = (
  businessUnitId: string,
  {
    signal,
  }: {
    signal?: AbortSignal | undefined
  }
): Promise<IGetBusinessUnitResponse> =>
  axios
    .get(`api/business_units/${businessUnitId}`, { signal })
    .then((res) => res.data)

export const apiSearchBusinessUnitMemberships = (
  params: ISearchBusinessUnitMembershipsParams,
  { signal }: { signal?: AbortSignal }
): Promise<IBusinessUnitMembershipsSearchResponse> =>
  axios
    .get(`api/business_units/${params.businessUnitId}/search_memberships`, {
      params: params,
      signal,
    })
    .then((res) => res.data)

export const apiGetBusinessUnitAddableUsers = (
  businessUnitId: string,
  { signal }: { signal?: AbortSignal }
): Promise<IUser[]> =>
  axios
    .get(`api/business_units/${businessUnitId}/addable_users`, {
      signal,
    })
    .then((res) => res.data)

export const apiCreateBusinessUnit = (
  params: ICreateBusinessUnitParams
): Promise<ICreateBusinessUnitResponse> =>
  axios.post("api/business_units", params).then((res) => res.data)

export const apiUpdateBusinessUnit = (
  params: IUpdateBusinessUnitParams
): Promise<undefined> =>
  axios.patch(`api/business_units/${params.id}`, params).then((res) => res.data)

export const apiSearchPendingInvitations = (
  params: ISearchPendingInvitationsParams,
  { signal }: { signal?: AbortSignal }
): Promise<ISearchPendingInvitationsResponse> =>
  axios
    .get(`api/business_units/${params.businessUnitId}/pending_invitations`, {
      params: params,
      signal,
    })
    .then((res) => res.data)

export const apiArchiveBusinessUnit = (
  businessUnitId: string
): Promise<undefined> =>
  axios
    .patch(`api/business_units/${businessUnitId}/archive`)
    .then((res) => res.data)
