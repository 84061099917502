import List from "@mui/material/List"
import { useNavigate } from "react-router-dom"

import useResolvedParameter from "utils/hooks/useResolvedParameter"

import NavEntry from "./BasicNav/NavEntry"

interface Entry {
  href: string
  icon?: React.ElementType
  title: React.ReactNode
}

interface BasicNavProps {
  entries: Entry[]
}

export default function BasicNav({ entries }: BasicNavProps) {
  const navigate = useNavigate()
  const activeTab = useResolvedParameter()

  return (
    <List>
      {entries.map(({ href, icon, title }) => (
        <NavEntry
          key={href}
          onClick={() => navigate(href)}
          icon={icon}
          title={title}
          selected={activeTab === href}
        />
      ))}
    </List>
  )
}
