import axios from "./axios"
import { IVote, IVoteParams, IVoteUpdateNoteParams } from "./types/votes"

export const apiVoteOnArgument = (params: IVoteParams): Promise<IVote> =>
  axios.post("api/votes", params).then((res) => res.data)

export const apiUpdateVoteNote = (
  params: IVoteUpdateNoteParams
): Promise<IVote> =>
  axios
    .patch(`api/votes/${params.id}/update_note`, params)
    .then((res) => res.data)
