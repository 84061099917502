import { MyValue } from "ds/RichTextNew/types"

import { IUser } from "./users"

export interface IArgumentCommentReaction {
  emojiName: string
  reactorsIds: string[]
}

export const argumentCommentCategories = [
  "question",
  "client_feedback",
  "client_objection",
  "need_more_info",
  "suggestion",
  "performance",
] as const

export type IArgumentCommentCategory = typeof argumentCommentCategories[number]

export interface IArgumentComment {
  id: string
  rawValue: string | null
  value: MyValue | null
  category: IArgumentCommentCategory | null
  resolvedByUser: IUser | null
  createdAt: string
  lastEditedAt: string | null
  discardedAt: string | null
  user: IUser
  argumentId: string
  argumentTitle: string | null
  playbookId: string
  businessUnitId: string
  editedAt: boolean | null
  archivedAt: string | null
  canEdit: boolean
  canEditCategory: boolean
  canDelete: boolean
  canResolve: boolean
  canReact: boolean
  reactions: IArgumentCommentReaction[]
}

export interface IArgumentCommentsIndexParams {
  argumentId: string
}

export type IArgumentCommentsIndexResponse = IArgumentComment[]

export interface IArgumentCommentCreateParams {
  argumentId: string
  value: MyValue
  rawValue: string
  category: string | null
}

export interface IArgumentCommentCreateResponse {
  argumentComment: IArgumentComment
}

export interface IArgumentCommentUpdateParams {
  id: string
  argumentComment: {
    value?: MyValue
    rawValue?: string
    category?: string | null
  }
}

export interface IArgumentCommentToggleResolveParams {
  id: string
}

export interface IArgumentCommentUpdateResponse {
  argumentComment: IArgumentComment
}

export interface IArgumentCommentsCountParams {
  argumentId: string
}

export type IArgumentCommentsCountResponse = number

export interface IArgumentCommentReactionParams {
  commentId: string
  emojiName: string
}

export type IArgumentCommentReactionResponse = IArgumentComment
