import FormatColorTextIcon from "@mui/icons-material/FormatColorText"
import { Box } from "@mui/material"
import {
  MARK_COLOR,
  useColorDropdownMenu,
  useColorDropdownMenuState,
} from "@udecode/plate"
import { useRef } from "react"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"

import Button from "ds/Button"
import Popover from "ds/Popover"
import { mod, shift } from "ds/RichTextNew/hotkeys"
import { FONT_COLORS } from "ds/RichTextNew/plugins/fontColorPlugin"
import Stack from "ds/Stack"
import Tooltip from "ds/Tooltip"
import UnstyledButton from "ds/UnstyledButton"

import { DialogClassName } from "../DialogClassName"
import PillIconButton from "./PillIconButton"

interface Props {
  disabled: boolean
}

export default function FontColorButton({ disabled }: Props) {
  const intl = useIntl()
  const anchorRef = useRef<HTMLDivElement>(null)

  const state = useColorDropdownMenuState({
    nodeType: MARK_COLOR,
    colors: buildColors(intl),
    customColors: [],
    closeOnSelect: true,
  })

  const { menuProps, buttonProps } = useColorDropdownMenu(state)

  return (
    <>
      <div ref={anchorRef}>
        <PillIconButton
          onMouseDown={(event) => {
            event.preventDefault()
            menuProps.onOpenChange(true)
          }}
          disabled={disabled}
          shortcut={[mod, shift, "C"]}
          label={intl.formatMessage({
            id: "richtext.highlight",
            defaultMessage: "Highlight",
          })}
          {...buttonProps}
        >
          <FormatColorTextIcon
            sx={{
              height: "16px",
              width: "16px",
              fill: state.selectedColor,
            }}
          />
        </PillIconButton>
      </div>

      <Popover
        anchorEl={anchorRef.current}
        open={menuProps.open}
        onClose={() => menuProps.onOpenChange(false)}
        disableEnforceFocus
        className={DialogClassName}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack sx={{ p: 1 }} spacing={1}>
          {state.selectedColor && (
            <Button
              onMouseDown={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.preventDefault()
                event.stopPropagation()
                menuProps.onOpenChange(false)
                state.clearColor()
              }}
            >
              <FormattedMessage id="richtext.font.color.remove" />
            </Button>
          )}

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(6, 1fr)",
              gap: 1,
            }}
            onMouseDown={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            {state.colors.map((color) => (
              <Tooltip title={color.name} key={color.value}>
                <UnstyledButton
                  onMouseDown={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    state.updateColorAndClose(color.value)
                  }}
                >
                  <Box
                    height="20px"
                    width="20px"
                    borderRadius={0.5}
                    sx={{ color: color.value }}
                  >
                    A
                  </Box>
                </UnstyledButton>
              </Tooltip>
            ))}
          </Box>
        </Stack>
      </Popover>
    </>
  )
}

function buildColors(intl: IntlShape) {
  return FONT_COLORS.map((config) => ({
    name: intl.formatMessage({ id: config.intlKey }),
    value: config.value,
    isBrightColor: config.isBrightColor,
  }))
}
