import { useQuery } from "@tanstack/react-query"

import {
  apiGetAllArgumentPersonalNote,
  apiRemoveArgumentPersonalNote,
  apiUpsertArgumentPersonalNote,
} from "api/argumentPersonalNotes"

import { useInvalidatingMutation } from "utils/hooks/mutations"

const buildArgumentPersonalNoteKey = (playbookId: string) => [
  "playbook",
  "argumentPersonalNotes",
  playbookId,
]

export const useArgumentPersonalNoteUpsertMutation = (playbookId: string) =>
  useInvalidatingMutation(
    buildArgumentPersonalNoteKey(playbookId),
    apiUpsertArgumentPersonalNote
  )

export const useArgumentPersonalNoteRemoveMutation = (playbookId: string) =>
  useInvalidatingMutation(
    buildArgumentPersonalNoteKey(playbookId),
    apiRemoveArgumentPersonalNote
  )

export const useArgumentPersonalNoteGetAllQuery = (playbookId: string) =>
  useQuery(buildArgumentPersonalNoteKey(playbookId), ({ signal }) =>
    apiGetAllArgumentPersonalNote({ playbookId }, { signal })
  )
