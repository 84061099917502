import styled from "styled-components"

import { INotification } from "api/types/notifications"

interface ContainerProps {
  $seen: boolean
}

const Container = styled.div<ContainerProps>`
  padding: ${({ theme }) => theme.spacing(2)};
  margin: ${({ theme }) => theme.spacing(-2)};
  background-color: ${({ theme, $seen }) =>
    $seen ? theme.palette.background.paper : theme.palette.background.paper2};
`

interface Props {
  notification: INotification
  children: React.ReactNode
}

export default function NotificationContainer({
  notification,
  children,
}: Props) {
  return <Container $seen={!!notification.seenAt}>{children}</Container>
}
