import {
  IMeetingLinkedToExternalCRM,
  IMeetingLinkedToExternalCRMCall,
} from "api/types/meetings"

const DEFAULT_URL_HUBSPOT = "https://app.hubspot.com"

export function getCRMMeetingUrl(meeting: IMeetingLinkedToExternalCRM) {
  switch (meeting.integrationName) {
    case "hubspot":
      return getHubSpotMeetingURL(meeting)
    case "salesforce":
      return getSalesForceMeetingURL(meeting)
    default:
      return ""
  }
}

export function getCRMMeetingCallUrl(meeting: IMeetingLinkedToExternalCRMCall) {
  switch (meeting.integrationName) {
    case "hubspot":
      return getHubSpotMeetingCallURL(meeting)
    case "salesforce":
      return getSalesForceMeetingCallURL(meeting)
    default:
      return ""
  }
}

function getHubSpotMeetingCallURL(meeting: IMeetingLinkedToExternalCRMCall) {
  return `${DEFAULT_URL_HUBSPOT}/calls/${meeting.workspaceId}/review/${meeting.externalCallId}`
}

function getHubSpotMeetingURL(meeting: IMeetingLinkedToExternalCRM) {
  if (meeting.crmContacts.length > 0) {
    return getHubspotMeetingURLFromContact(meeting)
  } else if (!!meeting.crmCompany) {
    return getHubspotMeetingURLFromCompany(meeting)
  }
  return DEFAULT_URL_HUBSPOT
}

function getHubspotMeetingURLFromContact(meeting: IMeetingLinkedToExternalCRM) {
  return `${DEFAULT_URL_HUBSPOT}/contacts/${meeting.workspaceId}/contact/${meeting.crmContacts[0].externalId}/?engagement=${meeting.externalId}`
}

function getHubspotMeetingURLFromCompany(meeting: IMeetingLinkedToExternalCRM) {
  return `${DEFAULT_URL_HUBSPOT}/companies/${meeting.workspaceId}/company/${meeting.crmCompany?.externalId}/?engagement=${meeting.externalId}`
}

function getSalesForceMeetingURL(meeting: IMeetingLinkedToExternalCRM) {
  return `${meeting.workspaceId}/lightning/r/Event/${meeting.externalId}/view`
}

function getSalesForceMeetingCallURL(meeting: IMeetingLinkedToExternalCRMCall) {
  return `${meeting.workspaceId}/lightning/r/Event/${meeting.externalCallId}/view`
}
