import { Box } from "@mui/material"
import { useIntl } from "react-intl"
import styled from "styled-components"

import { IArgumentFieldValue } from "api/types/argumentFieldValues"
import { IArgumentField } from "api/types/argumentFields"
import { IArgument } from "api/types/arguments"

import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import { MyValue } from "ds/RichTextNew/types"
import Stack from "ds/Stack"
import Typography, { TypographyProps } from "ds/Typography"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import ArgumentFieldName from "./ArgumentFieldName"
import DeleteArgumentFieldValue from "./DeleteArgumentFieldValue"

const StackContainer = styled(Stack)`
  .argument-field-value-actions {
    opacity: 0.5;
    transition: ${({ theme }) => theme.transitions.create("opacity")};
  }

  &:hover {
    .argument-field-value-actions {
      opacity: 1;
    }
  }
`

interface FieldValueProps {
  value: MyValue
  richTextProps: Partial<RichTextProps>
  typographyVariant?: TypographyProps["variant"]
}

export function FieldValue({
  value,
  richTextProps,
  typographyVariant = "smNormal",
}: FieldValueProps) {
  return (
    <Typography variant={typographyVariant}>
      <RichTextEditor {...richTextProps} value={value} readOnly />
    </Typography>
  )
}

interface Props {
  argument: IArgument
  argumentField: IArgumentField
  argumentFieldValue: IArgumentFieldValue
  onDeleteField: () => void
  containerProps?: React.HTMLAttributes<HTMLDivElement>
  richTextProps: Partial<RichTextProps>
}

export default function ArgumentFieldValueDisplay({
  argument,
  argumentField,
  argumentFieldValue,
  onDeleteField,
  containerProps = {},
  richTextProps,
}: Props) {
  const { editMode } = usePlaybook()
  const intl = useIntl()
  const defaultTitle = intl.formatMessage({
    id: "playbookEdit.argument.defaultTitle",
    defaultMessage: "Title",
  })
  const value =
    argumentField.type === "TITLE"
      ? argumentFieldValue.value ||
        buildNodeFromText(defaultTitle, { bold: true })
      : argumentFieldValue.value || buildNodeFromText("-")

  return (
    <StackContainer direction="row" spacing={1}>
      <Stack {...containerProps} spacing={1} flexGrow={1} minWidth={0}>
        {argumentField.type !== "TITLE" && (
          <Typography
            component="div"
            variant="baseSemi"
            sx={{ pr: 2, overflow: "hidden", textOverflow: "ellipsis" }}
          >
            <ArgumentFieldName argumentField={argumentField} />
          </Typography>
        )}

        <FieldValue
          richTextProps={richTextProps}
          value={value}
          typographyVariant={
            argumentField.type === "TITLE" ? "baseNormal" : "smNormal"
          }
        />
      </Stack>

      {editMode && (
        <Box className="argument-field-value-actions">
          <DeleteArgumentFieldValue
            argument={argument}
            argumentField={argumentField}
            afterDelete={onDeleteField}
          />
        </Box>
      )}
    </StackContainer>
  )
}
