// TODO TEST
import SearchIcon from "@mui/icons-material/Search"
import { Box } from "@mui/material"
import isHotkey from "is-hotkey"
import { Dictionary } from "lodash"
import { useIntl } from "react-intl"

import { IPlaybook } from "api/types/playbooks"

import IconButton from "ds/IconButton"
import Modal from "ds/Modal"
import { mod } from "ds/RichTextNew/hotkeys"
import Shortcut from "ds/Shortcut"
import Tooltip from "ds/Tooltip"

import useKeyboardListener from "utils/hooks/useKeyboardListener"

import SearchModalContent from "./Search/SearchModalContent"
import useArgumentSearchQueryParam from "./Search/useArgumentSearchQueryParam"

interface Props {
  playbook: IPlaybook
  initialSegmentFilters: Dictionary<string[]>
}

export default function Search({ playbook, initialSegmentFilters }: Props) {
  const [queryParam, setQueryParam, { isOpen, onClose, open }] =
    useArgumentSearchQueryParam()
  const intl = useIntl()
  useKeyboardListener((e) => {
    if (isHotkey("mod+k", e)) {
      open()
      e.preventDefault()
      e.stopPropagation()
    }
  })

  const label = intl.formatMessage({
    id: "playbook.search.title",
    defaultMessage: "Search",
  })

  return (
    <>
      <Tooltip
        title={
          <Box textAlign="center">
            <Box>{label}</Box>
            <Shortcut keys={[mod, "k"]} />
          </Box>
        }
      >
        <IconButton
          aria-label={label}
          onClick={open}
          sx={{ border: "solid 1px white", borderRadius: 1 }}
        >
          <SearchIcon
            fontSize="small"
            sx={{ fill: (theme) => theme.palette.common.white }}
          />
        </IconButton>
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        hideClosingCross
        heightVariant="stickyTop"
        widthVariant="medium"
      >
        <SearchModalContent
          playbook={playbook}
          initialSegmentFilters={initialSegmentFilters}
          queryParam={typeof queryParam === "string" ? queryParam : ""}
          setQueryParam={setQueryParam}
          onClose={onClose}
        />
      </Modal>
    </>
  )
}
