import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import InputAdornment from "@mui/material/InputAdornment"
import { useIntl } from "react-intl"

import IconButton from "ds/IconButton"

interface SubmitInputAdornmentProps {
  onSubmit: React.MouseEventHandler<HTMLButtonElement>
  onCancel: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
}

export default function SubmitInputAdornment({
  onSubmit,
  onCancel,
  disabled = false,
}: SubmitInputAdornmentProps) {
  const intl = useIntl()

  const submitLabel = intl.formatMessage({
    id: "common.submit",
    defaultMessage: "Submit",
  })

  const cancelLabel = intl.formatMessage({
    id: "common.cancel",
    defaultMessage: "Cancel",
  })

  return (
    <InputAdornment position="end">
      <IconButton
        aria-label={submitLabel}
        onClick={onSubmit}
        disabled={disabled}
      >
        <CheckIcon fontSize="small" />
      </IconButton>

      <IconButton
        aria-label={cancelLabel}
        onClick={onCancel}
        disabled={disabled}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </InputAdornment>
  )
}
