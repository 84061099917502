import React, { forwardRef } from "react"

import UncontrolledError from "ds/UncontrolledError"

interface ErrorBoundaryProps {
  FallbackComponent?: React.ComponentType<FallbackComponentProps>
  children: React.ReactNode
}

interface State {
  error: null | Error
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state: State = { error: null }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error) {
    // @ts-ignore
    const RumGlobal = window.DD_RUM
    if (RumGlobal) {
      RumGlobal.addError(error)
    }
  }

  render() {
    const { FallbackComponent = UncontrolledError, children } = this.props
    if (this.state.error) return <FallbackComponent error={this.state.error} />
    return children
  }
}

export interface FallbackComponentProps {
  error: Error
}

export function withErrorBoundary<T extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<T>
) {
  const displayName = Component.displayName || Component.name || "Component"

  const ComponentWithErrorBoundary = forwardRef((props: T, ref) => {
    return (
      <ErrorBoundary>
        <Component ref={ref} {...(props as T)} />
      </ErrorBoundary>
    )
  })
  ComponentWithErrorBoundary.displayName = `withErrorBoundary(${displayName})`
  return ComponentWithErrorBoundary
}
