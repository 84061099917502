import { useIntl } from "react-intl"

// If a `message` is provided, it prevails over `messageKey`
// `messageKey` can be `confirm` or `leaveWithoutSaving`
// By default, messageKey is `confirm`
// When calling confirm(), you can also provide an override
// TODO Use only the override

interface Props {
  message?: string
  messageKey?: "leaveWithoutSaving"
}

const useConfirm = ({ message, messageKey }: Props = {}) => {
  const intl = useIntl()
  let confirmationMessage = ""
  if (message) {
    confirmationMessage = message
  } else if (messageKey === "leaveWithoutSaving") {
    confirmationMessage = intl.formatMessage({
      id: "common.leaveWithoutSaving",
      defaultMessage: "Leave without saving?",
    })
  } else {
    confirmationMessage = intl.formatMessage({
      id: "common.confirm",
      defaultMessage: "Are you sure?",
    })
  }
  return () => window.confirm(confirmationMessage)
}

export default useConfirm
