import { DraggableProvided } from "@hello-pangea/dnd"
import InputAdornment from "@mui/material/InputAdornment"
import { useState } from "react"
import { createPortal } from "react-dom"
import { useDebouncedCallback } from "use-debounce"

import { IArgumentType } from "api/types/argumentTypes"

import DragAndDropHandle from "ds/DragAndDropHandle"
import { BasicErrorSnack } from "ds/Snackbar"
import TextField from "ds/TextField"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import {
  useArgumentTypeDeleteMutation,
  useArgumentTypeRestoreMutation,
  useArgumentTypeUpdateMutation,
} from "components/App/Playbook/queries/argumentTypeQueries"

import { isArchived } from "services/archivable"

import useConfirm from "utils/hooks/useConfirm"

import DeleteCTA from "./DeleteCTA"
import RestoreCTA from "./RestoreCTA"

const debounceWaitMs = 250

// Portal allows using Drag and Drop inside a modal
const portal = document.createElement("div")
document.body.appendChild(portal)

// DnD props might not be provided, meaning the DnD feature is disabled
interface Props {
  argumentType: IArgumentType
  providedDraggable?: DraggableProvided
  isDragging?: boolean
  inputRef?: React.RefObject<HTMLInputElement> | null
}

const ArgumentTypeInput = ({
  argumentType,
  providedDraggable,
  isDragging = false,
  inputRef,
}: Props) => {
  const initialName = argumentType.name || ""
  const [name, setName] = useState(initialName)
  const { playbook } = usePlaybook()
  const updateMutation = useArgumentTypeUpdateMutation(playbook.id)
  const deleteMutation = useArgumentTypeDeleteMutation(playbook.id)
  const restoreMutation = useArgumentTypeRestoreMutation(playbook.id)
  const confirm = useConfirm()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  function submit(name: string) {
    updateMutation.mutate(
      {
        argumentTypeId: argumentType.id,
        argumentType: { name },
      },
      {
        onError: () => setErrorSnackOpen(true),
      }
    )
  }
  const debouncedSubmit = useDebouncedCallback(submit, debounceWaitMs)

  function setNameAndSubmit(name: string) {
    setName(name)
    debouncedSubmit(name)
  }

  function onDelete() {
    if (!confirm()) return
    deleteMutation.mutate(argumentType.id)
  }

  function onRestore() {
    restoreMutation.mutate(argumentType.id)
  }

  const startAdornment = providedDraggable ? (
    <InputAdornment position="start">
      <DragAndDropHandle dragHandleProps={providedDraggable.dragHandleProps} />
    </InputAdornment>
  ) : undefined

  const endAdornment = (
    <InputAdornment position="end">
      {isArchived(argumentType) ? (
        <RestoreCTA onClick={onRestore} disabled={restoreMutation.isLoading} />
      ) : (
        <DeleteCTA onClick={onDelete} disabled={deleteMutation.isLoading} />
      )}
    </InputAdornment>
  )

  const node = (
    <>
      <TextField
        id={`argument-type-name-${argumentType.id}`}
        sx={{
          bgcolor: "background.paper",
          opacity: argumentType.discarded ? 0.5 : 1,
        }}
        value={name}
        onChange={(e) => setNameAndSubmit(e.target.value)}
        ref={providedDraggable?.innerRef}
        size="small"
        InputProps={{ endAdornment, startAdornment }}
        inputRef={inputRef}
        fullWidth
        {...providedDraggable?.draggableProps}
      />

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </>
  )

  if (!isDragging) {
    return node
  }

  return createPortal(node, portal)
}

ArgumentTypeInput.defaultProps = {
  draggableProps: {},
  dragHandleProps: {},
  draggableRef: undefined,
  isDragging: false,
}

export default ArgumentTypeInput
