import { SxProps, Theme } from "@mui/material"

import DSAvatar from "ds/Avatar"

import initials from "utils/initials"

interface Props {
  userName: string | null
  userEmail: string
  userDefaultAvatarColorCode: string
  userAvatarUrl: string | null
  sx?: SxProps<Theme>
}

export default function Avatar({
  userName,
  userEmail,
  userDefaultAvatarColorCode,
  userAvatarUrl,
  sx,
}: Props) {
  return (
    <DSAvatar
      src={userAvatarUrl ? userAvatarUrl : undefined}
      sx={{
        ...sx,
        backgroundColor: userDefaultAvatarColorCode,
        color: (theme) => theme.palette.grey[800],
      }}
    >
      {initials(userName || userEmail)}
    </DSAvatar>
  )
}
