import { useParams } from "react-router-dom"

import Loader from "ds/Loader"
import UncontrolledError from "ds/UncontrolledError"

import BusinessUnitMembershipsCreationForm from "./BusinessUnitMembershipsCreationForm"
import { useBusinessUnitAddableUsersQuery } from "./businessUnitQueries"

interface Props {
  onSuccess: () => void
}

export default function BusinessUnitMembershipsCreation({ onSuccess }: Props) {
  const params = useParams()
  const businessUnitId = params.businessUnitId!
  const { isLoading, isError, error, data } =
    useBusinessUnitAddableUsersQuery(businessUnitId)

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <UncontrolledError error={error} />
  }

  return (
    <BusinessUnitMembershipsCreationForm
      addableUsers={data}
      onSuccess={onSuccess}
    />
  )
}
