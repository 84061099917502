import { FormattedMessage } from "react-intl"

import NavMenu from "ds/BasicNav/NavMenu"

import Header from "components/layouts/Header"
import SidebarLayout from "components/layouts/SidebarLayout"

import CRMNav from "./CRMNav"

interface Props {
  children: React.ReactNode
  tab: React.ReactNode
}

export default function CRMLayout({ children, tab }: Props) {
  const crmNav = <CRMNav />
  const navMenu = <NavMenu nav={crmNav} responsive />

  const title = (
    <>
      <FormattedMessage id="navigation.CRM" defaultMessage="CRM" />
      {" / "}
      {tab}
    </>
  )

  const header = <Header title={title} leftContent={navMenu} />

  return (
    <SidebarLayout
      sidebar={crmNav}
      header={header}
      responsive
      contentProps={{ sx: { overflowY: "auto" } }}
    >
      {children}
    </SidebarLayout>
  )
}
