import axios from "./axios"
import {
  IArgumentSegmentationLinkToCrmPropertyParams,
  IArgumentSegmentationLinkToMeetingNoteConfigurationParams,
  IArgumentSegmentationResponse,
  IArgumentSegmentationUnlinkFromCrmPropertyParams,
  IArgumentSegmentationUnlinkFromMeetingNoteConfigurationParams,
  IArgumentSegmentationUpdateParams,
} from "./types/argumentSegmentations"
import {
  IArgumentSegmentResponse,
  IArgumentSegmentUpdateParams,
} from "./types/argumentSegments"

export const apiUpdateArgumentSegmentation = (
  params: IArgumentSegmentationUpdateParams
): Promise<IArgumentSegmentationResponse> =>
  axios
    .patch(`api/argument_segmentations/${params.id}`, params)
    .then((res) => res.data)

export const apiUpdateArgumentSegmentationPosition = ({
  id,
  relativeId,
}: {
  id: string
  relativeId: string
}): Promise<undefined> =>
  axios
    .patch(`api/argument_segmentations/${id}/update_position`, { relativeId })
    .then((res) => res.data)

export const apiDeleteArgumentSegmentation = (
  id: string
): Promise<IArgumentSegmentationResponse> =>
  axios.delete(`api/argument_segmentations/${id}`).then((res) => res.data)

export const apiRestoreArgumentSegmentation = (
  id: string
): Promise<IArgumentSegmentationResponse> =>
  axios
    .patch(`api/argument_segmentations/${id}/restore`)
    .then((res) => res.data)

export const apiCreateArgumentSegmentation = (
  playbookId: string
): Promise<IArgumentSegmentationResponse> =>
  axios
    .post("api/argument_segmentations", { playbookId })
    .then((res) => res.data)

export const apiArgumentSegmentationLinkToCrmProperty = (
  params: IArgumentSegmentationLinkToCrmPropertyParams
): Promise<IArgumentSegmentationResponse> =>
  axios
    .patch(
      `api/argument_segmentations/${params.id}/link_to_crm_property`,
      params
    )
    .then((res) => res.data)

export const apiArgumentSegmentationLinkToMeetingNoteConfiguration = (
  params: IArgumentSegmentationLinkToMeetingNoteConfigurationParams
): Promise<IArgumentSegmentationResponse> =>
  axios
    .patch(
      `api/argument_segmentations/${params.id}/link_to_meeting_note_configuration`,
      params
    )
    .then((res) => res.data)

export const apiArgumentSegmentationUnlinkFromCrmProperty = (
  params: IArgumentSegmentationUnlinkFromCrmPropertyParams
): Promise<IArgumentSegmentationResponse> =>
  axios
    .patch(`api/argument_segmentations/${params.id}/unlink_from_crm_property`)
    .then((res) => res.data)

export const apiArgumentSegmentationUnlinkFromMeetingNoteConfiguration = (
  params: IArgumentSegmentationUnlinkFromMeetingNoteConfigurationParams
): Promise<IArgumentSegmentationResponse> =>
  axios
    .patch(
      `api/argument_segmentations/${params.id}/unlink_from_meeting_note_configuration`
    )
    .then((res) => res.data)

export const apiUpdateArgumentSegment = (
  params: IArgumentSegmentUpdateParams
): Promise<IArgumentSegmentResponse> =>
  axios
    .patch(`api/argument_segments/${params.id}`, params)
    .then((res) => res.data)

export const apiDeleteArgumentSegment = (
  id: string
): Promise<IArgumentSegmentResponse> =>
  axios.delete(`api/argument_segments/${id}`).then((res) => res.data)

export const apiCreateArgumentSegment = (
  argumentSegmentationId: string
): Promise<IArgumentSegmentResponse> =>
  axios
    .post("api/argument_segments", { argumentSegmentationId })
    .then((res) => res.data)

export const apiUpdateArgumentSegmentPosition = ({
  id,
  relativeId,
}: {
  id: string
  relativeId: string
}): Promise<undefined> =>
  axios
    .patch(`api/argument_segments/${id}/update_position`, {
      relativeId,
    })
    .then((res) => res.data)
