import MuiSnackbar, {
  SnackbarProps as MuiSnackbarProps,
} from "@mui/material/Snackbar"
import { forwardRef } from "react"
import { FormattedMessage } from "react-intl"

import Alert, { AlertProps } from "./Alert"

export interface SnackbarProps {
  children: MuiSnackbarProps["children"]
  onClose: () => void
  disableAutoHide?: boolean
  open: boolean
}

const defaultAutoHideDuration = 5000

const Snackbar = forwardRef(function Snackbar(
  props: SnackbarProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <MuiSnackbar
      open={props.open}
      onClose={props.onClose}
      children={props.children}
      autoHideDuration={props.disableAutoHide ? null : defaultAutoHideDuration}
      ref={ref}
      sx={{
        width: { sm: "400px" },
        "> *": { boxShadow: 16 },
      }}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    />
  )
})

export default Snackbar

interface AlertSnackbarProps extends SnackbarProps {
  severity: AlertProps["severity"]
}

export function AlertSnackbar({
  severity,
  onClose,
  children,
  ...otherProps
}: AlertSnackbarProps) {
  return (
    <Snackbar onClose={onClose} {...otherProps}>
      <Alert severity={severity} onClose={onClose}>
        {children}
      </Alert>
    </Snackbar>
  )
}

export function BasicSuccessSnack({
  open,
  onClose,
}: Pick<SnackbarProps, "open" | "onClose">) {
  return (
    <AlertSnackbar open={open} onClose={onClose} severity="success">
      <FormattedMessage id="common.success" />
    </AlertSnackbar>
  )
}

export function BasicErrorSnack({
  open,
  onClose,
}: Pick<SnackbarProps, "open" | "onClose">) {
  return (
    <AlertSnackbar open={open} onClose={onClose} severity="error">
      <FormattedMessage id="common.error" />
    </AlertSnackbar>
  )
}
