import isEmpty from "lodash/isEmpty"
import xor from "lodash/xor"
import { useState } from "react"
import { useParams } from "react-router-dom"

import { IBusinessUnit } from "api/types/businessUnits"
import {
  IOrganizationMembershipRole,
  organizationMembershipRoles,
} from "api/types/organizationMemberships"

import BusinessUnitsSelector from "components/App/BusinessUnit/BusinessUnitsSelector"

import { isEmailValid } from "utils/emailValidation"

import RoleAndEmailForm from "./RoleAndEmailForm"
import { useInviteCreateMutation } from "./organizationInvitationQueries"

function validate({ email }: { email: string }) {
  const errors: { email?: string } = {}
  if (!email) {
    errors.email = "missing"
  }
  if (!isEmailValid(email)) {
    errors.email = "invalid"
  }
  return errors
}

interface Props {
  organizationId: string
  businessUnits: IBusinessUnit[]
  onSuccess: () => void
  onError: () => void
}

function areAllBusinessUnitIdsSelected(
  selectedIds: string[],
  businessUnitIds: string[]
) {
  return isEmpty(xor(selectedIds, businessUnitIds))
}

export default function InviteUserForm({
  organizationId,
  businessUnits,
  onSuccess,
  onError,
}: Props) {
  const params = useParams()
  const currentBusinessUnitId = params.businessUnitId
  const [email, setEmail] = useState("")
  const [role, setRole] = useState<IOrganizationMembershipRole>(
    organizationMembershipRoles[0]
  )

  function getInitialBusinessUnitIdsSelection() {
    if (
      currentBusinessUnitId &&
      businessUnits.map(({ id }) => id).includes(currentBusinessUnitId)
    )
      return [currentBusinessUnitId]
    return businessUnits.length > 0 ? [businessUnits[0].id] : []
  }

  const [selectedBusinessUnitIds, setSelectedBusinessUnitIds] = useState(
    getInitialBusinessUnitIdsSelection()
  )

  const [showBusinessUnitsSelector, setShowBusinessUnitsSelector] =
    useState(false)

  const createMutation = useInviteCreateMutation(
    organizationId,
    businessUnits.map((businessUnit) => businessUnit.id)
  )

  const selectableBusinessUnits = businessUnits.filter(
    ({ archived }) => !archived
  )

  const validationErrors = validate({ email })
  const isInvalid = !isEmpty(validationErrors)

  const handleEmailAndRoleSubmition = () => {
    if (
      role === "ADMIN" ||
      businessUnits.length <= 1 ||
      !selectableBusinessUnits.length
    ) {
      submit()
    } else {
      setShowBusinessUnitsSelector(true)
    }
  }

  const toggleBusinessUnitIdsSelection = (businessUnitId: string) => {
    setSelectedBusinessUnitIds((prevSelection) =>
      prevSelection.includes(businessUnitId)
        ? prevSelection.filter((id) => id !== businessUnitId)
        : [...prevSelection, businessUnitId]
    )
  }

  const toggleAllBusinessUnitIds = (selected: boolean) => {
    if (selected) {
      setSelectedBusinessUnitIds(selectableBusinessUnits.map(({ id }) => id))
    } else {
      setSelectedBusinessUnitIds([])
    }
  }

  const determinateBusinessUnitIds = () => {
    if (role === "ADMIN") {
      return []
    }
    if (selectableBusinessUnits.length === 0) {
      return []
    }
    if (selectableBusinessUnits.length === 1) {
      return [businessUnits[0].id]
    }
    return selectedBusinessUnitIds
  }

  const submit = () => {
    const businessUnitIds = determinateBusinessUnitIds()
    createMutation.mutate(
      {
        organizationId,
        user: { email, role, businessUnitIds },
      },
      {
        onSuccess: () => {
          onSuccess()
        },
        onError: () => onError(),
      }
    )
  }

  return (
    <>
      {showBusinessUnitsSelector && (
        <BusinessUnitsSelector
          businessUnits={selectableBusinessUnits}
          selectedBusinessUnitIds={selectedBusinessUnitIds}
          allSelected={areAllBusinessUnitIdsSelected(
            selectedBusinessUnitIds,
            selectableBusinessUnits.map(({ id }) => id)
          )}
          onSelectionChange={(businessUnitId) =>
            toggleBusinessUnitIdsSelection(businessUnitId)
          }
          onSelectAllChange={toggleAllBusinessUnitIds}
          disabled={selectedBusinessUnitIds.length === 0}
          onSubmit={submit}
        />
      )}
      {!showBusinessUnitsSelector && (
        <RoleAndEmailForm
          organizationId={organizationId}
          email={email}
          onEmailChange={(
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => setEmail(e.target.value)}
          role={role}
          onRoleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const rawValue = e.target.value
            const safeValue =
              organizationMembershipRoles.find((x) => x === rawValue) ||
              organizationMembershipRoles[0]
            setRole(safeValue)
          }}
          submitDisabled={createMutation.isLoading || isInvalid}
          onSubmit={handleEmailAndRoleSubmition}
        />
      )}
    </>
  )
}
