import { Navigate, Route, Routes } from "react-router-dom"

import useMe from "authentication/useMe"

import AcceptOrganizationInvitation from "./AcceptOrganizationInvitation"
import AppRoot from "./AppRoot"
import BusinessUnit from "./BusinessUnit/BusinessUnit"
import CRMAuthentication from "./CRM/CRMAuthentication/CRMAuthentication"
import Organization from "./Organization/Organization"
import SuperAdmin from "./SuperAdmin"

export default function RootRouter() {
  const user = useMe()

  return (
    <Routes>
      <Route
        path="/authentication/:crmName/callback"
        element={<CRMAuthentication />}
      />
      <Route
        path="/accept_invitation/:invitationToken"
        element={<AcceptOrganizationInvitation />}
      />

      <Route path="bu/:businessUnitId/*" element={<BusinessUnit />} />
      <Route path="org/:organizationId/*" element={<Organization />} />

      {user.isSuperAdmin && (
        <Route path="/super-admin/*" element={<SuperAdmin />} />
      )}
      <Route path="/" element={<AppRoot />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
