import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IOrganization } from "api/types/organizations"

import Button from "ds/Button"
import Modal from "ds/Modal"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

import { useOrganizationUpdateMutation } from "components/App/Organization/organizationQueries"

interface Props {
  organization: IOrganization
  onClose: () => void
}

export default function RenameOrganization(props: Props) {
  const [value, setValue] = useState(props.organization.name || "")
  const updateMutation = useOrganizationUpdateMutation()

  function submit(e: React.SyntheticEvent) {
    e.preventDefault()
    updateMutation.mutate(
      { id: props.organization.id, organization: { name: value } },
      { onSuccess: () => props.onClose() }
    )
  }

  return (
    <Modal isOpen onClose={props.onClose}>
      <form onSubmit={submit}>
        <Stack spacing={2}>
          <TextField
            id={`organization-name-${props.organization.id}`}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            label={<FormattedMessage id="organizations.field.name" />}
            autoFocus
            onFocus={(e) => e.target.select()}
          />

          <Button
            color="primary"
            type="submit"
            disabled={updateMutation.isLoading || value.length === 0}
          >
            <FormattedMessage id="common.submit" defaultMessage="Submit" />
          </Button>
        </Stack>
      </form>
    </Modal>
  )
}
