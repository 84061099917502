import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import LightbulbIcon from "@mui/icons-material/Lightbulb"
import { Box, Collapse } from "@mui/material"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { FormattedMessage, useIntl } from "react-intl"

import { ICRMCompany } from "api/types/CRMCompanies"
import { ICRMContact } from "api/types/CRMContacts"
import { ICRMDeal } from "api/types/CRMDeals"
import { ISyncedEntityType } from "api/types/integrations"
import { IMeeting } from "api/types/meetings"

import Alert from "ds/Alert"
import Avatar from "ds/Avatar"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UnstyledButton from "ds/UnstyledButton"

import CRMCompanyCreatable from "components/App/CRM/companies/CRMCompanyCreatable"
import CRMContactWithCRMCompanyAssociationMismatchAlert from "components/App/CRM/contacts/CRMContactWithCRMCompanyAssociationMismatchAlert"
import CRMContactsCreatable from "components/App/CRM/contacts/CRMContactsCreatable"
import CRMDealSelector from "components/App/CRM/deals/CRMDealSelector"
import { useApplicationIntegrationQuery } from "components/App/Organization/ApplicationsIntegration/applicationIntegrationsQueries"

import { buildCrmDealSearchResult } from "services/crmDeals"
import { contactOnCurrentContactPageMismatch } from "services/meetings"

import useLocalStorageState from "utils/hooks/useLocalStorageState"

import CRMSyncedEntityTypeForm from "./MeetingInformation/CRMSyncedEntityTypeForm"
import ExternalCRMEventSelector from "./MeetingInformation/ExternalCRMEventSelector"
import { useMeeting } from "./MeetingProvider"

function findContactWithCompanyAssocMismatch(
  contacts: ICRMContact[],
  company: ICRMCompany | null
) {
  return (
    contacts.find((contact) => {
      if (!company) {
        return !!contact.primaryCrmCompany
      } else {
        return (
          !!contact.primaryCrmCompany &&
          contact.primaryCrmCompany?.id !== company.id
        )
      }
    }) || null
  )
}

function findCompanyWithDealAssocMismatch(
  company: ICRMCompany | null,
  deal: ICRMDeal | null
) {
  if (!company?.externalId || !deal?.crmCompanyExternalId) {
    return false
  }

  return deal.crmCompanyExternalId !== company.externalId
}

interface Props {
  ongoingMeeting: IMeeting
  currentPageUrl?: string
  back?: React.ReactNode
}

export default function MeetingInformation({
  ongoingMeeting,
  currentPageUrl,
  back,
}: Props) {
  const intl = useIntl()
  const [isExpanded, setIsExpended] = useLocalStorageState(
    "MeetingInformationSectionExpanded",
    true
  )
  const {
    isSubmitting,
    company,
    contacts,
    deal,
    syncedEntityType,
    handleSyncedEntityTypeChange,
    handleCompanyChange,
    handleContactsChange,
    handleDealChange,
  } = useMeeting()

  const contactWithCompanyAssocMismatch = findContactWithCompanyAssocMismatch(
    contacts,
    company
  )

  const dealWithCompanyAssocMismatch = findCompanyWithDealAssocMismatch(
    company,
    deal
  )

  const { data: currentCRMIntegration } = useApplicationIntegrationQuery(
    ongoingMeeting.businessUnitId
  )

  const expandLabel = intl.formatMessage({
    id: "playbook.meeting.information.expand",
  })

  return (
    <Box>
      <UnstyledButton
        onClick={() => setIsExpended((prevState) => !prevState)}
        aria-label={expandLabel}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1.5}
        >
          <Stack direction="row" spacing={1.5} alignItems="center">
            {back}
            <MeetingInformationHeader />
          </Stack>

          {isExpanded ? (
            <ExpandLessIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )}
        </Stack>
      </UnstyledButton>

      <Collapse sx={{ mt: 2 }} in={isExpanded} timeout="auto" unmountOnExit>
        <Stack spacing={2}>
          {currentCRMIntegration &&
            !currentCRMIntegration.isSyncedEntityTypeForced && (
              <CRMSyncedEntityTypeForm
                value={syncedEntityType}
                onChange={(_event, value) => {
                  handleSyncedEntityTypeChange(value as ISyncedEntityType)
                }}
                disabled={isSubmitting}
                integration={currentCRMIntegration}
              />
            )}

          <CRMCompanyCreatable
            value={company}
            onChange={(newCompany) => {
              handleCompanyChange(newCompany)
            }}
            businessUnitId={ongoingMeeting.businessUnitId}
            disabled={isSubmitting}
            includeCreatable={!currentCRMIntegration?.personAccounts}
          />

          {!currentCRMIntegration?.personAccounts && (
            <CRMContactsCreatable
              value={contacts}
              onChange={(newContacts) => {
                handleContactsChange(newContacts)
              }}
              businessUnitId={ongoingMeeting.businessUnitId}
              disabled={isSubmitting}
              companyId={company?.id || null}
            />
          )}

          {currentCRMIntegration && (
            <CRMDealSelector
              value={deal && buildCrmDealSearchResult(deal)}
              onChange={(newDeal) => {
                handleDealChange(newDeal)
              }}
              businessUnitId={ongoingMeeting.businessUnitId}
              externalCompanyId={ongoingMeeting.crmCompany?.externalId || null}
            />
          )}

          {currentCRMIntegration && <ExternalCRMEventSelector />}

          {IS_CHROME_EXTENSION &&
            currentCRMIntegration &&
            contactOnCurrentContactPageMismatch(currentPageUrl, contacts) && (
              <Alert severity="warning">
                <FormattedMessage id="playbook.meeting.information.crmContacts.contactMismatch" />
              </Alert>
            )}

          {contactWithCompanyAssocMismatch && (
            <CRMContactWithCRMCompanyAssociationMismatchAlert
              contactName={contactWithCompanyAssocMismatch.name}
              companyName={
                contactWithCompanyAssocMismatch.primaryCrmCompany?.name || ""
              }
            />
          )}

          {dealWithCompanyAssocMismatch && (
            <Alert severity="warning">
              <FormattedMessage id="playbook.meeting.information.crmDeal.crmCompanyAssociationMismatch" />
            </Alert>
          )}
        </Stack>
      </Collapse>
    </Box>
  )
}

export function MeetingInformationHeader() {
  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.light }}>
        <LightbulbIcon fontSize="small" color="primary" />
      </Avatar>
      <Typography variant="lgSemi">
        <FormattedMessage id="playbook.meeting.information.title" />
      </Typography>
    </Stack>
  )
}
