import CachedIcon from "@mui/icons-material/Cached"
import { FormattedMessage } from "react-intl"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"

interface Props {
  onClick: () => void
}

export default function BusinessUnitSwitchMenuItem({ onClick }: Props) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <CachedIcon />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="navigation.businessUnitSwitch" />
      </ListItemText>
    </MenuItem>
  )
}
