import { ELEMENT_TODO_LI, ELEMENT_TOGGLE } from "@udecode/plate"
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote"
import {
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
} from "@udecode/plate-common"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import {
  ResetNodePlugin,
  createResetNodePlugin,
} from "@udecode/plate-reset-node"

import { MyEditor, MyValue } from "../types"

const resetBlockTypesCommonRule = {
  types: [ELEMENT_BLOCKQUOTE, ELEMENT_TOGGLE, ELEMENT_TODO_LI],
  defaultType: ELEMENT_PARAGRAPH,
}

// const resetBlockTypesCodeBlockRule = {
//   types: [ELEMENT_CODE_BLOCK],
//   defaultType: ELEMENT_PARAGRAPH,
//   onReset: unwrapCodeBlock,
// }

export const resetNodePlugin = createResetNodePlugin<
  ResetNodePlugin<MyValue, MyEditor>,
  MyValue,
  MyEditor
>({
  options: {
    rules: [
      {
        ...resetBlockTypesCommonRule,
        hotkey: "Enter",
        predicate: isBlockAboveEmpty,
      },
      {
        ...resetBlockTypesCommonRule,
        hotkey: "Backspace",
        predicate: isSelectionAtBlockStart,
      },
      // {
      //   ...resetBlockTypesCodeBlockRule,
      //   hotkey: "Enter",
      //   predicate: isCodeBlockEmpty,
      // },
      // {
      //   ...resetBlockTypesCodeBlockRule,
      //   hotkey: "Backspace",
      //   predicate: isSelectionAtCodeBlockStart,
      // },
    ],
  },
})
