import { IArgumentComment } from "api/types/argumentComments"

export function filterComments(
  comments: IArgumentComment[],
  {
    category,
    showResolved,
  }: { category?: string | null; showResolved?: boolean }
) {
  let filteredComments = comments
  if (category) {
    filteredComments = comments.filter(
      (comment) => comment.category === category
    )
  }

  if (!showResolved) {
    filteredComments = filteredComments.filter(
      (comment) => !comment.resolvedByUser
    )
  }
  return filteredComments
}
