import changeArrayPosition from "./changeArrayPosition"

// In an array, move element at a given position just before anther position
const moveBefore = <TElement>(
  array: TElement[],
  index: number,
  relativeIndex: number
): TElement[] => {
  if (index > relativeIndex) {
    return changeArrayPosition(array, index, relativeIndex)
  } else {
    return changeArrayPosition(array, index, relativeIndex - 1)
  }
}

export default moveBefore
