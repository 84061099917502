import MoreVert from "@mui/icons-material/MoreVert"
import { useIntl } from "react-intl"

import { IOrganizationMembership } from "api/types/organizationMemberships"

import Button from "ds/Button"
import Menu from "ds/Menu"

import useAnchorEl from "utils/hooks/useAnchorEl"

import OrganizationMembershipActivation from "./OrganizationMembershipActivation"

interface Props {
  organizationMembership: IOrganizationMembership
}

export default function OrganizationMembershipActions({
  organizationMembership,
}: Props) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()

  return (
    <>
      <Button
        onClick={handleClick}
        aria-label={intl.formatMessage({
          id: "organizations.users.actions",
          defaultMessage: "User actions",
        })}
      >
        <MoreVert />
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <OrganizationMembershipActivation
          organizationMembership={organizationMembership}
          onChangeActivation={handleClose}
        />
      </Menu>
    </>
  )
}
