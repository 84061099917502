import AppsIcon from "@mui/icons-material/Apps"
import { FormattedMessage } from "react-intl"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"
import { UnstyledRouterLink } from "ds/UnstyledLink"

export default function ApplicationMenuItem() {
  return (
    <MenuItem component={UnstyledRouterLink} to="/">
      <ListItemIcon>
        <AppsIcon />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="navigation.app" defaultMessage="App" />
      </ListItemText>
    </MenuItem>
  )
}
