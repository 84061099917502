import { IArgumentSearchResult } from "api/types/arguments"

import Linkify from "ds/Linkify"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import { UnstyledRouterLink } from "ds/UnstyledLink"

import ArgumentKindIndicator from "components/App/Playbook/Argument/ArgumentCard/ArgumentKindIndicator/ArgumentKindIndicator"
import DefaultTitle from "components/App/Playbook/Argument/DefaultTitle"
import { useArgumentModalLocation } from "components/App/Playbook/Argument/routing"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { getArgumentType } from "services/argumentTypes"

import HighlightedText from "./HighlightedText"

interface Props {
  result: IArgumentSearchResult
}

export default function SearchResult({ result }: Props) {
  const { playbook } = usePlaybook()
  const argumentType = getArgumentType(playbook, result.argument.argumentTypeId)
  const argumentGroupNameHighlight = result.highlight.argumentGroupName?.[0]
  const argumentGroupName = result.argumentGroupName
  const argumentModalLocation = useArgumentModalLocation(result.argument.id)

  return (
    <Stack direction="row" spacing={1.5} alignItems="start">
      {!!result.argument && (
        <ArgumentKindIndicator disabled argument={result.argument} />
      )}

      <UnstyledRouterLink to={argumentModalLocation} style={{ minWidth: 0 }}>
        <Stack spacing={1} minHeight="32px" justifyContent="center">
          <Typography variant="smSemi">
            {argumentType?.name}
            {(argumentGroupName || argumentGroupNameHighlight) && (
              <>
                {" > "}
                {argumentGroupNameHighlight ? (
                  <HighlightedText html={argumentGroupNameHighlight} />
                ) : (
                  argumentGroupName
                )}
              </>
            )}
          </Typography>

          {result.highlight.title ? (
            <ArgumentTitle
              title={<HighlightedText html={result.highlight.title[0]} />}
            />
          ) : (
            <ArgumentTitle title={result.title} />
          )}

          {result.highlight.otherFields.map((highlightedText, index) => (
            <ArgumentDescription
              key={`${highlightedText}-${index}`}
              description={<HighlightedText html={highlightedText} />}
            />
          ))}
        </Stack>
      </UnstyledRouterLink>
    </Stack>
  )
}

function ArgumentTitle({ title }: { title: React.ReactNode }) {
  return (
    <Typography
      variant="smNormal"
      sx={{ pr: 2, overflow: "hidden", textOverflow: "ellipsis" }}
    >
      {title || <DefaultTitle />}
    </Typography>
  )
}

function ArgumentDescription({
  description,
}: {
  description: React.ReactNode
}) {
  return (
    <Typography
      variant="smNormal"
      component="div"
      sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
    >
      <Linkify>{description}</Linkify>
    </Typography>
  )
}
