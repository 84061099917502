import { useEffect } from "react"
import { Navigate, Route, Routes, useParams } from "react-router-dom"

import useMe from "authentication/useMe"

import Loader from "ds/Loader"
import UncontrolledError from "ds/UncontrolledError"

import { isOrganizationAdmin } from "services/organizationMemberships"

import OrganizationManagement from "./OrganizationManagement"
import OrganizationProvider from "./OrganizationProvider"
import OrganizationRoot from "./OrganizationRoot"
import { setLastVisitedOrganizationId } from "./lastVisit"
import { useOrganizationQuery } from "./organizationQueries"

export default function Organization() {
  const user = useMe()
  const params = useParams()
  const organizationId = params.organizationId!
  const { isLoading, isError, error, data } =
    useOrganizationQuery(organizationId)

  useEffect(() => {
    setLastVisitedOrganizationId(organizationId)
  }, [organizationId])

  if (isLoading) return <Loader />

  if (isError) return <UncontrolledError error={error} />

  return (
    <OrganizationProvider organization={data.organization}>
      <Routes>
        <Route path="/" element={<OrganizationRoot />} />
        {(user.isSuperAdmin ||
          isOrganizationAdmin(user, data.organization.id)) && (
          <Route path="manage/*" element={<OrganizationManagement />} />
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </OrganizationProvider>
  )
}
