import { useQuery, useQueryClient } from "@tanstack/react-query"

import {
  apiCountNotSeenNotifications,
  apiGetNotifications,
  apiMarkNotificationsAsSeen,
} from "api/notifications"

function buildNotificationsKey() {
  return ["notifications"]
}

function buildNotificationsNotSeenKey() {
  return [...buildNotificationsKey(), "notSeenCount"]
}

export const useNotificationsQuery = () => {
  const queryClient = useQueryClient()
  return useQuery(buildNotificationsKey(), apiGetNotifications, {
    onSuccess: (result) => {
      apiMarkNotificationsAsSeen(result.map((notification) => notification.id))
      queryClient.setQueryData(buildNotificationsNotSeenKey(), 0)
    },
  })
}

export const useNotificationsCountQuery = () =>
  useQuery(buildNotificationsNotSeenKey(), apiCountNotSeenNotifications, {
    staleTime: 1000 * 60 * 5,
  })
