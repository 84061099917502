import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon"
import { useIntl } from "react-intl"

import useAnchorEl from "utils/hooks/useAnchorEl"

import PillIconButton from "../PillIconButton"
import EmojiPickerPopover from "./EmojiPickerPopover"

export interface EmojiPickerButtonProps {
  disabled?: boolean
}

export default function EmojiButton({ disabled }: EmojiPickerButtonProps) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()

  return (
    <div>
      <PillIconButton
        label={intl.formatMessage({
          id: "richtext.emoji",
          defaultMessage: "Emoji",
        })}
        onMouseDown={(event) => event.preventDefault()}
        onClick={handleClick}
        disabled={!!disabled}
      >
        <InsertEmoticonIcon sx={{ height: "16px", width: "16px" }} />
      </PillIconButton>

      <EmojiPickerPopover
        anchorEl={anchorEl}
        isOpen={open}
        onClose={handleClose}
      />
    </div>
  )
}
