import { Box, BoxProps } from "@mui/material"
import { forwardRef } from "react"
import styled from "styled-components"

import { onKeyDownEnter } from "utils/onKeyDown"

// removes border on focus on touch devices
const StyledDiv = styled(Box)``

export interface UnstyledButtonProps extends BoxProps {
  // Doesn't take an event, as it is called for both onClick and onKeyDown
  onClick?: (
    e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>
  ) => void
  onMouseDown?: (
    e: React.KeyboardEvent<Element> | React.MouseEvent<Element, MouseEvent>
  ) => void
  disabled?: boolean
}

// Div that can be used like a button in an accessible way
export default forwardRef(function UnstyledButton(
  { onClick, onMouseDown, disabled, ...props }: UnstyledButtonProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const handler = onClick || onMouseDown

  return (
    <StyledDiv
      role="button"
      aria-disabled={disabled}
      tabIndex={0}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onKeyDown={handler && onKeyDownEnter(handler)}
      ref={ref}
      {...props}
      sx={{
        cursor: disabled ? undefined : "pointer",
        "@media (hover: none)": {
          outline: "none",
        },
        ...props.sx,
      }}
    />
  )
})
