import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { FilterOptionsState } from "@mui/material"
import { createFilterOptions } from "@mui/material/Autocomplete"
import { useState } from "react"
import { IntlShape, useIntl } from "react-intl"

import { IArgumentGroup } from "api/types/argumentGroups"

import Autocomplete, { CustomAutocompleteProps } from "ds/Autocomplete"
import IconButton from "ds/IconButton"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import TextField from "ds/TextField"

import { getDefaultArgumentGroupName } from "components/App/Playbook/ArgumentGroup/DefaultArgumentGroupName"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentGroupCreateMutation } from "components/App/Playbook/queries/argumentGroupQueries"
import { useArgumentCreateMutation } from "components/App/Playbook/queries/argumentQueries"

import { buildSegmentRulesFromSegmentFilters } from "services/argumentSegmentRules"

interface CreatableValue {
  id: null
  name: string
  inputValue: string
}

function buildCreatableValue(
  inputValue: string,
  intl: IntlShape
): CreatableValue {
  return {
    id: null,
    inputValue: inputValue,
    name: intl.formatMessage(
      {
        id: "playbookEdit.argumentGroups.create_group",
        defaultMessage: 'Create group "{name}"',
      },
      { name: inputValue }
    ),
  }
}

type Option = IArgumentGroup | CreatableValue

function isCreatableValue(option: Option): option is CreatableValue {
  return !option.id
}

function getOptionLabel(option: Option, intl: IntlShape) {
  if (isCreatableValue(option)) return option.name
  return option.rtRawName || getDefaultArgumentGroupName(intl)
}

const filter = createFilterOptions<Option>()

function filterOptions(
  options: Option[],
  params: FilterOptionsState<Option>,
  intl: IntlShape,
  isCreating: boolean
) {
  const filtered = filter(options, params)
  const isExisting = options.some(
    (option) =>
      !isCreatableValue(option) && params.inputValue === option.rtRawName
  )

  // !isCreating is used to avoid showing a label "Create group "Create group "new group"""
  if (params.inputValue !== "" && !isExisting && !isCreating) {
    filtered.push(buildCreatableValue(params.inputValue, intl))
  }

  return filtered
}

interface Props {
  argumentGroups: IArgumentGroup[]
  addExistingGroup: (argumentGroup: IArgumentGroup) => void
  onCreateGroup: (argumentGroup: IArgumentGroup) => void
  onClose: () => void
  forceHideSearch?: boolean
  autocompleteProps: Omit<
    CustomAutocompleteProps<Option, false, false, false>,
    | "value"
    | "onChange"
    | "options"
    | "filterOptions"
    | "inputLabel"
    | "freeSolo"
    | "multiple"
  >
}

export default function ArgumentGroupSelector({
  argumentGroups,
  addExistingGroup,
  onCreateGroup,
  onClose,
  forceHideSearch = false,
  autocompleteProps,
}: Props) {
  const intl = useIntl()

  const { playbook, activeTypeId, segmentFilters } = usePlaybook()
  const [isCreating, setIsCreating] = useState(false)
  const argumentCreateMutation = useArgumentCreateMutation(activeTypeId)
  const argumentGroupCreateMutation = useArgumentGroupCreateMutation(
    playbook.id
  )

  const searchInputplaceholder = intl.formatMessage(
    forceHideSearch
      ? {
          id: "playbookEdit.argumentGroups.new",
          defaultMessage: "New group name",
        }
      : {
          id: "playbookEdit.argumentGroups.searchOrCreate",
          defaultMessage: "Type name of the group",
        }
  )

  async function handleGroupCreation(name: string) {
    setIsCreating(true)
    const { argumentGroup } = await argumentGroupCreateMutation.mutateAsync({
      argumentTypeId: activeTypeId,
      rtRawName: name,
      rtName: buildNodeFromText(name, { bold: true }),
    })

    await argumentCreateMutation.mutateAsync({
      playbookId: playbook.id,
      argumentTypeId: activeTypeId,
      argumentGroupId: argumentGroup.id,
      segmentRules: buildSegmentRulesFromSegmentFilters(segmentFilters),
    })
    setIsCreating(false)
    onCreateGroup(argumentGroup)
  }

  return (
    <Autocomplete<Option, false, false, false>
      disablePortal
      open={true}
      popupIcon={false}
      clearIcon={false}
      disabled={isCreating}
      onChange={(_, value) => {
        if (!value) return
        isCreatableValue(value)
          ? handleGroupCreation(value.inputValue)
          : addExistingGroup(value)
      }}
      options={argumentGroups}
      getOptionLabel={(option) => getOptionLabel(option, intl)}
      filterOptions={(options, params) =>
        filterOptions(options, params, intl, isCreating)
      }
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {getOptionLabel(option, intl)}
        </li>
      )}
      noOptionsText=""
      value={null}
      sx={{
        ".MuiOutlinedInput-notchedOutline": { border: "unset" },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          placeholder={searchInputplaceholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <IconButton onClick={onClose}>
                <ArrowBackIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      )}
      inputLabel={null}
      {...autocompleteProps}
    />
  )
}
