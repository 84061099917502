import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import TableCell from "ds/TableCell"
import TableHead from "ds/TableHead"
import TableRow from "ds/TableRow"

const TableHeadCell = styled(TableCell)`
  font-size: 0.9rem;
`

export default function MeetingTypesHead() {
  return (
    <TableHead>
      <TableRow>
        <TableHeadCell width="30%">
          <FormattedMessage id="meetingTypes.name" defaultMessage="Name" />
        </TableHeadCell>
        <TableHeadCell width="70%">
          <FormattedMessage
            id="meetingTypes.generalNoteTemplate"
            defaultMessage="📝 General note template"
          />
        </TableHeadCell>
      </TableRow>
    </TableHead>
  )
}
