// AsyncAutocomplete is an Autocomplete that loads options asynchronously upon user input (and onOpen the first time).
// Pass `getOptions`, a function that takes a string and returns a promise that provides the options
// Instead of `options`, pass `getOptions`, a function that takes a string and returns a promise that provides the options.
// Otherwise use this component as Autocomplete.
// Note: The search is debounced.
import { identity } from "lodash"
import { useState } from "react"
import { useDebouncedCallback } from "use-debounce"

import Autocomplete, { CustomAutocompleteProps } from "ds/Autocomplete"

const debounceWaitMs = 250

export interface AsyncAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    "options" | "filterOptions"
  > {
  getOptions: (inputValue: string) => Promise<T[]>
  onGetOptionsFailure?: () => void
  filterOptions?: CustomAutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >["filterOptions"]
}

const AsyncAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  getOptions,
  onGetOptionsFailure = () => null,
  filterOptions = identity, // Disable built-in filtering
  ...props // Other props are passed to Autocomplete
}: AsyncAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const [options, setOptions] = useState<T[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const loadOptions = async (inputValue: string, reason?: string) => {
    if (reason === "reset") return // Ignore programmatic changes
    setIsLoading(true)
    try {
      let newOptions = await getOptions(inputValue)
      setOptions(newOptions)
    } catch {
      onGetOptionsFailure()
      setOptions([])
    }
    setIsLoading(false)
  }
  const debouncedLoadOptions = useDebouncedCallback(loadOptions, debounceWaitMs)

  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      onInputChange={(event, inputValue, reason) => {
        debouncedLoadOptions(inputValue, reason)
        props?.onInputChange?.(event, inputValue, reason)
      }}
      options={options}
      loading={isLoading}
      filterOptions={filterOptions}
      onOpen={(...args) => {
        loadOptions("")
        props?.onOpen?.(...args)
      }}
      {...props}
    />
  )
}

export default AsyncAutocomplete
