import { FormattedMessage } from "react-intl"

import Alert from "ds/Alert"

interface Props {
  contactName: string
  companyName: string
}

export default function CRMContactWithCRMCompanyAssociationMismatchAlert({
  contactName,
  companyName,
}: Props) {
  return (
    <Alert severity="warning">
      <FormattedMessage
        id="playbook.meeting.information.crmContacts.crmCompanyAssociationMismatch"
        values={{
          contactName,
          companyName,
        }}
      />
    </Alert>
  )
}
