import MarkChatReadOutlined from "@mui/icons-material/MarkChatReadOutlined"
import { FormattedMessage } from "react-intl"

import IconButton from "ds/IconButton"
import Tooltip from "ds/Tooltip"

interface Props {
  showResolvedComments: boolean
  toggleResolvedArgumentComments: () => void
}

export default function ResolvedFilter({
  showResolvedComments,
  toggleResolvedArgumentComments,
}: Props) {
  return (
    <Tooltip
      disableTouchListener
      title={
        showResolvedComments ? (
          <FormattedMessage
            id="argumentComments.filters.hide_resolved"
            defaultMessage="Hide resolved comments"
          />
        ) : (
          <FormattedMessage
            id="argumentComments.filters.show_resolved"
            defaultMessage="Show resolved comments"
          />
        )
      }
    >
      <IconButton
        color={showResolvedComments ? "primary" : undefined}
        onClick={() => toggleResolvedArgumentComments()}
      >
        <MarkChatReadOutlined fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}
