import { IArgumentComment } from "api/types/argumentComments"

import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import Typography from "ds/Typography"

interface Props {
  comment: IArgumentComment
  richTextProps: Partial<RichTextProps>
}

export default function CommentBody({ comment, richTextProps }: Props) {
  return (
    <Typography component="div" variant="smNormal">
      <RichTextEditor
        {...richTextProps}
        value={comment.value || buildNodeFromText("-")}
        readOnly
        autoFocus
      />
    </Typography>
  )
}
