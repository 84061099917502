import axios from "./axios"
import {
  ICRMCompaniesSearchParams,
  ICRMCompanyCreateParams,
  ICRMCompanyPullParams,
  ICRMCompanyPullResponse,
  ICRMCompanyResponse,
  ICRMCompanySearchResult,
} from "./types/CRMCompanies"

export const apiSearchCRMCompanies = (
  params: ICRMCompaniesSearchParams
): Promise<ICRMCompanySearchResult[]> =>
  axios.post("api/crm/companies/search", params).then((res) => res.data)

export const apiCreateCRMCompany = (
  params: ICRMCompanyCreateParams
): Promise<ICRMCompanyResponse> =>
  axios.post("api/crm/companies", params).then((res) => res.data)

export const apiPullCRMCompany = (
  params: ICRMCompanyPullParams
): Promise<ICRMCompanyPullResponse> =>
  axios.post("api/crm/companies/pull", params).then((res) => res.data)
