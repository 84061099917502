import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import MenuItem from "ds/MenuItem"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentsArchiveMutation } from "components/App/Playbook/queries/argumentQueries"

import useConfirm from "utils/hooks/useConfirm"

interface Props {
  argument: IArgument
  onSuccess: () => void
}

export default function ArgumentArchive({ argument, onSuccess }: Props) {
  const { activeTypeId } = usePlaybook()
  const archiveMutation = useArgumentsArchiveMutation(activeTypeId)
  const confirm = useConfirm()

  const onClick = () => {
    if (!confirm()) return
    archiveMutation.mutate([argument.id], { onSuccess })
  }

  return (
    <MenuItem
      onClick={onClick}
      disabled={archiveMutation.isLoading}
      sx={{ color: "error.main" }}
    >
      <FormattedMessage
        id="playbookEdit.argument.archive"
        defaultMessage="Archive"
      />
    </MenuItem>
  )
}
