import { Box } from "@mui/material"
import { Dictionary } from "lodash"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"

import MenuItem from "ds/MenuItem"
import { SuffixRequiredCue } from "ds/RequiredCue"
import Select from "ds/Select"

import { filterNonArchived } from "services/archivable"
import {
  SEGMENT_ALL,
  findArgumentSegmentsById,
} from "services/argumentSegmentations"

import ArgumentSegmentName from "./ArgumentSegmentation/ArgumentSegmentName"
import ArgumentSegmentationName from "./ArgumentSegmentation/ArgumentSegmentationName"
import { usePlaybook } from "./PlaybookProvider"

const labelId = (argumentSegmentation: IArgumentSegmentation) =>
  `argument-segmentation-select-input-${argumentSegmentation.id}`

const selectId = (argumentSegmentation: IArgumentSegmentation) =>
  `argument-segmentation-select-${argumentSegmentation.id}`

const Ellipsis = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 400px;
`

interface Props {
  argumentSegmentation: IArgumentSegmentation
  value: string[]
  onChange: (newValue: string[]) => void
  nbArgumentsBySegment: Dictionary<number>
  forceShowNbOfArgumentsBySegment?: boolean
  required?: boolean
  hasNoEffect?: boolean
}

const SEGMENTATION_MIN_WIDTH = 100
const LABEL_WIDTH_RATIO_WITH_REQUIRED_CUE = 7 / 10
const LABEL_WIDTH_RATIO_WITHOUT_REQUIRED_CUE = 9 / 10

export default function ArgumentSegmentation({
  argumentSegmentation,
  value,
  onChange,
  nbArgumentsBySegment,
  forceShowNbOfArgumentsBySegment = false,
  required = false,
  hasNoEffect = false,
}: Props) {
  const { editMode, segmentFilters } = usePlaybook()
  const [open, setOpen] = useState(false)

  const labelWidthRatio = required
    ? LABEL_WIDTH_RATIO_WITH_REQUIRED_CUE
    : LABEL_WIDTH_RATIO_WITHOUT_REQUIRED_CUE

  // To be visible, a segment must:
  //   1. Be non archived
  //   2. Be currently filtered on OR if selected, there would be arguments
  let visibleSegments = filterNonArchived(argumentSegmentation.argumentSegments)
  if (!editMode) {
    visibleSegments = visibleSegments.filter(
      (segment) =>
        nbArgumentsBySegment[segment.id] > 0 ||
        (segmentFilters[argumentSegmentation.id] || []).includes(segment.id)
    )
  }

  function closeIfNotMultiple() {
    if (argumentSegmentation.allowMultipleSelection) return
    setOpen(false)
  }

  return (
    // Make space for the label
    <Box py={1}>
      <Box maxWidth="200px" minWidth={`${SEGMENTATION_MIN_WIDTH}px`}>
        <Select
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          sx={{ opacity: hasNoEffect ? 0.25 : 1 }}
          fullWidth
          labelId={labelId(argumentSegmentation)}
          id={selectId(argumentSegmentation)}
          multiple={argumentSegmentation.allowMultipleSelection}
          value={
            argumentSegmentation.allowMultipleSelection
              ? value.filter((segmentId) => segmentId !== SEGMENT_ALL)
              : value[0] || SEGMENT_ALL
          }
          onChange={(event) => {
            if (typeof event.target.value === "string") {
              onChange([event.target.value])
            } else {
              onChange(
                event.target.value.includes(SEGMENT_ALL)
                  ? [SEGMENT_ALL]
                  : event.target.value
              )
            }
          }}
          error={required}
          inputLabelProps={{ shrink: true }}
          label={
            <SuffixRequiredCue showCue={required}>
              <Box
                maxWidth={`max(calc(${100 * labelWidthRatio}%), ${
                  labelWidthRatio * SEGMENTATION_MIN_WIDTH
                }px)`}
                overflow="hidden"
                textOverflow="ellipsis"
                display="inline-block"
              >
                <ArgumentSegmentationName
                  argumentSegmentation={argumentSegmentation}
                />
              </Box>
            </SuffixRequiredCue>
          }
          displayEmpty
          notched
          renderValue={(valueToRender) => {
            const segmentIds =
              typeof valueToRender === "string"
                ? [valueToRender]
                : valueToRender
            const segments = findArgumentSegmentsById(
              argumentSegmentation.argumentSegments,
              segmentIds
            )
            const segmentNames = segments
              .map((segment) => segment.name)
              .join(", ")
            if (segments.length === 0) {
              return (
                <FormattedMessage
                  id="argumentSegmentations.options.all"
                  defaultMessage="All"
                />
              )
            } else {
              return <span title={segmentNames}>{segmentNames}</span>
            }
          }}
        >
          <MenuItem value={SEGMENT_ALL} onClick={() => setOpen(false)}>
            <FormattedMessage
              id="argumentSegmentations.options.all"
              defaultMessage="All"
            />
          </MenuItem>

          {visibleSegments.map((argumentSegment) => {
            return (
              <MenuItem
                key={argumentSegment.id}
                value={argumentSegment.id}
                onClick={closeIfNotMultiple}
              >
                <Ellipsis title={argumentSegment.name || undefined}>
                  <ArgumentSegmentName argumentSegment={argumentSegment} />
                  {(forceShowNbOfArgumentsBySegment ||
                    nbArgumentsBySegment[argumentSegment.id] === 0) &&
                    ` (${nbArgumentsBySegment[argumentSegment.id]})`}
                </Ellipsis>
              </MenuItem>
            )
          })}
        </Select>
      </Box>
    </Box>
  )
}
