import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { FormattedMessage } from "react-intl"

import { ICurrentUserOrganizationMembership } from "api/types/organizationMemberships"

import MenuItem from "ds/MenuItem"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import { UnstyledRouterLink } from "ds/UnstyledLink"

import { getOrganizationPath } from "services/organizations"

interface Props {
  organizationMembership: ICurrentUserOrganizationMembership
  selected: boolean
}

export default function OrganizationItem({
  organizationMembership,
  selected,
}: Props) {
  return (
    <MenuItem
      component={UnstyledRouterLink}
      to={getOrganizationPath(organizationMembership.organization.id)}
      selected={selected}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        width="100%"
        minWidth={0}
      >
        <Stack spacing={1} minWidth={0} flexGrow={1}>
          <Typography
            variant="baseBold"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {organizationMembership.organization.name}
          </Typography>

          <Typography variant="xsNormal">
            <FormattedMessage
              id="organizationToggle.nbPlaybooks"
              defaultMessage="{playbooksCount} playbook(s)"
              values={{
                playbooksCount:
                  organizationMembership.organization.playbooks.length,
              }}
            />
          </Typography>
        </Stack>

        <div>{selected && <CheckCircleOutlineIcon color="success" />}</div>
      </Stack>
    </MenuItem>
  )
}
