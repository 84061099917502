import { ICRMMeetingType } from "api/types/CRMMeetingTypes"

import Paper from "ds/Paper"
import Table from "ds/Table"
import TableBody from "ds/TableBody"
import TableContainer from "ds/TableContainer"

import MeetingTypesHead from "./MeetingTypesTable/MeetingTypesHead"
import MeetingTypesRow from "./MeetingTypesTable/MeetingTypesRow"

interface Props {
  meetingTypes: ICRMMeetingType[]
  onRowClick: (meetingTypeId: string) => void
}

export default function MeetingTypesTable({ meetingTypes, onRowClick }: Props) {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 6 }}>
      <Table>
        <MeetingTypesHead />
        <TableBody>
          {meetingTypes.map((meetingType) => (
            <MeetingTypesRow
              key={meetingType.id}
              meetingType={meetingType}
              onClick={() => onRowClick(meetingType.id)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
