import { ELEMENT_BLOCKQUOTE, ELEMENT_TOGGLE } from "@udecode/plate"
import {
  IndentListPlugin,
  createIndentListPlugin,
} from "@udecode/plate-indent-list"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"

import { MyEditor, MyValue } from "../types"

export const indentListPlugin = createIndentListPlugin<
  IndentListPlugin,
  MyValue,
  MyEditor
>({
  inject: {
    props: {
      validTypes: [
        ELEMENT_PARAGRAPH,
        ELEMENT_BLOCKQUOTE,
        ELEMENT_TOGGLE,
        // ELEMENT_H1,
      ],
    },
  },
})
