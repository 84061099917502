import { useIntl } from "react-intl"

import IconButton from "ds/IconButton"
import Popover from "ds/Popover"

import useAnchorEl from "utils/hooks/useAnchorEl"

import NotificationsIcon from "./Notifications/NotificationsIcon"
import NotificationsPanel from "./Notifications/NotificationsPanel"
import { useNotificationsCountQuery } from "./Notifications/notificationQueries"

export default function Notifications() {
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const id = open ? "notifications-center" : undefined
  const intl = useIntl()
  const notificationsCountQuery = useNotificationsCountQuery()

  const notSeenCount = notificationsCountQuery.data || 0

  const label = intl.formatMessage({
    id: "notifications.title",
    defaultMessage: "Notifications",
  })

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-describedby={id}
        aria-label={label}
      >
        <NotificationsIcon notSeenCount={notSeenCount} />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <NotificationsPanel onClose={handleClose} />
      </Popover>
    </>
  )
}
