import CheckIcon from "@mui/icons-material/Check"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { ARGUMENT_KINDS, IArgument, IArgumentKind } from "api/types/arguments"

import Menu from "ds/Menu"
import MenuItem from "ds/MenuItem"
import Modal from "ds/Modal"
import Stack from "ds/Stack"
import Tooltip from "ds/Tooltip"
import UnstyledButton from "ds/UnstyledButton"

import EditArgumentAnswer from "components/App/Playbook/PlaybookEdit/Argument/ArgumentActions/EditArgumentAnswer"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentUpdateKindMutation } from "components/App/Playbook/queries/argumentQueries"

import { argumentHasMandatoryMeetingNoteConfiguration } from "services/meetingArgumentNotes"

import useAnchorEl from "utils/hooks/useAnchorEl"

import ArgumentKindIndicator, {
  BaseKindIndicator,
} from "./ArgumentKindIndicator"

interface Props {
  argument: IArgument
}

export default function EditableArgumentKindIndicator({ argument }: Props) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const { activeTypeId } = usePlaybook()
  const updateKindArgumentMutation = useArgumentUpdateKindMutation(activeTypeId)
  function updateArgumentKind(kind: IArgumentKind) {
    updateKindArgumentMutation.mutate(
      { id: argument.id, kind },
      { onSuccess: () => handleClose() }
    )
  }
  const [
    isMeetingNoteConfigurationModalOpen,
    setIsMeetingNoteConfigurationModalOpen,
  ] = useState(false)

  function onClickKind(kind: IArgumentKind) {
    if (kind === "ANSWER") {
      setIsMeetingNoteConfigurationModalOpen(true)
    } else if (kind !== argument.kind) {
      updateArgumentKind(kind)
    }
  }

  return (
    <>
      <Tooltip
        title={intl.formatMessage({ id: "playbookEdit.argument.kind.editCTA" })}
      >
        <UnstyledButton onClick={handleClick}>
          <ArgumentKindIndicator
            argument={argument}
            disabled
            showMandatoryAnswerIndicator={argumentHasMandatoryMeetingNoteConfiguration(
              argument
            )}
          />
        </UnstyledButton>
      </Tooltip>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
      >
        {ARGUMENT_KINDS.map((kind) => (
          <MenuItem
            key={kind}
            onClick={() => onClickKind(kind)}
            disabled={
              updateKindArgumentMutation.isLoading &&
              updateKindArgumentMutation.variables?.kind === kind
            }
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <BaseKindIndicator kind={kind} />

              <Box>
                <FormattedMessage id={`playbookEdit.argument.kind.${kind}`} />
              </Box>

              {kind === argument.kind && kind === "ANSWER" && (
                <EditOutlinedIcon
                  fontSize="small"
                  sx={{ fill: (theme) => theme.palette.primary.main }}
                />
              )}

              {kind === argument.kind && (
                <CheckIcon
                  fontSize="small"
                  sx={{ fill: (theme) => theme.palette.primary.main }}
                />
              )}
            </Stack>
          </MenuItem>
        ))}
      </Menu>

      <Modal
        widthVariant="medium"
        isOpen={isMeetingNoteConfigurationModalOpen}
        onClose={() => setIsMeetingNoteConfigurationModalOpen(false)}
        data-testid={`meeting-note-configuration-${argument.id}`}
      >
        <EditArgumentAnswer
          argument={argument}
          onSuccess={() => {
            setIsMeetingNoteConfigurationModalOpen(false)
            handleClose()
          }}
        />
      </Modal>
    </>
  )
}
