import { autoformatPlugin } from "./plugins/autoformatPlugin"
import {
  blockquoteComponents,
  blockquotePlugin,
} from "./plugins/blockquotePlugin"
import { boldPlugin } from "./plugins/boldPlugin"
import {
  cloudAttachmentPlugin,
  cloudComponents,
  createMyCloudPlugin,
} from "./plugins/cloudPlugin"
import { comboboxPlugin } from "./plugins/comboboxPlugin"
import { fontBackgroundColorPlugin } from "./plugins/fontBackgroundColorPlugin"
import { fontColorPlugin } from "./plugins/fontColorPlugin"
import { indentListPlugin } from "./plugins/indentListPlugin"
import { indentPlugin } from "./plugins/indentPlugin"
import { italicPlugin } from "./plugins/italicPlugin"
import { linkComponents, linkPlugin } from "./plugins/linkPlugin"
import { mentionComponents, mentionPlugin } from "./plugins/mentionPlugin"
import { nodeIdPlugin } from "./plugins/nodeIdPlugin"
import { paragraphComponents, paragraphPlugin } from "./plugins/paragraphPlugin"
import { resetNodePlugin } from "./plugins/resetNodePlugin"
import { selectOnBackspacePlugin } from "./plugins/selectOnBackspacePlugin"
import { softBreakPlugin } from "./plugins/softBreakPlugin"
import { todoListComponents, todoListPlugin } from "./plugins/todoListPlugin"
import { createMyTogglePlugin, toggleComponents } from "./plugins/togglePlugin"
import { trailingBlockPlugin } from "./plugins/trailingBlockPlugin"
import { underlinePlugin } from "./plugins/underlinePlugin"
import { createMyPlugins } from "./types"

export function createAllPlugins({ uploadsHost }: { uploadsHost: string }) {
  return createMyPlugins(
    [
      paragraphPlugin,
      nodeIdPlugin,
      boldPlugin,
      italicPlugin,
      underlinePlugin,
      fontColorPlugin,
      fontBackgroundColorPlugin,
      blockquotePlugin,
      indentPlugin,
      indentListPlugin,
      softBreakPlugin,
      autoformatPlugin,
      resetNodePlugin,
      trailingBlockPlugin,
      selectOnBackspacePlugin,
      linkPlugin,
      comboboxPlugin,
      mentionPlugin,
      createMyTogglePlugin(), // Must be recreated each time to separate the stores. This won't be necessary when Plate has a store layer on the editor.
      todoListPlugin,
      createMyCloudPlugin(uploadsHost),
      cloudAttachmentPlugin,
    ],
    {
      components: {
        ...paragraphComponents,
        ...mentionComponents,
        ...blockquoteComponents,
        ...linkComponents,
        ...toggleComponents,
        ...todoListComponents,
        ...cloudComponents,
      },
    }
  )
}
