import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined"
import ErrorIcon from "@mui/icons-material/Error"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined"
import { Theme } from "@mui/material"
import MuiAlert, {
  AlertColor,
  AlertProps as MuiAlertProps,
} from "@mui/material/Alert"
import { forwardRef } from "react"

import Typography from "./Typography"

export type AlertProps = {
  severity: AlertColor
  children: React.ReactNode
  onClose?: MuiAlertProps["onClose"]
}

export default forwardRef(function Alert(
  { severity, children, onClose }: AlertProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <MuiAlert
      severity={severity}
      icon={<AlertIcon severity={severity} />}
      ref={ref}
      onClose={onClose}
      sx={{
        backgroundColor: (theme) => backgroundColor(theme, severity),
        py: 1,
        "& .MuiAlert-icon": {
          mr: 1,
        },
      }}
    >
      <Typography
        variant="smNormal"
        color={(theme) => theme.palette.text.primary}
      >
        {children}
      </Typography>
    </MuiAlert>
  )
})

function backgroundColor(theme: Theme, severity: AlertColor) {
  switch (severity) {
    case "success":
      return theme.palette.success[50]
    case "info":
      return theme.palette.primary[50]
    case "warning":
      return theme.palette.warning[50]
    case "error":
      return theme.palette.error[50]
  }
}

function AlertIcon({ severity }: { severity: AlertColor }) {
  switch (severity) {
    case "success":
      return <CheckCircleOutlinedIcon fontSize="small" />
    case "info":
      return <InfoOutlinedIcon fontSize="small" />
    case "warning":
      return <WarningOutlinedIcon fontSize="small" />
    case "error":
      return <ErrorIcon fontSize="small" />
  }
}
