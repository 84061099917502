import styled from "styled-components"

import Typography from "ds/Typography"

export default styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.common.white};
  text-overflow: ellipsis;
`
