import MoreVert from "@mui/icons-material/MoreVert"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { IArgumentGroup } from "api/types/argumentGroups"
import { IArgument } from "api/types/arguments"

import IconButton from "ds/IconButton"
import Menu from "ds/Menu"
import { AlertSnackbar } from "ds/Snackbar"

import useAnchorEl from "utils/hooks/useAnchorEl"

import CopyArgumentGroup from "./CopyArgumentGroup"
import DeleteArgumentGroup from "./DeleteArgumentGroup"
import DuplicateArgumentGroup from "./DuplicateArgumentGroup"
import PasteInArgumentGroup from "./PasteInArgumentGroup"

interface Props {
  argumentGroup: IArgumentGroup
  arguments: IArgument[]
  showDelete?: boolean
}

export default function ArgumentGroupActions({
  argumentGroup,
  arguments: _arguments,
  showDelete = true,
}: Props) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const [copySuccessSnackOpen, setCopySuccessSnackOpen] = useState(false)
  const [duplicateErrorSnackOpen, setDuplicateErrorSnackOpen] = useState(false)
  const [duplicateSuccessSnackOpen, setDuplicateSuccessSnackOpen] =
    useState(false)
  const [pasteErrorSnackOpen, setPasteErrorSnackOpen] = useState(false)
  const [
    pasteEmptyOrInconsistentSnackOpen,
    setPasteEmptyOrInconsistentSnackOpen,
  ] = useState(false)
  const [pasteSuccessSnackOpen, setPasteSuccessSnackOpen] = useState(false)

  const label = intl.formatMessage({
    id: "playbookEdit.argumentGroups.actions",
    defaultMessage: "Talking point group actions",
  })

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-label={label}
        className="argument-group-actions"
        opacityTransitionOnHover
      >
        <MoreVert fontSize="small" />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {showDelete && (
          <DeleteArgumentGroup
            argumentGroup={argumentGroup}
            arguments={_arguments}
            afterDelete={handleClose}
          />
        )}

        <CopyArgumentGroup
          argumentGroup={argumentGroup}
          onSuccess={() => {
            handleClose()
            setCopySuccessSnackOpen(true)
          }}
        />

        <PasteInArgumentGroup
          argumentGroup={argumentGroup}
          onSuccess={() => {
            handleClose()
            setPasteSuccessSnackOpen(true)
          }}
          onError={() => setPasteErrorSnackOpen(true)}
          onInconsistency={() => setPasteEmptyOrInconsistentSnackOpen(true)}
        />

        <DuplicateArgumentGroup
          argumentGroup={argumentGroup}
          onSuccess={() => {
            handleClose()
            setDuplicateSuccessSnackOpen(true)
          }}
          onError={() => setDuplicateErrorSnackOpen(true)}
        />
      </Menu>

      <AlertSnackbar
        severity="success"
        open={copySuccessSnackOpen}
        onClose={() => setCopySuccessSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.copy.success" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="success"
        open={duplicateSuccessSnackOpen}
        onClose={() => setDuplicateSuccessSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.success" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="error"
        open={duplicateErrorSnackOpen}
        onClose={() => setDuplicateErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.error.api" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="error"
        open={pasteErrorSnackOpen}
        onClose={() => setPasteErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.error.api" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="error"
        open={pasteEmptyOrInconsistentSnackOpen}
        onClose={() => setPasteEmptyOrInconsistentSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.error.emptyOrInconsitent" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="success"
        open={pasteSuccessSnackOpen}
        onClose={() => setPasteSuccessSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.success" />
      </AlertSnackbar>
    </>
  )
}
