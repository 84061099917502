import axios from "./axios"
import { IArgumentResponse } from "./types/arguments"
import {
  ICRMPropertyMeetingNoteConfigurationParams,
  ICustomMeetingNoteConfigurationParams,
} from "./types/meetingNoteConfigurations"

export const apiUpsertMeetingNoteConfiguration = (
  params: ICustomMeetingNoteConfigurationParams
): Promise<IArgumentResponse> =>
  axios
    .post("api/meeting_note_configurations/upsert", params)
    .then((res) => res.data)

export const apiUpsertFromCRMMeetingNoteConfiguration = (
  params: ICRMPropertyMeetingNoteConfigurationParams
): Promise<IArgumentResponse> =>
  axios
    .post("api/meeting_note_configurations/upsert_from_crm", params)
    .then((res) => res.data)

export const apiRemoveMeetingNoteConfiguration = ({
  argumentId,
}: {
  argumentId: string
}): Promise<IArgumentResponse> =>
  axios
    .delete("api/meeting_note_configurations/remove", {
      data: { argumentId },
    })
    .then((res) => res.data)
