import { FormattedMessage, IntlShape, MessageDescriptor } from "react-intl"

const descriptor: MessageDescriptor = {
  id: "argumentGroups.defaultName",
  defaultMessage: "New group",
}

export function getDefaultArgumentGroupName(intl: IntlShape) {
  return intl.formatMessage(descriptor)
}

export default function DefaultArgumentGroupName() {
  return <FormattedMessage {...descriptor} />
}
