import useSearchParam from "utils/hooks/useSearchParam"

export default function useArgumentSearchQueryParam() {
  const [queryParam, setQueryParam] = useSearchParam("query")
  const isOpen = queryParam !== undefined
  const onClose = () => setQueryParam(undefined)
  const open = () => setQueryParam("")

  return [queryParam, setQueryParam, { isOpen, onClose, open }] as const
}
