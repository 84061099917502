import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"

import { IBusinessUnit } from "api/types/businessUnits"

import useMe from "authentication/useMe"

import Typography from "ds/Typography"

import { isOrganizationAdmin } from "services/organizationMemberships"
import { getPlaybookPath } from "services/playbooks"

import NoPlaybookCreationCTAs from "./BusinessUnitPlaybooks/NoPlaybookCreationCTAs"

interface Props {
  businessUnit: IBusinessUnit
}

export default function NoPlaybook({ businessUnit }: Props) {
  const user = useMe()
  const navigate = useNavigate()
  return (
    <Box sx={{ p: 2, mt: 16, display: "flex", justifyContent: "center" }}>
      {/* TODO isOrganizationAdmin takes currently an org ID, change */}
      {user.isSuperAdmin ||
      isOrganizationAdmin(user, businessUnit.organizationId) ? (
        <NoPlaybookCreationCTAs
          businessUnitId={businessUnit.id}
          onPlaybookCreation={(playbook) => {
            navigate(getPlaybookPath(playbook.businessUnitId, playbook.id))
          }}
        />
      ) : (
        <Typography variant="baseNormal">
          <FormattedMessage id="appRoot.noPlaybook.explanationForNonAdmins" />
        </Typography>
      )}
    </Box>
  )
}
