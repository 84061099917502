import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import { ICRMMeetingType } from "api/types/CRMMeetingTypes"

import TableCell from "ds/TableCell"
import TableRow from "ds/TableRow"
import Typography from "ds/Typography"

// https://css-tricks.com/almanac/properties/l/line-clamp/
// Non-standard css but supported by allmost all browsers
const ParagraphWithEllipsis = styled(Typography)`
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface Props {
  meetingType: ICRMMeetingType
  onClick: React.MouseEventHandler<HTMLTableRowElement>
}

export default function MeetingTypesRow({ meetingType, onClick }: Props) {
  return (
    <TableRow onClick={onClick} hover sx={{ cursor: "pointer" }}>
      <TableCell>{meetingType.name}</TableCell>
      <TableCell>
        <ParagraphWithEllipsis variant="smNormal">
          {meetingType.rawGeneralNoteTemplate || (
            <FormattedMessage
              id="meetingTypes.generalNoteTemplate.empty"
              defaultMessage="(empty)"
            />
          )}
        </ParagraphWithEllipsis>
      </TableCell>
    </TableRow>
  )
}
