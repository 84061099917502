import { ListStyleType, toggleIndentList } from "@udecode/plate-indent-list"

import { MyAutoformatRule } from "ds/RichTextNew/types"

export const autoformatIndentLists: MyAutoformatRule[] = [
  {
    mode: "block",
    type: "list",
    match: ["* ", "- "],
    format: (editor) => {
      toggleIndentList(editor, {
        listStyleType: ListStyleType.Disc,
      })
    },
  },
  {
    mode: "block",
    type: "list",
    match: ["1. ", "1) "],
    format: (editor) =>
      toggleIndentList(editor, {
        listStyleType: ListStyleType.Decimal,
      }),
  },
]
