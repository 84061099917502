import { useState } from "react"
import { useIntl } from "react-intl"
import { useDebounce } from "use-debounce"

import Stack from "ds/Stack"
import TextField from "ds/TextField"

import CreateOrganization from "../Organization/CreateOrganization"
import OrganizationsIndex from "./Organizations/OrganizationsIndex"

const debounceWaitMs = 250

export default function Organizations() {
  const [query, setQuery] = useState("")
  const [debouncedQuery] = useDebounce(query, debounceWaitMs)
  const intl = useIntl()

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          name="organization-name"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          label={intl.formatMessage({
            id: "organizations.search.title",
          })}
        />

        <CreateOrganization addCurrentUser={false} />
      </Stack>

      <OrganizationsIndex name={debouncedQuery} />
    </Stack>
  )
}
