import { useParams } from "react-router-dom"

import ControlledError from "ds/ControlledError"

import AuthenticationPage from "./AuthenticationPage"

export default function CRMAuthentication() {
  const params = useParams()
  const crmName = params.crmName || "none"

  switch (crmName) {
    case "hubspot":
      return <AuthenticationPage integrationName="hubspot" />
    case "salesforce":
      return <AuthenticationPage integrationName="salesforce" />
    default:
      return <ControlledError>Unknown CRM: {crmName}</ControlledError>
  }
}
