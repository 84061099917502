import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"

import {
  apiRemoveMeetingNoteConfiguration,
  apiUpsertFromCRMMeetingNoteConfiguration,
  apiUpsertMeetingNoteConfiguration,
} from "api/meetingNoteConfigurations"
import { IArgumentResponse } from "api/types/arguments"

import { buildArgumentKey, buildArgumentsKey } from "./argumentQueries"

export function useMeetingNoteConfigurationUpsertMutation() {
  return useBaseMeetingNoteConfigurationUpsertMutation(
    apiUpsertMeetingNoteConfiguration
  )
}

export function useMeetingNoteConfigurationUpsertFromCRMMutation() {
  return useBaseMeetingNoteConfigurationUpsertMutation(
    apiUpsertFromCRMMeetingNoteConfiguration
  )
}

export function useMeetingNoteConfigurationRemoveMutation() {
  return useBaseMeetingNoteConfigurationUpsertMutation(
    apiRemoveMeetingNoteConfiguration
  )
}

function useBaseMeetingNoteConfigurationUpsertMutation<TError, TVariables>(
  mutationFn: MutateFunction<IArgumentResponse, TError, TVariables>
) {
  const queryClient = useQueryClient()
  return useMutation(mutationFn, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        buildArgumentsKey(data.argument.argumentTypeId)
      )
      queryClient.invalidateQueries(buildArgumentKey(data.argument.id))
    },
  })
}
