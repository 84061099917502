import MuiAvatar, { AvatarProps } from "@mui/material/Avatar"
import { forwardRef } from "react"

export type { AvatarProps }

export default forwardRef(function Avatar(
  props: AvatarProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <MuiAvatar
      {...props}
      sx={{
        width: 32,
        height: 32,
        fontSize: (theme) => theme.typography.baseNormal.fontSize,
        lineHeight: (theme) => theme.typography.baseNormal.lineHeight,
        ...props.sx,
      }}
      ref={ref}
    />
  )
})
