import axios from "./axios"
import { IBulkCreateBusinessUnitMembershipsParams } from "./types/businessUnitMemberships"

export const apiDeactivateBusinessUnitMembership = (
  id: string
): Promise<undefined> =>
  axios
    .patch(`api/business_unit_memberships/${id}/deactivate`)
    .then((res) => res.data)

export const apiCreateBusinessUnitMemberships = (
  params: IBulkCreateBusinessUnitMembershipsParams
): Promise<undefined> =>
  axios.post("api/business_unit_memberships", params).then((res) => res.data)
