import GroupsIcon from "@mui/icons-material/Groups"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"

import { IBusinessUnit } from "api/types/businessUnits"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"

import { useConfig } from "components/Config"

import { getManageBusinessUnitPath } from "services/businessUnits"

interface Props {
  businessUnit: IBusinessUnit
  onClick: React.MouseEventHandler
}

export default function BusinessUnitMenuItem({ businessUnit, onClick }: Props) {
  const config = useConfig()
  const to = getManageBusinessUnitPath(businessUnit)
  return (
    <MenuItem
      component={IS_CHROME_EXTENSION ? "a" : Link}
      to={IS_CHROME_EXTENSION ? undefined : to}
      href={IS_CHROME_EXTENSION ? `${config.appUrl}${to}` : undefined}
      target={IS_CHROME_EXTENSION ? "_blank" : undefined}
      onClick={onClick}
    >
      <ListItemIcon>
        <GroupsIcon />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="navigation.businessUnit" />
      </ListItemText>
    </MenuItem>
  )
}
