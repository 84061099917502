import { useState } from "react"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"

import { apiCreateCRMContact, apiPullCRMContacts } from "api/CRMContacts"
import {
  ICRMContact,
  ICRMContactSearchResult,
  isExternalContactSearchResult,
} from "api/types/CRMContacts"

import { AlertSnackbar } from "ds/Snackbar"

import CRMContactSelector, {
  CRMContactSelectorProps,
} from "components/App/CRM/contacts/CRMContactSelector"

import { buildCrmContactSearchResults } from "services/crmContacts"

const NEW_OPTION_ID = "new"

interface CreatableOption {
  origin: "DB"
  id: string
  name: string
  inputValue: string
}

function isCreatableOption(
  option: ICRMContactSearchResult | CreatableOption
): option is CreatableOption {
  return option.id === NEW_OPTION_ID
}

function buildCreatableOption(
  inputValue: string,
  intl: IntlShape
): CreatableOption {
  return {
    origin: "DB",
    id: NEW_OPTION_ID,
    name: intl.formatMessage(
      {
        id: "playbook.meeting.information.createContactNameInputTitle",
        defaultMessage: 'Create contact "{name}"',
      },
      { name: inputValue }
    ),
    inputValue,
  }
}

const InputLabel = () => (
  <FormattedMessage
    id="playbook.meeting.information.crmContacts.title"
    defaultMessage="Contact(s)"
  />
)

interface Props
  extends Omit<
    CRMContactSelectorProps<true, false, CreatableOption>,
    "onChange" | "filterOptions" | "multiple" | "isOptionAlternative" | "value"
  > {
  onChange: (newValue: ICRMContact[]) => void
  value: ICRMContact[]
  companyId: string | null
}

export default function CRMContactsCreatable({
  value,
  onChange,
  businessUnitId,
  inputLabel = <InputLabel />,
  companyId,
  ...props
}: Props) {
  const intl = useIntl()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const onChangeAutocomplete: CRMContactSelectorProps<
    true,
    false,
    CreatableOption
  >["onChange"] = async (_event, newValue) => {
    const creatableValues = newValue.filter(isCreatableOption)
    const pullableValues = newValue.filter(
      (value) =>
        !isCreatableOption(value) && isExternalContactSearchResult(value)
    )

    const newlyCreatedContacts = await Promise.all(
      creatableValues.map(async (creatableValue) => {
        const { crmContact } = await apiCreateCRMContact({
          name: creatableValue.inputValue,
          email: null,
          businessUnitId,
          companyId,
        })
        return crmContact
      })
    )

    let pulledContacts: ICRMContact[] = []
    if (pullableValues.length > 0) {
      try {
        const pullResult = await apiPullCRMContacts({
          businessUnitId,
          contactExternalIds: pullableValues.map(({ id }) => id),
        })
        pulledContacts = pullResult.crmContacts
      } catch {
        setErrorSnackOpen(true)
      }
    }

    const newContacts = newValue.map((value) => {
      if (isCreatableOption(value)) {
        return newlyCreatedContacts[creatableValues.indexOf(value)]
      } else if (isExternalContactSearchResult(value)) {
        return pulledContacts[pullableValues.indexOf(value)]
      } else {
        return value.crmContact
      }
    })

    return onChange(newContacts)
  }

  return (
    <>
      <CRMContactSelector
        value={buildCrmContactSearchResults(value)}
        multiple
        businessUnitId={businessUnitId}
        onChange={onChangeAutocomplete}
        inputLabel={<InputLabel />}
        filterOptions={(options, { inputValue }) => {
          if (!inputValue) return options
          return [...options, buildCreatableOption(inputValue, intl)]
        }}
        isOptionAlternative={isCreatableOption}
        companyId={companyId}
        {...props}
      />

      <AlertSnackbar
        severity="error"
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbook.meeting.information.crmContacts.pull.error" />
      </AlertSnackbar>
    </>
  )
}
