import { FormattedMessage } from "react-intl"

import Autocomplete from "ds/Autocomplete"

import { BCP47Lang, BCP47Languages } from "./bcp47"

interface Props {
  value: BCP47Lang
  onChange: (language: BCP47Lang) => void
  disabled: boolean
}

export function SpeechToTextLanguagePicker({
  value,
  onChange,
  disabled,
}: Props) {
  return (
    <Autocomplete<BCP47Lang, false, true, false>
      disableClearable
      value={value}
      onChange={(_e, value) => onChange(value)}
      multiple={false}
      inputLabel={<FormattedMessage id="common.language" />}
      options={BCP47Languages}
      sx={{ minWidth: "125px" }}
      disabled={disabled}
    />
  )
}
