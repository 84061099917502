import SettingsIcon from "@mui/icons-material/Settings"
import { FormattedMessage } from "react-intl"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"

interface Props {
  onClick: React.MouseEventHandler<HTMLLIElement>
}

export default function SettingsMenuItem({ onClick }: Props) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="navigation.settings" defaultMessage="Settings" />
      </ListItemText>
    </MenuItem>
  )
}
