import { Box } from "@mui/material"

import { IArgumentSegment } from "api/types/argumentSegments"

interface Props {
  argumentSegment: IArgumentSegment
}

export default function ArgumentSegmentColorIndicator({
  argumentSegment,
}: Props) {
  return (
    <Box
      sx={{
        width: "16px",
        height: "16px",
        lineHeight: 0,
        backgroundColor: argumentSegment.colorCode,
        borderRadius: 0.5,
      }}
    />
  )
}
