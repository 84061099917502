import { HotkeyPlugin } from "@udecode/plate"
import {
  ELEMENT_BLOCKQUOTE,
  createBlockquotePlugin,
} from "@udecode/plate-block-quote"

import Blockquote from "../components/Blockquote"
import { MyEditor, MyValue } from "../types"

export const blockquotePlugin = createBlockquotePlugin<
  HotkeyPlugin,
  MyValue,
  MyEditor
>()

export const blockquoteComponents = {
  [ELEMENT_BLOCKQUOTE]: Blockquote,
}
