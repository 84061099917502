import { IArgumentFieldValue } from "./argumentFieldValues"
import { IMeetingNoteConfiguration } from "./meetingNoteConfigurations"

export type IArgumentSegmentRuleOperator =
  | "ALL"
  | "ONLY"
  | "EXCEPT"
  | "MULTIPLE"

interface IArgumentSegmentRuleCommon {
  argumentSegmentationId: string
  operator: IArgumentSegmentRuleOperator
  argumentSegmentIds?: string[]
}

export interface IArgumentSegmentRuleAll extends IArgumentSegmentRuleCommon {
  operator: "ALL"
}

export interface IArgumentSegmentRuleOnly extends IArgumentSegmentRuleCommon {
  operator: "ONLY"
  argumentSegmentIds: string[]
}

export interface IArgumentSegmentRuleExcept extends IArgumentSegmentRuleCommon {
  operator: "EXCEPT"
  argumentSegmentIds: string[]
}

export type IArgumentSegmentRule =
  | IArgumentSegmentRuleAll
  | IArgumentSegmentRuleOnly
  | IArgumentSegmentRuleExcept

export interface IArgumentSegmentRuleMultiple
  extends IArgumentSegmentRuleCommon {
  operator: "MULTIPLE"
}

export const ARGUMENT_KINDS = [
  "TIP",
  "ANSWER",
  "INFO",
  "REFERENCE",
  "TALKING_POINT",
  "VOTE",
  "CHECKBOX",
] as const

export type IArgumentKind = typeof ARGUMENT_KINDS[number]

export interface IArgument {
  id: string
  argumentGroupId: string | null
  argumentTypeId: string
  archived: boolean
  segmentRules: IArgumentSegmentRule[]
  argumentFieldValues: IArgumentFieldValue[]
  votesCount: number
  kind: IArgumentKind
  notVisitedNotificationsCount: number
  meetingNoteConfiguration: IMeetingNoteConfiguration | null
  fieldsLastTouchedAt: string
}

export interface IArgumentWithMeetingNoteConfiguration extends IArgument {
  meetingNoteConfiguration: IMeetingNoteConfiguration
}

export interface IArgumentUpdateParams {
  id: string
  argumentGroupId?: string | null
  segmentRules?: IArgumentSegmentRule[]
}

export interface IArgumentResponse {
  argument: IArgument
}

export interface IArgumentUpdateKindParams {
  id: string
  kind: IArgumentKind
}

export interface IArgumentBulkUpdateParams {
  argumentIds: string[]
  playbookId: string
  argumentGroupId?: string
  segmentRules?: (IArgumentSegmentRule | IArgumentSegmentRuleMultiple)[]
}

export interface IArgumentBulkUpdateResponse {
  arguments: IArgument[]
}

export interface IArgumentMoveParams {
  argumentIds: string[]
  argumentTypeId: string
}

export interface IArgumentCreateParams {
  playbookId: string
  argumentTypeId: string
  argumentGroupId?: string
  segmentRules: IArgumentSegmentRule[]
}

export interface IArgumentSearchResult {
  argumentGroupName: string | null
  title: string
  highlight: {
    title: string
    argumentGroupName: string | null
    otherFields: string[]
  }
  argument: IArgument
}

export interface IArgumentsPasteParams {
  argumentIds: string[]
  argumentGroupId: string | null
  argumentTypeId: string
  segmentRules: IArgumentSegmentRule[]
  relativeArgumentId?: string
}

export type IArgumentsPasteResponse = IArgument[]

export type IArgumentsArchiveResponse = {
  arguments: IArgument[]
}

export type IArgumentsRestoreResponse = {
  arguments: IArgument[]
}

export type IArgumentsDuplicateResponse = {
  arguments: IArgument[]
}
