import AddIcon from "@mui/icons-material/Add"
import { FormattedMessage } from "react-intl"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"

import Button from "ds/Button"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentSegmentationCreateMutation } from "components/App/Playbook/queries/argumentSegmentationQueries"

interface Props {
  onAddArgumentSegmentation: (
    argumentSegmentation: IArgumentSegmentation
  ) => void
}

export default function AddArgumentSegmentation({
  onAddArgumentSegmentation,
}: Props) {
  const { playbook } = usePlaybook()
  const createMutation = useArgumentSegmentationCreateMutation(playbook.id)

  function onClick() {
    createMutation.mutate(playbook.id, {
      onSuccess: ({ argumentSegmentation }) =>
        onAddArgumentSegmentation(argumentSegmentation),
    })
  }

  return (
    <Button
      variant="contained"
      startIcon={<AddIcon />}
      onClick={onClick}
      disabled={createMutation.isLoading}
    >
      <FormattedMessage
        id="playbookEdit.argumentSegmentations.add"
        defaultMessage="Add segmentation"
      />
    </Button>
  )
}
