import { createContext, useContext } from "react"

interface Props {
  meetingNotesPanelRef: React.RefObject<HTMLDivElement>
  setHasMeetingNotesPanelOpen: (value: boolean) => void
  children: React.ReactElement
}

export default function MeetingNotesPanelProvider({
  meetingNotesPanelRef,
  setHasMeetingNotesPanelOpen,
  children,
}: Props) {
  return (
    <PanelContext.Provider
      value={{ meetingNotesPanelRef, setHasMeetingNotesPanelOpen }}
    >
      {children}
    </PanelContext.Provider>
  )
}

type ProvidedValue = {
  meetingNotesPanelRef: React.RefObject<HTMLDivElement>
  setHasMeetingNotesPanelOpen: (value: boolean) => void
}

const PanelContext = createContext<ProvidedValue | null>(null)

export function usePanel(): ProvidedValue {
  const providedValue = useContext(PanelContext)
  if (providedValue) return providedValue
  throw new Error(
    "usePanel was called without being wrapped in a MeetingNotesPanelProvider"
  )
}
