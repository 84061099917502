import { CssBaseline, ThemeProvider } from "@mui/material"
import { Breakpoints } from "@mui/material/styles"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import "overlayscrollbars/overlayscrollbars.css"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter, MemoryRouter } from "react-router-dom"
import { StyleSheetManager } from "styled-components"

import { createQueryClient } from "api/queryClient"

import AuthenticationProvider from "authentication/Provider"

import ErrorBoundary from "ds/ErrorBoundary"
import { ModalProps } from "ds/Modal"

import { createCustomTheme } from "themes/default"

import TranslationsProvider from "translations/TranslationsProvider"

import "../activestorage"
import "../index.css"
import "../monitoring"
import reportWebVitals from "../reportWebVitals"
import { WithLoadedConfig } from "./Config"

const queryClient = createQueryClient()

export default function AppWrappers({
  appRoot,
  breakpointValues,
  styleSheetTarget,
  children,
}: {
  appRoot?: ModalProps["container"]
  breakpointValues?: Breakpoints["values"]
  styleSheetTarget?: HTMLElement | ShadowRoot
  children: React.ReactNode
}) {
  const Router = IS_CHROME_EXTENSION ? MemoryRouter : BrowserRouter
  // @ts-ignore
  window.theme = createCustomTheme({ appRoot, breakpointValues })
  return (
    <HelmetProvider>
      <Router>
        <ThemeProvider theme={createCustomTheme({ appRoot, breakpointValues })}>
          <StyleSheetManager target={styleSheetTarget}>
            <TranslationsProvider>
              <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools initialIsOpen={false} />
                  <WithLoadedConfig>
                    <AuthenticationProvider>
                      <>
                        <CssBaseline />
                        {children}
                      </>
                    </AuthenticationProvider>
                  </WithLoadedConfig>
                </QueryClientProvider>
              </ErrorBoundary>
            </TranslationsProvider>
          </StyleSheetManager>
        </ThemeProvider>
      </Router>
    </HelmetProvider>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
