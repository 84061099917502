import CampaignIcon from "@mui/icons-material/Campaign"
import { useIntl } from "react-intl"

import Avatar from "ds/Avatar"

export default function TalkingPointIndicator() {
  const intl = useIntl()
  return (
    <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.light }}>
      <CampaignIcon
        fontSize="small"
        sx={{ color: (theme) => theme.palette.primary.dark }}
        aria-label={intl.formatMessage({
          id: "playbookEdit.argument.kind.TALKING_POINT",
        })}
      />
    </Avatar>
  )
}
