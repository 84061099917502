import { IArgumentField } from "api/types/argumentFields"

import Divider from "ds/Divider"

import ArgumentFieldResultsGroup from "./ArgumentFieldResultsGroup"

interface Props {
  argumentFields: IArgumentField[]
  onClick: (argumentField: IArgumentField) => void
}

export default function ArgumentFieldResults({
  argumentFields,
  onClick,
}: Props) {
  const activeArgumentFields = argumentFields.filter((x) => !x.archived)
  const archivedArgumentFields = argumentFields.filter((x) => x.archived)

  return (
    <>
      {activeArgumentFields.length > 0 && (
        <ArgumentFieldResultsGroup
          argumentFields={activeArgumentFields}
          onClick={onClick}
        />
      )}

      {archivedArgumentFields.length > 0 && (
        <>
          <Divider>Archive</Divider>
          <ArgumentFieldResultsGroup
            argumentFields={archivedArgumentFields}
            onClick={onClick}
          />
        </>
      )}
    </>
  )
}
