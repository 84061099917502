import { useIntl } from "react-intl"

import { IMeetingArgumentNoteText } from "api/types/meetingArgumentNotes"

import FormControl from "ds/FormControl"
import OutlinedInput from "ds/OutlinedInput"
import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { MyValue } from "ds/RichTextNew/types"

interface Props {
  note: IMeetingArgumentNoteText
  onChange: (newValue: MyValue, newRawValue: string) => void
  disabled: boolean
}

export default function FreeTextForm({ note, onChange, disabled }: Props) {
  const intl = useIntl()
  const placeholder = intl.formatMessage({
    id: "playbookEdit.argumentAnswer.form.typeSelect.free_text",
    defaultMessage: "Free text",
  })

  return (
    <FormControl fullWidth>
      <OutlinedInput
        disabled={disabled}
        // @ts-ignore // TODO RichText typing
        inputComponent={RichTextEditor}
        sx={{
          "& .MuiOutlinedInput-input": {
            p: 1,
            pb: 0,
            flex: 1,
            height: "auto",
          },
        }}
        inputProps={{
          value: note.value,
          // @ts-ignore // TODO RichText typing
          onChange,
          disabled,
          placeholder,
        }}
      />
    </FormControl>
  )
}
