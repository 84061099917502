import SimpleLayout from "components/layouts/SimpleLayout"

import PlaybookHeader from "./PlaybookLayout/Header"

interface Props {
  children: React.ReactNode
}

export default function PlaybookLayout({ children }: Props) {
  return <SimpleLayout header={<PlaybookHeader />}>{children}</SimpleLayout>
}
