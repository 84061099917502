import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import Loader from "ds/Loader"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import BusinessUnitInvitationsTable from "./BusinessUnitInvitationsTable"
import { useBusinessUnit } from "./BusinessUnitProvider"
import { usePendingInvitationsInfiniteQuery } from "./businessUnitQueries"

interface Props {
  query: string
}

export default function BusinessUnitInvitationsIndex({ query }: Props) {
  const businessUnit = useBusinessUnit()

  const invitationsQuery = usePendingInvitationsInfiniteQuery(
    businessUnit.id,
    query
  )

  if (invitationsQuery.isLoading) {
    return <Loader />
  }

  if (invitationsQuery.isError) {
    return <UncontrolledError error={invitationsQuery.error} />
  }

  const invitations = invitationsQuery.data.pages
    .map((page) => {
      return page.businessUnitPendingInvitations
    })
    .flat()

  return (
    <>
      {invitations.length > 0 && (
        <>
          <Typography variant="smSemi">
            <FormattedMessage id="organizations.users.pendingInvitations" />
          </Typography>

          <BusinessUnitInvitationsTable invitations={invitations} />

          {invitationsQuery.isFetchingNextPage && <Loader />}

          {invitationsQuery.hasNextPage &&
            !invitationsQuery.isFetchingNextPage && (
              <Box>
                <Button onClick={() => invitationsQuery.fetchNextPage()}>
                  <FormattedMessage id="organizations.users.pendingInvitations.index.loadMore" />
                </Button>
              </Box>
            )}
        </>
      )}
    </>
  )
}
