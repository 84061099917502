import MoreVert from "@mui/icons-material/MoreVert"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { FormattedMessage, useIntl } from "react-intl"
import styled from "styled-components"

import { IBusinessUnit } from "api/types/businessUnits"
import { IIntegration } from "api/types/integrations"

import Button from "ds/Button"
import Menu from "ds/Menu"
import MenuItem from "ds/MenuItem"

import { useBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"

import { isSuccessfulIntegration } from "services/applicationIntegration"

import useAnchorEl from "utils/hooks/useAnchorEl"
import useConfirm from "utils/hooks/useConfirm"

import { useApplicationIntegrationRevokeMutation } from "./applicationIntegrationsQueries"

const UnstyledExternalLink = styled.a`
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit;
    text-decoration: inherit;
  }
`

interface Props {
  availableIntegration: {
    name: string
    authorizationUrl: string
    authorizationSandboxUrl: string | null
  }
  integration: IIntegration | null
}

export default function ApplicationIntegrationActions({
  availableIntegration,
  integration,
}: Props) {
  const businessUnit = useBusinessUnit()
  const intl = useIntl()
  const confirm = useConfirm()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const revokeIntegrationMutation = useApplicationIntegrationRevokeMutation(
    businessUnit.id
  )

  const canRevoke =
    integration &&
    isSuccessfulIntegration(integration) &&
    integration.name === availableIntegration.name

  const revokeIntegration = (integrationId: string) => {
    if (!confirm()) return
    revokeIntegrationMutation.mutate(integrationId)
  }

  const authorizationUrl = buildAuthorizationUrl(
    availableIntegration.authorizationUrl,
    businessUnit
  )

  const authorizationSandboxUrl =
    availableIntegration.authorizationSandboxUrl &&
    buildAuthorizationUrl(
      availableIntegration.authorizationSandboxUrl,
      businessUnit,
      true
    )

  return (
    <>
      <Button
        onClick={handleClick}
        aria-label={intl.formatMessage({
          id: "businessUnits.playbooks.actions",
          defaultMessage: "Playbooks actions",
        })}
      >
        <MoreVert />
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem>
          <Button disabled={!!integration}>
            <UnstyledExternalLink
              href={authorizationUrl.toString()}
              target={IS_CHROME_EXTENSION ? "_blank" : undefined}
            >
              <FormattedMessage
                id="settings.applicationsIntegration.table.actions.connect"
                defaultMessage="Connect"
              />
            </UnstyledExternalLink>
          </Button>
        </MenuItem>

        {authorizationSandboxUrl && (
          <MenuItem>
            <Button disabled={!!integration}>
              <UnstyledExternalLink
                href={authorizationSandboxUrl.toString()}
                target={IS_CHROME_EXTENSION ? "_blank" : undefined}
              >
                <FormattedMessage
                  id="settings.applicationsIntegration.table.actions.connectSandbox"
                  defaultMessage="Connect"
                />
              </UnstyledExternalLink>
            </Button>
          </MenuItem>
        )}

        <MenuItem>
          <Button
            onClick={() => integration && revokeIntegration(integration.id)}
            disabled={!canRevoke || revokeIntegrationMutation.isLoading}
          >
            <FormattedMessage
              id="settings.applicationsIntegration.table.actions.revoke"
              defaultMessage="Revoke"
            />
          </Button>
        </MenuItem>
      </Menu>
    </>
  )
}

function buildAuthorizationUrl(
  baseUrl: string,
  businessUnit: IBusinessUnit,
  sandbox: boolean = false
) {
  const authorizationUrl = new URL(baseUrl)
  authorizationUrl.searchParams.set(
    "state",
    `${businessUnit.organizationId};${businessUnit.id};${
      sandbox ? "true" : "false"
    }`
  )
  return authorizationUrl
}
