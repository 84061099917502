import { FormattedMessage } from "react-intl"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"

interface Props {
  argumentSegmentation: IArgumentSegmentation
}

export default function ArgumentSegmentationName({
  argumentSegmentation,
}: Props) {
  if (argumentSegmentation.name) return <>{argumentSegmentation.name}</>
  return <DefaultArgumentSegmentationName />
}

function DefaultArgumentSegmentationName() {
  return (
    <FormattedMessage
      id="argumentSegmentation.defaultName"
      defaultMessage="New segmentation"
    />
  )
}
