import axios from "./axios"
import {
  ICreatePlaybookExportParams,
  ICreatePlaybookExportResponse,
  IGetLatestPlaybookExportAtResponse,
} from "./types/playbookExports"

export const createPlaybookExport = (
  params: ICreatePlaybookExportParams
): Promise<ICreatePlaybookExportResponse> =>
  axios.post("api/playbook_exports", params).then((res) => res.data)

export const getLatestPlaybookExportAt = (
  playbookId: string,
  { signal }: { signal?: AbortSignal }
): Promise<IGetLatestPlaybookExportAtResponse> =>
  axios
    .get("api/playbook_exports/latest_export_at", {
      params: { playbookId },
      signal,
    })
    .then((res) => res.data)
