import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { apiGetUpcomingMeetings } from "api/meetings"
import { IUpcomingMeeting } from "api/types/meetings"

import AsyncAutocomplete from "ds/AsyncAutocomplete"
import { CustomAutocompleteProps } from "ds/Autocomplete"
import { AlertSnackbar } from "ds/Snackbar"

import { useMeeting } from "components/App/Playbook/Meeting/MeetingProvider"

import CRMMeetingItem from "./CRMMeetingSelector/CRMMeetingItem"

function InputLabel() {
  return (
    <FormattedMessage id="playbook.meeting.informations.crmMeetings.title" />
  )
}

function getOptionLabel(option: IUpcomingMeeting) {
  return option.title || "Meeting"
}

function filterOptions(options: IUpcomingMeeting[], filterValue: string) {
  return options.filter((option) => {
    return option.title?.match(new RegExp(filterValue, "i"))
  })
}

interface Props<DisableClearable extends boolean | undefined>
  extends Omit<
    CustomAutocompleteProps<IUpcomingMeeting, false, DisableClearable, false>,
    | "getOptionLabel"
    | "options"
    | "loading"
    | "renderOption"
    | "multiple"
    | "inputLabel"
  > {
  businessUnitId: string
  inputLabel?: React.ReactNode
}

export default function CRMMeetingSelector<
  DisableClearable extends boolean | undefined
>({
  businessUnitId,
  inputLabel = <InputLabel />,
  ...props
}: Props<DisableClearable>) {
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const { company, contacts } = useMeeting()

  return (
    <>
      <AsyncAutocomplete
        multiple={false}
        inputLabel={inputLabel}
        getOptions={() =>
          apiGetUpcomingMeetings(
            businessUnitId,
            contacts.map(({ id }) => id),
            company?.id
          )
        }
        isOptionEqualToValue={(option, value) =>
          option.externalId === value.externalId
        }
        onGetOptionsFailure={() => setErrorSnackOpen(true)}
        renderOption={(props, option) => (
          <CRMMeetingItem
            {...props}
            key={option.externalId}
            crmMeeting={option}
          />
        )}
        getOptionLabel={getOptionLabel}
        filterOptions={(options, { inputValue }) => {
          if (!inputValue) return options
          return filterOptions(options, inputValue)
        }}
        {...props}
      />

      <AlertSnackbar
        severity="error"
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbook.meeting.information.crmMeetings.search.error" />
      </AlertSnackbar>
    </>
  )
}
