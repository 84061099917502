import { useEffect } from "react"

export default function useKeyboardListener(
  listener: (e: KeyboardEvent) => void
) {
  useEffect(() => {
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
  }, [listener])
}
