// TODO move to the PlaybookEdit directory
import CloudDoneIcon from "@mui/icons-material/CloudDone"
import NotesIcon from "@mui/icons-material/Notes"
import { useIntl } from "react-intl"
import styled from "styled-components"

import IconButton from "ds/IconButton"
import Popover from "ds/Popover"
import Stack from "ds/Stack"
import Textarea from "ds/Textarea"
import Tooltip from "ds/Tooltip"
import Typography from "ds/Typography"

import useAnchorEl from "utils/hooks/useAnchorEl"

const TextareaContainer = styled(Stack)`
  width: min(400px, 90vw);
`

const TextareaTitle = styled(Typography)`
  > :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`

interface Props {
  value: string
  onChange: (newValue: string) => void
  isSaved: boolean
  disabled?: boolean
}

export default function AdditionalInfo({
  value,
  onChange,
  isSaved,
  disabled = false,
}: Props) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const additionalInfoLabel = intl.formatMessage({
    id: "playbookEdit.argumentAnswer.form.additionalInfo",
    defaultMessage: "Additional information",
  })

  return (
    <>
      <Tooltip title={value}>
        <IconButton
          color={value ? "primary" : undefined}
          onClick={handleClick}
          aria-label={additionalInfoLabel}
          disabled={disabled}
        >
          <NotesIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Popover
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        onClose={handleClose}
      >
        <TextareaContainer spacing={1} padding={1.5}>
          <TextareaTitle
            variant="smSemi"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span>{additionalInfoLabel}</span>
            <CloudDoneIcon
              color="success"
              fontSize="small"
              sx={{
                opacity: isSaved ? 1 : 0,
                transition: (theme) => theme.transitions.create("opacity"),
              }}
            />
          </TextareaTitle>

          <Textarea
            aria-label={additionalInfoLabel}
            autoFocus
            value={value}
            onChange={(event) => onChange(event.target.value)}
            disabled={disabled}
          />
        </TextareaContainer>
      </Popover>
    </>
  )
}
