import { useIntl } from "react-intl"

import { IArgument } from "api/types/arguments"

import Avatar from "ds/Avatar"
import Checkbox from "ds/Checkbox"

import { useCurrentMeetingArgumentNoteMutation } from "components/App/CRM/Meetings/meetingQueries"
import { useMeeting } from "components/App/Playbook/Meeting/MeetingProvider"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { isArgumentChecked } from "services/meetingArgumentNotes"

export default function CheckboxIndicator({
  argument,
  disabled = false,
}: {
  argument: IArgument
  disabled?: boolean
}) {
  const { playbook, readOnly } = usePlaybook()
  const argumentNoteMutation = useCurrentMeetingArgumentNoteMutation(
    playbook.id
  )
  const { ongoingMeeting } = useMeeting()

  return (
    <BaseCheckboxIndicator
      disabled={
        disabled ||
        readOnly ||
        !ongoingMeeting ||
        argumentNoteMutation.isLoading
      }
      checked={!!ongoingMeeting && isArgumentChecked(argument, ongoingMeeting)}
      onChange={(e) => {
        if (!ongoingMeeting) return
        argumentNoteMutation.mutate({
          meetingId: ongoingMeeting.id,
          argumentId: argument.id,
          fieldType: "checkbox",
          value: e.target.checked,
          rawValue: null,
          additionalInfo: null,
        })
      }}
    />
  )
}

export function BaseCheckboxIndicator({
  disabled = true,
  checked = false,
  onChange,
}: {
  disabled?: boolean
  checked?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}) {
  const intl = useIntl()

  return (
    <Avatar
      sx={{
        bgcolor: (theme) => theme.palette.grey[100],
      }}
    >
      <Checkbox
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        inputProps={{
          "aria-label": intl.formatMessage({
            id: "playbookEdit.argument.kind.CHECKBOX",
          }),
        }}
      />
    </Avatar>
  )
}
