import { Box, SxProps, Theme } from "@mui/material"
import MuiModal, { ModalProps as MuiModalProps } from "@mui/material/Modal"

import mergeSx from "utils/mergeSx"

import ClosingCross, { ClosingCrossContainer } from "./Modal/ClosingCross"

const baseStyle: SxProps<Theme> = {
  position: "absolute",
  overflowY: "auto",
  boxShadow: 24,
  outline: "none",
  bgcolor: (theme) => theme.palette.background.paper,
  p: 2,
  boxSizing: "border-box",
  borderRadius: 1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "min(300px, 100%)",
  maxWidth: (theme) => `calc(100% - ${theme.spacing(2)})`,
  maxHeight: (theme) => `calc(100% - ${theme.spacing(2)})`,
}

function getBoxHeightStyle(heightVariant: HeightVariant): SxProps<Theme> {
  switch (heightVariant) {
    case "fitContent":
      return {}
    case "big":
      return { height: "750px" }
    case "medium":
      return { height: "550px" }
    case "small":
      return { height: "300px" }
    case "stickyTop":
      return { top: (theme) => theme.spacing(1), transform: "translateX(-50%)" }
  }
}

function getBoxWidthStyle(widthVariant: WidthVariant): SxProps<Theme> {
  switch (widthVariant) {
    case "fitContent":
      return {}
    case "big":
      return { width: "650px" }
    case "medium":
      return { width: "500px" }
    case "small":
      return { width: "300px" }
  }
}

function getBoxPaddingStyle(noPadding: boolean): SxProps<Theme> {
  return noPadding ? { p: 0 } : { p: 2 }
}

export interface ModalProps extends Omit<MuiModalProps, "open" | "onClose"> {
  isOpen: boolean
  onClose: () => void
  hideClosingCross?: boolean
  heightVariant?: HeightVariant
  widthVariant?: WidthVariant
  sizeVariant?: SizeVariant
  noPadding?: boolean
}

type SizeVariant = "fitContent" | "big" | "medium" | "small"
type HeightVariant = SizeVariant | "stickyTop"
type WidthVariant = SizeVariant

export default function Modal({
  isOpen,
  onClose,
  children,
  hideClosingCross,
  heightVariant,
  widthVariant,
  sizeVariant = "fitContent",
  noPadding = false,
  ...props
}: ModalProps) {
  return (
    <MuiModal open={isOpen} onClose={onClose} {...props}>
      <Box
        sx={mergeSx(
          baseStyle,
          getBoxHeightStyle(heightVariant || sizeVariant),
          getBoxWidthStyle(widthVariant || sizeVariant),
          getBoxPaddingStyle(noPadding)
        )}
      >
        {!hideClosingCross && (
          <ClosingCrossContainer>
            <ClosingCross onClick={onClose} />
          </ClosingCrossContainer>
        )}
        {children}
      </Box>
    </MuiModal>
  )
}
