import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { useIntl } from "react-intl"

import Avatar from "ds/Avatar"

export default function InformationIndicator() {
  const intl = useIntl()
  return (
    <Avatar sx={{ bgcolor: (theme) => theme.palette.warning[200] }}>
      <InfoOutlinedIcon
        fontSize="small"
        sx={{ color: (theme) => theme.palette.warning[700] }}
        aria-label={intl.formatMessage({
          id: "playbookEdit.argument.kind.INFO",
        })}
      />
    </Avatar>
  )
}
