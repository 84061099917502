import { Box } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"

import { IArgument } from "api/types/arguments"

import FormattedTime from "ds/FormattedTime"
import IconButton from "ds/IconButton"
import Stack from "ds/Stack"
import Tooltip from "ds/Tooltip"
import Typography from "ds/Typography"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useRefreshArgumentMutation } from "components/App/Playbook/queries/argumentQueries"

import { isArgumentStale } from "services/arguments"

import StaleWarningIcon from "./StaleWarningIcon"

interface Props {
  argument: IArgument
}

export default function StalenessStatus({ argument }: Props) {
  const { editMode, activeTypeId } = usePlaybook()
  const isStale = isArgumentStale(argument)
  const refreshArgumentMutation = useRefreshArgumentMutation(activeTypeId)

  function refreshArgument() {
    refreshArgumentMutation.mutate(argument.id)
  }
  const intl = useIntl()

  return (
    <Stack direction="row" justifyContent="end" alignItems="center">
      {isStale && editMode && (
        <Tooltip
          title={intl.formatMessage({
            id: "arguments.staleState.actions.stillValid",
          })}
        >
          <Box>
            <IconButton
              onClick={() => refreshArgument()}
              disabled={!editMode || refreshArgumentMutation.isLoading}
              color="error"
            >
              <StaleWarningIcon fontSize="small" />
            </IconButton>
          </Box>
        </Tooltip>
      )}

      {isStale && !editMode && <StaleWarningIcon fontSize="small" />}

      <Typography
        variant="xsNormal"
        color={(theme) => theme.palette.text.secondary}
      >
        <FormattedMessage
          id="arguments.lastTouchedAt"
          defaultMessage="Last update:"
        />
        &nbsp;
        <FormattedTime
          value={argument.fieldsLastTouchedAt}
          format="shortTimeWithYear"
        />
      </Typography>
    </Stack>
  )
}
