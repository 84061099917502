import { FormattedMessage, useIntl } from "react-intl"

import { IMeeting } from "api/types/meetings"

import Button from "ds/Button"

import { convertMeetingNotesToClipboardText } from "services/meetings"

interface Props {
  meeting: IMeeting
}

export default function CopyMeetingNotes({ meeting }: Props) {
  const intl = useIntl()

  return (
    <Button
      variant="contained"
      onClick={() =>
        navigator.clipboard.writeText(
          convertMeetingNotesToClipboardText(meeting, intl)
        )
      }
    >
      <FormattedMessage
        id="playbook.meeting.endDialog.copyToClipboard"
        defaultMessage="Copy to clipboard"
      />
    </Button>
  )
}
