import { FormattedMessage } from "react-intl"

import Alert from "ds/Alert"

export default function LinkToMeetingNoteConfigurationExplanation() {
  return (
    <Alert severity="info">
      <FormattedMessage id="playbookEdit.argumentSegmentations.linkToMeetingNoteConfigurationExplanation" />
    </Alert>
  )
}
