import DeleteIcon from "@mui/icons-material/DeleteOutline"
import { useIntl } from "react-intl"

import IconButton from "ds/IconButton"

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  disabled: boolean
}

export default function DeleteCTA({ onClick, disabled }: Props) {
  const intl = useIntl()

  return (
    <IconButton
      aria-label={intl.formatMessage({
        id: "playbookEdit.argumentTypes.delete",
        defaultMessage: "Delete",
      })}
      onClick={onClick}
      disabled={disabled}
      opacityTransitionOnHover
      color="error"
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  )
}
