import { FormattedMessage } from "react-intl"

import TableCell from "ds/TableCell"
import TableHead from "ds/TableHead"
import TableRow from "ds/TableRow"

export default function OrganizationsTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <FormattedMessage
            id="organizations.field.name"
            defaultMessage="Name"
          />
        </TableCell>
        <TableCell>
          <FormattedMessage id="organizations.field.businessUnits" />
        </TableCell>

        <TableCell>
          <FormattedMessage
            id="organizations.field.creationDate"
            defaultMessage="Creation date"
          />
        </TableCell>

        <TableCell>
          <FormattedMessage
            id="organizations.actions"
            defaultMessage="Actions"
          />
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
