import axios from "./axios"
import {
  IMeetingArgumentNote,
  IMeetingArgumentNoteParams,
} from "./types/meetingArgumentNotes"

export const apiSaveMeetingArgumentNote = (
  params: IMeetingArgumentNoteParams
): Promise<IMeetingArgumentNote> =>
  axios.post("api/meeting_argument_notes/save", params).then((res) => res.data)
