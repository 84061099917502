// Helper to scroll to a DOM element smoothly
export function scrollToElement(
  elementId: string,
  options: ScrollIntoViewOptions
) {
  const element = document.getElementById(elementId)
  element?.scrollIntoView(options)
}

// Same helper, with a default delay of 500 ms
export function scrollToElementWithDelay(
  elementId: string,
  {
    delay = 500,
    behavior = "smooth",
    block = "center",
    inline = undefined,
  }: {
    delay?: number
    behavior?: ScrollBehavior
    block?: ScrollLogicalPosition
    inline?: ScrollLogicalPosition
  } = {}
) {
  setTimeout(
    () => scrollToElement(elementId, { behavior, block, inline }),
    delay
  )
}
