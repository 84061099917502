import AddIcon from "@mui/icons-material/Add"
import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import { IArgumentGroup } from "api/types/argumentGroups"

import Button from "ds/Button"
import Popover from "ds/Popover"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { filterArgumentGroupsInArgumentType } from "services/argumentGroups"

import useAnchorEl from "utils/hooks/useAnchorEl"

import ArgumentGroupSelector from "./ArgumentGroupSelector"

const ArgumentGroupSelectorBox = styled(Box)`
  width: 260px;
  height: 300px;
  display: flex;
  flex-direction: column;
`

interface Props {
  onAddGroup?: (argumentGroup: IArgumentGroup) => void
  forceHideSearch?: boolean
}

export default function AddArgumentGroup({
  onAddGroup,
  forceHideSearch = false,
}: Props) {
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const id = open ? "add-group" : undefined

  const { playbook, activeTypeId } = usePlaybook()
  const argumentGroupsInThisType = filterArgumentGroupsInArgumentType(
    playbook.argumentGroups,
    activeTypeId
  )
  const shouldDisplaySearch =
    !forceHideSearch && argumentGroupsInThisType.length > 0

  return (
    <>
      <Button
        color="neutral"
        variant="contained"
        onClick={handleClick}
        startIcon={<AddIcon />}
        sx={{ whiteSpace: "nowrap" }}
      >
        <FormattedMessage
          id="playbookEdit.argumentGroups.create"
          defaultMessage="Add a group"
        />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "center" }}
        transformOrigin={{ horizontal: "center", vertical: "center" }}
      >
        <ArgumentGroupSelectorBox>
          <ArgumentGroupSelector
            argumentGroups={shouldDisplaySearch ? argumentGroupsInThisType : []}
            addExistingGroup={(argumentGroup) => {
              onAddGroup?.(argumentGroup)
              handleClose()
            }}
            onCreateGroup={(argumentGroup) => {
              onAddGroup?.(argumentGroup)
              handleClose()
            }}
            onClose={handleClose}
            forceHideSearch={forceHideSearch}
            // Render the options menu next to the search text field
            // And use flexbox to fill the ArgumentGroupSelectorBox
            autocompleteProps={{
              PopperComponent: ({ children }) => <>{children}</>,
              PaperComponent: ({ children }) => <>{children}</>,
              ListboxProps: {
                style: {
                  flexGrow: 1,
                  minHeight: 0,
                },
              },
            }}
          />
        </ArgumentGroupSelectorBox>
      </Popover>
    </>
  )
}
