import { IBusinessUnit } from "api/types/businessUnits"

import { getLastVisitedBusinessUnitId } from "components/App/BusinessUnit/lastVisit"

import { getManageOrganizationPath } from "./organizations"

export function getBusinessUnitPath(businessUnitId: string) {
  return `/bu/${businessUnitId}`
}

export function getManageBusinessUnitPath(businessUnit: IBusinessUnit) {
  return `${getManageOrganizationPath(
    businessUnit.organizationId
  )}${getBusinessUnitPath(businessUnit.id)}`
}

export function getManageDashboardBusinessUnitPath(
  businessUnit: IBusinessUnit
) {
  return `${getManageBusinessUnitPath(businessUnit)}/dashboard`
}

export function getManageUsersBusinessUnitPath(
  organizationId: string,
  businessUnitId: string
) {
  return `${getManageOrganizationPath(organizationId)}${getBusinessUnitPath(
    businessUnitId
  )}/users`
}

export function getManagePlaybooksBusinessUnitPath(
  organizationId: string,
  businessUnitId: string
) {
  return `${getManageOrganizationPath(organizationId)}${getBusinessUnitPath(
    businessUnitId
  )}/playbooks`
}

export function getManageIntegrationsBusinessUnitPath(
  organizationId: string,
  businessUnitId: string
) {
  return `${getManageOrganizationPath(organizationId)}${getBusinessUnitPath(
    businessUnitId
  )}/integrations`
}

export function getValidLastVisitedBusinessUnitId(
  validBusinessUnitIds: string[]
) {
  const lastVisitedBusinessUnitId = getLastVisitedBusinessUnitId()
  if (!lastVisitedBusinessUnitId) return null

  if (validBusinessUnitIds.includes(lastVisitedBusinessUnitId))
    return lastVisitedBusinessUnitId
  return null
}
