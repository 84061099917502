import isEmpty from "lodash/isEmpty"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IBarePlaybook } from "api/types/playbooks"

import Button from "ds/Button"
import { BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

import { useOrganization } from "components/App/Organization/OrganizationProvider"

import { useBusinessUnitPlaybookUpdateMutation } from "./businessUnitPlaybookQueries"

function validate({ name }: { name: string }) {
  const errors: { name?: string } = {}
  if (!name) {
    errors.name = "missing"
  }
  return errors
}

interface Props {
  playbook: IBarePlaybook
  onSuccess: () => void
}

export default function EditPlaybookForm({ playbook, onSuccess }: Props) {
  const organization = useOrganization()
  const [name, setName] = useState(playbook.name || "")
  const updateMutation = useBusinessUnitPlaybookUpdateMutation(organization.id)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)

  const validationErrors = validate({ name })
  const isInvalid = !isEmpty(validationErrors)

  const submit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    updateMutation.mutate(
      { id: playbook.id, playbook: { name } },
      {
        onSuccess: () => {
          setSuccessSnackOpen(true)
          onSuccess()
        },
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <form onSubmit={submit}>
      <Stack spacing={2}>
        <TextField
          label={
            <FormattedMessage
              id="businessUnits.playbooks.fields.name"
              defaultMessage="Name"
            />
          }
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoFocus
        />

        <Button
          color="primary"
          type="submit"
          disabled={updateMutation.isLoading || isInvalid}
        >
          <FormattedMessage
            id="businessUnits.playbooks.actions.form.submit"
            defaultMessage="Submit"
          />
        </Button>
      </Stack>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
    </form>
  )
}
