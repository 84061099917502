import {
  MutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"

import { apiUpdateArgumentFieldValue } from "api/argumentFieldValues"
import {
  apiArchiveArguments,
  apiBulkUpdateArguments,
  apiCreateArgument,
  apiDuplicateArgument,
  apiGetArgument,
  apiGetArguments,
  apiGetArgumentsUsingField,
  apiGetArgumentsWithLinkableConfiguration,
  apiMoveArguments,
  apiPasteArguments,
  apiRefreshArgument,
  apiRestoreArguments,
  apiUpdateArgument,
  apiUpdateArgumentPosition,
  apiUpdateKindArgument,
} from "api/arguments"
import { IArgument, IArgumentResponse } from "api/types/arguments"
import { apiVoteOnArgument } from "api/votes"

import { updateVoteOnCurrentMeeting } from "components/App/CRM/Meetings/meetingQueries"

import {
  insertDuplicatedArguments,
  moveArgument,
  replaceArguments,
} from "services/arguments"

import {
  useInvalidatingMutation,
  useOptimisticMutation,
  useUpdateMutation,
} from "utils/hooks/mutations"
import { replaceAllById, replaceById, updateById } from "utils/mutationHelpers"

import { buildPlaybookKey } from "./playbookQueries"

export function buildArgumentKey(argumentId: string) {
  return ["argument", argumentId]
}

export function buildArgumentsKey(argumentTypeId: string) {
  return ["arguments", argumentTypeId]
}

function buildArgumentsUsingFieldKey(argumentFieldId: string) {
  return ["argumentsUsingField", argumentFieldId]
}

function buildArgumentWithLinkableConfigurationKey(playbookId: string) {
  return ["argumentsWithLinkableConfiguration", playbookId]
}

export const useArgumentQuery = (argumentId: string) =>
  useQuery(buildArgumentKey(argumentId), ({ signal }) =>
    apiGetArgument(argumentId, { signal })
  )

export const useArgumentsQuery = (argumentTypeId: string) =>
  useQuery(buildArgumentsKey(argumentTypeId), ({ signal }) =>
    apiGetArguments(argumentTypeId, { signal })
  )

const useArgumentMutation = <TError, TVariables>(
  argumentTypeId: string,
  mutationFn: MutateFunction<IArgumentResponse, TError, TVariables>
) => {
  const queryClient = useQueryClient()
  return useMutation(mutationFn, {
    onSuccess: (result) => {
      queryClient.setQueryData(
        buildArgumentsKey(argumentTypeId),
        (prevArguments: IArgument[] | undefined) =>
          prevArguments && replaceById(prevArguments, result.argument)
      )
      queryClient.setQueryData(
        buildArgumentKey(result.argument.id),
        result.argument
      )
    },
  })
}

export const useArgumentUpdateMutation = (argumentTypeId: string) =>
  useArgumentMutation(argumentTypeId, apiUpdateArgument)

export const useArgumentUpdateKindMutation = (argumentTypeId: string) =>
  useArgumentMutation(argumentTypeId, apiUpdateKindArgument)

export const useArgumentsArchiveMutation = (argumentTypeId: string) =>
  useUpdateMutation(
    buildArgumentsKey(argumentTypeId),
    apiArchiveArguments,
    (result, prevArguments: IArgument[] | undefined) => {
      if (!prevArguments) return undefined
      return replaceAllById(prevArguments, result.arguments)
    }
  )

export const useArgumentsRestoreMutation = (argumentTypeId: string) =>
  useUpdateMutation(
    buildArgumentsKey(argumentTypeId),
    apiRestoreArguments,
    (result, prevArguments: IArgument[] | undefined) => {
      if (!prevArguments) return undefined
      return replaceAllById(prevArguments, result.arguments)
    }
  )

export const useArgumentFieldValueUpdateMutation = (argumentTypeId: string) =>
  useArgumentMutation(argumentTypeId, apiUpdateArgumentFieldValue)

export const useArgumentBulkUpdateMutation = (argumentTypeId: string) =>
  useUpdateMutation(
    buildArgumentsKey(argumentTypeId),
    apiBulkUpdateArguments,
    (result, prevArguments: IArgument[] | undefined) =>
      prevArguments && replaceArguments(prevArguments, result.arguments)
  )

export const useArgumentCreateMutation = (argumentTypeId: string) =>
  useUpdateMutation(
    buildArgumentsKey(argumentTypeId),
    apiCreateArgument,
    (result, prevArguments: IArgument[] | undefined) =>
      prevArguments && [...prevArguments, result.argument]
  )

export const useArgumentsDuplicateMutation = (argumentTypeId: string) =>
  useUpdateMutation(
    buildArgumentsKey(argumentTypeId),
    apiDuplicateArgument,
    (result, prevArguments: IArgument[] | undefined, originalArgumentIds) =>
      prevArguments &&
      insertDuplicatedArguments(
        prevArguments,
        originalArgumentIds,
        result.arguments
      )
  )

export const useArgumentsMoveMutation = (
  playbookId: string,
  argumentTypeId: string
) => {
  const queryClient = useQueryClient()
  return useMutation(apiMoveArguments, {
    onSuccess: () => {
      queryClient.invalidateQueries(buildArgumentsKey(argumentTypeId))
      queryClient.invalidateQueries(buildPlaybookKey(playbookId))
    },
  })
}

export const useArgumentUpdatePositionMutation = (argumentTypeId: string) =>
  useOptimisticMutation(
    buildArgumentsKey(argumentTypeId),
    apiUpdateArgumentPosition,
    (
      prevArguments: IArgument[] | undefined,
      { id, relativeId, argumentGroupId }
    ) =>
      prevArguments &&
      moveArgument(prevArguments, id, relativeId, argumentGroupId)
  )

// We update both:
//   - The playbook to increase the votes count on the argument
//   - The meeting to update the votes
// TODO Do not use useUpdateMutation
export const useArgumentVoteMutation = (
  playbookId: string,
  argumentTypeId: string
) => {
  const queryClient = useQueryClient()
  return useUpdateMutation(
    buildArgumentsKey(argumentTypeId),
    apiVoteOnArgument,
    (_result, prevArguments: IArgument[] | undefined, { argumentId, delta }) =>
      prevArguments &&
      updateById(prevArguments, argumentId, (argument) => ({
        ...argument,
        votesCount: argument.votesCount + delta,
      })),
    {
      onSuccess: (vote) => {
        updateVoteOnCurrentMeeting(queryClient, playbookId, vote)
      },
    }
  )
}

export const useRefreshArgumentMutation = (argumentTypeId: string) =>
  useUpdateMutation(
    buildArgumentsKey(argumentTypeId),
    apiRefreshArgument,
    (
      { fieldsLastTouchedAt },
      prevArguments: IArgument[] | undefined,
      argumentId
    ) => {
      return (
        prevArguments &&
        updateById(prevArguments, argumentId, (argument) => ({
          ...argument,
          fieldsLastTouchedAt,
        }))
      )
    }
  )

export const usePasteArgumentsMutation = (argumentTypeId: string) =>
  useInvalidatingMutation(buildArgumentsKey(argumentTypeId), apiPasteArguments)

export const useArgumentsUsingFieldQuery = (argumentFieldId: string) =>
  useQuery(buildArgumentsUsingFieldKey(argumentFieldId), ({ signal }) =>
    apiGetArgumentsUsingField(argumentFieldId, { signal })
  )

export const useArgumentsWithLinkableConfigurationQuery = (
  playbookId: string
) =>
  useQuery(
    buildArgumentWithLinkableConfigurationKey(playbookId),
    ({ signal }) =>
      apiGetArgumentsWithLinkableConfiguration(playbookId, { signal })
  )
