import { Box } from "@mui/material"
import { Dictionary } from "lodash"

import { apiCreateLog } from "api/logs"
import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IPickedSegment } from "api/types/argumentSegments"

import OverlayScrollbarsComponent from "ds/OverlayScrollbarsComponent"
import Stack from "ds/Stack"

import ArgumentSegmentation from "./ArgumentSegmentation"
import EditArgumentSegmentations from "./PlaybookEdit/EditArgumentSegmentations"
import { usePlaybook } from "./PlaybookProvider"

interface Props {
  argumentSegmentations: IArgumentSegmentation[]
  segmentFilters: Dictionary<string[]>
  onPickArgumentSegment: (pickedSegments: IPickedSegment[]) => void
  nbArgumentsBySegment: Dictionary<number>
  forceShowNbOfArgumentsBySegment?: boolean
  editMode?: boolean
  unactiveSegmentationsWithArgumentsHavingAnySegmentRuleActive?: IArgumentSegmentation[]
  segmentationsWithNoEffect?: IArgumentSegmentation[]
}

export default function ArgumentSegmentations({
  argumentSegmentations,
  segmentFilters,
  onPickArgumentSegment,
  nbArgumentsBySegment,
  forceShowNbOfArgumentsBySegment = false,
  editMode = false,
  unactiveSegmentationsWithArgumentsHavingAnySegmentRuleActive = [],
  segmentationsWithNoEffect = [],
}: Props) {
  const { playbook } = usePlaybook()
  return (
    <Stack direction="row" alignItems="center" spacing={1} minWidth={0}>
      <Box minWidth={0}>
        <OverlayScrollbarsComponent
          options={{
            overflow: { x: "scroll", y: "hidden" },
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {argumentSegmentations.map((argumentSegmentation) => (
              <ArgumentSegmentation
                key={argumentSegmentation.id}
                argumentSegmentation={argumentSegmentation}
                value={segmentFilters[argumentSegmentation.id] || []}
                onChange={(argumentSegmentIds) => {
                  onPickArgumentSegment([
                    {
                      argumentSegmentationId: argumentSegmentation.id,
                      argumentSegmentIds,
                    },
                  ])
                  apiCreateLog({
                    action: "CHANGE_ARGUMENT_SEGMENTS",
                    countsAsActivity: true,
                    playbookId: playbook.id,
                  })
                }}
                nbArgumentsBySegment={nbArgumentsBySegment}
                forceShowNbOfArgumentsBySegment={
                  forceShowNbOfArgumentsBySegment
                }
                required={unactiveSegmentationsWithArgumentsHavingAnySegmentRuleActive.some(
                  (inactiveSegmentation) =>
                    argumentSegmentation.id === inactiveSegmentation.id
                )}
                hasNoEffect={segmentationsWithNoEffect.some(
                  (segmentationWithNoEffect) =>
                    segmentationWithNoEffect.id === argumentSegmentation.id
                )}
              />
            ))}
          </Stack>
        </OverlayScrollbarsComponent>
      </Box>

      {editMode && <EditArgumentSegmentations />}
    </Stack>
  )
}
