import AutorenewIcon from "@mui/icons-material/Autorenew"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { FormattedMessage } from "react-intl"

import { IIntegration } from "api/types/integrations"

import Tooltip from "ds/Tooltip"

interface Props {
  status: IIntegration["status"] | null
  workspaceId: IIntegration["workspaceId"] | undefined
}

export default function ApplicationsIntegrationStatusCue({
  status,
  workspaceId,
}: Props) {
  if (!status || status === "revoked")
    return (
      <FormattedMessage
        id="settings.applicationsIntegration.notConnected"
        defaultMessage="Not connected"
      />
    )
  switch (status) {
    case "in_progress":
      return <AutorenewIcon fontSize="large" />
    case "success":
      return (
        <Tooltip title={<div>{workspaceId}</div>}>
          <CheckCircleOutlineIcon fontSize="large" />
        </Tooltip>
      )
    case "failure":
      return <ErrorOutlineIcon fontSize="large" />
  }
}
