import { IMeetingNoteConfigurationFieldType } from "./meetingNoteConfigurations"

export const CRM_PROPERTY_CONTACT_OBJECT_TYPE = "Contact"
export const CRM_PROPERTY_COMPANY_OBJECT_TYPE = "Company"
export const CRM_PROPERTY_DEAL_OBJECT_TYPE = "Deal"
export const CRM_PROPERTY_CALL_OBJECT_TYPE = "Call"
const CRM_PROPERTY_OBJECT_TYPES = [
  CRM_PROPERTY_CONTACT_OBJECT_TYPE,
  CRM_PROPERTY_COMPANY_OBJECT_TYPE,
  CRM_PROPERTY_DEAL_OBJECT_TYPE,
  CRM_PROPERTY_CALL_OBJECT_TYPE,
] as const

export type ICrmPropertyObjectType = typeof CRM_PROPERTY_OBJECT_TYPES[number]

export const CRM_PROPERTY_MULTI_SELECT_DATA_TYPE = "multi_select"
export const CRM_PROPERTY_SINGLE_SELECT_DATA_TYPE = "single_select"
export const CRM_PROPERTY_BOOLEAN_DATA_TYPE = "boolean"
export const CRM_PROPERTY_FREE_TEXT_DATA_TYPE = "free_text"
export const CRM_PROPERTY_NUMBER_DATA_TYPE = "number"
export const CRM_PROPERTY_PHONE_DATA_TYPE = "phone"
export const CRM_PROPERTY_EMAIL_DATA_TYPE = "email"
export const CRM_PROPERTY_URL_DATA_TYPE = "url"
export const CRM_PROPERTY_DATE_DATA_TYPE = "date"
export const CRM_PROPERTY_UNSUPPORTED_DATA_TYPE = "unsupported"
const CRM_PROPERTY_DATA_TYPES = [
  CRM_PROPERTY_MULTI_SELECT_DATA_TYPE,
  CRM_PROPERTY_SINGLE_SELECT_DATA_TYPE,
  CRM_PROPERTY_BOOLEAN_DATA_TYPE,
  CRM_PROPERTY_FREE_TEXT_DATA_TYPE,
  CRM_PROPERTY_NUMBER_DATA_TYPE,
  CRM_PROPERTY_PHONE_DATA_TYPE,
  CRM_PROPERTY_EMAIL_DATA_TYPE,
  CRM_PROPERTY_URL_DATA_TYPE,
  CRM_PROPERTY_DATE_DATA_TYPE,
  CRM_PROPERTY_UNSUPPORTED_DATA_TYPE,
] as const

export type ICRMPropertyDataType = typeof CRM_PROPERTY_DATA_TYPES[number]

export interface ICRMProperty {
  id: string
  externalId: string
  name: string
  integrationName: string
  objectType: ICrmPropertyObjectType
  dataType: ICRMPropertyDataType
  readOnly: boolean
}

export interface ICRMPropertiesRefreshParams {
  businessUnitId: string
}

export interface ICRMExternalProperty {
  id: string
  value: string | string[]
  fieldType: IMeetingNoteConfigurationFieldType
}
