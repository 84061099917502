import dataEN from "@emoji-mart/data"
import Picker from "@emoji-mart/react"
import { Box } from "@mui/material"
import { useIntl } from "react-intl"

import { AvailableLocales } from "translations/TranslationsProvider"
import enEmojis from "translations/resources/emojis.en.json"
import frEmojis from "translations/resources/emojis.fr.json"

import dataFR from "./EmojiPicker/data-fr.json"

interface EmojiPickerProps {
  onEmojiSelect: (emoji: IEmoji) => void
}

type EmojiKey = keyof typeof dataEN["emojis"]

function isEmojiKey(key: string): key is EmojiKey {
  return key in dataEN["emojis"]
}

export function getEmojiById(id: string, skinIndex: number = 0) {
  const entry = isEmojiKey(id) ? dataEN["emojis"][id] : undefined
  if (!entry) return undefined
  skinIndex = Math.min(entry.skins.length - 1, skinIndex)
  return entry.skins[skinIndex].native
}

const emojiButtonColors = [
  "rgba(155,223,88,.7)",
  "rgba(149,211,254,.7)",
  "rgba(247,233,34,.7)",
  "rgba(238,166,252,.7)",
  "rgba(255,213,143,.7)",
  "rgba(211,209,255,.7)",
]

const emojisDataMapping: {
  // TODO use type provided by emoji-mart when the library is type safe, see https://github.com/missive/emoji-mart/pull/671
  [key in AvailableLocales]: any
} = {
  fr: dataFR,
  en: dataEN,
}

export interface IEmoji {
  id: string
  native: string
}

export const emojisTextsMapping: {
  // TODO use type provided by emoji-mart when the library is type safe, see https://github.com/missive/emoji-mart/pull/671
  [key in AvailableLocales]: typeof enEmojis
} = {
  fr: frEmojis,
  en: enEmojis,
}

function useEmojiTranslations() {
  const intl = useIntl()
  return emojisTextsMapping[intl.locale]
}

function useEmojiData() {
  const intl = useIntl()
  return emojisDataMapping[intl.locale]
}

export default function CustomEmojiPicker({ onEmojiSelect }: EmojiPickerProps) {
  const emojiTranslations = useEmojiTranslations()
  const data = useEmojiData()

  return (
    <Box sx={{ width: "min(350px,90vw)" }}>
      <Picker
        dynamicWidth
        emojiButtonColors={emojiButtonColors}
        defaultSkin={0}
        autoFocus
        i18n={emojiTranslations}
        data={data}
        onEmojiSelect={onEmojiSelect}
      />
    </Box>
  )
}
