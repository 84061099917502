import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentProps,
  OverlayScrollbarsComponentRef,
} from "overlayscrollbars-react"
import { ForwardedRef, forwardRef } from "react"

export default forwardRef(function CustomOverlayScrollbarsComponent(
  props: OverlayScrollbarsComponentProps<"div">,
  ref: ForwardedRef<OverlayScrollbarsComponentRef<"div">>
) {
  return (
    <OverlayScrollbarsComponent
      element="div"
      defer
      ref={ref}
      {...props}
      options={{
        ...defaultOverlayScrollbarsOptions,
        ...props.options,
      }}
    />
  )
})

export const defaultOverlayScrollbarsOptions: OverlayScrollbarsComponentProps["options"] =
  {
    scrollbars: { autoHide: "move" },
    update: {
      elementEvents: [
        ["img", "load"],
        ["video", "loadeddata"],
      ],
    },
  }
