import MuiFab, { FabProps } from "@mui/material/Fab"
import { forwardRef } from "react"

export type { FabProps }

export default forwardRef(function Fab(
  props: FabProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return <MuiFab {...props} sx={{ boxShadow: 12, ...props.sx }} ref={ref} />
})
