import { useState } from "react"
import { useDebouncedCallback } from "use-debounce"

import { IVote } from "api/types/votes"

import { useCurrentMeetingVoteNoteMutation } from "components/App/CRM/Meetings/meetingQueries"
import AdditionalInfo from "components/common/AdditionalInfo"

const debounceWaitMs = 250

interface Props {
  vote: IVote
  playbookId: string
}

export default function VoteNote({ vote, playbookId }: Props) {
  const [value, setValue] = useState(vote.note || "")
  const [isSaved, setIsSaved] = useState(true)
  const voteNoteMutation = useCurrentMeetingVoteNoteMutation(playbookId)

  function saveVoteNote() {
    voteNoteMutation.mutate(
      { id: vote.id, value },
      { onSuccess: () => setIsSaved(true) }
    )
  }

  const debouncedSaveVoteNote = useDebouncedCallback(
    saveVoteNote,
    debounceWaitMs
  )

  const onChange = (newValue: string) => {
    setValue(newValue)
    setIsSaved(false)
    debouncedSaveVoteNote()
  }

  return <AdditionalInfo value={value} onChange={onChange} isSaved={isSaved} />
}
