import axios from "./axios"
import { IUpcomingCall } from "./types/CRMCalls"
import { IMeeting } from "./types/meetings"

export const apiGetUpcomingCalls = (
  businessUnitId: string,
  crmContactIds: string[],
  crmCompanyId?: string
): Promise<IUpcomingCall[]> =>
  axios
    .get("api/crm/calls/upcoming", {
      params: { businessUnitId, crmCompanyId, crmContactIds },
    })
    .then((res) => res.data)

export const apiLinkMeetingToExternal = ({
  meetingId,
  externalCallId,
}: {
  meetingId: string
  externalCallId?: string
}): Promise<IMeeting> =>
  axios
    .patch(`api/meetings/${meetingId}/link_to_external_call`, {
      externalCallId,
    })
    .then((res) => res.data)
