import MuiInputLabel, { InputLabelProps } from "@mui/material/InputLabel"
import { forwardRef } from "react"

export type { InputLabelProps }

export default forwardRef(function InputLabel(
  props: InputLabelProps,
  ref: React.Ref<HTMLLabelElement>
) {
  return <MuiInputLabel size="small" {...props} ref={ref} />
})
