import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  OnDragEndResponder,
} from "@hello-pangea/dnd"
import { Box } from "@mui/material"
import { Dictionary } from "lodash"
import get from "lodash/get"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { IArgumentGroup } from "api/types/argumentGroups"
import { IArgument } from "api/types/arguments"
import { IPlaybook } from "api/types/playbooks"

import { useIsSmallerThanSM } from "ds/Media"
import OverlayScrollbarsComponent from "ds/OverlayScrollbarsComponent"
import Stack from "ds/Stack"

import { groupArguments } from "services/arguments"

import ArgumentGroup, { parseGroupDestinationId } from "./ArgumentGroup"
import NoArgumentsInSegments from "./NoArgumentsInSegments"
import NoArgumentsInTab from "./NoArgumentsInTab"
import AddArgumentGroup from "./PlaybookEdit/ArgumentGroups/AddArgumentGroup"
import { usePlaybook } from "./PlaybookProvider"
import {
  ARGUMENTS_SCROLL_CONTAINER_ID,
  usePlaybookScrollRestore,
} from "./playbookScrollRestore"
import { useArgumentGroupUpdatePositionMutation } from "./queries/argumentGroupQueries"
import { useArgumentUpdatePositionMutation } from "./queries/argumentQueries"

const ArgumentGroupsContainer = styled.div`
  flex-grow: 1;
  min-height: 0; // To not fit the children by default

  // To position the "Add group" CTAs
  position: relative;

  .os-scrollbar-vertical {
    right: 2px;
  }
`

const FloatingActionsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`

interface ArgumentGroupsProps extends ArgumentGroupsWithDndProps {
  providedDroppable?: DroppableProvided
}

// arguments are already filtered at this point
function ArgumentGroups({
  arguments: _arguments,
  groupsToShow,
  argumentsByGroupId,
  onAddGroup,
  editMode,
  providedDroppable,
  lastCreatedArgumentGroupId,
  lastCreatedArgumentGroupRef,
}: ArgumentGroupsProps) {
  const droppableRef = providedDroppable?.innerRef
  const droppableProps = providedDroppable?.droppableProps || {}
  const isSmallerThanSM = useIsSmallerThanSM()

  const scrollContainerRef = usePlaybookScrollRestore()

  const grouplessArguments = argumentsByGroupId["none"] || []
  const hasGrouplessArguments = grouplessArguments.length > 0
  const nbArgumentsInTab = _arguments.length

  return (
    <ArgumentGroupsContainer>
      <OverlayScrollbarsComponent
        id={ARGUMENTS_SCROLL_CONTAINER_ID}
        ref={(ref) => {
          if (!ref) return
          scrollContainerRef.current = ref.getElement()
        }}
        options={{
          overflow: { y: "scroll", x: "hidden" },
        }}
        style={{ height: "100%" }}
      >
        {nbArgumentsInTab === 0 && (
          <Box sx={{ m: 2 }}>
            <NoArgumentsInTab />
          </Box>
        )}
        {nbArgumentsInTab !== 0 && _arguments.length === 0 && (
          <Box sx={{ m: 2 }}>
            <NoArgumentsInSegments />
          </Box>
        )}

        {/* Adding margin bottom to avoid conflict with groups CTAs */}
        <Stack
          spacing={isSmallerThanSM ? 2 : 3}
          mb={12} // Leave some space for CTAs
          px={2}
          ref={droppableRef}
          {...droppableProps}
        >
          {groupsToShow.map((argumentGroup, index) => (
            <ArgumentGroup
              key={argumentGroup.id}
              index={index}
              argumentGroup={argumentGroup}
              arguments={argumentsByGroupId[argumentGroup.id] || []}
              nameContainerRef={
                lastCreatedArgumentGroupId === argumentGroup.id
                  ? lastCreatedArgumentGroupRef
                  : null
              }
            />
          ))}

          {providedDroppable?.placeholder}

          {/* Always show the groupless group last */}
          {(editMode || hasGrouplessArguments) && (
            <ArgumentGroup
              key="none"
              argumentGroup={null}
              arguments={grouplessArguments}
            />
          )}
        </Stack>
      </OverlayScrollbarsComponent>

      {editMode && (
        <FloatingActionsContainer>
          <AddArgumentGroup onAddGroup={onAddGroup} />
        </FloatingActionsContainer>
      )}
    </ArgumentGroupsContainer>
  )
}

interface ArgumentGroupsWithDndProps extends ConnectedArgumentGroupsProps {
  editMode: boolean
  playbook: IPlaybook
  activeTypeId: string
  groupsToShow: IArgumentGroup[]
  argumentsByGroupId: Dictionary<IArgument[]>
  onAddGroup: (argumentGroup: IArgumentGroup) => void
  lastCreatedArgumentGroupId: string | null
  lastCreatedArgumentGroupRef: React.RefObject<HTMLDivElement>
}

const ArgumentGroupsWithDnd = (props: ArgumentGroupsWithDndProps) => {
  const { editMode, playbook, groupsToShow, argumentsByGroupId, activeTypeId } =
    props
  const argumentUpdatePositionMutation =
    useArgumentUpdatePositionMutation(activeTypeId)
  const argumentGroupUpdatePositionMutation =
    useArgumentGroupUpdatePositionMutation(playbook.id)

  if (!editMode) {
    return <ArgumentGroups {...props} />
  }

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return
    }
    if (result.type === "droppableForArguments") {
      const argumentId = result.draggableId
      const destinationId = result.destination.droppableId
      const parsedDestinationId = parseGroupDestinationId(destinationId)
      let newArgumentGroupId =
        parsedDestinationId === "none" ? null : parsedDestinationId
      const newPosition = result.destination.index
      const relativeArgument = get(argumentsByGroupId, [
        newArgumentGroupId || "none",
        newPosition,
      ])
      argumentUpdatePositionMutation.mutate({
        id: argumentId,
        relativeId: relativeArgument?.id,
        argumentGroupId: newArgumentGroupId,
      })
    } else if (result.type === "droppableForArgumentGroups") {
      const argumentGroupId = result.draggableId
      const oldPosition = result.source.index
      const newPosition = result.destination.index
      if (oldPosition === newPosition) {
        return
      }
      const relativeArgumentGroup = groupsToShow[newPosition]
      argumentGroupUpdatePositionMutation.mutate({
        id: argumentGroupId,
        relativeId: relativeArgumentGroup.id,
      })
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={`argumentGroups-${playbook.id}`}
        type="droppableForArgumentGroups"
      >
        {(providedDroppable) => (
          <ArgumentGroups providedDroppable={providedDroppable} {...props} />
        )}
      </Droppable>
    </DragDropContext>
  )
}

interface ConnectedArgumentGroupsProps {
  arguments: IArgument[]
}

export default function ConnectedArgumentGroups({
  arguments: _arguments,
}: ConnectedArgumentGroupsProps) {
  const { playbook, editMode, activeTypeId, viewSettings } = usePlaybook()
  const [forcedGroupIds, setForcedGroupIds] = useState<string[]>([])

  // Scroll to the argument group that was just created
  const [lastCreatedArgumentGroupId, setLastCreatedArgumentGroupId] = useState<
    string | null
  >(null)
  const lastCreatedArgumentGroupRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Not sure why setTimeout is necessary, the ref is present
    setTimeout(() => {
      lastCreatedArgumentGroupRef.current?.scrollIntoView({
        behavior: "smooth",
      })
    }, 50)
  }, [lastCreatedArgumentGroupId])

  const onAddGroup = (argumentGroup: IArgumentGroup) => {
    setForcedGroupIds((prev) => [...prev, argumentGroup.id])
    setLastCreatedArgumentGroupId(argumentGroup.id)
    viewSettings.expandGroups(activeTypeId, [argumentGroup.id])
  }

  const argumentsByGroupId = groupArguments(_arguments)

  const groupsToShow = playbook.argumentGroups.filter(
    (argumentGroup) =>
      argumentsByGroupId[argumentGroup.id] ||
      (forcedGroupIds.includes(argumentGroup.id) &&
        argumentGroup.argumentTypeId === activeTypeId)
  )

  return (
    <ArgumentGroupsWithDnd
      arguments={_arguments}
      activeTypeId={activeTypeId}
      playbook={playbook}
      groupsToShow={groupsToShow}
      argumentsByGroupId={argumentsByGroupId}
      onAddGroup={onAddGroup}
      editMode={editMode}
      lastCreatedArgumentGroupId={lastCreatedArgumentGroupId}
      lastCreatedArgumentGroupRef={lastCreatedArgumentGroupRef}
    />
  )
}
