import {
  ELEMENT_TODO_LI,
  TodoListPlugin,
  createTodoListPlugin,
} from "@udecode/plate"

import TodoList from "../components/TodoList"
import { MyEditor, MyValue } from "../types"

export const todoListPlugin = createTodoListPlugin<
  TodoListPlugin,
  MyValue,
  MyEditor
>()

export const todoListComponents = {
  [ELEMENT_TODO_LI]: TodoList,
}
