import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { IArgumentGroup } from "api/types/argumentGroups"

import Stack from "ds/Stack"

import ArgumentModal from "components/App/Playbook/Argument/ArgumentModal"
import { useArgumentIdParam } from "components/App/Playbook/Argument/routing"
import ArgumentTypes from "components/App/Playbook/ArgumentTypes"
import ViewSettingsControls from "components/App/Playbook/ViewSettings/ViewSettingsControls"

import AddArgumentGroup from "./ArgumentGroups/AddArgumentGroup"
import PlaybookMatrixTable from "./PlaybookMatrix/PlaybookMatrixTable"

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: ${({ theme }) => theme.spacing(1)};
  position: relative; // To position the floating actions
`

const Content = styled.div`
  flex-grow: 1;
  min-height: 0;
  overflow: scroll;
`

const TableContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing(3)};
  margin-right: ${({ theme }) => theme.spacing(3)};
  width: fit-content;
`

const FloatingActionsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; // Make sure this appears in front of the table
`

export default function PlaybookMatrix() {
  const [argumentIdParam, setArgumentIdParam] = useArgumentIdParam()

  // Scroll to the argument group that was just created
  const [lastCreatedArgumentGroupId, setLastCreatedArgumentGroupId] = useState<
    string | null
  >(null)
  const lastCreatedArgumentGroupRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    lastCreatedArgumentGroupRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [lastCreatedArgumentGroupId])

  function onAddGroup(argumentGroup: IArgumentGroup) {
    setLastCreatedArgumentGroupId(argumentGroup.id)
  }

  return (
    <>
      <Container>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          sx={{ pl: 3, pr: 3 }}
        >
          <ArgumentTypes />
          <ViewSettingsControls />
        </Stack>

        <FloatingActionsContainer>
          <AddArgumentGroup forceHideSearch onAddGroup={onAddGroup} />
        </FloatingActionsContainer>

        <Content>
          <TableContainer>
            <PlaybookMatrixTable
              lastCreatedArgumentGroupId={lastCreatedArgumentGroupId}
              lastCreatedArgumentGroupRef={lastCreatedArgumentGroupRef}
            />
          </TableContainer>
        </Content>
      </Container>

      {argumentIdParam && (
        <ArgumentModal
          argumentId={argumentIdParam.toString()}
          onClose={() => setArgumentIdParam(undefined)}
        />
      )}
    </>
  )
}
