import LightbulbIcon from "@mui/icons-material/Lightbulb"
import { useIntl } from "react-intl"

import Avatar from "ds/Avatar"

export default function TipIndicator() {
  const intl = useIntl()

  return (
    <Avatar sx={{ bgcolor: (theme) => theme.palette.success.light }}>
      <LightbulbIcon
        fontSize="small"
        sx={{ color: (theme) => theme.palette.success[600] }}
        aria-label={intl.formatMessage({
          id: "playbookEdit.argument.kind.TIP",
        })}
      />
    </Avatar>
  )
}
