import MicIcon from "@mui/icons-material/Mic"
import { useRef, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { apiCreateLog } from "api/logs"

import Button from "ds/Button"
import { AlertSnackbar } from "ds/Snackbar"
import Tooltip from "ds/Tooltip"
import Typography from "ds/Typography"

import useLocalStorageState from "utils/hooks/useLocalStorageState"

import PillIconButton from "../PillIconButton"
import SpeechToTextPopover from "./SpeechToTextPopover"
import { BCP47Lang, isBCP47Lang } from "./bcp47"
import {
  LANGUAGE_LS_KEY,
  SpeechRecognition,
  useSpeechRecognition,
  useSpeechRecognitionState,
} from "./speechToTextService"

interface Props {
  highlight?: boolean
  disabled?: boolean
}

export default function SpeechToTextButton({
  highlight = false,
  disabled = false,
}: Props) {
  const intl = useIntl()
  const [permissionNecessarySnackOpen, setPermissionNecessarySnackOpen] =
    useState(false)

  const [popoverOpen, setPopoverOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const [_lang, setLang] = useLocalStorageState<null | string>(
    LANGUAGE_LS_KEY,
    null
  )
  const lang = _lang && isBCP47Lang(_lang) ? _lang : null
  const speechRecognitionRef = useSpeechRecognition(lang)
  const { isListening, results, startListening, stopListening, insertResults } =
    useSpeechRecognitionState({
      speechRecognitionRef,
      onStartListening: () =>
        apiCreateLog({
          action: "START_SPEECH_TO_TEXT",
          countsAsActivity: true,
        }),
      onError: () => setPermissionNecessarySnackOpen(true),
    })

  function onClick() {
    setPopoverOpen(true)
    startListening()
  }

  function onClose() {
    setPopoverOpen(false)
    stopListening()
  }

  function onInsert() {
    insertResults()
    onClose()
  }

  const label = intl.formatMessage({
    id: "richtext.speechToText",
  })

  return (
    <>
      <div ref={anchorRef}>
        {highlight ? (
          <Tooltip title={label}>
            <Button
              color={isListening ? "error" : "primary"}
              variant="contained"
              sx={{ py: 0.5, px: 1 }}
              onClick={onClick}
              disabled={disabled || !SpeechRecognition}
            >
              <MicIcon sx={{ height: "16px", width: "16px" }} />
            </Button>
          </Tooltip>
        ) : (
          <PillIconButton
            color={isListening ? "error" : "default"}
            label={label}
            onMouseDown={onClick}
            disabled={disabled || !SpeechRecognition}
          >
            <MicIcon sx={{ height: "16px", width: "16px" }} />
          </PillIconButton>
        )}
      </div>

      <SpeechToTextPopover
        anchorEl={anchorRef.current}
        isOpen={popoverOpen}
        onClose={onClose}
        isListening={isListening}
        lang={speechRecognitionRef.current?.lang as BCP47Lang}
        setLang={(selectedLang: BCP47Lang) => {
          setLang(selectedLang)
          if (!speechRecognitionRef.current) return
          speechRecognitionRef.current.lang = selectedLang
        }}
        startListening={startListening}
        stopListening={stopListening}
        results={results}
        onInsert={onInsert}
      />

      <AlertSnackbar
        severity="error"
        open={permissionNecessarySnackOpen}
        onClose={() => setPermissionNecessarySnackOpen(false)}
      >
        <Typography variant="baseSemi">
          <FormattedMessage id="richtext.speechToText.permissionNecessary" />
        </Typography>
      </AlertSnackbar>
    </>
  )
}
