import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined"
import { useIntl } from "react-intl"

import Badge from "ds/Badge"
import IconButton from "ds/IconButton"

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  commentsCount: number
  commentsNotVisitedNotificationsCount: number
}

export default function CommentsToggle({
  onClick,
  commentsCount,
  commentsNotVisitedNotificationsCount,
}: Props) {
  const intl = useIntl()

  const label = intl.formatMessage({
    id: "arguments.comments.title",
    defaultMessage: "Comments",
  })

  const badgeColor =
    commentsNotVisitedNotificationsCount > 0 ? "error" : "primary"
  const badgeVariant =
    commentsNotVisitedNotificationsCount > 0 ? "standard" : "dot"
  const badgeContent =
    commentsNotVisitedNotificationsCount > 0
      ? commentsNotVisitedNotificationsCount
      : commentsCount

  return (
    <IconButton onClick={onClick} aria-label={label}>
      <Badge
        color={badgeColor}
        badgeContent={badgeContent}
        variant={badgeVariant}
      >
        <ForumOutlinedIcon fontSize="small" />
      </Badge>
    </IconButton>
  )
}
