import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import Link from "ds/Link"
import Loader from "ds/Loader"
import UncontrolledError, { ForbiddenError } from "ds/UncontrolledError"

import { getPlaybookPath } from "services/playbooks"

import isMobileDevice from "utils/isMobileDevice"

import { useBusinessUnit } from "./BusinessUnit/BusinessUnitProvider"
import MeetingProvider from "./Playbook/Meeting/MeetingProvider"
import PlaybookEdit from "./Playbook/PlaybookEdit/PlaybookEdit"
import UnsupportedOnMobile from "./Playbook/PlaybookEdit/UnsupportedOnMobile"
import PlaybookProvider from "./Playbook/PlaybookProvider"
import {
  usePlaybookQuery,
  usePlaybookUsersQuery,
} from "./Playbook/queries/playbookQueries"

export default function PlaybookEditPage() {
  const params = useParams()
  const businessUnit = useBusinessUnit()
  const playbookId = params.playbookId!
  const playbookQuery = usePlaybookQuery(playbookId)
  const playbookUsersQuery = usePlaybookUsersQuery(playbookId)

  if (playbookQuery.isLoading || playbookUsersQuery.isLoading) return <Loader />
  if (playbookQuery.isError || playbookUsersQuery.isError)
    return (
      <UncontrolledError
        error={playbookQuery.error || playbookUsersQuery.error}
      />
    )

  if (!playbookQuery.data.canEdit) {
    return (
      <ForbiddenError
        error="Cannot edit this playbook"
        errorCTA={
          <Link to={getPlaybookPath(businessUnit.id, playbookId)}>
            <FormattedMessage id="navigation.playbook" />
          </Link>
        }
      />
    )
  }

  return (
    <PlaybookProvider
      playbook={playbookQuery.data.playbook}
      canEdit={playbookQuery.data.canEdit}
      users={playbookUsersQuery.data}
      editMode
    >
      <MeetingProvider
        ongoingMeeting={null}
        playbook={playbookQuery.data.playbook}
      >
        {isMobileDevice() && <UnsupportedOnMobile />}
        <PlaybookEdit />
      </MeetingProvider>
    </PlaybookProvider>
  )
}
