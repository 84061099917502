import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Collapse } from "@mui/material"

import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UnstyledButton from "ds/UnstyledButton"

import BusinessUnitNav from "components/App/Organization/OrganizationSidebar/BusinessUnitNav"

import { useOrganization } from "../OrganizationProvider"

interface Props {
  activeTab: string | undefined
  expandedBusinessUnitId: string | null
  setExpandedBusinessUnitId: (businessUnitId: string | null) => void
}

export default function BusinessUnitsNav({
  activeTab,
  expandedBusinessUnitId,
  setExpandedBusinessUnitId,
}: Props) {
  const organization = useOrganization()

  function toggleBusinessUnit(businessUnitId: string) {
    businessUnitId === expandedBusinessUnitId
      ? setExpandedBusinessUnitId(null)
      : setExpandedBusinessUnitId(businessUnitId)
  }

  return (
    <>
      {organization.businessUnits
        .filter((businessUnit) => !businessUnit.archived)
        .map((businessUnit) => (
          <Stack key={businessUnit.id}>
            <UnstyledButton onClick={() => toggleBusinessUnit(businessUnit.id)}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1.5}
                py={1}
              >
                <Typography
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  variant="baseMed"
                  title={businessUnit.name || ""}
                >
                  {businessUnit.name}
                </Typography>

                {expandedBusinessUnitId === businessUnit.id ? (
                  <ExpandLessIcon fontSize="small" />
                ) : (
                  <ExpandMoreIcon fontSize="small" />
                )}
              </Stack>
            </UnstyledButton>

            <Collapse
              in={expandedBusinessUnitId === businessUnit.id}
              unmountOnExit
            >
              <BusinessUnitNav
                businessUnitId={businessUnit.id}
                activeTab={activeTab}
                // We count archived business units
                showUsersTab={organization.businessUnits.length > 1}
              />
            </Collapse>
          </Stack>
        ))}
    </>
  )
}
