import { FormattedMessage } from "react-intl"

import MenuItem from "ds/MenuItem"

interface Props {
  onClick: React.MouseEventHandler<HTMLLIElement>
}

export default function EditArgumentTypes({ onClick }: Props) {
  return (
    <MenuItem onClick={onClick}>
      <FormattedMessage
        id="playbookEdit.argumentTypes.edit"
        defaultMessage="Edit tabs"
      />
    </MenuItem>
  )
}
