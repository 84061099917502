const LS_KEY = "playbook:edit:clipboard"

enum LSClipboardItemTypes {
  Argument = "Argument",
  ArgumentGroup = "ArgumentGroup",
  ArgumentType = "ArgumentType",
}

type LSClipboardItem = {
  id: string
  type: LSClipboardItemTypes
}

function isValidLSValue(value: unknown): value is LSClipboardItem[] {
  return Array.isArray(value) && value.every(isValidLSItem)
}

function isValidLSItem(item: unknown): item is LSClipboardItem {
  if (typeof item !== "object") return false
  if (item === null) return false
  return (
    typeof (item as LSClipboardItem).id === "string" &&
    Object.values(LSClipboardItemTypes).includes((item as LSClipboardItem).type)
  )
}

export function isArgumentLSItem(item: LSClipboardItem): boolean {
  return item.type === LSClipboardItemTypes.Argument
}

export function isArgumentGroupLSItem(item: LSClipboardItem): boolean {
  return item.type === LSClipboardItemTypes.ArgumentGroup
}

export function isArgumentTypeLSItem(item: LSClipboardItem): boolean {
  return item.type === LSClipboardItemTypes.ArgumentType
}

export function getLS(): null | LSClipboardItem[] {
  const rawValue = localStorage.getItem(LS_KEY)
  if (rawValue === null) return null
  try {
    const parsedValue = JSON.parse(rawValue)
    if (isValidLSValue(parsedValue)) return parsedValue
    return null
  } catch {
    return null
  }
}

function setLS(items: LSClipboardItem[]) {
  localStorage.setItem(LS_KEY, JSON.stringify(items))
}

export function copyArguments(argumentIds: string[]) {
  setLS(
    argumentIds.map((id) => ({
      type: LSClipboardItemTypes.Argument,
      id,
    }))
  )
}

export function copyArgumentGroup(argumentGroupId: string) {
  setLS([{ type: LSClipboardItemTypes.ArgumentGroup, id: argumentGroupId }])
}

export function copyArgumentTypes(argumentTypeIds: string[]) {
  setLS(
    argumentTypeIds.map((id) => ({
      type: LSClipboardItemTypes.ArgumentType,
      id,
    }))
  )
}
