import { PlateEditor, Value, WithPlatePlugin } from "@udecode/plate-common"

import { createUploadStore } from "../upload/createUploadStore"
import { finishUploads } from "./finishUploads"
import { getSaveValue } from "./getSaveValue"
import { CloudEditorProps, CloudPlugin, FinishUploadsOptions } from "./types"
import { uploadFiles } from "./uploadFiles"

export const withCloud = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>,
  EE extends E & CloudEditorProps<V> = E & CloudEditorProps<V>
>(
  e: E,
  plugin: WithPlatePlugin<CloudPlugin, V, E>
) => {
  const editor = e as unknown as EE

  const { uploadFile } = plugin.options
  if (!uploadFile) {
    throw new Error("You need to provide an uploadFile option")
  }

  const uploadStore = createUploadStore({
    uploads: {},
  })

  editor.cloud = {
    uploadFile: uploadFile,
    uploadFiles: (files: Iterable<File>) => {
      uploadFiles(editor, files)
    },
    uploadStore,
    getSaveValue: () => {
      return getSaveValue<V>(editor.children, uploadStore.get.uploads())
    },
    finishUploads: async (options?: FinishUploadsOptions) => {
      return finishUploads(editor, options)
    },
  }

  return editor
}
