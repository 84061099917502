import {
  QueryClient,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import identity from "lodash/identity"
import { useCallback, useMemo } from "react"

import { apiSaveMeetingArgumentNote } from "api/meetingArgumentNotes"
import {
  apiCreateMeeting,
  apiDiscardMeeting,
  apiEndCurrentMeeting,
  apiGetCurrentMeeting,
  apiGetMeeting,
  apiGetMeetings,
  apiLinkToExternalMeeting,
  apiRestartMeeting,
  apiUpdateMeetingCRMDeal,
  apiUpdateMeetingCRMInformation,
  apiUpdateMeetingCompany,
  apiUpdateMeetingContacts,
  apiUpdateMeetingGeneralNote,
} from "api/meetings"
import { IMeeting, IMeetingsFiltersParams } from "api/types/meetings"
import { IVote } from "api/types/votes"
import { apiUpdateVoteNote } from "api/votes"

import {
  insertOrUpdateArgumentNote,
  insertOrUpdateVote,
} from "services/meetings"

import {
  PLAYBOOK_MEETING_CHANNEL,
  PlaybookMeetingChannelReceivedData,
  PlaybookMeetingChannelSubscriptionParams,
} from "sockets/types/playbookMeeting"
import useSubscription from "sockets/useSubscription"

import {
  useInvalidatingMutation,
  useUpdateMutation,
} from "utils/hooks/mutations"

function buildMeetingKey(meetingId: string) {
  return ["meeting", meetingId]
}
function buildMeetingsKey(
  businessUnitId: string,
  filters: IMeetingsFiltersParams
) {
  return ["meetings", businessUnitId, filters]
}
export function buildCurrentMeetingKey(playbookId: string) {
  return ["currentMeeting", playbookId]
}

const setIndividualMeetingsCache = (
  queryClient: QueryClient,
  meetings: IMeeting[]
) => {
  meetings.forEach((meeting) => {
    queryClient.setQueryData(buildMeetingKey(meeting.id), meeting)
  })
}

export const useMeetingsInfiniteQuery = (
  businessUnitId: string,
  filters: IMeetingsFiltersParams
) => {
  const queryClient = useQueryClient()
  return useInfiniteQuery(
    buildMeetingsKey(businessUnitId, filters),
    ({ pageParam }) => apiGetMeetings(businessUnitId, filters, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.paginationCursor,
      onSuccess: (result) => {
        const meetings = result.pages.map((page) => page.meetings).flat()
        setIndividualMeetingsCache(queryClient, meetings)
      },
    }
  )
}

export const updateVoteOnCurrentMeeting = (
  queryClient: QueryClient,
  playbookId: string,
  vote: IVote
) =>
  queryClient.setQueryData(
    buildCurrentMeetingKey(playbookId),
    (prev: IMeeting | undefined) => prev && insertOrUpdateVote(prev, vote)
  )

export const useMeetingQuery = (meetingId: string) =>
  useQuery(buildMeetingKey(meetingId), ({ signal }) =>
    apiGetMeeting(meetingId, { signal })
  )

export const useCurrentMeetingQuery = (playbookId: string) =>
  useQuery(buildCurrentMeetingKey(playbookId), ({ signal }) =>
    apiGetCurrentMeeting(playbookId, { signal })
  )

export const useCurrentMeetingUpdateNoteMutation = (playbookId: string) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiUpdateMeetingGeneralNote,
    identity
  )

export const useCurrentMeetingUpdateCRMInformationMutation = (
  playbookId: string
) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiUpdateMeetingCRMInformation,
    identity
  )

export const useCurrentMeetingUpdateCRMDealMutation = (playbookId: string) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiUpdateMeetingCRMDeal,
    identity
  )

export const useUpdateMeetingContactsMutation = (playbookId: string) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiUpdateMeetingContacts,
    identity
  )

export const useUpdateMeetingCompanyMutation = (playbookId: string) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiUpdateMeetingCompany,
    identity
  )

export const useCurrentMeetingStartMutation = (playbookId: string) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiCreateMeeting,
    identity
  )

export const useMeetingRestartMutation = (playbookId: string) =>
  useInvalidatingMutation(buildCurrentMeetingKey(playbookId), apiRestartMeeting)

export const useCurrentMeetingEndMutation = (playbookId: string) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiEndCurrentMeeting,
    identity
  )

export const useCurrentMeetingDiscardMutation = (playbookId: string) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiDiscardMeeting,
    () => null
  )

export const useCurrentMeetingVoteNoteMutation = (playbookId: string) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiUpdateVoteNote,
    (result, prev: IMeeting | undefined) =>
      prev && insertOrUpdateVote(prev, result)
  )

export const useCurrentMeetingArgumentNoteMutation = (playbookId: string) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiSaveMeetingArgumentNote,
    (result, prev: IMeeting | undefined) =>
      prev && insertOrUpdateArgumentNote(prev, result)
  )

export const useCurrentMeetingLinkToExternalMutation = (playbookId: string) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiLinkToExternalMeeting,
    identity
  )

function getPlaybookMeetingSubscriptionParams(
  playbookId: string
): PlaybookMeetingChannelSubscriptionParams {
  return {
    id: playbookId,
  }
}

export function useSubscriptionToPlaybookMeeting(playbookId: string) {
  const queryClient = useQueryClient()
  function handleMeetingSubscription(
    _message: PlaybookMeetingChannelReceivedData
  ) {
    // Same treatment, regardless of the update
    invalidateMeetingQuery(queryClient, playbookId)
  }
  const memoizedHandleMeetingSubscription = useCallback(
    handleMeetingSubscription,
    [queryClient, playbookId]
  )
  const params = useMemo(
    () => getPlaybookMeetingSubscriptionParams(playbookId),
    [playbookId]
  )
  return useSubscription(
    PLAYBOOK_MEETING_CHANNEL,
    memoizedHandleMeetingSubscription,
    params
  )
}

export const invalidateMeetingQuery = (
  queryClient: QueryClient,
  playbookId: string
) => {
  queryClient.invalidateQueries(buildCurrentMeetingKey(playbookId))
}
