import AllInboxIcon from "@mui/icons-material/AllInbox"
import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import FormControlLabel from "ds/FormControlLabel"
import Stack from "ds/Stack"
import Switch from "ds/Switch"

import { IViewSettings } from "./useViewSettings"

interface Props {
  viewSettings: IViewSettings
}

export default function ArchivedViewSetting({ viewSettings }: Props) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={viewSettings.showArchived}
          onChange={(e) => viewSettings.setShowArchived(e.target.checked)}
        />
      }
      label={
        <Stack direction="row" alignItems="center" spacing={1}>
          <AllInboxIcon fontSize="small" />

          <Box>
            <FormattedMessage
              id="playbook.viewSettings.showArchived"
              defaultMessage="Show archived"
            />
          </Box>
        </Stack>
      }
    />
  )
}
