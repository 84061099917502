import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { FormattedMessage } from "react-intl"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"

interface Props {
  onClick: React.MouseEventHandler<HTMLLIElement>
}

export default function MyProfileMenuItem({ onClick }: Props) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="navigation.profile" defaultMessage="My Profile" />
      </ListItemText>
    </MenuItem>
  )
}
