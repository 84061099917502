import { FormattedMessage } from "react-intl"

import { IBarePlaybook } from "api/types/playbooks"

import Modal from "ds/Modal"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import EditPlaybookForm from "./EditPlaybookForm"

interface Props {
  playbook: IBarePlaybook
  onClose: () => void
}

export default function EditPlaybook({ playbook, onClose }: Props) {
  return (
    <Modal isOpen onClose={onClose} data-testid="edit-playbook-modal">
      <Stack spacing={4}>
        <Typography variant="xsNormal">
          <FormattedMessage
            id="businessUnits.playbooks.actions.edit"
            defaultMessage="Edit"
          />
        </Typography>

        <EditPlaybookForm playbook={playbook} onSuccess={onClose} />
      </Stack>
    </Modal>
  )
}
