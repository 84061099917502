import MoreVert from "@mui/icons-material/MoreVert"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { IOrganizationPendingInvitation } from "api/types/organizationInvitations"

import Button from "ds/Button"
import Menu from "ds/Menu"
import { AlertSnackbar, BasicErrorSnack } from "ds/Snackbar"

import useAnchorEl from "utils/hooks/useAnchorEl"

import ResendInvitation from "./ResendInvitation"
import RevokeInvitation from "./RevokeInvitation"

interface Props {
  invitation: IOrganizationPendingInvitation
}

export default function InvitationActions({ invitation }: Props) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const [resendSuccessSnackOpen, setResendSuccessSnackOpen] = useState(false)
  const [resendErrorSnackOpen, setResendErrorSnackOpen] = useState(false)

  return (
    <>
      <Button
        onClick={handleClick}
        aria-label={intl.formatMessage({
          id: "organizations.users.invite.actions",
          defaultMessage: "Invitation actions",
        })}
      >
        <MoreVert />
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ResendInvitation
          invitation={invitation}
          onSuccess={() => {
            handleClose()
            setResendSuccessSnackOpen(true)
          }}
          onError={() => setResendErrorSnackOpen(true)}
        />
        <RevokeInvitation invitation={invitation} onRevoke={handleClose} />
      </Menu>

      <AlertSnackbar
        open={resendSuccessSnackOpen}
        onClose={() => setResendSuccessSnackOpen(false)}
        severity="success"
      >
        <FormattedMessage id="organizations.users.invite.resend.success" />
      </AlertSnackbar>

      <BasicErrorSnack
        open={resendErrorSnackOpen}
        onClose={() => setResendErrorSnackOpen(false)}
      />
    </>
  )
}
