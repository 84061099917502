import { MutateFunction, useInfiniteQuery } from "@tanstack/react-query"

import {
  apiCreateBusinessUnitMemberships,
  apiDeactivateBusinessUnitMembership,
} from "api/businessUnitMemberships"
import { apiSearchBusinessUnitMemberships } from "api/businessUnits"

import { useInvalidatingMutation } from "utils/hooks/mutations"

function buildBusinessUnitMembershipsSearchKey(
  businessUnitId: string,
  query: string
) {
  return ["businessUnitMemberships", businessUnitId, query]
}

function buildBusinessUnitMembershipsKey(businessUnitId: string) {
  return ["businessUnitMemberships", businessUnitId]
}

export const useBusinessUnitMembershipsInfiniteQuery = (
  businessUnitId: string,
  query: string
) => {
  return useInfiniteQuery(
    buildBusinessUnitMembershipsSearchKey(businessUnitId, query),
    ({ pageParam, signal }) =>
      apiSearchBusinessUnitMemberships(
        {
          businessUnitId,
          paginationCursor: pageParam,
          query,
        },
        { signal }
      ),
    {
      getNextPageParam: (lastPage) => lastPage.paginationCursor,
    }
  )
}

function useBusinessUnitMembershipUpdateMutation<TData, TVariables, TError>(
  businessUnitId: string,
  mutationFn: MutateFunction<TData, TError, TVariables>
) {
  return useInvalidatingMutation(
    buildBusinessUnitMembershipsKey(businessUnitId),
    mutationFn
  )
}

export function useBusinessUnitMembershipDeactivateMutation(
  businessUnitId: string
) {
  return useBusinessUnitMembershipUpdateMutation(
    businessUnitId,
    apiDeactivateBusinessUnitMembership
  )
}

export function useBusinessUnitMembershipCreateMutation(
  businessUnitId: string
) {
  return useBusinessUnitMembershipUpdateMutation(
    businessUnitId,
    apiCreateBusinessUnitMemberships
  )
}
