import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import Loader from "ds/Loader"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import OrganizationInvitationsTable from "./OrganizationMemberships/OrganizationInvitationsTable"
import { usePendingInvitationsInfiniteQuery } from "./OrganizationMemberships/organizationInvitationQueries"
import { useOrganization } from "./OrganizationProvider"

interface Props {
  query: string
}

export default function OrganizationInvitationsIndex({ query }: Props) {
  const organization = useOrganization()
  const invitationsQuery = usePendingInvitationsInfiniteQuery(
    organization.id,
    query
  )

  if (invitationsQuery.isLoading) {
    return <Loader />
  }

  if (invitationsQuery.isError) {
    return <UncontrolledError error={invitationsQuery.error} />
  }

  const invitations = invitationsQuery.data.pages
    .map((page) => {
      return page.organizationPendingInvitations
    })
    .flat()

  return (
    <>
      {invitations.length > 0 && (
        <>
          <Typography variant="smSemi">
            <FormattedMessage id="organizations.users.pendingInvitations" />
          </Typography>

          <OrganizationInvitationsTable invitations={invitations} />

          {invitationsQuery.isFetchingNextPage && <Loader />}

          {invitationsQuery.hasNextPage &&
            !invitationsQuery.isFetchingNextPage && (
              <Box>
                <Button onClick={() => invitationsQuery.fetchNextPage()}>
                  <FormattedMessage id="organizations.users.pendingInvitations.index.loadMore" />
                </Button>
              </Box>
            )}
        </>
      )}
    </>
  )
}
