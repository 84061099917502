import { IIntegration } from "api/types/integrations"

export function crmIntegrationMismatch(
  crmIntegration: IIntegration,
  provider: string | null,
  workspaceId: string | null
): boolean {
  if (!provider || !workspaceId) return false
  return (
    crmIntegration.name !== provider ||
    !crmIntegration.workspaceId.includes(workspaceId) // SalesForce is not equal, includes should cover all providers
  )
}
