import { AnyObject } from "@udecode/plate"
import { createComboboxPlugin } from "@udecode/plate-combobox"

import Combobox from "../components/Combobox"
import { MyEditor, MyValue } from "../types"

export const comboboxPlugin = createComboboxPlugin<
  AnyObject,
  MyValue,
  MyEditor
>({
  component: Combobox,
})
