import {
  SelectOnBackspacePlugin,
  createSelectOnBackspacePlugin,
} from "@udecode/plate"

import { MyEditor, MyValue } from "../types"
import { ELEMENT_CLOUD_ATTACHMENT } from "./cloud"

export const selectOnBackspacePlugin = createSelectOnBackspacePlugin<
  SelectOnBackspacePlugin,
  MyValue,
  MyEditor
>({
  options: {
    query: {
      allow: [ELEMENT_CLOUD_ATTACHMENT],
    },
  },
})
