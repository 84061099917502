import { DraggableProvided } from "@hello-pangea/dnd"
import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import { IArgument } from "api/types/arguments"

import AnchorDiv from "ds/AnchorDiv"
import DragAndDropHandle from "ds/DragAndDropHandle"
import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import Stack from "ds/Stack"
import Tooltip from "ds/Tooltip"

import { useMeeting } from "components/App/Playbook/Meeting/MeetingProvider"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import {
  getRawDescription,
  getRawTitle,
  getShortDescription,
  getTitle,
  hasFieldsOutsideTitleAndDescription,
} from "services/argumentFields"
import { findArgumentPersonalNote } from "services/argumentPersonalNotes"
import { isArgumentStale, isCheckboxArgument } from "services/arguments"
import {
  argumentHasMeetingNoteConfiguration,
  getArgumentNoteFormKey,
  isArgumentChecked,
  mandatoryAnswerNotFilled,
} from "services/meetingArgumentNotes"

import ArgumentActions from "../PlaybookEdit/Argument/ArgumentActions/ArgumentActions"
import { SegmentRulesCues } from "../PlaybookEdit/ArgumentSegmentRules/SegmentRulesCues"
import { SCROLL_RESTORE_ANCHOR } from "../playbookScrollRestore"
import AdditionalDetailsCue from "./ArgumentCard/AdditionalDetailsCue"
import ArgumentDescription from "./ArgumentCard/ArgumentDescription"
import ArgumentKindIndicator from "./ArgumentCard/ArgumentKindIndicator/ArgumentKindIndicator"
import EditableArgumentKindIndicator from "./ArgumentCard/ArgumentKindIndicator/EditableArgumentKindIndicator"
import ArgumentTitle from "./ArgumentCard/ArgumentTitle"
import NotificationsCue from "./ArgumentCard/NotificationsCue"
import ArgumentPersonalNote from "./ArgumentPersonalNote/ArgumentPersonalNote"
import MeetingArgumentNoteForm from "./ArgumentShow/MeetingArgumentNoteForm"
import StaleWarning from "./Staleness/StaleWarning"
import { useArgumentModalLocation } from "./routing"

const Container = styled(Box)`
  & .argument-actions {
    opacity: 0.5;
  }

  &:hover {
    .argument-actions {
      opacity: 1;
    }
  }
`

interface Props {
  argument: IArgument
  richText?: boolean
  providedDraggable?: DraggableProvided
}

export default function ArgumentCard({
  argument,
  richText = true,
  providedDraggable,
}: Props) {
  const { editMode, argumentPersonalNotes, playbook, viewSettings, users } =
    usePlaybook()
  const { ongoingMeeting } = useMeeting()

  const hasMissingAnswer = mandatoryAnswerNotFilled(argument, ongoingMeeting)

  const draggableRef = providedDraggable?.innerRef
  const dragHandleProps = providedDraggable?.dragHandleProps
  const draggableProps = providedDraggable?.draggableProps
  const hasOtherFields = hasFieldsOutsideTitleAndDescription(argument, playbook)
  const showStaleCue =
    editMode && viewSettings.showStaleness && isArgumentStale(argument)
  const hasNotifications = argument.notVisitedNotificationsCount !== 0

  const meetingNoteFormKey = getArgumentNoteFormKey(ongoingMeeting, argument)

  const argumentModalLocation = useArgumentModalLocation(argument.id)
  const navigate = useNavigate()

  const description = getShortDescription(argument)
  const rawDescription = getRawDescription(argument)
  const title = getTitle(argument)
  const rawTitle = getRawTitle(argument)

  const argumentPersonalNote = findArgumentPersonalNote(
    argumentPersonalNotes,
    argument.id
  )

  const richTextProps: Partial<RichTextProps> = {
    mentionees: users,
  }

  const strikeThroughTitle =
    !!ongoingMeeting &&
    isCheckboxArgument(argument) &&
    isArgumentChecked(argument, ongoingMeeting)

  return (
    <Container
      id={argumentCardId(argument.id)}
      className={SCROLL_RESTORE_ANCHOR}
      sx={{
        // To make sure the card is scrolled into view below the argument group header
        scrollMarginTop: argument.argumentGroupId ? "44px" : 0,
        borderRadius: 1,
      }}
      ref={draggableRef}
      {...draggableProps}
    >
      <Stack
        direction="row"
        alignItems="start"
        spacing={1.5}
        sx={{
          position: "relative",
          opacity: argument.archived ? 0.5 : 1,
        }}
      >
        <Box flexBasis={0} flexGrow={5} flexShrink={0} minWidth={0}>
          <Stack direction="row" alignItems="start" spacing={1.5}>
            {editMode && dragHandleProps && (
              <DragAndDropHandle dragHandleProps={dragHandleProps} />
            )}

            {editMode ? (
              <EditableArgumentKindIndicator argument={argument} />
            ) : (
              <ArgumentKindIndicator
                showMandatoryAnswerIndicator={hasMissingAnswer}
                argument={argument}
              />
            )}

            <Stack
              minWidth={0}
              minHeight="32px"
              spacing={1}
              justifyContent="center"
              flexGrow={1}
            >
              <AnchorDiv onClick={() => navigate(argumentModalLocation)}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    textDecoration: strikeThroughTitle
                      ? "line-through"
                      : undefined,
                  }}
                >
                  <ArgumentTitle
                    title={title}
                    rawTitle={rawTitle}
                    richTextProps={richTextProps}
                    richText={richText}
                  />
                </Stack>
              </AnchorDiv>

              {!!description && (
                <AnchorDiv onClick={() => navigate(argumentModalLocation)}>
                  <ArgumentDescription
                    description={description}
                    rawDescription={rawDescription}
                    richTextProps={richTextProps}
                    richText={richText}
                  />
                </AnchorDiv>
              )}

              {argumentPersonalNote?.rawValue && !editMode && (
                <AnchorDiv onClick={() => navigate(argumentModalLocation)}>
                  <ArgumentPersonalNote
                    argumentPersonalNote={argumentPersonalNote}
                  />
                </AnchorDiv>
              )}

              {editMode && argumentHasMeetingNoteConfiguration(argument) && (
                <Tooltip
                  title={
                    <FormattedMessage id="playbookEdit.argumentAnswer.form.disabledExplanation" />
                  }
                >
                  <Box>
                    <MeetingArgumentNoteForm
                      disabled
                      argument={argument}
                      key={meetingNoteFormKey}
                    />
                  </Box>
                </Tooltip>
              )}

              {!editMode &&
                ongoingMeeting &&
                argumentHasMeetingNoteConfiguration(argument) && (
                  <MeetingArgumentNoteForm
                    argument={argument}
                    key={meetingNoteFormKey}
                  />
                )}
            </Stack>
          </Stack>
        </Box>

        {editMode && (
          <Box
            display="flex"
            alignItems="center"
            flexBasis={0}
            flexShrink={0}
            flexGrow={3}
            minWidth={0}
          >
            <SegmentRulesCues argument={argument} />
          </Box>
        )}

        <Stack
          direction="row"
          alignItems="center"
          // Magic value that corresponds to the max width of its contents,
          //  so that the card's columns have a consistent width
          flexBasis={editMode ? "70px" : "40px"}
          flexShrink={0}
          flexGrow={0}
          minWidth={0}
          justifyContent="end"
        >
          <AnchorDiv onClick={() => navigate(argumentModalLocation)}>
            <Stack lineHeight={0} direction="row" alignItems="center">
              {hasOtherFields && <AdditionalDetailsCue />}
              {showStaleCue && <StaleWarning />}
              {hasNotifications && <NotificationsCue argument={argument} />}
            </Stack>
          </AnchorDiv>
          {editMode && <ArgumentActions argument={argument} />}
        </Stack>
      </Stack>
    </Container>
  )
}

export function argumentCardId(argumentId: string) {
  return `argument-card-${argumentId}`
}
