import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import Loader from "ds/Loader"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import OrganizationMembershipsTable from "./OrganizationMemberships/OrganizationMembershipsTable"
import { useOrganizationMembershipsInfiniteQuery } from "./OrganizationMemberships/organizationMembershipQueries"
import { useOrganization } from "./OrganizationProvider"

interface Props {
  query: string
}

export default function OrganizationMembershipsIndex({ query }: Props) {
  const organization = useOrganization()
  const membershipsQuery = useOrganizationMembershipsInfiniteQuery(
    organization.id,
    query
  )
  if (membershipsQuery.isLoading) {
    return <Loader />
  }

  if (membershipsQuery.isError) {
    return <UncontrolledError error={membershipsQuery.error} />
  }

  const lastPage = membershipsQuery.data.pages.slice(-1)[0]

  const memberships = membershipsQuery.data.pages
    .map((page) => {
      return page.organizationMemberships
    })
    .flat()

  return (
    <>
      {memberships.length > 0 && (
        <>
          <Stack direction="row" spacing={1}>
            <Typography variant="smSemi">
              {lastPage.searchedMembershipsCount
                ? `${lastPage.searchedMembershipsCount} / ${lastPage.totalMembershipsCount}`
                : lastPage.totalMembershipsCount}
            </Typography>

            <Typography variant="smSemi">
              <FormattedMessage id="organizations.users.title" />
            </Typography>
          </Stack>

          <OrganizationMembershipsTable organizationMemberships={memberships} />
          {membershipsQuery.isFetchingNextPage && <Loader />}

          {membershipsQuery.hasNextPage &&
            !membershipsQuery.isFetchingNextPage && (
              <Box>
                <Button onClick={() => membershipsQuery.fetchNextPage()}>
                  <FormattedMessage id="organizations.memberships.index.loadMore" />
                </Button>
              </Box>
            )}
        </>
      )}
    </>
  )
}
