import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"

import {
  apiArchiveBusinessUnit,
  apiCreateBusinessUnit,
  apiGetBusinessUnit,
  apiGetBusinessUnitAddableUsers,
  apiGetNavigableBusinessUnits,
  apiSearchPendingInvitations,
  apiUpdateBusinessUnit,
} from "api/businessUnits"

import { useInvalidatingMutation } from "utils/hooks/mutations"

import { buildOrganizationKey } from "../Organization/organizationQueries"
import { buildNavigablePlaybooksKey } from "../Playbook/queries/playbookQueries"

function buildBusinessUnitNavigablesKey(organizationId: string) {
  return ["businessUnitNavigables", organizationId]
}

function buildBusinessUnitKey(businessUnitId: string) {
  return ["businessUnit", businessUnitId]
}

function buildBusinessUnitAddableUsers(businessUnit: string) {
  return ["businessUnitAddableUsers", businessUnit]
}

export function buildBusinessUnitPendingInvitationsKey(businessUnitId: string) {
  return ["businessUnitInvitations", businessUnitId, { pending: true }]
}

function buildBusinessUnitPendingInvitationsSearchKey(
  businessUnitId: string,
  query: string
) {
  return ["businessUnitInvitations", businessUnitId, { pending: true }, query]
}

export function useBusinessUnitNavigablesQuery(organizationId: string) {
  return useQuery(
    buildBusinessUnitNavigablesKey(organizationId),
    ({ signal }) => apiGetNavigableBusinessUnits(organizationId, { signal })
  )
}

export function useBusinessUnitQuery(businessUnitId: string) {
  return useQuery(buildBusinessUnitKey(businessUnitId), ({ signal }) =>
    apiGetBusinessUnit(businessUnitId, { signal })
  )
}

export function useBusinessUnitAddableUsersQuery(businessUnitId: string) {
  return useQuery(buildBusinessUnitAddableUsers(businessUnitId), ({ signal }) =>
    apiGetBusinessUnitAddableUsers(businessUnitId, { signal })
  )
}

export const useBusinessUnitCreateMutation = (organizationId: string) =>
  useInvalidatingMutation(
    buildOrganizationKey(organizationId),
    apiCreateBusinessUnit
  )

export const useBusinessUnitUpdateMutation = (
  organizationId: string,
  businessUnitId: string
) => {
  const queryClient = useQueryClient()
  return useMutation(apiUpdateBusinessUnit, {
    onSuccess: () => {
      invalidateBusinessUnitAndOrganizationQueries(
        queryClient,
        organizationId,
        businessUnitId
      )
    },
  })
}

export function useBusinessUnitArchiveMutation(
  organizationId: string,
  businessUnitId: string
) {
  const queryClient = useQueryClient()
  return useMutation(apiArchiveBusinessUnit, {
    onSuccess: () => {
      invalidateQueriesWithBusinessUnit(
        queryClient,
        organizationId,
        businessUnitId
      )
    },
  })
}

export const usePendingInvitationsInfiniteQuery = (
  businessUnitId: string,
  query: string
) =>
  useInfiniteQuery(
    buildBusinessUnitPendingInvitationsSearchKey(businessUnitId, query),
    ({ pageParam, signal }) =>
      apiSearchPendingInvitations(
        { businessUnitId, paginationCursor: pageParam, query },
        { signal }
      ),
    {
      getNextPageParam: (lastPage) => lastPage.paginationCursor,
    }
  )

const invalidateBusinessUnitAndOrganizationQueries = (
  queryClient: QueryClient,
  organizationId: string,
  businessUnitId: string
) => {
  queryClient.invalidateQueries(buildOrganizationKey(organizationId))
  queryClient.invalidateQueries(buildBusinessUnitKey(businessUnitId))
}

const invalidateQueriesWithBusinessUnit = (
  queryClient: QueryClient,
  organizationId: string,
  businessUnitId: string
) => {
  invalidateBusinessUnitAndOrganizationQueries(
    queryClient,
    organizationId,
    businessUnitId
  )
  queryClient.invalidateQueries(buildNavigablePlaybooksKey())
}
