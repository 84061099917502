import { FormattedMessage } from "react-intl"

import { IBusinessUnit } from "api/types/businessUnits"

import Loader from "ds/Loader"
import MenuList from "ds/MenuList"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import { useBusinessUnitNavigablesQuery } from "components/App/BusinessUnit/businessUnitQueries"

import BusinessUnitItem from "./BusinessUnitSwitch/BusinessUnitItem"

interface Props {
  currentBusinessUnit: IBusinessUnit
}

export default function BusinessUnitSwitch({ currentBusinessUnit }: Props) {
  const {
    isLoading,
    isError,
    error,
    data: businessUnits,
  } = useBusinessUnitNavigablesQuery(currentBusinessUnit.organizationId)

  if (isLoading) return <Loader />
  if (isError) return <UncontrolledError error={error} />

  return (
    <Stack spacing={2}>
      <Typography variant="lgBold">
        <FormattedMessage id="navigation.businessUnitSwitch" />
      </Typography>

      <MenuList>
        {businessUnits
          .filter((x) => !x.archived)
          .map((businessUnit) => (
            <BusinessUnitItem
              key={businessUnit.id}
              businessUnit={businessUnit}
              selected={businessUnit.id === currentBusinessUnit.id}
            />
          ))}
      </MenuList>
    </Stack>
  )
}
