import { FormattedMessage } from "react-intl"
import { Navigate, Route, Routes } from "react-router-dom"

import Indexation from "./SuperAdmin/Indexation"
import Organizations from "./SuperAdmin/Organizations"
import SuperAdminLayout from "./SuperAdmin/SuperAdminLayout"

export default function SuperAdmin() {
  return (
    <SuperAdminLayout
      tab={
        <Routes>
          <Route
            path="organizations/*"
            element={
              <FormattedMessage
                id="superadmin.organizations.title"
                defaultMessage="Organizations"
              />
            }
          />

          <Route
            path="indexation/*"
            element={
              <FormattedMessage
                id="superadmin.indexation.title"
                defaultMessage="Indexation"
              />
            }
          />
        </Routes>
      }
    >
      <Routes>
        <Route path="organizations/*" element={<Organizations />} />
        <Route path="indexation/*" element={<Indexation />} />
        <Route path="/" element={<Navigate to="organizations" replace />} />
      </Routes>
    </SuperAdminLayout>
  )
}
