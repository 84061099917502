// Helpers to reduce verbosity of react-query's mutations that update a cache key
import {
  MutationFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"

import { replaceByPredicate } from "utils/mutationHelpers"

// Mutation that updates a key in the cache on success
//
// Example:
// const query = useQuery(cacheKey, apiGet) // apiGet returns an object
// const mutation = useMutation(cacheKey, apiUpdate) // apiUpdate returns the updated object
//
// Option: `getValue`
// Description: Return the new value to set in the cache. Arguments are the result of the mutation, the previous value, and the onSuccess arguments (other than the result)
// Default: `(result) => (result)` the mutation result is the new value
// Example: `{ getValue: (result, prev) => ({ ...prev, result }) }`
//
// Other options are passed to `useMutation`

export const useUpdateMutation = <
  TCacheData = unknown,
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  cacheKey: QueryKey,
  mutationFn: MutationFunction<TData, TVariables>,
  getValue: (
    data: TData,
    prevValue: TCacheData | undefined,
    variables: TVariables,
    context: TContext | undefined
  ) => TCacheData | undefined,
  {
    onSuccess,
    ...options
  }: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationFn"
  > = {}
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const queryClient = useQueryClient()
  return useMutation(mutationFn, {
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(cacheKey, (prevValue: TCacheData | undefined) =>
        getValue(data, prevValue, variables, context)
      )
      onSuccess?.(data, variables, context)
    },
    ...options,
  })
}

// Mutation that updates a list in the cache on success
//
// Example:
// const query = useQuery(cacheKey, apiGet) // apiGet returns an array
// const mutation = useUpdateInArrayMutation(cacheKey, apiUpdate) // apiUpdate returns the updated version of an array element
//
// Option: `getElement`
// Description: Get the updated element from the mutation's result. Arguments are the same as `onSuccess`.
// Default: `(result) => result` the mutation result is the element
// Example: `{ getElement: (result) => result.comment }`
//
// Option: `compareElements`
// Description: Returns whether the original element matches the updated element
// Default: `(el1, el2) => el1.id === el2.id` elements match on the `id` prop
// Example: `(el1, el2) => el1.uuid === el2.uuid`
//
// Option: `updateElement`
// Description: How to update the element that matches the updated element
// Default: `(newElement) => newElement` the original is replaced with the updated element
// Example: `(newElement, originalElement) => ({...originalElement, ...newElement})`
//
// Other options are passed to `useMutation`

export const useUpdateInArrayMutation = <
  TElement = unknown,
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  cacheKey: QueryKey,
  mutationFn: MutationFunction<TData, TVariables>,
  getElement: (data: TData) => TElement,
  compareElements: (el1: TElement, el2: TElement) => boolean,
  mutationOptions: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationFn"
  > = {}
): UseMutationResult<TData, TError, TVariables, TContext> =>
  useUpdateMutation(
    cacheKey,
    mutationFn,
    (result, prevValue: TElement[] | undefined) => {
      const newElement = getElement(result)
      return replaceByPredicate(
        prevValue || [],
        (element) => compareElements(element, newElement),
        newElement
      )
    },
    mutationOptions
  )

// Mutation that adds an element to a list in the cache on success
//
// Example:
// const query = useQuery(cacheKey, apiGet) // apiGet returns an array
// const mutation = useUpdateInArrayMutation(cacheKey, apiCreate) // apiUpdate returns the updated version of an array element
//
// Option: `getElement`
// Description: Get the updated element from the mutation's result. Arguments are the same as `onSuccess`.
// Default: `(result) => result` the mutation result is the element
// Example: `{ getElement: (result) => result.comment }`
//
// Other options are passed to `useMutation`
export const useAddMutation = <
  TElement = unknown,
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  cacheKey: QueryKey,
  mutationFn: MutationFunction<TData, TVariables>,
  getElement: (
    data: TData,
    variables: TVariables,
    context?: TContext
  ) => TElement,
  mutationOptions: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationFn"
  > = {}
): UseMutationResult<TData, TError, TVariables, TContext> =>
  useUpdateMutation(
    cacheKey,
    mutationFn,
    (result, prevValue: TElement[] | undefined, variables, context) => {
      const newElement = getElement(result, variables, context)
      return [...(prevValue || []), newElement]
    },
    mutationOptions
  )

// Mutation that invalidates queries on success
//
// Example:
// const query = useQuery(cacheKey, apiGetSomething)
// const mutation = useInvalidatingMutation(cacheKey, apiUpdateSomething)
//
// Options are passed to `useMutation`
export const useInvalidatingMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  cacheKey: QueryKey,
  mutationFn: MutationFunction<TData, TVariables>,
  {
    onSuccess,
    ...options
  }: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationFn"
  > = {}
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const queryClient = useQueryClient()
  return useMutation(mutationFn, {
    onSuccess: (...onSuccessArgs) => {
      queryClient.invalidateQueries(cacheKey)
      onSuccess?.(...onSuccessArgs)
    },
    ...options,
  })
}

// Mutation that makes an optimistic update of the cache through `onMutate`
//
// Example:
// const query = useQuery(cacheKey, apiGetSomething)
// const mutation = useOptimisticMutation(cacheKey, apiUpdateSomething, (prev, variables) => ({...prev, ...variables}))
//
// Other options are passed to `useMutation`
export const useOptimisticMutation = <
  TCacheData = unknown,
  TData = unknown,
  TError = unknown,
  TVariables = void
>(
  cacheKey: QueryKey,
  mutationFn: MutationFunction<TData, TVariables>,
  updater: (
    prevData: TCacheData | undefined,
    variables: TVariables
  ) => TCacheData | undefined,
  {
    onMutate,
    onError,
    ...options
  }: Omit<
    UseMutationOptions<
      TData,
      TError,
      TVariables,
      { cachedPrev: TCacheData | undefined }
    >,
    "mutationFn"
  > = {}
): UseMutationResult<
  TData,
  TError,
  TVariables,
  { cachedPrev: TCacheData | undefined }
> => {
  const queryClient = useQueryClient()
  return useMutation(mutationFn, {
    onMutate: (variables: TVariables) => {
      const cachedPrev = queryClient.getQueryData<TCacheData>(cacheKey)
      queryClient.setQueryData(cacheKey, (prev: TCacheData | undefined) =>
        updater(prev, variables)
      )
      onMutate?.(variables)
      return { cachedPrev }
    },
    onError: (err, variables, context) => {
      if (context) queryClient.setQueryData(cacheKey, context.cachedPrev)
      onError?.(err, variables, context)
    },
    ...options,
  })
}
