import MuiMenu, { MenuProps } from "@mui/material/Menu"
import { forwardRef } from "react"

export type { MenuProps }

export default forwardRef(function Menu(
  props: MenuProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiMenu {...props} ref={ref} />
})
