import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { apiGetUpcomingCalls } from "api/CRMCalls"
import { IUpcomingCall } from "api/types/CRMCalls"

import AsyncAutocomplete from "ds/AsyncAutocomplete"
import { CustomAutocompleteProps } from "ds/Autocomplete"
import { AlertSnackbar } from "ds/Snackbar"

import { useMeeting } from "components/App/Playbook/Meeting/MeetingProvider"

import CRMCallItem from "./CRMCallSelector/CRMCallItem"

function InputLabel() {
  return <FormattedMessage id="playbook.meeting.informations.crmCalls.title" />
}

function getOptionLabel(option: IUpcomingCall) {
  return option.title || "Call"
}

function filterOptions(options: IUpcomingCall[], filterValue: string) {
  return options.filter((option) => {
    return option.title?.match(new RegExp(filterValue, "i"))
  })
}

interface Props<DisableClearable extends boolean | undefined>
  extends Omit<
    CustomAutocompleteProps<IUpcomingCall, false, DisableClearable, false>,
    | "getOptionLabel"
    | "options"
    | "loading"
    | "renderOption"
    | "multiple"
    | "inputLabel"
  > {
  businessUnitId: string
  inputLabel?: React.ReactNode
}

export default function CRMCallSelector<
  DisableClearable extends boolean | undefined
>({
  businessUnitId,
  inputLabel = <InputLabel />,
  ...props
}: Props<DisableClearable>) {
  const { company, contacts } = useMeeting()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  return (
    <>
      <AsyncAutocomplete
        multiple={false}
        inputLabel={inputLabel}
        getOptions={() =>
          apiGetUpcomingCalls(
            businessUnitId,
            contacts.map(({ id }) => id),
            company?.id
          )
        }
        isOptionEqualToValue={(option, value) =>
          option.externalId === value.externalId
        }
        onGetOptionsFailure={() => setErrorSnackOpen(true)}
        renderOption={(props, option) => (
          <CRMCallItem {...props} key={option.externalId} crmCall={option} />
        )}
        getOptionLabel={getOptionLabel}
        filterOptions={(options, { inputValue }) => {
          if (!inputValue) return options
          return filterOptions(options, inputValue)
        }}
        {...props}
      />

      <AlertSnackbar
        severity="error"
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbook.meeting.information.crmCalls.search.error" />
      </AlertSnackbar>
    </>
  )
}
