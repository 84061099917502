// Singleton that contains the (async) JWT token getter function
// This is a hack to avoid having to pass that getter from components to API calls
type GetTokenFn = () => Promise<string>
let getToken: null | GetTokenFn = null

export const setGetToken = (func: GetTokenFn): void => {
  getToken = func
}

export const getGetToken = (): GetTokenFn => {
  if (!getToken) {
    throw new Error("getToken was not set")
  }
  return getToken
}
