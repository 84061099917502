import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { Box } from "@mui/material"
import truncate from "lodash/truncate"
import { useIntl } from "react-intl"

import { IVote } from "api/types/votes"

import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UnstyledLink from "ds/UnstyledLink"

import { getArgumentPath } from "components/App/Playbook/Argument/routing"
import { useConfig } from "components/Config"

import { getRawTitle } from "services/argumentFields"

import ReadonlyVoteNote from "./ReadonlyVoteNote"
import VoteNote from "./VoteNote"

interface Props {
  vote: IVote
  readonly?: boolean
  playbookId: string
  businessUnitId: string
}

export default function Vote({
  vote,
  readonly = false,
  playbookId,
  businessUnitId,
}: Props) {
  const intl = useIntl()

  const argumentTitle =
    getRawTitle(vote.argument) ||
    intl.formatMessage({
      id: "playbookEdit.argument.defaultTitle",
      defaultMessage: "Title",
    })
  const config = useConfig()

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={1.5}
      minWidth={0}
      width="100%"
    >
      <Box p={0.75} lineHeight={0}>
        <KeyboardArrowRightIcon fontSize="small" />
      </Box>

      <Box flexGrow={1} minWidth={0}>
        <UnstyledLink
          target="_blank"
          href={`${config.appUrl}${getArgumentPath(
            businessUnitId,
            playbookId,
            vote.argument.id
          )}`}
          rel="noreferrer"
        >
          <Typography variant="smNormal" title={argumentTitle}>
            {truncate(argumentTitle, { length: 140 })}
          </Typography>
        </UnstyledLink>
      </Box>

      <Typography
        flexShrink={0}
        whiteSpace={"nowrap"}
        variant="smMed"
        component="span"
      >
        {vote.argumentType.name}
      </Typography>

      <Box minWidth="20px" flexShrink={0}>
        {readonly && vote.note && <ReadonlyVoteNote vote={vote} />}
        {!readonly && <VoteNote vote={vote} playbookId={playbookId} />}
      </Box>
    </Stack>
  )
}
