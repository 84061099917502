import {
  MutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"

import {
  apiAddArgumentCommentReaction,
  apiCreateArgumentComment,
  apiDeleteArgumentComment,
  apiDeleteArgumentCommentReaction,
  apiGetArgumentComments,
  apiGetArgumentCommentsCount,
  apiToggleResolveArgumentComment,
  apiUpdateArgumentComment,
} from "api/argumentComments"
import { IArgumentComment } from "api/types/argumentComments"
import { IArgument } from "api/types/arguments"

import { resetArgumentNotVisitedNotifications } from "services/arguments"

import { useUpdateMutation } from "utils/hooks/mutations"
import { removeById, replaceById, updateById } from "utils/mutationHelpers"

import { buildArgumentsKey } from "../../queries/argumentQueries"

function buildArgumentCommentsKey(argumentId: string) {
  return ["argumentComments", argumentId]
}
function buildArgumentCommentsCountKey(argumentId: string) {
  return [...buildArgumentCommentsKey(argumentId), "count"]
}

// Once the comments are loaded, reset the not visited notifications to 0 on the argument
export function useArgumentCommentsQuery(
  argumentId: string,
  argumentTypeId: string
) {
  const queryClient = useQueryClient()
  return useQuery(
    buildArgumentCommentsKey(argumentId),
    ({ signal }) => apiGetArgumentComments({ argumentId }, { signal }),
    {
      onSuccess: () => {
        if (argumentTypeId) {
          queryClient.setQueryData(
            buildArgumentsKey(argumentTypeId),
            (prevArguments: IArgument[] | undefined) =>
              prevArguments &&
              resetArgumentNotVisitedNotifications(prevArguments, argumentId)
          )
        }
      },
    }
  )
}

export function useArgumentCommentsCountQuery(argumentId: string) {
  return useQuery(buildArgumentCommentsCountKey(argumentId), ({ signal }) =>
    apiGetArgumentCommentsCount({ argumentId }, { signal })
  )
}

export function useArgumentCommentCreateMutation(argumentId: string) {
  const queryClient = useQueryClient()
  return useMutation(apiCreateArgumentComment, {
    onSuccess: (result) => {
      queryClient.setQueryData(
        buildArgumentCommentsKey(argumentId),
        (prev: IArgumentComment[] | undefined) =>
          prev && [...prev, result.argumentComment]
      )
      queryClient.invalidateQueries(buildArgumentCommentsCountKey(argumentId))
    },
  })
}

export function useArgumentCommentDeleteMutation(argumentId: string) {
  const queryClient = useQueryClient()
  return useMutation(apiDeleteArgumentComment, {
    onSuccess: (_result, variables) => {
      const id = variables
      queryClient.setQueryData(
        buildArgumentCommentsKey(argumentId),
        (prev: IArgumentComment[] | undefined) => prev && removeById(prev, id)
      )
      queryClient.invalidateQueries(buildArgumentCommentsCountKey(argumentId))
    },
  })
}

export function useArgumentCommentUpdateMutation(argumentId: string) {
  const queryClient = useQueryClient()
  return useMutation(apiUpdateArgumentComment, {
    onSuccess: (result) => {
      queryClient.setQueryData(
        buildArgumentCommentsKey(argumentId),
        (prev: IArgumentComment[] | undefined) =>
          prev && replaceById(prev, result.argumentComment)
      )
      queryClient.invalidateQueries(buildArgumentCommentsCountKey(argumentId))
    },
  })
}

export function useArgumentCommentToggleResolveMutation(argumentId: string) {
  const queryClient = useQueryClient()
  return useMutation(apiToggleResolveArgumentComment, {
    onSuccess: (result) => {
      queryClient.setQueryData(
        buildArgumentCommentsKey(argumentId),
        (prev: IArgumentComment[] | undefined) =>
          prev && replaceById(prev, result.argumentComment)
      )
      queryClient.invalidateQueries(buildArgumentCommentsCountKey(argumentId))
    },
  })
}

function useArgumentCommentReactionMutation<TVariables, TError>(
  argumentId: string,
  mutationFn: MutateFunction<IArgumentComment, TVariables, TError>
) {
  return useUpdateMutation(
    buildArgumentCommentsKey(argumentId),
    mutationFn,
    (result, prev: IArgumentComment[] | undefined) =>
      prev &&
      updateById(prev, result.id, (comment) => ({
        ...comment,
        reactions: result.reactions,
      }))
  )
}

export function useArgumentCommentReactionAddMutation(argumentId: string) {
  return useArgumentCommentReactionMutation(
    argumentId,
    apiAddArgumentCommentReaction
  )
}

export function useArgumentCommentReactionDeleteMutation(argumentId: string) {
  return useArgumentCommentReactionMutation(
    argumentId,
    apiDeleteArgumentCommentReaction
  )
}
