import { DraggableProvided } from "@hello-pangea/dnd"
import { Box } from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import { useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useDebouncedCallback } from "use-debounce"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IIntegration } from "api/types/integrations"

import DragAndDropHandle from "ds/DragAndDropHandle"
import { BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import {
  useArgumentSegmentationDeleteMutation,
  useArgumentSegmentationRestoreMutation,
  useArgumentSegmentationUpdateMutation,
} from "components/App/Playbook/queries/argumentSegmentationQueries"

import { isArchived } from "services/archivable"

import useConfirm from "utils/hooks/useConfirm"

import AllowMultipleSelectionCTA from "./AllowMultipleSelectionCTA"
import ArgumentSegments from "./ArgumentSegments"
import CollapseCTA from "./CollapseCTA"
import DeleteCTA from "./DeleteCTA"
import LinkedToCrmPropertyHint from "./LinkedToCrmPropertyHint"
import RestoreCTA from "./RestoreCTA"

// Portal allows using Drag and Drop inside a modal
const portal = document.createElement("div")
document.body.appendChild(portal)

const debounceWaitMs = 250

interface Props {
  argumentSegmentation: IArgumentSegmentation
  providedDraggable?: DraggableProvided
  isDragging?: boolean
  crmIntegration: IIntegration | null
  isLastCreated?: boolean
}

// DnD props might not be provided, meaning the DnD feature is disabled
export default function ArgumentSegmentationInput({
  argumentSegmentation,
  providedDraggable,
  isDragging = false,
  crmIntegration,
  isLastCreated,
}: Props) {
  const initialName = argumentSegmentation.name || ""
  const [name, setName] = useState(initialName)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const confirm = useConfirm()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const { playbook } = usePlaybook()
  const updateMutation = useArgumentSegmentationUpdateMutation(playbook.id)
  const deleteMutation = useArgumentSegmentationDeleteMutation(playbook.id)
  const restoreMutation = useArgumentSegmentationRestoreMutation(playbook.id)
  const inputRef = useRef<HTMLInputElement>(null)

  // For newly created segmentation, focus on the input, and open segments
  useEffect(() => {
    if (!isLastCreated) return
    if (inputRef.current) inputRef.current?.focus()
    setIsCollapsed(false)
  }, [isLastCreated])

  function submit(name: string) {
    updateMutation.mutate(
      {
        id: argumentSegmentation.id,
        name,
      },
      { onError: () => setErrorSnackOpen(true) }
    )
  }

  function onToggleDefaultSegment(segmentId: string) {
    updateMutation.mutate(
      {
        id: argumentSegmentation.id,
        defaultSegmentId:
          segmentId === argumentSegmentation.defaultSegmentId
            ? null
            : segmentId,
      },
      { onError: () => setErrorSnackOpen(true) }
    )
  }

  const debouncedSubmit = useDebouncedCallback(submit, debounceWaitMs)

  function setNameAndSubmit(name: string) {
    setName(name)
    debouncedSubmit(name)
  }

  function onDelete() {
    if (!confirm()) return
    deleteMutation.mutate(argumentSegmentation.id)
  }

  const onRestore = () => restoreMutation.mutate(argumentSegmentation.id)

  const startAdornment = providedDraggable ? (
    <InputAdornment position="start">
      <DragAndDropHandle dragHandleProps={providedDraggable.dragHandleProps} />
      {argumentSegmentation.crmProperty && (
        <LinkedToCrmPropertyHint
          crmProperty={argumentSegmentation.crmProperty}
        />
      )}
    </InputAdornment>
  ) : undefined

  const collapseCTA = (
    <CollapseCTA
      isCollapsed={isCollapsed}
      onClick={() => setIsCollapsed((prev) => !prev)}
    />
  )

  const allowMultipleSelectionCTA = (
    <AllowMultipleSelectionCTA
      active={argumentSegmentation.allowMultipleSelection}
      onClick={() =>
        updateMutation.mutate({
          id: argumentSegmentation.id,
          allowMultipleSelection: !argumentSegmentation.allowMultipleSelection,
        })
      }
      disabled={updateMutation.isLoading}
    />
  )

  const deleteCTA = (
    <DeleteCTA onClick={onDelete} disabled={deleteMutation.isLoading} />
  )

  const restoreCTA = (
    <RestoreCTA onClick={onRestore} disabled={restoreMutation.isLoading} />
  )

  const endAdornment = (
    <InputAdornment position="end">
      {allowMultipleSelectionCTA}
      {isArchived(argumentSegmentation) ? restoreCTA : deleteCTA}
    </InputAdornment>
  )

  const node = (
    <>
      <Stack
        ref={providedDraggable?.innerRef}
        {...providedDraggable?.draggableProps}
        spacing={1}
        sx={{ bgcolor: (theme) => theme.palette.background.paper }}
      >
        <Stack direction="row">
          <Box flexShrink={0}>{collapseCTA}</Box>
          <Box flexShrink={0} flexGrow={1}>
            <TextField
              fullWidth
              id={`argument-segmentation-name-${argumentSegmentation.id}`}
              value={name}
              onChange={(e) => setNameAndSubmit(e.target.value)}
              size="small"
              InputProps={{ endAdornment, startAdornment }}
              inputRef={inputRef}
              sx={{
                opacity: argumentSegmentation.discarded ? 0.5 : 1,
              }}
            />
          </Box>
        </Stack>

        {!isCollapsed && (
          <Box pl={4}>
            <Box
              pl={1.5}
              borderLeft={(theme) => `solid 2px ${theme.palette.primary.main}`}
            >
              <ArgumentSegments
                argumentSegmentation={argumentSegmentation}
                crmIntegration={crmIntegration}
                onToggleDefaultSegment={onToggleDefaultSegment}
              />
            </Box>
          </Box>
        )}
      </Stack>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </>
  )

  if (!isDragging) {
    return node
  }

  return createPortal(node, portal)
}
