import { useQuery } from "@tanstack/react-query"

import {
  createPlaybookExport,
  getLatestPlaybookExportAt,
} from "api/playbookExports"

import { useInvalidatingMutation } from "utils/hooks/mutations"

function buildLatestPlaybookExportKey(playbookId: string) {
  return ["latestPlaybookExport", playbookId]
}

export const useLatestPlaybookExportQuery = (playbookId: string) =>
  useQuery(buildLatestPlaybookExportKey(playbookId), ({ signal }) =>
    getLatestPlaybookExportAt(playbookId, { signal })
  )

export const usePlaybookExportCreateMutation = (playbookId: string) =>
  useInvalidatingMutation(
    buildLatestPlaybookExportKey(playbookId),
    createPlaybookExport
  )
