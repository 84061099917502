import { Theme, useTheme } from "@mui/material"

import { IArgumentCommentCategory } from "api/types/argumentComments"

import Chip from "ds/Chip"

import FormattedCommentCategory from "./FormattedCommentCategory"

// Not part of the theme, maybe it should?
function getChipColor(theme: Theme, category: IArgumentCommentCategory) {
  switch (category) {
    case "question":
      return theme.palette.secondary[100]
    case "client_feedback":
      return theme.palette.primary[100]
    case "client_objection":
      return theme.palette.error[100]
    case "need_more_info":
      return theme.palette.warning[100]
    case "suggestion":
      return theme.palette.success[100]
    case "performance":
      return theme.palette.secondary[50]
  }
}

interface Props {
  category: IArgumentCommentCategory
}

export default function CommentCategoryChip({ category }: Props) {
  const theme = useTheme()
  const bgcolor = getChipColor(theme, category)
  return (
    <Chip
      label={<FormattedCommentCategory category={category} />}
      sx={{ bgcolor }}
    />
  )
}
