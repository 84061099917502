import ZoomInIcon from "@mui/icons-material/ZoomIn"
import { Box } from "@mui/material"
import { PlateElement } from "@udecode/plate"

import IconButton from "ds/IconButton"
import { useCloudAttachmentElementState } from "ds/RichTextNew/plugins/cloud"

import { CloudAttachmentProps } from "./CloudAttachmentProps"

// At this point we know that the attachment is uploaded
// This would not be the case fi we were using the cloudImagePlugin or the the imagePlugin from @udecode/plate-media

export default function CloudImageAttachment(props: CloudAttachmentProps) {
  const { children, element } = props
  const { focused, selected } = useCloudAttachmentElementState({
    element,
  })

  return (
    <PlateElement {...props}>
      <Box
        contentEditable={false}
        sx={{
          userSelect: "none",
          position: "relative",
          "> img": {
            maxHeight: "400px",
            maxWidth: "100%",
            outline: (theme) =>
              focused && selected
                ? `2px solid ${theme.palette.primary[300]}`
                : undefined,
            m: 0,
            my: 1,
            display: "block",
            borderRadius: 1,
          },
          "&:hover": {
            "& .open-image": {
              opacity: 1,
            },
          },
        }}
        draggable
      >
        <img src={element.url} alt={element.filename} />
        <Box
          className="open-image"
          sx={{
            position: "absolute",
            opacity: 0,
            transition: (theme) => theme.transitions.create("opacity"),
            top: (theme) => theme.spacing(1),
            right: (theme) => theme.spacing(1),
          }}
        >
          <IconButton
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              window.open(element.url, "_blank")
            }}
            sx={{
              opacity: 0.7,
              bgcolor: (theme) => theme.palette.common.white,
              "&:hover": { bgcolor: (theme) => theme.palette.grey[400] },
            }}
          >
            <ZoomInIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      {children}
    </PlateElement>
  )
}
