import { useIntl } from "react-intl"

import { IMeetingArgumentNoteDate } from "api/types/meetingArgumentNotes"
import { IMeetingNoteConfiguration } from "api/types/meetingNoteConfigurations"

import DatePicker from "ds/DatePicker"
import Stack from "ds/Stack"

import AdditionalInfo from "components/common/AdditionalInfo"

interface Props {
  note: IMeetingArgumentNoteDate
  onChange: (newValue: string | null) => void
  onAdditionalInfoChange: (newValue: string) => void
  configuration: IMeetingNoteConfiguration
  isSaved: boolean
  disabled: boolean
}

export default function DateForm({
  note,
  onChange,
  onAdditionalInfoChange,
  configuration,
  isSaved,
  disabled,
}: Props) {
  const { hasAdditionalInfo } = configuration
  const intl = useIntl()

  return (
    <Stack direction="row">
      <DatePicker
        label={intl.formatMessage({
          id: "playbookEdit.argumentAnswer.form.typeSelect.date",
        })}
        value={note.value}
        onChange={onChange}
        disabled={disabled}
        fullWidth
      />

      {hasAdditionalInfo && (
        <AdditionalInfo
          value={note.additionalInfo || ""}
          onChange={onAdditionalInfoChange}
          isSaved={isSaved}
          disabled={disabled}
        />
      )}
    </Stack>
  )
}
