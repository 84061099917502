import { Box } from "@mui/material"
import { useState } from "react"
import { useIntl } from "react-intl"

import { IArgumentField } from "api/types/argumentFields"

import ClosingCross from "ds/Modal/ClosingCross"
import SearchInput from "ds/SearchInput"
import Stack from "ds/Stack"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { filterArgumentFields, getCustomFields } from "services/argumentFields"

import ArgumentFieldResults from "./ArgumentFieldResults"
import CreateArgumentField from "./CreateArgumentField"

interface Props {
  onAddField: (argumentField: IArgumentField) => void
  onClose: () => void
}

export default function ArgumentFieldCreatable({ onAddField, onClose }: Props) {
  const { playbook } = usePlaybook()
  const [searchTerm, setSearchTerm] = useState("")
  const intl = useIntl()

  const filteredArgumentFields = filterArgumentFields(
    getCustomFields(playbook),
    searchTerm
  )

  return (
    <Box width="300px" minHeight="400px" p={1}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Box flexGrow={1}>
          <SearchInput
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={intl.formatMessage({
              id: "common.searchOrCreate",
              defaultMessage: "Type to search or create",
            })}
            clear={() => setSearchTerm("")}
          />
        </Box>

        <Box>
          <ClosingCross onClick={onClose} />
        </Box>
      </Stack>

      {searchTerm.length > 0 && (
        <CreateArgumentField name={searchTerm} onAddField={onAddField} />
      )}

      <ArgumentFieldResults
        argumentFields={filteredArgumentFields}
        onClick={onAddField}
      />
    </Box>
  )
}
