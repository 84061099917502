import { createContext, useContext } from "react"

import { IOrganization } from "api/types/organizations"

interface Props {
  organization: IOrganization
  children: React.ReactElement
}

export default function OrganizationProvider({
  organization,
  children,
}: Props) {
  return (
    <OrganizationContext.Provider value={organization}>
      {children}
    </OrganizationContext.Provider>
  )
}

type OrganizationProvidedValue = IOrganization

const OrganizationContext = createContext<OrganizationProvidedValue | null>(
  null
)

export function useUnsafeOrganization() {
  return useContext(OrganizationContext)
}

export function useOrganization() {
  const providedValue = useContext(OrganizationContext)
  if (providedValue) return providedValue
  throw new Error(
    "useOrganization was called without being wrapped in a OrganizationProvider"
  )
}
