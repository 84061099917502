import { useState } from "react"

import { IArgumentComment } from "api/types/argumentComments"

import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import { MyValue } from "ds/RichTextNew/types"

import CommentDisplay from "./CommentDisplay"
import CommentEdit from "./CommentEdit"
import { useArgumentCommentUpdateMutation } from "./argumentCommentQueries"

interface Props {
  comment: IArgumentComment
  richTextProps: Partial<RichTextProps>
}

export default function ArgumentComment({ comment, richTextProps }: Props) {
  const [isEditing, setIsEditing] = useState(false)
  const updateMutation = useArgumentCommentUpdateMutation(comment.argumentId)

  async function onUpdate(
    commentId: string,
    value: MyValue,
    rawValue: string,
    category: string | null
  ) {
    updateMutation.mutate(
      {
        id: commentId,
        argumentComment: {
          value,
          rawValue,
          category,
        },
      },
      {
        onSuccess: () => setIsEditing(false),
      }
    )
  }

  if (!isEditing)
    return (
      <CommentDisplay
        richTextProps={richTextProps}
        comment={comment}
        onEditClick={() => setIsEditing(true)}
      />
    )

  return (
    <CommentEdit
      richTextProps={richTextProps}
      comment={comment}
      onSubmit={onUpdate}
      isSubmitting={updateMutation.isLoading}
      cancelEditing={() => setIsEditing(false)}
    />
  )
}
