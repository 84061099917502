import MuiStack, { StackProps } from "@mui/material/Stack"
import { forwardRef } from "react"

export type { StackProps }

export default forwardRef(function Stack(
  props: StackProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiStack {...props} ref={ref} />
})
