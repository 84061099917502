import styled from "styled-components"

import { IVote } from "api/types/votes"

import Vote from "components/App/Playbook/Meeting/MeetingNotes/Votes/Vote"

const StyledUl = styled.ul`
  list-style-type: none;
  padding-inline-start: ${({ theme }) => theme.spacing(3)};

  > li:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }
`

interface Props {
  votes: IVote[]
  playbookId: string
  businessUnitId: string
}

export default function MeetingVotes({
  votes,
  playbookId,
  businessUnitId,
}: Props) {
  return (
    <StyledUl>
      {votes.map((vote) => (
        <li key={vote.id}>
          <Vote
            key={vote.id}
            vote={vote}
            playbookId={playbookId}
            businessUnitId={businessUnitId}
            readonly
          />
        </li>
      ))}
    </StyledUl>
  )
}
