import { useMutation, useQueryClient } from "@tanstack/react-query"

import {
  apiCreateArgumentGroup,
  apiDeleteArgumentGroup,
  apiPasteArgumentGroups,
  apiUpdateArgumentGroup,
  apiUpdateArgumentGroupPosition,
} from "api/argumentGroups"
import { IArgumentGroup } from "api/types/argumentGroups"
import { IGetPlaybookResponse } from "api/types/playbooks"

import { moveArgumentGroup } from "services/argumentGroups"

import {
  useInvalidatingMutation,
  useOptimisticMutation,
  useUpdateMutation,
} from "utils/hooks/mutations"
import { replaceById } from "utils/mutationHelpers"

import { buildArgumentsKey } from "./argumentQueries"
import { buildPlaybookKey, setPlaybook } from "./playbookQueries"

const setArgumentGroups = (
  prevData: IGetPlaybookResponse,
  updateFn: (prevArgumentGroups: IArgumentGroup[]) => IArgumentGroup[]
) =>
  setPlaybook(prevData, (prevPlaybook) => ({
    ...prevPlaybook,
    argumentGroups: updateFn(prevPlaybook.argumentGroups),
  }))

const setArgumentGroup = (
  prevData: IGetPlaybookResponse,
  argumentGroup: IArgumentGroup
) =>
  setArgumentGroups(prevData, (prevArgumentGroups) =>
    replaceById(prevArgumentGroups, argumentGroup)
  )

export const useArgumentGroupUpdateMutation = (playbookId: string) =>
  useUpdateMutation(
    buildPlaybookKey(playbookId),
    apiUpdateArgumentGroup,
    (result, prevData: IGetPlaybookResponse | undefined) =>
      prevData && setArgumentGroup(prevData, result.argumentGroup)
  )

export const useArgumentGroupCreateMutation = (playbookId: string) =>
  useUpdateMutation(
    buildPlaybookKey(playbookId),
    apiCreateArgumentGroup,
    (result, prevData: IGetPlaybookResponse | undefined) =>
      prevData &&
      setArgumentGroups(prevData, (prevArgumentGroups) => [
        ...prevArgumentGroups,
        result.argumentGroup,
      ])
  )

export const useArgumentGroupDeleteMutation = (playbookId: string) =>
  useInvalidatingMutation(buildPlaybookKey(playbookId), apiDeleteArgumentGroup)

export const useArgumentGroupUpdatePositionMutation = (playbookId: string) =>
  useOptimisticMutation(
    buildPlaybookKey(playbookId),
    apiUpdateArgumentGroupPosition,
    (prevData: IGetPlaybookResponse | undefined, { id, relativeId }) =>
      prevData &&
      setArgumentGroups(prevData, (prevArgumentGroups) =>
        moveArgumentGroup(prevArgumentGroups, id, relativeId)
      )
  )

export const usePasteArgumentGroupsMutation = (
  playbookId: string,
  argumentTypeId: string
) => {
  const queryClient = useQueryClient()
  return useMutation(apiPasteArgumentGroups, {
    onSuccess: () => {
      queryClient.invalidateQueries(buildPlaybookKey(playbookId))
      queryClient.invalidateQueries(buildArgumentsKey(argumentTypeId))
    },
  })
}
