import { FormattedMessage } from "react-intl"
import { Navigate, Route, Routes } from "react-router-dom"

import CRMLayout from "./CRM/CRMLayout"
import MeetingTypesIndex from "./CRM/MeetingTypes/MeetingTypesIndex"
import MeetingsIndex from "./CRM/Meetings/MeetingsIndex"

export default function CRM() {
  return (
    <CRMLayout
      tab={
        <Routes>
          <Route
            path="meetings/*"
            element={<FormattedMessage id="navigation.meetings" />}
          />

          <Route
            path="meeting_types/*"
            element={<FormattedMessage id="navigation.meetingTypes" />}
          />
        </Routes>
      }
    >
      <Routes>
        <Route path="meetings/*" element={<MeetingsIndex />} />
        <Route path="meeting_types/*" element={<MeetingTypesIndex />} />
        <Route path="/" element={<Navigate to="meetings" replace />} />
      </Routes>
    </CRMLayout>
  )
}
