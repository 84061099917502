import { ICRMDeal, ICRMDealSearchResult } from "api/types/CRMDeals"
import { CRM_PROPERTY_DEAL_OBJECT_TYPE } from "api/types/CRMProperties"
import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IPickedSegment } from "api/types/argumentSegments"

import { filterNonArchived } from "./archivable"
import { findArgumentSegmentsByValue } from "./argumentSegmentations"

export function buildCrmDealSearchResult(
  crmDeal: ICRMDeal
): ICRMDealSearchResult {
  return {
    id: crmDeal.externalId,
    name: crmDeal.name,
    // TODO see how to better handle this
    createdAt: Date.now().toString(10),
  }
}

export function findCrmDealProperty(crmDeal: ICRMDeal, propertyId: string) {
  return crmDeal.properties.find((property) => property.id === propertyId)
}

export function selectSegmentIdsMatchingDealProperties(
  crmDeal: ICRMDeal,
  argumentSegmentations: IArgumentSegmentation[]
): IPickedSegment[] {
  return argumentSegmentations.flatMap((segmentation) => {
    if (!segmentation.crmProperty) return []
    if (segmentation.crmProperty.objectType !== CRM_PROPERTY_DEAL_OBJECT_TYPE)
      return []
    const crmDealProperty = findCrmDealProperty(
      crmDeal,
      segmentation.crmProperty.externalId
    )
    if (!crmDealProperty) return []
    const segments = findArgumentSegmentsByValue(
      filterNonArchived(segmentation.argumentSegments),
      [crmDealProperty.value].flat()
    )

    return [
      {
        argumentSegmentationId: segmentation.id,
        argumentSegmentIds: segments.map((segment) => segment.id),
      },
    ]
  })
}
