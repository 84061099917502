import { BoxProps } from "@mui/material/Box"
import styled from "styled-components"

import Stack from "ds/Stack"

import Header from "./Header"
import { ContentContainer, PageContainer } from "./SimpleLayout"

interface SidebarContainerProps {
  $responsive: boolean
}

const SidebarContainer = styled.div<SidebarContainerProps>`
  overflow-y: auto;
  padding: ${({ theme }) => theme.spacing(4)};
  height: 100%;
  width: 260px;
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints.down("md")} {
    display: ${({ $responsive }) => ($responsive ? "none" : "initial")};
  }
`

interface Props {
  header?: React.ReactNode
  children?: React.ReactNode
  sidebar?: React.ReactNode
  contentProps?: Omit<BoxProps, "children">
  responsive?: boolean
}

// If `responsive` is true, the sidebar is not displayed for screens smaller than desktop.
// Below desktop, it is the responsibility of the caller to display the sidebar
//   elsewhere (probably as a menu in the header)
export default function SidebarLayout({
  header,
  children,
  sidebar,
  contentProps,
  responsive = false,
}: Props) {
  return (
    <PageContainer>
      {header || <Header />}
      <Stack direction="row" sx={{ flexGrow: 1, minHeight: 0 }} spacing={2}>
        <SidebarContainer $responsive={responsive}>{sidebar}</SidebarContainer>
        <ContentContainer {...contentProps}>{children}</ContentContainer>
      </Stack>
    </PageContainer>
  )
}
