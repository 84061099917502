import { SxProps, Theme, useTheme } from "@mui/material"
import MuiTextField, { TextFieldProps } from "@mui/material/TextField"
import { forwardRef } from "react"

import mergeSx from "utils/mergeSx"

export type { TextFieldProps }

export default forwardRef(function TextField(
  props: TextFieldProps,
  ref: React.Ref<HTMLDivElement>
) {
  const theme = useTheme()
  return (
    <MuiTextField
      {...props}
      sx={mergeSx(textFieldCustomizations(theme), props.sx)}
      ref={ref}
    />
  )
})

export function textFieldCustomizations(theme: Theme): SxProps<Theme> {
  return {
    "& .MuiInputBase-root.MuiInputBase-adornedStart": {
      pl: 1.5,
    },
    "& .MuiInputBase-root.MuiInputBase-adornedEnd": {
      pr: 1.5,
    },
    "& .MuiInputBase-input": {
      px: 1.5,
      py: 0.75,
      "&.MuiInputBase-inputAdornedStart": {
        pl: 0,
      },
      "&.MuiInputBase-inputAdornedEnd": {
        pr: 0,
      },
      "&::placeholder": {
        color: theme.palette.text.secondary,
        opacity: 1,
      },
      ...theme.typography.smNormal,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      "-webkit-text-fill-color": theme.palette.text.secondary,
    },
    "& .MuiOutlinedInput-notchedOutline, & .Mui-disabled .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.palette.grey[300],
      },
    "& .MuiInputLabel-root": {
      lineHeight: theme.typography.smNormal.lineHeight,
      fontSize: theme.typography.smNormal.fontSize,
      transform: "translate(14px,6px) scale(1)",
      color: theme.palette.text.secondary,
      "&.MuiInputLabel-shrink": {
        transform: "translate(14px,-9px) scale(0.857)",
      },
    },
    "& .MuiFormHelperText-root": {
      lineHeight: theme.typography.smNormal.lineHeight,
      fontSize: theme.typography.smNormal.fontSize,
    },
  }
}
