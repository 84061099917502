import axios from "./axios"
import {
  ICreateIntegrationParams,
  IGetCurrentIntegrationParams,
  IIntegration,
} from "./types/integrations"

export const apiFindOrCreateIntegration = (
  params: ICreateIntegrationParams,
  { signal }: { signal?: AbortSignal }
): Promise<IIntegration> =>
  axios
    .post("api/crm/integrations/find_or_create", params, { signal })
    .then((res) => res.data)

export const apiGetIntegration = (
  integrationId: string
): Promise<IIntegration> =>
  axios.get(`api/crm/integrations/${integrationId}`).then((res) => res.data)

export const apiCRMGetCurrentIntegration = (
  params: IGetCurrentIntegrationParams,
  {
    signal,
  }: {
    signal?: AbortSignal
  }
): Promise<IIntegration | null> =>
  axios
    .get(`api/crm/integrations/current`, { params, signal })
    .then((res) => res.data)

export const apiRevokeIntegration = (
  integrationId: string
): Promise<undefined> =>
  axios
    .post(`api/crm/integrations/${integrationId}/revoke`)
    .then((res) => res.data)
