import { useIsSmallerThanMD } from "ds/Media"
import Stack from "ds/Stack"

interface FiltersContainerProps {
  children: React.ReactNode
}

export default function FiltersContainer({ children }: FiltersContainerProps) {
  const isSmallerThanMD = useIsSmallerThanMD()

  return (
    <Stack
      direction={isSmallerThanMD ? "column" : "row"}
      flexWrap="wrap"
      spacing={1}
      alignItems="center"
    >
      {children}
    </Stack>
  )
}
