import {
  AutoformatPlugin,
  createAutoformatPlugin,
} from "@udecode/plate-autoformat"

import { MyEditor, MyValue } from "../types"
import { autoformatRules } from "./autoformatPlugin/autoformatRules"

export const autoformatPlugin = createAutoformatPlugin<
  AutoformatPlugin<MyValue, MyEditor>,
  MyValue,
  MyEditor
>({
  options: {
    enableUndoOnDelete: true,
    rules: autoformatRules,
  },
})
