import { FormattedMessage } from "react-intl"

import { IBusinessUnit } from "api/types/businessUnits"

import Button from "ds/Button"
import Checkbox from "ds/Checkbox"
import FormControlLabel from "ds/FormControlLabel"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

interface Props {
  businessUnits: IBusinessUnit[]
  selectedBusinessUnitIds: string[]
  allSelected: boolean
  onSelectionChange: (id: string) => void
  onSelectAllChange: (selected: boolean) => void
  onSubmit: () => void
  disabled: boolean
}

export default function BusinessUnitsSelector({
  businessUnits,
  selectedBusinessUnitIds,
  allSelected,
  onSelectionChange,
  onSelectAllChange,
  onSubmit,
  disabled,
}: Props) {
  return (
    <Stack spacing={2}>
      <Typography variant="smNormal">
        <FormattedMessage id="organizations.users.invite.businessUnitsSelection.title" />
      </Typography>

      <Stack>
        {businessUnits.map((businessUnit) => (
          <FormControlLabel
            key={businessUnit.id}
            control={
              <Checkbox
                checked={selectedBusinessUnitIds.includes(businessUnit.id)}
                onChange={() => onSelectionChange(businessUnit.id)}
              />
            }
            label={businessUnit.name}
          />
        ))}

        <FormControlLabel
          control={
            <Checkbox
              checked={allSelected}
              onChange={(e) => onSelectAllChange(e.target.checked)}
            />
          }
          label={
            <FormattedMessage id="organizations.users.invite.businessUnitsSelection.selectAll" />
          }
        />
      </Stack>
      <Button color="primary" onClick={onSubmit} disabled={disabled}>
        <FormattedMessage id="organizations.users.invite.submit" />
      </Button>
    </Stack>
  )
}
