import { FormattedMessage } from "react-intl"

import MenuItem from "ds/MenuItem"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentsDuplicateMutation } from "components/App/Playbook/queries/argumentQueries"

interface Props {
  argumentIds: string[]
  onSuccess: () => void
}

export default function DuplicateArguments(props: Props) {
  const { activeTypeId } = usePlaybook()
  const duplicateMutation = useArgumentsDuplicateMutation(activeTypeId)

  function onClick() {
    duplicateMutation.mutate(props.argumentIds, { onSuccess: props.onSuccess })
  }

  return (
    <MenuItem onClick={onClick} disabled={duplicateMutation.isLoading}>
      <FormattedMessage id="playbookEdit.argument.duplicate" />
    </MenuItem>
  )
}
