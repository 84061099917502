import { FormattedMessage } from "react-intl"

import { IOrganizationMembershipRole } from "api/types/organizationMemberships"

import Button from "ds/Button"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

import OrganizationRoleSelect from "./OrganizationRoleSelect"

interface Props {
  organizationId: string
  email: string
  onEmailChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  role: IOrganizationMembershipRole
  onRoleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  submitDisabled: boolean
  onSubmit: () => void
}

export default function RoleAndEmailForm({
  organizationId,
  email,
  onEmailChange,
  role,
  onRoleChange,
  submitDisabled,
  onSubmit,
}: Props) {
  return (
    <Stack spacing={2}>
      <TextField
        id={`invite-user-email-${organizationId}`}
        label={
          <FormattedMessage
            id="organizations.users.field.email"
            defaultMessage="Email"
          />
        }
        value={email}
        onChange={onEmailChange}
      />

      <OrganizationRoleSelect
        id={`invite-user-role-${organizationId}`}
        value={role}
        onChange={onRoleChange}
      />

      <Button color="primary" disabled={submitDisabled} onClick={onSubmit}>
        <FormattedMessage id="organizations.users.invite.submit" />
      </Button>
    </Stack>
  )
}
