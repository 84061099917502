import { FormattedMessage, IntlShape, useIntl } from "react-intl"

import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import { MyValue } from "ds/RichTextNew/types"
import Typography from "ds/Typography"

interface ArgumentTitleProps {
  title: MyValue | null
  rawTitle: string | null
  richTextProps: Partial<RichTextProps>
  richText?: boolean
}

export default function ArgumentTitle({
  title,
  richTextProps,
  rawTitle,
  richText = true,
}: ArgumentTitleProps) {
  const intl = useIntl()
  return (
    <Typography component="div" variant="smNormal" minWidth={0}>
      {richText ? (
        <RichTextEditor
          {...richTextProps}
          value={title || getDefaultTitle(intl)}
          readOnly
        />
      ) : (
        <Typography variant="smSemi" component="div">
          {rawTitle || (
            <FormattedMessage id="playbookEdit.argument.defaultTitle" />
          )}
        </Typography>
      )}
    </Typography>
  )
}

function getDefaultTitle(intl: IntlShape) {
  return buildNodeFromText(
    intl.formatMessage({
      id: "playbookEdit.argument.defaultTitle",
    }),
    { bold: true }
  )
}
