import { useState } from "react"
import { FormattedMessage } from "react-intl"

import Alert from "ds/Alert"
import Breadcrumbs from "ds/Breadcrumbs"
import Loader from "ds/Loader"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import { useBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"

import MeetingTypeModal from "./MeetingTypeModal"
import MeetingTypesTable from "./MeetingTypesTable"
import NewMeetingType from "./NewMeetingType"
import { useMeetingTypesQuery } from "./meetingTypeQueries"

function NoResults() {
  return (
    <Alert severity="info">
      <FormattedMessage
        id="meetingTypes.results.none"
        defaultMessage="No results"
      />
    </Alert>
  )
}

export default function MeetingTypesIndex() {
  const businessUnit = useBusinessUnit()

  const [meetingTypeId, setMeetingTypeId] = useState<String | undefined>(
    undefined
  )

  const {
    isLoading,
    isError,
    error,
    data: meetingTypes,
  } = useMeetingTypesQuery(businessUnit.id)

  const meetingTypeToEdit =
    meetingTypes &&
    meetingTypeId &&
    meetingTypes.find((meetingType) => meetingType.id === meetingTypeId)

  if (isLoading) return <Loader />
  if (isError) return <UncontrolledError error={error} />
  return (
    <Stack sx={{ p: 2 }} spacing={2} maxWidth="800px">
      <Breadcrumbs>
        <Typography variant="smMed">{businessUnit.name}</Typography>
        <Typography variant="smMed">
          <FormattedMessage id="navigation.meetingTypes" />
        </Typography>
      </Breadcrumbs>

      <Alert severity="info">
        <FormattedMessage id="meetingTypes.info" />
      </Alert>

      <NewMeetingType
        businessUnitId={businessUnit.id}
        onSuccess={(meetingTypeId) => setMeetingTypeId(meetingTypeId)}
      />

      {meetingTypes.length ? (
        <MeetingTypesTable
          meetingTypes={meetingTypes}
          onRowClick={(meetingTypeId) => setMeetingTypeId(meetingTypeId)}
        />
      ) : (
        <NoResults />
      )}

      {meetingTypeToEdit && (
        <MeetingTypeModal
          meetingType={meetingTypeToEdit}
          businessUnitId={businessUnit.id}
          onClose={() => setMeetingTypeId(undefined)}
        />
      )}
    </Stack>
  )
}
