import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { createPath, useNavigate } from "react-router-dom"

import IconButton from "ds/IconButton"
import Tooltip from "ds/Tooltip"

import {
  EditModeToggleIcon,
  useEditModeToggle,
  useEditModeTogglelabel,
} from "components/App/Playbook/useEditModeToggle"
import { useConfig } from "components/Config"

import { getTopMostVisibleArgumentCardId } from "../../playbookScrollRestore"

export default function EditModeToggle() {
  const { editMode, toggleEditModeLocation } = useEditModeToggle()
  const label = useEditModeTogglelabel(editMode)
  const config = useConfig()
  const navigate = useNavigate()

  return (
    <Tooltip title={label}>
      <IconButton
        aria-label={label}
        onClick={(e) => {
          e.preventDefault()
          if (IS_CHROME_EXTENSION) {
            window.open(
              `${config.appUrl}${createPath(toggleEditModeLocation)}`,
              "_blank"
            )
          } else {
            navigate({
              ...toggleEditModeLocation,
              hash: getTopMostVisibleArgumentCardId(),
            })
          }
        }}
      >
        <EditModeToggleIcon
          sx={{ fill: (theme) => theme.palette.common.white }}
          editMode={editMode}
        />
      </IconButton>
    </Tooltip>
  )
}
