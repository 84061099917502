import { useLayoutEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

import { scrollToElement } from "utils/scrollToElement"

import { getArgumentGroupDOMId } from "./getArgumentGroupDOMId"

export const ARGUMENTS_SCROLL_CONTAINER_ID = "arguments-scroll-container"
export const SCROLL_RESTORE_ANCHOR = "arguments-scroll-anchor"

export function usePlaybookScrollRestore() {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const location = useLocation()
  useLayoutEffect(() => {
    if (!location.hash) {
      if (!scrollContainerRef.current) return
      scrollContainerRef.current.scrollTop = 0
    } else {
      scrollToElement(
        location.hash.slice(1), // Remove #
        { block: "start", behavior: "auto" }
      )
    }
  }, [location.hash])
  return scrollContainerRef
}

export function getTopMostVisibleArgumentCardId(): string | undefined {
  const container = document.getElementById(ARGUMENTS_SCROLL_CONTAINER_ID)
  if (!container) return
  const containerTop = container.getBoundingClientRect().top
  const elements = container.getElementsByClassName(SCROLL_RESTORE_ANCHOR)
  const positions = Array.from(elements)
    .map((element) => {
      return {
        id: element.id,
        top: element.getBoundingClientRect().top,
      }
    })
    .sort((a, b) => a.top - b.top)
  // 5px safety net
  return positions.find((position) => position.top >= containerTop - 5)?.id
}

// Scroll to a group only if the group is not visible, or if the top of the group is above the scroll container
export function scrollToGroupIfNecessary(argumentGroupId: string) {
  setTimeout(() => {
    const groupsScrollContainer = document
      .getElementById(ARGUMENTS_SCROLL_CONTAINER_ID)
      ?.querySelector("[data-overlayscrollbars-contents]")
    const group = document.getElementById(
      getArgumentGroupDOMId(argumentGroupId)
    )
    if (!group || !groupsScrollContainer) return
    if (groupsScrollContainer.scrollTop > group.offsetTop) {
      scrollToElement(group.id, {
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      })
    }
  }, 200)
}
