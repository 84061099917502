import MuiTableHead, { TableHeadProps } from "@mui/material/TableHead"
import { forwardRef } from "react"

export type { TableHeadProps }

export default forwardRef(function TableHead(
  props: TableHeadProps,
  ref: React.Ref<HTMLTableSectionElement>
) {
  return <MuiTableHead {...props} ref={ref} />
})
