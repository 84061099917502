import { useIntl } from "react-intl"

import { IMeetingArgumentNoteEmail } from "api/types/meetingArgumentNotes"
import { IMeetingNoteConfiguration } from "api/types/meetingNoteConfigurations"

import Stack from "ds/Stack"
import TextField from "ds/TextField"

import AdditionalInfo from "components/common/AdditionalInfo"

interface Props {
  disabled?: boolean
  note: IMeetingArgumentNoteEmail
  onValueChange: (value: string) => void
  onAdditionalInfoChange: (newValue: string) => void
  configuration: IMeetingNoteConfiguration
  isSaved: boolean
  error: boolean
}
export default function EmailForm({
  disabled = false,
  note,
  onValueChange,
  configuration,
  onAdditionalInfoChange,
  isSaved,
  error,
}: Props) {
  const intl = useIntl()
  const { hasAdditionalInfo } = configuration
  return (
    <Stack direction="row">
      <TextField
        fullWidth
        placeholder={intl.formatMessage({
          id: "playbookEdit.argumentAnswer.form.typeSelect.email",
        })}
        size="small"
        type="text"
        value={note.value || ""}
        onChange={(e) => onValueChange(e.target.value)}
        disabled={disabled}
        helperText={
          error
            ? intl.formatMessage({
                id: "playbookEdit.argumentAnswer.form.emailBuilder.description",
              })
            : ""
        }
        error={error}
      />

      {hasAdditionalInfo && (
        <AdditionalInfo
          value={note.additionalInfo || ""}
          onChange={onAdditionalInfoChange}
          isSaved={isSaved}
          disabled={disabled}
        />
      )}
    </Stack>
  )
}
