import CancelIcon from "@mui/icons-material/Cancel"
import SendIcon from "@mui/icons-material/Send"
import styled from "styled-components"

import ButtonGroup from "ds/ButtonGroup"
import IconButton from "ds/IconButton"

const Group = styled(ButtonGroup)`
  position: absolute;
  right: 0;
  bottom: 0;
  color: ${({ theme }) => theme.palette.grey[500]};
`

interface Props {
  onSubmit: React.MouseEventHandler<HTMLButtonElement>
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  submitDisabled: boolean
}

export default function CommentFormActions({
  onSubmit,
  onCancel,
  submitDisabled,
}: Props) {
  return (
    <Group variant="text" aria-label="text button group" color="inherit">
      {!!onCancel && (
        <IconButton aria-label="cancel" onClick={onCancel}>
          <CancelIcon fontSize="small" />
        </IconButton>
      )}

      <IconButton
        aria-label="send"
        onClick={onSubmit}
        disabled={submitDisabled}
      >
        <SendIcon fontSize="small" />
      </IconButton>
    </Group>
  )
}
