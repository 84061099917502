import { ToggleMarkPlugin } from "@udecode/plate"
import { createItalicPlugin } from "@udecode/plate-basic-marks"

import Italic from "../components/Italic"
import { MyEditor, MyValue } from "../types"

export const italicPlugin = createItalicPlugin<
  ToggleMarkPlugin,
  MyValue,
  MyEditor
>({ component: Italic })
