import AddIcon from "@mui/icons-material/Add"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import Button from "ds/Button"
import { BasicErrorSnack } from "ds/Snackbar"

import { useMeetingTypeCreateMutation } from "./meetingTypeQueries"

interface Props {
  businessUnitId: string
  onSuccess: (meetingTypeId: string) => void
}

export default function NewMeetingType({ businessUnitId, onSuccess }: Props) {
  const intl = useIntl()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const createMutation = useMeetingTypeCreateMutation(businessUnitId)

  const create = () => {
    createMutation.mutate(
      {
        businessUnitId,
        name: intl.formatMessage({
          id: "meetingTypes.name",
          defaultMessage: "Name",
        }),
      },
      {
        onSuccess: ({ crmMeetingType }) => onSuccess(crmMeetingType.id),

        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        sx={{ alignSelf: "start" }}
        onClick={create}
        disabled={createMutation.isLoading}
      >
        <FormattedMessage
          id="meetingTypes.create"
          defaultMessage="Create template"
        />
      </Button>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </>
  )
}
