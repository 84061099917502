import MoreVert from "@mui/icons-material/MoreVert"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import IconButton from "ds/IconButton"
import Menu from "ds/Menu"
import Modal from "ds/Modal"
import { AlertSnackbar } from "ds/Snackbar"

import useAnchorEl from "utils/hooks/useAnchorEl"

import ArgumentTypesForm from "../ArgumentTypesForm"
import CopyArgumentTypes from "./CopyArgumentTypes"
import CopyArgumentTypesSelector from "./CopyArgumentTypesSelector"
import EditArgumentTypes from "./EditArgumentTypes"
import PasteInArgumentTypes from "./PasteInArgumentTypes"

export default function ArgumentTypeActions() {
  const intl = useIntl()

  const [anchorEl, actionsMenuIsOpen, openActionsMenu, closeActionsMenu] =
    useAnchorEl()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
  const [copySuccessSnackOpen, setCopySuccessSnackOpen] = useState(false)
  const [pasteErrorSnackOpen, setPasteErrorSnackOpen] = useState(false)
  const [
    pasteEmptyOrInconsistentSnackOpen,
    setPasteEmptyOrInconsistentSnackOpen,
  ] = useState(false)
  const [pasteSuccessSnackOpen, setPasteSuccessSnackOpen] = useState(false)

  const label = intl.formatMessage({
    id: "playbookEdit.argumentTypes.actions",
    defaultMessage: "Argument types actions",
  })

  return (
    <>
      <IconButton onClick={openActionsMenu} aria-label={label}>
        <MoreVert fontSize="small" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={actionsMenuIsOpen}
        onClose={closeActionsMenu}
      >
        <EditArgumentTypes
          onClick={() => {
            closeActionsMenu()
            setIsEditModalOpen(true)
          }}
        />

        <CopyArgumentTypes
          onClick={() => {
            closeActionsMenu()
            setIsCopyModalOpen(true)
          }}
        />

        <PasteInArgumentTypes
          onSuccess={() => {
            closeActionsMenu()
            setPasteSuccessSnackOpen(true)
          }}
          onError={() => setPasteErrorSnackOpen(true)}
          onInconsistency={() => setPasteEmptyOrInconsistentSnackOpen(true)}
        />
      </Menu>

      <Modal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        sizeVariant="medium"
      >
        <ArgumentTypesForm />
      </Modal>

      <Modal
        isOpen={isCopyModalOpen}
        data-testid="copy-argument-types-modal"
        onClose={() => setIsCopyModalOpen(false)}
        sizeVariant="medium"
      >
        <CopyArgumentTypesSelector
          onSuccess={() => {
            setIsCopyModalOpen(false)
            setCopySuccessSnackOpen(true)
          }}
        />
      </Modal>

      <AlertSnackbar
        severity="success"
        open={copySuccessSnackOpen}
        onClose={() => setCopySuccessSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.copy.success" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="error"
        open={pasteErrorSnackOpen}
        onClose={() => setPasteErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.error.api" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="error"
        open={pasteEmptyOrInconsistentSnackOpen}
        onClose={() => setPasteEmptyOrInconsistentSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.error.emptyOrInconsitent" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="success"
        open={pasteSuccessSnackOpen}
        onClose={() => setPasteSuccessSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.success" />
      </AlertSnackbar>
    </>
  )
}
