import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt"
import styled from "styled-components"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IArgumentSegment } from "api/types/argumentSegments"
import { IArgument, IArgumentSegmentRule } from "api/types/arguments"

import Tooltip from "ds/Tooltip"

import { useArgumentSegmentName } from "components/App/Playbook/ArgumentSegmentation/ArgumentSegmentName"
import SegmentRuleOperator from "components/App/Playbook/PlaybookEdit/ArgumentSegmentRules/SegmentRuleOperator"

import {
  filterArgumentSegments,
  getSegmentRule,
} from "services/argumentSegmentRules"

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 0;
  width: 100%;
`

const TextContainer = styled.div`
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

interface Props {
  argument: IArgument
  argumentSegmentation: IArgumentSegmentation
}

function getSegmentNames(
  argumentSegmentation: IArgumentSegmentation,
  segmentRule: IArgumentSegmentRule,
  getSegmentName: (segment: IArgumentSegment) => string
) {
  return (
    filterArgumentSegments(argumentSegmentation, segmentRule)
      .map(getSegmentName)
      .join(", ") || "-"
  )
}

export default function SegmentRuleDisplay({
  argument,
  argumentSegmentation,
}: Props) {
  const segmentRule = getSegmentRule(argument, argumentSegmentation)
  const getSegmentName = useArgumentSegmentName()

  if (segmentRule.operator === "ALL") {
    return (
      <Container>
        <TextContainer>
          <SegmentRuleOperator operator={segmentRule.operator} />
        </TextContainer>
      </Container>
    )
  }

  if (segmentRule.operator === "ONLY") {
    const segmentNames = getSegmentNames(
      argumentSegmentation,
      segmentRule,
      getSegmentName
    )
    return (
      <Tooltip
        title={segmentNames}
        disableHoverListener={segmentNames.length < 20}
      >
        <Container>
          <TextContainer>{segmentNames}</TextContainer>
        </Container>
      </Tooltip>
    )
  }

  if (segmentRule.operator === "EXCEPT") {
    const segmentNames = getSegmentNames(
      argumentSegmentation,
      segmentRule,
      getSegmentName
    )
    return (
      <Tooltip
        title={
          <div>
            <SegmentRuleOperator operator={segmentRule.operator} />
            &nbsp;
            {segmentNames}
          </div>
        }
      >
        <Container>
          <DoNotDisturbAltIcon fontSize="inherit" />
          &nbsp;
          <TextContainer>{segmentNames}</TextContainer>
        </Container>
      </Tooltip>
    )
  }

  return <>-</>
}
