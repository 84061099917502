import { FormattedMessage } from "react-intl"

import Breadcrumbs from "ds/Breadcrumbs"
import Section from "ds/Section"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import ArchiveBusinessUnit from "./ArchiveBusinessUnit"
import BusinessUnitNameForm from "./BusinessUnitNameForm"
import { useBusinessUnit } from "./BusinessUnitProvider"

export default function BusinessUnitSettings() {
  const businessUnit = useBusinessUnit()

  return (
    <Stack sx={{ p: 2 }} spacing={2} maxWidth="800px">
      <Breadcrumbs>
        <Typography variant="smMed">{businessUnit.name}</Typography>
        <Typography variant="smMed">
          <FormattedMessage id="businessUnits.settings.title" />
        </Typography>
      </Breadcrumbs>

      <Section>
        <BusinessUnitNameForm businessUnit={businessUnit} />
      </Section>

      <Section>
        <ArchiveBusinessUnit businessUnit={businessUnit} />
      </Section>
    </Stack>
  )
}
