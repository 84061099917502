import { useQuery } from "@tanstack/react-query"

import {
  apiArchivePlaybook,
  apiCreatePlaybook,
  apiGetImportablePlaybooks,
  apiImportPlaybook,
  apiRestorePlaybook,
  apiUpdatePlaybook,
} from "api/playbooks"

import { buildOrganizationKey } from "components/App/Organization/organizationQueries"

import { useInvalidatingMutation } from "utils/hooks/mutations"

export const useBusinessUnitPlaybookUpdateMutation = (organizationId: string) =>
  useInvalidatingMutation(
    buildOrganizationKey(organizationId),
    apiUpdatePlaybook
  )

export const useBusinessUnitPlaybookCreateMutation = (organizationId: string) =>
  useInvalidatingMutation(
    buildOrganizationKey(organizationId),
    apiCreatePlaybook
  )

function buildImportablePlaybooksKey() {
  return ["playbooks", { importable: true }]
}

export function useImportablePlaybooksQuery() {
  return useQuery(buildImportablePlaybooksKey(), apiGetImportablePlaybooks)
}

export function useImportPlaybookMutation(organizationId: string) {
  return useInvalidatingMutation(
    buildOrganizationKey(organizationId),
    apiImportPlaybook
  )
}

export function useArchivePlaybookMutation(organizationId: string) {
  return useInvalidatingMutation(
    buildOrganizationKey(organizationId),
    apiArchivePlaybook
  )
}

export function useRestorePlaybookMutation(organizationId: string) {
  return useInvalidatingMutation(
    buildOrganizationKey(organizationId),
    apiRestorePlaybook
  )
}
