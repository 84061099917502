import LinkIcon from "@mui/icons-material/Link"
import { useLinkToolbarButton, useLinkToolbarButtonState } from "@udecode/plate"
import { useIntl } from "react-intl"

import PillIconButton from "./PillIconButton"

interface Props {
  disabled: boolean
}

export default function LinkButton({ disabled }: Props) {
  const intl = useIntl()
  const state = useLinkToolbarButtonState()
  const { props } = useLinkToolbarButton(state)

  return (
    <PillIconButton
      label={intl.formatMessage({ id: "richtext.link" })}
      disabled={disabled}
      pressed={props.pressed}
      onMouseDown={(e) => e.preventDefault()}
      onClick={props.onClick}
    >
      <LinkIcon sx={{ height: "16px", width: "16px" }} />
    </PillIconButton>
  )
}
