import { TComboboxItem } from "@udecode/plate-combobox"
import {
  ELEMENT_MENTION,
  ELEMENT_MENTION_INPUT,
  MentionPlugin,
  createMentionPlugin,
} from "@udecode/plate-mention"

import { IUser } from "api/types/users"

import MentionElement from "../components/MentionElement"
import MentionInputElement from "../components/MentionInputElement"
import { MENTION_ALL, MentionItemData, MyEditor, MyValue } from "../types"

export const mentionPlugin = createMentionPlugin<
  MentionPlugin,
  MyValue,
  MyEditor
>()

export const mentionComponents = {
  [ELEMENT_MENTION]: MentionElement,
  [ELEMENT_MENTION_INPUT]: MentionInputElement,
}

export function mentioneesToMentionables(
  mentionees: IUser[]
): TComboboxItem<MentionItemData>[] {
  return (
    mentionees
      .map(mentioneeToMentionable)
      // No need to translate at that point since we control the rendering
      .concat({ key: MENTION_ALL, text: MENTION_ALL, data: MENTION_ALL })
  )
}

function mentioneeToMentionable(
  mentionee: IUser
): TComboboxItem<MentionItemData> {
  return {
    key: mentionee.id,
    text: mentionee.handle,
    data: mentionee,
  }
}
