import mimeLite from "mime/lite"

import { CloudAttachmentProps } from "./CloudAttachment/CloudAttachmentProps"
import CloudAudioAttachment from "./CloudAttachment/CloudAudioAttachment"
import CloudGenericAttachment from "./CloudAttachment/CloudGenericAttachment"
import CloudImageAttachment from "./CloudAttachment/CloudImageAttachment"
import CloudVideoAttachment from "./CloudAttachment/CloudVideoAttachment"

export default function CloudAttachment(props: CloudAttachmentProps) {
  const mime = mimeLite.getType(props.element.url.split("?")[0])
  const mimeType = mime && mime.split("/")?.[0]

  switch (mimeType) {
    case "image":
      return <CloudImageAttachment {...props} />
    case "video":
      return <CloudVideoAttachment {...props} />
    case "audio":
      return <CloudAudioAttachment {...props} />
    default:
      return <CloudGenericAttachment {...props} />
  }
}
