import { IArgument, IArgumentKind } from "api/types/arguments"

import Badge from "ds/Badge"

import VoteControls, {
  Votes,
} from "components/App/Playbook/Argument/VoteControls"

import CRMPropertyTooltip from "../CRMPropertyTooltip"
import AnswerIndicator from "./AnswerIndicator"
import CheckboxIndicator, { BaseCheckboxIndicator } from "./CheckboxIndicator"
import InformationIndicator from "./InformationIndicator"
import ReferenceIndicator from "./ReferenceIndicator"
import TalkingPointIndicator from "./TalkingPointIndicator"
import TipIndicator from "./TipIndicator"

interface Props {
  argument: IArgument
  disabled?: boolean
  showMandatoryAnswerIndicator?: boolean
}

export default function ArgumentKindIndicator({
  argument,
  disabled = false,
  showMandatoryAnswerIndicator = false,
}: Props) {
  switch (argument.kind) {
    case "TIP":
      return <TipIndicator />
    case "ANSWER":
      return (
        <Badge
          sx={{
            "& .MuiBadge-badge": { top: 8, right: 4 },
          }}
          badgeContent={
            argument?.meetingNoteConfiguration?.crmProperty && (
              <CRMPropertyTooltip
                crmProperty={argument.meetingNoteConfiguration.crmProperty}
              />
            )
          }
        >
          <AnswerIndicator
            showMandatoryAnswerIndicator={showMandatoryAnswerIndicator}
          />
        </Badge>
      )
    case "INFO":
      return <InformationIndicator />
    case "REFERENCE":
      return <ReferenceIndicator />
    case "TALKING_POINT":
      return <TalkingPointIndicator />
    case "VOTE":
      return <VoteControls argument={argument} disabled={disabled} />
    case "CHECKBOX":
      return <CheckboxIndicator argument={argument} disabled={disabled} />
    default:
      return <TalkingPointIndicator />
  }
}

// Not interactive
export function BaseKindIndicator({ kind }: { kind: IArgumentKind }) {
  switch (kind) {
    case "TIP":
      return <TipIndicator />
    case "ANSWER":
      return <AnswerIndicator />
    case "INFO":
      return <InformationIndicator />
    case "REFERENCE":
      return <ReferenceIndicator />
    case "TALKING_POINT":
      return <TalkingPointIndicator />
    case "VOTE":
      return <Votes />
    case "CHECKBOX":
      return <BaseCheckboxIndicator />
    default:
      return <TalkingPointIndicator />
  }
}
