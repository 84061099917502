// Usage
// import useToggle from 'utils/hooks/useToggle'
//
// const TogglingButton = () => {
//   const [on, { toggle }] = useToggle(false)
//   return <button onClick={toggle}>{on ? 'ON' : 'OFF'}</button>
// }
import { useState } from "react"

export default function useToggle(initialValue: boolean = false) {
  const [value, setValue] = useState<boolean>(initialValue)
  const toggle = () => setValue((prevValue) => !prevValue)
  const toggleOn = () => setValue(true)
  const toggleOff = () => setValue(false)
  return [value, { toggle, toggleOn, toggleOff, setValue }] as const
}
