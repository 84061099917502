import styled from "styled-components"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import {
  IArgumentSegmentRule,
  IArgumentSegmentRuleMultiple,
} from "api/types/arguments"

import SegmentRule from "./SegmentRule"

const Grid = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1)};
  grid-template-columns: minmax(auto, 200px) auto 1fr;
  align-items: center;
`

interface Props {
  argumentSegmentations: IArgumentSegmentation[]
  segmentRules: (IArgumentSegmentRule | IArgumentSegmentRuleMultiple)[]
  setSegmentRule: (
    rule: IArgumentSegmentRule | IArgumentSegmentRuleMultiple
  ) => React.Dispatch<
    React.SetStateAction<IArgumentSegmentRule | IArgumentSegmentRuleMultiple>
  >
}

export default function SegmentRules({
  argumentSegmentations,
  segmentRules,
  setSegmentRule,
}: Props) {
  return (
    <Grid>
      {argumentSegmentations.map((argumentSegmentation) => {
        const segmentRule = segmentRules.find(
          (x) => x.argumentSegmentationId === argumentSegmentation.id
        )
        if (!segmentRule) return null
        return (
          <SegmentRule
            key={argumentSegmentation.id}
            argumentSegmentation={argumentSegmentation}
            segmentRule={segmentRule}
            setSegmentRule={setSegmentRule(segmentRule)}
          />
        )
      })}
    </Grid>
  )
}
