import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"

import { useConfig } from "components/Config"

import { getMeetingsPath } from "services/meetings"

interface Props {
  businessUnitId: string
  onClick: React.MouseEventHandler
}

export default function MeetingsMenuItem({ businessUnitId, onClick }: Props) {
  const config = useConfig()
  const to = getMeetingsPath(businessUnitId)
  return (
    <MenuItem
      component={IS_CHROME_EXTENSION ? "a" : Link}
      to={IS_CHROME_EXTENSION ? undefined : to}
      href={IS_CHROME_EXTENSION ? `${config.appUrl}${to}` : undefined}
      target={IS_CHROME_EXTENSION ? "_blank" : undefined}
      onClick={onClick}
    >
      <ListItemIcon>
        <MeetingRoomIcon />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="navigation.meetings" />
      </ListItemText>
    </MenuItem>
  )
}
