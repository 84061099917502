import Check from "@mui/icons-material/Check"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import Tooltip from "ds/Tooltip"

const StyledCheck = styled(Check)`
  vertical-align: text-bottom;
  fill: ${({ theme }) => theme.palette.success.main};
`
export default function CommentResolvedCue() {
  return (
    <Tooltip
      title={
        <FormattedMessage
          id="argumentComments.resolved"
          defaultMessage="Resolved"
        />
      }
    >
      <StyledCheck fontSize="small" />
    </Tooltip>
  )
}
