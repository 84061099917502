import { useState } from "react"
import { FormattedMessage } from "react-intl"

import Breadcrumbs from "ds/Breadcrumbs"
import Loader from "ds/Loader"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import { useBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"

import useSearchParam from "utils/hooks/useSearchParam"

import MeetingsFilters, {
  IMeetingsFilters,
} from "./MeetingsIndex/MeetingFilters"
import MeetingModal from "./MeetingsIndex/MeetingModal"
import MeetingsIndexResults from "./MeetingsIndex/MeetingsIndexResults"
import { useMeetingsInfiniteQuery } from "./meetingQueries"

const filtersToParams = (filters: IMeetingsFilters) => ({
  crmCompanyId: filters.companyFilter?.id,
  crmContactId: filters.contactFilter?.id,
  crmMeetingTypeId: filters.meetingTypeFilter?.id,
  userId: filters.userFilter?.id,
})

export default function MeetingsIndex() {
  const businessUnit = useBusinessUnit()

  const [filters, setFilters] = useState<IMeetingsFilters>({})
  const [meetingIdParam, setMeetingIdParam] =
    useSearchParam<string>("meetingId")
  const {
    isLoading,
    data,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useMeetingsInfiniteQuery(businessUnit.id, filtersToParams(filters))

  if (isError) return <UncontrolledError error={error} />

  const meetings = isLoading
    ? []
    : data.pages.map((page) => page.meetings).flat()

  return (
    <>
      <Stack sx={{ p: 2 }} spacing={2}>
        <Breadcrumbs>
          <Typography variant="smMed">{businessUnit.name}</Typography>
          <Typography variant="smMed">
            <FormattedMessage id="navigation.meetings" />
          </Typography>
        </Breadcrumbs>

        <MeetingsFilters
          businessUnitId={businessUnit.id}
          filters={filters}
          onChange={setFilters}
          disabled={isLoading}
        />

        {isLoading ? (
          <Loader />
        ) : (
          <MeetingsIndexResults
            meetings={meetings}
            showMeeting={setMeetingIdParam}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={!!hasNextPage}
            fetchNextPage={fetchNextPage}
          />
        )}
      </Stack>

      {meetingIdParam && (
        <MeetingModal
          onClose={() => setMeetingIdParam(undefined)}
          meetingId={meetingIdParam.toString()}
        />
      )}
    </>
  )
}
