import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import Loader from "ds/Loader"
import { AlertSnackbar, BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import UncontrolledError from "ds/UncontrolledError"

import { filterNonArchived } from "services/archivable"
import {
  filterUnactiveSegmentationsWithArgumentsHavingAnySegmentRuleActive,
  selectSegmentationsWithNoArgumentsHavingActiveSegmentRule,
} from "services/argumentSegmentations"
import {
  getArgumentType,
  isReferencesArgumentType,
} from "services/argumentTypes"
import { filterArguments, getNbArgumentsBySegment } from "services/arguments"

import { useApplicationIntegrationQuery } from "../Organization/ApplicationsIntegration/applicationIntegrationsQueries"
import ArgumentGroups from "./ArgumentGroups"
import ArgumentSegmentations from "./ArgumentSegmentations"
import ImportReferencesCSV from "./ArgumentType/ImportReferencesCSV"
import ReferencesRefreshButton from "./ArgumentType/ReferencesRefreshButton"
import ReferencesSetupButton from "./ArgumentType/ReferencesSetupButton"
import ArgumentTypes from "./ArgumentTypes"
import { usePlaybook } from "./PlaybookProvider"
import References from "./References"
import ViewSettingsControls from "./ViewSettings/ViewSettingsControls"
import { useArgumentsQuery } from "./queries/argumentQueries"
import { useReferencesConfigurationSyncMutation } from "./queries/referencesConfigurationQueries"

function SegmentationsRow({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      flexShrink={0}
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      direction="row"
    >
      {children}
    </Stack>
  )
}

interface PlaybookContentProps {
  arguments: IArgument[]
}

function PlaybookContent({ arguments: _arguments }: PlaybookContentProps) {
  const [referencesWarningSnackOpen, setReferencesWarningSnackOpen] =
    useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const {
    playbook,
    activeTypeId,
    editMode,
    segmentFilters,
    pickSegments,
    viewSettings,
  } = usePlaybook()

  const activeType = getArgumentType(playbook, activeTypeId)

  const {
    isLoading,
    isError,
    data: currentCRMIntegration,
  } = useApplicationIntegrationQuery(playbook.businessUnitId)

  const showReferencesImport =
    editMode && activeType && isReferencesArgumentType(activeType)

  const filteredArguments = filterArguments(
    _arguments,
    playbook.argumentSegmentations,
    {
      typeId: activeTypeId,
      segmentFilters,
      showArchived: viewSettings.showArchived,
    }
  )

  const nonArchivedArgumentsForActiveTab = filterArguments(
    _arguments,
    playbook.argumentSegmentations,
    {
      typeId: activeTypeId,
      segmentFilters: {},
      showArchived: viewSettings.showArchived,
    }
  )

  const nonArchivedSegmentations = filterNonArchived(
    playbook.argumentSegmentations
  )

  const nbArgumentsBySegment = getNbArgumentsBySegment(
    _arguments,
    playbook.argumentSegmentations,
    viewSettings.showArchived,
    undefined,
    segmentFilters
  )

  const activeArgumentType = getArgumentType(playbook, activeTypeId)
  const isActiveReferencesArgumentType =
    !!activeArgumentType && isReferencesArgumentType(activeArgumentType)

  const unactiveSegmentationsWithArgumentsHavingAnySegmentRuleActive =
    filterUnactiveSegmentationsWithArgumentsHavingAnySegmentRuleActive(
      nonArchivedSegmentations,
      filteredArguments,
      segmentFilters
    )

  const segmentationsWithNoArgumentsHavingActiveSegmentRule =
    selectSegmentationsWithNoArgumentsHavingActiveSegmentRule(
      nonArchivedSegmentations,
      nonArchivedArgumentsForActiveTab
    )

  const [selectedCrmPropertyId, setSelectedPropertyId] = useState<
    string | null
  >(activeType?.referencesConfiguration?.crmPropertyId || null)

  const syncReferencesMutation =
    useReferencesConfigurationSyncMutation(activeTypeId)

  function syncReferences(
    argumentTypeId: string,
    crmPropertyId?: string | null
  ) {
    syncReferencesMutation.mutate(
      { argumentTypeId, crmPropertyId },
      {
        onSuccess: () => {
          setReferencesWarningSnackOpen(false)
          setSuccessSnackOpen(true)
        },
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <Stack
      sx={{
        height: "100%",
        flexGrow: 1,
        minWidth: 0,
        flexBasis: "500px",
        maxWidth: editMode ? "1050px" : "850px",
        margin: "auto",
        px: editMode ? "30px" : 0,
      }}
    >
      <Stack px={2} py={1} spacing={1}>
        <ArgumentTypes />

        <SegmentationsRow>
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            <ArgumentSegmentations
              argumentSegmentations={nonArchivedSegmentations}
              segmentFilters={segmentFilters}
              onPickArgumentSegment={pickSegments}
              nbArgumentsBySegment={nbArgumentsBySegment}
              forceShowNbOfArgumentsBySegment={isActiveReferencesArgumentType}
              editMode={editMode}
              unactiveSegmentationsWithArgumentsHavingAnySegmentRuleActive={
                unactiveSegmentationsWithArgumentsHavingAnySegmentRuleActive
              }
              segmentationsWithNoEffect={
                segmentationsWithNoArgumentsHavingActiveSegmentRule
              }
            />
          </Box>

          <Stack direction="row">{editMode && <ViewSettingsControls />}</Stack>
        </SegmentationsRow>
      </Stack>

      {showReferencesImport && (
        <Stack direction="row" px={2} pb={1} spacing={1}>
          {currentCRMIntegration && (
            <ReferencesSetupButton
              businessUnitId={playbook.businessUnitId}
              disabled={
                isLoading || isError || syncReferencesMutation.isLoading
              }
              onCrmPropertyIdChange={setSelectedPropertyId}
              crmPropertyId={selectedCrmPropertyId}
              onSync={() => {
                syncReferences(activeTypeId, selectedCrmPropertyId)
                setReferencesWarningSnackOpen(true)
              }}
            />
          )}

          {currentCRMIntegration && selectedCrmPropertyId && (
            <ReferencesRefreshButton
              disabled={
                isLoading || isError || syncReferencesMutation.isLoading
              }
              onSync={() => {
                syncReferences(activeTypeId)
                setReferencesWarningSnackOpen(true)
              }}
            />
          )}

          <ImportReferencesCSV argumentTypeId={activeTypeId} />
        </Stack>
      )}

      {isActiveReferencesArgumentType ? (
        <References _arguments={filteredArguments} />
      ) : (
        <ArgumentGroups arguments={filteredArguments} />
      )}

      <AlertSnackbar
        severity="success"
        open={referencesWarningSnackOpen}
        onClose={() => setReferencesWarningSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.argumentTypes.references.configure.warning" />
      </AlertSnackbar>

      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </Stack>
  )
}

export default function LoadedPlaybookContent() {
  const { activeTypeId } = usePlaybook()
  const argumentsQuery = useArgumentsQuery(activeTypeId)

  if (argumentsQuery.isLoading) return <Loader />
  if (argumentsQuery.isError)
    return <UncontrolledError error={argumentsQuery.error} />

  return <PlaybookContent arguments={argumentsQuery.data} />
}
