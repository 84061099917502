import {
  useMarkToolbarButton,
  useMarkToolbarButtonState,
} from "@udecode/plate-common"

import PillIconButton, { PillIconButtonProps } from "./PillIconButton"

interface MarkButtonProps extends Omit<PillIconButtonProps, "onMouseDown"> {
  clear?: string | string[] | undefined
  nodeType: string
}

export default function MarkButton({
  clear,
  nodeType,
  ...rest
}: MarkButtonProps) {
  const state = useMarkToolbarButtonState({ clear, nodeType })
  const { props } = useMarkToolbarButton(state)

  return <PillIconButton {...props} {...rest} />
}
