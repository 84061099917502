import axios from "./axios"
import {
  IDashboardGetEmbedURLParams,
  IDashboardGetEmbedURLResponse,
} from "./types/dashboards"

export const apiGetDashboardEmbedURL = (
  params: IDashboardGetEmbedURLParams,
  { signal }: { signal?: AbortSignal }
): Promise<IDashboardGetEmbedURLResponse> =>
  axios
    .get("api/bi/dashboards/get_embed_url", {
      params,
      signal,
    })
    .then((res) => res.data)
