import { Box } from "@mui/material"

import {
  IMeeting,
  isMeetingLinkedToExternalCRM,
  isMeetingLinkedToExternalCRMCall,
} from "api/types/meetings"

import Button from "ds/Button"
import FormattedTime from "ds/FormattedTime"
import TableCell from "ds/TableCell"
import TableRow from "ds/TableRow"
import Tooltip from "ds/Tooltip"

import Avatar from "components/common/Avatar"
import IntegrationIcon from "components/common/IntegrationIcon"

import { getCRMMeetingCallUrl, getCRMMeetingUrl } from "utils/crmURLGetter"

interface Props {
  meeting: IMeeting
  onClick: React.MouseEventHandler<HTMLTableRowElement>
}

export default function MeetingTableRow({ meeting, onClick }: Props) {
  return (
    <TableRow onClick={onClick} hover sx={{ cursor: "pointer" }}>
      <TableCell>{meeting.crmCompany?.name}</TableCell>
      <TableCell>
        {meeting.crmContacts.map(({ name }) => name).join(", ")}
      </TableCell>
      <TableCell>{meeting.crmDeal?.name}</TableCell>
      <TableCell>{meeting.crmMeetingType?.name}</TableCell>
      <TableCell>
        <FormattedTime value={meeting.createdAt} format="longTime" />
      </TableCell>
      <TableCell onClick={(e) => e.stopPropagation()}>
        {(isMeetingLinkedToExternalCRM(meeting) ||
          isMeetingLinkedToExternalCRMCall(meeting)) && (
          <Button
            href={
              isMeetingLinkedToExternalCRM(meeting)
                ? getCRMMeetingUrl(meeting)
                : getCRMMeetingCallUrl(meeting)
            }
            target="_blank"
          >
            <Box display="flex" justifyContent="center">
              <IntegrationIcon
                integrationName={meeting.integrationName}
                style={{ width: "25px" }}
              />
            </Box>
          </Button>
        )}
      </TableCell>
      <TableCell align="right">
        <Tooltip
          title={<div>{meeting.user.name || meeting.user.email}</div>}
          placement="top"
        >
          <Box display="flex" justifyContent="end">
            <Avatar
              userName={meeting.user.name}
              userEmail={meeting.user.email}
              userDefaultAvatarColorCode={meeting.user.defaultAvatarColorCode}
              userAvatarUrl={meeting.user.thumbnailAvatarUrl}
            />
          </Box>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}
