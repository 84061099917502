export const PLAYBOOK_MEETING_CHANNEL = "PlaybookMeetingChannel" as const
const MEETING_CREATION = "MEETING_CREATION" as const
const MEETING_DELETION = "MEETING_DELETION" as const
const MEETING_UPDATE = "MEETING_UPDATE" as const

export interface PlaybookMeetingChannelSubscriptionParams {
  id: string
}

export type PlaybookMeetingChannelReceivedData =
  | PlaybookMeetingCreationEvent
  | PlaybookMeetingDeletionEvent
  | PlaybookMeetingUpdateEvent

type PlaybookMeetingCreationEvent = { action: typeof MEETING_CREATION }
type PlaybookMeetingDeletionEvent = { action: typeof MEETING_DELETION }
type PlaybookMeetingUpdateEvent = { action: typeof MEETING_UPDATE }
