import { Dictionary } from "lodash"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IPickedSegment } from "api/types/argumentSegments"

import addDefaultSegments, {
  SEGMENT_ALL,
  ensureValidSegments,
} from "services/argumentSegmentations"

import useSearchParam from "utils/hooks/useSearchParam"

export const segmentsFiltersKey = "segments"

export function useRawSegmentsParam() {
  return useSearchParam<Dictionary<string[]>>(segmentsFiltersKey)
}

export default function useSegmentsParam(
  argumentSegmentations: IArgumentSegmentation[]
) {
  const [segmentsParam, setSegmentsParam] = useRawSegmentsParam()

  const validSegments = ensureValidSegments(
    argumentSegmentations,
    segmentsParam
  )
  const validSegmentsWithDefault = addDefaultSegments(
    argumentSegmentations,
    validSegments
  )

  function pickSegments(pickedSegments: IPickedSegment[]) {
    let newSegments: Dictionary<string[]> = {}
    pickedSegments.forEach(({ argumentSegmentationId, argumentSegmentIds }) => {
      newSegments[argumentSegmentationId] =
        argumentSegmentIds.length > 0 ? argumentSegmentIds : [SEGMENT_ALL] // For some reason, we can't reset to an empty array, react-router-dom ignores the change, so we set it to ["ALL"], which correspnds to no valid segments, which has the same effect
    })
    setSegmentsParam({ ...validSegmentsWithDefault, ...newSegments })
  }
  return [validSegmentsWithDefault, pickSegments, setSegmentsParam] as const
}

export function isEmptySegments(segments: Dictionary<string[]>): boolean {
  return Object.values(segments).every((value) => value.length === 0)
}
