import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { IBusinessUnit } from "api/types/businessUnits"

import Button from "ds/Button"
import { BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import TextField from "ds/TextField"
import Typography from "ds/Typography"

import { useBusinessUnitUpdateMutation } from "./businessUnitQueries"

interface Props {
  businessUnit: IBusinessUnit
}

export default function BusinessUnitNameForm({ businessUnit }: Props) {
  const [name, setName] = useState(businessUnit.name || "")
  const intl = useIntl()

  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)

  const updateMutation = useBusinessUnitUpdateMutation(
    businessUnit.organizationId,
    businessUnit.id
  )

  const submit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    updateMutation.mutate(
      { id: businessUnit.id, businessUnit: { name } },
      {
        onSuccess: () => setSuccessSnackOpen(true),
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <form onSubmit={submit}>
      <Stack spacing={2}>
        <Typography variant="smSemi">
          <FormattedMessage id="businessUnits.settings.updateName" />
        </Typography>

        <TextField
          label={intl.formatMessage({
            id: "businessUnits.settings.name",
          })}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <Box>
          <Button
            color="success"
            type="submit"
            variant="contained"
            disabled={updateMutation.isLoading || !name}
          >
            <FormattedMessage id="common.submit" />
          </Button>
        </Box>
      </Stack>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
    </form>
  )
}
