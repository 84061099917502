import AddIcon from "@mui/icons-material/Add"
import BusinessIcon from "@mui/icons-material/Business"
import GroupsIcon from "@mui/icons-material/Groups"
import PersonIcon from "@mui/icons-material/Person"
import { Box, List } from "@mui/material"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  matchPath,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom"

import NavEntry from "ds/BasicNav/NavEntry"
import IconButton from "ds/IconButton"
import Stack from "ds/Stack"
import Tooltip from "ds/Tooltip"
import Typography from "ds/Typography"

import CreateBusinessUnitModal from "./CreateBusinessUnitModal"
import { useOrganization } from "./OrganizationProvider"
import BusinessUnitsNav from "./OrganizationSidebar/BusinessUnitsNav"

export default function OrganizationSidebar() {
  const navigate = useNavigate()
  const resolvedPath = useResolvedPath("*")
  const match = useMatch({ path: resolvedPath.pathname, end: true })
  const activeTab = match?.params["*"]
  const intl = useIntl()

  const [isCreateOpen, setCreateOpen] = useState(false)
  const organization = useOrganization()

  const businessUnitId =
    activeTab &&
    matchPath({ path: "bu/:businessUnitId/*" }, `/${activeTab}`)?.params
      ?.businessUnitId

  const [expandedBusinessUnitId, setExpandedBusinessUnitId] = useState<
    string | null
  >(businessUnitId || organization.businessUnits[0]?.id || null)

  return (
    <Stack>
      <List
        subheader={
          <Stack direction="row" spacing={1} alignItems="center">
            <BusinessIcon fontSize="small" />

            <Typography variant="baseSemi">
              <FormattedMessage id="navigation.organization" />
            </Typography>
          </Stack>
        }
      >
        <NavEntry
          onClick={() => navigate("users")}
          icon={PersonIcon}
          title={<FormattedMessage id="organizations.users.title" />}
          selected={activeTab === "users"}
        />
      </List>

      <List
        subheader={
          <Stack spacing={1} direction="row" alignItems="center">
            <GroupsIcon fontSize="small" />

            <Typography variant="baseSemi">
              <FormattedMessage id="organizations.businessUnits.title" />
            </Typography>

            <Tooltip
              title={intl.formatMessage({
                id: "organizations.businessUnits.actions.create",
              })}
            >
              <IconButton onClick={() => setCreateOpen(true)}>
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        }
      >
        <Box pl={1}>
          <BusinessUnitsNav
            activeTab={activeTab}
            expandedBusinessUnitId={expandedBusinessUnitId}
            setExpandedBusinessUnitId={setExpandedBusinessUnitId}
          />
        </Box>
      </List>

      <CreateBusinessUnitModal
        organizationId={organization.id}
        isOpen={isCreateOpen}
        onSuccess={(businessUnit) => {
          setCreateOpen(false)
          setExpandedBusinessUnitId(businessUnit.id)
        }}
        onClose={() => setCreateOpen(false)}
      />
    </Stack>
  )
}
