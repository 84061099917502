import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import ControlledError from "ds/ControlledError"

interface Props {
  email: string
}

export default function EmailNotVerified({ email }: Props) {
  return (
    <ControlledError showNav>
      <FormattedMessage
        id="authentication.email_not_verified"
        defaultMessage="You need to verify your email, an email has been sent to <b>{email}</b>"
        values={{
          email,
          b: (chunks) => (
            <Box component="span" sx={{ fontWeight: 500 }}>
              {chunks}
            </Box>
          ),
        }}
      />
    </ControlledError>
  )
}
