import styled from "styled-components"

import { IOrganization } from "api/types/organizations"

import NavMenu from "ds/BasicNav/NavMenu"

import Header from "components/layouts/Header"
import SidebarLayout from "components/layouts/SidebarLayout"

import OrganizationSidebar from "./OrganizationSidebar"

const SidebarContainer = styled.div`
  width: 260px;
  padding: ${({ theme }) => theme.spacing(2)};
`

interface Props {
  organization: IOrganization
  children: React.ReactNode
}

export default function OrganizationLayout({ organization, children }: Props) {
  const organizationSidebar = <OrganizationSidebar />
  const navMenu = (
    <NavMenu
      nav={<SidebarContainer>{organizationSidebar}</SidebarContainer>}
      responsive
      closeOnClick={false}
    />
  )

  const header = <Header title={organization.name} leftContent={navMenu} />

  return (
    <SidebarLayout
      sidebar={organizationSidebar}
      header={header}
      responsive
      contentProps={{ sx: { overflowY: "auto" } }}
    >
      {children}
    </SidebarLayout>
  )
}
