import styled from "styled-components"

import { IMeeting } from "api/types/meetings"

import { useIsSmallerThanMD } from "ds/Media"
import Stack from "ds/Stack"

import MeetingFab from "./MeetingFab"
import MeetingNotesFab from "./MeetingNotesFab"

const Container = styled(Stack)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: row;
  align-items: end;

  > :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`

interface Props {
  ongoingMeeting: IMeeting | null
}

export default function MeetingControls({ ongoingMeeting }: Props) {
  const isSmallerThanMD = useIsSmallerThanMD()

  return (
    <>
      {isSmallerThanMD && !!ongoingMeeting && (
        <Container sx={{ left: (theme) => theme.spacing(2) }}>
          <MeetingNotesFab ongoingMeeting={ongoingMeeting} />
        </Container>
      )}
      <Container sx={{ right: (theme) => theme.spacing(2) }}>
        <MeetingFab />
      </Container>
    </>
  )
}
