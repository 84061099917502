import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useIntl } from "react-intl"

import IconButton from "ds/IconButton"

interface Props {
  isCollapsed: boolean
  toggleCollapse: () => void
}

export default function ArgumentGroupCollapse({
  isCollapsed,
  toggleCollapse,
}: Props) {
  const intl = useIntl()

  const expandLabel = intl.formatMessage({
    id: "playbook.viewSettings.expandGroup",
    defaultMessage: "Expand group",
  })

  const collapseLabel = intl.formatMessage({
    id: "playbook.viewSettings.collapseGroup",
    defaultMessage: "Collapse group",
  })

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    toggleCollapse()
  }

  if (isCollapsed) {
    return (
      <IconButton aria-label={expandLabel} onClick={onClick}>
        <ExpandMoreIcon fontSize="small" />
      </IconButton>
    )
  } else {
    return (
      <IconButton aria-label={collapseLabel} onClick={onClick}>
        <ExpandLessIcon fontSize="small" />
      </IconButton>
    )
  }
}
