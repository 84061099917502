import CloseIcon from "@mui/icons-material/Close"
import { useIntl } from "react-intl"
import styled from "styled-components"

import IconButton from "ds/IconButton"

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const ClosingCrossContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(1)};
  right: ${({ theme }) => theme.spacing(1)};
  opacity: 0.5;
  transition: ${({ theme }) => theme.transitions.create("opacity")};

  &:hover {
    opacity: 1;
  }
`

export default function ClosingCross({ onClick }: Props) {
  const intl = useIntl()
  const label = intl.formatMessage({
    id: "common.close",
    defaultMessage: "Close",
  })
  return (
    <IconButton onClick={onClick} aria-label={label}>
      <CloseIcon fontSize="small" />
    </IconButton>
  )
}
