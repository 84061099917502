let mod = "^",
  alt = "⎇",
  shift = "⇧",
  enter = "↵",
  tab = "⇥",
  space = "␣"

if (
  "platform" in navigator &&
  (navigator.platform.indexOf("Mac") === 0 || navigator.platform === "iPhone")
) {
  mod = "⌘"
  alt = "⎇"
}

export { mod, alt, shift, tab, enter, space }
