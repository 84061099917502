import isArray from "lodash/isArray"
import isPlainObject from "lodash/isPlainObject"
import mapKeys from "lodash/mapKeys"
import mapValues from "lodash/mapValues"

// Same signature as mapKeys from lodash
// Iterates recursively over all the keys of the object and applies the iteratee
const deepMapKeys = (
  object: object,
  iteratee: (value: unknown, key: string) => string
): object => {
  if (isArray(object)) {
    return object.map((e) => deepMapKeys(e, iteratee))
  }
  if (!isPlainObject(object)) {
    return object
  }
  return mapValues(mapKeys(object, iteratee), (value) => {
    if (isPlainObject(value) || isArray(value)) {
      return deepMapKeys(value, iteratee)
    }
    return value
  })
}

export default deepMapKeys
