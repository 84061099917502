import { useIntl } from "react-intl"
import styled from "styled-components"

import { IArgumentGroup } from "api/types/argumentGroups"
import { IArgument } from "api/types/arguments"

import Checkbox from "ds/Checkbox"

import ArgumentGroupName from "components/App/Playbook/ArgumentGroup/ArgumentGroupName"

import ArgumentGroupActions from "./ArgumentGroupActions/ArgumentGroupActions"
import MatrixGrid from "./MatrixGrid"
import StickyCheckboxContainer from "./StickyCheckboxContainer"

const GroupNameContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-self: left;
  position: sticky;
  left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(4)}; // Floating actions
`

interface Props {
  argumentGroup: IArgumentGroup
  arguments: IArgument[]
  nbColumns: number
  nameContainerRef?: React.RefObject<HTMLDivElement> | null
  onBulkActionCheckboxClick: (_arguments: IArgument[]) => void
  isSelectedForBulkAction: boolean
}

export default function ArgumentGroupRow({
  argumentGroup,
  arguments: _arguments,
  nbColumns,
  nameContainerRef,
  onBulkActionCheckboxClick,
  isSelectedForBulkAction,
}: Props) {
  const intl = useIntl()

  const selectAllArgumentsInGroupLabel = intl.formatMessage({
    id: "playbookEdit.argument.selectAllInGroup",
    defaultMessage: "Select all arguments in group",
  })

  return (
    <MatrixGrid $nbColumns={nbColumns}>
      <GroupNameContainer ref={nameContainerRef}>
        <ArgumentGroupName argumentGroup={argumentGroup} />
      </GroupNameContainer>

      <ArgumentGroupActions
        arguments={_arguments}
        argumentGroup={argumentGroup}
      />

      <StickyCheckboxContainer>
        <Checkbox
          onChange={() => onBulkActionCheckboxClick(_arguments)}
          checked={isSelectedForBulkAction}
          inputProps={{
            "aria-label": selectAllArgumentsInGroupLabel,
          }}
        />
      </StickyCheckboxContainer>
    </MatrixGrid>
  )
}
