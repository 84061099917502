import { useTheme } from "@mui/material"
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input"
import { useIntl } from "react-intl"

export interface PhoneInputProps extends MuiTelInputProps {}

export function PhoneInput(props: PhoneInputProps) {
  const intl = useIntl()
  const defaultPlaceholder = intl.formatMessage({
    id: "phone.placeholder",
  })
  const theme = useTheme()

  return (
    <MuiTelInput
      {...props}
      sx={{
        "& .MuiOutlinedInput-input": {
          py: 0.75,
          ...theme.typography.smNormal,
          color: (theme) => theme.palette.text.secondary,
          "-webkit-text-fill-color": (theme) => theme.palette.text.secondary,
          "&::placeholder": {
            color: (theme) => theme.palette.text.secondary,
          },
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },
        "& .MuiOutlinedInput-root": {
          px: 0.75,
        },
        "& .MuiOutlinedInput-notchedOutline, & .Mui-disabled .MuiOutlinedInput-notchedOutline":
          {
            borderColor: (theme) => theme.palette.grey[300],
          },
        ...props.sx,
      }}
      placeholder={props.placeholder || defaultPlaceholder}
    />
  )
}
