import {
  useIndentListToolbarButton,
  useIndentListToolbarButtonState,
} from "@udecode/plate-indent-list"

import PillIconButton, { PillIconButtonProps } from "./PillIconButton"

interface ListToolbarButtonProps
  extends Omit<PillIconButtonProps, "onMouseDown"> {
  nodeType: string
}

export default function ListButton({
  nodeType,
  ...rest
}: ListToolbarButtonProps) {
  const state = useIndentListToolbarButtonState({ nodeType })
  const { props } = useIndentListToolbarButton(state)

  return <PillIconButton {...props} {...rest} />
}
