import { Box } from "@mui/material"

import { IArgumentSearchResult } from "api/types/arguments"

import MenuItem from "ds/MenuItem"
import MenuList from "ds/MenuList"

import DefaultTitle from "components/App/Playbook/Argument/DefaultTitle"

interface Props {
  results: IArgumentSearchResult[]
  onClickResult: (result: IArgumentSearchResult) => void
  disabled: boolean
}

export default function Results({ results, onClickResult, disabled }: Props) {
  return (
    <MenuList>
      {results.map((result) => (
        <MenuItem
          key={result.argument.id}
          onClick={() => onClickResult(result)}
          disabled={disabled}
        >
          <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {result.title || <DefaultTitle />}
          </Box>
        </MenuItem>
      ))}
    </MenuList>
  )
}
