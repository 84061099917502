import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { ELEMENT_TODO_LI } from "@udecode/plate"
import { useIntl } from "react-intl"

import {
  useBlockToolbarButton,
  useBlockToolbarButtonState,
} from "ds/RichTextNew/helpers"

import { space } from "../../hotkeys"
import PillIconButton from "./PillIconButton"

interface Props {
  disabled: boolean
}

export default function TodoListButton({ disabled }: Props) {
  const intl = useIntl()

  const state = useBlockToolbarButtonState({ nodeType: ELEMENT_TODO_LI })
  const { props } = useBlockToolbarButton(state)

  return (
    <PillIconButton
      label={intl.formatMessage({ id: "richtext.checkList" })}
      shortcut={["[]", space]}
      disabled={disabled}
      {...props}
    >
      <CheckBoxIcon sx={{ height: "16px", width: "16px" }} />
    </PillIconButton>
  )
}
