import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import { FormattedMessage } from "react-intl"

import BasicNav from "ds/BasicNav"

export default function CRMNav() {
  return (
    <BasicNav
      entries={[
        {
          title: <FormattedMessage id="navigation.meetings" />,
          href: "meetings",
          icon: MeetingRoomIcon,
        },
        {
          title: <FormattedMessage id="navigation.meetingTypes" />,
          href: "meeting_types",
          icon: AssignmentOutlinedIcon,
        },
      ]}
    />
  )
}
