import { FormattedMessage } from "react-intl"

import MenuItem from "ds/MenuItem"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentsRestoreMutation } from "components/App/Playbook/queries/argumentQueries"

interface Props {
  argumentIds: string[]
  onSuccess: () => void
}

export default function RestoreArguments(props: Props) {
  const { activeTypeId } = usePlaybook()
  const restoreMutation = useArgumentsRestoreMutation(activeTypeId)
  function onClick() {
    restoreMutation.mutate(props.argumentIds, { onSuccess: props.onSuccess })
  }

  return (
    <MenuItem
      onClick={onClick}
      disabled={restoreMutation.isLoading}
      sx={{ color: "error.main" }}
    >
      <FormattedMessage id="playbookEdit.argument.restore" />
    </MenuItem>
  )
}
