import { useMatch, useResolvedPath } from "react-router-dom"

// Gives the value of the parameter relative to the resolved react-router match
// For example:
//   - The URL is /organization/:organization_id/tab1
//   - The current resolved path is "/organization/:organizationId"
//   - useResolvedParameter() would return "tab1"
const useResolvedParameter = (): string | undefined => {
  const resolved = useResolvedPath(":resolvedParameter")
  const match = useMatch({ path: resolved.pathname, end: true })
  return match?.params?.resolvedParameter
}

export default useResolvedParameter
