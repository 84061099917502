import { FormattedMessage } from "react-intl"

import MenuItem from "ds/MenuItem"

import { copyArguments } from "services/copyPaste"

interface Props {
  argumentIds: string[]
  onSuccess: () => void
}

export default function CopyArguments({ argumentIds, onSuccess }: Props) {
  async function onClick() {
    copyArguments(argumentIds)
    onSuccess()
  }

  return (
    <MenuItem onClick={onClick}>
      <FormattedMessage id="playbookEdit.argument.copy" />
    </MenuItem>
  )
}
