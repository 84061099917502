import { FormattedMessage } from "react-intl"

import { IOrganizationPendingInvitation } from "api/types/organizationInvitations"

import MenuItem from "ds/MenuItem"

import { useInviteResendMutation } from "./organizationInvitationQueries"

interface Props {
  invitation: IOrganizationPendingInvitation
  onSuccess: () => void
  onError: () => void
}

export default function ResendInvitation({
  invitation,
  onSuccess,
  onError,
}: Props) {
  const resendMutation = useInviteResendMutation()

  const onClick = () =>
    resendMutation.mutate(invitation.id, {
      onSuccess: () => onSuccess(),
      onError: () => onError(),
    })

  return (
    <MenuItem onClick={onClick} disabled={resendMutation.isLoading}>
      <FormattedMessage
        id="organizations.users.invite.resend"
        defaultMessage="Resend"
      />
    </MenuItem>
  )
}
