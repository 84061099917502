import { Box } from "@mui/material"
import {
  AnyObject,
  Plate,
  PlateContent,
  PlateEditor,
  PlatePlugin,
  Value,
  withoutNormalizing,
} from "@udecode/plate-common"
import { forwardRef, useEffect, useMemo, useRef } from "react"
import styled from "styled-components"

import { useConfig } from "components/Config"

import AutoFocusEffect from "./AutoFocusEffect"
import { RichTextProps } from "./RichTextProps"
import { MentionCombobox } from "./components/MentionCombobox"
import Toolbar from "./components/Toolbar"
import { resetNodes } from "./helpers"
import { createAllPlugins } from "./plugins"
import { mentioneesToMentionables } from "./plugins/mentionPlugin"
import { toRawValue } from "./serialization"
import { MyEditor, MyValue } from "./types"

export default forwardRef<HTMLDivElement, RichTextProps>(
  function RichTextEditor(
    {
      readOnly = false,
      disabled = false,
      autoFocus,
      value,
      onChange,
      placeholder,
      mentionees = [],
      editableStyle,
      onKeyDown,
      editorRef,
      toolbarProps,
      className,
      id,
    },
    ref
  ) {
    const { uploadsHost } = useConfig()

    const plugins = useMemo(
      () =>
        createAllPlugins({ uploadsHost }) as PlatePlugin<
          AnyObject,
          Value,
          PlateEditor<Value>
        >[],
      [uploadsHost]
    )

    const innerEditorRef = useRef<MyEditor | null>(null)
    const boxRef = useRef<HTMLDivElement | null>(null)

    // Necessary to update the value when the update comes from outside
    useEffect(() => {
      if (!readOnly) return
      const editor = innerEditorRef.current
      if (!editor) return
      if (editor.children === value) return
      withoutNormalizing(editor, () => {
        resetNodes(editor, { nodes: value })
      })
    }, [value, readOnly])

    return (
      <Plate<MyValue, MyEditor>
        plugins={plugins}
        value={value}
        onChange={(newValue) => {
          if (!onChange) return
          if (newValue === value) return // Don't call onChange when only selection changed
          // Timeout to let React re-render the HTML value
          setTimeout(() => onChange(newValue, getRawValue(boxRef.current)), 0)
        }}
        readOnly={readOnly || disabled}
        editorRef={(editor) => {
          if (editorRef) editorRef.current = editor
          innerEditorRef.current = editor
        }}
      >
        <Box
          // Assign 2 refs at once
          ref={(element: HTMLDivElement) => {
            boxRef.current = element
            if (ref) {
              if (typeof ref === "function") {
                ref(element)
              } else {
                ref.current = element
              }
            }
          }}
          className={className}
          id={id}
        >
          <StyledPlateContent
            style={editableStyle}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
          />

          {!readOnly && autoFocus && <AutoFocusEffect />}

          {!readOnly && <Toolbar disabled={disabled} {...toolbarProps} />}

          {!readOnly && (
            <MentionCombobox items={mentioneesToMentionables(mentionees)} />
          )}
        </Box>
      </Plate>
    )
  }
)

const StyledPlateContent = styled(PlateContent)`
  outline: none;

  & [data-slate-placeholder] {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`

// To compute the raw value, we get the HTML of the editor, and convert it to text.
// We make sure to:
//   - Only consider the HTML of the editor itself
//   - Remove the placeholder
function getRawValue(element: HTMLDivElement | null): string {
  if (!element) return ""
  const editorElement = element
    .querySelector('[data-slate-editor="true"]')
    ?.cloneNode(true) as HTMLDivElement | null
  if (!editorElement) return ""
  editorElement
    .querySelectorAll('[data-slate-placeholder="true"]')
    .forEach((node) => node.remove())
  const html = editorElement.innerHTML || ""
  return toRawValue(html)
}
