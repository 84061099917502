import { ICRMContact, ICRMContactSearchResult } from "api/types/CRMContacts"
import { CRM_PROPERTY_CONTACT_OBJECT_TYPE } from "api/types/CRMProperties"
import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IPickedSegment } from "api/types/argumentSegments"

import { filterNonArchived } from "./archivable"
import { findArgumentSegmentsByValue } from "./argumentSegmentations"

export function buildCrmContactSearchResults(
  contacts: ICRMContact[]
): ICRMContactSearchResult[] {
  return contacts.map((contact) => buildCrmContactSearchResult(contact))
}

export function buildCrmContactSearchResult(
  crmContact: ICRMContact
): ICRMContactSearchResult {
  return {
    origin: "DB",
    id: crmContact.id,
    integrationName: crmContact.integrationName,
    name: crmContact.name,
    email: crmContact.email,
    primaryCrmCompany: crmContact.primaryCrmCompany,
    crmContact,
  }
}

export function findCrmContactProperty(
  crmContact: ICRMContact,
  propertyId: string
) {
  return crmContact.properties.find((property) => property.id === propertyId)
}

export function selectSegmentIdsMatchingContactProperties(
  crmContact: ICRMContact,
  argumentSegmentations: IArgumentSegmentation[]
): IPickedSegment[] {
  return argumentSegmentations.flatMap((segmentation) => {
    if (!segmentation.crmProperty) return []
    if (
      segmentation.crmProperty.objectType !== CRM_PROPERTY_CONTACT_OBJECT_TYPE
    )
      return []
    const crmContactProperty = findCrmContactProperty(
      crmContact,
      segmentation.crmProperty.externalId
    )
    if (!crmContactProperty) return []

    const segments = findArgumentSegmentsByValue(
      filterNonArchived(segmentation.argumentSegments),
      [crmContactProperty.value].flat()
    )
    return [
      {
        argumentSegmentationId: segmentation.id,
        argumentSegmentIds: segments.map((segment) => segment.id),
      },
    ]
  })
}
