import PlaybookHeader from "components/App/Playbook/PlaybookLayout/Header"
import SidebarLayout from "components/layouts/SidebarLayout"

import PlaybookEditNav from "./PlaybookEditNav"

interface Props {
  children: React.ReactNode
}

export default function PlaybookEditLayout({ children }: Props) {
  const playbookEditNav = <PlaybookEditNav />
  const header = <PlaybookHeader />

  return (
    <SidebarLayout
      header={header}
      sidebar={playbookEditNav}
      contentProps={{
        bgcolor: (theme) => theme.palette.background.paper2,
      }}
    >
      {children}
    </SidebarLayout>
  )
}
