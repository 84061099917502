import isEqual from "lodash/isEqual"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { ICRMMeetingType } from "api/types/CRMMeetingTypes"

import Button from "ds/Button"
import FormControl from "ds/FormControl"
import InputLabel from "ds/InputLabel"
import Modal from "ds/Modal"
import OutlinedInput from "ds/OutlinedInput"
import { SuffixRequiredCue } from "ds/RequiredCue"
import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import { MyValue } from "ds/RichTextNew/types"
import { BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import TextField from "ds/TextField"
import Typography from "ds/Typography"

import useConfirm from "utils/hooks/useConfirm"

import DeleteAction from "./MeetingTypeModal/DeleteAction"
import { useMeetingTypeUpdateMutation } from "./meetingTypeQueries"

interface Props {
  meetingType: ICRMMeetingType
  businessUnitId: string
  onClose: () => void
}

export default function MeetingTypeModal({
  meetingType,
  businessUnitId,
  onClose,
}: Props) {
  const intl = useIntl()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)
  const confirm = useConfirm({ messageKey: "leaveWithoutSaving" })

  const initialGeneralNoteTemplateValue =
    meetingType.generalNoteTemplate || buildNodeFromText("")
  const [generalNoteTemplateValue, setGeneralNoteTemplateValue] =
    useState<MyValue>(initialGeneralNoteTemplateValue)
  const [nameValue, setNameValue] = useState(meetingType.name || "")
  const [rawGeneralNoteTemplateValue, setRawGeneralNoteTemplateValue] =
    useState(meetingType.rawGeneralNoteTemplate || "")
  const updateMutation = useMeetingTypeUpdateMutation(businessUnitId)

  const isChanged = !isEqual(
    initialGeneralNoteTemplateValue,
    generalNoteTemplateValue
  )

  const safeCloseModal = () => (isChanged ? confirm() && onClose() : onClose())

  const onGeneralNoteTemplateChange = (
    newGeneralNoteTemplateValue: MyValue,
    newRawGeneralNoteTemplateValue: string
  ) => {
    setGeneralNoteTemplateValue(newGeneralNoteTemplateValue)
    setRawGeneralNoteTemplateValue(newRawGeneralNoteTemplateValue)
  }

  const submit = () =>
    updateMutation.mutate(
      {
        id: meetingType.id,
        name: nameValue,
        generalNoteTemplate: generalNoteTemplateValue,
        rawGeneralNoteTemplate: rawGeneralNoteTemplateValue,
      },
      {
        onSuccess: () => {
          setSuccessSnackOpen(true)
          onClose()
        },
        onError: () => setErrorSnackOpen(true),
      }
    )

  return (
    <Modal
      isOpen
      onClose={safeCloseModal}
      data-testid="edit-meetingType-modal"
      sizeVariant="medium"
    >
      <Stack spacing={2}>
        <Typography variant="lgSemi">
          <FormattedMessage id="meetingTypes.edit" />
        </Typography>

        <TextField
          label={
            <SuffixRequiredCue>
              <FormattedMessage id="meetingTypes.name" defaultMessage="Name" />
            </SuffixRequiredCue>
          }
          variant="outlined"
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
          autoFocus
        />

        <FormControl>
          <InputLabel shrink htmlFor="meeting-type-general-note-input">
            <FormattedMessage
              id="meetingTypes.generalNoteTemplate"
              defaultMessage="📝 General note template"
            />
          </InputLabel>

          <OutlinedInput
            id="meeting-type-general-note-input"
            // @ts-ignore // TODO RichText typing
            inputComponent={RichTextEditor}
            value={generalNoteTemplateValue}
            // @ts-ignore // TODO RichText typing
            onChange={onGeneralNoteTemplateChange}
            inputProps={{
              editableStyle: { height: "300px", overflowY: "auto" },
              checkListOption: true,
            }}
            sx={{
              "& .MuiOutlinedInput-input": {
                p: 1,
                pb: 0,
                flex: 1,
                height: "auto",
              },
            }}
            label={intl.formatMessage({
              id: "meetingTypes.generalNoteTemplate",
              defaultMessage: "📝 General note template",
            })}
            notched
          />
        </FormControl>

        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button color="neutral" variant="outlined" onClick={onClose}>
            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
          </Button>

          <DeleteAction
            businessUnitId={businessUnitId}
            meetingTypeId={meetingType.id}
            onClose={onClose}
          />

          <Button
            color="success"
            variant="contained"
            onClick={submit}
            disabled={updateMutation.isLoading || nameValue.length === 0}
          >
            <FormattedMessage id="common.submit" defaultMessage="Submit" />
          </Button>
        </Stack>

        <BasicErrorSnack
          open={errorSnackOpen}
          onClose={() => setErrorSnackOpen(false)}
        />

        <BasicSuccessSnack
          open={successSnackOpen}
          onClose={() => setSuccessSnackOpen(false)}
        />
      </Stack>
    </Modal>
  )
}
