import styled from "styled-components"

import OverlayScrollbarsComponent from "ds/OverlayScrollbarsComponent"

import MeetingForm from "./MeetingForm"

// Negative margin left to avoid duplicating the padding with the playbook
const Wrapper = styled(OverlayScrollbarsComponent)`
  overflow-y: auto;
  flex-basis: 350px;
  flex-grow: 1;
  flex-shrink: 0;
  padding: ${({ theme }) => theme.spacing(2)};
  margin-left: ${({ theme }) => theme.spacing(-2)};
`

export default function MeetingPanel() {
  return (
    <Wrapper
      data-testid="meeting-panel"
      defer
      options={{
        overflow: { y: "scroll", x: "hidden" },
      }}
    >
      <MeetingForm />
    </Wrapper>
  )
}
