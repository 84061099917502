import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress"

import CenteringContainer from "./CenteringContainer"

export default function Loader(props: CircularProgressProps) {
  return (
    <CenteringContainer>
      <CircularProgress {...props} />
    </CenteringContainer>
  )
}
