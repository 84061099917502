import { Box } from "@mui/material"
import { partition } from "lodash"
import { FormattedMessage } from "react-intl"

import { IBusinessUnitMembership } from "api/types/businessUnitMemberships"

import ConnectionMethodLogo from "ds/ConnectionMethodLogo"
import Paper from "ds/Paper"
import Table from "ds/Table"
import TableBody from "ds/TableBody"
import TableCell from "ds/TableCell"
import TableContainer from "ds/TableContainer"
import TableHead from "ds/TableHead"
import TableRow from "ds/TableRow"

import BusinessUnitMembershipDelete from "./BusinessUnitMembershipDelete"

interface Props {
  businessUnitMemberships: IBusinessUnitMembership[]
}

export default function BusinessUnitMembershipsTable({
  businessUnitMemberships,
}: Props) {
  const [activeMemberships, deactivatedMemberships] = partition(
    businessUnitMemberships,
    (membership) => membership.active
  )

  return (
    <TableContainer component={Paper} sx={{ maxWidth: 800, boxShadow: 6 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="businessUnits.users.fields.email" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="businessUnits.users.fields.name" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="businessUnits.users.fields.connectionMethod" />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {activeMemberships.map((businessUnitMembership) => (
            <TableRow key={businessUnitMembership.id}>
              <TableCell>{businessUnitMembership.user.email}</TableCell>
              <TableCell>{businessUnitMembership.user.name}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    "> svg": {
                      display: "block",
                      width: "32px",
                      height: "32px",
                    },
                  }}
                >
                  <ConnectionMethodLogo
                    connectionMethod={
                      businessUnitMembership.user.connectionMethod
                    }
                  />
                </Box>
              </TableCell>
              <TableCell>
                <BusinessUnitMembershipDelete
                  businessUnitMembership={businessUnitMembership}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableBody>
          {deactivatedMemberships.map((businessUnitMembership) => (
            <TableRow key={businessUnitMembership.id} sx={{ opacity: 0.5 }}>
              <TableCell>{businessUnitMembership.user.email}</TableCell>
              <TableCell>{businessUnitMembership.user.name}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    "> svg": {
                      display: "block",
                      width: "32px",
                      height: "32px",
                    },
                  }}
                >
                  <ConnectionMethodLogo
                    connectionMethod={
                      businessUnitMembership.user.connectionMethod
                    }
                  />
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
