import MuiPopover, {
  PopoverProps as MuiPopoverProps,
} from "@mui/material/Popover"
import { forwardRef } from "react"

export interface PopoverProps extends MuiPopoverProps {}

export default forwardRef(function Popover(
  props: PopoverProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiPopover {...props} ref={ref} />
})
