import MuiLink, { LinkProps } from "@mui/material/Link"
import { forwardRef } from "react"

export type { LinkProps }

export default forwardRef(function LinkRouterless(
  props: LinkProps,
  ref: React.Ref<HTMLAnchorElement>
) {
  return <MuiLink {...props} ref={ref} />
})
