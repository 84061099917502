import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined"
import SearchIcon from "@mui/icons-material/Search"
import InputAdornment from "@mui/material/InputAdornment"
import { useIntl } from "react-intl"

import TextField, { TextFieldProps } from "ds/TextField"

import IconButton from "./IconButton"

interface SearchInputProps {
  value: string
  onChange: TextFieldProps["onChange"]
  placeholder?: TextFieldProps["placeholder"]
  disabled?: boolean
  clear: () => void
}

function SearchInput({
  value,
  onChange,
  placeholder,
  disabled = false,
  clear,
}: SearchInputProps) {
  const intl = useIntl()
  const defaultPlaceholder = intl.formatMessage({
    id: "common.search",
    defaultMessage: "Type to search",
  })
  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder={placeholder || defaultPlaceholder}
      autoFocus
      fullWidth
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={clear}>
              <CancelOutlinedIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default SearchInput
