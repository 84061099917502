import { Auth0Provider, LogoutOptions, useAuth0 } from "@auth0/auth0-react"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { useNavigate } from "react-router-dom"

import { useConfig } from "components/Config"

interface Props {
  children: React.ReactNode
}

export default function AuthenticationProvider({ children }: Props) {
  const navigate = useNavigate()
  const config = useConfig()

  return (
    <Auth0Provider
      cacheLocation={IS_CHROME_EXTENSION ? "localstorage" : undefined}
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      audience={config.auth0.audience}
      redirectUri={
        IS_CHROME_EXTENSION ? window.location.href : window.location.origin
      }
      onRedirectCallback={(appState) => {
        // Return to the original pathname, before login, that was stored in the appState passed to Auth0
        navigate(appState?.returnTo || window.location.pathname)
      }}
      children={children}
    />
  )
}

export function useCustomLogout() {
  const { logout, buildLogoutUrl } = useAuth0()
  async function customLogout(options?: LogoutOptions) {
    logout({
      localOnly: IS_CHROME_EXTENSION,
      returnTo: window.location.origin,
      ...options,
    })
    if (IS_CHROME_EXTENSION) {
      window.open(buildLogoutUrl(options), "_blank")
    }
  }

  return customLogout
}
