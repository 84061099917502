import { Box, useTheme } from "@mui/material"
import { NoData } from "@udecode/plate"
import {
  ComboboxContentItemProps,
  ComboboxContentProps,
  ComboboxProps,
  comboboxActions,
  useActiveComboboxStore,
  useComboboxContent,
  useComboboxContentState,
  useComboboxControls,
  useComboboxItem,
  useComboboxSelectors,
} from "@udecode/plate-combobox"
import {
  useEditorRef,
  useEditorSelector,
  useEventEditorSelectors,
  usePlateSelectors,
} from "@udecode/plate-common"
import { createVirtualRef } from "@udecode/plate-floating"
import { useEffect, useState } from "react"

import Popover from "ds/Popover"

export function ComboboxItem<TData = NoData>({
  combobox,
  index,
  item,
  onRenderItem,
  ...rest
}: ComboboxContentItemProps<TData>) {
  const { props } = useComboboxItem({ item, index, combobox, onRenderItem })
  const theme = useTheme()

  return (
    <Box
      {...props}
      {...rest}
      sx={{
        position: "relative",
        cursor: "pointer",
        outline: "none",
        p: 0.5,
        transition: theme.transitions.create("backgroundColor"),
        ...theme.typography.smNormal,
        "&:hover, &[data-highlighted=true]": {
          backgroundColor: theme.palette.grey[100],
        },
      }}
    />
  )
}

export function ComboboxContent<TData = NoData>(
  props: ComboboxContentProps<TData>
) {
  const {
    component: Component,
    items,
    portalElement,
    combobox,
    onRenderItem,
  } = props

  const editor = useEditorRef()

  const filteredItems = useComboboxSelectors.filteredItems()
  const activeComboboxStore = useActiveComboboxStore()!

  const state = useComboboxContentState({ items, combobox })
  const { menuProps, targetRange } = useComboboxContent(state)
  // Without fallback, createVirtualRef raises
  const bodyRect = document.body.getBoundingClientRect()
  const virtualRef = createVirtualRef(editor, targetRange ?? undefined, {
    fallbackRect: bodyRect,
  })
  function getBoundingClientRect() {
    return virtualRef.current?.getBoundingClientRect() || bodyRect
  }

  const [open, setOpen] = useState(true) // TODO check if this is the way

  return (
    <Popover
      container={portalElement}
      open={open}
      onClose={() => setOpen(false)}
      anchorEl={{
        nodeType: 1,
        getBoundingClientRect,
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      disableAutoFocus // To allow typing below the popover
    >
      {Component ? Component({ store: activeComboboxStore }) : null}

      <Box {...menuProps}>
        {filteredItems.map((item, index) => (
          <ComboboxItem
            key={item.key}
            item={item}
            combobox={combobox}
            index={index}
            onRenderItem={onRenderItem}
          />
        ))}
      </Box>
    </Popover>
  )
}

export default function Combobox<TData = NoData>({
  id,
  trigger,
  searchPattern,
  onSelectItem,
  controlled,
  maxSuggestions,
  filter,
  sort,
  disabled: _disabled,
  ...props
}: ComboboxProps<TData>) {
  const storeItems = useComboboxSelectors.items()
  const disabled =
    _disabled ?? (storeItems.length === 0 && !props.items?.length)

  const focusedEditorId = useEventEditorSelectors.focus?.()
  const combobox = useComboboxControls()
  const activeId = useComboboxSelectors.activeId()
  const selectionDefined = useEditorSelector((editor) => !!editor.selection, [])
  const editorId = usePlateSelectors().id()

  useEffect(() => {
    comboboxActions.setComboboxById({
      id,
      trigger,
      searchPattern,
      controlled,
      onSelectItem,
      maxSuggestions,
      filter,
      sort,
    })
  }, [
    id,
    trigger,
    searchPattern,
    controlled,
    onSelectItem,
    maxSuggestions,
    filter,
    sort,
  ])

  if (
    !combobox ||
    !selectionDefined ||
    focusedEditorId !== editorId ||
    activeId !== id ||
    disabled
  ) {
    return null
  }

  return <ComboboxContent<TData> combobox={combobox} {...props} />
}
