import { FormattedMessage } from "react-intl"

import { IOrganizationMembership } from "api/types/organizationMemberships"

import useMe from "authentication/useMe"

import MenuItem from "ds/MenuItem"

import useConfirm from "utils/hooks/useConfirm"

import {
  useOrganizationMembershipDeactivateMutation,
  useOrganizationMembershipReactivateMutation,
} from "./organizationMembershipQueries"

interface Props {
  organizationMembership: IOrganizationMembership
  onChangeActivation: () => void
}

function Deactivate({ organizationMembership, onChangeActivation }: Props) {
  const user = useMe()
  const confirm = useConfirm()
  const deactivateMutation = useOrganizationMembershipDeactivateMutation(
    organizationMembership.organizationId
  )

  const cannotDisable =
    organizationMembership.role === "ADMIN" && !user.isSuperAdmin

  const onClick = () => {
    if (!confirm()) return
    deactivateMutation.mutate(organizationMembership.id, {
      onSuccess: () => onChangeActivation(),
    })
  }

  return (
    <MenuItem
      onClick={onClick}
      disabled={cannotDisable || deactivateMutation.isLoading}
      sx={{ color: "error.main" }}
    >
      <FormattedMessage
        id="organizations.users.actions.deactivate"
        defaultMessage="Deactivate"
      />
    </MenuItem>
  )
}

function Reactivate({ organizationMembership, onChangeActivation }: Props) {
  const reactivateMutation = useOrganizationMembershipReactivateMutation(
    organizationMembership.organizationId
  )
  const onClick = () =>
    reactivateMutation.mutate(organizationMembership.id, {
      onSuccess: () => onChangeActivation(),
    })

  return (
    <MenuItem onClick={onClick} disabled={reactivateMutation.isLoading}>
      <FormattedMessage
        id="organizations.users.actions.reactivate"
        defaultMessage="Reactivate"
      />
    </MenuItem>
  )
}

export default function OrganizationMembershipActivation({
  organizationMembership,
  onChangeActivation,
}: Props) {
  return organizationMembership.active ? (
    <Deactivate
      organizationMembership={organizationMembership}
      onChangeActivation={onChangeActivation}
    />
  ) : (
    <Reactivate
      organizationMembership={organizationMembership}
      onChangeActivation={onChangeActivation}
    />
  )
}
