import { Box } from "@mui/material"
import { PlateLeaf } from "@udecode/plate-common"

import { MyPlateLeafProps } from "../types"

export default function Underline({ children, ...props }: MyPlateLeafProps) {
  return (
    <PlateLeaf asChild {...props}>
      <Box component="span" sx={{ textDecoration: "underline" }}>
        {children}
      </Box>
    </PlateLeaf>
  )
}
