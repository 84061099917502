import axios from "./axios"
import {
  ICRMContactCreateParams,
  ICRMContactPullParams,
  ICRMContactPullResponse,
  ICRMContactResponse,
  ICRMContactSearchResult,
  ICRMContactsSearchParams,
} from "./types/CRMContacts"

export const apiSearchCRMContacts = (
  params: ICRMContactsSearchParams
): Promise<ICRMContactSearchResult[]> =>
  axios.post("api/crm/contacts/search", params).then((res) => res.data)

export const apiCreateCRMContact = (
  params: ICRMContactCreateParams
): Promise<ICRMContactResponse> =>
  axios.post("api/crm/contacts", params).then((res) => res.data)

export const apiPullCRMContacts = (
  params: ICRMContactPullParams
): Promise<ICRMContactPullResponse> =>
  axios.post("api/crm/contacts/pull", params).then((res) => res.data)
