// Same as `useState`, synced with a key in local storage
// Taken from https://usehooks.com/useLocalStorage/
import { SetStateAction, useCallback, useState } from "react"

export default function useLocalStorageState<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") return initialValue
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.error(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (setStateAction: SetStateAction<T>): void => {
      function saveInLS(value: T) {
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(value))
        }
      }
      try {
        // Allow value to be a function so we have same API as useState
        if (setStateAction instanceof Function) {
          setStoredValue((prev) => {
            const value = setStateAction(prev)
            saveInLS(value)
            return value
          })
        } else {
          const value = setStateAction
          setStoredValue(value)
          saveInLS(value)
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.error(error)
      }
    },
    [key]
  )
  return [storedValue, setValue] as const
}
