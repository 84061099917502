import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd"
import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"

import Loader from "ds/Loader"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import CrmPropertyRefresh from "components/App/CRM/properties/CrmPropertyRefresh"
import { useApplicationIntegrationQuery } from "components/App/Organization/ApplicationsIntegration/applicationIntegrationsQueries"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentSegmentationUpdatePositionMutation } from "components/App/Playbook/queries/argumentSegmentationQueries"

import { filterArchived, filterNonArchived } from "services/archivable"

import AddArgumentSegmentation from "./AddArgumentSegmentation"
import ArgumentSegmentationInput from "./ArgumentSegmentationInput"
import LinkToCrmPropertyExplanation from "./LinkToCrmPropertyExplanation"
import LinkToMeetingNoteConfigurationExplanation from "./LinkToMeetingNoteConfigurationExplanation"
import ShowArchivedToggle from "./ShowArchivedToggle"

export default function ArgumentSegmentationsForm() {
  const { playbook } = usePlaybook()
  const [showArchived, setShowArchived] = useState(false)
  const updatePositionMutation = useArgumentSegmentationUpdatePositionMutation(
    playbook.id
  )

  const argumentSegmentationsToShow = showArchived
    ? playbook.argumentSegmentations
    : filterNonArchived(playbook.argumentSegmentations)

  const hasArchived = filterArchived(playbook.argumentSegmentations).length > 0

  const integrationQuery = useApplicationIntegrationQuery(
    playbook.businessUnitId
  )

  // Special treatment for newly created segmentation
  const [
    lastCreatedArgumentSegmentationId,
    setLastCreatedArgumentSegmentationId,
  ] = useState<string | null>(null)

  if (integrationQuery.isLoading) return <Loader />
  if (integrationQuery.isError)
    return <UncontrolledError error={integrationQuery.error} />

  const crmIntegration = integrationQuery.data

  function onAddArgumentSegmentation(
    argumentSegmentation: IArgumentSegmentation
  ) {
    setLastCreatedArgumentSegmentationId(argumentSegmentation.id)
  }

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return
    }
    const argumentSegmentationId = result.draggableId
    const oldPosition = result.source.index
    const newPosition = result.destination.index
    if (oldPosition === newPosition) {
      return
    }
    const relativeArgumentSegmentation =
      argumentSegmentationsToShow[newPosition]
    updatePositionMutation.mutate({
      id: argumentSegmentationId,
      relativeId: relativeArgumentSegmentation.id,
    })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Stack spacing={2} alignItems="start">
        <Typography variant="lgSemi">
          <FormattedMessage id="playbookEdit.argumentSegmentations.edit" />
        </Typography>

        {crmIntegration && (
          <Stack direction="row" spacing={1} alignItems="start">
            <LinkToCrmPropertyExplanation />
            <Box>
              <CrmPropertyRefresh />
            </Box>
          </Stack>
        )}

        <LinkToMeetingNoteConfigurationExplanation />

        <Droppable droppableId={`argumentSegmentations-${playbook.id}`}>
          {(providedDroppable) => (
            <Stack
              spacing={2}
              ref={providedDroppable.innerRef}
              sx={{ width: "100%" }}
              {...providedDroppable.droppableProps}
            >
              {argumentSegmentationsToShow.map(
                (argumentSegmentation, index) => (
                  <Draggable
                    draggableId={argumentSegmentation.id}
                    index={index}
                    key={argumentSegmentation.id}
                  >
                    {(providedDraggable, dragSnapshot) => (
                      <ArgumentSegmentationInput
                        argumentSegmentation={argumentSegmentation}
                        providedDraggable={providedDraggable}
                        isDragging={dragSnapshot.isDragging}
                        isLastCreated={
                          lastCreatedArgumentSegmentationId ===
                          argumentSegmentation.id
                        }
                        crmIntegration={crmIntegration}
                      />
                    )}
                  </Draggable>
                )
              )}

              {providedDroppable.placeholder}
            </Stack>
          )}
        </Droppable>

        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          width="100%"
        >
          <Box>
            {hasArchived && (
              <ShowArchivedToggle
                checked={showArchived}
                onChange={(e) => setShowArchived(e.target.checked)}
              />
            )}
          </Box>

          <AddArgumentSegmentation
            onAddArgumentSegmentation={onAddArgumentSegmentation}
          />
        </Stack>
      </Stack>
    </DragDropContext>
  )
}
