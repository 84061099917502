import { Fragment } from "react"

import { IArgumentSegment } from "api/types/argumentSegments"

import ArgumentSegmentName from "components/App/Playbook/ArgumentSegmentation/ArgumentSegmentName"

interface Props {
  selectedSegmentIds: string[]
  argumentSegments: IArgumentSegment[]
}

export default function SelectedSegmentNames({
  selectedSegmentIds,
  argumentSegments,
}: Props) {
  return (
    <>
      {argumentSegments
        .filter((segment) => selectedSegmentIds.includes(segment.id))
        .map((argumentSegment, index) => (
          <Fragment key={argumentSegment.id}>
            {index > 0 && ", "}
            <ArgumentSegmentName argumentSegment={argumentSegment} />
          </Fragment>
        ))}
    </>
  )
}
