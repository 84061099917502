import {
  AnyObject,
  MARK_COLOR,
  getMarks,
  isHotkey,
  setMarks,
} from "@udecode/plate"
import { createFontColorPlugin } from "@udecode/plate-font"

import { AvailableTranslationKeys } from "translations/TranslationsProvider"

import { MyEditor, MyValue } from "../types"

export const fontColorPlugin = createFontColorPlugin<
  AnyObject,
  MyValue,
  MyEditor
>({
  handlers: {
    onKeyDown: (editor, plugin) => (e) => {
      if (e.defaultPrevented) return
      if (plugin.options.hotkey && isHotkey(plugin.options.hotkey, e)) {
        e.preventDefault()
        const marks = getMarks(editor) || {}
        setMarks(editor, {
          [MARK_COLOR]: marks[MARK_COLOR] ? undefined : FONT_COLORS[0].value,
        })
      }
    },
  },
  options: {
    hotkey: ["mod+shift+c"],
  },
})

// These colors were obtained by darkening the highlight colors by 40%
export const FONT_COLORS: {
  intlKey: AvailableTranslationKeys
  value: string
  isBrightColor: boolean
}[] = [
  {
    intlKey: "richtext.font.color.YELLOW",
    value: "rgb(198, 144, 6)",
    isBrightColor: true,
  },
  {
    intlKey: "richtext.font.color.GREEN",
    value: "rgb(66, 134, 49)",
    isBrightColor: true,
  },
  {
    intlKey: "richtext.font.color.PINK",
    value: "rgb(228, 8, 69)",
    isBrightColor: true,
  },
  {
    intlKey: "richtext.font.color.PURPLE",
    value: "rgb(181, 23, 225)",
    isBrightColor: true,
  },
  {
    intlKey: "richtext.font.color.BLUE",
    value: "rgb(9, 122, 252)",
    isBrightColor: false,
  },
  {
    intlKey: "richtext.font.color.GREY",
    value: "rgb(130, 130, 130)",
    isBrightColor: false,
  },
]
