import { useTheme } from "@mui/material"
import MuiRadio, { RadioProps } from "@mui/material/Radio"
import { forwardRef } from "react"

export type { RadioProps }

export default forwardRef(function Radio(
  props: RadioProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <MuiRadio
      icon={<UncheckedIcon disabled={props.disabled} />}
      checkedIcon={<CheckedIcon disabled={props.disabled} />}
      sx={{
        borderColor: (theme) => theme.palette.grey[400],
        borderWidth: "1px",
        p: 0.5,
      }}
      {...props}
      ref={ref}
    />
  )
})

function UncheckedIcon({ disabled }: { disabled?: boolean }) {
  const theme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        fill={disabled ? theme.palette.grey[100] : theme.palette.common.white}
      />
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        stroke={theme.palette.grey[400]}
      />
    </svg>
  )
}

function CheckedIcon({ disabled }: { disabled?: boolean }) {
  const theme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        fill={disabled ? theme.palette.grey[100] : theme.palette.common.white}
      />
      <circle
        cx="12"
        cy="12"
        r="5"
        fill={disabled ? theme.palette.grey[400] : theme.palette.primary.main}
      />
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        stroke={theme.palette.grey[400]}
      />
    </svg>
  )
}
