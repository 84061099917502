import {
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd"
import WebAssetOffIcon from "@mui/icons-material/WebAssetOff"
import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import { apiCreateLog } from "api/logs"
import { IArgumentGroup } from "api/types/argumentGroups"
import { IArgument } from "api/types/arguments"

import DragAndDropHandle from "ds/DragAndDropHandle"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UnstyledButton from "ds/UnstyledButton"

import ArgumentGroupCollapse from "./ArgumentGroup/ArgumentGroupCollapse"
import ArgumentGroupName from "./ArgumentGroup/ArgumentGroupName"
import Arguments from "./Arguments"
import AddArgumentCTAs from "./PlaybookEdit/Argument/AddArgumentCTAs"
import ArgumentGroupActions from "./PlaybookEdit/PlaybookMatrix/ArgumentGroupActions/ArgumentGroupActions"
import { usePlaybook } from "./PlaybookProvider"
import { getArgumentGroupDOMId } from "./getArgumentGroupDOMId"
import {
  SCROLL_RESTORE_ANCHOR,
  scrollToGroupIfNecessary,
} from "./playbookScrollRestore"

const BottomFloatingActionsContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -16px;
  z-index: 2;
`

const StickyTitleRowContainer = styled(UnstyledButton)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.7);
  position: sticky;
  top: 0;
  z-index: 2;
`

interface ArgumentGroupProps extends ArgumentGroupWithDndProps {
  providedDraggable?: DraggableProvided
}

const DROPPABLE_GROUP_HEADER_PREFIX = "GroupHeader"
export function parseGroupDestinationId(id: string) {
  if (id.startsWith(DROPPABLE_GROUP_HEADER_PREFIX)) {
    return id.slice(DROPPABLE_GROUP_HEADER_PREFIX.length + 1)
  } else {
    return id
  }
}

// argumentGroup might be null
const ArgumentGroup = ({
  argumentGroup,
  arguments: _arguments,
  providedDraggable,
  nameContainerRef,
}: ArgumentGroupProps) => {
  const { editMode, viewSettings, activeTypeId, playbook } = usePlaybook()
  const draggableRef = providedDraggable?.innerRef
  const draggableProps = providedDraggable?.draggableProps
  const isCollapsed =
    !!argumentGroup &&
    !viewSettings.isGroupExpanded(activeTypeId, argumentGroup.id)

  const expand = () => {
    if (!argumentGroup) return
    viewSettings.expandGroups(activeTypeId, [argumentGroup.id])
    scrollToGroupIfNecessary(argumentGroup.id)
  }
  const collapse = () =>
    argumentGroup &&
    viewSettings.collapseGroups(activeTypeId, [argumentGroup.id])

  const toggleCollapse = () => {
    apiCreateLog({
      action: isCollapsed ? "EXPAND_GROUP" : "COLLAPSE_GROUP",
      countsAsActivity: true,
      playbookId: playbook.id,
    })
    isCollapsed ? expand() : collapse()
  }

  const header =
    argumentGroup || editMode ? (
      editMode ? (
        <Droppable
          droppableId={`${DROPPABLE_GROUP_HEADER_PREFIX}-${
            argumentGroup?.id || "none"
          }`}
          type="droppableForArguments"
        >
          {(providedDroppable, snapshot) => (
            <ArgumentGroupHeader
              argumentGroup={argumentGroup}
              arguments={_arguments}
              isCollapsed={isCollapsed}
              toggleCollapse={toggleCollapse}
              nameContainerRef={nameContainerRef}
              editMode={editMode}
              providedDroppable={providedDroppable}
              providedDraggable={providedDraggable}
              snapshot={snapshot}
            />
          )}
        </Droppable>
      ) : (
        <ArgumentGroupHeader
          argumentGroup={argumentGroup}
          arguments={_arguments}
          isCollapsed={isCollapsed}
          toggleCollapse={toggleCollapse}
          nameContainerRef={nameContainerRef}
          providedDraggable={providedDraggable}
          editMode={editMode}
        />
      )
    ) : null

  return (
    <Box
      bgcolor={(theme) => theme.palette.common.white}
      boxShadow={6}
      borderRadius={1}
      display="flex"
      flexDirection="column"
      position="relative" // To position the "Add argument" CTAs
      sx={{
        "& .argument-group-actions": {
          opacity: 0.5,
          transition: (theme) => theme.transitions.create("opacity"),
        },
        "&:hover": {
          "& .argument-group-actions": {
            opacity: 1,
          },
        },
      }}
      id={getArgumentGroupDOMId(
        argumentGroup ? argumentGroup.id : "groupless-arguments"
      )}
      className={SCROLL_RESTORE_ANCHOR}
      ref={draggableRef}
      {...draggableProps}
    >
      {header}

      {!isCollapsed && (
        <Box p={2} pt={argumentGroup ? 0 : 2}>
          <Arguments arguments={_arguments} argumentGroup={argumentGroup} />
        </Box>
      )}

      {editMode && !isCollapsed && (
        <BottomFloatingActionsContainer>
          <AddArgumentCTAs
            arguments={_arguments}
            argumentGroup={argumentGroup}
          />
        </BottomFloatingActionsContainer>
      )}
    </Box>
  )
}

interface ArgumentGroupWithDndProps {
  index?: number
  argumentGroup: IArgumentGroup | null
  arguments: IArgument[]
  nameContainerRef?: React.RefObject<HTMLDivElement> | null
}

export default function ArgumentGroupWithDnd(props: ArgumentGroupWithDndProps) {
  const { editMode } = usePlaybook()

  // We cannot drag the groupless arguments group
  if (editMode && props.argumentGroup && typeof props.index !== "undefined") {
    return (
      <Draggable draggableId={props.argumentGroup.id} index={props.index}>
        {(providedDraggable) => (
          <ArgumentGroup providedDraggable={providedDraggable} {...props} />
        )}
      </Draggable>
    )
  }

  return <ArgumentGroup {...props} />
}

interface ArgumentGroupHeaderProps {
  argumentGroup: IArgumentGroup | null
  arguments: IArgument[]
  isCollapsed: boolean
  toggleCollapse: () => void
  nameContainerRef?: React.RefObject<HTMLDivElement> | null
  providedDraggable?: DraggableProvided
  editMode: boolean
  providedDroppable?: DroppableProvided
  snapshot?: DroppableStateSnapshot
}

function ArgumentGroupHeader({
  argumentGroup,
  arguments: _arguments,
  isCollapsed,
  toggleCollapse,
  nameContainerRef,
  providedDraggable,
  editMode,
  providedDroppable,
  snapshot,
}: ArgumentGroupHeaderProps) {
  return (
    <StickyTitleRowContainer
      onClick={toggleCollapse}
      disabled={!argumentGroup}
      {...providedDroppable?.droppableProps}
      ref={providedDroppable?.innerRef}
      sx={{
        border: (theme) =>
          snapshot?.isDraggingOver
            ? `dashed 1px ${theme.palette.primary.main}`
            : undefined,
      }}
    >
      <Stack
        direction="row"
        spacing={1.5}
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        ref={nameContainerRef}
        {...providedDraggable?.dragHandleProps}
      >
        {editMode && providedDraggable && (
          <DragAndDropHandle
            dragHandleProps={providedDraggable.dragHandleProps}
          />
        )}

        <Box sx={{ flexGrow: 1, minWidth: 0 }}>
          {argumentGroup ? (
            <ArgumentGroupName argumentGroup={argumentGroup} />
          ) : (
            <Stack direction="row" spacing={1} alignItems="center">
              <WebAssetOffIcon
                fontSize="small"
                sx={{ fill: (theme) => theme.palette.text.secondary }}
              />
              <Typography
                variant="baseSemi"
                color={(theme) => theme.palette.text.secondary}
              >
                <FormattedMessage id="argumentGroups.groupless" />
              </Typography>
            </Stack>
          )}
        </Box>

        {argumentGroup && (
          <Stack direction="row">
            <ArgumentGroupCollapse
              isCollapsed={isCollapsed}
              toggleCollapse={toggleCollapse}
            />
            {editMode && (
              // Prevent propagation that would collapse the group
              <Box onClick={(e) => e.stopPropagation()}>
                <ArgumentGroupActions
                  argumentGroup={argumentGroup}
                  arguments={_arguments}
                  showDelete={false}
                />
              </Box>
            )}
          </Stack>
        )}
        <Box display="none">{providedDroppable?.placeholder}</Box>
      </Stack>
    </StickyTitleRowContainer>
  )
}
