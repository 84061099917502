import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd"
import { useEffect, useRef, useState } from "react"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IArgumentSegment } from "api/types/argumentSegments"
import { IIntegration } from "api/types/integrations"

import Stack from "ds/Stack"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentSegmentUpdatePositionMutation } from "components/App/Playbook/queries/argumentSegmentQueries"

import { filterNonArchived } from "services/archivable"

import AddArgumentSegment from "./AddArgumentSegment"
import ArgumentSegmentInput from "./ArgumentSegmentInput"
import LinkArgumentSegmentationToCrmProperty from "./LinkArgumentSegmentationToCrmProperty"
import LinkArgumentSegmentationMeetingNoteConfiguration from "./LinkArgumentSegmentationToMeetingNoteConfiguration"
import UnlinkArgumentSegmentationFromCrmProperty from "./UnlinkArgumentSegmentationFromCrmProperty"
import UnlinkArgumentSegmentationFromMeetingNoteConfiguration from "./UnlinkArgumentSegmentationFromMeetingNoteConfiguration"

interface Props {
  argumentSegmentation: IArgumentSegmentation
  crmIntegration: IIntegration | null
  onToggleDefaultSegment: (segmentId: string) => void
}

export default function ArgumentSegments({
  argumentSegmentation,
  crmIntegration,
  onToggleDefaultSegment,
}: Props) {
  const { playbook } = usePlaybook()
  const updatePositionMutation = useArgumentSegmentUpdatePositionMutation(
    playbook.id,
    argumentSegmentation.id
  )

  // Focus on newly created segment input
  const [lastCreatedArgumentSegmentId, setLastCreatedArgumentSegmentId] =
    useState<string | null>(null)
  const lastCreatedArgumentSegmentRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    lastCreatedArgumentSegmentRef.current?.focus()
  }, [lastCreatedArgumentSegmentId])

  function onAddArgumentSegment(argumentSegment: IArgumentSegment) {
    setLastCreatedArgumentSegmentId(argumentSegment.id)
  }

  const displayedSegments = filterNonArchived(
    argumentSegmentation.argumentSegments
  )

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return
    }
    const argumentSegmentId = result.draggableId
    const oldPosition = result.source.index
    const newPosition = result.destination.index
    if (oldPosition === newPosition) {
      return
    }
    const relativeArgumentSegment = displayedSegments[newPosition]

    updatePositionMutation.mutate({
      id: argumentSegmentId,
      relativeId: relativeArgumentSegment.id,
    })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`argumentSegments-${argumentSegmentation.id}`}>
        {(providedDroppable) => (
          <Stack
            spacing={1}
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
          >
            {displayedSegments.map((argumentSegment, index) => (
              <Draggable
                draggableId={argumentSegment.id}
                index={index}
                key={argumentSegment.id}
              >
                {(providedDraggable, dragSnapshot) => (
                  <ArgumentSegmentInput
                    disabled={
                      !!argumentSegmentation.crmProperty ||
                      !!argumentSegmentation.meetingNoteConfiguration
                    }
                    key={argumentSegment.id}
                    argumentSegmentationId={argumentSegmentation.id}
                    argumentSegment={argumentSegment}
                    inputRef={
                      argumentSegment.id === lastCreatedArgumentSegmentId
                        ? lastCreatedArgumentSegmentRef
                        : null
                    }
                    providedDraggable={providedDraggable}
                    isDragging={dragSnapshot.isDragging}
                    onClickDefaultSegment={() =>
                      onToggleDefaultSegment(argumentSegment.id)
                    }
                    isDefaultSegment={
                      argumentSegmentation.defaultSegmentId ===
                      argumentSegment.id
                    }
                  />
                )}
              </Draggable>
            ))}

            {providedDroppable.placeholder}

            <Stack spacing={1}>
              {!argumentSegmentation.crmProperty &&
                !argumentSegmentation.meetingNoteConfiguration && (
                  <AddArgumentSegment
                    argumentSegmentationId={argumentSegmentation.id}
                    onAddArgumentSegment={onAddArgumentSegment}
                  />
                )}

              {argumentSegmentation.crmProperty && (
                <UnlinkArgumentSegmentationFromCrmProperty
                  argumentSegmentation={argumentSegmentation}
                />
              )}

              {!argumentSegmentation.crmProperty &&
                !argumentSegmentation.meetingNoteConfiguration &&
                crmIntegration && (
                  <LinkArgumentSegmentationToCrmProperty
                    crmIntegration={crmIntegration}
                    argumentSegmentation={argumentSegmentation}
                  />
                )}

              {argumentSegmentation.meetingNoteConfiguration && (
                <UnlinkArgumentSegmentationFromMeetingNoteConfiguration
                  argumentSegmentation={argumentSegmentation}
                />
              )}

              {!argumentSegmentation.crmProperty &&
                !argumentSegmentation.meetingNoteConfiguration && (
                  <LinkArgumentSegmentationMeetingNoteConfiguration
                    argumentSegmentation={argumentSegmentation}
                  />
                )}
            </Stack>
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  )
}
