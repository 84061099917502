import { FormattedMessage } from "react-intl"

import { IBarePlaybook } from "api/types/playbooks"

import FormattedTime from "ds/FormattedTime"
import Link from "ds/Link"
import Paper from "ds/Paper"
import Table from "ds/Table"
import TableBody from "ds/TableBody"
import TableCell from "ds/TableCell"
import TableContainer from "ds/TableContainer"
import TableHead from "ds/TableHead"
import TableRow from "ds/TableRow"

import { getPlaybookPath } from "services/playbooks"

import PlaybookActions from "./PlaybookActions"

interface Props {
  playbooks: IBarePlaybook[]
}

export default function BusinessUnitPlaybooksTable({ playbooks }: Props) {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: 800, boxShadow: 6 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="businessUnits.playbooks.fields.name"
                defaultMessage="Name"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="businessUnits.playbooks.fields.creationDate"
                defaultMessage="Creation date"
              />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {playbooks.map((playbook) => (
            <TableRow key={playbook.id}>
              <TableCell>
                <Link
                  to={getPlaybookPath(playbook.businessUnitId, playbook.id)}
                >
                  {playbook.name}
                </Link>
              </TableCell>
              <TableCell>
                <FormattedTime value={playbook.createdAt} format="shortTime" />
              </TableCell>
              <TableCell>
                <PlaybookActions playbook={playbook} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
