import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import Loader from "ds/Loader"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { useBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"
import { useApplicationIntegrationQuery } from "components/App/Organization/ApplicationsIntegration/applicationIntegrationsQueries"

import ArgumentAnswerForm from "./ArgumentAnswer/ArgumentAnswerForm"
import MeetingNoteConfigurationCRMForm from "./ArgumentAnswer/MeetingNoteConfigurationCRMForm"

interface Props {
  argument: IArgument
  onSuccess: () => void
}

export default function EditArgumentAnswer({ argument, onSuccess }: Props) {
  const businessUnit = useBusinessUnit()
  const currentCRMIntegration = useApplicationIntegrationQuery(businessUnit.id)

  return (
    <Stack spacing={2}>
      <Typography variant="lgSemi">
        <FormattedMessage id="playbookEdit.argumentAnswer.form.title" />
      </Typography>

      {currentCRMIntegration.isLoading ? (
        <Loader />
      ) : currentCRMIntegration.data ? (
        <MeetingNoteConfigurationCRMForm
          argument={argument}
          onSuccess={onSuccess}
        />
      ) : (
        <ArgumentAnswerForm argument={argument} onSuccess={onSuccess} />
      )}
    </Stack>
  )
}
