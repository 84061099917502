import { ThemeProvider, useTheme } from "@mui/material"
import { deepmerge } from "@mui/utils"

import { ModalProps } from "ds/Modal"

import { createModalContainerOptions } from "themes/default"

interface Props {
  container: ModalProps["container"]
  children: React.ReactNode
}

export default function ModalContainerOverride(props: Props) {
  const theme = useTheme()
  return (
    <ThemeProvider
      theme={deepmerge(theme, createModalContainerOptions(props.container))}
    >
      {props.children}
    </ThemeProvider>
  )
}
