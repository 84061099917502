import { FormattedMessage } from "react-intl"

import Alert from "ds/Alert"

export default function NoArgumentsInTab() {
  return (
    <Alert severity="warning">
      <FormattedMessage
        id="playbook.noArgumentsInTab"
        defaultMessage="No argument in tab."
      />
    </Alert>
  )
}
