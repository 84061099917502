import { useState } from "react"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import Modal from "ds/Modal"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import BusinessUnitMembershipsCreation from "./BusinessUnitMembershipsCreation"

export default function CreateBusinessUnitMembership() {
  const [isOpen, setIsOpen] = useState(false)

  const cta = <FormattedMessage id="businessUnits.memberships.add.title" />

  return (
    <>
      <Button
        color="primary"
        onClick={() => setIsOpen(true)}
        variant="contained"
      >
        {cta}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        widthVariant="medium"
      >
        <Stack spacing={2}>
          <Typography variant="baseSemi">{cta}</Typography>

          <BusinessUnitMembershipsCreation onSuccess={() => setIsOpen(false)} />
        </Stack>
      </Modal>
    </>
  )
}
