import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import Typography from "ds/Typography"

export default function ArgumentSegmentRulesTitle({
  _arguments,
}: {
  _arguments: IArgument[]
}) {
  return (
    <Typography variant="baseSemi">
      <FormattedMessage id="playbookEdit.argument.segmentRules.title" />
      {_arguments.length > 1 && <> ({_arguments.length})</>}
    </Typography>
  )
}
