import { isEmpty } from "lodash"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { ICreateOrganizationResponse } from "api/types/organizations"

import Button from "ds/Button"
import { BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import TextField from "ds/TextField"
import Typography from "ds/Typography"

import { useOrganizationCreateMutation } from "./organizationQueries"

function validate({ name }: { name: string }) {
  const errors: { name?: string } = {}
  if (!name) {
    errors.name = "missing"
  }
  return errors
}

interface Props {
  onCreate?: (data: ICreateOrganizationResponse) => void
  addCurrentUser: boolean
}

export default function CreateOrganizationForm({
  onCreate,
  addCurrentUser,
}: Props) {
  const [name, setName] = useState("")
  const createMutation = useOrganizationCreateMutation()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)

  const validationErrors = validate({ name })
  const isInvalid = !isEmpty(validationErrors)

  const submit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    createMutation.mutate(
      { organization: { name }, addCurrentUser },
      {
        onSuccess: (data) => {
          setSuccessSnackOpen(true)
          onCreate?.(data)
        },
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <form>
      <Stack spacing={2}>
        <Typography variant="xsNormal">
          <FormattedMessage
            id="organizations.create.cta"
            defaultMessage="Create new organization"
          />
        </Typography>

        <Stack spacing={4}>
          <TextField
            label={
              <FormattedMessage
                id="organizations.field.name"
                defaultMessage="Name"
              />
            }
            name="name"
            value={name}
            autoFocus
            onChange={(e) => setName(e.target.value)}
          />
          <Button
            color="primary"
            type="submit"
            onClick={submit}
            disabled={createMutation.isLoading || isInvalid}
          >
            <FormattedMessage
              id="organizations.create.submit"
              defaultMessage="Create"
            />
          </Button>
        </Stack>
      </Stack>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
    </form>
  )
}
