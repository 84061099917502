import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import { enter, shift } from "ds/RichTextNew/hotkeys"
import Shortcut from "ds/Shortcut"
import Typography from "ds/Typography"

export default function NewCommentHint() {
  return (
    <Box pt={0.5} display="flex" gap={2}>
      <Typography variant="xsNormal" component="span">
        <FormattedMessage
          id="argumentComments.textareaHints.enter"
          defaultMessage="{shortcut} to send"
          values={{ shortcut: <Shortcut keys={[enter]} /> }}
        />
      </Typography>

      <Typography variant="xsNormal" component="span">
        <FormattedMessage
          id="argumentComments.textareaHints.shiftEnter"
          defaultMessage="{shortcut} for newline"
          values={{ shortcut: <Shortcut keys={[shift, enter]} /> }}
        />
      </Typography>
    </Box>
  )
}
