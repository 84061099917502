import MoreVert from "@mui/icons-material/MoreVert"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { IArgument } from "api/types/arguments"

import Divider from "ds/Divider"
import IconButton from "ds/IconButton"
import Menu from "ds/Menu"
import { AlertSnackbar } from "ds/Snackbar"

import useAnchorEl from "utils/hooks/useAnchorEl"

import EditArgumentSegmentRules from "../../ArgumentSegmentRules/EditArgumentSegmentRules"
import MoveArgumentsModal from "../../MoveArgumentsModal"
import ArgumentArchive from "./ArgumentArchive"
import ArgumentEdit from "./ArgumentEdit"
import ArgumentRestore from "./ArgumentRestore"
import ArgumentSegmentRulesItem from "./ArgumentSegmentRulesItem"
import ArgumentViewComments from "./ArgumentViewComments"
import CopyArgument from "./CopyArgument"
import DuplicateArgument from "./DuplicateArgument"
import MoveArgumentItem from "./MoveArgumentItem"
import PasteInArgument from "./PasteInArgument"

interface Props {
  argument: IArgument
  onlyConfigOptions?: boolean
}

export default function ArgumentActions({
  argument,
  onlyConfigOptions = false,
}: Props) {
  const intl = useIntl()
  const [anchorEl, actionsMenuIsOpen, openActionsMenu, closeActionsMenu] =
    useAnchorEl()
  const [editSegmentRulesIsOpen, setEditSegmentRulesIsOpen] = useState(false)
  const [moveArgumentIsOpen, setMoveArgumentIsOpen] = useState(false)
  const [copySuccessSnackOpen, setCopySuccessSnackOpen] = useState(false)

  const [pasteErrorSnackOpen, setPasteErrorSnackOpen] = useState(false)
  const [
    pasteEmptyOrInconsistentSnackOpen,
    setPasteEmptyOrInconsistentSnackOpen,
  ] = useState(false)
  const [pasteSuccessSnackOpen, setPasteSuccessSnackOpen] = useState(false)

  const label = intl.formatMessage({
    id: "playbookEdit.argument.actions",
    defaultMessage: "Talking point actions",
  })

  return (
    <>
      <IconButton
        onClick={openActionsMenu}
        aria-label={label}
        className="argument-actions"
        opacityTransitionOnHover
      >
        <MoreVert fontSize="small" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={actionsMenuIsOpen}
        onClose={closeActionsMenu}
      >
        {!onlyConfigOptions && (
          <ArgumentEdit argument={argument} onClick={closeActionsMenu} />
        )}
        <ArgumentSegmentRulesItem
          onClick={() => {
            closeActionsMenu()
            setEditSegmentRulesIsOpen(true)
          }}
        />
        {!onlyConfigOptions && <Divider />}
        {!onlyConfigOptions && (
          <DuplicateArgument argument={argument} onSuccess={closeActionsMenu} />
        )}
        {!onlyConfigOptions && (
          <MoveArgumentItem
            onClick={() => {
              closeActionsMenu()
              setMoveArgumentIsOpen(true)
            }}
          />
        )}
        {!onlyConfigOptions && (
          <CopyArgument
            argument={argument}
            onSuccess={() => {
              closeActionsMenu()
              setCopySuccessSnackOpen(true)
            }}
          />
        )}
        {!onlyConfigOptions && (
          <PasteInArgument
            argument={argument}
            onSuccess={() => {
              closeActionsMenu()
              setPasteSuccessSnackOpen(true)
            }}
            onError={() => setPasteErrorSnackOpen(true)}
            onInconsistency={() => setPasteEmptyOrInconsistentSnackOpen(true)}
          />
        )}
        {!onlyConfigOptions && <Divider />}
        {!onlyConfigOptions && (
          <ArgumentViewComments
            argument={argument}
            onClick={closeActionsMenu}
          />
        )}
        {!onlyConfigOptions && <Divider />}
        {!argument.archived && !onlyConfigOptions && (
          <ArgumentArchive argument={argument} onSuccess={closeActionsMenu} />
        )}
        {argument.archived && !onlyConfigOptions && (
          <ArgumentRestore argument={argument} onSuccess={closeActionsMenu} />
        )}
      </Menu>

      {editSegmentRulesIsOpen && (
        <EditArgumentSegmentRules
          arguments={[argument]}
          close={() => setEditSegmentRulesIsOpen(false)}
        />
      )}

      {moveArgumentIsOpen && (
        <MoveArgumentsModal
          argumentIds={[argument.id]}
          close={() => setMoveArgumentIsOpen(false)}
        />
      )}

      <AlertSnackbar
        severity="success"
        open={copySuccessSnackOpen}
        onClose={() => setCopySuccessSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.copy.success" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="error"
        open={pasteErrorSnackOpen}
        onClose={() => setPasteErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.error.api" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="error"
        open={pasteEmptyOrInconsistentSnackOpen}
        onClose={() => setPasteEmptyOrInconsistentSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.error.emptyOrInconsitent" />
      </AlertSnackbar>

      <AlertSnackbar
        severity="success"
        open={pasteSuccessSnackOpen}
        onClose={() => setPasteSuccessSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.paste.success" />
      </AlertSnackbar>
    </>
  )
}
