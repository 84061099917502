import MoreVert from "@mui/icons-material/MoreVert"
import { Box } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"
import styled from "styled-components"

import { IArgumentComment } from "api/types/argumentComments"

import IconButton from "ds/IconButton"
import Menu from "ds/Menu"
import MenuItem from "ds/MenuItem"

import useAnchorEl from "utils/hooks/useAnchorEl"

import {
  useArgumentCommentDeleteMutation,
  useArgumentCommentToggleResolveMutation,
} from "./argumentCommentQueries"

const Container = styled.div`
  position: absolute;
  right: 0;
  opacity: 0.2;
  transition: ${({ theme }) => theme.transitions.create("opacity")};
`

interface Props {
  comment: IArgumentComment
  onEditClick: React.MouseEventHandler<HTMLLIElement>
}

export default function CommentActions({ comment, onEditClick }: Props) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const deleteMutation = useArgumentCommentDeleteMutation(comment.argumentId)
  const toggleResolveMutation = useArgumentCommentToggleResolveMutation(
    comment.argumentId
  )

  const onToggleResolve = () =>
    toggleResolveMutation.mutate(
      {
        id: comment.id,
      },
      {
        onSuccess: () => handleClose(),
      }
    )

  const onDelete = () => deleteMutation.mutate(comment.id)

  const label = intl.formatMessage({
    id: "argumentComments.moreActions",
    defaultMessage: "More argument comments actions",
  })

  return (
    <Container className="argument-comment-actions">
      <IconButton onClick={handleClick} aria-label={label}>
        <MoreVert fontSize="small" />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={onToggleResolve}
          disabled={toggleResolveMutation.isLoading || !comment.canResolve}
        >
          <Box>
            {comment.resolvedByUser ? (
              <FormattedMessage
                id="argumentComments.actions.unresolve"
                defaultMessage="Unresolve"
              />
            ) : (
              <FormattedMessage
                id="argumentComments.actions.resolve"
                defaultMessage="Resolve"
              />
            )}
          </Box>
        </MenuItem>

        <MenuItem
          onClick={onEditClick}
          disabled={!comment.canEdit && !comment.canEditCategory}
        >
          <Box>
            <FormattedMessage
              id="argumentComments.actions.edit"
              defaultMessage="Edit"
            />
          </Box>
        </MenuItem>

        <MenuItem
          onClick={onDelete}
          disabled={deleteMutation.isLoading || !comment.canDelete}
        >
          <Box>
            <FormattedMessage
              id="argumentComments.actions.delete"
              defaultMessage="Delete"
            />
          </Box>
        </MenuItem>
      </Menu>
    </Container>
  )
}
