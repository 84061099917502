import MuiRadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup"
import { forwardRef } from "react"

export type { RadioGroupProps }

export default forwardRef(function RadioGroup(
  props: RadioGroupProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiRadioGroup {...props} ref={ref} />
})
