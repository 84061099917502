import { FormattedMessage } from "react-intl"

import { IArgumentGroup } from "api/types/argumentGroups"

import MenuItem from "ds/MenuItem"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { getArgumentGroupDOMId } from "components/App/Playbook/getArgumentGroupDOMId"
import { usePasteArgumentGroupsMutation } from "components/App/Playbook/queries/argumentGroupQueries"

import { buildSegmentRulesFromSegmentFilters } from "services/argumentSegmentRules"

import { scrollToElementWithDelay } from "utils/scrollToElement"

interface Props {
  argumentGroup: IArgumentGroup
  onSuccess: () => void
  onError: () => void
}

export default function DuplicateArgumentGroup({
  argumentGroup,
  onSuccess,
  onError,
}: Props) {
  const { playbook, activeTypeId, segmentFilters } = usePlaybook()
  const pasteArgumentGroupsMutation = usePasteArgumentGroupsMutation(
    playbook.id,
    activeTypeId
  )

  async function onClick() {
    pasteArgumentGroupsMutation.mutate(
      {
        argumentGroupIds: [argumentGroup.id],
        argumentTypeId: activeTypeId,
        segmentRules: buildSegmentRulesFromSegmentFilters(segmentFilters),
        relativeArgumentGroupId: argumentGroup.id,
      },
      {
        onSuccess: (argumentGroups) => {
          const firstGroup = argumentGroups[0]
          scrollToElementWithDelay(getArgumentGroupDOMId(firstGroup.id))
          onSuccess()
        },
        onError: () => onError(),
      }
    )
  }

  return (
    <MenuItem onClick={onClick}>
      <FormattedMessage id="playbookEdit.argumentGroups.duplicate" />
    </MenuItem>
  )
}
