import { Box } from "@mui/material"
import { useState } from "react"
import { useIntl } from "react-intl"

import useMe from "authentication/useMe"

import Button from "ds/Button"
import Divider from "ds/Divider"
import Menu from "ds/Menu"
import Modal from "ds/Modal"
import Typography from "ds/Typography"

import { useUnsafeBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"

import { isOrganizationAdmin } from "services/organizationMemberships"

import useAnchorEl from "utils/hooks/useAnchorEl"

import Avatar from "./Avatar"
import ApplicationMenuItem from "./AvatarMenu/ApplicationMenuItem"
import BusinessUnitMenuItem from "./AvatarMenu/BusinessUnitMenuItem"
import BusinessUnitSwitch from "./AvatarMenu/BusinessUnitSwitch"
import BusinessUnitSwitchMenuItem from "./AvatarMenu/BusinessUnitSwitchMenuItem"
import ContactOrDemoMenuItem from "./AvatarMenu/ContactOrDemoMenuItem"
import DashboardMenuItem from "./AvatarMenu/DashboardMenuItem"
import LogOutMenuItem from "./AvatarMenu/LogOutMenuItem"
import MeetingTypesMenuItem from "./AvatarMenu/MeetingTypesMenuItem"
import MeetingsMenuItem from "./AvatarMenu/MeetingsMenuItem"
import MyProfile from "./AvatarMenu/MyProfile"
import MyProfileMenuItem from "./AvatarMenu/MyProfileMenuItem"
import OrganizationMenuItem from "./AvatarMenu/OrganizationMenuItem"
import OrganizationSwitch from "./AvatarMenu/OrganizationSwitch"
import OrganizationSwitchMenuItem from "./AvatarMenu/OrganizationSwitchMenuItem"
import Settings from "./AvatarMenu/Settings"
import SettingsMenuItem from "./AvatarMenu/SettingsMenuItem"
import SuperAdminMenuItem from "./AvatarMenu/SuperAdminMenuItem"
import TutorialMenuItem from "./AvatarMenu/TutorialMenuItem"

export default function AvatarMenu() {
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const user = useMe()
  const [profileModalIsOpen, setProfileModalIsOpen] = useState(false)
  const [organizationSwitchModalIsOpen, setOrganizationSwitchModalIsOpen] =
    useState(false)
  const [businessUnitSwitchModalIsOpen, setBusinessUnitSwitchModalIsOpen] =
    useState(false)
  const [settingsModalIsOpen, setSettingsModalIsOpen] = useState(false)
  const intl = useIntl()
  const businessUnit = useUnsafeBusinessUnit()

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClick}
        style={{ padding: 0, minWidth: 0 }}
        aria-label={intl.formatMessage({
          id: "navigation.label",
          defaultMessage: "Open navigation menu",
        })}
      >
        <Avatar
          userName={user.name}
          userEmail={user.email}
          userAvatarUrl={user.thumbnailAvatarUrl}
          userDefaultAvatarColorCode={user.defaultAvatarColorCode}
          sx={{ height: 32, width: 32 }}
        />
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ApplicationMenuItem />
        {user.isSuperAdmin && <SuperAdminMenuItem />}

        <MyProfileMenuItem
          onClick={() => {
            setProfileModalIsOpen(true)
            handleClose()
          }}
        />

        <SettingsMenuItem
          onClick={() => {
            setSettingsModalIsOpen(true)
            handleClose()
          }}
        />

        <LogOutMenuItem />

        <Divider />

        <TutorialMenuItem onClick={handleClose} />

        <ContactOrDemoMenuItem onClick={handleClose} />
        {businessUnit && (
          <Divider>
            <Box
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxWidth="200px"
            >
              <Typography variant="smSemi">{businessUnit.name}</Typography>
            </Box>
          </Divider>
        )}

        {businessUnit &&
          (user.isSuperAdmin ||
            isOrganizationAdmin(user, businessUnit.organizationId)) && (
            <BusinessUnitMenuItem
              businessUnit={businessUnit}
              onClick={handleClose}
            />
          )}

        {businessUnit && (
          <MeetingsMenuItem
            businessUnitId={businessUnit.id}
            onClick={handleClose}
          />
        )}

        {businessUnit && (
          <MeetingTypesMenuItem
            businessUnitId={businessUnit.id}
            onClick={handleClose}
          />
        )}

        {businessUnit &&
          (user.isSuperAdmin ||
            isOrganizationAdmin(user, businessUnit.organizationId)) && (
            <DashboardMenuItem
              businessUnit={businessUnit}
              onClick={handleClose}
            />
          )}

        {businessUnit && (
          <BusinessUnitSwitchMenuItem
            onClick={() => {
              setBusinessUnitSwitchModalIsOpen(true)
              handleClose()
            }}
          />
        )}

        <Divider />

        {businessUnit &&
          (user.isSuperAdmin ||
            isOrganizationAdmin(user, businessUnit.organizationId)) && (
            <OrganizationMenuItem
              organizationId={businessUnit.organizationId}
              onClick={handleClose}
            />
          )}

        <OrganizationSwitchMenuItem
          onClick={() => {
            setOrganizationSwitchModalIsOpen(true)
            handleClose()
          }}
        />
      </Menu>

      <Modal
        isOpen={profileModalIsOpen}
        onClose={() => setProfileModalIsOpen(false)}
        widthVariant="medium"
        heightVariant="fitContent"
      >
        <MyProfile />
      </Modal>

      {businessUnit && (
        <Modal
          isOpen={businessUnitSwitchModalIsOpen}
          onClose={() => setBusinessUnitSwitchModalIsOpen(false)}
          sizeVariant="small"
        >
          <BusinessUnitSwitch currentBusinessUnit={businessUnit} />
        </Modal>
      )}

      <Modal
        isOpen={organizationSwitchModalIsOpen}
        onClose={() => setOrganizationSwitchModalIsOpen(false)}
        sizeVariant="small"
      >
        <OrganizationSwitch
          onOrganizationCreate={() => setOrganizationSwitchModalIsOpen(false)}
        />
      </Modal>

      <Modal
        isOpen={settingsModalIsOpen}
        onClose={() => setSettingsModalIsOpen(false)}
      >
        <Settings />
      </Modal>
    </div>
  )
}
