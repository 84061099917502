import { MutateFunction, useInfiniteQuery } from "@tanstack/react-query"

import {
  apiDeactivateOrganizationMembership,
  apiReactivateOrganizationMembership,
  apiUpdateCanEditPlaybooks,
  apiUpdateOrganizationMembershipRole,
} from "api/organizationMemberships"
import { apiSearchOrganizationMemberships } from "api/organizations"

import { useInvalidatingMutation } from "utils/hooks/mutations"

function buildOrganizationMembershipsKey(organizationId: string) {
  return ["organizationMemberships", organizationId]
}

function buildOrganizationMembershipsSearchKey(
  organizationId: string,
  query: string
) {
  return ["organizationMemberships", organizationId, query]
}

export const useOrganizationMembershipsInfiniteQuery = (
  organizationId: string,
  query: string
) => {
  return useInfiniteQuery(
    buildOrganizationMembershipsSearchKey(organizationId, query),
    ({ pageParam, signal }) =>
      apiSearchOrganizationMemberships(
        {
          organizationId,
          paginationCursor: pageParam,
          query,
        },
        { signal }
      ),
    {
      getNextPageParam: (lastPage) => lastPage.paginationCursor,
    }
  )
}

function useOrganizationMembershipUpdateMutation<TData, TVariables, TError>(
  organizationId: string,
  mutationFn: MutateFunction<TData, TError, TVariables>
) {
  return useInvalidatingMutation(
    buildOrganizationMembershipsKey(organizationId),
    mutationFn
  )
}

export function useOrganizationMembershipDeactivateMutation(
  organizationId: string
) {
  return useOrganizationMembershipUpdateMutation(
    organizationId,
    apiDeactivateOrganizationMembership
  )
}

export function useOrganizationMembershipReactivateMutation(
  organizationId: string
) {
  return useOrganizationMembershipUpdateMutation(
    organizationId,
    apiReactivateOrganizationMembership
  )
}

export function useOrganizationMembershipUpdateRoleMutation(
  organizationId: string
) {
  return useOrganizationMembershipUpdateMutation(
    organizationId,
    apiUpdateOrganizationMembershipRole
  )
}

export function useOrganizationMembershipUpdateCanEditPlaybooksMutation(
  organizationId: string
) {
  return useOrganizationMembershipUpdateMutation(
    organizationId,
    apiUpdateCanEditPlaybooks
  )
}
