import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import Box from "@mui/material/Box"
import { FormattedMessage } from "react-intl"

import { IBusinessUnit } from "api/types/businessUnits"

import MenuItem from "ds/MenuItem"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import { UnstyledRouterLink } from "ds/UnstyledLink"

import { getBusinessUnitPath } from "services/businessUnits"

interface Props {
  businessUnit: IBusinessUnit
  selected: boolean
}

export default function BusinessUnitItem({ businessUnit, selected }: Props) {
  return (
    <MenuItem
      component={UnstyledRouterLink}
      to={getBusinessUnitPath(businessUnit.id)}
      selected={selected}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack spacing={1}>
          <Typography variant="baseBold">{businessUnit.name}</Typography>

          <Typography variant="xsNormal">
            <FormattedMessage
              id="navigation.businessUnitSwitch.nbPlaybooks"
              values={{
                playbooksCount: businessUnit.playbooks.length,
              }}
            />
          </Typography>
        </Stack>

        <Box>{selected && <CheckCircleOutlineIcon color="success" />}</Box>
      </Stack>
    </MenuItem>
  )
}
