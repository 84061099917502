import { Navigate } from "react-router-dom"

import useMe from "authentication/useMe"

import SimpleLayout from "components/layouts/SimpleLayout"

import { getOrganizationPath } from "services/organizations"

import NoOrganization from "./NoOrganization"
import { getLastVisitedOrganizationId } from "./Organization/lastVisit"

function getValidatedLastOrganizationId(activeOrganizationIds: string[]) {
  const lastVisitedOrganizationId = getLastVisitedOrganizationId()
  if (!lastVisitedOrganizationId) return null

  if (activeOrganizationIds.includes(lastVisitedOrganizationId))
    return lastVisitedOrganizationId
  return null
}

export default function AppRoot() {
  const user = useMe()
  const activeOrganizationIds = user.organizationMemberships
    .filter((x) => !x.organization.discarded)
    .map((x) => x.organization.id)

  if (activeOrganizationIds.length === 0) {
    return (
      <SimpleLayout>
        <NoOrganization />
      </SimpleLayout>
    )
  }

  const lastOrganizationId = getValidatedLastOrganizationId(
    activeOrganizationIds
  )

  if (lastOrganizationId) {
    return <Navigate to={getOrganizationPath(lastOrganizationId)} replace />
  }

  return <Navigate to={getOrganizationPath(activeOrganizationIds[0])} replace />
}
