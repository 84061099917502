import { AutocompleteValue, FilterOptionsState } from "@mui/material"
import { createFilterOptions } from "@mui/material/Autocomplete"
import { useIntl } from "react-intl"

import { IMeetingNoteConfigurationOption } from "api/types/meetingNoteConfigurations"

import Autocomplete, { CustomAutocompleteProps } from "ds/Autocomplete"
import FormControl from "ds/FormControl"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

import AdditionalInfo from "components/common/AdditionalInfo"

const filter = createFilterOptions<IMeetingNoteConfigurationOption>()

function filterOptionsInCreatable(
  options: IMeetingNoteConfigurationOption[],
  params: FilterOptionsState<IMeetingNoteConfigurationOption>
) {
  const filtered = filter(options, params)

  const { inputValue } = params
  // Suggest the creation of a new value
  const isExisting = options.some(
    (option) => inputValue.toLowerCase() === option.label.toLowerCase()
  )
  if (inputValue !== "" && !isExisting)
    filtered.push({ label: inputValue, value: inputValue })

  return filtered
}

interface Props<
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined
> {
  value: AutocompleteValue<
    IMeetingNoteConfigurationOption,
    Multiple,
    undefined,
    FreeSolo
  >
  onChange: CustomAutocompleteProps<
    IMeetingNoteConfigurationOption,
    Multiple,
    undefined,
    FreeSolo
  >["onChange"]
  hasAdditionalInfo: boolean
  additionalInfo: string
  onAdditionalInfoChange: (newAdditionalInfo: string) => void
  multiple: Multiple
  options: IMeetingNoteConfigurationOption[]
  isSaved: boolean
  creatable: FreeSolo
  disabled: boolean
}

export default function SelectForm<
  Multiple extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  value,
  onChange,
  hasAdditionalInfo,
  additionalInfo,
  onAdditionalInfoChange,
  multiple,
  options,
  isSaved,
  creatable,
  disabled,
}: Props<Multiple, FreeSolo>) {
  const intl = useIntl()

  const placeholder = intl.formatMessage({
    id: multiple
      ? "playbookEdit.argumentAnswer.form.typeSelect.multi_select"
      : "playbookEdit.argumentAnswer.form.typeSelect.single_select",
    defaultMessage: multiple ? "Multiple choices" : "Single choice",
  })

  const other = intl.formatMessage({
    id: "playbookEdit.argumentAnswer.form.selectBuilder.optionOther",
    defaultMessage: "Other",
  })
  const otherOption = { value: other, label: other }
  const optionsWithOther = creatable ? options.concat(otherOption) : options

  return (
    <Stack direction="row">
      <FormControl fullWidth>
        <Autocomplete<
          IMeetingNoteConfigurationOption,
          Multiple,
          undefined,
          FreeSolo
        >
          fullWidth
          autoHighlight
          size="small"
          clearOnBlur
          multiple={multiple}
          inputLabel={null}
          options={optionsWithOther}
          value={value}
          onChange={onChange}
          renderInput={(params) => {
            return <TextField placeholder={placeholder} {...params} />
          }}
          freeSolo={creatable}
          filterOptions={creatable ? filterOptionsInCreatable : filter}
          renderOption={(props, option) => (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          )}
          forcePopupIcon // https://github.com/mui/material-ui/issues/18486
          disabled={disabled}
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      </FormControl>

      {hasAdditionalInfo && (
        <AdditionalInfo
          value={additionalInfo}
          onChange={onAdditionalInfoChange}
          isSaved={isSaved}
          disabled={disabled}
        />
      )}
    </Stack>
  )
}
