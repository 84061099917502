// Messaging between the components of the extension
import { isValidChromeRuntime } from "./utils"

const TO_CONTENT_SCRIPT_MESSAGE_TYPE = "toContentScript"
const SYNC_MEETING_NOTES_PANEL_OPEN_STATE_TYPE =
  "SYNC_MEETING_NOTES_PANEL_OPEN_STATE"

const REQUEST_CURRENT_URL_MESSAGE_TYPE = "currentUrlRequest"
const REQUEST_CLOSE_PANEL_MESSAGE_TYPE = "closePanelRequest"

function sendMessageToBackgroundIfConnected<M = any, R = any>(
  message: M
): Promise<R> | undefined {
  if (!isValidChromeRuntime()) {
    alert("Extension has been updated, please reload")
    return undefined
  }
  return chrome.runtime.sendMessage(message)
}

const CLICK_PANEL_TRIGGER_TYPE = "CLICK_PANEL_TRIGGER"

export function sendClickPanelTriggerToContentScript() {
  sendMessageToContentScript({
    type: CLICK_PANEL_TRIGGER_TYPE,
  })
}

export function listenToClickPanelTrigger(listener: () => void) {
  chrome.runtime.onMessage.addListener((message) => {
    if (message.type === CLICK_PANEL_TRIGGER_TYPE) {
      listener()
    }
  })
}

function sendMessageToContentScript(message: unknown) {
  // Sending a message to the content script from another part of the extension can only be done through the background
  // We have a special message type to tell the background to forward the message to the content script
  return sendMessageToBackgroundIfConnected({
    type: TO_CONTENT_SCRIPT_MESSAGE_TYPE,
    message,
  })
}

export function forwardMessageToContentScript() {
  chrome.runtime.onMessage.addListener((message, sender) => {
    if (message.type === TO_CONTENT_SCRIPT_MESSAGE_TYPE) {
      if (sender.tab?.id) {
        chrome.tabs.sendMessage(sender.tab?.id, message.message)
      }
    }
  })
}

export function sendMeetingNotesPanelOpenState(open: boolean) {
  return sendMessageToContentScript({
    type: SYNC_MEETING_NOTES_PANEL_OPEN_STATE_TYPE,
    open,
  })
}

export function listenToMeetingNotesPanelOpenState(
  listener: (open: boolean) => void
) {
  chrome.runtime.onMessage.addListener((message) => {
    if (message.type === SYNC_MEETING_NOTES_PANEL_OPEN_STATE_TYPE) {
      listener(message.open)
    }
  })
}

export async function requestCurrentUrl(): Promise<string | undefined> {
  const response = await sendMessageToBackgroundIfConnected({
    type: REQUEST_CURRENT_URL_MESSAGE_TYPE,
  })
  if (typeof response === "string") return response
  return undefined
}

export async function listenToCurrentUrlRequests() {
  chrome.runtime.onMessage.addListener((message, sender, sendResponse) => {
    if (message.type !== REQUEST_CURRENT_URL_MESSAGE_TYPE) return undefined
    if (!sender.tab?.id) return undefined
    chrome.tabs.get(sender.tab.id, function (tab) {
      sendResponse(tab.url)
    })

    return true
  })
}

// Necessary to watch "disconnect" from the content script
export function addConnectListener() {
  chrome.runtime.onConnect.addListener(() => {})
}

// TODO FIX the following: This is not the proper way to check that the extension has been updated
// Used to show a warning when the app is updated
export function addDisconnectListener(
  listener: (port: chrome.runtime.Port) => void
) {
  chrome.runtime.connect().onDisconnect.addListener(listener)
}

export function removeDisconnectListener(
  listener: (port: chrome.runtime.Port) => void
) {
  chrome.runtime.connect().onDisconnect.addListener(listener)
}

export async function requestToCloseExtensionPanel() {
  return sendMessageToContentScript({
    type: REQUEST_CLOSE_PANEL_MESSAGE_TYPE,
  })
}

export async function listenToCloseExtensionPanelRequests(
  onClosePanelRequest: () => void
) {
  chrome.runtime.onMessage.addListener((message) => {
    if (message.type !== REQUEST_CLOSE_PANEL_MESSAGE_TYPE) return undefined
    onClosePanelRequest()

    return undefined
  })
}
