import axios from "./axios"
import {
  ICRMDealPullParams,
  ICRMDealPullResponse,
  ICRMDealSearchResult,
  ICRMDealsSearchParams,
} from "./types/CRMDeals"

export const apiSearchCRMDeals = (
  params: ICRMDealsSearchParams
): Promise<ICRMDealSearchResult[]> =>
  axios.post("api/crm/deals/search", params).then((res) => res.data)

export const apiPullCRMDeal = (
  params: ICRMDealPullParams
): Promise<ICRMDealPullResponse> =>
  axios.post("api/crm/deals/pull", params).then((res) => res.data)
