import { FormattedMessage, useIntl } from "react-intl"

import { IArgumentSegment } from "api/types/argumentSegments"

const id = "argumentSegments.defaultName"
const defaultMessage = "[Unnamed]"

export function useArgumentSegmentName() {
  const intl = useIntl()
  const defaultName = intl.formatMessage({ id, defaultMessage })
  return (argumentSegment: IArgumentSegment) =>
    argumentSegment.name || defaultName
}

interface Props {
  argumentSegment: IArgumentSegment
}

export default function ArgumentSegmentName({ argumentSegment }: Props) {
  if (argumentSegment.name) return <>{argumentSegment.name}</>
  return <FormattedMessage id={id} defaultMessage={defaultMessage} />
}
