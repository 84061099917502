import { match } from "@formatjs/intl-localematcher"
import type { Locale as DateFnsLocale } from "date-fns"
import { enUS as enUSDateFns, fr as frDateFns } from "date-fns/locale"
import { IntlProvider, useIntl } from "react-intl"

import enMessages from "./resources/en.json"
import frMessages from "./resources/fr.json"

const availableLocales = ["en", "fr"] as const
export type AvailableLocales = typeof availableLocales[number]
const defaultLocale = "en"

type AvailableTranslations = typeof enMessages
export type AvailableTranslationKeys = keyof AvailableTranslations

// Doc: https://formatjs.io/docs/react-intl/#typing-message-ids-and-locale
declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: AvailableTranslationKeys
    }
  }

  namespace FormatjsIntl {
    interface IntlConfig {
      locale: AvailableLocales
    }
  }
}

const messagesMapping: {
  [key in AvailableLocales]: AvailableTranslations
} = {
  fr: frMessages,
  en: enMessages,
}

const dateFnsMapping: {
  [key in AvailableLocales]: DateFnsLocale
} = {
  en: enUSDateFns,
  fr: frDateFns,
}

export function useDateFnsLocale(): DateFnsLocale {
  const intl = useIntl()
  return dateFnsMapping[intl.locale]
}

const getLocale = (): AvailableLocales => {
  let requestedLocales = [...navigator.languages]
  let localeInLS = localStorage.getItem("locale")
  if (localeInLS) requestedLocales = [localeInLS, ...requestedLocales]
  return match(
    requestedLocales,
    [...availableLocales],
    defaultLocale
  ) as AvailableLocales
}

interface Props {
  forceLocale?: AvailableLocales
  children: React.ReactNode
}

const TranslationsProvider = ({ forceLocale, children }: Props) => {
  const locale = forceLocale || getLocale()
  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={messagesMapping[locale]}
    >
      {children}
    </IntlProvider>
  )
}

export default TranslationsProvider
