import { Box } from "@mui/material"
import { PlateElement } from "@udecode/plate"

import { useCloudAttachmentElementState } from "ds/RichTextNew/plugins/cloud"

import { CloudAttachmentProps } from "./CloudAttachmentProps"

// At this point we know that the attachment is uploaded
// This would not be the case fi we were using the cloudImagePlugin or the the imagePlugin from @udecode/plate-media

export default function CloudAudioAttachment(props: CloudAttachmentProps) {
  const { children, element } = props
  const { focused, selected } = useCloudAttachmentElementState({
    element,
  })

  return (
    <PlateElement {...props}>
      <Box
        contentEditable={false}
        sx={{
          userSelect: "none",
          "> audio": {
            maxHeight: "400px",
            maxWidth: "100%",
            outline: (theme) =>
              focused && selected
                ? `2px solid ${theme.palette.primary[300]}`
                : undefined,
            m: 0,
            my: 1,
            display: "block",
            borderRadius: 1,
            boxSizing: "border-box",
          },
        }}
        draggable
      >
        <audio
          controls
          onClick={(e) => e.stopPropagation()}
          controlsList="nodownload noplaybackrate"
          src={element.url}
        />
      </Box>
      {children}
    </PlateElement>
  )
}
