import AddIcon from "@mui/icons-material/Add"
import NotesIcon from "@mui/icons-material/Notes"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import Stack from "ds/Stack"

import ArgumentPersonalNoteTooltip from "./ArgumentPersonalNoteTooltip"

interface Props {
  onClick: () => void
}

export default function ArgumentPersonalNoteCTA({ onClick }: Props) {
  return (
    <ArgumentPersonalNoteTooltip>
      <Button
        onClick={onClick}
        variant="outlined"
        startIcon={<AddIcon fontSize="small" />}
        endIcon={<NotesIcon fontSize="small" />}
      >
        <Stack spacing={1} direction="row" alignItems="center">
          <FormattedMessage id="playbook.argumentPersonalNote.cta" />
        </Stack>
      </Button>
    </ArgumentPersonalNoteTooltip>
  )
}
