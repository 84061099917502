import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { apiIndexAll } from "api/arguments"

import Button from "ds/Button"
import { BasicSuccessSnack } from "ds/Snackbar"

export default function Indexation() {
  const [isLoading, setIsLoading] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)

  return (
    <>
      <Button
        color="primary"
        disabled={isLoading}
        onClick={async () => {
          setIsLoading(true)
          await apiIndexAll()
          setSuccessSnackOpen(true)
          setIsLoading(false)
        }}
      >
        {isLoading ? (
          <FormattedMessage
            id="superadmin.indexation.loading"
            defaultMessage="Indexing..."
          />
        ) : (
          <FormattedMessage
            id="superadmin.indexation.cta"
            defaultMessage="Index all"
          />
        )}
      </Button>

      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
    </>
  )
}
