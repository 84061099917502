import React from "react"
import { createRoot } from "react-dom/client"

import App from "components/App"
import AppWrappers from "components/AppWrappers"
import WebAppLoginGuard from "components/WebAppLoginGuard"

const container = document.getElementById("root")!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <AppWrappers>
      <WebAppLoginGuard>
        <App />
      </WebAppLoginGuard>
    </AppWrappers>
  </React.StrictMode>
)
