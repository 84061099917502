import MuiButtonGroup, { ButtonGroupProps } from "@mui/material/ButtonGroup"
import { forwardRef } from "react"

export type { ButtonGroupProps }

export default forwardRef(function ButtonGroup(
  props: ButtonGroupProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiButtonGroup {...props} ref={ref} />
})
