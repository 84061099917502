// Fetch the configuration from the backend on load of the app
import axios from "./axios"
import { IFrontendConfig } from "./types/frontendConfig"

// Don't send an authorization token, it is not available at this point
export const getFrontendConfig = (): Promise<IFrontendConfig> =>
  axios
    .get("api/frontend_config", {
      headers: { Authorization: "None" },
    })
    .then((res) => res.data)
