import { IOrganization } from "api/types/organizations"

import Paper from "ds/Paper"
import Table from "ds/Table"
import TableBody from "ds/TableBody"
import TableContainer from "ds/TableContainer"

import OrganizationsTableHead from "./OrganizationsTableHead"
import OrganizationsTableRow from "./OrganizationsTableRow"

interface Props {
  organizations: IOrganization[]
}

export default function OrganizationsTable({ organizations }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }}>
        <OrganizationsTableHead />

        <TableBody>
          {organizations.map((organization) => (
            <OrganizationsTableRow
              key={organization.id}
              organization={organization}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
