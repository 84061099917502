import { FormattedMessage } from "react-intl"

import Button from "ds/Button"

interface Props {
  disabled: boolean
  onSync: () => void
}

export default function ReferencesRefreshButton({ disabled, onSync }: Props) {
  return (
    <Button variant="outlined" disabled={disabled} onClick={onSync}>
      <FormattedMessage id="playbookEdit.argumentTypes.references.refresh" />
    </Button>
  )
}
