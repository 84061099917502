import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import MenuItem from "ds/MenuItem"

import { copyArguments } from "services/copyPaste"

interface Props {
  argument: IArgument
  onSuccess: () => void
}

export default function CopyArgument({ argument, onSuccess }: Props) {
  async function onClick() {
    copyArguments([argument.id])
    onSuccess()
  }

  return (
    <MenuItem onClick={onClick}>
      <FormattedMessage id="playbookEdit.argument.copy" />
    </MenuItem>
  )
}
