import { selectEditor, usePlateSelectors } from "@udecode/plate"
import { useEffect } from "react"

export default function AutoFocusEffect() {
  const plateSelectors = usePlateSelectors()
  const isMounted = plateSelectors.isMounted()
  const editor = plateSelectors.editor()
  useEffect(() => {
    setTimeout(() => {
      if (editor && isMounted) {
        selectEditor(editor, { edge: "end", focus: true })
      }
    }, 0)
  }, [isMounted, editor])

  return null
}
