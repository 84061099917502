import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgumentGroup } from "api/types/argumentGroups"
import { IArgument } from "api/types/arguments"

import MenuItem from "ds/MenuItem"
import { AlertSnackbar } from "ds/Snackbar"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentGroupDeleteMutation } from "components/App/Playbook/queries/argumentGroupQueries"

interface DeleteArgumentGroupProps {
  arguments: IArgument[]
  argumentGroup: IArgumentGroup
  afterDelete: () => void
}

export default function DeleteArgumentGroup({
  arguments: _arguments,
  argumentGroup,
  afterDelete,
}: DeleteArgumentGroupProps) {
  const { playbook } = usePlaybook()
  const deleteMutation = useArgumentGroupDeleteMutation(playbook.id)
  const [warningSnackOpen, setWarningSnackOpen] = useState(false)

  const onClick = () => {
    const nonArchivedArgumentsInGroup = _arguments
      .filter((argument) => argument.argumentGroupId === argumentGroup.id)
      .filter((argument) => !argument.archived)

    if (nonArchivedArgumentsInGroup.length > 0) {
      setWarningSnackOpen(true)
    } else {
      deleteMutation.mutate(argumentGroup.id, {
        onSuccess: () => afterDelete(),
      })
    }
  }

  return (
    <>
      <MenuItem onClick={onClick} sx={{ color: "error.main" }}>
        <FormattedMessage
          id="playbookEdit.argumentGroups.delete"
          defaultMessage="Delete"
        />
      </MenuItem>

      <AlertSnackbar
        severity="warning"
        open={warningSnackOpen}
        onClose={() => setWarningSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.argumentGroups.notEmptyWarning" />
      </AlertSnackbar>
    </>
  )
}
