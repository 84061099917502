import DownloadIcon from "@mui/icons-material/Download"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IBarePlaybook, IPlaybook } from "api/types/playbooks"

import IconButton from "ds/IconButton"
import { AlertSnackbar } from "ds/Snackbar"
import Tooltip from "ds/Tooltip"

import { SuccessMessage } from "components/App/BusinessUnit/BusinessUnitPlaybooks/BusinessUnitPlaybooks"
import { useImportPlaybookMutation } from "components/App/BusinessUnit/BusinessUnitPlaybooks/businessUnitPlaybookQueries"
import { useBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"

interface IProps {
  playbook: IPlaybook
}

export default function ImportPublicPlaybook({ playbook }: IProps) {
  const businessUnit = useBusinessUnit()
  const [justImportedPlaybook, setJustImportedPlaybook] =
    useState<null | IBarePlaybook>(null)
  const importMutation = useImportPlaybookMutation(businessUnit.organizationId)

  function importPlaybook() {
    importMutation.mutate(
      {
        playbookId: playbook.id,
        businessUnitId: businessUnit.id,
      },
      {
        onSuccess: ({ playbook: newPlaybook }) => {
          setJustImportedPlaybook(newPlaybook)
        },
      }
    )
  }

  return (
    <>
      <Tooltip
        title={
          <FormattedMessage id="businessUnits.playbooks.actions.import.publicPlaybookCTA" />
        }
      >
        <IconButton
          onClick={() => importPlaybook()}
          disabled={importMutation.isLoading}
        >
          <DownloadIcon
            fontSize="small"
            sx={{ fill: (theme) => theme.palette.common.white }}
          />
        </IconButton>
      </Tooltip>

      <AlertSnackbar
        severity="success"
        open={!!justImportedPlaybook}
        onClose={() => setJustImportedPlaybook(null)}
      >
        {justImportedPlaybook ? (
          <SuccessMessage playbook={justImportedPlaybook} />
        ) : undefined}
      </AlertSnackbar>
    </>
  )
}
