import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import MenuItem from "ds/MenuItem"
import { UnstyledRouterLink } from "ds/UnstyledLink"

import { useArgumentModalLocation } from "components/App/Playbook/Argument/routing"

interface Props {
  argument: IArgument
  onClick: React.MouseEventHandler<HTMLLIElement>
}

export default function ArgumentEdit({ argument, onClick }: Props) {
  const argumentModalLocation = useArgumentModalLocation(argument.id)
  return (
    <MenuItem
      component={UnstyledRouterLink}
      to={argumentModalLocation}
      onClick={onClick}
    >
      <FormattedMessage id="common.edit" defaultMessage="Edit" />
    </MenuItem>
  )
}
