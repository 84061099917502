import { useTheme } from "@mui/material"
import MuiTable, { TableProps } from "@mui/material/Table"
import { forwardRef } from "react"

export type { TableProps }

export default forwardRef(function Table(
  props: TableProps,
  ref: React.Ref<HTMLTableElement>
) {
  const theme = useTheme()
  return (
    <MuiTable
      {...props}
      ref={ref}
      sx={{
        ...props.sx,
        "& thead th": {
          ...theme.typography.smSemi,
        },
        "& tbody td": {
          ...theme.typography.smNormal,
        },
      }}
    />
  )
})
