import { useQuery } from "@tanstack/react-query"

import { apiGetDashboardEmbedURL } from "api/dashboards"

function buildDashboardKey(businessUnitId: string) {
  return ["dashboard", businessUnitId]
}

export function useDashboardQuery(businessUnitId: string) {
  return useQuery(
    buildDashboardKey(businessUnitId),
    ({ signal }) => apiGetDashboardEmbedURL({ businessUnitId }, { signal }),
    { cacheTime: 0 } // URL is only usable once
  )
}
