import { Draggable } from "@hello-pangea/dnd"
import { useIntl } from "react-intl"
import styled from "styled-components"

import { IArgument } from "api/types/arguments"

import Checkbox from "ds/Checkbox"
import DragAndDropHandle from "ds/DragAndDropHandle"

import { argumentCardId } from "components/App/Playbook/Argument/ArgumentCard"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { filterNonArchived } from "services/archivable"

import isTouchDevice from "utils/isTouchDevice"

import ArgumentActions from "../Argument/ArgumentActions/ArgumentActions"
import ArgumentCellContent from "./ArgumentCellContent"
import MatrixGrid from "./MatrixGrid"
import SegmentRuleCellContent from "./SegmentRuleCellContent"
import StickyCheckboxContainer from "./StickyCheckboxContainer"

interface StyledRowProps {
  $isSelected: boolean
}

// Reduce opacity of checkbox unless hover or touch device or selected
const StyledRow = styled(MatrixGrid)<StyledRowProps>`
  position: relative;

  > .bulkEditCheckbox {
    opacity: ${({ $isSelected }) =>
      isTouchDevice() || $isSelected ? 1.0 : 0.5};
    transition: ${({ theme }) => theme.transitions.create("opacity")};
  }

  &:hover {
    > .bulkEditCheckbox {
      opacity: unset;
    }

    > .drag-handle-argument {
      opacity: 1;
    }
  }
`

const DragHandleContainer = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.spacing(-4)};
  top: 5px;
  opacity: 0.2;
  transition: ${({ theme }) => theme.transitions.create("opacity")};
`

interface Props {
  argument: IArgument
  index: number
  onBulkActionCheckboxClick: (_arguments: IArgument[]) => void
  isSelectedForBulkAction: boolean
  nbColumns: number
}

export default function MatrixArgumentRow({
  argument,
  index,
  onBulkActionCheckboxClick,
  isSelectedForBulkAction,
  nbColumns,
}: Props) {
  const intl = useIntl()
  const { playbook } = usePlaybook()
  const activeArgumentSegmentations = filterNonArchived(
    playbook.argumentSegmentations
  )

  const selectArgumentLabel = intl.formatMessage({
    id: "playbookEdit.argument.select",
    defaultMessage: "Select argument",
  })

  return (
    <Draggable draggableId={argument.id} index={index} key={argument.id}>
      {(providedDraggable) => (
        <StyledRow
          id={argumentCardId(argument.id)}
          $nbColumns={nbColumns}
          $isSelected={isSelectedForBulkAction}
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
        >
          <DragHandleContainer className="drag-handle-argument">
            <DragAndDropHandle
              dragHandleProps={providedDraggable.dragHandleProps}
            />
          </DragHandleContainer>

          <ArgumentCellContent argument={argument} />

          <ArgumentActions argument={argument} />

          <StickyCheckboxContainer>
            <Checkbox
              onChange={() => onBulkActionCheckboxClick([argument])}
              checked={isSelectedForBulkAction}
              inputProps={{ "aria-label": selectArgumentLabel }}
              className="bulkEditCheckbox"
            />
          </StickyCheckboxContainer>

          {activeArgumentSegmentations.map((argumentSegmentation) => (
            <SegmentRuleCellContent
              key={argumentSegmentation.id}
              argument={argument}
              argumentSegmentation={argumentSegmentation}
            />
          ))}

          {/* Add a blank div if no segmentation */}
          {activeArgumentSegmentations.length === 0 && <div />}
        </StyledRow>
      )}
    </Draggable>
  )
}
