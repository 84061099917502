import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"

import {
  apiCreateArgumentType,
  apiDeleteArgumentType,
  apiPasteArgumentTypes,
  apiRestoreArgumentType,
  apiUpdateArgumentType,
  apiUpdateArgumentTypePosition,
} from "api/argumentTypes"
import { IArgumentType, IArgumentTypeResponse } from "api/types/argumentTypes"
import { IGetPlaybookResponse } from "api/types/playbooks"

import { moveArgumentType } from "services/argumentTypes"

import { useOptimisticMutation, useUpdateMutation } from "utils/hooks/mutations"
import { replaceById } from "utils/mutationHelpers"

import { buildArgumentsKey } from "./argumentQueries"
import { buildPlaybookKey, setPlaybook } from "./playbookQueries"

function setArgumentTypes(
  prevData: IGetPlaybookResponse,
  updateFn: (prevArgumentTypes: IArgumentType[]) => IArgumentType[]
) {
  return setPlaybook(prevData, (prevPlaybook) => ({
    ...prevPlaybook,
    argumentTypes: updateFn(prevPlaybook.argumentTypes),
  }))
}

function setArgumentType(
  prevData: IGetPlaybookResponse,
  argumentType: IArgumentType
) {
  return setArgumentTypes(prevData, (prevArgumentTypes) =>
    replaceById(prevArgumentTypes, argumentType)
  )
}

function useArgumentTypeMutation<TError, TVariables>(
  playbookId: string,
  mutationFn: MutateFunction<IArgumentTypeResponse, TError, TVariables>
) {
  return useUpdateMutation(
    buildPlaybookKey(playbookId),
    mutationFn,
    (result, prevData: IGetPlaybookResponse | undefined) =>
      prevData && setArgumentType(prevData, result.argumentType)
  )
}

export const useArgumentTypeUpdateMutation = (playbookId: string) =>
  useArgumentTypeMutation(playbookId, apiUpdateArgumentType)

export const useArgumentTypeDeleteMutation = (playbookId: string) =>
  useArgumentTypeMutation(playbookId, apiDeleteArgumentType)

export const useArgumentTypeRestoreMutation = (playbookId: string) =>
  useArgumentTypeMutation(playbookId, apiRestoreArgumentType)

export const useArgumentTypeCreateMutation = (playbookId: string) =>
  useUpdateMutation(
    buildPlaybookKey(playbookId),
    apiCreateArgumentType,
    (result, prevData: IGetPlaybookResponse | undefined) =>
      prevData &&
      setArgumentTypes(prevData, (prevArgumentTypes) => [
        ...prevArgumentTypes,
        result.argumentType,
      ])
  )

export const useArgumentTypeUpdatePositionMutation = (playbookId: string) =>
  useOptimisticMutation(
    buildPlaybookKey(playbookId),
    apiUpdateArgumentTypePosition,
    (prevData: IGetPlaybookResponse | undefined, { id, relativeId }) =>
      prevData &&
      setArgumentTypes(prevData, (prevArgumentTypes) =>
        moveArgumentType(prevArgumentTypes, id, relativeId)
      )
  )

export const usePasteArgumentTypesMutation = (
  playbookId: string,
  argumentTypeId: string
) => {
  const queryClient = useQueryClient()
  return useMutation(apiPasteArgumentTypes, {
    onSuccess: () => {
      queryClient.invalidateQueries(buildPlaybookKey(playbookId))
      queryClient.invalidateQueries(buildArgumentsKey(argumentTypeId))
    },
  })
}
