import { INotification } from "api/types/notifications"

import FormattedTime from "ds/FormattedTime"
import Typography from "ds/Typography"

interface Props {
  notification: INotification
}

export default function NotificationTimestamp({ notification }: Props) {
  return (
    <Typography variant="xsNormal" component="div">
      <FormattedTime value={notification.createdAt} format="shortTime" />
    </Typography>
  )
}
