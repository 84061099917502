import DoneAllIcon from "@mui/icons-material/DoneAll"
import { FormattedMessage } from "react-intl"

import IconButton from "ds/IconButton"
import Tooltip from "ds/Tooltip"

interface Props {
  active: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
  disabled: boolean
}

export default function AllowMultipleSelectionCTA({
  active,
  onClick,
  disabled,
}: Props) {
  return (
    <Tooltip
      title={
        <FormattedMessage id="playbookEdit.argumentSegmentations.allowMultipleSelection" />
      }
    >
      <span>
        <IconButton
          onClick={onClick}
          disabled={disabled}
          opacityTransitionOnHover={!active}
          color={active ? "primary" : "default"}
        >
          <DoneAllIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  )
}
