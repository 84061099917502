import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import Stack from "ds/Stack"

interface Props {
  onSubmit: () => void
  onCancel: () => void
  disabled: boolean
}

export default function ArgumentSegmentRulesFormButtons({
  onSubmit,
  onCancel,
  disabled,
}: Props) {
  return (
    <Stack spacing={2} direction="row" justifyContent="end">
      <Button
        onClick={onCancel}
        disabled={disabled}
        variant="outlined"
        color="neutral"
      >
        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
      </Button>

      <Button
        type="submit"
        onClick={onSubmit}
        disabled={disabled}
        variant="contained"
      >
        <FormattedMessage
          id="playbookEdit.argument.segmentRules.submit"
          defaultMessage="Update segments"
        />
      </Button>
    </Stack>
  )
}
