import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"

import {
  acceptOrganizationInvitation,
  apiGetOwnPendingInvitations,
  resendInvitation,
  revokeInvitation,
} from "api/organizationInvitations"
import { apiSearchPendingInvitations, inviteUser } from "api/organizations"

import { buildBusinessUnitPendingInvitationsKey } from "components/App/BusinessUnit/businessUnitQueries"

import { useInvalidatingMutation } from "utils/hooks/mutations"

function buildOwnInvitationsKey() {
  return ["ownOrganizationInvitations"]
}
function buildOrganizationInvitationsKey(organizationId: string) {
  return ["organizationInvitations", organizationId]
}

function buildOrganizationPendingInvitationsKey(organizationId: string) {
  return [...buildOrganizationInvitationsKey(organizationId), { pending: true }]
}

function buildOrganizationPendingInvitationsSearchKey(
  organizationId: string,
  query: string
) {
  return ["organizationInvitations", organizationId, { pending: true }, query]
}

// Pending invitations for the current query, contains the private token
// See OrganizationInvitationSerializer
export const useOwnPendingInvitationsQuery = () =>
  useQuery(buildOwnInvitationsKey(), apiGetOwnPendingInvitations)

export const useInvitationAcceptMutation = () =>
  useMutation(acceptOrganizationInvitation)

// Pending invitations for a given organization, does not contain the private token
// See OrganizationPendingInvitationSerializer
export const usePendingInvitationsInfiniteQuery = (
  organizationId: string,
  query: string
) =>
  useInfiniteQuery(
    buildOrganizationPendingInvitationsSearchKey(organizationId, query),
    ({ pageParam, signal }) =>
      apiSearchPendingInvitations(
        { organizationId, paginationCursor: pageParam, query },
        { signal }
      ),
    {
      getNextPageParam: (lastPage) => lastPage.paginationCursor,
    }
  )

export const useInviteCreateMutation = (
  organizationId: string,
  businessUnitIds: string[]
) => {
  const queryClient = useQueryClient()
  return useMutation(inviteUser, {
    onSuccess: () => {
      invalidateBusinessUnitsAndOrganizationPendingInvitationQueries(
        queryClient,
        organizationId,
        businessUnitIds
      )
    },
  })
}

export const useInviteResendMutation = () => useMutation(resendInvitation)

export const useInviteRevokeMutation = (organizationId: string) =>
  useInvalidatingMutation(
    buildOrganizationInvitationsKey(organizationId),
    revokeInvitation
  )

const invalidateBusinessUnitsAndOrganizationPendingInvitationQueries = (
  queryClient: QueryClient,
  organizationId: string,
  businessUnitIds: string[]
) => {
  queryClient.invalidateQueries(
    buildOrganizationPendingInvitationsKey(organizationId)
  )
  businessUnitIds.forEach((businessUnitId) =>
    queryClient.invalidateQueries(
      buildBusinessUnitPendingInvitationsKey(businessUnitId)
    )
  )
}
