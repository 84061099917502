// Default textarea:
//   - Not resizeable
//   - Height scales with the content
//   - minRows and maxRows
//   - Padding
//   - Full width
//   - display block
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "@mui/material/TextareaAutosize"
import { forwardRef } from "react"
import styled from "styled-components"

const StyledTextareaAutosize = styled(TextareaAutosize)`
  resize: none;
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  border-color: ${({ theme }) => theme.palette.grey[300]};
  padding: ${({ theme }) => theme.spacing(0.75)}
    ${({ theme }) => theme.spacing(1.5)};
  display: block;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  // Minimum of 16px, see https://stackoverflow.com/a/7655319/6193734
  &,
  &:active,
  &:focus {
    font-size: ${({ theme }) => theme.typography.smNormal.fontSize};
    line-height: ${({ theme }) => theme.typography.smNormal.lineHeight};
  }

  &:disabled {
    opacity: 0.6;
    background-color: ${({ theme }) => theme.palette.grey[300]};
  }
`

export default forwardRef(function Textarea(
  props: TextareaAutosizeProps,
  ref?: React.Ref<HTMLTextAreaElement> | undefined
) {
  return <StyledTextareaAutosize minRows={4} maxRows={8} ref={ref} {...props} />
})
