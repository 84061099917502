import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import Menu from "ds/Menu"
import { AlertSnackbar } from "ds/Snackbar"
import Stack from "ds/Stack"

import useAnchorEl from "utils/hooks/useAnchorEl"

import MoveArgumentsModal from "../MoveArgumentsModal"
import ArchiveArguments from "./ArgumentBulkActions/ArchiveArguments"
import BulkEditSegments from "./ArgumentBulkActions/BulkEditSegments"
import CopyArguments from "./ArgumentBulkActions/CopyArguments"
import DeselectAllArguments from "./ArgumentBulkActions/DeselectAllArguments"
import DuplicateArguments from "./ArgumentBulkActions/DuplicateArguments"
import MoveArguments from "./ArgumentBulkActions/MoveArguments"
import OtherBulkActionsButton from "./ArgumentBulkActions/OtherBulkActionsButton"
import RestoreArguments from "./ArgumentBulkActions/RestoreArguments"

interface Props {
  selectedArguments: IArgument[]
  clearSelection: () => void
}

export default function ArgumentBulkActions({
  selectedArguments,
  clearSelection,
}: Props) {
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const [isMoveArgumentsModalOpen, setIsMoveArgumentsModalOpen] =
    useState(false)
  const [copySuccessSnackOpen, setCopySuccessSnackOpen] = useState(false)
  const selectedArgumentIds = selectedArguments.map(({ id }) => id)

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          opacity: selectedArguments.length > 0 ? 1 : 0,
          transition: (theme) => theme.transitions.create("opacity"),
        }}
      >
        {selectedArguments.length > 0 && (
          <BulkEditSegments
            arguments={selectedArguments}
            onSuccess={clearSelection}
          />
        )}

        {selectedArguments.length > 0 && (
          <OtherBulkActionsButton
            onClick={handleClick}
            nbArguments={selectedArguments.length}
          />
        )}

        {selectedArguments.length > 0 && (
          <DeselectAllArguments onClick={clearSelection} />
        )}

        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MoveArguments
            onClick={() => {
              setIsMoveArgumentsModalOpen(true)
              handleClose()
            }}
          />

          <DuplicateArguments
            argumentIds={selectedArgumentIds}
            onSuccess={() => {
              handleClose()
              clearSelection()
            }}
          />

          <CopyArguments
            argumentIds={selectedArgumentIds}
            onSuccess={() => {
              handleClose()
              clearSelection()
              setCopySuccessSnackOpen(true)
            }}
          />

          <ArchiveArguments
            argumentIds={selectedArgumentIds}
            onSuccess={() => {
              handleClose()
              clearSelection()
            }}
          />

          <RestoreArguments
            argumentIds={selectedArgumentIds}
            onSuccess={() => {
              handleClose()
              clearSelection()
            }}
          />
        </Menu>

        {isMoveArgumentsModalOpen && (
          <MoveArgumentsModal
            argumentIds={selectedArgumentIds}
            afterSubmit={clearSelection}
            close={() => setIsMoveArgumentsModalOpen(false)}
          />
        )}
      </Stack>

      <AlertSnackbar
        severity="success"
        open={copySuccessSnackOpen}
        onClose={() => setCopySuccessSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.copy.success" />
      </AlertSnackbar>
    </>
  )
}
