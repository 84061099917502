import MuiListItemIcon, { ListItemIconProps } from "@mui/material/ListItemIcon"
import { forwardRef } from "react"

export type { ListItemIconProps }

export default forwardRef(function ListItemIcon(
  props: ListItemIconProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiListItemIcon {...props} ref={ref} />
})
