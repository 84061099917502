import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from "@mui/material/Typography"
import { Variant as TypographyVariant } from "@mui/material/styles/createTypography"
import { forwardRef } from "react"

export type { TypographyVariant }

export type TypographyProps = Omit<MuiTypographyProps, "fontWeight">

export default forwardRef(function Typography(
  props: TypographyProps,
  ref: React.Ref<HTMLSpanElement>
) {
  return <MuiTypography {...props} ref={ref} />
})
