import { useIntl } from "react-intl"
import styled from "styled-components"

import { IArgumentComment } from "api/types/argumentComments"

import EmojiPicker, { IEmoji } from "ds/EmojiPicker"
import IconButton from "ds/IconButton"
import Popover from "ds/Popover"
import Tooltip from "ds/Tooltip"

import { ReactComponent as AddEmojiIcon } from "images/add_emoji.svg"

import useAnchorEl from "utils/hooks/useAnchorEl"

const AddReactionButton = styled(IconButton)`
  padding: 2.5px;
  height: 100%;
  fill: ${({ theme }) => theme.palette.text.secondary};

  svg {
    height: 15px;
  }

  // change ripple color
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.palette.warning.dark}3f;
    fill: unset;
  }
  .MuiTouchRipple-child {
    background-color: ${({ theme }) => theme.palette.warning.dark};
  }
` as typeof IconButton

interface Props {
  comment: IArgumentComment
  toggleReaction: (
    alreadyReacted: boolean,
    commentId: string,
    emojiId: string
  ) => void
}

export default function AddReaction({ comment, toggleReaction }: Props) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  function onEmojiSelect(emoji: IEmoji) {
    const alreadyReacted = comment.reactions.some(
      (reaction) => reaction.emojiName === emoji.id
    )
    toggleReaction(alreadyReacted, comment.id, emoji.id)
    handleClose()
  }

  return (
    <>
      <Tooltip
        title={intl.formatMessage({
          id: "argumentComments.reactions.add",
          defaultMessage: "Add a reaction",
        })}
      >
        <AddReactionButton onClick={handleClick}>
          <AddEmojiIcon />
        </AddReactionButton>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: { boxShadow: "none", bgcolor: "transparent" },
        }}
      >
        <EmojiPicker onEmojiSelect={onEmojiSelect} />
      </Popover>
    </>
  )
}
