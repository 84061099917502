import axios from "./axios"
import {
  IMeeting,
  IMeetingCreateParams,
  IMeetingCreateResponse,
  IMeetingEndParams,
  IMeetingUpdateCRMDealParams,
  IMeetingUpdateCRMInformationParams,
  IMeetingUpdateGeneralNoteParams,
  IMeetingUsersSearchParams,
  IMeetingsFiltersParams,
  IMeetingsIndexResponse,
  IUpcomingMeeting,
} from "./types/meetings"
import { IUser } from "./types/users"

export const apiGetMeeting = (
  id: string,
  { signal }: { signal?: AbortSignal }
): Promise<IMeeting> =>
  axios.get(`api/meetings/${id}`, { signal }).then((res) => res.data)

export const apiGetMeetings = (
  businessUnitId: string,
  filters: IMeetingsFiltersParams,
  paginationCursor: string | null
): Promise<IMeetingsIndexResponse> =>
  axios
    .get(`api/meetings`, {
      params: {
        businessUnitId,
        ...filters,
        paginationCursor,
      },
    })
    .then((res) => res.data)

export const apiGetCurrentMeeting = (
  playbookId: string,
  { signal }: { signal?: AbortSignal }
): Promise<IMeeting | null> =>
  axios
    .get("api/meetings/current", { params: { playbookId }, signal })
    .then((res) => res.data)

export const apiCreateMeeting = (
  params: IMeetingCreateParams
): Promise<IMeetingCreateResponse> =>
  axios.post("api/meetings", params).then((res) => res.data)

export const apiRestartMeeting = (meetingId: string): Promise<undefined> =>
  axios.patch(`api/meetings/${meetingId}/restart`).then((res) => res.data)

export const apiEndCurrentMeeting = (
  params: IMeetingEndParams
): Promise<undefined> =>
  axios
    .patch(`api/meetings/${params.meetingId}/end`, params)
    .then((res) => res.data)

export const apiDiscardMeeting = (meetingId: string): Promise<undefined> =>
  axios.patch(`api/meetings/${meetingId}/discard`).then((res) => res.data)

export const apiUpdateMeetingGeneralNote = (
  params: IMeetingUpdateGeneralNoteParams
): Promise<IMeeting> =>
  axios
    .patch(`api/meetings/${params.id}/update_general_note`, params)
    .then((res) => res.data)

export const apiUpdateMeetingCRMInformation = (
  params: IMeetingUpdateCRMInformationParams
): Promise<IMeeting> =>
  axios
    .patch(`api/meetings/${params.id}/update_crm_information`, params)
    .then((res) => res.data)

export const apiUpdateMeetingCRMDeal = (
  params: IMeetingUpdateCRMDealParams
): Promise<IMeeting> =>
  axios
    .patch(`api/meetings/${params.id}/update_crm_deal`, params)
    .then((res) => res.data)

export const apiGetUpcomingMeetings = (
  businessUnitId: string,
  crmContactIds: string[],
  crmCompanyId?: string
): Promise<IUpcomingMeeting[]> =>
  axios
    .get("api/meetings/upcoming", {
      params: { businessUnitId, crmCompanyId, crmContactIds },
    })
    .then((res) => res.data)

export const apiLinkToExternalMeeting = ({
  meetingId,
  externalMeetingId,
}: {
  meetingId: string
  externalMeetingId?: string
}): Promise<IMeeting> =>
  axios
    .patch(`api/meetings/${meetingId}/link_to_external_meeting`, {
      externalMeetingId,
    })
    .then((res) => res.data)

export const apiUpdateMeetingCompany = ({
  meetingId,
  companyId,
}: {
  meetingId: string
  companyId: string | null
}): Promise<IMeeting> =>
  axios
    .patch(`api/meetings/${meetingId}/update_crm_company`, {
      companyId,
    })
    .then((res) => res.data)

export const apiUpdateMeetingContacts = ({
  meetingId,
  contactIds,
}: {
  meetingId: string
  contactIds: string[]
}): Promise<IMeeting> =>
  axios
    .patch(`api/meetings/${meetingId}/update_crm_contacts`, {
      contactIds,
    })
    .then((res) => res.data)

export const apiSearchMeetingsUsers = (
  params: IMeetingUsersSearchParams
): Promise<IUser[]> =>
  axios.get("api/meetings/search_users", { params }).then((res) => res.data)
