import axios from "./axios"
import {
  IOrganizationMembershipUpdateCanEditPlaybooksParams,
  IOrganizationMembershipUpdateRoleParams,
} from "./types/organizationMemberships"

export const apiDeactivateOrganizationMembership = (
  id: string
): Promise<undefined> =>
  axios
    .patch(`api/organization_memberships/${id}/deactivate`)
    .then((res) => res.data)

export const apiReactivateOrganizationMembership = (
  id: string
): Promise<undefined> =>
  axios
    .patch(`api/organization_memberships/${id}/reactivate`)
    .then((res) => res.data)

export const apiUpdateOrganizationMembershipRole = (
  params: IOrganizationMembershipUpdateRoleParams
): Promise<undefined> =>
  axios
    .patch(`api/organization_memberships/${params.id}/update_role`, params)
    .then((res) => res.data)

export const apiUpdateCanEditPlaybooks = (
  params: IOrganizationMembershipUpdateCanEditPlaybooksParams
): Promise<undefined> =>
  axios
    .patch(
      `api/organization_memberships/${params.id}/update_can_edit_playbooks`,
      params
    )
    .then((res) => res.data)
