import MuiMenuList, { MenuListProps } from "@mui/material/MenuList"
import { forwardRef } from "react"

export type { MenuListProps }

export default forwardRef(function MenuList(
  props: MenuListProps,
  ref: React.Ref<HTMLUListElement>
) {
  return <MuiMenuList {...props} ref={ref} />
})
