import axios from "./axios"
import {
  IArgumentTypePasteParams,
  IArgumentTypePasteResponse,
  IArgumentTypeResponse,
  IUpdateArgumentTypeParams,
  IUpdateArgumentTypePositionParams,
} from "./types/argumentTypes"

export const apiUpdateArgumentType = ({
  argumentTypeId,
  argumentType,
}: {
  argumentTypeId: String
  argumentType: IUpdateArgumentTypeParams
}): Promise<IArgumentTypeResponse> =>
  axios
    .patch(`api/argument_types/${argumentTypeId}`, argumentType)
    .then((res) => res.data)

export const apiUpdateArgumentTypePosition = ({
  id,
  relativeId,
}: IUpdateArgumentTypePositionParams): Promise<undefined> =>
  axios
    .patch(`api/argument_types/${id}/update_position`, {
      relativeId: relativeId,
    })
    .then((res) => res.data)

export const apiDeleteArgumentType = (
  id: string
): Promise<IArgumentTypeResponse> =>
  axios.delete(`api/argument_types/${id}`).then((res) => res.data)

export const apiRestoreArgumentType = (
  id: string
): Promise<IArgumentTypeResponse> =>
  axios.patch(`api/argument_types/${id}/restore`).then((res) => res.data)

export const apiCreateArgumentType = (
  playbookId: string
): Promise<IArgumentTypeResponse> =>
  axios.post("api/argument_types", { playbookId }).then((res) => res.data)

export const apiPasteArgumentTypes = (
  params: IArgumentTypePasteParams
): Promise<IArgumentTypePasteResponse> =>
  axios.post("api/argument_types/paste", params).then((res) => res.data)
