import { IOrganization } from "./organizations"
import { IUser } from "./users"

export interface IOrganizationMembershipUpdateRoleParams {
  id: string
  role: string
}

export interface IOrganizationMembershipUpdateCanEditPlaybooksParams {
  id: string
  canEditPlaybooks: boolean
}

export const organizationMembershipRoles = ["ADMIN", "MEMBER"] as const
export type IOrganizationMembershipRole =
  typeof organizationMembershipRoles[number]

export interface IOrganizationMembership {
  id: string
  organizationId: string
  active: boolean
  role: IOrganizationMembershipRole
  canEditPlaybooks: boolean
  user: IUser
}

export interface ICurrentUserOrganizationMembership {
  id: string
  role: IOrganizationMembershipRole
  organization: IOrganization
}
