import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from "@mui/material/IconButton"
import React, { forwardRef } from "react"

export interface IconButtonProps
  extends Pick<
    MuiIconButtonProps,
    | "disabled"
    | "onClick"
    | "aria-label"
    | "aria-describedby"
    | "aria-pressed"
    | "id"
    | "children"
    | "className"
    | "color"
    | "sx"
  > {
  opacityTransitionOnHover?: boolean
  noBackgroundOnHover?: boolean
}

function IconButton(props: IconButtonProps, ref: React.Ref<HTMLButtonElement>) {
  const { opacityTransitionOnHover, noBackgroundOnHover, ...otherProps } = props

  let sx: MuiIconButtonProps["sx"] = props.sx || {}

  if (props.opacityTransitionOnHover) {
    sx = {
      transition: (theme) => theme.transitions.create("opacity"),
      "&:not(:hover)": {
        opacity: 0.5,
      },
      ...sx,
    }
  }

  if (props.noBackgroundOnHover) {
    sx = {
      "&:hover": {
        background: "transparent",
      },
      ...sx,
    }
  }

  return <MuiIconButton size="small" {...otherProps} ref={ref} sx={sx} />
}

// https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop
export default forwardRef(IconButton) as typeof IconButton
