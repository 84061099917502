import { FormattedMessage } from "react-intl"

import FormControlLabel from "ds/FormControlLabel"
import Switch, { SwitchProps } from "ds/Switch"

interface Props {
  checked: boolean
  onChange: SwitchProps["onChange"]
}

export default function ShowArchivedToggle({ checked, onChange }: Props) {
  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} />}
      label={
        <FormattedMessage id="playbookEdit.argumentSegmentations.showArchived" />
      }
    />
  )
}
