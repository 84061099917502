import { FormattedMessage } from "react-intl"

import MenuItem from "ds/MenuItem"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentsArchiveMutation } from "components/App/Playbook/queries/argumentQueries"

import useConfirm from "utils/hooks/useConfirm"

interface Props {
  argumentIds: string[]
  onSuccess: () => void
}

export default function ArchiveArguments(props: Props) {
  const { activeTypeId } = usePlaybook()
  const archiveMutation = useArgumentsArchiveMutation(activeTypeId)
  const confirm = useConfirm()
  function onClick() {
    if (!confirm()) return
    archiveMutation.mutate(props.argumentIds, { onSuccess: props.onSuccess })
  }

  return (
    <MenuItem
      onClick={onClick}
      disabled={archiveMutation.isLoading}
      sx={{ color: "error.main" }}
    >
      <FormattedMessage id="playbookEdit.argument.archive" />
    </MenuItem>
  )
}
