import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { getRawTitle } from "services/argumentFields"

import CheckboxIndicator from "../../Argument/ArgumentCard/ArgumentKindIndicator/CheckboxIndicator"

interface Props {
  argument: IArgument
}

export default function UncheckedCheckboxArgument({ argument }: Props) {
  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <CheckboxIndicator argument={argument} />

      <div>
        <Typography variant="smSemi">
          {getRawTitle(argument) || (
            <FormattedMessage id="playbookEdit.argument.defaultTitle" />
          )}
        </Typography>
      </div>
    </Stack>
  )
}
