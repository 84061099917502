import { useTheme } from "@mui/material"
import MuiMenuItem, { MenuItemProps } from "@mui/material/MenuItem"
import React, { forwardRef } from "react"

import isMobileDevice from "utils/isMobileDevice"

export type { MenuItemProps }

function MenuItem<RootComponent extends React.ElementType>(
  {
    children,
    ...props
  }: MenuItemProps<RootComponent, { component?: RootComponent }>,
  ref: React.Ref<HTMLLIElement>
) {
  const theme = useTheme()
  return (
    <MuiMenuItem
      {...props}
      sx={{
        ...theme.typography.smNormal,
        // Only have a min height for mobile devices to touch more easily
        minHeight: isMobileDevice() ? "48px" : 0,
        ...props.sx,
      }}
      ref={ref}
    >
      {children}
    </MuiMenuItem>
  )
}

// https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop
export default forwardRef(MenuItem) as typeof MenuItem
