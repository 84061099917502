import { Box } from "@mui/material"
import { PlateElement } from "@udecode/plate-common"
import {
  useTodoListElement,
  useTodoListElementState,
} from "@udecode/plate-list"
import styled from "styled-components"

import Checkbox from "ds/Checkbox"

import { MyPlateElementProps } from "../types"

export default function TodoList(props: MyPlateElementProps) {
  const state = useTodoListElementState({ element: props.element })
  const { checkboxProps } = useTodoListElement(state)

  return (
    <StyledPlateElement {...props}>
      <Box
        component="span"
        display="flex"
        mr={0.75}
        alignItems="center"
        justifyContent="center"
        sx={{ userSelect: "none" }}
        contentEditable={false}
      >
        <Checkbox
          inputProps={{
            // @ts-ignore // Useful for serialization
            "data-checked": checkboxProps.checked,
          }}
          sx={{ p: 0 }}
          checked={checkboxProps.checked}
          onChange={(e) => checkboxProps.onCheckedChange(e.target.checked)}
          onMouseDown={(e) => e.preventDefault()}
        />
      </Box>
      <Box
        component="span"
        flex="1 1 0%"
        sx={{
          "&:focus": { outline: "none" },
          textDecoration: state.checked ? "line-through" : undefined,
          color: state.checked
            ? (theme) => theme.palette.text.disabled
            : undefined,
        }}
        contentEditable={!state.readOnly}
        suppressContentEditableWarning
      >
        {props.children}
      </Box>
    </StyledPlateElement>
  )
}

const StyledPlateElement = styled(PlateElement)`
  display: flex;
  align-items: center;
`
