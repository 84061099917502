import { useState } from "react"
import { FormattedMessage, IntlShape, useIntl } from "react-intl"

import { apiCreateCRMCompany, apiPullCRMCompany } from "api/CRMCompanies"
import {
  ICRMCompany,
  ICRMCompanySearchResult,
  isExternalCompanySearchResult,
} from "api/types/CRMCompanies"

import { AlertSnackbar } from "ds/Snackbar"

import CRMCompanySelector, {
  CRMCompanySelectorProps,
} from "components/App/CRM/companies/CRMCompanySelector"

import { buildCrmCompanySearchResult } from "services/crmCompanies"

const NEW_OPTION_ID = "new"

interface CreatableOption {
  origin: "DB"
  id: string
  name: string
  inputValue: string
}

function isCreatableOption(
  option: ICRMCompanySearchResult | CreatableOption
): option is CreatableOption {
  return option.id === NEW_OPTION_ID
}

function buildCreatableOption(
  inputValue: string,
  intl: IntlShape
): CreatableOption {
  return {
    origin: "DB",
    id: NEW_OPTION_ID,
    name: intl.formatMessage(
      {
        id: "playbook.meeting.information.createCompanyNameInputTitle",
        defaultMessage: 'Create account "{name}"',
      },
      { name: inputValue }
    ),
    inputValue,
  }
}

function InputLabel() {
  return <FormattedMessage id="playbook.meeting.information.crmCompany.title" />
}

interface Props
  extends Omit<
    CRMCompanySelectorProps<false, false, CreatableOption>,
    "onChange" | "filterOptions" | "multiple" | "value" | "disableClearable"
  > {
  businessUnitId: string
  value: ICRMCompany | null
  onChange: (newValue: ICRMCompany | null) => void
  includeCreatable?: boolean
}

const CRMCompanyCreatable = ({
  value,
  onChange,
  businessUnitId,
  inputLabel = <InputLabel />,
  includeCreatable = true,
  ...props
}: Props) => {
  const intl = useIntl()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const onChangeAutocomplete: CRMCompanySelectorProps<
    false,
    false,
    CreatableOption
  >["onChange"] = async (_event, newValue) => {
    if (!newValue) return onChange(null)

    if (isCreatableOption(newValue)) {
      const { crmCompany } = await apiCreateCRMCompany({
        name: newValue.inputValue,
        businessUnitId,
      })
      return onChange(crmCompany)
    } else if (isExternalCompanySearchResult(newValue)) {
      try {
        const { crmCompanies } = await apiPullCRMCompany({
          businessUnitId,
          externalIds: [newValue.id],
        })
        return onChange(crmCompanies[0] || null)
      } catch {
        setErrorSnackOpen(true)
      }
    } else {
      return onChange(newValue.crmCompany)
    }
  }

  return (
    <>
      <CRMCompanySelector
        value={value && buildCrmCompanySearchResult(value)}
        businessUnitId={businessUnitId}
        onChange={onChangeAutocomplete}
        isOptionAlternative={isCreatableOption}
        inputLabel={inputLabel}
        filterOptions={(options, { inputValue }) => {
          if (!inputValue || !includeCreatable) return options
          return [...options, buildCreatableOption(inputValue, intl)]
        }}
        multiple={false}
        {...props}
      />

      <AlertSnackbar
        severity="error"
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbook.meeting.information.crmCompany.pull.error" />
      </AlertSnackbar>
    </>
  )
}

export default CRMCompanyCreatable
