import { useState } from "react"

import {
  IArgument,
  IArgumentSegmentRule,
  IArgumentSegmentRuleMultiple,
} from "api/types/arguments"

import Modal from "ds/Modal"
import { BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentBulkUpdateMutation } from "components/App/Playbook/queries/argumentQueries"

import { filterNonArchived } from "services/archivable"
import {
  buildInitialSegmentRules,
  filterUpdatableSegmentRules,
} from "services/argumentSegmentRules"

import useConfirm from "utils/hooks/useConfirm"
import { updateByPredicate } from "utils/mutationHelpers"

import ArgumentSegmentRulesFormButtons from "./ArgumentSegmentRulesFormButtons"
import ArgumentSegmentRulesTitle from "./ArgumentSegmentRulesTitle"
import SegmentRules from "./SegmentRules"

interface Props {
  close: () => void
  arguments: IArgument[]
  onSuccess?: () => void
}

export default function EditArgumentSegmentRules({
  close,
  arguments: _arguments,
  onSuccess,
}: Props) {
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const { playbook, activeTypeId } = usePlaybook()
  const bulkUpdateMutation = useArgumentBulkUpdateMutation(activeTypeId)
  const confirm = useConfirm({ messageKey: "leaveWithoutSaving" })
  const initialSegmentRules = buildInitialSegmentRules(_arguments, playbook)
  const [segmentRules, setSegmentRules] = useState(initialSegmentRules)

  const closeWithConfirmation = () => {
    if (
      JSON.stringify(segmentRules) === JSON.stringify(initialSegmentRules) ||
      confirm()
    ) {
      close()
    }
  }

  const setSegmentRule: (
    rule: IArgumentSegmentRule | IArgumentSegmentRuleMultiple
  ) => React.Dispatch<
    React.SetStateAction<IArgumentSegmentRule | IArgumentSegmentRuleMultiple>
  > = (rule) => (ruleOrUpdater) =>
    setSegmentRules((prev) =>
      updateByPredicate(
        prev,
        (el) => el.argumentSegmentationId === rule.argumentSegmentationId,
        typeof ruleOrUpdater === "function"
          ? ruleOrUpdater
          : () => ruleOrUpdater
      )
    )

  function onSubmit() {
    const filteredSegmentRules = filterUpdatableSegmentRules(segmentRules)
    bulkUpdateMutation.mutate(
      {
        argumentIds: _arguments.map(({ id }) => id),
        playbookId: playbook.id,
        segmentRules: filteredSegmentRules,
      },
      {
        onSuccess: () => {
          onSuccess?.()
          close()
        },
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <Modal isOpen onClose={closeWithConfirmation} widthVariant="medium">
      <Stack spacing={2}>
        <ArgumentSegmentRulesTitle _arguments={_arguments} />

        <Stack spacing={2}>
          <SegmentRules
            argumentSegmentations={filterNonArchived(
              playbook.argumentSegmentations
            )}
            segmentRules={segmentRules}
            setSegmentRule={setSegmentRule}
          />

          <ArgumentSegmentRulesFormButtons
            onSubmit={onSubmit}
            onCancel={close}
            disabled={bulkUpdateMutation.isLoading}
          />
        </Stack>

        <BasicErrorSnack
          open={errorSnackOpen}
          onClose={() => setErrorSnackOpen(false)}
        />
      </Stack>
    </Modal>
  )
}
