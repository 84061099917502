import SearchIcon from "@mui/icons-material/Search"
import { useIntl } from "react-intl"

import { IArgumentGroup } from "api/types/argumentGroups"

import Fab from "ds/Fab"
import Popover from "ds/Popover"

import useAnchorEl from "utils/hooks/useAnchorEl"

import AddToGroupSearch from "./AddToGroupSearch"

interface Props {
  argumentGroup: IArgumentGroup | null
}

export default function AddExistingArgument({ argumentGroup }: Props) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const id = open
    ? `add-existing-argument-to-group-${argumentGroup?.id || "none"}`
    : undefined

  return (
    <>
      <Fab
        variant="extended"
        size="small"
        onClick={handleClick}
        sx={{ color: "text.primary", bgcolor: "background.paper" }}
        title={intl.formatMessage({
          id: "playbookEdit.arguments.addExisting",
          defaultMessage: "Add an existing argument",
        })}
        aria-describedby={id}
      >
        <SearchIcon />
      </Fab>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "center" }}
        transformOrigin={{ horizontal: "center", vertical: "center" }}
      >
        <AddToGroupSearch argumentGroup={argumentGroup} onClose={handleClose} />
      </Popover>
    </>
  )
}
