import axios from "./axios"
import { IArgumentFieldValueUpdateParams } from "./types/argumentFieldValues"
import { IArgumentResponse } from "./types/arguments"

export const apiUpdateArgumentFieldValue = (
  params: IArgumentFieldValueUpdateParams
): Promise<IArgumentResponse> =>
  axios
    .patch("api/argument_field_values/upsert", params)
    .then((res) => res.data)
