import { Helmet } from "react-helmet-async"

import { useMeQuery } from "authentication/useMe"

import Loader from "ds/Loader"
import UncontrolledError from "ds/UncontrolledError"

// This import is aliased in the craco config to avoid adding Google Analytics to the bundle
import useGoogleAnalytics from "utils/hooks/useGoogleAnalytics"

import RootRouter from "./App/RootRouter"
import { useConfig } from "./Config"

export default function App() {
  const { featureFlags } = useConfig()
  useGoogleAnalytics(featureFlags.googleAnalytics)
  const meQuery = useMeQuery()

  if (meQuery.isLoading) return <Loader />
  if (meQuery.error) return <UncontrolledError error={meQuery.error} />
  if (!meQuery.data) throw new Error("Missing user")

  return (
    <>
      <Helmet>
        <title>SalesPath</title>
      </Helmet>
      <RootRouter />
    </>
  )
}
