import { FormattedMessage } from "react-intl"

import Tooltip from "ds/Tooltip"

interface Props {
  children: React.ReactElement
}

export default function ArgumentPersonalNoteTooltip({ children }: Props) {
  return (
    <Tooltip
      title={<FormattedMessage id="playbook.argumentPersonalNote.tooltip" />}
      placement="top"
    >
      {children}
    </Tooltip>
  )
}
