import {
  TrailingBlockPlugin,
  createTrailingBlockPlugin,
  isElement,
} from "@udecode/plate"

import { MyEditor, MyValue } from "../types"
import { ELEMENT_CLOUD_ATTACHMENT } from "./cloud"

export const trailingBlockPlugin = createTrailingBlockPlugin<
  TrailingBlockPlugin,
  MyValue,
  MyEditor
>({
  options: {
    filter: (entry) =>
      isElement(entry[0]) && entry[0].type === ELEMENT_CLOUD_ATTACHMENT,
  },
})
