import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { ICRMProperty } from "api/types/CRMProperties"

import Alert from "ds/Alert"
import Button from "ds/Button"
import Modal from "ds/Modal"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import CRMPropertySelector from "components/App/CRM/properties/CRMPropertySelector"
import CrmPropertyRefresh from "components/App/CRM/properties/CrmPropertyRefresh"

function filterDealBooleanProperties(properties: ICRMProperty[]) {
  return properties.filter(
    (property) =>
      property.objectType === "Deal" && property.dataType === "boolean"
  )
}

interface Props {
  businessUnitId: string
  disabled: boolean
  crmPropertyId: string | null
  onCrmPropertyIdChange: (crmPropertyId: string | null) => void
  onSync: () => void
}

export default function ReferencesSetupButton({
  businessUnitId,
  disabled,
  crmPropertyId,
  onCrmPropertyIdChange,
  onSync,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setIsModalOpen(true)}
        disabled={disabled}
      >
        <FormattedMessage id="playbookEdit.argumentTypes.references.configure.title" />
      </Button>

      <Modal
        widthVariant="medium"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Stack spacing={4}>
          <Typography variant="baseSemi">
            <FormattedMessage id="playbookEdit.argumentTypes.references.configure.title" />
          </Typography>

          <Alert severity="info">
            <FormattedMessage id="playbookEdit.argumentTypes.references.configure.explanation" />
          </Alert>

          <Stack direction="row" alignSelf="center">
            <CRMPropertySelector
              businessUnitId={businessUnitId}
              fullWidth
              crmPropertyId={crmPropertyId}
              onChange={(_, newProperty) => {
                onCrmPropertyIdChange(newProperty?.id || null)
              }}
              restrictOptions={(options) =>
                filterDealBooleanProperties(options)
              }
            />
            <CrmPropertyRefresh />
          </Stack>

          <Button
            variant="contained"
            sx={{ alignSelf: "end" }}
            disabled={!crmPropertyId}
            onClick={() => {
              onSync()
              setIsModalOpen(false)
            }}
          >
            <FormattedMessage id="playbookEdit.argumentTypes.references.configure.submit" />
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
