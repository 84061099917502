import NotesIcon from "@mui/icons-material/Notes"

import { IVote } from "api/types/votes"

import Tooltip from "ds/Tooltip"

interface Props {
  vote: IVote
}

export default function ReadonlyVoteNote({ vote }: Props) {
  return (
    <Tooltip title={vote.note}>
      <NotesIcon
        sx={{ fill: (theme) => theme.palette.primary.main }}
        fontSize="small"
      />
    </Tooltip>
  )
}
