import { IMeeting } from "api/types/meetings"

import Section from "ds/Section"
import Stack from "ds/Stack"

import { DownvotesHeader } from "components/App/Playbook/Meeting/MeetingNotes/Votes/Downvotes"
import { UpvotesHeader } from "components/App/Playbook/Meeting/MeetingNotes/Votes/Upvotes"

import { getDownvotes, getUpvotes } from "services/votes"

import MeetingActions from "./MeetingActions"
import MeetingArgumentNotes from "./MeetingArgumentNotes"
import MeetingCRMInformations from "./MeetingCRMInformations"
import MeetingGeneralNote from "./MeetingGeneralNote"
import MeetingVotes from "./MeetingVotes"

interface Props {
  meeting: IMeeting
}

export default function MeetingNotes({ meeting }: Props) {
  const upvotes = meeting && getUpvotes(meeting.votes)
  const downvotes = meeting && getDownvotes(meeting.votes)

  return (
    <Stack spacing={2}>
      <Section>
        <MeetingCRMInformations meeting={meeting} />
      </Section>

      <Section>
        <MeetingGeneralNote meeting={meeting} />
      </Section>

      {meeting.meetingArgumentNotes.length > 0 && (
        <Section>
          <MeetingArgumentNotes
            meeting={meeting}
            meetingArgumentNotes={meeting.meetingArgumentNotes}
          />
        </Section>
      )}

      {upvotes.length > 0 && (
        <Section>
          <Stack spacing={2}>
            <UpvotesHeader />
            <MeetingVotes
              votes={upvotes}
              playbookId={meeting.playbookId}
              businessUnitId={meeting.businessUnitId}
            />
          </Stack>
        </Section>
      )}

      {downvotes.length > 0 && (
        <Section>
          <Stack spacing={2}>
            <DownvotesHeader />
            <MeetingVotes
              votes={downvotes}
              playbookId={meeting.playbookId}
              businessUnitId={meeting.businessUnitId}
            />
          </Stack>
        </Section>
      )}

      <MeetingActions meeting={meeting} />
    </Stack>
  )
}
