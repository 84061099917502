import React, { useState } from "react"

const useAnchorEl = <T extends Element = Element>() => {
  const [anchorEl, setAnchorEl] = useState<T | null>(null)

  const open = Boolean(anchorEl)

  function handleClick(event: React.MouseEvent<T> | React.KeyboardEvent<T>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return [anchorEl, open, handleClick, handleClose] as const
}

export default useAnchorEl
