import { IArgumentGroup } from "api/types/argumentGroups"

import { getEmojiById } from "ds/EmojiPicker"
import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import DefaultArgumentGroupName from "./DefaultArgumentGroupName"

interface Props {
  argumentGroup: IArgumentGroup
}

export default function ArgumentGroupNameDisplay({ argumentGroup }: Props) {
  return (
    <Typography variant="baseNormal">
      <Stack spacing={1} direction="row">
        {argumentGroup.emojiName && (
          <span>{getEmojiById(argumentGroup.emojiName)}</span>
        )}
        <span>
          {argumentGroup.rtRawName ? (
            <RichTextEditor
              value={argumentGroup.rtName || buildNodeFromText("")}
              readOnly
            />
          ) : (
            <DefaultArgumentGroupName />
          )}
        </span>
      </Stack>
    </Typography>
  )
}
