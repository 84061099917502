import AddReactionIcon from "@mui/icons-material/AddReaction"
import { FormattedMessage, useIntl } from "react-intl"

import Button from "ds/Button"
import EmojiPicker, { IEmoji, getEmojiById } from "ds/EmojiPicker"
import IconButton from "ds/IconButton"
import Popover from "ds/Popover"
import Stack from "ds/Stack"

import useAnchorEl from "utils/hooks/useAnchorEl"

interface Props {
  value: string | null
  onChange: (newValue: string | null) => void
}

export const EMOJI_POPOVER_CLASS_NAME = "emoji-popover"

export function eventTargetIsInEmojiPopover(
  event: MouseEvent | TouchEvent
): boolean {
  return (
    !!event.target &&
    event.target instanceof Element &&
    !!event.target.closest(`.${EMOJI_POPOVER_CLASS_NAME}`)
  )
}

export default function ArgumentGroupEmojiSelector(props: Props) {
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const emoji = props.value ? getEmojiById(props.value) : null
  const intl = useIntl()

  function onEmojiSelect(emoji: IEmoji | null) {
    handleClose()
    props.onChange(emoji ? emoji.id : null)
  }
  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-label={intl.formatMessage({
          id: "playbookEdit.argumentGroups.emoji",
        })}
      >
        {emoji || (
          <AddReactionIcon
            fontSize="small"
            sx={{ fill: (theme) => theme.palette.grey[500] }}
          />
        )}
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        className={EMOJI_POPOVER_CLASS_NAME}
        PaperProps={{
          sx: { bgcolor: "transparent", boxShadow: "none" },
        }}
      >
        <Stack spacing={1}>
          {props.value && (
            <Button
              color="neutral"
              variant="contained"
              onClick={() => onEmojiSelect(null)}
            >
              <FormattedMessage id="common.remove" />
            </Button>
          )}

          <EmojiPicker onEmojiSelect={onEmojiSelect} />
        </Stack>
      </Popover>
    </>
  )
}
