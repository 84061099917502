import { useState } from "react"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IArgument } from "api/types/arguments"

import UnstyledButton from "ds/UnstyledButton"

import ArgumentSegmentRulesForm from "components/App/Playbook/PlaybookEdit/ArgumentSegmentRules/EditArgumentSegmentRules"

import SegmentRuleDisplay from "./SegmentRuleDisplay"

interface Props {
  argument: IArgument
  argumentSegmentation: IArgumentSegmentation
}

export default function SegmentRuleCellContent({
  argument,
  argumentSegmentation,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <UnstyledButton
        onClick={() => setIsModalOpen(true)}
        style={{ width: "100%" }}
      >
        <SegmentRuleDisplay
          argument={argument}
          argumentSegmentation={argumentSegmentation}
        />
      </UnstyledButton>

      {isModalOpen && (
        <ArgumentSegmentRulesForm
          close={() => setIsModalOpen(false)}
          arguments={[argument]}
        />
      )}
    </>
  )
}
