import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { apiSearchMeetingsUsers } from "api/meetings"
import { IUser } from "api/types/users"

import AsyncAutocomplete from "ds/AsyncAutocomplete"
import { CustomAutocompleteProps } from "ds/Autocomplete"
import { AlertSnackbar } from "ds/Snackbar"

function InputLabel() {
  return <FormattedMessage id="user.label" defaultMessage="User" />
}

interface Props<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends Omit<
    CustomAutocompleteProps<IUser, Multiple, DisableClearable, false>,
    "options" | "loading" | "getOptionLabel" | "inputLabel" | "freeSolo"
  > {
  businessUnitId: string
  inputLabel?: React.ReactNode
}

export default function MeetingUsersSelector<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>({
  businessUnitId,
  inputLabel = <InputLabel />,
  ...props
}: Props<Multiple, DisableClearable>) {
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  return (
    <>
      <AsyncAutocomplete
        inputLabel={inputLabel}
        getOptions={(name) =>
          apiSearchMeetingsUsers({
            businessUnitId,
            name,
          })
        }
        onGetOptionsFailure={() => setErrorSnackOpen(true)}
        getOptionLabel={(option) => option.name || option.email}
        {...props}
      />
      <AlertSnackbar
        severity="error"
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      >
        <FormattedMessage id="playbook.meeting.information.users.search.error" />
      </AlertSnackbar>
    </>
  )
}
