import { identity } from "lodash"
import { FormattedMessage } from "react-intl"

import { ICRMProperty } from "api/types/CRMProperties"

import Autocomplete, { CustomAutocompleteProps } from "ds/Autocomplete"

import { useCrmPropertiesQuery } from "./crmPropertyQueries"

function InputLabel() {
  return <FormattedMessage id="playbookEdit.crmProperties.label" />
}

// TODO translate property option label
function getOptionLabel(crmProperty: ICRMProperty) {
  return `${crmProperty.objectType} - ${crmProperty.name}`
}

interface Props
  extends Omit<
    CustomAutocompleteProps<ICRMProperty, false, false, false>,
    | "options"
    | "loading"
    | "getOptionLabel"
    | "inputLabel"
    | "value"
    | "freeSolo"
    | "multiple"
  > {
  crmPropertyId: string | null
  businessUnitId: string
  inputLabel?: React.ReactNode
  restrictOptions?: (options: ICRMProperty[]) => ICRMProperty[]
}

export default function CRMPropertySelector({
  businessUnitId,
  inputLabel = <InputLabel />,
  crmPropertyId,
  restrictOptions = identity,
  ...props
}: Props) {
  const { data, isLoading } = useCrmPropertiesQuery(businessUnitId)

  const value =
    (data || []).find((property) => property.id === crmPropertyId) || null

  return (
    <Autocomplete
      inputLabel={inputLabel}
      options={restrictOptions(data || [])}
      loading={isLoading}
      getOptionLabel={getOptionLabel}
      renderOption={(props, crmProperty) => (
        <li {...props} key={`${crmProperty.objectType}-${crmProperty.id}`}>
          {getOptionLabel(crmProperty)}
        </li>
      )}
      value={value}
      {...props}
    />
  )
}
