import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"
import { IPlaybook } from "api/types/playbooks"

import Alert from "ds/Alert"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import InvalidAnswer from "./InvalidAnswer"

interface Props {
  argumentsWithInvalidAnswer: IArgument[]
  onReplyClick: () => void
  playbook: IPlaybook
}

export default function argumentsWithInvalidValue({
  argumentsWithInvalidAnswer,
  onReplyClick,
}: Props) {
  return (
    <Stack spacing={2}>
      <Alert severity="error">
        <Typography variant="smNormal">
          <FormattedMessage id="playbook.meeting.answersWithInvalidValues" />
        </Typography>
      </Alert>

      {argumentsWithInvalidAnswer.slice(0, 5).map((argument) => (
        <InvalidAnswer
          key={argument.id}
          argument={argument}
          onReplyClick={onReplyClick}
        />
      ))}

      {argumentsWithInvalidAnswer.length > 5 && (
        <Box sx={{ color: "error.main" }}>
          +{argumentsWithInvalidAnswer.length - 5}
        </Box>
      )}
    </Stack>
  )
}
