import { RenderAfterEditable } from "@udecode/plate"
import { ELEMENT_LINK, LinkPlugin, createLinkPlugin } from "@udecode/plate-link"

import Link from "../components/Link"
import LinkFloatingToolbar from "../components/LinkFloatingToolbar"
import { MyEditor, MyValue } from "../types"

export const linkPlugin = createLinkPlugin<LinkPlugin, MyValue, MyEditor>({
  // `as RenderAfterEditable` is copied on plate-ui demo
  renderAfterEditable: LinkFloatingToolbar as RenderAfterEditable,
})

export const linkComponents = {
  [ELEMENT_LINK]: Link,
}
