import axios from "./axios"
import { IReferencesSyncParams } from "./types/referencesConfigurations"

export const apiSyncReferences = ({
  argumentTypeId,
  crmPropertyId,
}: IReferencesSyncParams): Promise<undefined> =>
  axios
    .post("api/references_configurations/sync", {
      argumentTypeId,
      crmPropertyId,
    })
    .then((res) => res.data)
