import { useRef, useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgumentGroup } from "api/types/argumentGroups"

import FormControl from "ds/FormControl"
import InputLabel from "ds/InputLabel"
import OutlinedInput from "ds/OutlinedInput"
import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { eventTargetIsInDialog } from "ds/RichTextNew/components/DialogClassName"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import { MyValue } from "ds/RichTextNew/types"
import SubmitInputAdornment from "ds/SubmitInputAdornment"
import UnstyledButton from "ds/UnstyledButton"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import useConfirm from "utils/hooks/useConfirm"
import useOnClickOutside from "utils/hooks/useOnClickOutside"
import { onKeyDownEnter, onKeyDownEscape } from "utils/onKeyDown"

import { useArgumentGroupUpdateMutation } from "../queries/argumentGroupQueries"
import ArgumentGroupEmojiSelector, {
  eventTargetIsInEmojiPopover,
} from "./ArgumentGroupEmojiSelector"
import ArgumentGroupNameDisplay from "./ArgumentGroupNameDisplay"

interface Props {
  argumentGroup: IArgumentGroup
}

const initialRtName = buildNodeFromText("")
function isDirty(
  argumentGroup: IArgumentGroup,
  rtName: MyValue,
  emojiName: string | null
): boolean {
  if (
    JSON.stringify(rtName) !==
    JSON.stringify(argumentGroup.rtName || initialRtName)
  )
    return true
  if (emojiName !== argumentGroup.emojiName) return true
  return false
}

export default function EditableArgumentGroupName({ argumentGroup }: Props) {
  const { playbook, users } = usePlaybook()
  const updateMutation = useArgumentGroupUpdateMutation(playbook.id)
  const [rtName, setRtName] = useState(argumentGroup.rtName || initialRtName)
  const [rtRawName, setRtRawName] = useState(argumentGroup.rtRawName || "")
  const [emojiName, setEmojiName] = useState(argumentGroup.emojiName)
  const [isEditing, setIsEditing] = useState(false)
  const confirm = useConfirm({ messageKey: "leaveWithoutSaving" })
  const textFieldRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const cancel = () => {
    setIsEditing(false)
    setRtName(argumentGroup.rtName || initialRtName)
    setRtRawName(argumentGroup.rtRawName || "")
    setEmojiName(argumentGroup.emojiName)
  }

  const confirmCancel = (e: React.KeyboardEvent) => {
    e.preventDefault() // Don't close the modal
    if (!isDirty(argumentGroup, rtName, emojiName) || confirm()) {
      cancel()
    }
  }

  const submit = () =>
    updateMutation.mutate(
      { id: argumentGroup.id, argumentGroup: { rtName, rtRawName, emojiName } },
      { onSuccess: () => setIsEditing(false) }
    )

  useOnClickOutside(textFieldRef, (event) => {
    if (eventTargetIsInEmojiPopover(event)) return
    if (eventTargetIsInDialog(event)) return
    submit()
  })

  const inputId = `argumentGroupNameInput-${argumentGroup.id}`
  const label = (
    <FormattedMessage
      id="playbookEdit.argumentGroups.name"
      defaultMessage="Name"
    />
  )

  if (isEditing) {
    return (
      <FormControl ref={textFieldRef} fullWidth>
        <InputLabel shrink htmlFor={inputId}>
          {label}
        </InputLabel>

        <OutlinedInput
          ref={inputRef}
          // @ts-ignore // TODO RichText typing
          inputComponent={RichTextEditor}
          sx={{
            "& .MuiOutlinedInput-input": {
              p: 1,
              pb: 0,
              flex: 1,
              height: "auto",
            },
          }}
          id={inputId}
          value={argumentGroup.rtName || buildNodeFromText("")}
          // @ts-ignore // TODO RichText typing
          onChange={(newValue, newRawValue) => {
            setRtName(newValue)
            setRtRawName(newRawValue)
          }}
          // @ts-ignore // TODO RichText typing
          inputProps={{
            mentionees: users,
            autoFocus: true,
            onKeyDown: (event) => {
              onKeyDownEnter((e) => {
                e.stopPropagation()
                if (e.metaKey) {
                  e.preventDefault()
                  submit()
                }
              })(event)
              onKeyDownEscape(confirmCancel)(event)
            },
          }}
          startAdornment={
            <ArgumentGroupEmojiSelector
              value={emojiName}
              onChange={(newEmojiName) => {
                setEmojiName(newEmojiName)
                setTimeout(() => inputRef.current?.focus(), 0) // Wait for popover to close
              }}
            />
          }
          endAdornment={
            <SubmitInputAdornment
              onSubmit={submit}
              onCancel={cancel}
              disabled={updateMutation.isLoading}
            />
          }
          label={label}
          notched
        />
      </FormControl>
    )
  }

  return (
    <UnstyledButton onClick={() => setIsEditing(true)}>
      <ArgumentGroupNameDisplay argumentGroup={argumentGroup} />
    </UnstyledButton>
  )
}
