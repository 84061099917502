import { Box } from "@mui/material"

// Simple Box with a padding, a shadow, and a radius
export default function Section({ children }: { children: React.ReactNode }) {
  return (
    <Box
      borderRadius={1}
      boxShadow={6}
      p={2}
      bgcolor={(theme) => theme.palette.common.white}
    >
      {children}
    </Box>
  )
}
