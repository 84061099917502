import { focusEditor, useEditorRef } from "@udecode/plate"

import EmojiPicker, { IEmoji } from "ds/EmojiPicker"
import Popover from "ds/Popover"

import { DialogClassName } from "../../DialogClassName"

interface Props {
  anchorEl: Element | null
  isOpen: boolean
  onClose: () => void
}

const EmojiPickerPopover = ({ anchorEl, isOpen, onClose }: Props) => {
  const editor = useEditorRef()
  function onEmojiSelect(emoji: IEmoji) {
    editor.insertNode({ text: emoji.native })
    onClose()
    focusEditor(editor)
  }

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      // Don't trap focus inside the modal, this will make selecting the editor impossible
      disableEnforceFocus
      className={DialogClassName}
      PaperProps={{
        sx: { boxShadow: "none", bgcolor: "transparent" },
      }}
    >
      <EmojiPicker onEmojiSelect={onEmojiSelect} />
    </Popover>
  )
}

export default EmojiPickerPopover
