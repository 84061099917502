import { useIntl } from "react-intl"

import { IArgumentSegment } from "api/types/argumentSegments"

import Checkbox from "ds/Checkbox"
import MenuItem from "ds/MenuItem"
import Select, { SelectProps } from "ds/Select"

import ArgumentSegmentName from "components/App/Playbook/ArgumentSegmentation/ArgumentSegmentName"

import SelectedSegmentNames from "./SelectedSegmentNames"

interface Props {
  value: string[]
  onChange: SelectProps<string[]>["onChange"]
  argumentSegments: IArgumentSegment[]
}

export default function SegmentRuleSegmentsSelect({
  value,
  onChange,
  argumentSegments,
}: Props) {
  const intl = useIntl()

  const label = intl.formatMessage({
    id: "playbookEdit.argument.segmentRules.argumentSegments.label",
    defaultMessage: "Segments for this rule",
  })

  return (
    <Select
      multiple
      value={value}
      onChange={onChange}
      sx={{ fontSize: "0.8rem" }}
      aria-label={label}
      renderValue={(selectedSegmentIds) => (
        <SelectedSegmentNames
          selectedSegmentIds={selectedSegmentIds}
          argumentSegments={argumentSegments}
        />
      )}
    >
      {argumentSegments.map((argumentSegment) => (
        <MenuItem key={argumentSegment.id} value={argumentSegment.id}>
          <Checkbox checked={value.includes(argumentSegment.id)} />
          <ArgumentSegmentName argumentSegment={argumentSegment} />
        </MenuItem>
      ))}
    </Select>
  )
}
