import { ICRMProperty } from "./CRMProperties"

export const meetingNoteConfigurationFieldTypes = [
  "multi_select",
  "single_select",
  "boolean",
  "free_text",
  "number",
  "phone",
  "email",
  "url",
  "date",
  "probability",
] as const

export type IMeetingNoteConfigurationFieldType =
  typeof meetingNoteConfigurationFieldTypes[number]

export interface IMeetingNoteConfigurationOption {
  label: string
  value: string
}

export interface IMeetingNoteConfiguration {
  id: string
  crmProperty: ICRMProperty | null
  fieldType: IMeetingNoteConfigurationFieldType
  options: IMeetingNoteConfigurationOption[]
  hasAdditionalInfo: boolean
  appearsInSummary: boolean
  hasOptionOther: boolean
  isMandatory: boolean
}

export type IMeetingNoteConfigurationParams =
  | ICustomMeetingNoteConfigurationParams
  | ICRMPropertyMeetingNoteConfigurationParams

export interface ICustomMeetingNoteConfigurationParams {
  argumentId: string
  fieldType: string
  options: IMeetingNoteConfigurationOption[]
  hasOptionOther: boolean
  hasAdditionalInfo: boolean
  appearsInSummary: boolean
  isMandatory: boolean
}

export interface ICRMPropertyMeetingNoteConfigurationParams {
  argumentId: string
  crmPropertyId: string
  isMandatory: boolean
  appearsInSummary: boolean
}
