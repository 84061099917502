import { ModalProps } from "@mui/material"
import {
  Breakpoints,
  Theme,
  ThemeOptions,
  createTheme,
} from "@mui/material/styles"
import { deepmerge } from "@mui/utils"
import "styled-components"

declare module "@mui/material/styles" {
  interface Palette {
    neutral: PaletteColor
  }

  interface PaletteOptions {
    neutral: PaletteColor // Must specify everything
  }

  interface PaletteColor {
    dark: string
    900: string
    800: string
    700: string
    600: string
    500: string
    main: string
    400: string
    300: string
    200: string
    100: string
    light: string
    50: string
  }

  interface SimplePaletteColorOptions {
    900: string
    800: string
    700: string
    600: string
    500: string
    400: string
    300: string
    200: string
    100: string
    50: string
  }

  interface TypeBackground {
    paper2: string
  }

  interface TypographyVariants {
    xxxlBold: TypographyStyle
    xxlBold: TypographyStyle
    xxlSemi: TypographyStyle
    xxlMed: TypographyStyle // === h1
    xlBold: TypographyStyle
    xlSemi: TypographyStyle // h2
    xlMed: TypographyStyle
    xlNormal: TypographyStyle
    lgBold: TypographyStyle
    lgSemi: TypographyStyle // h3
    lgMed: TypographyStyle
    lgNormal: TypographyStyle
    baseBold: TypographyStyle
    baseSemi: TypographyStyle // h4
    baseMed: TypographyStyle
    baseNormal: TypographyStyle
    smBold: TypographyStyle
    smSemi: TypographyStyle // h5
    smMed: TypographyStyle
    smNormal: TypographyStyle
    xsBold: TypographyStyle
    xsSemi: TypographyStyle
    xsMed: TypographyStyle
    xsNormal: TypographyStyle
    xxsSemi: TypographyStyle
    xxsMed: TypographyStyle
    xxsNormal: TypographyStyle
  }

  interface TypographyStyle {
    fontSize: string
    lineHeight: string
    fontWeight: string
  }

  interface TypographyVariantsOptions {
    xxxlBold: TypographyStyle
    xxlBold: TypographyStyle
    xxlSemi: TypographyStyle
    xxlMed: TypographyStyle // === h1
    xlBold: TypographyStyle
    xlSemi: TypographyStyle // h2
    xlMed: TypographyStyle
    xlNormal: TypographyStyle
    lgBold: TypographyStyle
    lgSemi: TypographyStyle // h3
    lgMed: TypographyStyle
    lgNormal: TypographyStyle
    baseBold: TypographyStyle
    baseSemi: TypographyStyle // h4
    baseMed: TypographyStyle
    baseNormal: TypographyStyle
    smBold: TypographyStyle
    smSemi: TypographyStyle // h5
    smMed: TypographyStyle
    smNormal: TypographyStyle
    xsBold: TypographyStyle
    xsSemi: TypographyStyle
    xsMed: TypographyStyle
    xsNormal: TypographyStyle
    xxsSemi: TypographyStyle
    xxsMed: TypographyStyle
    xxsNormal: TypographyStyle
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h1: false
    h2: false
    h3: false
    h4: false
    h5: false
    h6: false
    subtitle1: false
    subtitle2: false
    body1: false
    body2: false
    caption: false
    button: false
    overline: false
    xxxlBold: true
    xxlBold: true
    xxlSemi: true
    xxlMed: true // === h1
    xlBold: true
    xlSemi: true // h2
    xlMed: true
    xlNormal: true
    lgBold: true
    lgSemi: true // h3
    lgMed: true
    lgNormal: true
    baseBold: true
    baseSemi: true // h4
    baseMed: true
    baseNormal: true
    smBold: true
    smSemi: true // h5
    smMed: true
    smNormal: true
    xsBold: true
    xsSemi: true
    xsMed: true
    xsNormal: true
    xxsSemi: true
    xxsMed: true
    xxsNormal: true
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true
  }
}
const defaultThemeOptions: ThemeOptions = {
  spacing: 8,
  palette: {
    neutral: {
      dark: "#090909",
      900: "#090909",
      800: "#121313",
      700: "#1C1C1D",
      600: "#252627",
      500: "#2F3031",
      main: "#2F3031",
      400: "#434445",
      300: "#6D6E6E",
      200: "#979798",
      100: "#C0C0C1",
      light: "#C0C0C1",
      50: "#D5D5D5",
      contrastText: "#fff",
    },
    primary: {
      dark: "#012977",
      900: "#012977",
      800: "#0339A1",
      700: "#0551C2",
      600: "#0E67DA",
      500: "#2476F1",
      main: "#2476F1",
      400: "#3888FF",
      300: "#589BFF",
      200: "#7EB0FC",
      100: "#B0D0FF",
      light: "#B0D0FF",
      50: "#DEEAFF",
    },
    // Same as primary
    info: {
      dark: "#012977",
      900: "#012977",
      800: "#0339A1",
      700: "#0551C2",
      600: "#0E67DA",
      500: "#2476F1",
      main: "#2476F1",
      400: "#3888FF",
      300: "#589BFF",
      200: "#7EB0FC",
      100: "#B0D0FF",
      light: "#B0D0FF",
      50: "#DEEAFF",
    },
    secondary: {
      dark: "#530583",
      900: "#530583",
      800: "#6D0CA9",
      700: "#8619C9",
      600: "#9A2BDE",
      500: "#AC48EA",
      main: "#AC48EA",
      400: "#C66AFF",
      300: "#D48EFF",
      200: "#E0ADFF",
      100: "#EAC7FF",
      light: "#EAC7FF",
      50: "#F2DDFF",
    },
    success: {
      dark: "#00693D",
      900: "#00693D",
      800: "#05834E",
      700: "#0B985D",
      600: "#1AAC6F",
      500: "#38BD85",
      main: "#38BD85",
      400: "#45DA9B",
      300: "#63F0B5",
      200: "#8AF8CA",
      100: "#B1FFDF",
      light: "#B1FFDF",
      50: "#DCFFEE",
    },
    error: {
      dark: "#92110C",
      900: "#92110C",
      800: "#B21D18",
      700: "#CE211B",
      600: "#DE3934",
      500: "#ED544F",
      main: "#ED544F",
      400: "#FD7A76",
      300: "#FFA4A1",
      200: "#FFC3BF",
      100: "#FFE0DE",
      light: "#FFE0DE",
      50: "#FFF0EF",
      contrastText: "#fff",
    },
    grey: {
      900: "#34416C",
      800: "#4C5A87",
      700: "#616E9A",
      600: "#7985AF",
      500: "#939DC2",
      400: "#A9B2D3",
      300: "#C1C9E5",
      200: "#D4DBF1",
      100: "#E8EDFD",
      50: "#F3F6FF",
    },
    text: {
      primary: "#2F3031",
      secondary: "#939DC2",
    },
    warning: {
      dark: "#B7870E",
      900: "#B7870E",
      800: "#C09600",
      700: "#DBAB01",
      600: "#F1BD03",
      500: "#FFC700",
      main: "#FFC700",
      400: "#FFD640",
      300: "#FFDE65",
      200: "#FFE584",
      100: "#FFEFB5",
      light: "#FFEFB5",
      50: "#FFF8DF",
      contrastText: "#333",
    },
    background: {
      paper2: "#F5F7FF",
    },
  },
  typography: {
    fontFamily: `"Inter variant0", "Arial", sans-serif`,
    h1: undefined,
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    caption: undefined,
    button: undefined,
    overline: undefined,
    xxxlBold: {
      fontSize: "2.25rem",
      lineHeight: "2.625rem",
      fontWeight: "700",
    },
    xxlBold: {
      fontSize: "2rem",
      lineHeight: "2.5rem",
      fontWeight: "700",
    },
    xxlSemi: {
      fontSize: "2rem",
      lineHeight: "2.5rem",
      fontWeight: "600",
    },
    xxlMed: {
      fontSize: "2rem",
      lineHeight: "2.5rem",
      fontWeight: "500",
    },
    xlBold: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
      fontWeight: "700",
    },
    xlSemi: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
      fontWeight: "600",
    },
    xlMed: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
      fontWeight: "500",
    },
    xlNormal: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
      fontWeight: "400",
    },
    lgBold: {
      fontSize: "1.25rem",
      lineHeight: "2rem",
      fontWeight: "700",
    },
    lgSemi: {
      fontSize: "1.25rem",
      lineHeight: "2rem",
      fontWeight: "600",
    },
    lgMed: {
      fontSize: "1.25rem",
      lineHeight: "2rem",
      fontWeight: "500",
    },
    lgNormal: {
      fontSize: "1.25rem",
      lineHeight: "2rem",
      fontWeight: "400",
    },
    baseBold: {
      fontSize: "1rem",
      lineHeight: "1.375rem",
      fontWeight: "700",
    },
    baseSemi: {
      fontSize: "1rem",
      lineHeight: "1.375rem",
      fontWeight: "600",
    },
    baseMed: {
      fontSize: "1rem",
      lineHeight: "1.375rem",
      fontWeight: "500",
    },
    baseNormal: {
      fontSize: "1rem",
      lineHeight: "1.375rem",
      fontWeight: "400",
    },
    smBold: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "700",
    },
    smSemi: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "600",
    },
    smMed: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "500",
    },
    smNormal: {
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      fontWeight: "400",
    },
    xsBold: {
      fontSize: "0.75rem",
      lineHeight: "0.875rem",
      fontWeight: "700",
    },
    xsSemi: {
      fontSize: "0.75rem",
      lineHeight: "0.875rem",
      fontWeight: "600",
    },
    xsMed: {
      fontSize: "0.75rem",
      lineHeight: "0.875rem",
      fontWeight: "500",
    },
    xsNormal: {
      fontSize: "0.75rem",
      lineHeight: "0.875rem",
      fontWeight: "400",
    },
    xxsSemi: {
      fontSize: "0.625rem",
      lineHeight: "0.75rem",
      fontWeight: "600",
    },
    xxsMed: {
      fontSize: "0.625rem",
      lineHeight: "0.75rem",
      fontWeight: "500",
    },
    xxsNormal: {
      fontSize: "0.625rem",
      lineHeight: "0.75rem",
      fontWeight: "400",
    },
  },
  shape: {
    borderRadius: 12,
  },
  shadows: [
    "none",
    "0px 2px 2px 0px rgba(35, 37, 55, 0.08)", // 1
    "0px 4px 4px 0px rgba(35, 37, 55, 0.05)", // 2
    "0px 6px 8px 0px rgba(35, 37, 55, 0.06)", // 3
    "0px 6px 8px 0px rgba(35, 37, 55, 0.12)", // 4
    "0px 8px 8px 0px rgba(35, 37, 55, 0.06)", // 5
    "0px 6px 16px 0px rgba(35, 37, 55, 0.08)", // 6
    "0px 6px 16px 0px rgba(35, 37, 55, 0.12)", // 7
    "0px 8px 12px 0px rgba(35, 37, 55, 0.20)", // 8
    "0px 6px 16px 0px rgba(35, 37, 55, 0.20)", // 9
    "0px 6px 16px 0px rgba(35, 37, 55, 0.24)", // 10
    "0px 12px 20px 0px rgba(35, 37, 55, 0.24)", // 11
    "0px 12px 20px 0px rgba(35, 37, 55, 0.32)", // 12
    "0px 16px 20px 0px rgba(35, 37, 55, 0.12)", // 13
    "0px 16px 20px 0px rgba(35, 37, 55, 0.24)", // 14
    "0px 16px 20px 0px rgba(35, 37, 55, 0.32)", // 15
    "0px 20px 20px 0px rgba(35, 37, 55, 0.12)", // 16
    "0px 20px 20px 0px rgba(35, 37, 55, 0.20)", // 17
    "0px 20px 20px 0px rgba(35, 37, 55, 0.24)", // 18
    "0px 20px 20px 0px rgba(35, 37, 55, 0.32)", // 19
    "0px 12px 40px 0px rgba(35, 37, 55, 0.16)", // 20
    "0px 12px 40px 0px rgba(35, 37, 55, 0.24)", // 21
    "0px 12px 40px 0px rgba(35, 37, 55, 0.32)", // 22
    "-8px 32px 32px 0px rgba(35, 37, 55, 0.12)", // 23
    "-8px 32px 48px 0px rgba(35, 37, 55, 0.12)", // 24
  ],
}

function createContainerOptions(
  container: ModalProps["container"]
): ThemeOptions {
  return {
    components: {
      MuiPopover: {
        defaultProps: {
          container,
        },
      },
      MuiPopper: {
        defaultProps: {
          container,
        },
      },
      MuiModal: {
        defaultProps: {
          container,
        },
      },
      MuiDialog: {
        defaultProps: {
          container,
        },
      },
    },
  }
}

// Use these options to contain the modals to a container.
// The container must be in position "relative"
export function createModalContainerOptions(
  container: ModalProps["container"]
): ThemeOptions {
  return {
    components: {
      MuiModal: {
        defaultProps: {
          container,
          disableEnforceFocus: true,
        },
      },
      MuiDialog: {
        defaultProps: {
          container,
          disableEnforceFocus: true,
        },
      },
      MuiPopover: {
        defaultProps: {
          // Weird that this is needed, but otherwise the modal root teleports when closing the popover which makes the page unusable
          // (Probably an MUI bug)
          // It's possible that the popover overflows the modal, we don't have a good solution for that yet.
          container: document.body,
        },
      },
    },
  }
}

function createBreakpointOptions(
  breakpointValues: Breakpoints["values"]
): ThemeOptions {
  return {
    breakpoints: {
      values: breakpointValues,
    },
  }
}

interface CustomThemeOptions {
  appRoot?: ModalProps["container"]
  breakpointValues?: Breakpoints["values"]
}
export const createCustomTheme = ({
  appRoot,
  breakpointValues,
}: CustomThemeOptions = {}): Theme => {
  let themeOptions = defaultThemeOptions

  if (appRoot) {
    themeOptions = deepmerge(themeOptions, createContainerOptions(appRoot))
  }

  if (breakpointValues) {
    themeOptions = deepmerge(
      themeOptions,
      createBreakpointOptions(breakpointValues)
    )
  }

  return createTheme(themeOptions)
}

declare module "styled-components" {
  export interface DefaultTheme extends Theme {}
}
