import React, { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"
import {
  IMeetingNoteConfigurationFieldType,
  IMeetingNoteConfigurationOption,
} from "api/types/meetingNoteConfigurations"

import Button from "ds/Button"
import Checkbox from "ds/Checkbox"
import FormControlLabel from "ds/FormControlLabel"
import { BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"

import {
  useMeetingNoteConfigurationRemoveMutation,
  useMeetingNoteConfigurationUpsertMutation,
} from "components/App/Playbook/queries/meetingNoteConfigurationQueries"

import ArgumentAnswerBooleanBuilder from "./ArgumentAnswerBooleanBuilder"
import ArgumentAnswerDateBuilder from "./ArgumentAnswerDateBuilder"
import ArgumentAnswerEmailBuilder from "./ArgumentAnswerEmailBuilder"
import ArgumentAnswerFormTypeSelector from "./ArgumentAnswerFormTypeSelector"
import ArgumentAnswerFreeTextBuilder from "./ArgumentAnswerFreeTextBuilder"
import ArgumentAnswerNumberBuilder from "./ArgumentAnswerNumberBuilder"
import ArgumentAnswerPhoneBuilder from "./ArgumentAnswerPhoneBuilder"
import ArgumentAnswerSelectBuilder from "./ArgumentAnswerSelectBuilder"
import ArgumentAnswerUrlBuilder from "./ArgumentAnswerUrlBuilder"

interface Props {
  argument: IArgument
  onSuccess: () => void
}

export default function ArgumentAnswerForm({ argument, onSuccess }: Props) {
  const upsertMutation = useMeetingNoteConfigurationUpsertMutation()
  const removeMutation = useMeetingNoteConfigurationRemoveMutation()
  const [fieldType, setFieldType] = useState<
    IMeetingNoteConfigurationFieldType | ""
  >(argument.meetingNoteConfiguration?.fieldType || "")
  const [options, setOptions] = useState(
    argument.meetingNoteConfiguration?.options || []
  )
  const [isMandatory, setIsMandatory] = useState(
    argument.meetingNoteConfiguration?.isMandatory || false
  )
  const [appearsInSummary, setAppearsInSummary] = useState(
    argument.meetingNoteConfiguration?.appearsInSummary || false
  )
  const [hasOptionOther, setHasOptionOther] = useState(
    !!argument.meetingNoteConfiguration?.hasOptionOther
  )
  const [hasAdditionalInfo, setHasAdditionalInfo] = useState(
    !!argument.meetingNoteConfiguration?.hasAdditionalInfo
  )

  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  function addOption() {
    setOptions((prevOptions) => {
      // TODO translate
      const label = `Option ${prevOptions.length + 1}`
      return prevOptions.concat([{ label, value: label }])
    })
  }

  function changeOption(
    newValue: IMeetingNoteConfigurationOption,
    index: number
  ) {
    let newOptions = [...options]
    newOptions[index] = newValue
    setOptions(newOptions)
  }

  function removeOption(index: number) {
    setOptions((prevOptions) =>
      prevOptions.filter((_prevOption, i) => i !== index)
    )
  }

  function submit(e: React.FormEvent) {
    e.preventDefault()
    upsertMutation.mutate(
      {
        argumentId: argument.id,
        fieldType,
        options,
        hasOptionOther,
        hasAdditionalInfo,
        isMandatory,
        appearsInSummary,
      },
      {
        onSuccess: () => onSuccess(),
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  const remove = () =>
    removeMutation.mutate(
      { argumentId: argument.id },
      {
        onSuccess: () => onSuccess(),
        onError: () => setErrorSnackOpen(true),
      }
    )

  const additionalInfoInput = (
    <FormControlLabel
      checked={hasAdditionalInfo}
      control={
        <Checkbox onChange={(e) => setHasAdditionalInfo(e.target.checked)} />
      }
      label={
        <FormattedMessage id="playbookEdit.argumentAnswer.form.allowAdditionalInfo" />
      }
    />
  )

  const isMandatoryInput = (
    <FormControlLabel
      checked={isMandatory}
      control={<Checkbox onChange={(e) => setIsMandatory(e.target.checked)} />}
      label={
        <FormattedMessage id="playbookEdit.argumentAnswer.form.isMandatory" />
      }
    />
  )

  const appearsInSummaryInput = (
    <FormControlLabel
      checked={appearsInSummary}
      control={
        <Checkbox onChange={(e) => setAppearsInSummary(e.target.checked)} />
      }
      label={
        <FormattedMessage id="playbookEdit.argumentAnswer.form.appearsInSummary" />
      }
    />
  )

  return (
    <>
      <Stack spacing={2}>
        <form onSubmit={submit}>
          <Stack spacing={2}>
            <ArgumentAnswerFormTypeSelector
              onSelect={setFieldType}
              value={fieldType}
            />

            {fieldType === "free_text" && (
              <ArgumentAnswerFreeTextBuilder
                isMandatoryInput={isMandatoryInput}
                appearsInSummaryInput={appearsInSummaryInput}
              />
            )}

            {fieldType === "single_select" && (
              <ArgumentAnswerSelectBuilder
                options={options}
                addOption={addOption}
                removeOption={removeOption}
                changeOption={changeOption}
                hasOptionOther={hasOptionOther}
                addOptionOther={() => setHasOptionOther(true)}
                removeOptionOther={() => setHasOptionOther(false)}
                additionalInfoInput={additionalInfoInput}
                isMandatoryInput={isMandatoryInput}
                appearsInSummaryInput={appearsInSummaryInput}
              />
            )}

            {fieldType === "multi_select" && (
              <ArgumentAnswerSelectBuilder
                options={options}
                addOption={addOption}
                removeOption={removeOption}
                changeOption={changeOption}
                hasOptionOther={hasOptionOther}
                addOptionOther={() => setHasOptionOther(true)}
                removeOptionOther={() => setHasOptionOther(false)}
                additionalInfoInput={additionalInfoInput}
                isMandatoryInput={isMandatoryInput}
                appearsInSummaryInput={appearsInSummaryInput}
              />
            )}

            {fieldType === "boolean" && (
              <ArgumentAnswerBooleanBuilder
                additionalInfoInput={additionalInfoInput}
                isMandatoryInput={isMandatoryInput}
                appearsInSummaryInput={appearsInSummaryInput}
              />
            )}

            {fieldType === "number" && (
              <ArgumentAnswerNumberBuilder
                additionalInfoInput={additionalInfoInput}
                isMandatoryInput={isMandatoryInput}
                appearsInSummaryInput={appearsInSummaryInput}
              />
            )}

            {fieldType === "phone" && (
              <ArgumentAnswerPhoneBuilder
                isMandatoryInput={isMandatoryInput}
                appearsInSummaryInput={appearsInSummaryInput}
              />
            )}

            {fieldType === "email" && (
              <ArgumentAnswerEmailBuilder
                additionalInfoInput={additionalInfoInput}
                isMandatoryInput={isMandatoryInput}
                appearsInSummaryInput={appearsInSummaryInput}
              />
            )}

            {fieldType === "url" && (
              <ArgumentAnswerUrlBuilder
                additionalInfoInput={additionalInfoInput}
                isMandatoryInput={isMandatoryInput}
                appearsInSummaryInput={appearsInSummaryInput}
              />
            )}

            {fieldType === "date" && (
              <ArgumentAnswerDateBuilder
                additionalInfoInput={additionalInfoInput}
                isMandatoryInput={isMandatoryInput}
                appearsInSummaryInput={appearsInSummaryInput}
              />
            )}

            <Stack direction="row" spacing={1}>
              {argument.meetingNoteConfiguration && (
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="error"
                  disabled={removeMutation.isLoading}
                  onClick={() => remove()}
                >
                  <FormattedMessage id="playbookEdit.argumentAnswer.remove" />
                </Button>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!fieldType || upsertMutation.isLoading}
              >
                <FormattedMessage id="common.submit" />
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </>
  )
}
