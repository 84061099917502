import { createContext, useContext } from "react"

import { IBusinessUnit } from "api/types/businessUnits"

interface Props {
  businessUnit: IBusinessUnit
  children: React.ReactElement
}

export default function BusinessUnitProvider({
  businessUnit,
  children,
}: Props) {
  return (
    <BusinessUnitContext.Provider value={businessUnit}>
      {children}
    </BusinessUnitContext.Provider>
  )
}

type ProvidedValue = IBusinessUnit

const BusinessUnitContext = createContext<ProvidedValue | null>(null)

export function useUnsafeBusinessUnit() {
  return useContext(BusinessUnitContext)
}

export function useBusinessUnit() {
  const providedValue = useContext(BusinessUnitContext)
  if (providedValue) return providedValue
  throw new Error(
    "useBusinessUnit was called without being wrapped in a BusinessUnitProvider"
  )
}
