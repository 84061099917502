import Cancel from "@mui/icons-material/Cancel"
import Check from "@mui/icons-material/CheckCircle"
import { Box } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { requestCurrentUrl } from "chrome_extension/messaging"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { FormattedMessage, useIntl } from "react-intl"

import {
  CRM_PROPERTY_COMPANY_OBJECT_TYPE,
  CRM_PROPERTY_CONTACT_OBJECT_TYPE,
  CRM_PROPERTY_DEAL_OBJECT_TYPE,
} from "api/types/CRMProperties"
import { IArgument } from "api/types/arguments"

import Button from "ds/Button"
import Checkbox from "ds/Checkbox"
import Loader from "ds/Loader"
import Modal from "ds/Modal"
import Stack from "ds/Stack"
import Tooltip from "ds/Tooltip"
import UncontrolledError from "ds/UncontrolledError"

import Back from "components/common/Back"

import {
  filterArguments,
  sortArgumentsByOrderedGroups,
} from "services/arguments"
import {
  filterAnswersWithInvalidValue,
  filterMandatoryAnswersNotFilled,
  filterUncheckedCheckboxes,
  getArgumentNotesCRMPropertyObjectTypes,
} from "services/meetingArgumentNotes"

import useConfirm from "utils/hooks/useConfirm"
import useLocalStorageState from "utils/hooks/useLocalStorageState"

import { usePlaybook } from "../../PlaybookProvider"
import { useArgumentsQuery } from "../../queries/argumentQueries"
import MeetingInformation from "../MeetingInformation"
import { useMeeting } from "../MeetingProvider"
import AnswersWithInvalidValue from "./EndMeetingModal/AnswersWithInvalidValue"
import EmptyCRMResourcesWithAnswersWarning from "./EndMeetingModal/EmptyCRMResourcesWithAnswersWarning"
import MandatoryAnswersNotfilled from "./MandatoryAnswersNotFilled"
import UncheckedCheckboxArguments from "./UncheckedCheckboxArguments"

interface LoadedEndMeetingModalProps {
  isOpen: boolean
  onClose: () => void
}

export default function LoadedEndMeetingModal(
  props: LoadedEndMeetingModalProps
) {
  const { activeTypeId } = usePlaybook()
  const argumentsQuery = useArgumentsQuery(activeTypeId)

  if (argumentsQuery.isLoading) return <Loader />
  if (argumentsQuery.isError)
    return <UncontrolledError error={argumentsQuery.error} />

  return <EndMeetingModal arguments={argumentsQuery.data} {...props} />
}

interface EndMeetingModalProps {
  arguments: IArgument[]
  isOpen: boolean
  onClose: () => void
}

function EndMeetingModal({
  arguments: _arguments,
  isOpen,
  onClose,
}: EndMeetingModalProps) {
  const {
    ongoingMeeting,
    company,
    contacts,
    deal,
    validateAndEndMeeting,
    isMeetingEnding,
    discardMeeting,
    copyNotesToClipboard,
  } = useMeeting()

  const { data: url } = useQuery(["currentExtensionURL"], () =>
    IS_CHROME_EXTENSION ? requestCurrentUrl() : null
  )

  const { playbook, activeTypeId, segmentFilters } = usePlaybook()
  const [shouldCopyNotesToClipboard, setShouldCopyNotesToClipboard] =
    useLocalStorageState("shouldCopyNotesToClipboard", true)
  const intl = useIntl()

  const confirmMandatory = useConfirm({
    message: intl.formatMessage({
      id: "playbook.meeting.missingMandatoryAnswers.confirm",
    }),
  })

  if (!ongoingMeeting) return null

  const sortedByGroupArguments = sortArgumentsByOrderedGroups(
    _arguments,
    playbook.argumentGroups.map((group) => group.id)
  )

  const visibleArguments = filterArguments(
    sortedByGroupArguments,
    playbook.argumentSegmentations,
    {
      segmentFilters,
      typeId: activeTypeId,
    }
  )

  const argumentsWithMissingAnswer = filterMandatoryAnswersNotFilled(
    visibleArguments,
    ongoingMeeting
  )

  const hasMissingMandatoryAnswers = argumentsWithMissingAnswer.length > 0

  const argumentsWithInvalidAnswer = filterAnswersWithInvalidValue(
    ongoingMeeting.meetingArgumentNotes
  ).map((meetingArgumentNote) => meetingArgumentNote.argument)

  const hasInvalidAnswers = argumentsWithInvalidAnswer.length > 0

  const uncheckedCheckboxArguments = filterUncheckedCheckboxes(
    visibleArguments,
    ongoingMeeting
  )

  const hasUncheckedCheckboxes = uncheckedCheckboxArguments.length > 0

  const objectTypesWithAnswers = getArgumentNotesCRMPropertyObjectTypes(
    ongoingMeeting.meetingArgumentNotes
  )

  const emptyCRMResourceswithAnswersNames = [
    objectTypesWithAnswers.includes(CRM_PROPERTY_COMPANY_OBJECT_TYPE) &&
      !company &&
      intl.formatMessage({
        id: "meetings.company",
      }),
    objectTypesWithAnswers.includes(CRM_PROPERTY_CONTACT_OBJECT_TYPE) &&
      !contacts.length &&
      intl.formatMessage({
        id: "meetings.contact",
      }),
    objectTypesWithAnswers.includes(CRM_PROPERTY_DEAL_OBJECT_TYPE) &&
      !deal &&
      intl.formatMessage({
        id: "meetings.deal",
      }),
  ].filter((x) => x)

  return (
    <Modal
      isOpen={isOpen}
      hideClosingCross
      onClose={onClose}
      data-testid="EndMeetingModal"
      sizeVariant="big"
    >
      <Stack spacing={2} height="100%" sx={{ containerType: "size" }}>
        <Stack flexGrow={1} overflow="auto" spacing={2}>
          <MeetingInformation
            back={<Back onClick={onClose} />}
            ongoingMeeting={ongoingMeeting}
            currentPageUrl={url || undefined}
          />

          {emptyCRMResourceswithAnswersNames.length > 0 && (
            <EmptyCRMResourcesWithAnswersWarning
              resourceNames={emptyCRMResourceswithAnswersNames.join(", ")}
            />
          )}

          {hasInvalidAnswers && (
            <AnswersWithInvalidValue
              argumentsWithInvalidAnswer={argumentsWithInvalidAnswer}
              onReplyClick={onClose}
              playbook={playbook}
            />
          )}

          {hasMissingMandatoryAnswers && (
            <MandatoryAnswersNotfilled
              argumentsWithMissingAnswer={argumentsWithMissingAnswer}
              onReplyClick={onClose}
            />
          )}

          {hasUncheckedCheckboxes && (
            <UncheckedCheckboxArguments
              uncheckedCheckboxArguments={uncheckedCheckboxArguments}
            />
          )}
        </Stack>

        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
        >
          <Box>
            <Button
              fullWidth
              color="error"
              variant="contained"
              startIcon={<Cancel />}
              onClick={() => discardMeeting(onClose)}
              disabled={isMeetingEnding}
            >
              <FormattedMessage id="playbook.meeting.endDialog.discardMeeting" />
            </Button>
          </Box>

          <Box>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<Check />}
              onClick={() => {
                if (
                  (hasMissingMandatoryAnswers || hasUncheckedCheckboxes) &&
                  !confirmMandatory()
                )
                  return
                if (shouldCopyNotesToClipboard) copyNotesToClipboard()
                validateAndEndMeeting(
                  uncheckedCheckboxArguments.map((x) => x.id),
                  onClose
                )
              }}
              disabled={isMeetingEnding || hasInvalidAnswers}
            >
              <FormattedMessage id="playbook.meeting.endDialog.endMeeting" />
            </Button>
          </Box>

          <Tooltip
            title={
              <FormattedMessage id="playbook.meeting.endDialog.copyToClipboard" />
            }
          >
            <Checkbox
              checked={shouldCopyNotesToClipboard}
              onChange={(e) => setShouldCopyNotesToClipboard(e.target.checked)}
            />
          </Tooltip>
        </Stack>
      </Stack>
    </Modal>
  )
}
