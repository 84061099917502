import { FormattedMessage } from "react-intl"

import { IOrganizationMembershipRole } from "api/types/organizationMemberships"

interface Props {
  role: IOrganizationMembershipRole
}

export default function RoleLabel({ role }: Props) {
  return (
    <FormattedMessage
      id={`organizationMembership.role.${role}`}
      defaultMessage={role}
    />
  )
}
