import { IMeetingArgumentNoteSingleSelect } from "api/types/meetingArgumentNotes"
import {
  IMeetingNoteConfiguration,
  IMeetingNoteConfigurationOption,
} from "api/types/meetingNoteConfigurations"

import SelectForm from "./SelectForm"

interface Props {
  note: IMeetingArgumentNoteSingleSelect
  onChange: (newValue: IMeetingNoteConfigurationOption | null) => void
  onAdditionalInfoChange: (newAdditionalInfo: string) => void
  configuration: IMeetingNoteConfiguration
  isSaved: boolean
  disabled: boolean
}

const SingleSelectForm = ({
  note,
  onChange,
  onAdditionalInfoChange,
  configuration,
  isSaved,
  disabled,
}: Props) => {
  const { hasAdditionalInfo, hasOptionOther, options } = configuration

  return (
    <SelectForm
      multiple={false}
      value={note.value}
      options={options}
      onChange={(_e, newValue) => {
        if (typeof newValue === "string") {
          // User input another option
          return onChange({ label: newValue, value: newValue })
        }
        onChange(newValue)
      }}
      hasAdditionalInfo={hasAdditionalInfo}
      additionalInfo={note.additionalInfo || ""}
      onAdditionalInfoChange={onAdditionalInfoChange}
      isSaved={isSaved}
      creatable={hasOptionOther}
      disabled={disabled}
    />
  )
}

export default SingleSelectForm
