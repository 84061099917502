import { Box } from "@mui/material"
import isHotkey from "is-hotkey"
import { isEqual } from "lodash"
import { useRef, useState } from "react"

import { IArgument } from "api/types/arguments"

import { eventTargetIsInDialog } from "ds/RichTextNew/components/DialogClassName"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import { MyValue } from "ds/RichTextNew/types"
import Stack from "ds/Stack"
import SubmitInputAdornment from "ds/SubmitInputAdornment"
import UnstyledButton from "ds/UnstyledButton"

import { findArgumentPersonalNote } from "services/argumentPersonalNotes"

import useConfirm from "utils/hooks/useConfirm"
import useOnClickOutside from "utils/hooks/useOnClickOutside"
import isMobileDevice from "utils/isMobileDevice"

import { usePlaybook } from "../../PlaybookProvider"
import ArgumentPersonalNote from "./ArgumentPersonalNote"
import ArgumentPersonalNoteCTA from "./ArgumentPersonalNoteCTA"
import ArgumentPersonalNoteInput from "./ArgumentPersonalNoteInput"
import RemoveArgumentPersonalNote from "./RemoveArgumentPersonalNote"
import { useArgumentPersonalNoteUpsertMutation } from "./argumentPersonalNoteQueries"

interface Props {
  argument: IArgument
}

export default function EditableArgumentPersonalNote({ argument }: Props) {
  const { playbook, argumentPersonalNotes } = usePlaybook()
  const argumentPersonalNote = findArgumentPersonalNote(
    argumentPersonalNotes,
    argument.id
  )
  const inputRef = useRef<HTMLInputElement>(null)
  const [isEditing, setIsEditing] = useState(false)
  const initialValue = argumentPersonalNote?.value || buildNodeFromText("")
  const [value, setValue] = useState(initialValue)
  const [rawValue, setRawValue] = useState(argumentPersonalNote?.rawValue || "")
  const upsertMutation = useArgumentPersonalNoteUpsertMutation(playbook.id)
  const isMobile = isMobileDevice()
  const confirm = useConfirm({ messageKey: "leaveWithoutSaving" })

  function onChange(value: MyValue, rawValue: string) {
    setValue(value)
    setRawValue(rawValue)
  }

  function cancel() {
    setIsEditing(false)
  }

  function confirmCancel() {
    if (isEqual(value, initialValue) || confirm()) {
      cancel()
    }
  }

  function submit() {
    upsertMutation.mutate(
      {
        argumentId: argument.id,
        argumentPersonalNote: { value, rawValue },
      },
      {
        onSuccess: () => setIsEditing(false),
      }
    )
  }

  useOnClickOutside(inputRef, (event) => {
    if (eventTargetIsInDialog(event)) return
    submit()
  })

  if (argumentPersonalNote && argumentPersonalNote.rawValue && !isEditing)
    return (
      <Stack direction="row" spacing={1} width="100%">
        <UnstyledButton onClick={() => setIsEditing(true)} sx={{ flexGrow: 1 }}>
          <ArgumentPersonalNote
            argumentPersonalNote={argumentPersonalNote}
            light
          />
        </UnstyledButton>

        <Box>
          <RemoveArgumentPersonalNote
            argumentPersonalNote={argumentPersonalNote}
          />
        </Box>
      </Stack>
    )

  if (isEditing)
    return (
      <Box mt={1} width="100%">
        <ArgumentPersonalNoteInput
          id={`argument-personal-note-${argument.id}`}
          inputRef={inputRef}
          value={value}
          onChange={onChange}
          endAdornment={
            <SubmitInputAdornment
              onSubmit={submit}
              onCancel={cancel}
              disabled={upsertMutation.isLoading}
            />
          }
          onKeyDown={(event) => {
            if (isHotkey("esc", event)) {
              event.stopPropagation() // Don't close the modal
              confirmCancel()
            }

            if (!isMobile && isHotkey("mod+enter", event)) {
              submit()
            }
          }}
        />
      </Box>
    )

  return <ArgumentPersonalNoteCTA onClick={() => setIsEditing(true)} />
}
