import { useState } from "react"
import { useDebouncedCallback } from "use-debounce"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IPickedSegment } from "api/types/argumentSegments"
import { IArgumentWithMeetingNoteConfiguration } from "api/types/arguments"
import { IMeetingArgumentNote } from "api/types/meetingArgumentNotes"

import { useCurrentMeetingArgumentNoteMutation } from "components/App/CRM/Meetings/meetingQueries"
import { useMeeting } from "components/App/Playbook/Meeting/MeetingProvider"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { filterNonArchived } from "services/archivable"
import {
  SEGMENT_DEFAULT,
  findArgumentSegmentsByValue,
} from "services/argumentSegmentations"
import {
  getMeetingArgumentNote,
  noteHasValidValue,
} from "services/meetingArgumentNotes"

import BooleanForm from "./MeetingArgumentNoteForm/BooleanForm"
import DateForm from "./MeetingArgumentNoteForm/DateForm"
import EmailForm from "./MeetingArgumentNoteForm/EmailForm"
import FreeTextForm from "./MeetingArgumentNoteForm/FreeTextForm"
import MultiSelectForm from "./MeetingArgumentNoteForm/MultiSelectForm"
import NumberForm from "./MeetingArgumentNoteForm/NumberForm"
import { PhoneForm } from "./MeetingArgumentNoteForm/PhoneForm"
import ProbabilityForm from "./MeetingArgumentNoteForm/ProbabilityForm"
import SingleSelectForm from "./MeetingArgumentNoteForm/SingleSelectForm"
import UrlForm from "./MeetingArgumentNoteForm/UrlForm"

const debounceWaitMs = 250

interface Props {
  argument: IArgumentWithMeetingNoteConfiguration
  disabled?: boolean
}

function selectSegmentsMatchingMeetingNoteConfiguration(
  meetingNoteConfigurationId: string,
  argumentSegmentations: IArgumentSegmentation[],
  value: string[]
): IPickedSegment[] {
  return argumentSegmentations.flatMap((segmentation) => {
    if (
      !segmentation.meetingNoteConfiguration?.id ||
      segmentation.meetingNoteConfiguration.id !== meetingNoteConfigurationId
    )
      return []

    const segments = findArgumentSegmentsByValue(
      filterNonArchived(segmentation.argumentSegments),
      value
    )

    if (segments.length === 0) {
      return [
        {
          argumentSegmentationId: segmentation.id,
          argumentSegmentIds: [SEGMENT_DEFAULT],
        },
      ]
    }

    return [
      {
        argumentSegmentationId: segmentation.id,
        argumentSegmentIds: segments.map((segment) => segment.id),
      },
    ]
  })
}

function selectSegmentsMatchingArgumentProperty(
  argumentPropertyId: string,
  argumentSegmentations: IArgumentSegmentation[],
  value: string[]
): IPickedSegment[] {
  return argumentSegmentations.flatMap((segmentation) => {
    if (
      !segmentation.crmProperty?.id ||
      segmentation.crmProperty.id !== argumentPropertyId
    )
      return []

    const segments = findArgumentSegmentsByValue(
      filterNonArchived(segmentation.argumentSegments),
      value
    )

    if (segments.length === 0) {
      return [
        {
          argumentSegmentationId: segmentation.id,
          argumentSegmentIds: [SEGMENT_DEFAULT],
        },
      ]
    }

    return [
      {
        argumentSegmentationId: segmentation.id,
        argumentSegmentIds: segments.map((segment) => segment.id),
      },
    ]
  })
}

export default function MeetingArgumentNoteForm({
  argument,
  disabled = false,
}: Props) {
  const [isSaved, setIsSaved] = useState(false)
  const { ongoingMeeting } = useMeeting()
  const { playbook, pickSegments } = usePlaybook()
  const argumentNoteMutation = useCurrentMeetingArgumentNoteMutation(
    playbook.id
  )
  const saveDebouncedNote = useDebouncedCallback(saveNote, debounceWaitMs)
  const [note, setNote] = useState(
    getMeetingArgumentNote(ongoingMeeting, argument)
  )
  const [error, setError] = useState(!noteHasValidValue(note))

  const fieldType = argument.meetingNoteConfiguration.fieldType

  function saveNote(newNote: IMeetingArgumentNote) {
    if (!ongoingMeeting) return
    argumentNoteMutation.mutate(
      {
        meetingId: ongoingMeeting.id,
        argumentId: argument.id,
        fieldType,
        value: newNote.value,
        rawValue: newNote.rawValue,
        additionalInfo: newNote.additionalInfo,
      },
      { onSuccess: () => setIsSaved(true) }
    )
  }

  function setValueAndSaveDebounced(newNote: IMeetingArgumentNote) {
    if (isSaved) setIsSaved(false)
    setNote(newNote)
    setError(!noteHasValidValue(newNote))
    saveDebouncedNote(newNote)
  }

  function onAdditionalInfoChange(additionalInfo: string) {
    setValueAndSaveDebounced({
      ...note,
      additionalInfo,
    })
  }

  switch (note.fieldType) {
    case "email":
      return (
        <EmailForm
          disabled={disabled}
          note={note}
          onValueChange={(newValue) =>
            setValueAndSaveDebounced({
              ...note,
              value: newValue,
            })
          }
          onAdditionalInfoChange={onAdditionalInfoChange}
          configuration={argument.meetingNoteConfiguration}
          isSaved={isSaved}
          error={error}
        />
      )
    case "phone":
      return (
        <PhoneForm
          disabled={disabled}
          note={note}
          onChange={(value, _info) =>
            setValueAndSaveDebounced({
              ...note,
              value,
            })
          }
        />
      )
    case "number":
      return (
        <NumberForm
          disabled={disabled}
          note={note}
          onValueChange={(newValue) =>
            setValueAndSaveDebounced({ ...note, value: newValue })
          }
          onAdditionalInfoChange={onAdditionalInfoChange}
          configuration={argument.meetingNoteConfiguration}
          isSaved={isSaved}
        />
      )
    case "boolean":
      return (
        <BooleanForm
          disabled={disabled}
          note={note}
          onBooleanChange={(newValue) =>
            setValueAndSaveDebounced({ ...note, value: newValue })
          }
          onAdditionalInfoChange={onAdditionalInfoChange}
          configuration={argument.meetingNoteConfiguration}
          isSaved={isSaved}
        />
      )
    case "single_select":
      return (
        <SingleSelectForm
          disabled={disabled}
          note={note}
          onChange={(newValue) => {
            if (argument.meetingNoteConfiguration?.id) {
              pickSegments(
                selectSegmentsMatchingMeetingNoteConfiguration(
                  argument.meetingNoteConfiguration.id,
                  filterNonArchived(playbook.argumentSegmentations),
                  newValue ? [newValue.value] : []
                )
              )
            }
            if (argument.meetingNoteConfiguration?.crmProperty?.id) {
              pickSegments(
                selectSegmentsMatchingArgumentProperty(
                  argument.meetingNoteConfiguration.crmProperty.id,
                  filterNonArchived(playbook.argumentSegmentations),
                  newValue ? [newValue.value] : []
                )
              )
            }
            setValueAndSaveDebounced({
              ...note,
              value: newValue,
            })
          }}
          onAdditionalInfoChange={onAdditionalInfoChange}
          configuration={argument.meetingNoteConfiguration}
          isSaved={isSaved}
        />
      )
    case "multi_select":
      return (
        <MultiSelectForm
          disabled={disabled}
          note={note}
          onChange={(newValue) => {
            if (argument.meetingNoteConfiguration?.id) {
              pickSegments(
                selectSegmentsMatchingMeetingNoteConfiguration(
                  argument.meetingNoteConfiguration.id,
                  filterNonArchived(playbook.argumentSegmentations),
                  newValue.map((option) => option.value)
                )
              )
            }
            if (argument.meetingNoteConfiguration?.crmProperty?.id) {
              pickSegments(
                selectSegmentsMatchingArgumentProperty(
                  argument.meetingNoteConfiguration.crmProperty.id,
                  filterNonArchived(playbook.argumentSegmentations),
                  newValue.map((option) => option.value)
                )
              )
            }
            setValueAndSaveDebounced({
              ...note,
              value: newValue,
            })
          }}
          onAdditionalInfoChange={onAdditionalInfoChange}
          configuration={argument.meetingNoteConfiguration}
          isSaved={isSaved}
        />
      )
    case "free_text":
      return (
        <FreeTextForm
          disabled={disabled}
          note={note}
          onChange={(newValue, newRawValue) =>
            setValueAndSaveDebounced({
              ...note,
              value: newValue,
              rawValue: newRawValue,
            })
          }
        />
      )
    case "url":
      return (
        <UrlForm
          disabled={disabled}
          note={note}
          onChange={(newValue) =>
            setValueAndSaveDebounced({
              ...note,
              value: newValue,
            })
          }
          onAdditionalInfoChange={onAdditionalInfoChange}
          configuration={argument.meetingNoteConfiguration}
          isSaved={isSaved}
          error={error}
        />
      )
    case "date":
      return (
        <DateForm
          disabled={disabled}
          note={note}
          onChange={(newValue) =>
            setValueAndSaveDebounced({
              ...note,
              value: newValue,
            })
          }
          onAdditionalInfoChange={onAdditionalInfoChange}
          configuration={argument.meetingNoteConfiguration}
          isSaved={isSaved}
        />
      )
    case "probability":
      return (
        <ProbabilityForm
          disabled={disabled}
          note={note}
          onChange={(newValue) =>
            setValueAndSaveDebounced({
              ...note,
              value: newValue,
            })
          }
          onAdditionalInfoChange={onAdditionalInfoChange}
          configuration={argument.meetingNoteConfiguration}
          isSaved={isSaved}
          error={error}
        />
      )
    case "checkbox":
      return null
  }
}
