import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { IUser } from "api/types/users"

import Alert from "ds/Alert"
import Button from "ds/Button"
import Checkbox from "ds/Checkbox"
import FormControlLabel from "ds/FormControlLabel"
import { BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { useBusinessUnitMembershipCreateMutation } from "./businessUnitMembershipsQueries"

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 400px;
`

interface Props {
  addableUsers: IUser[]
  onSuccess: () => void
}

export default function BusinessUnitMembershipsCreationForm({
  addableUsers,
  onSuccess,
}: Props) {
  const params = useParams()
  const businessUnitId = params.businessUnitId!

  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)

  const createMutation = useBusinessUnitMembershipCreateMutation(businessUnitId)

  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])

  function toggleSelectedUserId(userId: string) {
    setSelectedUserIds((prevState) =>
      prevState.includes(userId)
        ? prevState.filter((selectedUserId) => selectedUserId !== userId)
        : [...prevState, userId]
    )
  }

  const onSubmit = () => {
    createMutation.mutate(
      {
        businessUnitId: businessUnitId,
        userIds: selectedUserIds,
      },
      {
        onSuccess: () => {
          setSuccessSnackOpen(true)
          onSuccess()
        },
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <Stack spacing={1}>
      <ScrollContainer>
        {addableUsers.length === 0 && (
          <Alert severity="warning">
            <FormattedMessage id="businessUnits.memberships.add.allUsersAlreadyInvited" />
          </Alert>
        )}

        {addableUsers.map((user) => (
          <Stack key={user.id} direction="row" spacing={1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedUserIds.includes(user.id)}
                  onChange={() => toggleSelectedUserId(user.id)}
                />
              }
              label={
                <Box>
                  {!!user.name ? (
                    <>
                      <Box>{user.name}</Box> -{" "}
                      <Typography variant="xsNormal">{user.email}</Typography>
                    </>
                  ) : (
                    user.email
                  )}
                </Box>
              }
            />
          </Stack>
        ))}
      </ScrollContainer>

      <Button disabled={selectedUserIds.length === 0} onClick={onSubmit}>
        <FormattedMessage id="businessUnits.memberships.add" />
      </Button>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
    </Stack>
  )
}
