import { Navigate, Route, Routes } from "react-router-dom"

import CRM from "../CRM"
import PlaybookEditPage from "../PlaybookEditPage"
import PlaybookPage from "../PlaybookPage"
import PublicPlaybookPage from "../PublicPlaybookPage"
import BusinessUnitRoot from "./BusinessUnitRoot"

export default function BusinessUnitRouter() {
  return (
    <Routes>
      <Route path="playbook/:playbookId/*" element={<PlaybookPage />} />
      <Route
        path="public-playbook/:playbookId/*"
        element={<PublicPlaybookPage />}
      />
      <Route
        path="playbook-edit/:playbookId/*"
        element={<PlaybookEditPage />}
      />
      <Route path="crm/*" element={<CRM />} />
      <Route path="/" element={<BusinessUnitRoot />} />
      <Route path="*" element={<Navigate to="./" />} />
    </Routes>
  )
}
