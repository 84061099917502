import MuiChip, { ChipProps } from "@mui/material/Chip"
import { forwardRef } from "react"

export type { ChipProps }

export default forwardRef(function Chip(
  props: ChipProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <MuiChip
      size="small"
      {...props}
      sx={{ "& .MuiChip-label": { px: 2 }, ...props.sx }}
      ref={ref}
    />
  )
})
