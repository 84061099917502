import { IMeetingArgumentNote } from "api/types/meetingArgumentNotes"

import Typography from "ds/Typography"

interface Props {
  argumentNote: IMeetingArgumentNote
}

export default function ReadonlyAdditionalInfo({ argumentNote }: Props) {
  return (
    <Typography variant="smNormal" sx={{ whiteSpace: "pre-wrap" }}>
      ({argumentNote.additionalInfo})
    </Typography>
  )
}
