import { FormattedMessage } from "react-intl"

import MenuItem from "ds/MenuItem"

interface Props {
  onClick: () => void
}

export default function CopyArgumentTypes({ onClick }: Props) {
  return (
    <MenuItem onClick={onClick}>
      <FormattedMessage
        id="playbookEdit.argumentTypes.copy"
        defaultMessage="Copy tabs"
      />
    </MenuItem>
  )
}
