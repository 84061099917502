import { Box } from "@mui/material"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import { IArgument } from "api/types/arguments"

import { useIsSmallerThanSM } from "ds/Media"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import Back from "components/common/Back"

import useSearchParam from "utils/hooks/useSearchParam"

import ArgumentActions from "../PlaybookEdit/Argument/ArgumentActions/ArgumentActions"
import { usePlaybook } from "../PlaybookProvider"
import ArgumentShow from "./ArgumentShow"
import CommentsPanel from "./Comments/CommentsPanel"
import CommentsToggle from "./Comments/CommentsToggle"
import { useArgumentCommentsCountQuery } from "./Comments/argumentCommentQueries"
import EditModeToggle from "./EditModeToggle"
import SharePanel from "./Share/SharePanel"
import ShareToggle from "./Share/ShareToggle"
import { tabParamKey } from "./routing"

const ArgumentAndActiveTab = styled(Box)`
  height: 100%;
  display: flex;
  min-width: 0;
  background: ${({ theme }) => theme.palette.grey[50]};

  > * {
    flex-basis: 0;
    flex-grow: 1;
  }
`

const PanelContainer = styled(Box)`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 0;
`

interface Props {
  argument: IArgument
  onClose: () => void
  singleColumnLayout: boolean
}

export default function ArgumentPage({
  argument,
  onClose,
  singleColumnLayout,
}: Props) {
  const [activeTab, setActiveTab] = useSearchParam<string>(tabParamKey)
  const argumentCommentsCountQuery = useArgumentCommentsCountQuery(argument.id)
  const { editMode, canEdit, readOnly } = usePlaybook()
  const isSmallerThanSM = useIsSmallerThanSM()

  function onClickTab(tabKey: string) {
    setActiveTab(activeTab === tabKey ? undefined : tabKey)
  }

  function closeTab() {
    setActiveTab(undefined)
  }

  const closeTabButton = <Back onClick={closeTab} />

  let tabContent = null
  if (activeTab === "comments") {
    tabContent = (
      <CommentsPanel
        showArgumentTitle={singleColumnLayout}
        argument={argument}
        backButton={closeTabButton}
      />
    )
  }
  if (activeTab === "share") {
    tabContent = <SharePanel argument={argument} backButton={closeTabButton} />
  }

  const wide = !singleColumnLayout && !!activeTab

  return (
    <ArgumentAndActiveTab
      sx={{
        width: {
          sm: "100%",
          md: wide ? "min(1300px, 90vw)" : "min(650px, 90vw)",
        },
      }}
    >
      {(!singleColumnLayout || !activeTab) && (
        <PanelContainer>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            p={2}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Back onClick={onClose} />
              <Typography variant="lgSemi">
                <FormattedMessage id="arguments.title" />
              </Typography>
            </Stack>

            <Stack direction="row" spacing={0.5}>
              {canEdit &&
                !readOnly &&
                (!isSmallerThanSM || IS_CHROME_EXTENSION) && <EditModeToggle />}

              <CommentsToggle
                onClick={() => onClickTab("comments")}
                commentsCount={argumentCommentsCountQuery.data || 0}
                commentsNotVisitedNotificationsCount={
                  argument.notVisitedNotificationsCount || 0
                }
              />

              <ShareToggle onClick={() => onClickTab("share")} />

              {editMode && (
                <ArgumentActions argument={argument} onlyConfigOptions={true} />
              )}
            </Stack>
          </Stack>

          <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
            <ArgumentShow argument={argument} />
          </Box>
        </PanelContainer>
      )}

      {!!activeTab && <PanelContainer>{tabContent}</PanelContainer>}
    </ArgumentAndActiveTab>
  )
}
