import styled from "styled-components"

import { IArgumentComment } from "api/types/argumentComments"

import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import { MyValue } from "ds/RichTextNew/types"

import CommentForm from "./CommentForm"

const Container = styled.div`
  margin: ${({ theme }) => theme.spacing(1)} 0;
`

interface Props {
  comment: IArgumentComment
  onSubmit: (
    resourceId: string,
    value: MyValue,
    rawValue: string,
    category: string | null
  ) => Promise<void>
  isSubmitting: boolean
  cancelEditing: () => void
  richTextProps: Partial<RichTextProps>
}

export default function CommentEdit({
  comment,
  onSubmit,
  isSubmitting,
  cancelEditing,
  richTextProps,
}: Props) {
  return (
    <Container>
      <CommentForm
        richTextProps={richTextProps}
        categorySelectorId={`comment-form-category-argument-comment-${comment.id}`}
        resourceId={comment.id}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        onCancel={cancelEditing}
        comment={comment}
      />
    </Container>
  )
}
