import { useEffect, useState } from "react"
import { useDebounce } from "use-debounce"

import { apiSearchArguments } from "api/arguments"
import { IArgumentSearchResult } from "api/types/arguments"

const minQueryLength = 1
const debounceWaitMs = 250

// Hook that debounces on the search term and returns `results` and `isSearching`
export default function useArgumentSearch(
  playbookId: string,
  searchTerm: string,
  argumentTypeId?: string
) {
  const [debouncedSearchTerm] = useDebounce(searchTerm, debounceWaitMs)
  const [results, setResults] = useState<IArgumentSearchResult[]>([])
  const [isSearching, setIsSearching] = useState(false)

  // TODO use debounced callback
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= minQueryLength) {
      setIsSearching(true)
      apiSearchArguments(debouncedSearchTerm, playbookId, argumentTypeId)
        .then((data) => setResults(data))
        .finally(() => setIsSearching(false))
    } else {
      setResults([])
      setIsSearching(false)
    }
  }, [debouncedSearchTerm, playbookId, argumentTypeId])

  return {
    results,
    isSearching,
  }
}
