import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined"
import { useIntl } from "react-intl"

import IconButton from "ds/IconButton"

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default function ShareToggle({ onClick }: Props) {
  const intl = useIntl()

  const label = intl.formatMessage({
    id: "arguments.share.title",
    defaultMessage: "Share with your team",
  })

  return (
    <IconButton onClick={onClick} aria-label={label}>
      <ShareOutlinedIcon fontSize="small" />
    </IconButton>
  )
}
