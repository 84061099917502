import { FormatCallback, HtmlToTextOptions, compile } from "html-to-text"

export function toRawValue(html: string) {
  return compiledConvert(html).trim()
}

const formatAudioOrVideo: FormatCallback = (
  elem,
  _walk,
  builder,
  _formatOptions
) => {
  const attribs = elem.attribs || {}
  let src = attribs.src
  if (!src) {
    const source = elem.children.find(
      (el: any) => el.type === "tag" && el.name.toLowerCase() === "source"
    )
    src = source?.attribs?.src
  }

  builder.addInline(`[${src}]`, { noWordTransform: true })
}

const formatInput: FormatCallback = (elem, _walk, builder, _formatOptions) => {
  const attribs = elem.attribs || {}
  let type = attribs.type
  if (type === "checkbox") {
    const icon = attribs["data-checked"] === "true" ? "✅" : "❌"
    builder.addInline(`${icon} `)
  }
}

const options: HtmlToTextOptions = {
  formatters: {
    audioOrVideoFormatter: formatAudioOrVideo,
    inputFormatter: formatInput,
  },
  selectors: [
    {
      selector: "video",
      format: "audioOrVideoFormatter",
    },
    {
      selector: "audio",
      format: "audioOrVideoFormatter",
    },
    { selector: "input", format: "inputFormatter" },
  ],
}

const compiledConvert = compile(options)
