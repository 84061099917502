import { useTheme } from "@mui/material"
import MuiFormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel"
import { forwardRef } from "react"

export type { FormControlLabelProps }

const FormControlLabel = forwardRef(function (
  props: FormControlLabelProps,
  ref: React.Ref<HTMLLabelElement>
) {
  const theme = useTheme()
  return (
    <MuiFormControlLabel
      {...props}
      sx={{ gap: 0.5, ...theme.typography.smNormal, ...props.sx }}
      ref={ref}
    />
  )
})

export default FormControlLabel

// Override margin-left to align the radio button properly
const RadioFormControlLabel = forwardRef(function (
  props: Omit<FormControlLabelProps, "ref">,
  ref: React.Ref<HTMLLabelElement>
) {
  return (
    <FormControlLabel {...props} sx={{ ml: -0.5, ...props.sx }} ref={ref} />
  )
})

export { RadioFormControlLabel }
