import { FormattedMessage } from "react-intl"

import Stack from "ds/Stack"
import Typography from "ds/Typography"

interface Props {
  additionalInfoInput: React.ReactNode
  isMandatoryInput: React.ReactNode
  appearsInSummaryInput: React.ReactNode
}

export default function ArgumentAnswerDateBuilder({
  additionalInfoInput,
  isMandatoryInput,
  appearsInSummaryInput,
}: Props) {
  return (
    <Stack spacing={2}>
      <div>
        <Typography variant="smNormal">
          <FormattedMessage id="playbookEdit.argumentAnswer.form.dateBuilder.description" />
        </Typography>
      </div>

      <Stack spacing={1}>
        <div>{additionalInfoInput}</div>
        <div>{isMandatoryInput}</div>
        <div>{appearsInSummaryInput}</div>
      </Stack>
    </Stack>
  )
}
