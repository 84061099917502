import { Draggable, Droppable, DroppableProvided } from "@hello-pangea/dnd"

import { IArgumentGroup } from "api/types/argumentGroups"
import { IArgument } from "api/types/arguments"

import Stack from "ds/Stack"

import ArgumentCard from "./Argument/ArgumentCard"
import ArgumentCardWithSwipe from "./Argument/ArgumentCardWithSwipe"
import { usePlaybook } from "./PlaybookProvider"

interface ArgumentsProps extends ArgumentsWithDnDProps {
  providedDroppable?: DroppableProvided
}

function Arguments({
  arguments: _arguments,
  providedDroppable,
}: ArgumentsProps) {
  const { editMode } = usePlaybook()
  const droppableRef = providedDroppable?.innerRef
  const droppableProps = providedDroppable?.droppableProps || {}
  const droppablePlaceholder = providedDroppable?.placeholder

  return (
    <Stack
      spacing={2}
      sx={{ minHeight: "10px" }} // To be able to drop arguments even if empty
      ref={droppableRef}
      {...droppableProps}
    >
      {_arguments.map((argument, index) =>
        editMode ? (
          <Draggable draggableId={argument.id} index={index} key={argument.id}>
            {(providedDraggable) => (
              <ArgumentCard
                argument={argument}
                providedDraggable={providedDraggable}
              />
            )}
          </Draggable>
        ) : (
          <ArgumentCardWithSwipe key={argument.id} argument={argument} />
        )
      )}

      {droppablePlaceholder}
    </Stack>
  )
}

interface ArgumentsWithDnDProps {
  arguments: IArgument[]
  argumentGroup: IArgumentGroup | null
}

export default function ArgumentsWithDnD(props: ArgumentsWithDnDProps) {
  const { editMode } = usePlaybook()

  if (editMode) {
    return (
      <Droppable
        droppableId={props.argumentGroup?.id || "none"}
        type="droppableForArguments"
      >
        {(providedDroppable) => (
          <Arguments {...props} providedDroppable={providedDroppable} />
        )}
      </Droppable>
    )
  }
  return <Arguments {...props} />
}
