import { FormattedMessage } from "react-intl"

import {
  CALL_SYNCED_ENTITY,
  IIntegration,
  ISyncedEntityType,
  MEETING_SYNCED_ENTITY,
} from "api/types/integrations"

import FormControl from "ds/FormControl"
import { RadioFormControlLabel } from "ds/FormControlLabel"
import FormLabel from "ds/FormLabel"
import Radio from "ds/Radio"
import RadioGroup from "ds/RadioGroup"
import Stack from "ds/Stack"

import IntegrationIcon from "components/common/IntegrationIcon"

interface Props {
  value: ISyncedEntityType
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  disabled: boolean
  integration: IIntegration
}

export default function CRMSyncedEntityTypeForm(props: Props) {
  return (
    <FormControl disabled={props.disabled}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <FormLabel>
          <Stack direction="row" spacing={1} alignItems="center">
            <IntegrationIcon integrationName={props.integration.name} />
            <div>
              <FormattedMessage id="playbook.meeting.informations.syncedEntityType.title" />
            </div>
          </Stack>
        </FormLabel>

        <RadioGroup row value={props.value} onChange={props.onChange}>
          <RadioFormControlLabel
            value={MEETING_SYNCED_ENTITY}
            control={<Radio />}
            label={
              <FormattedMessage id="playbook.meeting.informations.syncedEntityType.crmMeetings.title" />
            }
          />
          <RadioFormControlLabel
            value={CALL_SYNCED_ENTITY}
            control={<Radio />}
            label={
              <FormattedMessage id="playbook.meeting.informations.syncedEntityType.crmCalls.title" />
            }
          />
        </RadioGroup>
      </Stack>
    </FormControl>
  )
}
