import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates"
import { FormattedMessage, useIntl } from "react-intl"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"

interface Props {
  onClick: React.MouseEventHandler
}

export default function TutorialMenuItem({ onClick }: Props) {
  const intl = useIntl()
  return (
    <MenuItem
      component="a"
      target="_blank"
      rel="noreferrer"
      href={intl.formatMessage({
        id: "navigation.tutorialHref",
        defaultMessage:
          "https://innate-gallimimus-e08.notion.site/SalesPath-onboarding-guide-EN-2bb5d5a894354c7184a0f5cfcdf902e6",
      })}
      onClick={onClick}
    >
      <ListItemIcon>
        <TipsAndUpdatesIcon color="info" />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="navigation.tutorial" defaultMessage="Tutorial" />
      </ListItemText>
    </MenuItem>
  )
}
