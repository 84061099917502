import { FormattedMessage } from "react-intl"

import CenteringContainer from "ds/CenteringContainer"
import Typography from "ds/Typography"

export default function NoResults() {
  return (
    <CenteringContainer>
      <Typography variant="xlSemi" component="div" sx={{ textAlign: "center" }}>
        <FormattedMessage
          id="playbook.search.results.none"
          defaultMessage="No results"
        />
      </Typography>
    </CenteringContainer>
  )
}
