import { MutateFunction } from "@tanstack/react-query"

import {
  apiArchiveArgumentField,
  apiCreateArgumentField,
  apiRestoreArgumentField,
  apiUpdateArgumentField,
  apiUpdateArgumentFieldPosition,
} from "api/argumentFields"
import {
  IArgumentField,
  IArgumentFieldUpdateResponse,
} from "api/types/argumentFields"
import { IGetPlaybookResponse } from "api/types/playbooks"

import { moveArgumentField } from "services/argumentFields"

import { useOptimisticMutation, useUpdateMutation } from "utils/hooks/mutations"
import { replaceById } from "utils/mutationHelpers"

import { buildPlaybookKey, setPlaybook } from "./playbookQueries"

const setArgumentFields = (
  prevData: IGetPlaybookResponse,
  updateFn: (prevArgumentFields: IArgumentField[]) => IArgumentField[]
) =>
  setPlaybook(prevData, (prevPlaybook) => ({
    ...prevPlaybook,
    argumentFields: updateFn(prevPlaybook.argumentFields),
  }))

const setArgumentField = (
  prevData: IGetPlaybookResponse,
  argumentField: IArgumentField
) =>
  setArgumentFields(prevData, (prevArgumentFields) =>
    replaceById(prevArgumentFields, argumentField)
  )

const useArgumentFieldMutation = <TError, TVariables>(
  playbookId: string,
  mutationFn: MutateFunction<IArgumentFieldUpdateResponse, TError, TVariables>
) =>
  useUpdateMutation(
    buildPlaybookKey(playbookId),
    mutationFn,
    (result, prevData: IGetPlaybookResponse | undefined) =>
      prevData && setArgumentField(prevData, result.argumentField)
  )

export const useArgumentFieldUpdateMutation = (playbookId: string) =>
  useArgumentFieldMutation(playbookId, apiUpdateArgumentField)

export const useArgumentFieldDeleteMutation = (playbookId: string) =>
  useArgumentFieldMutation(playbookId, apiArchiveArgumentField)

export const useArgumentFieldRestoreMutation = (playbookId: string) =>
  useArgumentFieldMutation(playbookId, apiRestoreArgumentField)

export const useArgumentFieldCreateMutation = (playbookId: string) =>
  useUpdateMutation(
    buildPlaybookKey(playbookId),
    apiCreateArgumentField,
    (result, prevData: IGetPlaybookResponse | undefined) =>
      prevData &&
      setArgumentFields(prevData, (prevArgumentFields) => [
        ...prevArgumentFields,
        result.argumentField,
      ])
  )

export const useArgumentFieldUpdatePositionMutation = (playbookId: string) =>
  useOptimisticMutation(
    buildPlaybookKey(playbookId),
    apiUpdateArgumentFieldPosition,
    (prevData: IGetPlaybookResponse | undefined, { id, relativeId }) =>
      prevData &&
      setArgumentFields(prevData, (prevArgumentFields) =>
        moveArgumentField(prevArgumentFields, id, relativeId)
      )
  )
