import MuiTableContainer, {
  TableContainerProps,
} from "@mui/material/TableContainer"
import { forwardRef } from "react"

export type { TableContainerProps }

export default forwardRef(function TableContainer(
  props: TableContainerProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiTableContainer {...props} ref={ref} />
})
