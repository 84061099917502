import Box, { BoxProps } from "@mui/material/Box"
import React, { forwardRef } from "react"

import { onKeyDownEnter } from "utils/onKeyDown"

export interface AnchorDivProps extends Omit<BoxProps, "onClick"> {
  // Doesn't take an event, as it is called for both onClick and onKeyDown
  onClick: React.MouseEventHandler<HTMLDivElement>
}

// Div that can be used like a button in an accessible way
export default forwardRef(function AnchorDiv(
  { onClick, style, ...props }: AnchorDivProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <Box
      ref={ref}
      role="link"
      style={{ cursor: "pointer", ...style }}
      tabIndex={0}
      onClick={onClick}
      // TODO fix typing
      onKeyDown={onKeyDownEnter((e) => onClick(e as any))}
      {...props}
    />
  )
})
