import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { useState } from "react"
import { useIntl } from "react-intl"

import IconButton from "ds/IconButton"
import Modal from "ds/Modal"

import ArgumentSegmentationsForm from "./ArgumentSegmentations/ArgumentSegmentationsForm"

export default function EditArgumentSegmentations() {
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()
  const editLabel = intl.formatMessage({
    id: "playbookEdit.argumentSegmentations.edit",
  })

  return (
    <>
      <IconButton aria-label={editLabel} onClick={() => setIsOpen(true)}>
        <EditOutlinedIcon fontSize="small" />
      </IconButton>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        sizeVariant="medium"
      >
        <ArgumentSegmentationsForm />
      </Modal>
    </>
  )
}
