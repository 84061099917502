import { useIntl } from "react-intl"

import { IMeetingArgumentNoteNumber } from "api/types/meetingArgumentNotes"
import { IMeetingNoteConfiguration } from "api/types/meetingNoteConfigurations"

import Stack from "ds/Stack"
import TextField from "ds/TextField"

import AdditionalInfo from "components/common/AdditionalInfo"

interface Props {
  note: IMeetingArgumentNoteNumber
  onValueChange: (newValue: string) => void
  onAdditionalInfoChange: (newValue: string) => void
  configuration: IMeetingNoteConfiguration
  isSaved: boolean
  disabled: boolean
}

export default function NumberForm({
  note,
  onValueChange,
  onAdditionalInfoChange,
  configuration,
  isSaved,
  disabled,
}: Props) {
  const { hasAdditionalInfo } = configuration
  const intl = useIntl()
  return (
    <Stack direction="row">
      <TextField
        sx={{ width: "100%" }}
        placeholder={intl.formatMessage({
          id: "playbookEdit.argumentAnswer.form.typeSelect.number",
          defaultMessage: "Number",
        })}
        type="number"
        size="small"
        value={note.value || ""}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => onValueChange(e.target.value)}
        disabled={disabled}
      />

      {hasAdditionalInfo && (
        <AdditionalInfo
          value={note.additionalInfo || ""}
          onChange={onAdditionalInfoChange}
          isSaved={isSaved}
          disabled={disabled}
        />
      )}
    </Stack>
  )
}
