import axios from "./axios"
import {
  IArgumentPersonalNoteGetAllParams,
  IArgumentPersonalNoteGetAllResponse,
  IArgumentPersonalNoteRemoveParams,
  IArgumentPersonalNoteUpsertParams,
  IArgumentPersonalNoteUpsertResponse,
} from "./types/argumentPersonalNotes"

export const apiUpsertArgumentPersonalNote = (
  params: IArgumentPersonalNoteUpsertParams
): Promise<IArgumentPersonalNoteUpsertResponse> =>
  axios
    .patch("api/argument_personal_notes/upsert", params)
    .then((res) => res.data)

export const apiRemoveArgumentPersonalNote = (
  params: IArgumentPersonalNoteRemoveParams
): Promise<undefined> =>
  axios
    .delete("api/argument_personal_notes/remove", { params })
    .then((res) => res.data)

export const apiGetAllArgumentPersonalNote = (
  params: IArgumentPersonalNoteGetAllParams,
  { signal }: { signal?: AbortSignal } = {}
): Promise<IArgumentPersonalNoteGetAllResponse> =>
  axios
    .get("api/argument_personal_notes/get_all", { params, signal })
    .then((res) => res.data)
