import { Box } from "@mui/material"
import { usePanel } from "chrome_extension/MeetingNotesPanelProvider"
import ModalContainerOverride from "chrome_extension/ModalContainerOverride"
import { useEffect } from "react"
import { createPortal } from "react-dom"

import OverlayScrollbarsComponent from "ds/OverlayScrollbarsComponent"

interface Props {
  children: React.ReactNode
}

export default function MeetingNotesExtensionPortal({ children }: Props) {
  const { setHasMeetingNotesPanelOpen, meetingNotesPanelRef } = usePanel()

  // Side effect to change the width of the iframe
  useEffect(() => {
    setHasMeetingNotesPanelOpen(true)
    return () => {
      setHasMeetingNotesPanelOpen(false)
    }
  }, [setHasMeetingNotesPanelOpen])

  const meetingNotesPortalNode = meetingNotesPanelRef.current
  if (!meetingNotesPortalNode) return null
  return createPortal(
    <ModalContainerOverride container={() => meetingNotesPanelRef.current}>
      <OverlayScrollbarsComponent
        options={{
          overflow: { y: "scroll", x: "hidden" },
        }}
        style={{ height: "100%" }}
      >
        <Box p={2}>{children}</Box>
      </OverlayScrollbarsComponent>
    </ModalContainerOverride>,
    meetingNotesPortalNode
  )
}
