import Loader from "ds/Loader"
import Modal from "ds/Modal"
import UncontrolledError from "ds/UncontrolledError"

import { useMeetingQuery } from "../meetingQueries"
import MeetingNotes from "./MeetingModal/MeetingNotes"

interface Props {
  onClose: () => void
  meetingId: string
}

export default function MeetingModal({ onClose, meetingId }: Props) {
  const {
    isLoading,
    isError,
    error,
    data: meeting,
  } = useMeetingQuery(meetingId)

  return (
    <Modal
      isOpen
      onClose={onClose}
      data-testid="show-meeting-modal"
      sizeVariant="big"
    >
      <>
        {isLoading && <Loader />}
        {!isLoading && isError && <UncontrolledError error={error} />}
        {!isLoading && meeting && <MeetingNotes meeting={meeting} />}
      </>
    </Modal>
  )
}
