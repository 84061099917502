import { useState } from "react"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import { BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"

import useConfirm from "utils/hooks/useConfirm"

import { useMeetingTypeDeleteMutation } from "../meetingTypeQueries"

interface Props {
  meetingTypeId: string
  businessUnitId: string
  onClose: () => void
}

export default function DeleteAction({
  businessUnitId,
  meetingTypeId,
  onClose,
}: Props) {
  const confirm = useConfirm()
  const deleteMutation = useMeetingTypeDeleteMutation(businessUnitId)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)

  const destroy = () => {
    confirm() &&
      deleteMutation.mutate(meetingTypeId, {
        onSuccess: () => {
          setSuccessSnackOpen(true)
          onClose()
        },
        onError: () => setErrorSnackOpen(true),
      })
  }
  return (
    <>
      <Button
        color="error"
        variant="contained"
        onClick={destroy}
        disabled={deleteMutation.isLoading}
      >
        <FormattedMessage id="common.remove" defaultMessage="Remove" />
      </Button>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
    </>
  )
}
