import { FormattedMessage } from "react-intl"

import Alert from "ds/Alert"
import Typography from "ds/Typography"

interface Props {
  resourceNames: string
}

export default function EmptyCRMResourcesWithAnswersWarning({
  resourceNames,
}: Props) {
  return (
    <Alert severity="warning">
      <Typography variant="smNormal">
        <FormattedMessage
          id="playbook.meeting.endDialog.emptyCRMResourcesWithAnswersWarning"
          values={{
            resourceNames,
            b: (chunks) => <Typography variant="smBold">{chunks}</Typography>,
          }}
        />
      </Typography>
    </Alert>
  )
}
