import { Dictionary, difference, groupBy, intersection } from "lodash"

import { IArgumentField } from "api/types/argumentFields"
import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IArgument, IArgumentSearchResult } from "api/types/arguments"
import { IPlaybook } from "api/types/playbooks"

import { filterNonArchived } from "services/archivable"

import changeArrayPosition from "utils/changeArrayPosition"
import moveBefore from "utils/moveBefore"
import { insertAtIndex, updateById } from "utils/mutationHelpers"

import { findSegmentRule } from "./argumentSegmentRules"
import { SEGMENT_ALL } from "./argumentSegmentations"

export function hasVotesEnabled(argument: IArgument): boolean {
  return argument.kind === "VOTE"
}

// Group by argumentGroupId
export function groupArguments(_arguments: IArgument[]) {
  return groupBy(_arguments, (argument) => argument.argumentGroupId || "none")
}

export function sortArgumentsByOrderedGroups(
  _arguments: IArgument[],
  argumentGroupIds: string[]
) {
  let sortedArguments: IArgument[] = []

  const groupedArguments = groupArguments(_arguments)

  argumentGroupIds.forEach((id) => {
    if (groupedArguments[id]) {
      sortedArguments = [...sortedArguments, ...groupedArguments[id]]
    }
  })

  if (groupedArguments["none"]) {
    sortedArguments = [...sortedArguments, ...groupedArguments["none"]]
  }

  return sortedArguments
}

export function replaceArgument(
  _arguments: IArgument[],
  replacedArgument: IArgument
) {
  return replaceArguments(_arguments, [replacedArgument])
}

export function replaceArguments(
  _arguments: IArgument[],
  replacedArguments: IArgument[]
) {
  return _arguments.map(
    (argument) =>
      replacedArguments.find(
        (replacedArgument) => replacedArgument.id === argument.id
      ) || argument
  )
}

export function resetArgumentNotVisitedNotifications(
  _arguments: IArgument[],
  argumentId: string
) {
  return updateById(_arguments, argumentId, (argument) => ({
    ...argument,
    notVisitedNotificationsCount: 0,
  }))
}

function argumentMatches(
  argument: IArgument,
  argumentSegmentationId: string,
  argumentSegmentIds: string[]
): boolean {
  const relevantRule = findSegmentRule(
    argument.segmentRules,
    argumentSegmentationId
  )

  if (!relevantRule) {
    return true
  }
  if (argumentSegmentIds.length === 0) {
    return true
  }
  if (argumentSegmentIds.includes(SEGMENT_ALL)) {
    return true
  }
  switch (relevantRule.operator) {
    case "ALL":
      return true
    case "ONLY":
      return (
        intersection(argumentSegmentIds, relevantRule.argumentSegmentIds)
          .length > 0
      )

    case "EXCEPT":
      return (
        relevantRule.argumentSegmentIds.length === 0 ||
        difference(relevantRule.argumentSegmentIds, argumentSegmentIds).length >
          0
      )
  }
}

export function filterArguments(
  _arguments: IArgument[],
  argumentSegmentations: IArgumentSegmentation[],
  {
    typeId,
    segmentFilters = {},
    showArchived = false,
  }: {
    typeId?: string
    segmentFilters?: Dictionary<string[]>
    showArchived?: boolean
  }
) {
  let filteredArguments = _arguments

  if (!showArchived) {
    filteredArguments = filteredArguments.filter(
      (argument) => !argument.archived
    )
  }

  if (typeId) {
    filteredArguments = filteredArguments.filter(
      (argument) => argument.argumentTypeId === typeId
    )
  }

  argumentSegmentations.forEach((argumentSegmentation) => {
    filteredArguments = filteredArguments.filter((argument) =>
      argumentMatches(
        argument,
        argumentSegmentation.id,
        segmentFilters[argumentSegmentation.id] || []
      )
    )
  })

  return filteredArguments
}

export function getNbArgumentsBySegment(
  _arguments: IArgument[],
  argumentSegmentations: IArgumentSegmentation[],
  showArchived: boolean,
  typeId: string | undefined,
  segmentFilters: Dictionary<string[]>
) {
  const result: Dictionary<number> = {}

  argumentSegmentations.forEach((argumentSegmentation) => {
    filterNonArchived(argumentSegmentation.argumentSegments).forEach(
      (argumentSegment) => {
        result[argumentSegment.id] = filterArguments(
          _arguments,
          argumentSegmentations,
          {
            showArchived,
            typeId,
            segmentFilters: {
              ...segmentFilters,
              [argumentSegmentation.id]: [argumentSegment.id],
            },
          }
        ).length
      }
    )
  })

  return result
}

export function filterArgumentSearchResults(
  argumentSearchResults: IArgumentSearchResult[],
  segmentFilters: Dictionary<string[]>,
  playbook: IPlaybook
) {
  const _arguments = argumentSearchResults.map((x) => x.argument)
  const filteredArguments = filterArguments(
    _arguments,
    playbook.argumentSegmentations,
    {
      segmentFilters,
      showArchived: true,
    }
  )
  const filteredArgumentIds = new Set(filteredArguments.map((x) => x.id))
  return argumentSearchResults.filter((x) =>
    filteredArgumentIds.has(x.argument.id)
  )
}

export function getNbArgumentSearchResultsBySegment(
  argumentSearchResults: IArgumentSearchResult[],
  segmentFilters: Dictionary<string[]>,
  playbook: IPlaybook
) {
  const _arguments = argumentSearchResults.map((x) => x.argument)
  return getNbArgumentsBySegment(
    _arguments,
    playbook.argumentSegmentations,
    true,
    undefined,
    segmentFilters
  )
}

export function moveArgument(
  _arguments: IArgument[],
  argumentId: string,
  relativeArgumentId: string | null,
  newArgumentGroupId: string | null
) {
  const argument = _arguments.find((x) => x.id === argumentId)
  if (!argument) throw new Error(`Cannot move argument ${argumentId}`)

  const replacedArgument = {
    ...argument,
    argumentGroupId: newArgumentGroupId,
  }

  const oldPosition = _arguments.findIndex((x) => x.id === argumentId)

  if (!relativeArgumentId) {
    // It means we moved the argument to an empty group,
    //   so we push the argument to the end of the group
    const movedArguments = changeArrayPosition(
      _arguments,
      oldPosition,
      _arguments.length - 1
    )
    return replaceArgument(movedArguments, replacedArgument)
  }

  const relativePosition = _arguments.findIndex(
    (x) => x.id === relativeArgumentId
  )

  if (argument.argumentGroupId === newArgumentGroupId) {
    // We just move the argument in the same group
    return changeArrayPosition(_arguments, oldPosition, relativePosition)
  }

  // Else, we just moved the argument to another group,
  // so we move the argument before the relative argument
  const movedArguments = moveBefore(_arguments, oldPosition, relativePosition)
  return replaceArgument(movedArguments, replacedArgument)
}

// Count arguments having a truthy field *raw* value for a given field
export function filterArgumentsWithField(
  _arguments: IArgument[],
  argumentField: IArgumentField
) {
  return _arguments
    .filter((argument) => !argument.archived)
    .filter((argument) =>
      argument.argumentFieldValues.some(
        (argumentFieldValue) =>
          argumentFieldValue.argumentFieldId === argumentField.id &&
          !!argumentFieldValue.rawValue
      )
    )
}

export function insertDuplicatedArguments(
  prevArguments: IArgument[],
  originalArgumentIds: string[],
  duplicatedArguments: IArgument[]
) {
  let result = prevArguments

  originalArgumentIds.forEach((originalArgumentId, index) => {
    result = insertDuplicatedArgument(
      result,
      originalArgumentId,
      duplicatedArguments[index]
    )
  })

  return result
}

function insertDuplicatedArgument(
  _arguments: IArgument[],
  originalArgumentId: string,
  duplicatedArgument: IArgument
) {
  const originalArgumentIndex = _arguments.findIndex(
    (argument) => argument.id === originalArgumentId
  )
  return insertAtIndex(
    _arguments,
    originalArgumentIndex + 1,
    duplicatedArgument
  )
}

const STALE_TIME_MS = 1000 * 60 * 60 * 24 * 90 // 3 months

export function isArgumentStale(argument: IArgument) {
  const timeSinceLastTouch =
    new Date().getTime() - new Date(argument.fieldsLastTouchedAt).getTime()
  return timeSinceLastTouch > STALE_TIME_MS
}

export function argumentsHaveAnySegmentRuleActiveForSegmentation(
  _arguments: IArgument[],
  argumentSegmentation: IArgumentSegmentation
) {
  return _arguments.some((argument) =>
    argument.segmentRules.some(
      (segmentRule) =>
        segmentRule.operator !== "ALL" &&
        segmentRule.argumentSegmentationId === argumentSegmentation.id
    )
  )
}

export function isCheckboxArgument(argument: IArgument) {
  return argument.kind === "CHECKBOX"
}
