import axios from "./axios"
import { INotification } from "./types/notifications"

export const apiGetNotifications = ({
  signal,
}: {
  signal?: AbortSignal
}): Promise<INotification[]> =>
  axios.get("api/notifications", { signal }).then((res) => res.data)

export const apiCountNotSeenNotifications = ({
  signal,
}: {
  signal?: AbortSignal
}): Promise<number> =>
  axios
    .get("api/notifications/count_not_seen", { signal })
    .then((res) => res.data)

export const apiMarkNotificationsAsSeen = (
  notificationIds: string[]
): Promise<undefined> =>
  axios
    .patch("api/notifications/mark_as_seen", { notificationIds })
    .then((res) => res.data)
