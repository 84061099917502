import styled, { keyframes } from "styled-components"

const VoteAnimationContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;

  div:nth-child(1) {
    transform: rotate(72deg);
  }
  div:nth-child(2) {
    transform: rotate(144deg);
  }
  div:nth-child(3) {
    transform: rotate(216deg);
  }
  div:nth-child(4) {
    transform: rotate(288deg);
  }
`

const circleAnimation = keyframes`
  30% { left: 75%; }
  60%, 100% {
    left: 100%;
  }
`

const triangleAnimation = keyframes`
  0%, 30% {
    opacity: 0;
    left: 75%;
  }
  80%, 100% { left: 150%; }
`

const VoteAnimationPath = styled.div`
  width: 15px;
  height: 4px;
  position: absolute;
  transform-origin: 0 50%;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
  }

  //circles
  &:before {
    border-radius: 50%;
    width: 2.5px;
    height: 2.5px;
    background: #3498db;
    animation: ${circleAnimation} 1s linear forwards;
  }

  //triangles
  &:after {
    top: -4px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 4px solid #e67e22;
    animation: ${triangleAnimation} 1s linear forwards;
  }
`

interface Props {
  onAnimationEnd: React.AnimationEventHandler<HTMLDivElement>
}

export default function VoteAnimation({ onAnimationEnd }: Props) {
  return (
    <VoteAnimationContainer onAnimationEnd={onAnimationEnd}>
      <VoteAnimationPath />
      <VoteAnimationPath />
      <VoteAnimationPath />
      <VoteAnimationPath />
      <VoteAnimationPath />
    </VoteAnimationContainer>
  )
}
