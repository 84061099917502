import {
  ELEMENT_TOGGLE,
  TogglePlugin,
  createTogglePlugin,
} from "@udecode/plate"

import Toggle from "../components/Toggle"
import { MyEditor, MyValue } from "../types"

export const createMyTogglePlugin = () =>
  createTogglePlugin<TogglePlugin, MyValue, MyEditor>()

export const toggleComponents = {
  [ELEMENT_TOGGLE]: Toggle,
}
