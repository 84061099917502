import {
  DragDropContext,
  Draggable,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  OnDragEndResponder,
} from "@hello-pangea/dnd"
import { Box } from "@mui/material"
import { useVirtualizer } from "@tanstack/react-virtual"
import { useOverlayScrollbars } from "overlayscrollbars-react"
import { useEffect, useRef } from "react"
import styled from "styled-components"

import { IArgument } from "api/types/arguments"

import { defaultOverlayScrollbarsOptions } from "ds/OverlayScrollbarsComponent"
import Stack from "ds/Stack"

import ArgumentCard from "./Argument/ArgumentCard"
import AddArgumentCTAs from "./PlaybookEdit/Argument/AddArgumentCTAs"
import { usePlaybook } from "./PlaybookProvider"
import {
  ARGUMENTS_SCROLL_CONTAINER_ID,
  usePlaybookScrollRestore,
} from "./playbookScrollRestore"
import { useArgumentUpdatePositionMutation } from "./queries/argumentQueries"

const Container = styled.div`
  flex-grow: 1;
  min-height: 0; // To not fit the children by default

  // To position the "Add group" CTAs
  position: relative;

  .os-scrollbar-vertical {
    right: 2px;
  }
`

const BottomFloatingActionsContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16px;
  z-index: 2;
`

interface Props {
  _arguments: IArgument[]
  providedDroppable?: DroppableProvided
  droppableSnapshot?: DroppableStateSnapshot
}

function References(props: Props) {
  const { editMode } = usePlaybook()
  const droppableRef = props.providedDroppable?.innerRef
  const droppableProps = props.providedDroppable?.droppableProps || {}

  const rootRef = usePlaybookScrollRestore()
  const viewportRef = useRef(null)
  const [initialize] = useOverlayScrollbars({
    defer: true,
    options: defaultOverlayScrollbarsOptions,
  })
  // Rendering an extra item for the placeholder of DnD
  const count =
    props._arguments.length +
    (props.droppableSnapshot?.isUsingPlaceholder ? 1 : 0)
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => viewportRef.current,
    estimateSize: () => 500,
  })

  // Make overlay scrollbars and react-virtualized work together nicely
  useEffect(() => {
    const { current: root } = rootRef
    const { current: viewport } = viewportRef

    if (root && viewport) {
      initialize({
        target: root,
        elements: {
          viewport,
        },
      })
    }
  }, [initialize, rootRef])

  const items = virtualizer.getVirtualItems()

  return (
    <Container>
      <Box
        data-overlayscrollbars-initialize=""
        ref={rootRef}
        id={ARGUMENTS_SCROLL_CONTAINER_ID}
        sx={{ height: "100%" }}
      >
        <Box ref={viewportRef} sx={{ height: "100%" }}>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: virtualizer.getTotalSize(),
            }}
          >
            <Box
              position="absolute"
              width="100%"
              top="0"
              left="0"
              sx={{ transform: `translateY(${items[0]?.start ?? 0}px)` }}
            >
              <Stack
                px={2}
                minHeight="10px"
                mb={12} // To be able to drop arguments even if empty
                spacing={2}
                ref={droppableRef}
                {...droppableProps}
              >
                {items.map((virtualRow) => {
                  const argument = props._arguments[virtualRow.index]

                  if (argument)
                    return (
                      <div
                        key={virtualRow.key}
                        data-index={virtualRow.index}
                        ref={virtualizer.measureElement}
                      >
                        {editMode ? (
                          <Draggable
                            draggableId={argument.id}
                            index={virtualRow.index}
                            key={argument.id}
                          >
                            {(providedDraggable) => (
                              <ArgumentCard
                                argument={argument}
                                providedDraggable={providedDraggable}
                                richText={false}
                              />
                            )}
                          </Draggable>
                        ) : (
                          <ArgumentCard key={argument.id} argument={argument} />
                        )}
                      </div>
                    )

                  return null
                })}
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>

      {editMode && (
        <BottomFloatingActionsContainer>
          <AddArgumentCTAs arguments={props._arguments} argumentGroup={null} />
        </BottomFloatingActionsContainer>
      )}
    </Container>
  )
}

interface ReferencesWithDndProps {
  _arguments: IArgument[]
}

export default function ReferencesWithDnd(props: ReferencesWithDndProps) {
  const { playbook, editMode } = usePlaybook()

  const argumentUpdatePositionMutation = useArgumentUpdatePositionMutation(
    playbook.id
  )

  if (!editMode) return <References {...props} />

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return
    }
    if (result.type === "droppableForArguments") {
      const argumentId = result.draggableId
      let newArgumentGroupId = null
      const newPosition = result.destination.index
      const relativeArgument = props._arguments[newPosition]
      argumentUpdatePositionMutation.mutate({
        id: argumentId,
        relativeId: relativeArgument?.id,
        argumentGroupId: newArgumentGroupId,
      })
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={"none"}
        type="droppableForArguments"
        mode="virtual"
        renderClone={(provided, _snapshot, rubric) => {
          const argument = props._arguments[rubric.source.index]
          return (
            <ArgumentCard
              argument={argument}
              providedDraggable={provided}
              richText={false}
            />
          )
        }}
      >
        {(providedDroppable, snapshot) => (
          <References
            {...props}
            providedDroppable={providedDroppable}
            droppableSnapshot={snapshot}
          />
        )}
      </Droppable>
    </DragDropContext>
  )
}
