import { Box } from "@mui/material"

import { IUpcomingCall } from "api/types/CRMCalls"

import FormattedTime from "ds/FormattedTime"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import IntegrationIcon from "components/common/IntegrationIcon"

function getOptionLabel(call: IUpcomingCall) {
  return call.title
}

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  crmCall: IUpcomingCall
}

export default function CRMCallItem({ crmCall, ...props }: Props) {
  return (
    <li {...props}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          overflow="hidden"
          alignItems="center"
        >
          <IntegrationIcon integrationName={crmCall.integrationName} />

          <Box overflow="hidden" textOverflow="ellipsis">
            {getOptionLabel(crmCall)}
          </Box>
        </Stack>

        <Typography variant="xsNormal" flexShrink={0}>
          <FormattedTime value={crmCall.startTime} format="shortTime" />
        </Typography>
      </Stack>
    </li>
  )
}
