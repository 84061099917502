import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { apiCreateLog } from "api/logs"
import { IArgument } from "api/types/arguments"

import useMe from "authentication/useMe"

import Button from "ds/Button"
import Stack from "ds/Stack"
import Textarea from "ds/Textarea"
import Typography from "ds/Typography"

import { useBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useConfig } from "components/Config"

import { getRawTitle } from "services/argumentFields"

import { getArgumentPath } from "../routing"

interface Props {
  argument: IArgument
  backButton: React.ReactNode
}

export default function SharePanel({ argument, backButton }: Props) {
  const config = useConfig()
  const [additionalText, setAdditionalText] = useState("")
  const intl = useIntl()
  const user = useMe()
  const { playbook } = usePlaybook()
  const businessUnit = useBusinessUnit()
  const userName = user.name || user.email
  const argumentUrl = `${config.appUrl}${getArgumentPath(
    businessUnit.id,
    playbook.id,
    argument.id
  )}`

  const contentToCopy = intl.formatMessage(
    {
      id: "arguments.share.message",
      defaultMessage: "{argumentTitle}\n\n{argumentUrl}\n\n{additionalText}",
    },
    {
      userName,
      argumentTitle: getRawTitle(argument),
      argumentUrl,
      additionalText,
    }
  )

  const placeholder = intl.formatMessage({
    id: "arguments.share.additionalTextPlaceholder",
    defaultMessage: "Type your question here...",
  })

  const onClick = () => {
    navigator.clipboard.writeText(contentToCopy)
    apiCreateLog({
      action: "SHARE_ARGUMENT_QUESTION",
      countsAsActivity: true,
      playbookId: playbook.id,
    })
  }

  return (
    <Stack p={2} spacing={2} height="100%" overflow="auto">
      <Stack direction="row" spacing={1} alignItems="center">
        {backButton}

        <Typography variant="lgSemi">
          <FormattedMessage id="arguments.share.title" />
        </Typography>
      </Stack>

      <Stack
        spacing={2}
        boxShadow={6}
        borderRadius={1}
        bgcolor={(theme) => theme.palette.common.white}
        p={2}
      >
        <Typography variant="baseSemi">
          <FormattedMessage
            id="arguments.share.question"
            defaultMessage="1. What question do you want to ask your team?"
          />
        </Typography>

        <Textarea
          value={additionalText}
          onChange={(e) => setAdditionalText(e.target.value)}
          placeholder={placeholder}
          autoFocus
        />

        <Typography variant="baseSemi">
          <FormattedMessage
            id="arguments.share.instruction"
            defaultMessage="2. Click this button to copy your question and paste it into your chat app:"
          />
        </Typography>

        <Box px={3}>
          <Button variant="outlined" color="primary" onClick={onClick}>
            <FormattedMessage
              id="arguments.share.copyToClipboard"
              defaultMessage="Copy to clipboard"
            />
          </Button>
        </Box>

        <Box
          whiteSpace="pre-wrap"
          sx={{ wordWrap: "break-word" }}
          padding={2}
          bgcolor={(theme) => theme.palette.grey[50]}
          borderRadius={1}
        >
          <Typography variant="xxsNormal">
            <FormattedMessage
              id="arguments.share.hint"
              defaultMessage="Text generated for the chat application"
            />
          </Typography>

          <Box mt={1}>{contentToCopy}</Box>
        </Box>
      </Stack>
    </Stack>
  )
}
