import { AnyObject, getMarks, isHotkey, setMarks } from "@udecode/plate"
import {
  MARK_BG_COLOR,
  createFontBackgroundColorPlugin,
} from "@udecode/plate-font"

import { AvailableTranslationKeys } from "translations/TranslationsProvider"

import { MyEditor, MyValue } from "../types"

export const fontBackgroundColorPlugin = createFontBackgroundColorPlugin<
  AnyObject,
  MyValue,
  MyEditor
>({
  handlers: {
    onKeyDown: (editor, plugin) => (e) => {
      if (e.defaultPrevented) return
      if (plugin.options.hotkey && isHotkey(plugin.options.hotkey, e)) {
        e.preventDefault()
        const marks = getMarks(editor) || {}
        setMarks(editor, {
          [MARK_BG_COLOR]: marks[MARK_BG_COLOR]
            ? undefined
            : HIGHLIGHT_COLORS[0].value,
        })
      }
    },
  },
  options: {
    hotkey: ["mod+shift+h"],
  },
})

export const HIGHLIGHT_COLORS: {
  intlKey: AvailableTranslationKeys
  value: string
  isBrightColor: boolean
}[] = [
  {
    intlKey: "richtext.highlight.color.YELLOW",
    value: "rgb(250, 205, 90)",
    isBrightColor: true,
  },
  {
    intlKey: "richtext.highlight.color.GREEN",
    value: "rgb(123, 200, 104)",
    isBrightColor: true,
  },
  {
    intlKey: "richtext.highlight.color.PINK",
    value: "rgb(251, 143, 173)",
    isBrightColor: true,
  },
  {
    intlKey: "richtext.highlight.color.PURPLE",
    value: "rgb(229, 168, 246)",
    isBrightColor: true,
  },
  {
    intlKey: "richtext.highlight.color.BLUE",
    value: "rgb(181, 215, 254)",
    isBrightColor: false,
  },
  {
    intlKey: "richtext.highlight.color.GREY",
    value: "rgb(217, 217, 217)",
    isBrightColor: false,
  },
]
