import { Box } from "@mui/material"
import { useIntl } from "react-intl"

import { IMeetingArgumentNoteBoolean } from "api/types/meetingArgumentNotes"
import { IMeetingNoteConfiguration } from "api/types/meetingNoteConfigurations"

import { RadioFormControlLabel } from "ds/FormControlLabel"
import Radio from "ds/Radio"
import RadioGroup from "ds/RadioGroup"
import Stack from "ds/Stack"

import AdditionalInfo from "components/common/AdditionalInfo"

interface Props {
  note: IMeetingArgumentNoteBoolean
  onBooleanChange: (newValue: boolean) => void
  onAdditionalInfoChange: (newValue: string) => void
  configuration: IMeetingNoteConfiguration
  isSaved: boolean
  disabled: boolean
}

export default function BooleanForm({
  note,
  onBooleanChange,
  onAdditionalInfoChange,
  configuration,
  isSaved,
  disabled,
}: Props) {
  const { hasAdditionalInfo } = configuration
  const intl = useIntl()

  const yesLabel = intl.formatMessage({
    id: "common.yes",
    defaultMessage: "Yes",
  })

  const noLabel = intl.formatMessage({
    id: "common.no",
    defaultMessage: "No",
  })

  return (
    <Stack direction="row">
      <Box flexGrow={1}>
        <RadioGroup
          row
          onChange={(e) => onBooleanChange(e.target.value === "true")}
          value={note.value}
        >
          <RadioFormControlLabel
            value="true"
            label={yesLabel}
            control={<Radio />}
            disabled={disabled}
          />
          <RadioFormControlLabel
            value="false"
            label={noLabel}
            control={<Radio />}
            disabled={disabled}
          />
        </RadioGroup>
      </Box>

      {hasAdditionalInfo && (
        <AdditionalInfo
          value={note.additionalInfo || ""}
          onChange={onAdditionalInfoChange}
          isSaved={isSaved}
          disabled={disabled}
        />
      )}
    </Stack>
  )
}
