import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

import { IArgument } from "api/types/arguments"

import Avatar from "ds/Avatar"
import IconButton from "ds/IconButton"
import { BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import Tooltip from "ds/Tooltip"

import { useMeeting } from "components/App/Playbook/Meeting/MeetingProvider"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentVoteMutation } from "components/App/Playbook/queries/argumentQueries"

import { getMeetingVoteOnArgument } from "services/meetings"
import { getVoteCountDelta, getVoteValue } from "services/votes"

import VoteCount from "./VoteControls/VoteCount"

const VoteButton = styled(IconButton)`
  svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
    user-select: none; // Don't select text when clicking on the SVG

    // Only focus when using keyboard
    &:focus:not(:focus-visible) {
      outline: none;
    }
  }
` as typeof IconButton

const UpvoteButton = styled(VoteButton)`
  position: relative;
  padding-bottom: 0;
  top: 5px; // Magic value
` as typeof IconButton

const DownvoteButton = styled(VoteButton)`
  position: relative;
  padding-top: 0;
  bottom: 5px; // Magic value
` as typeof IconButton

const VoteButtonContainer = styled.span`
  line-height: 0;
`

interface Props {
  argument: IArgument
  disabled?: boolean
}

export default function VoteControls({ argument, disabled = false }: Props) {
  const intl = useIntl()
  const { playbook, segmentFilters, readOnly, activeTypeId } = usePlaybook()
  const argumentVoteMutation = useArgumentVoteMutation(
    playbook.id,
    activeTypeId
  )
  const { ongoingMeeting } = useMeeting()
  const { upvoted: upvotedDuringMeeting, downvoted: downvotedDuringMeeting } =
    getMeetingVoteOnArgument(argument, ongoingMeeting)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  function onVote(direction: "up" | "down") {
    const value = getVoteValue(
      direction,
      upvotedDuringMeeting,
      downvotedDuringMeeting
    )
    const delta = getVoteCountDelta(
      direction,
      upvotedDuringMeeting,
      downvotedDuringMeeting
    )
    argumentVoteMutation.mutate(
      {
        meetingId: ongoingMeeting?.id,
        argumentId: argument.id,
        count: value,
        segmentIds: Object.values(segmentFilters).flat(),
        delta,
      },
      { onError: () => setErrorSnackOpen(true) }
    )
  }

  const upvoteTooltip = intl.formatMessage({
    id: "arguments.upvoteTooltip",
    defaultMessage: "this talking point resonated with customer",
  })

  const downvoteTooltip = intl.formatMessage({
    id: "arguments.downvoteTooltip",
    defaultMessage: "this talking point did not resonate with customer",
  })

  return (
    <>
      <Votes
        upvoteTooltip={disabled ? null : upvoteTooltip}
        downvoteTooltip={disabled ? null : downvoteTooltip}
        disabled={readOnly || disabled}
        onVoteUp={() => onVote("up")}
        onVoteDown={() => onVote("down")}
        upvoted={upvotedDuringMeeting}
        downvoted={downvotedDuringMeeting}
        votesCount={argument.votesCount}
      />

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </>
  )
}

interface VoteProps {
  upvoteTooltip?: React.ReactNode
  downvoteTooltip?: React.ReactNode
  disabled?: boolean
  onVoteUp?: () => void
  onVoteDown?: () => void
  upvoted?: boolean
  downvoted?: boolean
  votesCount?: number
}

export function Votes({
  upvoteTooltip,
  downvoteTooltip,
  disabled = true,
  onVoteUp,
  onVoteDown,
  upvoted = false,
  downvoted = false,
  votesCount = 0,
}: VoteProps) {
  return (
    <Avatar
      sx={{
        bgcolor: (theme) => theme.palette.grey[100],
        color: (theme) => theme.palette.text.primary,
      }}
    >
      <Stack alignItems="center" justifyContent="center">
        <Tooltip title={upvoteTooltip} placement="right">
          <VoteButtonContainer>
            <UpvoteButton
              disabled={disabled}
              onClick={onVoteUp}
              aria-label="upvote" // TODO remove, use the tooltip in tests instead
              color={upvoted ? "success" : undefined}
              aria-pressed={upvoted}
              noBackgroundOnHover
            >
              <ExpandLessIcon />
            </UpvoteButton>
          </VoteButtonContainer>
        </Tooltip>

        <VoteCount votesCount={votesCount} />

        <Tooltip title={downvoteTooltip} placement="right">
          <VoteButtonContainer>
            <DownvoteButton
              disabled={disabled}
              onClick={onVoteDown}
              aria-label="downvote" // TODO remove, use the tooltip in tests instead
              color={downvoted ? "error" : undefined}
              aria-pressed={downvoted}
              noBackgroundOnHover
            >
              <ExpandMoreIcon />
            </DownvoteButton>
          </VoteButtonContainer>
        </Tooltip>
      </Stack>
    </Avatar>
  )
}
