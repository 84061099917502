import { FormattedMessage, useIntl } from "react-intl"

import { IBusinessUnitMembership } from "api/types/businessUnitMemberships"

import Button from "ds/Button"
import Typography from "ds/Typography"

import useConfirm from "utils/hooks/useConfirm"

import { useBusinessUnitMembershipDeactivateMutation } from "./businessUnitMembershipsQueries"

interface Props {
  businessUnitMembership: IBusinessUnitMembership
}

export default function BusinessUnitMembershipDelete({
  businessUnitMembership,
}: Props) {
  const intl = useIntl()

  const deactivateMutation = useBusinessUnitMembershipDeactivateMutation(
    businessUnitMembership.businessUnitId
  )

  const confirmDelete = useConfirm({
    message: intl.formatMessage({
      id: "businessUnits.memberships.delete.confirm",
    }),
  })

  const onClick = () => {
    if (!confirmDelete()) return
    deactivateMutation.mutate(businessUnitMembership.id)
  }

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={!businessUnitMembership.active}
    >
      <Typography whiteSpace="nowrap">
        <FormattedMessage id="businessUnits.memberships.delete" />
      </Typography>
    </Button>
  )
}
