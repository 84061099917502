import NotesIcon from "@mui/icons-material/Notes"
import { Box } from "@mui/material"

import { IArgumentPersonalNote } from "api/types/argumentPersonalNotes"

import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import ArgumentPersonalNoteTooltip from "./ArgumentPersonalNoteTooltip"

interface Props {
  argumentPersonalNote: IArgumentPersonalNote
  light?: boolean
}

export default function ArgumentPersonalNote({
  argumentPersonalNote,
  light,
}: Props) {
  return (
    <Box
      sx={{
        border: (theme) => `solid 1px ${theme.palette.grey[300]}`,
        bgcolor: (theme) =>
          light ? theme.palette.common.white : theme.palette.grey[50],
        px: 1.5,
        py: 0.75,
        borderRadius: 1,
      }}
    >
      <Stack direction="row" spacing={1} width="100%">
        <ArgumentPersonalNoteTooltip>
          <NotesIcon fontSize="small" />
        </ArgumentPersonalNoteTooltip>

        <Typography variant="smNormal">
          <RichTextEditor
            readOnly
            value={argumentPersonalNote.value || buildNodeFromText("")}
          />
        </Typography>
      </Stack>
    </Box>
  )
}
