import axios from "./axios"
import {
  ICurrentUserResponse,
  ICurrentUserUpdateParams,
  ICurrentUserUpdateResponse,
} from "./types/me"

export const apiGetMe = (locale: string): Promise<ICurrentUserResponse> =>
  axios.get("api/users/show_me", { params: { locale } }).then((res) => res.data)

export const apiUpdateMe = (
  params: ICurrentUserUpdateParams
): Promise<ICurrentUserUpdateResponse> =>
  axios.patch("api/users/update_profile", params).then((res) => res.data)
