import { IBarePlaybook } from "api/types/playbooks"

import Modal from "ds/Modal"

import ExportPlaybookForm from "./ExportPlaybookForm"

interface Props {
  playbook: IBarePlaybook
  onClose: () => void
}

export default function ExportPlaybook({ playbook, onClose }: Props) {
  return (
    <Modal isOpen onClose={onClose}>
      <ExportPlaybookForm playbook={playbook} onExport={onClose} />
    </Modal>
  )
}
