import { FormattedMessage } from "react-intl"

import { ICRMProperty } from "api/types/CRMProperties"

import Tooltip from "ds/Tooltip"
import Typography from "ds/Typography"

import IntegrationIcon from "components/common/IntegrationIcon"

interface Props {
  crmProperty: ICRMProperty
}

export default function LinkedToCrmPropertyHint(props: Props) {
  return (
    <Tooltip
      title={
        <div>
          <div>
            <Typography variant="smNormal">
              <FormattedMessage id="argumentSegmentations.linkedToCRMProperty" />
            </Typography>
          </div>
          <div>
            <Typography variant="smBold">{`${props.crmProperty.objectType} - ${props.crmProperty.name}`}</Typography>
          </div>
        </div>
      }
    >
      <IntegrationIcon integrationName={props.crmProperty.integrationName} />
    </Tooltip>
  )
}
