import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"

import { useConfig } from "components/Config"

import { getCRMMeetingTypesPath } from "services/CRMMeetingTypes"

interface Props {
  businessUnitId: string
  onClick: React.MouseEventHandler
}

export default function MeetingTypesMenuItem({
  businessUnitId,
  onClick,
}: Props) {
  const config = useConfig()
  const to = getCRMMeetingTypesPath(businessUnitId)

  return (
    <MenuItem
      component={IS_CHROME_EXTENSION ? "a" : Link}
      to={IS_CHROME_EXTENSION ? undefined : to}
      href={IS_CHROME_EXTENSION ? `${config.appUrl}${to}` : undefined}
      target={IS_CHROME_EXTENSION ? "_blank" : undefined}
      onClick={onClick}
    >
      <ListItemIcon>
        <AssignmentOutlinedIcon />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage
          id="navigation.meetingTypes"
          defaultMessage="Meeting Types"
        />
      </ListItemText>
    </MenuItem>
  )
}
