import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import Button from "ds/Button"
import MenuItem from "ds/MenuItem"
import Modal from "ds/Modal"
import Select from "ds/Select"
import { BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentsMoveMutation } from "components/App/Playbook/queries/argumentQueries"

import { filterNonArchived } from "services/archivable"

interface Props {
  argumentIds: string[]
  close: () => void
  afterSubmit?: () => void
}

export default function MoveArgumentsModal({
  argumentIds,
  close,
  afterSubmit,
}: Props) {
  const intl = useIntl()
  const { playbook, activeTypeId } = usePlaybook()
  const argumentsMoveMutation = useArgumentsMoveMutation(
    playbook.id,
    activeTypeId
  )
  const [argumentTypeId, setArgumentTypeId] = useState("")
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const moveArgumentsModalTitle = intl.formatMessage({
    id: "playbookEdit.arguments.move.description",
    defaultMessage: "Move selected talking points to another tab",
  })

  const argumentTypeLabel = intl.formatMessage({
    id: "playbookEdit.argumentType",
    defaultMessage: "Tab",
  })

  const submit = () =>
    argumentsMoveMutation.mutate(
      { argumentIds, argumentTypeId },
      {
        onSuccess: () => {
          afterSubmit?.()
          close()
        },
        onError: () => setErrorSnackOpen(true),
      }
    )

  return (
    <Modal isOpen={true} onClose={close} hideClosingCross>
      <Stack spacing={2}>
        <Typography variant="baseSemi">{moveArgumentsModalTitle}</Typography>

        <Select
          label={argumentTypeLabel}
          labelId="move-to-tab"
          value={argumentTypeId}
          onChange={(e) => setArgumentTypeId(e.target.value)}
        >
          {filterNonArchived(playbook.argumentTypes).map((argumentType) => (
            <MenuItem key={argumentType.id} value={argumentType.id}>
              {argumentType.name}
            </MenuItem>
          ))}
        </Select>

        <Stack direction="row" justifyContent="center" spacing={2}>
          <Button color="neutral" variant="outlined" onClick={close}>
            <FormattedMessage id="common.cancel" defaultMessage="cancel" />
          </Button>

          <Button
            variant="outlined"
            onClick={submit}
            disabled={!argumentTypeId || argumentsMoveMutation.isLoading}
          >
            <FormattedMessage id="playbookEdit.argument.move.submit" />
          </Button>
        </Stack>

        <BasicErrorSnack
          open={errorSnackOpen}
          onClose={() => setErrorSnackOpen(false)}
        />
      </Stack>
    </Modal>
  )
}
