import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd"

import { IArgumentField } from "api/types/argumentFields"

import MenuList from "ds/MenuList"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentFieldUpdatePositionMutation } from "components/App/Playbook/queries/argumentFieldQueries"

import ArgumentFieldResult from "./ArgumentFieldResult"

interface Props {
  argumentFields: IArgumentField[]
  onClick: (argumentField: IArgumentField) => void
}

export default function ArgumentFieldResultsGroup({
  argumentFields,
  onClick,
}: Props) {
  const { playbook } = usePlaybook()
  const updatePositionMutation = useArgumentFieldUpdatePositionMutation(
    playbook.id
  )

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return
    }
    const argumentFieldId = result.draggableId
    const oldPosition = result.source.index
    const newPosition = result.destination.index
    if (oldPosition === newPosition) {
      return
    }
    const relativeArgumentField = argumentFields[newPosition]
    updatePositionMutation.mutate({
      id: argumentFieldId,
      relativeId: relativeArgumentField.id,
    })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={`argumentFields-${playbook.id}`}>
        {(providedDroppable) => (
          <MenuList
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
          >
            {argumentFields.map((argumentField, index) => (
              <ArgumentFieldResult
                key={argumentField.id}
                argumentField={argumentField}
                onClick={() => onClick(argumentField)}
                index={index}
              />
            ))}
            {providedDroppable.placeholder}
          </MenuList>
        )}
      </Droppable>
    </DragDropContext>
  )
}
