import RefreshIcon from "@mui/icons-material/Refresh"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import IconButton from "ds/IconButton"
import { AlertSnackbar, BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"
import Tooltip from "ds/Tooltip"

import { useBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"
import { useCrmPropertiesRefreshMutation } from "components/App/CRM/properties/crmPropertyQueries"

export default function CrmPropertyRefresh() {
  const intl = useIntl()

  const businessUnit = useBusinessUnit()
  const crmPropertiesRefreshMutation = useCrmPropertiesRefreshMutation(
    businessUnit.id
  )

  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)
  const [warningSnackOpen, setWarningSnackOpen] = useState(false)

  function refresh() {
    setWarningSnackOpen(true)
    crmPropertiesRefreshMutation.mutate(
      { businessUnitId: businessUnit.id },
      {
        onSuccess: () => {
          setWarningSnackOpen(false)
          setSuccessSnackOpen(true)
        },
        onError: () => {
          setWarningSnackOpen(false)
          setErrorSnackOpen(true)
        },
      }
    )
  }

  const label = intl.formatMessage({ id: "playbookEdit.crmProperties.refresh" })

  return (
    <>
      <Tooltip title={label}>
        <IconButton
          onClick={() => refresh()}
          disabled={crmPropertiesRefreshMutation.isLoading}
        >
          <RefreshIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <AlertSnackbar
        severity="warning"
        open={warningSnackOpen && !successSnackOpen && !errorSnackOpen}
        onClose={() => setWarningSnackOpen(false)}
      >
        <FormattedMessage id="playbookEdit.crmProperties.refreshStarted" />
      </AlertSnackbar>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
    </>
  )
}
