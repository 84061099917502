import { Box } from "@mui/material"
import styled from "styled-components"

import { IArgumentComment } from "api/types/argumentComments"

import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import Stack from "ds/Stack"

import Avatar from "components/common/Avatar"

import CommentActions from "./CommentActions"
import CommentBody from "./CommentBody"
import CommentCategoryChip from "./CommentCategoryChip"
import CommentInfo from "./CommentInfo"
import CommentReactions from "./CommentReactions"

interface ContainerProps {
  $opacityReduced: boolean
}

const CommentContainer = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: start;
  gap: ${({ theme }) => theme.spacing(2)};
  opacity: ${({ $opacityReduced }) => ($opacityReduced ? 0.65 : 1)};
  &:hover {
    .argument-comment-actions {
      opacity: 1;
    }
  }
`

interface Props {
  comment: IArgumentComment
  onEditClick: React.MouseEventHandler
  richTextProps: Partial<RichTextProps>
}

export default function ArgumentCommentDisplay({
  comment,
  onEditClick,
  richTextProps,
}: Props) {
  return (
    <CommentContainer $opacityReduced={!!comment.resolvedByUser}>
      <Avatar
        userName={comment.user.name}
        userEmail={comment.user.email}
        userAvatarUrl={comment.user.thumbnailAvatarUrl}
        userDefaultAvatarColorCode={comment.user.defaultAvatarColorCode}
      />

      <Box
        borderRadius={1}
        boxShadow={6}
        bgcolor={(theme) => theme.palette.common.white}
        flexGrow={1}
        px={1.5}
        py={1}
        minWidth={0}
      >
        <CommentInfo comment={comment} />
        <CommentBody comment={comment} richTextProps={richTextProps} />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <CommentReactions comment={comment} />
          {comment.category ? (
            <CommentCategoryChip category={comment.category} />
          ) : (
            <div />
          )}
        </Stack>
      </Box>

      <CommentActions comment={comment} onEditClick={onEditClick} />
    </CommentContainer>
  )
}
