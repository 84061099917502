import { useState } from "react"
import { FormattedMessage } from "react-intl"

import Alert from "ds/Alert"
import Modal from "ds/Modal"

export default function UnsuppotedOnMobile() {
  const [modalIsOpen, setModalIsOpen] = useState(true)

  return (
    <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
      <Alert severity="error">
        <FormattedMessage
          id="playbook.editMode.unavailableOnMobile"
          defaultMessage="SalesPath's editor mode is not yet available on mobile. You need to use SalesPath on a computer to edit your playbook"
        />
      </Alert>
    </Modal>
  )
}
