import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import { MyValue } from "ds/RichTextNew/types"
import Typography from "ds/Typography"

interface ArgumentDescriptionProps {
  description: MyValue
  rawDescription: string | null
  richText?: boolean
  richTextProps: Partial<RichTextProps>
}

export default function ArgumentDescription({
  description,
  rawDescription,
  richText = true,
  richTextProps,
}: ArgumentDescriptionProps) {
  return (
    <Typography component="div" variant="smNormal" minWidth={0}>
      {richText ? (
        <RichTextEditor
          {...richTextProps}
          value={description || buildNodeFromText("-")}
          readOnly
        />
      ) : (
        <div>{rawDescription}</div>
      )}
    </Typography>
  )
}
