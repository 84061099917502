import { Value, nanoid } from "@udecode/plate-common"

import { PlateCloudEditor } from "./types"

export const uploadFile = <V extends Value = Value>(
  editor: PlateCloudEditor<V>,
  file: File
) => {
  const id = `#${nanoid()}`
  editor.cloud.uploadFile(file, id, editor.cloud.genericFileHandlers!)
}

export const uploadFiles = <V extends Value = Value>(
  editor: PlateCloudEditor<V>,
  files: Iterable<File>
) => {
  for (const file of Array.from(files)) {
    uploadFile(editor, file)
  }
}
