import React, { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgumentField } from "api/types/argumentFields"

import Button from "ds/Button"
import Modal from "ds/Modal"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentFieldUpdateMutation } from "components/App/Playbook/queries/argumentFieldQueries"

interface Props {
  argumentField: IArgumentField
  onClose: () => void
}

export default function RenameArgumentField({ argumentField, onClose }: Props) {
  const [value, setValue] = useState(argumentField.name || "")
  const { playbook } = usePlaybook()
  const updateMutation = useArgumentFieldUpdateMutation(playbook.id)

  function submit(e: React.SyntheticEvent) {
    e.preventDefault()
    updateMutation.mutate(
      { id: argumentField.id, argumentField: { name: value } },
      { onSuccess: () => onClose() }
    )
  }

  return (
    <Modal isOpen onClose={onClose}>
      <form onSubmit={submit}>
        <Stack spacing={2}>
          <TextField
            id={`argumentField-name-${argumentField.id}`}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            label={
              <FormattedMessage
                id="playbookEdit.argumentFields.name"
                defaultMessage="Name"
              />
            }
            autoFocus
            onFocus={(e) => e.target.select()}
            onKeyDown={(e) => {
              // Hack to avoid wrapping components from catching keys,
              //  since we are in a nested modal
              if (e.key !== "Escape") {
                e.stopPropagation()
              }
            }}
          />

          <Button
            color="primary"
            type="submit"
            disabled={updateMutation.isLoading || value.length === 0}
          >
            <FormattedMessage id="common.submit" defaultMessage="Submit" />
          </Button>
        </Stack>
      </form>
    </Modal>
  )
}
