import { datadogRum } from "@datadog/browser-rum"

const applicationId = "fe84132e-9957-47f0-8593-5cd3b2074d08"
const clientToken = "pub8637390a6e7f6c18a3806f6dd5a592e8"
const service = "salespath-app"
const env = process.env.REACT_APP_ENVIRONMENT

if (env && env !== "development") {
  datadogRum.init({
    applicationId,
    clientToken,
    site: "datadoghq.eu",
    service,
    env,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "allow",
  })

  datadogRum.startSessionReplayRecording()
}
