import Qs from "qs"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IArgumentType } from "api/types/argumentTypes"
import { IArgument } from "api/types/arguments"
import { IPlaybook } from "api/types/playbooks"

import changeArrayPosition from "utils/changeArrayPosition"

import { filterNonArchived } from "./archivable"
import { filterArguments } from "./arguments"

export function getArgumentType(playbook: IPlaybook, argumentTypeId: string) {
  return playbook.argumentTypes.find(
    (argumentType) => argumentType.id === argumentTypeId
  )
}

// Validate the typeIdParam based on the available argumentTypes
export function ensureValidArgumentTypeId(
  argumentTypes: IArgumentType[],
  typeIdParam: Qs.ParsedQs["param"]
): string {
  const nonArchivedArgumentTypeIds = filterNonArchived(argumentTypes).map(
    (argumentType) => argumentType.id
  )

  if (typeof typeIdParam !== "string") return nonArchivedArgumentTypeIds[0]
  if (nonArchivedArgumentTypeIds.includes(typeIdParam)) return typeIdParam
  return nonArchivedArgumentTypeIds[0]
}

export function moveArgumentType(
  argumentTypes: IArgumentType[],
  argumentTypeId: string,
  relativeArgumentTypeId: string
) {
  const oldPosition = argumentTypes.findIndex((x) => x.id === argumentTypeId)
  let newPosition = argumentTypes.findIndex(
    (x) => x.id === relativeArgumentTypeId
  )
  return changeArrayPosition(argumentTypes, oldPosition, newPosition)
}

export function isReferencesArgumentType(argumentType: IArgumentType) {
  return argumentType.type === "REFERENCES"
}

export function filterArgumentTypesHavingArguments(playbook: IPlaybook) {
  return playbook.argumentTypes.filter(
    (argumentType) => argumentType.numberOfArguments > 0
  )
}

export default function noArgumentsInTab(
  _arguments: IArgument[],
  argumentSegmentations: IArgumentSegmentation[],
  showArchived?: boolean,
  typeId?: string
) {
  return (
    filterArguments(_arguments, argumentSegmentations, {
      showArchived,
      typeId,
    }).length === 0
  )
}
