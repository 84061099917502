import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import MenuItem from "ds/MenuItem"

import { argumentCardId } from "components/App/Playbook/Argument/ArgumentCard"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { usePasteArgumentsMutation } from "components/App/Playbook/queries/argumentQueries"

import { buildSegmentRulesFromSegmentFilters } from "services/argumentSegmentRules"
import { getLS, isArgumentLSItem } from "services/copyPaste"

import { scrollToElementWithDelay } from "utils/scrollToElement"

interface Props {
  argument: IArgument
  onSuccess: () => void
  onError: () => void
  onInconsistency: () => void
}

export default function PasteInArgument({
  argument,
  onSuccess,
  onError,
  onInconsistency,
}: Props) {
  const { activeTypeId, segmentFilters } = usePlaybook()
  const pasteArgumentsMutation = usePasteArgumentsMutation(activeTypeId)

  const LSItems = getLS()

  async function onClick() {
    if (!LSItems) {
      onInconsistency()
    } else if (LSItems.every((item) => isArgumentLSItem(item))) {
      const argumentIds = LSItems.map(({ id }) => id)
      pasteArgumentsMutation.mutate(
        {
          argumentIds,
          argumentGroupId: argument.argumentGroupId,
          argumentTypeId: activeTypeId,
          segmentRules: buildSegmentRulesFromSegmentFilters(segmentFilters),
          relativeArgumentId: argument.id,
        },
        {
          onSuccess: (_arguments) => {
            const firstArgument = _arguments[0]
            scrollToElementWithDelay(argumentCardId(firstArgument.id))
            onSuccess()
          },
          onError: () => onError(),
        }
      )
    } else {
      onInconsistency()
    }
  }

  return (
    <MenuItem onClick={onClick} disabled={pasteArgumentsMutation.isLoading}>
      <FormattedMessage id="playbookEdit.argument.paste" />
    </MenuItem>
  )
}
