import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import min from "lodash/min"

import { apiCreateLog } from "api/logs"

import IconButton from "ds/IconButton"

function onClick() {
  apiCreateLog({ action: "OPEN_POPIN", countsAsActivity: true })
  window.open(
    window.location.href,
    "",
    `width=550,height=${min([window.screen.height, 1000])})`
  )
}

export default function PopIn() {
  return (
    <IconButton onClick={onClick}>
      <OpenInNewIcon
        fontSize="small"
        sx={{ fill: (theme) => theme.palette.common.white }}
      />
    </IconButton>
  )
}
