import { Box } from "@mui/material"
import { partition } from "lodash"
import { FormattedMessage } from "react-intl"

import { IOrganizationMembership } from "api/types/organizationMemberships"

import ConnectionMethodLogo from "ds/ConnectionMethodLogo"
import Paper from "ds/Paper"
import Table from "ds/Table"
import TableBody from "ds/TableBody"
import TableCell from "ds/TableCell"
import TableContainer from "ds/TableContainer"
import TableHead from "ds/TableHead"
import TableRow from "ds/TableRow"

import CanEditPlaybooksForm from "./CanEditPlaybooksForm"
import OrganizationMembershipActions from "./OrganizationMembershipActions"
import OrganizationMembershipRoleForm from "./OrganizationMembershipRoleForm"

interface Props {
  organizationMemberships: IOrganizationMembership[]
}

export default function OrganizationMembershipsTable({
  organizationMemberships,
}: Props) {
  const [activeMemberships, deactivatedMemberships] = partition(
    organizationMemberships,
    (membership) => membership.active
  )
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 6 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="organizations.users.field.email"
                defaultMessage="Email"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="organizations.users.field.name"
                defaultMessage="Name"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage id="organizations.users.field.connectionMethod" />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="organizations.users.field.role"
                defaultMessage="Role"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="organizations.users.field.canEditPlaybooks"
                defaultMessage="Playbook edition rights"
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {activeMemberships.map((organizationMembership) => (
            <TableRow key={organizationMembership.id}>
              <TableCell>{organizationMembership.user.email}</TableCell>
              <TableCell>{organizationMembership.user.name}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    "> svg": {
                      display: "block",
                      width: "32px",
                      height: "32px",
                    },
                  }}
                >
                  <ConnectionMethodLogo
                    connectionMethod={
                      organizationMembership.user.connectionMethod
                    }
                  />
                </Box>
              </TableCell>
              <TableCell>
                <OrganizationMembershipRoleForm
                  organizationMembership={organizationMembership}
                />
              </TableCell>
              <TableCell>
                <CanEditPlaybooksForm
                  organizationMembership={organizationMembership}
                />
              </TableCell>
              <TableCell>
                <OrganizationMembershipActions
                  organizationMembership={organizationMembership}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableBody>
          {deactivatedMemberships.map((organizationMembership) => (
            <TableRow key={organizationMembership.id} sx={{ opacity: 0.5 }}>
              <TableCell>{organizationMembership.user.email}</TableCell>
              <TableCell>{organizationMembership.user.name}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    "> svg": {
                      display: "block",
                      width: "32px",
                      height: "32px",
                    },
                  }}
                >
                  <ConnectionMethodLogo
                    connectionMethod={
                      organizationMembership.user.connectionMethod
                    }
                  />
                </Box>
              </TableCell>
              <TableCell>
                <OrganizationMembershipRoleForm
                  organizationMembership={organizationMembership}
                />
              </TableCell>
              <TableCell>
                <CanEditPlaybooksForm
                  organizationMembership={organizationMembership}
                />
              </TableCell>
              <TableCell>
                <OrganizationMembershipActions
                  organizationMembership={organizationMembership}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
