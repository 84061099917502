import truncate from "lodash/truncate"

const initials = (name: string): string =>
  name
    .split(" ")
    .map((x) => (x[0] || "").toUpperCase())
    .join("")

export const firstNameAndInitial = (name: string): string => {
  const parts = name.split(" ")
  if (parts.length < 2) {
    return name || ""
  }
  return `${parts[0]} ${parts[1][0]}.`
}

export const abbreviatedEmail = (email: string): string => {
  const userName = email.split("@")[0]
  return truncate(userName, { length: 18 })
}

export default initials
