import UploadIcon from "@mui/icons-material/Upload"
import { Box } from "@mui/material"
import { DirectUpload } from "@rails/activestorage"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import styled from "styled-components"

import { directUploadUrl } from "api/activestorage"

import useMe, { useMeMutation } from "authentication/useMe"

import Button from "ds/Button"
import { AlertSnackbar, BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import Tooltip from "ds/Tooltip"
import Typography from "ds/Typography"

import Avatar from "components/common/Avatar"

import useLocalFileUrl from "utils/hooks/useLocalFileUrl"

const maxSizeBytes = 1024 * 1024 * 5

// Trick to have the input invisible and over the button so that we can drop files on it
const FileInput = styled.input`
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`

export default function EditImage() {
  const intl = useIntl()
  const user = useMe()
  const meMutation = useMeMutation()
  const [isEditing, setIsEditing] = useState(false)
  const [tmpImage, setTmpImage] = useState<File | null>(null)
  const tmpImageUrl = useLocalFileUrl(tmpImage)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [sizeLimitSnackOpen, setSizeLimitSnackOpen] = useState(false)

  const sizeLimit = intl.formatMessage({
    id: "profile.image.edit.size_limit",
    defaultMessage: "Maximum size: 5 Mb",
  })

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!tmpImage) return
    const upload = new DirectUpload(tmpImage, directUploadUrl)

    upload.create((err, blob) => {
      if (err) {
        setErrorSnackOpen(true)
      } else {
        meMutation.mutate(
          { avatar: blob.signed_id },
          {
            onSuccess: () => setIsEditing(false),
            onError: () => setErrorSnackOpen(true),
          }
        )
      }
      setTmpImage(null)
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target.files || [])[0]
    // Allow user to reload the same file
    e.target.value = ""
    if (!file) return

    if (file.size > maxSizeBytes) {
      return setSizeLimitSnackOpen(true)
    }
    return setTmpImage(file)
  }

  const avatar = (
    <Avatar
      userName={user.name}
      userEmail={user.email}
      userAvatarUrl={tmpImageUrl || user.smallAvatarUrl}
      userDefaultAvatarColorCode={user.defaultAvatarColorCode}
      sx={{ width: 150, height: 150 }}
    />
  )

  const snacks = (
    <>
      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <AlertSnackbar
        severity="error"
        open={sizeLimitSnackOpen}
        onClose={() => setSizeLimitSnackOpen(false)}
      >
        <Typography variant="baseSemi">{sizeLimit}</Typography>
      </AlertSnackbar>
    </>
  )

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="smSemi">
          <FormattedMessage id="profile.image.title" />
        </Typography>

        <Button
          onClick={() => setIsEditing(true)}
          disabled={isEditing}
          variant="text"
        >
          <FormattedMessage id="profile.image.edit" />
        </Button>
      </Stack>

      <Box alignSelf="center">
        {!isEditing ? (
          avatar
        ) : (
          <form onSubmit={onSubmit}>
            <Stack spacing={1} alignItems="center">
              {/* drop area */}
              <Stack
                spacing={1}
                alignItems="center"
                sx={{ position: "relative" }}
              >
                {avatar}

                <Tooltip title={sizeLimit} placement="top">
                  <Button
                    variant="contained"
                    size="large"
                    component="label"
                    sx={{ position: "static" }} // See FileInput
                    startIcon={<UploadIcon />}
                  >
                    <FormattedMessage
                      id="common.upload"
                      defaultMessage="Upload"
                    />
                    <FileInput
                      type="file"
                      accept="image/*"
                      onChange={handleChange}
                    />
                  </Button>
                </Tooltip>
              </Stack>

              <Typography variant="xsNormal">
                <FormattedMessage
                  id="profile.image.drop"
                  defaultMessage="or drop your image into the avatar"
                />
              </Typography>

              <Stack spacing={1} direction="row">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => setIsEditing(false)}
                >
                  <FormattedMessage
                    id="common.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>

                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  type="submit"
                  disabled={meMutation.isLoading || !tmpImage}
                >
                  <FormattedMessage
                    id="common.submit"
                    defaultMessage="Submit"
                  />
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Box>

      {snacks}
    </Stack>
  )
}
