import MuiTableRow, { TableRowProps } from "@mui/material/TableRow"
import { forwardRef } from "react"

export type { TableRowProps }

export default forwardRef(function TableRow(
  props: TableRowProps,
  ref: React.Ref<HTMLTableRowElement>
) {
  return <MuiTableRow {...props} ref={ref} />
})
