import { FormattedMessage } from "react-intl"

import useMe from "authentication/useMe"

import Section from "ds/Section"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import EditHandle from "./MyProfile/EditHandle"
import EditImage from "./MyProfile/EditImage"
import EditName from "./MyProfile/EditName"

export default function MyProfile() {
  const user = useMe()

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="lgSemi">
        <FormattedMessage id="navigation.profile" />
      </Typography>

      <Section>
        <EditImage />
      </Section>

      <Section>
        <EditName />
      </Section>

      <Section>
        <EditHandle />
      </Section>

      <Section>
        <Stack spacing={1}>
          <Typography variant="smSemi">
            <FormattedMessage id="profile.email" defaultMessage="Email" />
          </Typography>

          <Typography variant="smNormal">{user.email}</Typography>
        </Stack>
      </Section>
    </Stack>
  )
}
