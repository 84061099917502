import { selectEditor } from "@udecode/plate-utils"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { useMyEditorRef } from "ds/RichTextNew/types"

import { BCP47Lang, convertLocaleToBCP47 } from "./bcp47"

export const LANGUAGE_LS_KEY = "preferred-speech-to-text-language"

export const SpeechRecognition: undefined | typeof window.SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition

function buildSpeechRecognition(language: BCP47Lang) {
  if (!SpeechRecognition) return null
  const recognition = new SpeechRecognition()
  recognition.continuous = true
  recognition.interimResults = true
  recognition.lang = language
  return recognition
}

interface SpeechRecognitionRef {
  current: SpeechRecognition | null
}

export function useSpeechRecognition(lang: BCP47Lang | null) {
  const intl = useIntl()

  const recognitionRef = useState<SpeechRecognitionRef>(() => {
    const language = lang || convertLocaleToBCP47(intl.locale)
    return { current: buildSpeechRecognition(language) }
  })[0]

  // Cleanup: Force stop listening when component is unmounted
  useEffect(() => () => recognitionRef.current?.stop(), [recognitionRef])

  return recognitionRef
}

interface UseSpeechRecognitionStateProps {
  speechRecognitionRef: SpeechRecognitionRef
  onStartListening?: () => void
  onError?: () => void
}

export function useSpeechRecognitionState({
  speechRecognitionRef,
  onStartListening,
  onError,
}: UseSpeechRecognitionStateProps) {
  const editor = useMyEditorRef()
  const [results, setResults] = useState<SpeechRecognitionResult[]>([])
  const [isListening, setIsListening] = useState(false)

  function stopListening() {
    speechRecognitionRef.current?.stop()
    setIsListening(false)
  }

  function startListening() {
    if (!speechRecognitionRef.current) return
    setResults([])
    setIsListening(true)
    speechRecognitionRef.current.start()
    speechRecognitionRef.current.onend = () => stopListening() // Automatically called after inactivity
    speechRecognitionRef.current.onresult = (event) =>
      setResults(Array.from(event.results))
    speechRecognitionRef.current.onerror = onError || null
    onStartListening?.()
  }

  function insertResults() {
    const textToInsert = results.map((result) => result[0].transcript).join(" ")

    selectEditor(editor, {
      at: editor.selection || undefined,
      edge: editor.selection ? undefined : "end",
      focus: true,
    })
    editor.insertText(textToInsert)
  }

  return {
    startListening,
    stopListening,
    results,
    setResults,
    isListening,
    insertResults,
  }
}
