import { FormattedMessage } from "react-intl"

import { IArgumentCommentCategory } from "api/types/argumentComments"

interface Props {
  category: IArgumentCommentCategory
}

export default function FormattedCommentCategory({ category }: Props) {
  return (
    <FormattedMessage
      id={`argumentComments.category.option.${category}`}
      defaultMessage={category}
    />
  )
}
