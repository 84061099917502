import { useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import {
  IArgumentComment,
  IArgumentCommentCategory,
} from "api/types/argumentComments"

import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import { buildNodeFromText, resetNodes } from "ds/RichTextNew/helpers"
import { MyEditor, MyValue } from "ds/RichTextNew/types"

import isMobileDevice from "utils/isMobileDevice"

import CategorySelector from "./CategorySelector"
import CommentFormActions from "./CommentFormActions"
import CommentTextarea from "./CommentTextarea"
import NewCommentHint from "./NewCommentHint"

const emptyValue = buildNodeFromText("")

const CommentTextareaContainer = styled.div`
  position: relative;
`

interface Props {
  categorySelectorId: string
  resourceId: string
  onSubmit: (
    resourceId: string,
    value: MyValue,
    rawValue: string,
    category: string | null
  ) => Promise<void>
  isSubmitting: boolean
  onCancel?: () => void
  comment?: IArgumentComment
  richTextProps: Partial<RichTextProps>
  disabled?: boolean
}

export default function CommentForm({
  categorySelectorId,
  resourceId,
  onSubmit,
  isSubmitting,
  onCancel,
  comment,
  richTextProps,
  disabled = false,
}: Props) {
  const [value, setValue] = useState(comment?.value || emptyValue)
  const [rawValue, setRawValue] = useState<string>(comment?.rawValue || "")
  const [category, setCategory] = useState<IArgumentCommentCategory | null>(
    comment?.category || null
  )
  const editorRef = useRef<MyEditor | null>(null)
  const isMobile = isMobileDevice()

  function onChange(value: MyValue, rawValue: string) {
    setValue(value)
    setRawValue(rawValue)
  }

  // TODO lift the state up
  async function submit() {
    await onSubmit(resourceId, value, rawValue, category)
    setCategory(null)
    setValue(emptyValue)
    setRawValue("")
    if (editorRef.current) {
      resetNodes(editorRef.current, { nodes: [] })
    }
  }

  const cannotEdit = !!comment && !comment.canEdit
  const isTextDisabled = isSubmitting || cannotEdit || disabled

  const categoryLabel = (
    <FormattedMessage
      id="argumentComments.category.label"
      defaultMessage="Category"
    />
  )

  const categoryNullLabel = (
    <FormattedMessage
      id="argumentComments.category.none"
      defaultMessage="None"
    />
  )

  return (
    <div>
      <CategorySelector
        id={categorySelectorId}
        value={category}
        onChange={setCategory}
        label={categoryLabel}
        noBottomBorderRadius
        nullLabel={categoryNullLabel}
      />
      <CommentTextareaContainer>
        <CommentTextarea
          value={value}
          rawValue={rawValue}
          onChange={onChange}
          onSubmit={submit}
          disabled={isTextDisabled}
          richTextProps={richTextProps}
          editorRef={editorRef}
        />
        <CommentFormActions
          onSubmit={submit}
          onCancel={onCancel}
          submitDisabled={rawValue.length === 0 || isSubmitting || disabled}
        />
      </CommentTextareaContainer>
      {!comment && !isMobile && <NewCommentHint />}
    </div>
  )
}
