import { FormattedMessage } from "react-intl"

import MenuItem from "ds/MenuItem"

interface Props {
  onClick: () => void
}

export default function MoveArguments(props: Props) {
  return (
    <MenuItem onClick={props.onClick}>
      <FormattedMessage id="playbookEdit.argument.move" />
    </MenuItem>
  )
}
