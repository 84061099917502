import { useQuery } from "@tanstack/react-query"

import {
  apiArchiveOrganization,
  apiCreateOrganization,
  apiGetOrganization,
  apiGetOrganizations,
  apiRestoreOrganization,
  apiUpdateOrganization,
} from "api/organizations"

import { useInvalidatingMutation } from "utils/hooks/mutations"

export function buildOrganizationsNameKey(query: string) {
  return ["organizations", query]
}

export function buildOrganizationsKey() {
  return ["organizations"]
}

export function buildOrganizationKey(organizationId: string) {
  return ["organization", organizationId]
}

export function useOrganizationQuery(organizationId: string) {
  return useQuery(buildOrganizationKey(organizationId), ({ signal }) =>
    apiGetOrganization(organizationId, { signal })
  )
}

export function useOrganizationsQuery(name: string) {
  return useQuery(buildOrganizationsNameKey(name), ({ signal }) =>
    apiGetOrganizations({ name }, { signal })
  )
}

export function useOrganizationCreateMutation() {
  return useInvalidatingMutation(buildOrganizationsKey(), apiCreateOrganization)
}

export function useOrganizationUpdateMutation() {
  return useInvalidatingMutation(buildOrganizationsKey(), apiUpdateOrganization)
}

export function useOrganizationArchiveMutation() {
  return useInvalidatingMutation(
    buildOrganizationsKey(),
    apiArchiveOrganization
  )
}

export function useOrganizationRestoreMutation() {
  return useInvalidatingMutation(
    buildOrganizationsKey(),
    apiRestoreOrganization
  )
}
