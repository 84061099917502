import { IMeeting } from "api/types/meetings"

import useMe from "authentication/useMe"

import Stack from "ds/Stack"

import CopyMeetingNotes from "./MeetingActions/CopyMeetingNotes"
import RestartMeeting from "./MeetingActions/RestartMeeting"

interface Props {
  meeting: IMeeting
}

export default function MeetingActions({ meeting }: Props) {
  const user = useMe()
  const isMeetingOwner = meeting.user.id === user.id
  return (
    <Stack direction="row" spacing={2}>
      <CopyMeetingNotes meeting={meeting} />
      {isMeetingOwner && <RestartMeeting meeting={meeting} />}
    </Stack>
  )
}
