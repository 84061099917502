import { PlateElement, getHandler } from "@udecode/plate-common"
import { forwardRef } from "react"
import { useFocused, useSelected } from "slate-react"
import styled from "styled-components"

import { MyPlateElementProps } from "../types"

export default forwardRef<HTMLSpanElement, MyPlateElementProps>(
  function MentionInputElement({ onClick, ...props }, ref) {
    const { children, element } = props

    const selected = useSelected()
    const focused = useFocused()

    return (
      <StyledPlateElement
        $selected={selected}
        $focused={focused}
        ref={ref}
        asChild
        data-slate-value={element.value}
        // @ts-ignore This is copied from plate-ui mention-input-element.tsx, not sure why it doesn't pass TS
        onClick={getHandler(onClick, element)}
        {...props}
      >
        <span>{children}</span>
      </StyledPlateElement>
    )
  }
)

interface StyleProps {
  $selected: boolean
  $focused: boolean
}

const StyledPlateElement = styled(PlateElement)<StyleProps>`
  display: inline-block;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme, $selected, $focused }) =>
    $selected && $focused ? theme.palette.grey[200] : theme.palette.grey[200]};
  padding: 0 ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`
