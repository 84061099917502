import identity from "lodash/identity"

import { apiLinkMeetingToExternal } from "api/CRMCalls"

import { useUpdateMutation } from "utils/hooks/mutations"

import { buildCurrentMeetingKey } from "../Meetings/meetingQueries"

export const useCurrentMeetingLinkToExternalCallMutation = (
  playbookId: string
) =>
  useUpdateMutation(
    buildCurrentMeetingKey(playbookId),
    apiLinkMeetingToExternal,
    identity
  )
