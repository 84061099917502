import { useEffect, useState } from "react"

// Returns a URL for a local file
// See https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
const useLocalFileUrl = (file: Blob | null) => {
  const [url, setUrl] = useState<string | null>(null)

  useEffect(() => {
    const createdURL = file ? URL.createObjectURL(file) : null
    setUrl(createdURL)

    return () => {
      if (createdURL) URL.revokeObjectURL(createdURL)
    }
  }, [file])

  return url
}

export default useLocalFileUrl
