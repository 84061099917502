import styled from "styled-components"

import { IArgumentSearchResult } from "api/types/arguments"

import Stack from "ds/Stack"

import NoResults from "./NoResults"
import SearchResult from "./SearchResult"

const Container = styled.div`
  flex-grow: 1;
  min-height: 0;
  overflow-y: auto;
`

interface Props {
  results: IArgumentSearchResult[]
}

export default function SearchResults({ results }: Props) {
  if (results.length === 0) {
    return (
      <Container>
        <NoResults />
      </Container>
    )
  }

  return (
    <Container>
      <Stack spacing={2}>
        {results.map((result) => (
          <SearchResult key={result.argument.id} result={result} />
        ))}
      </Stack>
    </Container>
  )
}
