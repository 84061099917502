import {
  ELEMENT_PARAGRAPH,
  collapseSelection,
  findNode,
  focusEditor,
  isBlock,
  isCollapsed,
  toggleNodeType,
  useEditorRef,
  useEditorSelector,
} from "@udecode/plate"
import { Point } from "slate"

import { MyBlockElement, MyEditor, MyValue } from "./types"

export function useCurrentBlockType() {
  return useEditorSelector((editor) => {
    if (isCollapsed(editor.selection)) {
      const entry = findNode<MyBlockElement>(editor, {
        match: (n) => isBlock(editor, n),
      })

      if (entry) return entry[0].type
      return ELEMENT_PARAGRAPH
    }

    return ELEMENT_PARAGRAPH
  }, [])
}

export interface BlockToolbarButtonState {
  nodeType: string
  pressed: boolean
}

export function useBlockToolbarButtonState({
  nodeType,
}: {
  nodeType: string
}): BlockToolbarButtonState {
  const currentBlockType = useCurrentBlockType()
  return {
    nodeType,
    pressed: currentBlockType === nodeType,
  }
}

export function useBlockToolbarButton(state: BlockToolbarButtonState) {
  const editor = useEditorRef()
  return {
    props: {
      pressed: state.pressed,
      onMouseDown: (e: React.MouseEvent) => {
        e.preventDefault()
        toggleNodeType(editor, { activeType: state.nodeType })
        collapseSelection(editor)
        focusEditor(editor)
      },
    },
  }
}

export function buildNodeFromText(
  text: string,
  { bold }: { bold?: boolean } = {}
): MyValue {
  return [{ type: "p", children: [{ text, bold }] }]
}

/**
 * resetNodes resets the value of the editor.
 * It should be noted that passing the `at` parameter may cause a "Cannot resolve a DOM point from Slate point" error.
 */
export function resetNodes(
  editor: MyEditor,
  options: {
    nodes?: MyValue
    at?: Location
  } = {}
): void {
  const children = [...editor.children]

  children.forEach((node) =>
    editor.apply({ type: "remove_node", path: [0], node })
  )

  if (options.nodes) {
    options.nodes.forEach((node, i) =>
      editor.apply({ type: "insert_node", path: [i], node: node })
    )
  }

  const point =
    options.at && Point.isPoint(options.at) ? options.at : editor.end([])

  if (point) {
    editor.select(point)
  }
}
