import { Dictionary } from "lodash"

import { IBusinessUnit } from "api/types/businessUnits"

import isStringArrayDictionary from "utils/isStringArrayDictionary"

function buildLastVisitedPlaybookKey(businessUnitId: string) {
  return `lastVisitedPlaybook.businessUnit.${businessUnitId}`
}

function buildLastVisitedTypeKey(playbookId: string) {
  return `lastVisitedType.playbook.${playbookId}`
}

function buildLastAppliedSegmentsKey(playbookId: string) {
  return `lastApplied.playbook.${playbookId}`
}

function getLastVisitedPlaybookId(businessUnitId: string) {
  return localStorage.getItem(buildLastVisitedPlaybookKey(businessUnitId))
}

export function setLastVisitedPlaybookId(
  businessUnitId: string,
  playbookId: string
) {
  return localStorage.setItem(
    buildLastVisitedPlaybookKey(businessUnitId),
    playbookId
  )
}

export function getValidLastVisitedPlaybookId(businessUnit: IBusinessUnit) {
  const lastVisitedPlaybookId = getLastVisitedPlaybookId(businessUnit.id)
  if (!lastVisitedPlaybookId) return null

  if (businessUnit.playbooks.find(({ id }) => id === lastVisitedPlaybookId))
    return lastVisitedPlaybookId
  return null
}

export function getLastVisitedTypeId(playbookId: string) {
  return localStorage.getItem(buildLastVisitedTypeKey(playbookId))
}

export function setLastVisitedTypeId(playbookId: string, typeId: string) {
  return localStorage.setItem(buildLastVisitedTypeKey(playbookId), typeId || "")
}

export function getLastAppliedSegments(
  playbookId: string
): Dictionary<string[]> | null {
  const segmentsInLS = localStorage.getItem(
    buildLastAppliedSegmentsKey(playbookId)
  )
  if (!segmentsInLS) return null
  try {
    const parsedSegments = JSON.parse(segmentsInLS)
    if (isStringArrayDictionary(parsedSegments)) return parsedSegments
    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

export function setLastAppliedSegments(
  playbookId: string,
  appliedSegments: Dictionary<string[]>
) {
  return localStorage.setItem(
    buildLastAppliedSegmentsKey(playbookId),
    JSON.stringify(appliedSegments)
  )
}
