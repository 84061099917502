import { Box } from "@mui/material"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"

import { useIsSmallerThanMD, useIsSmallerThanSM } from "ds/Media"
import Stack from "ds/Stack"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import Header from "components/layouts/Header"

import EditModeToggle from "./Header/EditModeToggle"
import ImportPublicPlaybook from "./Header/ImportPublicPlaybook"
import PlaybookTitleMenu from "./Header/PlaybookTitleMenu"
import PopIn from "./Header/PopIn"
import Search from "./Header/Search"

export default function PlaybookHeader() {
  const { editMode, canEdit, playbook, segmentFilters, readOnly } =
    usePlaybook()
  const isSmallerThanMD = useIsSmallerThanMD()
  const isSmallerThanSM = useIsSmallerThanSM()

  return (
    <Header
      leftContent={
        <>
          {!editMode && !isSmallerThanMD && (
            <div>
              <PopIn />
            </div>
          )}
        </>
      }
      middle={
        <Stack direction="row" spacing={1.5} alignItems="center" minWidth={0}>
          <Box sx={{ minWidth: 0 }}>
            <PlaybookTitleMenu playbook={playbook} />
          </Box>

          {readOnly && <ImportPublicPlaybook playbook={playbook} />}

          <div>
            <Search
              playbook={playbook}
              initialSegmentFilters={segmentFilters}
            />
          </div>

          {canEdit && !readOnly && (!isSmallerThanSM || IS_CHROME_EXTENSION) && (
            <div>
              <EditModeToggle />
            </div>
          )}
        </Stack>
      }
    />
  )
}
