import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import { FormattedMessage } from "react-intl"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"

import { useConfig } from "components/Config"

export default function SuperAdminMenuItem() {
  const config = useConfig()
  return (
    <MenuItem
      component="a"
      href={`${config.appUrl}/super-admin`}
      target="_blank"
      rel="noreferrer"
    >
      <ListItemIcon>
        <AdminPanelSettingsIcon />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage
          id="navigation.superadmin"
          defaultMessage="Super Admin"
        />
      </ListItemText>
    </MenuItem>
  )
}
