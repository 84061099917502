import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IBusinessUnit } from "api/types/businessUnits"

import Button from "ds/Button"
import { BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

import { useBusinessUnitCreateMutation } from "../BusinessUnit/businessUnitQueries"

interface Props {
  organizationId: string
  onSuccess: (businessUnit: IBusinessUnit) => void
}

export default function CreateBusinessUnitForm({
  organizationId,
  onSuccess,
}: Props) {
  const [name, setName] = useState("")

  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const createMutation = useBusinessUnitCreateMutation(organizationId)

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    createMutation.mutate(
      { organizationId, name },
      {
        onSuccess: ({ businessUnit }) => onSuccess(businessUnit),
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <form onSubmit={submit}>
      <Stack spacing={2}>
        <TextField
          label={
            <FormattedMessage id="organizations.businessUnits.fields.name" />
          }
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <Button
          color="primary"
          type="submit"
          disabled={createMutation.isLoading || name.length === 0}
        >
          <FormattedMessage id="organizations.businessUnits.actions.form.submit" />
        </Button>
      </Stack>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </form>
  )
}
