import { getBusinessUnitPath } from "./businessUnits"

export function getPlaybookPath(
  businessUnitId: string,
  playbookId: string,
  typeId?: string
) {
  const params = typeId ? `?typeId=${typeId}` : ""
  return `${getBusinessUnitPath(
    businessUnitId
  )}/playbook/${playbookId}${params}`
}

export function getPlaybookEditPath(
  businessUnitId: string,
  playbookId: string
) {
  return `${getBusinessUnitPath(businessUnitId)}/playbook-edit/${playbookId}`
}

export function getPublicPlaybookPath(
  businessUnitId: string,
  playbookId: string
) {
  return `${getBusinessUnitPath(businessUnitId)}/public-playbook/${playbookId}`
}

export function getPlaybookEditMatrixPath(
  businessUnitId: string,
  playbookId: string
) {
  return `${getPlaybookEditPath(businessUnitId, playbookId)}/matrix`
}

export function getPlaybookEditViewPath(
  businessUnitId: string,
  playbookId: string,
  segmentsParam?: string
) {
  const params = segmentsParam ? `?${segmentsParam}` : ""
  return `${getPlaybookEditPath(businessUnitId, playbookId)}/view${params}`
}
