import { IArgumentComment } from "./argumentComments"
import { IMeeting } from "./meetings"
import { IUser } from "./users"

export enum NOTIFICATION_TYPE {
  argumentComment = "argument_comment",
  crmPropertiesPushFailure = "crm_properties_push_failure",
  argumentCommentResolved = "argument_comment_resolved",
}
interface INotificationCommon {
  id: string
  createdAt: string
  seenAt: string | null
  notificationType: NOTIFICATION_TYPE
  payload: object
}

export interface IArgumentCommentNotification extends INotificationCommon {
  notificationType: NOTIFICATION_TYPE.argumentComment
  argumentComment: IArgumentComment
}
export interface IArgumentCommentResolvedNotification
  extends INotificationCommon {
  notificationType: NOTIFICATION_TYPE.argumentCommentResolved
  argumentComment: IArgumentComment
  payload: {
    resolver: IUser
  }
}

export interface IMeetingNotification extends INotificationCommon {
  notificationType: NOTIFICATION_TYPE.crmPropertiesPushFailure
  meeting: IMeeting
}

export type INotification =
  | IArgumentCommentNotification
  | IArgumentCommentResolvedNotification
  | IMeetingNotification
