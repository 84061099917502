import styled from "styled-components"

const ShortcutContainer = styled.span`
  text-align: center;
  display: inline-flex;
  gap: 1ch;
  justify-content: center;
`

const KeyboardKey = styled.span`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) => theme.palette.grey["600"]};
  color: ${({ theme }) => theme.palette.grey["200"]};
  width: 3ch;
`

interface ShortcutProps {
  keys: string[] // that correspond to buttons
  keyStyle?: React.CSSProperties
}

export default function Shortcut({ keys, keyStyle = {} }: ShortcutProps) {
  return (
    <ShortcutContainer>
      {keys.map((key) => (
        <KeyboardKey key={key} style={keyStyle}>
          {key}
        </KeyboardKey>
      ))}
    </ShortcutContainer>
  )
}
