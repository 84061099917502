// MUI Autocomplete component with custom defaults:
//   - size "small"
//   - Input is a Textfield
//   - Custom loader
//   - Ensure the value is in the options (MUI constraint)
//   - Shallow comparison to compare option and value
//   - Prop `required`: Suffixes the input label with a red *
import ClearIcon from "@mui/icons-material/Clear"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { AutocompleteValue } from "@mui/material"
import MuiAutocomplete, {
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete"
import isEqual from "lodash/isEqual"
import { FormattedMessage } from "react-intl"

import Loader from "ds/Loader"
import { SuffixRequiredCue } from "ds/RequiredCue"
import TextField from "ds/TextField"

function ensureSingleValueInOptions<T>(
  options: readonly T[],
  value: T | null,
  isOptionEqualToValue: ComparisonFn<T>
) {
  if (!value) return options
  const valueIsInOptions = options.find((option) =>
    isOptionEqualToValue(option, value)
  )
  return valueIsInOptions ? options : [...options, value]
}

function ensureValuesInOptions<T>(
  options: readonly T[],
  values: T[],
  isOptionEqualToValue: ComparisonFn<T>
) {
  return values.reduce(
    (previousOptions, value) =>
      ensureSingleValueInOptions<T>(
        previousOptions,
        value,
        isOptionEqualToValue
      ),
    options
  )
}

function ensureValueInOptions<T>(
  options: readonly T[],
  value: T | T[] | null,
  isOptionEqualToValue: ComparisonFn<T>
) {
  if (!value) return options
  if (Array.isArray(value))
    return ensureValuesInOptions(options, value, isOptionEqualToValue)
  return ensureSingleValueInOptions<T>(options, value, isOptionEqualToValue)
}

type ComparisonFn<T> = (option: T, value: T) => boolean

export interface CustomAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "ref" | "value"
  > {
  inputLabel: React.ReactNode
  required?: boolean
  isOptionEqualToValue?: ComparisonFn<T>
  value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
}

export default function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  inputLabel,
  required = false,
  sx,
  value,
  freeSolo,
  multiple,
  options,
  renderInput = (params) => defaultRenderInput(params, inputLabel, required),
  isOptionEqualToValue = isEqual, // Shallow comparison by default
  ...props
}: CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  return (
    <MuiAutocomplete<T, Multiple, DisableClearable, FreeSolo>
      freeSolo={freeSolo}
      multiple={multiple}
      value={value}
      noOptionsText={<FormattedMessage id="autocomplete.noOptions" />}
      options={
        freeSolo
          ? options
          : ensureValueInOptions<T>(
              options,
              value as AutocompleteValue<T, Multiple, DisableClearable, false>,
              isOptionEqualToValue
            )
      }
      popupIcon={
        <KeyboardArrowDownIcon
          sx={{ width: "16px", fill: (theme) => theme.palette.text.primary }}
        />
      }
      clearIcon={
        <ClearIcon
          sx={{ width: "16px", fill: (theme) => theme.palette.text.primary }}
        />
      }
      sx={{
        minWidth: "200px",
        lineHeight: (theme) => theme.typography.smNormal.lineHeight,
        fontSize: (theme) => theme.typography.smNormal.fontSize,
        "& .MuiInputLabel-root": {
          lineHeight: (theme) => theme.typography.smNormal.lineHeight,
          fontSize: (theme) => theme.typography.smNormal.fontSize,
          transform: "translate(14px,6px) scale(1)",
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px,-9px) scale(0.857)",
          },
        },
        "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
          py: multiple ? 0.5 : 0,
          pr: 0,
          pl: 0.75,
        },
        "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input":
          {
            pl: 0.75,
            py: multiple ? 0.25 : 0.75,
          },
        "& .MuiChip-root": {
          height: "20px",
        },
        "& fieldset": {
          borderColor: (theme) => theme.palette.grey[300],
        },
        "& legend": {
          fontSize: (theme) => theme.typography.xsMed,
        },
        ...sx,
      }}
      size="small"
      openOnFocus
      renderInput={renderInput}
      loadingText={<Loader size="20px" />}
      isOptionEqualToValue={isOptionEqualToValue}
      autoHighlight
      componentsProps={{
        popper: {
          sx: {
            "& .MuiAutocomplete-noOptions, & .MuiAutocomplete-option": {
              lineHeight: (theme) => theme.typography.smNormal.lineHeight,
              fontSize: (theme) => theme.typography.smNormal.fontSize,
            },
          },
        },
      }}
      {...props}
    />
  )
}

function defaultRenderInput(
  params: AutocompleteRenderInputParams,
  inputLabel: React.ReactNode,
  required: boolean
) {
  return (
    <TextField
      {...params}
      label={
        inputLabel && (
          <SuffixRequiredCue showCue={required}>{inputLabel}</SuffixRequiredCue>
        )
      }
    />
  )
}
