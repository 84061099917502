import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useIntl } from "react-intl"

import IconButton from "ds/IconButton"

interface Props {
  isCollapsed: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default function CollapseCTA({ isCollapsed, onClick }: Props) {
  const intl = useIntl()

  return isCollapsed ? (
    <IconButton
      aria-label={intl.formatMessage({
        id: "playbookEdit.argumentSegmentations.segments.show",
        defaultMessage: "Show segments",
      })}
      onClick={onClick}
    >
      <ExpandMoreIcon fontSize="small" />
    </IconButton>
  ) : (
    <IconButton
      aria-label={intl.formatMessage({
        id: "playbookEdit.argumentSegmentations.segments.hide",
        defaultMessage: "Hide segments",
      })}
      onClick={onClick}
    >
      <ExpandLessIcon fontSize="small" />
    </IconButton>
  )
}
