import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import FormControlLabel from "ds/FormControlLabel"
import Stack from "ds/Stack"
import Switch from "ds/Switch"

import StaleWarningIcon from "components/App/Playbook/Argument/Staleness/StaleWarningIcon"

import { IViewSettings } from "./useViewSettings"

interface Props {
  viewSettings: IViewSettings
}

export default function StalenessViewSetting({ viewSettings }: Props) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={viewSettings.showStaleness}
          onChange={(e) => viewSettings.setShowStaleness(e.target.checked)}
        />
      }
      label={
        <Stack direction="row" alignItems="center" spacing={1}>
          <StaleWarningIcon fontSize="small" />

          <Box>
            <FormattedMessage
              id="playbook.viewSettings.showStaleness"
              defaultMessage="Show staleness"
            />
          </Box>
        </Stack>
      }
    />
  )
}
