import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDebounce } from "use-debounce"

import Breadcrumbs from "ds/Breadcrumbs"
import SearchInput from "ds/SearchInput"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import InviteUser from "../Organization/OrganizationMemberships/InviteUser"
import BusinessUnitInvitationsIndex from "./BusinessUnitInvitationsIndex"
import BusinessUnitMembershipsIndex from "./BusinessUnitMembershipsIndex"
import { useBusinessUnit } from "./BusinessUnitProvider"
import CreateBusinessUnitMembership from "./CreateBusinessUnitMembership"

const debounceWaitMs = 250

export default function BusinessUnitMemberships() {
  const [searchQuery, setSearchQuery] = useState("")
  const [debouncedSearchQuery] = useDebounce(searchQuery, debounceWaitMs)
  const businessUnit = useBusinessUnit()
  const intl = useIntl()

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Breadcrumbs>
        <Typography variant="smMed">{businessUnit.name}</Typography>
        <Typography variant="smMed">
          <FormattedMessage id="businessUnits.users.title" />
        </Typography>
      </Breadcrumbs>

      <Stack spacing={2} direction="row" alignSelf="flex-start">
        <Box flexGrow={1}>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={intl.formatMessage({
              id: "businessUnits.memberships.search.title",
            })}
            clear={() => setSearchQuery("")}
          />
        </Box>

        <CreateBusinessUnitMembership />

        <InviteUser />
      </Stack>

      <BusinessUnitMembershipsIndex query={debouncedSearchQuery} />

      <BusinessUnitInvitationsIndex query={debouncedSearchQuery} />
    </Stack>
  )
}
