import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import MuiSelect, { SelectProps as MuiSelectProps } from "@mui/material/Select"

import FormControl from "./FormControl"
import InputLabel, { InputLabelProps } from "./InputLabel"

export interface SelectProps<Value>
  extends Pick<
    MuiSelectProps<Value>,
    | "value"
    | "onChange"
    | "multiple"
    | "fullWidth"
    | "sx"
    | "labelId"
    | "id"
    | "label"
    | "children"
    | "renderValue"
    | "aria-label"
    | "displayEmpty"
    | "notched"
    | "disabled"
    | "open"
    | "onOpen"
    | "onClose"
  > {
  noBottomBorderRadius?: boolean
  error?: boolean
  inputLabelProps?: InputLabelProps
}

export default function Select<Value>(props: SelectProps<Value>) {
  return (
    <FormControl fullWidth={props.fullWidth}>
      {props.label && (
        <InputLabel
          sx={{
            lineHeight: (theme) => theme.typography.smNormal.lineHeight,
            fontSize: (theme) => theme.typography.smNormal.fontSize,
            transform: "translate(14px,6px) scale(1)",
            "&.MuiInputLabel-shrink": {
              transform: "translate(14px,-9px) scale(0.857)",
            },
            color: props.error
              ? (theme) => theme.palette.error.main
              : undefined,
            "&.Mui-focused": {
              color: props.error
                ? (theme) => theme.palette.error.main
                : undefined,
            },
          }}
          id={props.labelId}
          {...props.inputLabelProps}
        >
          {props.label}
        </InputLabel>
      )}

      <MuiSelect<Value>
        autoWidth
        value={props.value}
        onChange={props.onChange}
        multiple={props.multiple}
        fullWidth={props.fullWidth}
        size="small"
        disabled={props.disabled}
        MenuProps={{
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          transformOrigin: { horizontal: "left", vertical: "top" },
        }}
        labelId={props.labelId}
        id={props.id}
        label={props.label}
        aria-label={props["aria-label"]}
        renderValue={props.renderValue}
        displayEmpty={props.displayEmpty}
        IconComponent={KeyboardArrowDownIcon}
        open={props.open}
        onOpen={props.onOpen}
        onClose={props.onClose}
        notched={props.notched}
        sx={{
          lineHeight: (theme) => theme.typography.smNormal.lineHeight,
          fontSize: (theme) => theme.typography.smNormal.fontSize,
          backgroundColor: (theme) => theme.palette.common.white,
          ...(props.noBottomBorderRadius
            ? { borderBottomLeftRadius: 0, WebkitBorderBottomRightRadius: 0 }
            : {}),
          ...props.sx,
          "& .MuiInputBase-input": {
            pl: 1.5,
            py: 0.75,
            // @ts-ignore
            ...props.sx?.["& .MuiInputBase-input"],
          },
          "& .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: (theme) =>
                props.error
                  ? theme.palette.error.main
                  : theme.palette.grey[300],
              // @ts-ignore
              ...props.sx?.["& .MuiOutlinedInput-notchedOutline"],
            },
          "& legend": {
            fontSize: (theme) => theme.typography.xsMed,
          },
          "& .MuiSelect-icon": {
            width: "16px",
            fill: (theme) => theme.palette.text.primary,
            // @ts-ignore
            ...props.sx?.["& .MuiSelect-icon"],
          },
        }}
      >
        {props.children}
      </MuiSelect>
    </FormControl>
  )
}
