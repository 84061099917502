import { Box } from "@mui/material"

import { IUpcomingMeeting } from "api/types/meetings"

import FormattedTime from "ds/FormattedTime"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import IntegrationIcon from "components/common/IntegrationIcon"

function getOptionLabel(meeting: IUpcomingMeeting) {
  return meeting.title || "Meeting"
}

interface Props extends React.HTMLAttributes<HTMLLIElement> {
  crmMeeting: IUpcomingMeeting
}

export default function CRMMeetingItem({ crmMeeting, ...props }: Props) {
  return (
    <li {...props}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack
          direction="row"
          spacing={1}
          overflow="hidden"
          alignItems="center"
        >
          <IntegrationIcon integrationName={crmMeeting.integrationName} />

          <Box overflow="hidden" textOverflow="ellipsis">
            {getOptionLabel(crmMeeting)}
          </Box>
        </Stack>

        <Typography variant="xsNormal" flexShrink={0}>
          <FormattedTime value={crmMeeting.startTime} format="shortTime" />
        </Typography>
      </Stack>
    </li>
  )
}
