import axios from "./axios"
import {
  IArgumentCommentCreateParams,
  IArgumentCommentCreateResponse,
  IArgumentCommentReactionParams,
  IArgumentCommentReactionResponse,
  IArgumentCommentToggleResolveParams,
  IArgumentCommentUpdateParams,
  IArgumentCommentUpdateResponse,
  IArgumentCommentsCountParams,
  IArgumentCommentsCountResponse,
  IArgumentCommentsIndexParams,
  IArgumentCommentsIndexResponse,
} from "./types/argumentComments"

export const apiGetArgumentComments = (
  params: IArgumentCommentsIndexParams,
  { signal }: { signal?: AbortSignal } = {}
): Promise<IArgumentCommentsIndexResponse> =>
  axios.get("api/argument_comments", { params, signal }).then((res) => res.data)

export const apiCreateArgumentComment = (
  params: IArgumentCommentCreateParams
): Promise<IArgumentCommentCreateResponse> =>
  axios.post("api/argument_comments", params).then((res) => res.data)

export const apiUpdateArgumentComment = (
  params: IArgumentCommentUpdateParams
): Promise<IArgumentCommentUpdateResponse> =>
  axios
    .patch(`api/argument_comments/${params.id}`, params)
    .then((res) => res.data)

export const apiToggleResolveArgumentComment = (
  params: IArgumentCommentToggleResolveParams
): Promise<IArgumentCommentUpdateResponse> =>
  axios
    .patch(`api/argument_comments/${params.id}/toggle_resolve`, params)
    .then((res) => res.data)

export const apiDeleteArgumentComment = (id: string): Promise<undefined> =>
  axios.delete(`api/argument_comments/${id}`).then((res) => res.data)

export const apiGetArgumentCommentsCount = (
  params: IArgumentCommentsCountParams,
  { signal }: { signal?: AbortSignal } = {}
): Promise<IArgumentCommentsCountResponse> =>
  axios
    .get("api/argument_comments/count", { params, signal })
    .then((res) => res.data)

export const apiDeleteArgumentCommentReaction = (
  params: IArgumentCommentReactionParams
): Promise<IArgumentCommentReactionResponse> =>
  axios
    .delete(`api/argument_comment_reactions/remove`, { data: params })
    .then((res) => res.data)

export const apiAddArgumentCommentReaction = (
  params: IArgumentCommentReactionParams
): Promise<IArgumentCommentReactionResponse> =>
  axios.post(`api/argument_comment_reactions/`, params).then((res) => res.data)
