import { MutateFunction } from "@tanstack/react-query"

import {
  apiArgumentSegmentationLinkToCrmProperty,
  apiArgumentSegmentationLinkToMeetingNoteConfiguration,
  apiArgumentSegmentationUnlinkFromCrmProperty,
  apiArgumentSegmentationUnlinkFromMeetingNoteConfiguration,
  apiCreateArgumentSegmentation,
  apiDeleteArgumentSegmentation,
  apiRestoreArgumentSegmentation,
  apiUpdateArgumentSegmentation,
  apiUpdateArgumentSegmentationPosition,
} from "api/argumentSegmentations"
import {
  IArgumentSegmentation,
  IArgumentSegmentationResponse,
} from "api/types/argumentSegmentations"
import { IGetPlaybookResponse } from "api/types/playbooks"

import { moveArgumentSegmentation } from "services/argumentSegmentations"

import { useOptimisticMutation, useUpdateMutation } from "utils/hooks/mutations"
import { replaceById } from "utils/mutationHelpers"

import { buildPlaybookKey, setPlaybook } from "./playbookQueries"

export const setArgumentSegmentations = (
  prevData: IGetPlaybookResponse,
  updateFn: (
    prevArgumentSegmentations: IArgumentSegmentation[]
  ) => IArgumentSegmentation[]
) =>
  setPlaybook(prevData, (prevPlaybook) => ({
    ...prevPlaybook,
    argumentSegmentations: updateFn(prevPlaybook.argumentSegmentations),
  }))

const setArgumentSegmentation = (
  prevData: IGetPlaybookResponse,
  argumentSegmentation: IArgumentSegmentation
) =>
  setArgumentSegmentations(prevData, (prevArgumentSegmentations) =>
    replaceById(prevArgumentSegmentations, argumentSegmentation)
  )

const useArgumentSegmentationMutation = <TError, TVariables>(
  playbookId: string,
  mutationFn: MutateFunction<IArgumentSegmentationResponse, TError, TVariables>
) =>
  useUpdateMutation(
    buildPlaybookKey(playbookId),
    mutationFn,
    (result, prevData: IGetPlaybookResponse | undefined) =>
      prevData && setArgumentSegmentation(prevData, result.argumentSegmentation)
  )

export const useArgumentSegmentationUpdateMutation = (playbookId: string) =>
  useArgumentSegmentationMutation(playbookId, apiUpdateArgumentSegmentation)

export const useArgumentSegmentationDeleteMutation = (playbookId: string) =>
  useArgumentSegmentationMutation(playbookId, apiDeleteArgumentSegmentation)

export const useArgumentSegmentationRestoreMutation = (playbookId: string) =>
  useArgumentSegmentationMutation(playbookId, apiRestoreArgumentSegmentation)

export const useArgumentSegmentationCreateMutation = (playbookId: string) =>
  useUpdateMutation(
    buildPlaybookKey(playbookId),
    apiCreateArgumentSegmentation,
    (result, prevData: IGetPlaybookResponse | undefined) =>
      prevData &&
      setArgumentSegmentations(prevData, (prevArgumentSegmentations) => [
        ...prevArgumentSegmentations,
        result.argumentSegmentation,
      ])
  )

export const useArgumentSegmentationUpdatePositionMutation = (
  playbookId: string
) =>
  useOptimisticMutation(
    buildPlaybookKey(playbookId),
    apiUpdateArgumentSegmentationPosition,
    (prevData: IGetPlaybookResponse | undefined, { id, relativeId }) =>
      prevData &&
      setArgumentSegmentations(prevData, (prevArgumentSegmentations) =>
        moveArgumentSegmentation(prevArgumentSegmentations, id, relativeId)
      )
  )

export const useArgumentSegmentationLinkToCrmPropertyMutation = (
  playbookId: string
) =>
  useArgumentSegmentationMutation(
    playbookId,
    apiArgumentSegmentationLinkToCrmProperty
  )

export const useArgumentSegmentationLinkToMeetingNoteConfigurationMutation = (
  playbookId: string
) =>
  useArgumentSegmentationMutation(
    playbookId,
    apiArgumentSegmentationLinkToMeetingNoteConfiguration
  )

export const useArgumentSegmentationUnlinkFromCrmPropertyMutation = (
  playbookId: string
) =>
  useArgumentSegmentationMutation(
    playbookId,
    apiArgumentSegmentationUnlinkFromCrmProperty
  )

export const useArgumentSegmentationUnlinkFromMeetingNoteConfigurationMutation =
  (playbookId: string) =>
    useArgumentSegmentationMutation(
      playbookId,
      apiArgumentSegmentationUnlinkFromMeetingNoteConfiguration
    )
