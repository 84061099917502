import axios from "axios"
import { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"

import { IMeeting } from "api/types/meetings"

import Button from "ds/Button"
import { AlertSnackbar } from "ds/Snackbar"

import { getPlaybookPath } from "services/playbooks"

import { useMeetingRestartMutation } from "../../../meetingQueries"

interface Props {
  meeting: IMeeting
}

export default function RestartMeeting({ meeting }: Props) {
  const navigate = useNavigate()
  const meetingRestartMutation = useMeetingRestartMutation(meeting.playbookId)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const restart = () => {
    meetingRestartMutation.mutate(meeting.id, {
      onSuccess: () =>
        navigate(getPlaybookPath(meeting.businessUnitId, meeting.playbookId)),
      onError: (err) => {
        if (axios.isAxiosError(err)) {
          if (err.response?.data === "meeting_in_progress") {
            setErrorSnackOpen(true)
          }
        }
      },
    })
  }

  return (
    <>
      <Button
        onClick={restart}
        variant="outlined"
        disabled={meetingRestartMutation.isLoading}
      >
        <FormattedMessage
          id="meetings.restart"
          defaultMessage="Restart meeting"
        />
      </Button>

      <AlertSnackbar
        severity="error"
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      >
        <FormattedMessage id="meetings.onGoingMeetingInSamePlaybook" />
      </AlertSnackbar>
    </>
  )
}
