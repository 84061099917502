import { IArgumentComment } from "api/types/argumentComments"

import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import Stack from "ds/Stack"

import Comment from "./Comment"

interface Props {
  comments: IArgumentComment[]
  richTextProps: Partial<RichTextProps>
}

export default function Comments({ comments, richTextProps }: Props) {
  return (
    <Stack spacing={2} p={2}>
      {comments.map((comment) => (
        <Comment
          key={comment.id}
          comment={comment}
          richTextProps={richTextProps}
        />
      ))}
    </Stack>
  )
}
