import MuiAlertTitle, { AlertTitleProps } from "@mui/material/AlertTitle"
import { forwardRef } from "react"

export type { AlertTitleProps }

export default forwardRef(function AlertTitle(
  props: AlertTitleProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiAlertTitle {...props} ref={ref} />
})
