import { autoformatArrow } from "@udecode/plate-autoformat"

import { MyAutoformatRule } from "../../types"
import { autoformatBlocks } from "./autoformatBlocks"
import { autoformatIndentLists } from "./autoformatIndentLists"
import { autoformatLists } from "./autoformatLists"

export const autoformatRules: MyAutoformatRule[] = [
  ...autoformatBlocks,
  ...autoformatIndentLists,
  // ...autoformatMarks,
  // ...(autoformatSmartQuotes as MyAutoformatRule[]),
  // ...(autoformatPunctuation as MyAutoformatRule[]),
  // ...(autoformatLegal as MyAutoformatRule[]),
  // ...(autoformatLegalHtml as MyAutoformatRule[]),
  ...(autoformatArrow as MyAutoformatRule[]),
  ...autoformatLists,
  // ...(autoformatMath as MyAutoformatRule[]),
]
