import {
  IArgumentCommentCategory,
  argumentCommentCategories,
} from "api/types/argumentComments"

import MenuItem from "ds/MenuItem"
import Select from "ds/Select"

import FormattedCommentCategory from "./FormattedCommentCategory"

interface Props {
  id: string
  value: IArgumentCommentCategory | null
  onChange: (newValue: IArgumentCommentCategory | null) => void
  label: React.ReactNode
  nullLabel: React.ReactNode
  noBottomBorderRadius?: boolean
}

export default function CategorySelector({
  id,
  value,
  onChange,
  label,
  nullLabel,
  noBottomBorderRadius,
}: Props) {
  return (
    <Select
      fullWidth
      id={id}
      label={label}
      labelId={`label-${id}`}
      value={value || ""}
      onChange={(e) =>
        onChange(
          e.target.value === ""
            ? null
            : (e.target.value as IArgumentCommentCategory)
        )
      }
      noBottomBorderRadius={noBottomBorderRadius}
    >
      <MenuItem value="">{nullLabel}</MenuItem>

      {argumentCommentCategories.map((category) => (
        <MenuItem key={category} value={category}>
          <FormattedCommentCategory category={category} />
        </MenuItem>
      ))}
    </Select>
  )
}
