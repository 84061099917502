import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IOrganizationPendingInvitation } from "api/types/organizationInvitations"

import MenuItem from "ds/MenuItem"
import { BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"

import { useInviteRevokeMutation } from "./organizationInvitationQueries"

interface Props {
  invitation: IOrganizationPendingInvitation
  onRevoke: () => void
}

export default function RevokeInvitation({ invitation, onRevoke }: Props) {
  const revokeMutation = useInviteRevokeMutation(invitation.organizationId)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)

  const onClick = () =>
    revokeMutation.mutate(invitation.id, {
      onSuccess: () => {
        setSuccessSnackOpen(true)
        onRevoke()
      },
      onError: () => setErrorSnackOpen(true),
    })

  return (
    <>
      <MenuItem onClick={onClick} disabled={revokeMutation.isLoading}>
        <FormattedMessage
          id="organizations.users.invite.revoke"
          defaultMessage="Revoke"
        />
      </MenuItem>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
    </>
  )
}
