import { MutateFunction } from "@tanstack/react-query"

import {
  apiCreateArgumentSegment,
  apiDeleteArgumentSegment,
  apiUpdateArgumentSegment,
  apiUpdateArgumentSegmentPosition,
} from "api/argumentSegmentations"
import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import {
  IArgumentSegment,
  IArgumentSegmentResponse,
} from "api/types/argumentSegments"
import { IGetPlaybookResponse } from "api/types/playbooks"

import { moveArgumentSegment } from "services/argumentSegmentations"

import { useOptimisticMutation, useUpdateMutation } from "utils/hooks/mutations"
import { replaceById, updateById } from "utils/mutationHelpers"

import { setArgumentSegmentations } from "./argumentSegmentationQueries"
import { buildPlaybookKey } from "./playbookQueries"

const setArgumentSegmentation = (
  prevData: IGetPlaybookResponse,
  argumentSegmentationId: string,
  updateFn: (
    prevArgumentSegmentation: IArgumentSegmentation
  ) => IArgumentSegmentation
) =>
  setArgumentSegmentations(prevData, (prevArgumentSegmentations) =>
    updateById(prevArgumentSegmentations, argumentSegmentationId, updateFn)
  )

const setArgumentSegments = (
  prevData: IGetPlaybookResponse,
  argumentSegmentationId: string,
  updateFn: (prevArgumentSegments: IArgumentSegment[]) => IArgumentSegment[]
) =>
  setArgumentSegmentation(
    prevData,
    argumentSegmentationId,
    (prevArgumentSegmentation) => ({
      ...prevArgumentSegmentation,
      argumentSegments: updateFn(prevArgumentSegmentation.argumentSegments),
    })
  )

const setArgumentSegment = (
  prevData: IGetPlaybookResponse,
  argumentSegmentationId: string,
  argumentSegment: IArgumentSegment
) =>
  setArgumentSegments(
    prevData,
    argumentSegmentationId,
    (prevArgumentSegments) => replaceById(prevArgumentSegments, argumentSegment)
  )

const useArgumentSegmentMutation = <TError, TVariables>(
  playbookId: string,
  argumentSegmentationId: string,
  mutationFn: MutateFunction<IArgumentSegmentResponse, TError, TVariables>
) =>
  useUpdateMutation(
    buildPlaybookKey(playbookId),
    mutationFn,
    (result, prevData: IGetPlaybookResponse | undefined) =>
      prevData &&
      setArgumentSegment(
        prevData,
        argumentSegmentationId,
        result.argumentSegment
      )
  )

export const useArgumentSegmentUpdateMutation = (
  playbookId: string,
  argumentSegmentationId: string
) =>
  useArgumentSegmentMutation(
    playbookId,
    argumentSegmentationId,
    apiUpdateArgumentSegment
  )

export const useArgumentSegmentDeleteMutation = (
  playbookId: string,
  argumentSegmentationId: string
) =>
  useArgumentSegmentMutation(
    playbookId,
    argumentSegmentationId,
    apiDeleteArgumentSegment
  )

export const useArgumentSegmentCreateMutation = (
  playbookId: string,
  argumentSegmentationId: string
) =>
  useUpdateMutation(
    buildPlaybookKey(playbookId),
    apiCreateArgumentSegment,
    (result, prevData: IGetPlaybookResponse | undefined) =>
      prevData &&
      setArgumentSegments(
        prevData,
        argumentSegmentationId,
        (prevArgumentSegments) => [
          ...prevArgumentSegments,
          result.argumentSegment,
        ]
      )
  )

export const useArgumentSegmentUpdatePositionMutation = (
  playbookId: string,
  argumentSegmentationId: string
) =>
  useOptimisticMutation(
    buildPlaybookKey(playbookId),
    apiUpdateArgumentSegmentPosition,
    (prevData: IGetPlaybookResponse | undefined, { id, relativeId }) =>
      prevData &&
      setArgumentSegments(
        prevData,
        argumentSegmentationId,
        (prevArgumentSegments) =>
          moveArgumentSegment(prevArgumentSegments, id, relativeId)
      )
  )
