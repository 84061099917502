import { upperFirst } from "lodash"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"

import { IIntegration } from "api/types/integrations"

import ControlledError from "ds/ControlledError"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import {
  useApplicationIntegrationFindOrCreateQuery,
  useApplicationIntegrationStatusQueryPoller,
} from "components/App/Organization/ApplicationsIntegration/applicationIntegrationsQueries"
import IntegrationIcon from "components/common/IntegrationIcon"

import {
  isFailedIntegration,
  isIntegrationInProgress,
} from "services/applicationIntegration"
import { getManageIntegrationsBusinessUnitPath } from "services/businessUnits"

import useSearchParam from "utils/hooks/useSearchParam"

interface Props {
  integrationName: string
}

export default function AuthenticationPage({ integrationName }: Props) {
  const authorizationCode = useSearchParam("code")[0]?.toString() || ""
  // state should be received as "organizationid;businessunitid"
  const state = useSearchParam("state")[0]?.toString() || ""
  const [organizationId, businessUnitId, sandbox] = state.split(";")

  const {
    isLoading,
    isError,
    data: currentIntegration,
  } = useApplicationIntegrationFindOrCreateQuery(
    businessUnitId,
    integrationName,
    authorizationCode,
    sandbox === "true"
  )

  let status

  if (isLoading) {
    status = (
      <FormattedMessage
        id="settings.applicationsIntegration.aboutToStart"
        values={{ applicationName: upperFirst(integrationName) }}
      />
    )
  } else if (isError) {
    status = (
      <ControlledError>
        <FormattedMessage id="common.error" />
      </ControlledError>
    )
  } else {
    status = (
      <AuthenticationPoller
        currentIntegration={currentIntegration}
        integrationName={upperFirst(integrationName)}
        organizationId={organizationId}
        businessUnitId={businessUnitId}
      />
    )
  }

  return (
    <Stack
      height="100%"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      spacing={2}
    >
      <IntegrationIcon
        integrationName={integrationName}
        style={{ width: "100px" }}
      />
      <Typography variant="lgSemi">{status}</Typography>
    </Stack>
  )
}

function AuthenticationPoller({
  currentIntegration,
  integrationName,
  organizationId,
  businessUnitId,
}: {
  currentIntegration: IIntegration
  integrationName: string
  organizationId: string
  businessUnitId: string
}) {
  useApplicationIntegrationStatusQueryPoller(
    currentIntegration.id,
    businessUnitId
  )

  if (isIntegrationInProgress(currentIntegration)) {
    return (
      <FormattedMessage
        id="settings.applicationsIntegration.inProgress"
        values={{ applicationName: integrationName }}
      />
    )
  }

  if (isFailedIntegration(currentIntegration)) {
    return <FormattedMessage id="common.error" />
  }

  return (
    <>
      <FormattedMessage
        id="settings.applicationsIntegration.done"
        values={{ applicationName: integrationName }}
      />
      &nbsp;
      <Link
        to={getManageIntegrationsBusinessUnitPath(
          organizationId,
          businessUnitId
        )}
      >
        <FormattedMessage id="settings.applicationsIntegration.goToApp" />
      </Link>
    </>
  )
}
