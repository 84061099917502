import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import NotesIcon from "@mui/icons-material/Notes"
import { Collapse } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"

import Avatar from "ds/Avatar"
import { MyEditor } from "ds/RichTextNew/types"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UnstyledButton from "ds/UnstyledButton"

import CRMMeetingTypeSelector from "components/App/CRM/MeetingTypes/CRMMeetingTypeSelector"

import useLocalStorageState from "utils/hooks/useLocalStorageState"

import { useMeeting } from "./MeetingProvider"

interface Props {
  form: React.ReactNode
  editorRef: React.RefObject<MyEditor>
}

export default function MeetingGeneralNote({ editorRef, form }: Props) {
  const { ongoingMeeting, meetingType, handleMeetingTypeChange } = useMeeting()
  const [isExpanded, setIsExpended] = useLocalStorageState(
    "MeetingGeneralNoteExpanded",
    true
  )
  const intl = useIntl()

  if (!ongoingMeeting) return null

  const expandLabel = intl.formatMessage({
    id: "playbook.meeting.generalNote.expand",
  })

  return (
    <Stack spacing={2}>
      <UnstyledButton
        onClick={() => setIsExpended((prevState) => !prevState)}
        aria-label={expandLabel}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1.5}
        >
          <MeetingGeneralNoteHeader />

          {isExpanded ? (
            <ExpandLessIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )}
        </Stack>
      </UnstyledButton>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Stack spacing={1}>
          <CRMMeetingTypeSelector<false>
            value={meetingType}
            onChange={(_e, meetingType) => {
              handleMeetingTypeChange(meetingType, editorRef)
            }}
            businessUnitId={ongoingMeeting.businessUnitId}
            includeManageOption
          />

          {form}
        </Stack>
      </Collapse>
    </Stack>
  )
}

export function MeetingGeneralNoteHeader() {
  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.light }}>
        <NotesIcon fontSize="small" color="secondary" />
      </Avatar>

      <Typography variant="lgSemi">
        <FormattedMessage id="playbook.meeting.generalNote.form.title" />
      </Typography>
    </Stack>
  )
}
