import CloseIcon from "@mui/icons-material/Close"
import NotesIcon from "@mui/icons-material/Notes"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { useIntl } from "react-intl"

import { IMeeting } from "api/types/meetings"

import Fab from "ds/Fab"
import Modal from "ds/Modal"

import useLocalStorageState from "utils/hooks/useLocalStorageState"

import MeetingForm from "./MeetingForm"
import MeetingNotesExtensionPortal from "./MeetingNotesExtensionPortal"

interface Props {
  ongoingMeeting: IMeeting
}

const OPEN_LS_KEY = "salespath-meeting-notes-fab-open"

export default function MeetingNotesFab({ ongoingMeeting }: Props) {
  const intl = useIntl()
  const [open, setOpen] = useLocalStorageState(OPEN_LS_KEY, false)
  const meetingNoteLabel = intl.formatMessage({
    id: "playbook.meeting.notes",
    defaultMessage: "Notes",
  })
  const id = open ? `meeting-notes-${ongoingMeeting.id}` : undefined

  // In the web app on mobile, we put the meeting info and general note in a modal that takes up the whole screen.
  // In the Chrome Extension, we instead place a popper on the left of the extension panel
  return (
    <>
      <Fab
        color="primary"
        aria-label={meetingNoteLabel}
        variant="circular"
        onClick={() => setOpen((prev) => !prev)}
        aria-describedby={id}
      >
        {open ? <CloseIcon /> : <NotesIcon />}
      </Fab>

      {open && IS_CHROME_EXTENSION && (
        <MeetingNotesExtensionPortal>
          <MeetingForm />
        </MeetingNotesExtensionPortal>
      )}

      {open && !IS_CHROME_EXTENSION && (
        <Modal sizeVariant="big" isOpen onClose={() => setOpen(false)}>
          <MeetingForm />
        </Modal>
      )}
    </>
  )
}
