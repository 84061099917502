import {
  useToggleToolbarButton,
  useToggleToolbarButtonState,
} from "@udecode/plate"
import { useIntl } from "react-intl"

import { ReactComponent as CaretUp } from "images/caret-up.svg"

import { space } from "../../hotkeys"
import PillIconButton from "./PillIconButton"

interface Props {
  disabled: boolean
}

export default function ToggleButton({ disabled }: Props) {
  const intl = useIntl()

  const state = useToggleToolbarButtonState()
  const { props } = useToggleToolbarButton(state)

  return (
    <PillIconButton
      label={intl.formatMessage({ id: "richText.toggle" })}
      shortcut={[">", space]}
      disabled={disabled}
      {...props}
    >
      <CaretUp
        height={"8px"}
        width={"8px"}
        style={{ transform: "rotate(180deg)" }}
      />
    </PillIconButton>
  )
}
