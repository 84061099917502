import styled from "styled-components"

import { IUser } from "api/types/users"

import Avatar from "components/common/Avatar"

const UserMentionContainer = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export interface UserMentionProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  user: IUser
  showHandle?: boolean
}

export default function UserMention({
  user,
  showHandle = false,
  ...props
}: UserMentionProps) {
  return (
    <UserMentionContainer {...props}>
      <Avatar
        userName={user.name}
        userEmail={user.email}
        userAvatarUrl={user.thumbnailAvatarUrl}
        userDefaultAvatarColorCode={user.defaultAvatarColorCode}
        sx={{ width: 30, height: 30, fontSize: "1rem" }}
      />
      {showHandle && <span style={{ fontWeight: 500 }}>{user.handle}</span>}
      <span>{user.name || user.email}</span>
    </UserMentionContainer>
  )
}
