import GroupsIcon from "@mui/icons-material/Groups"
import { Box } from "@mui/material"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { groupBy, sortBy } from "lodash"
import { FormattedMessage } from "react-intl"

import { IBarePlaybook, IPlaybook } from "api/types/playbooks"

import useMe from "authentication/useMe"

import Divider from "ds/Divider"
import MenuItem from "ds/MenuItem"
import Select from "ds/Select"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import { UnstyledRouterLink } from "ds/UnstyledLink"

import { useBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"
import { useConfig } from "components/Config"

import { getManagePlaybooksBusinessUnitPath } from "services/businessUnits"
import { isOrganizationAdmin } from "services/organizationMemberships"
import { getPlaybookPath } from "services/playbooks"

import { useNavigablePlaybooksQuery } from "../../queries/playbookQueries"

interface Props {
  playbook: IPlaybook
}

interface Option {
  id: string
  name: string | null
  businessUnitId: string
  businessUnitName: string | null
}

function getOptions(
  currentPlaybook: IPlaybook,
  playbooks: IBarePlaybook[]
): Option[] {
  const options = playbooks.map((playbook) => ({
    id: playbook.id,
    name: playbook.name,
    businessUnitId: playbook.businessUnitId,
    businessUnitName: playbook.businessUnitName,
  }))

  const currentPlaybookIsInPlaybooks = !!options.find(
    (option) => option.id === currentPlaybook.id
  )

  if (currentPlaybookIsInPlaybooks) return playbooks
  return [
    {
      id: currentPlaybook.id,
      name: currentPlaybook.name,
      businessUnitId: currentPlaybook.businessUnitId,
      businessUnitName: currentPlaybook.businessUnitName,
    },
    ...options,
  ]
}

function getGroupedOptions(options: Option[]): [string, Option[]][] {
  const unSortedGroupedOptions = Object.entries(
    groupBy(options, (option) => option.businessUnitId)
  )
  return sortBy(unSortedGroupedOptions, ([_, group]) => {
    const businessUnitName = group[0].businessUnitName || ""
    return [businessUnitName.toLowerCase(), businessUnitName]
  }).map(([businessUnitId, group]) => [
    businessUnitId,
    sortBy(group, (option) => [
      (option.name || "").toLowerCase(),
      option.name || "",
    ]),
  ])
}

export default function PlaybookTitleMenu({ playbook }: Props) {
  const businessUnit = useBusinessUnit()
  const user = useMe()
  const config = useConfig()
  const { data: navigablePlaybooks } = useNavigablePlaybooksQuery()

  // Ensure current playbook is in the list
  const options = getOptions(playbook, navigablePlaybooks || [])

  const showManageOption = isOrganizationAdmin(
    user,
    businessUnit.organizationId
  )

  const groupedOptions = getGroupedOptions(options)
  const managePath = getManagePlaybooksBusinessUnitPath(
    businessUnit.organizationId,
    businessUnit.id
  )

  return (
    <Select
      fullWidth
      value={playbook.id}
      // TODO instead, define a variant in the DS component
      sx={{
        "&.MuiInputBase-root": {
          backgroundColor: (theme) => theme.palette.primary[400],
          color: (theme) => theme.palette.common.white,
        },
        "& .MuiSelect-icon": {
          fill: (theme) => theme.palette.common.white,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
    >
      {showManageOption && (
        <MenuItem
          key="manage-playbooks"
          value="manage-playbooks"
          component={IS_CHROME_EXTENSION ? "a" : UnstyledRouterLink}
          to={IS_CHROME_EXTENSION ? undefined : managePath}
          href={
            IS_CHROME_EXTENSION ? `${config.appUrl}${managePath}` : undefined
          }
          target={IS_CHROME_EXTENSION ? "_blank" : undefined}
        >
          <FormattedMessage id="playbook.toggle.manage" />
        </MenuItem>
      )}

      {groupedOptions.map(([_, optionsInGroup]) => [
        <Divider>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            maxWidth="200px"
          >
            <GroupsIcon fontSize="small" />
            <Typography
              variant="smSemi"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {optionsInGroup[0].businessUnitName}
            </Typography>
          </Stack>
        </Divider>,
        ...optionsInGroup.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            component={UnstyledRouterLink}
            to={getPlaybookPath(option.businessUnitId, option.id)}
          >
            <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {option.name}
            </Box>
          </MenuItem>
        )),
      ])}
    </Select>
  )
}
