import { useQuery } from "@tanstack/react-query"

import {
  apiCreateCRMMeetingType,
  apiDeleteCRMMeetingType,
  apiIndexCRMMeetingTypes,
  apiUpdateCRMMeetingType,
} from "api/CRMMeetingTypes"

import {
  useAddMutation,
  useInvalidatingMutation,
  useUpdateInArrayMutation,
} from "utils/hooks/mutations"
import { compareIds } from "utils/mutationHelpers"

function buildMeetingTypesKey(businessUnitId: string) {
  return ["meetingTypes", businessUnitId]
}

export const useMeetingTypesQuery = (
  businessUnitId: string,
  { enabled } = { enabled: true }
) =>
  useQuery(
    buildMeetingTypesKey(businessUnitId),
    ({ signal }) => apiIndexCRMMeetingTypes(businessUnitId, { signal }),
    { enabled }
  )

export const useMeetingTypeUpdateMutation = (businessUnitId: string) =>
  useUpdateInArrayMutation(
    buildMeetingTypesKey(businessUnitId),
    apiUpdateCRMMeetingType,
    (result) => result.crmMeetingType,
    compareIds
  )

export const useMeetingTypeCreateMutation = (businessUnitId: string) =>
  useAddMutation(
    buildMeetingTypesKey(businessUnitId),
    apiCreateCRMMeetingType,
    (result) => result.crmMeetingType
  )

export const useMeetingTypeDeleteMutation = (businessUnitId: string) =>
  useInvalidatingMutation(
    buildMeetingTypesKey(businessUnitId),
    apiDeleteCRMMeetingType
  )
