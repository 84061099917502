import { FormattedDate, useIntl } from "react-intl"

import { IMeetingArgumentNote } from "api/types/meetingArgumentNotes"

import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { buildNodeFromText } from "ds/RichTextNew/helpers"

interface Props {
  argumentNote: IMeetingArgumentNote
}

export default function ArgumentNoteDisplay({ argumentNote }: Props) {
  const intl = useIntl()
  const trueTranslation = intl.formatMessage({
    id: "common.yes",
    defaultMessage: "Yes",
  })

  const falseTranslation = intl.formatMessage({
    id: "common.no",
    defaultMessage: "No",
  })

  const checkedTranslation = intl.formatMessage({
    id: "playbook.meeting.argumentNotes.checked",
  })

  const notCheckedTranslation = intl.formatMessage({
    id: "playbook.meeting.argumentNotes.notChecked",
  })

  switch (argumentNote.fieldType) {
    case "free_text":
      return (
        <RichTextEditor
          value={argumentNote.value || buildNodeFromText("")}
          readOnly
        />
      )
    case "single_select":
      return <>{argumentNote.value?.label}</>
    case "multi_select":
      return <>{argumentNote.value.map((option) => option.label).join(", ")}</>
    case "boolean":
      return <>{argumentNote.value ? trueTranslation : falseTranslation}</>
    case "number":
      return <>{argumentNote.value}</>
    case "phone":
      return <>{argumentNote.value}</>
    case "email":
      return <>{argumentNote.value}</>
    case "url":
      return <>{argumentNote.value}</>
    case "date":
      return (
        <>
          {argumentNote.value && <FormattedDate value={argumentNote.value} />}
        </>
      )
    case "probability":
      return <>{argumentNote.value}</>
    case "checkbox":
      return (
        <>{argumentNote.value ? checkedTranslation : notCheckedTranslation}</>
      )
  }
}
