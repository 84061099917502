import { ICRMExternalProperty } from "./CRMProperties"

export interface ICRMCompany {
  id: string
  name: string
  website: string | null
  integrationName: string | null
  externalId: string | null
  properties: ICRMExternalProperty[]
}

interface ICRMCompanySearchResultCommon {
  id: string
  name: string
  website: string | null
  integrationName: string | null
}
interface ICRMInternalCompanySearchResult
  extends ICRMCompanySearchResultCommon {
  origin: "DB"
  crmCompany: ICRMCompany
}
interface ICRMExternalCompanySearchResult
  extends ICRMCompanySearchResultCommon {
  origin: "EXTERNAL_CRM"
}
export type ICRMCompanySearchResult =
  | ICRMInternalCompanySearchResult
  | ICRMExternalCompanySearchResult

export interface ICRMCompaniesSearchParams {
  businessUnitId: string
  name: string
  externalSearchDisabled: boolean
}

export interface ICRMCompanyCreateParams {
  name: string
  businessUnitId: string
}

export interface ICRMCompanyPullParams {
  businessUnitId: string
  externalIds: string[]
}

export interface ICRMCompanyResponse {
  crmCompany: ICRMCompany
}

export interface ICRMCompanyPullResponse {
  crmCompanies: ICRMCompany[]
}

export function isExternalCompanySearchResult(
  company: ICRMCompanySearchResult
): company is ICRMExternalCompanySearchResult {
  return company.origin === "EXTERNAL_CRM"
}
