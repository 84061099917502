import MuiBadge, { BadgeProps } from "@mui/material/Badge"
import { forwardRef } from "react"

export type { BadgeProps }

export default forwardRef(function Badge(
  props: BadgeProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiBadge {...props} ref={ref} />
})
