import { QueryClient, useQuery } from "@tanstack/react-query"

import {
  apiGetNavigablePlaybooks,
  apiGetPlaybook,
  apiGetPlaybookUsers,
} from "api/playbooks"
import { IGetPlaybookResponse, IPlaybook } from "api/types/playbooks"

export function buildPlaybookKey(playbookId: string) {
  return ["playbooks", playbookId]
}
function buildPlaybookUsersKey(playbookId: string) {
  return ["playbook", "users", playbookId]
}

export function buildNavigablePlaybooksKey() {
  return ["navigablePlaybooks"]
}

export function setPlaybook(
  prevData: IGetPlaybookResponse,
  updateFn: (playbook: IPlaybook) => IPlaybook
): IGetPlaybookResponse {
  return {
    ...prevData,
    playbook: updateFn(prevData.playbook),
  }
}

export const usePlaybookQuery = (playbookId: string) =>
  useQuery(buildPlaybookKey(playbookId), ({ signal }) =>
    apiGetPlaybook(playbookId, { signal })
  )

export const usePlaybookUsersQuery = (playbookId: string) =>
  useQuery(buildPlaybookUsersKey(playbookId), ({ signal }) =>
    apiGetPlaybookUsers(playbookId, { signal })
  )

export function useNavigablePlaybooksQuery() {
  return useQuery(buildNavigablePlaybooksKey(), apiGetNavigablePlaybooks)
}

export function invalidatePlaybookQuery(
  queryClient: QueryClient,
  playbookId: string
) {
  queryClient.invalidateQueries(buildPlaybookKey(playbookId))
}
