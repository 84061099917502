import { ICurrentUserBusinessUnitMembership } from "./businessUnitMemberships"
import { ICurrentUserOrganizationMembership } from "./organizationMemberships"

export const emailNotificationPreferenceOptions = [
  "every_comment",
  "daily_summary_and_mentions",
  "daily_summary",
  "no_email",
] as const

export type EmailNotificationPreference =
  typeof emailNotificationPreferenceOptions[number]

export interface ICurrentUser {
  id: string
  name: string
  handle: string
  email: string
  thumbnailAvatarUrl: string | null
  smallAvatarUrl: string | null
  defaultAvatarColorCode: string
  emailNotificationPreference: string
  organizationMemberships: ICurrentUserOrganizationMembership[]
  businessUnitMemberships: ICurrentUserBusinessUnitMembership[]
  isSuperAdmin: boolean
  prefersUniqueArgumentGroupOpen: boolean
}

export interface ICurrentUserResponse {
  user: ICurrentUser
}

export interface ICurrentUserUpdateResponse {
  user: ICurrentUser
}

export interface ICurrentUserUpdateParams {
  handle?: string
  avatar?: string
  name?: string
  emailNotificationPreference?: string
  prefersUniqueArgumentGroupOpen?: boolean
}
