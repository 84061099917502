import AddIcon from "@mui/icons-material/Add"
import { FormattedMessage } from "react-intl"

import { IArgumentSegment } from "api/types/argumentSegments"

import Button from "ds/Button"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentSegmentCreateMutation } from "components/App/Playbook/queries/argumentSegmentQueries"

interface Props {
  argumentSegmentationId: string
  onAddArgumentSegment: (argumentSegment: IArgumentSegment) => void
}

export default function AddArgumentSegment({
  argumentSegmentationId,
  onAddArgumentSegment,
}: Props) {
  const { playbook } = usePlaybook()
  const createMutation = useArgumentSegmentCreateMutation(
    playbook.id,
    argumentSegmentationId
  )

  const onClick = () =>
    createMutation.mutate(argumentSegmentationId, {
      onSuccess: ({ argumentSegment }) => onAddArgumentSegment(argumentSegment),
    })

  return (
    <Button
      startIcon={<AddIcon />}
      onClick={onClick}
      disabled={createMutation.isLoading}
      size="small"
      variant="outlined"
    >
      <FormattedMessage
        id="playbookEdit.argumentSegmentations.segments.add"
        defaultMessage="Add segment"
      />
    </Button>
  )
}
