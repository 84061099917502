// Meant to be used when the user encounters an expected error
import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import LogOutButton from "authentication/LogOutButton"

import Alert from "ds/Alert"
import Button from "ds/Button"

import CenteringContainer from "./CenteringContainer"

interface ControlledErrorProps {
  children: React.ReactNode
  showNav?: boolean
}

export default function ControlledError({
  children,
  showNav = false,
}: ControlledErrorProps) {
  return (
    <Box sx={{ padding: 4, display: "flex", flexDirection: "column", gap: 4 }}>
      <Alert severity="error">{children}</Alert>
      {showNav && (
        <CenteringContainer>
          <Button color="primary" href="/">
            <FormattedMessage id="common.back" defaultMessage="Back" />
          </Button>
          <LogOutButton />
        </CenteringContainer>
      )}
    </Box>
  )
}
