import CloudAttachment from "../components/CloudAttachment"
import { MyEditor, MyValue } from "../types"
import {
  CloudAttachmentPlugin,
  CloudPlugin,
  ELEMENT_CLOUD_ATTACHMENT,
  createCloudAttachmentPlugin,
  createCloudPlugin,
} from "./cloud/index"
import uploadFileDirectly from "./cloudPlugin/uploadFileDirectly"

export const createMyCloudPlugin = (uploadsHost: string) => {
  return createCloudPlugin<CloudPlugin, MyValue, MyEditor>({
    options: {
      uploadFile: (file, id, genericFileHandlers) =>
        uploadFileDirectly(file, id, genericFileHandlers, uploadsHost),
    },
  })
}

export const cloudAttachmentPlugin = createCloudAttachmentPlugin<
  CloudAttachmentPlugin,
  MyValue,
  MyEditor
>()

export const cloudComponents = {
  [ELEMENT_CLOUD_ATTACHMENT]: CloudAttachment,
}
