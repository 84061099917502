import { FormattedMessage } from "react-intl"

import { IArgumentField } from "api/types/argumentFields"

import FormControl from "ds/FormControl"
import InputLabel from "ds/InputLabel"
import OutlinedInput from "ds/OutlinedInput"
import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import { enter, mod } from "ds/RichTextNew/hotkeys"
import { MyValue } from "ds/RichTextNew/types"
import Shortcut from "ds/Shortcut"
import Typography from "ds/Typography"

import isMobileDevice from "utils/isMobileDevice"

import ArgumentFieldName from "./ArgumentFieldName"

function SubmitShortcut() {
  return (
    <Typography
      variant="xsNormal"
      component="label"
      textAlign="right"
      paddingTop={0.5}
    >
      <FormattedMessage
        id="richtext.quick_submit"
        defaultMessage="Press {shortcut} to submit"
        values={{
          shortcut: <Shortcut keys={[mod, enter]} />,
        }}
      />
    </Typography>
  )
}

interface Props {
  value: MyValue
  onChange: (newValue: MyValue, newRawValue: string) => void
  inputRef: React.MutableRefObject<HTMLInputElement | null>
  endAdornment?: React.ReactNode
  argumentField: IArgumentField
  id: string
  autoFocus?: boolean
  onKeyDown: React.KeyboardEventHandler
  richTextProps: Partial<RichTextProps>
}

export default function ArgumentFieldValueInput({
  value,
  onChange,
  inputRef,
  endAdornment,
  argumentField,
  id,
  autoFocus = false,
  onKeyDown,
  richTextProps,
}: Props) {
  const label = <ArgumentFieldName argumentField={argumentField} />
  const isMobile = isMobileDevice()

  return (
    <FormControl>
      <InputLabel shrink htmlFor={id}>
        {label}
      </InputLabel>

      <OutlinedInput
        ref={inputRef}
        // @ts-ignore // TODO RichText typing
        inputComponent={RichTextEditor}
        id={id}
        value={value}
        // @ts-ignore // TODO RichText typing
        onChange={onChange}
        // @ts-ignore // TODO RichText typing
        inputProps={{
          ...richTextProps,
          autoFocus,
          onKeyDown,
        }}
        endAdornment={endAdornment}
        label={label}
        notched
        sx={{
          "& .MuiOutlinedInput-input": {
            p: 1,
            pb: 0,
            flex: 1,
            height: "auto",
          },
        }}
      />
      {!isMobile && <SubmitShortcut />}
    </FormControl>
  )
}
