import styled from "styled-components"

import { IArgumentCommentReaction } from "api/types/argumentComments"

import useMe from "authentication/useMe"

import { getEmojiById } from "ds/EmojiPicker"
import IconButton from "ds/IconButton"
import Tooltip from "ds/Tooltip"
import Typography from "ds/Typography"

import ReactionTooltip from "./ReactionTooltip"

export const ReactionButtonHeight = 20

const StyledButton = styled(IconButton)`
  cursor: pointer;
  min-width: 40px;
  height: ${ReactionButtonHeight}px;
  padding: 0;
  border-radius: 15px;
  outline: none !important;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.palette.grey["200"]};
  display: flex;
  align-items: center;

  // typgoraphy
  .MuiTypography-root {
    padding: 0 ${({ theme }) => theme.spacing(0.5)};

    // emoji
    span:nth-child(1) {
      font-size: 1.25em;
    }

    // count
    span:nth-child(2) {
      padding-left: ${({ theme }) => theme.spacing(0.5)};
      flex: 1;
      text-align: center;
    }
  }

  // states

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.palette.background.paper2};
    border-color: ${({ theme }) => theme.palette.text.primary};
  }

  &.alreadyReacted,
  :active {
    background-color: #2980b93f;
    border-color: #2980b9;
    color: #2980b9;
    font-weight: bold;
  }

  transform-origin: center;
  :active {
    transform: scale(0.95);
  }
` as typeof IconButton

interface Props {
  reaction: IArgumentCommentReaction
  onToggle: (alreadyReact: boolean) => void
}

export default function ReactionButton({ reaction, onToggle }: Props) {
  const user = useMe()
  const { emojiName, reactorsIds } = reaction
  const emoji = getEmojiById(emojiName)
  const alreadyReacted = !!reaction.reactorsIds.find(
    (reactorId) => reactorId === user.id
  )

  return (
    <Tooltip title={<ReactionTooltip reaction={reaction} />}>
      <StyledButton
        className={alreadyReacted ? "alreadyReacted" : ""}
        onClick={() => onToggle(alreadyReacted)}
      >
        <Typography variant="xsNormal" alignItems="center">
          <span>{emoji}</span>
          <span>{reactorsIds.length}</span>
        </Typography>
      </StyledButton>
    </Tooltip>
  )
}
