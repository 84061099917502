import { MuiTelInputInfo } from "mui-tel-input"
import { useIntl } from "react-intl"

import { IMeetingArgumentNotePhone } from "api/types/meetingArgumentNotes"

import { PhoneInput } from "ds/PhoneInput"

interface Props {
  disabled?: boolean
  note: IMeetingArgumentNotePhone
  onChange: (value: string, info: MuiTelInputInfo) => void
}
export function PhoneForm({ disabled = false, note, onChange }: Props) {
  const intl = useIntl()
  return (
    <PhoneInput
      disabled={disabled}
      defaultCountry={"FR"}
      InputProps={{ sx: { input: { p: 1, pl: 0 } } }}
      value={note.value || ""}
      onChange={onChange}
      langOfCountryName={intl.locale}
    />
  )
}
