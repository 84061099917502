import isHotkey from "is-hotkey"
import { isEqual } from "lodash"
import { useRef, useState } from "react"

import { IArgumentFieldValue } from "api/types/argumentFieldValues"
import { IArgumentField } from "api/types/argumentFields"
import { IArgument } from "api/types/arguments"

import { RichTextProps } from "ds/RichTextNew/RichTextProps"
import { eventTargetIsInDialog } from "ds/RichTextNew/components/DialogClassName"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import { MyValue } from "ds/RichTextNew/types"
import SubmitInputAdornment from "ds/SubmitInputAdornment"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentFieldValueUpdateMutation } from "components/App/Playbook/queries/argumentQueries"

import useConfirm from "utils/hooks/useConfirm"
import useOnClickOutside from "utils/hooks/useOnClickOutside"
import isMobileDevice from "utils/isMobileDevice"
import { onKeyDownEnter } from "utils/onKeyDown"

import ArgumentFieldValueDisplay from "./ArgumentFieldValueDisplay"
import ArgumentFieldValueInput from "./ArgumentFieldValueInput"

function getEmptyValue(fieldType: IArgumentField["type"]) {
  return buildNodeFromText("", { bold: fieldType === "TITLE" })
}

interface Props {
  argumentField: IArgumentField
  argumentFieldValue: IArgumentFieldValue
  argument: IArgument
  onDeleteField: () => void
  richTextProps: Partial<RichTextProps>
}

export default function EditableArgumentFieldValue({
  argumentField,
  argumentFieldValue,
  argument,
  onDeleteField,
  richTextProps,
}: Props) {
  const { activeTypeId } = usePlaybook()
  const updateMutation = useArgumentFieldValueUpdateMutation(activeTypeId)
  const emptyValue = getEmptyValue(argumentField.type)
  const initialValue = argumentFieldValue.value || emptyValue
  const [value, setValue] = useState(initialValue)
  const [rawValue, setRawValue] = useState(argumentFieldValue.rawValue || "")
  const [isEditing, setIsEditing] = useState(false)
  const confirm = useConfirm({ messageKey: "leaveWithoutSaving" })
  const isMobile = isMobileDevice()
  const inputRef = useRef<HTMLInputElement>(null)

  function onChange(value: MyValue, rawValue: string) {
    setValue(value)
    setRawValue(rawValue)
  }

  function cancel() {
    setIsEditing(false)
    setValue(argumentFieldValue.value || emptyValue)
    setRawValue(argumentFieldValue.rawValue || "")
  }

  function clear() {
    setIsEditing(false)
    setValue(emptyValue)
    setRawValue("")
  }

  function confirmCancel() {
    if (isEqual(value, initialValue) || confirm()) {
      cancel()
    }
  }

  const submit = () => {
    // Removing if empty
    updateMutation.mutate(
      {
        argumentId: argument.id,
        argumentFieldId: argumentField.id,
        value: rawValue === "" ? null : value,
        rawValue: rawValue === "" ? null : rawValue,
      },
      {
        onSuccess: () => setIsEditing(false),
      }
    )
  }

  useOnClickOutside(inputRef, (event) => {
    if (event.defaultPrevented) return
    if (eventTargetIsInDialog(event)) return
    submit()
  })

  if (isEditing) {
    return (
      <ArgumentFieldValueInput
        id={`argument-field-value-${argument.id}-${argumentField.id}`}
        inputRef={inputRef}
        argumentField={argumentField}
        value={value}
        onChange={onChange}
        richTextProps={richTextProps}
        endAdornment={
          <SubmitInputAdornment
            onSubmit={submit}
            onCancel={cancel}
            disabled={updateMutation.isLoading}
          />
        }
        onKeyDown={(event) => {
          if (isHotkey("esc", event)) {
            event.stopPropagation() // Don't close the modal
            confirmCancel()
          }

          if (!isMobile && isHotkey("mod+enter", event)) {
            submit()
          }
        }}
        autoFocus
      />
    )
  }

  return (
    <ArgumentFieldValueDisplay
      containerProps={{
        style: { cursor: "pointer" },
        role: "button",
        tabIndex: 0,
        onClick: () => setIsEditing(true),
        onKeyDown: onKeyDownEnter((e) => {
          e.preventDefault() // Don't input a new line
          setIsEditing(true)
        }),
        "aria-label": "Edit",
      }}
      argument={argument}
      argumentField={argumentField}
      argumentFieldValue={argumentFieldValue}
      onDeleteField={() => {
        onDeleteField()
        clear()
      }}
      richTextProps={richTextProps}
    />
  )
}
