import { HotkeyPlugin } from "@udecode/plate"
import {
  ELEMENT_PARAGRAPH,
  createParagraphPlugin,
} from "@udecode/plate-paragraph"

import Paragraph from "../components/Paragraph"
import { MyEditor, MyValue } from "../types"

export const paragraphPlugin = createParagraphPlugin<
  HotkeyPlugin,
  MyValue,
  MyEditor
>()

export const paragraphComponents = {
  [ELEMENT_PARAGRAPH]: Paragraph,
}
