import { FormattedMessage } from "react-intl"

import NavMenu from "ds/BasicNav/NavMenu"

import Header from "components/layouts/Header"
import SidebarLayout from "components/layouts/SidebarLayout"

import SuperAdminNav from "./SuperAdminNav"

interface Props {
  children: React.ReactNode
  tab: React.ReactNode
}

export default function SuperAdminLayout({ children, tab }: Props) {
  const superAdminNav = <SuperAdminNav />
  const navMenu = <NavMenu nav={superAdminNav} responsive />

  const title = (
    <>
      <FormattedMessage
        id="navigation.superadmin"
        defaultMessage="Super Admin"
      />
      {" / "}
      {tab}
    </>
  )

  const header = <Header title={title} leftContent={navMenu} />

  return (
    <SidebarLayout
      sidebar={superAdminNav}
      header={header}
      responsive
      contentProps={{ sx: { overflowY: "auto" } }}
    >
      {children}
    </SidebarLayout>
  )
}
