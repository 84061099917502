import { FormattedMessage } from "react-intl"
import { Navigate, Route, Routes, useParams } from "react-router-dom"

import ControlledError from "ds/ControlledError"
import Typography from "ds/Typography"

import BusinessUnitDashboard from "../BusinessUnit/BusinessUnitDashboard"
import BusinessUnitMemberships from "../BusinessUnit/BusinessUnitMemberships"
import BusinessUnitPlaybooks from "../BusinessUnit/BusinessUnitPlaybooks/BusinessUnitPlaybooks"
import BusinessUnitProvider from "../BusinessUnit/BusinessUnitProvider"
import BusinessUnitSettings from "../BusinessUnit/BusinessUnitSettings"
import MeetingTypesIndex from "../CRM/MeetingTypes/MeetingTypesIndex"
import MeetingsIndex from "../CRM/Meetings/MeetingsIndex"
import ApplicationsIntegrations from "./ApplicationsIntegration/ApplicationsIntegrations"
import { useOrganization } from "./OrganizationProvider"

export default function BusinessUnitManagement() {
  const params = useParams()
  const businessUnitId = params.businessUnitId!
  const organization = useOrganization()
  const businessUnit = organization.businessUnits.find(
    (businessUnit) => businessUnit.id === businessUnitId
  )

  if (!businessUnit) {
    return (
      <ControlledError>
        <Typography variant="baseNormal">
          <FormattedMessage id="businessUnits.incorrectBusinessUnit" />
        </Typography>
      </ControlledError>
    )
  }

  return (
    <BusinessUnitProvider key={businessUnit.id} businessUnit={businessUnit}>
      <Routes>
        <Route path="/settings/*" element={<BusinessUnitSettings />} />
        <Route path="/users/*" element={<BusinessUnitMemberships />} />
        <Route path="/dashboard/*" element={<BusinessUnitDashboard />} />
        <Route path="/meetings/*" element={<MeetingsIndex />} />
        <Route path="/meeting_types/*" element={<MeetingTypesIndex />} />
        <Route path="/playbooks/*" element={<BusinessUnitPlaybooks />} />
        <Route path="/integrations/*" element={<ApplicationsIntegrations />} />
        <Route path="*" element={<Navigate to="users" replace />} />
      </Routes>
    </BusinessUnitProvider>
  )
}
