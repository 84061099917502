import { Box } from "@mui/material"
import { PlateLeaf } from "@udecode/plate-common"

import { MyPlateLeafProps } from "../types"

export default function Bold({ children, ...props }: MyPlateLeafProps) {
  return (
    <PlateLeaf asChild {...props}>
      <Box
        component="span"
        sx={{ fontWeight: (theme) => theme.typography.smSemi.fontWeight }}
      >
        {children}
      </Box>
    </PlateLeaf>
  )
}
