import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"

interface Props {
  onClick: (event: React.MouseEvent | React.KeyboardEvent) => void
  nbArguments: number
}

export default function OtherBulkActionsButton(props: Props) {
  return (
    <Button
      onClick={props.onClick}
      variant="contained"
      endIcon={<ArrowDropDownIcon />}
    >
      <FormattedMessage
        id="playbookEdit.argument.bulkActions"
        values={{ nbArguments: props.nbArguments }}
      />
    </Button>
  )
}
