import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { IBarePlaybook } from "api/types/playbooks"

import Button from "ds/Button"
import DateTimePicker from "ds/DateTimePicker"
import FormattedTime, { shortTime } from "ds/FormattedTime"
import Loader from "ds/Loader"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import escapeFileName from "utils/escapeFileName"
import triggerSaveAs from "utils/triggerSaveAs"

import {
  useLatestPlaybookExportQuery,
  usePlaybookExportCreateMutation,
} from "./playbookExportQueries"

interface ExportPlaybookFormProps extends Props {
  latestExportAt: string | null
}

function ExportPlaybookForm({
  playbook,
  onExport,
  latestExportAt,
}: ExportPlaybookFormProps) {
  const [updatedSince, setUpdatedSince] = useState(latestExportAt)
  const intl = useIntl()
  const createMutation = usePlaybookExportCreateMutation(playbook.id)

  const download = () =>
    createMutation.mutate(
      {
        playbookId: playbook.id,
        updatedSince,
      },
      {
        onSuccess: (data) => {
          const formattedDate = new Intl.DateTimeFormat(
            intl.locale,
            shortTime
          ).format(new Date())
          const filename = `Salespath ${playbook.name} - ${formattedDate}`
          const escapedFileName = escapeFileName(filename)
          const blob = new Blob(["\ufeff", data.csvString]) // Set byte order mark for UTF-8 on CSV
          triggerSaveAs(blob, `${escapedFileName}.csv`)
          onExport()
        },
      }
    )

  const cta = (
    <FormattedMessage
      id="businessUnits.playbooks.actions.export"
      defaultMessage="Export as CSV"
    />
  )

  return (
    <Stack spacing={4}>
      <Typography variant="xsNormal">{cta}</Typography>

      <Stack component="label" spacing={1}>
        <div>
          <FormattedMessage
            id="businessUnits.playbookExports.exportSince"
            defaultMessage="Export all changes since"
          />
        </div>
        <DateTimePicker
          value={updatedSince}
          onChange={(newUpdatedSince) => setUpdatedSince(newUpdatedSince)}
          label={null}
        />
        <Typography variant="xsNormal" component="div">
          <FormattedMessage
            id="businessUnits.playbookExports.emptyHint"
            defaultMessage="💡Leave empty to export the whole playbook"
          />
        </Typography>

        {latestExportAt && (
          <Typography variant="xsNormal" component="div">
            <FormattedMessage
              id="businessUnits.playbookExports.latestExportHint"
              defaultMessage="💡The latest export was made on {latestExportAt}"
              values={{
                latestExportAt: (
                  <FormattedTime value={latestExportAt} format="shortTime" />
                ),
              }}
            />
          </Typography>
        )}
      </Stack>

      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={download}
      >
        {cta}
      </Button>
    </Stack>
  )
}

interface Props {
  playbook: IBarePlaybook
  onExport: () => void
}

export default function ExportPlaybookFormWithInitialUpdatedSince({
  playbook,
  onExport,
}: Props) {
  const { isLoading, isError, error, data } = useLatestPlaybookExportQuery(
    playbook.id
  )

  if (isLoading) return <Loader />
  if (isError) return <UncontrolledError error={error} />

  return (
    <ExportPlaybookForm
      playbook={playbook}
      onExport={onExport}
      latestExportAt={data.latestExportAt}
    />
  )
}
