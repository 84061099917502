import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import { IBusinessUnit } from "api/types/businessUnits"
import { ICurrentUserOrganizationMembership } from "api/types/organizationMemberships"
import { IOrganization } from "api/types/organizations"

import useMe from "authentication/useMe"

import MenuList from "ds/MenuList"
import Typography from "ds/Typography"

import { useUnsafeBusinessUnit } from "components/App/BusinessUnit/BusinessUnitProvider"
import { useUnsafeOrganization } from "components/App/Organization/OrganizationProvider"

import OrganizationItem from "./OrganizationItem"

function isSelected(
  organization: IOrganization | null,
  businessUnit: IBusinessUnit | null,
  organizationMembership: ICurrentUserOrganizationMembership
) {
  if (organization) {
    return organization.id === organizationMembership.organization.id
  } else if (businessUnit) {
    return (
      businessUnit.organizationId === organizationMembership.organization.id
    )
  }
  return false
}

export default function MenuItems() {
  const user = useMe()
  const organization = useUnsafeOrganization()
  const businessUnit = useUnsafeBusinessUnit()

  if (user.organizationMemberships.length === 0) return <Empty />

  return (
    <MenuList>
      {user.organizationMemberships
        .filter((x) => !x.organization.discarded)
        .map((organizationMembership) => (
          <OrganizationItem
            key={organizationMembership.id}
            organizationMembership={organizationMembership}
            selected={isSelected(
              organization,
              businessUnit,
              organizationMembership
            )}
          />
        ))}
    </MenuList>
  )
}

function Empty() {
  return (
    <Box sx={{ px: 1 }}>
      <Typography variant="xsNormal">
        <FormattedMessage id="organizationToggle.none" defaultMessage="None" />
      </Typography>
    </Box>
  )
}
