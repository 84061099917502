import { useIntl } from "react-intl"

import { ConnectionMethodType } from "api/types/users"

import { ReactComponent as Auth0Logo } from "images/auth0.svg"
import { ReactComponent as AzureLogo } from "images/azure.svg"
import { ReactComponent as GoogleLogo } from "images/google.svg"
import { ReactComponent as WindowsLiveLogo } from "images/windows_live.svg"

import Tooltip from "./Tooltip"

interface Props {
  connectionMethod: ConnectionMethodType
}

export default function ConnectionMethodLogo({ connectionMethod }: Props) {
  const intl = useIntl()
  switch (connectionMethod) {
    case "auth0":
      return (
        <Tooltip
          title={intl.formatMessage({
            id: "connectionMethodLogo.auth0",
          })}
        >
          <Auth0Logo />
        </Tooltip>
      )
    case "google-oauth2":
      return (
        <Tooltip
          title={intl.formatMessage({
            id: "connectionMethodLogo.google",
          })}
        >
          <GoogleLogo />
        </Tooltip>
      )
    case "waad":
      return (
        <Tooltip
          title={intl.formatMessage({
            id: "connectionMethodLogo.azure",
          })}
        >
          <AzureLogo />
        </Tooltip>
      )
    case "windowslive":
      return (
        <Tooltip
          title={intl.formatMessage({
            id: "connectionMethodLogo.windowsLive",
          })}
        >
          <WindowsLiveLogo />
        </Tooltip>
      )
  }
}
