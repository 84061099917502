import { SoftBreakPlugin, createSoftBreakPlugin } from "@udecode/plate-break"

import { MyEditor, MyValue } from "../types"

export const softBreakPlugin = createSoftBreakPlugin<
  SoftBreakPlugin,
  MyValue,
  MyEditor
>({
  options: {
    rules: [{ hotkey: "shift+enter" }],
  },
})
