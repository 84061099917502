import AddIcon from "@mui/icons-material/Add"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import { IArgumentField } from "api/types/argumentFields"
import { IArgument } from "api/types/arguments"

import Button from "ds/Button"
import Popover from "ds/Popover"

import useAnchorEl from "utils/hooks/useAnchorEl"

import ArgumentFieldCreatable from "./NewArgumentFieldValue/ArgumentFieldCreatable"

const Container = styled.div`
  display: flex;
  justify-content: center;
`

interface Props {
  argument: IArgument
  onAddField: (argumentField: IArgumentField) => void
}

export default function NewArgumentFieldValue({ argument, onAddField }: Props) {
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()

  const id = open ? `add-argument-field-value-${argument.id}` : undefined

  return (
    <>
      <Container>
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleClick}
          sx={{ whiteSpace: "nowrap" }}
        >
          <FormattedMessage
            id="playbookEdit.argument.argumentFields.additionalField"
            defaultMessage="Additional field"
          />
        </Button>
      </Container>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <ArgumentFieldCreatable
          onClose={handleClose}
          onAddField={(argumentField: IArgumentField) => {
            onAddField(argumentField)
            handleClose()
          }}
        />
      </Popover>
    </>
  )
}
