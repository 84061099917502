import LogoutIcon from "@mui/icons-material/Logout"
import { FormattedMessage } from "react-intl"

import { useCustomLogout } from "authentication/Provider"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"

export default function LogOutMenuItem() {
  const customLogout = useCustomLogout()
  return (
    <MenuItem onClick={() => customLogout()} sx={{ color: "error.main" }}>
      <ListItemIcon>
        <LogoutIcon color="error" />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="navigation.logout" defaultMessage="Log out" />
      </ListItemText>
    </MenuItem>
  )
}
