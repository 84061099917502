import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import MuiBreadcrumbs, { BreadcrumbsProps } from "@mui/material/Breadcrumbs"
import { forwardRef } from "react"

export type { BreadcrumbsProps }

export default forwardRef(function Breadcrumbs(
  props: BreadcrumbsProps,
  ref: React.Ref<HTMLHRElement>
) {
  return (
    <MuiBreadcrumbs
      {...props}
      color={props.color || "neutral"}
      separator={props.separator || <ChevronRightIcon />}
      ref={ref}
    />
  )
})
