import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import Button from "ds/Button"

import EditArgumentSegmentRulesModal from "components/App/Playbook/PlaybookEdit/ArgumentSegmentRules/EditArgumentSegmentRules"

interface Props {
  arguments: IArgument[]
  onSuccess: () => void
}

export default function BulkEditSegments({
  arguments: _arguments,
  onSuccess,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={() => setIsModalOpen(true)}
        sx={{ fontSize: "0.7rem" }}
      >
        <FormattedMessage
          id="playbookEdit.argumentSegmentations.bulkEditSegment"
          defaultMessage="Edit segments ({nbArguments})"
          values={{
            nbArguments: _arguments.length,
          }}
        />
      </Button>
      {isModalOpen && (
        <EditArgumentSegmentRulesModal
          close={() => setIsModalOpen(false)}
          arguments={_arguments}
          onSuccess={onSuccess}
        />
      )}
    </>
  )
}
