import { useState } from "react"
import { useSwipeable } from "react-swipeable"
import styled from "styled-components"

// how much time to transition from the swipe state to the base state
const transitionDuration = 200
// how much time until the box is where the user dragged it
const dragDuration = 50

interface SwipeContentProps {
  $deltaX: number
  $isSwiping: boolean
}

const SwipeContent = styled.div.attrs<SwipeContentProps>(({ $deltaX }) => ({
  style: { transform: `translateX(${$deltaX}px)` },
}))<SwipeContentProps>`
  touch-action: pan-y;
  transition-property: transform;
  transition-duration: ${({ $isSwiping }) =>
    $isSwiping ? dragDuration : transitionDuration}ms;
  transition-timing-function: ${({ $isSwiping }) =>
    $isSwiping ? "linear" : "ease-out"};
  transition-delay: 0s;
`

const SwipeContainer = styled.div`
  position: relative;
`

const config = {
  delta: 20,
  preventDefaultTouchmoveEvent: false,
  trackTouch: true,
  trackMouse: false,
  rotationAngle: 0,
}

// Wraps `children` inside a box that can be dragged horizontally
// Can display left/right area behind the dragged `children`
// pass an `onSwipe: (direction: "left" | "right") => void` to listen to swipes
// threshold is the minimum amount of displacement to be considered a swipe

interface SwipeableProps {
  children: React.ReactNode
  left: (deltaX: number, active: boolean) => React.ReactNode
  right: (deltaX: number, active: boolean) => React.ReactNode
  onSwipe: (activeArea: "right" | "left") => void
  threshold: number
}

export default function Swipeable({
  children,
  left,
  right,
  onSwipe,
  threshold,
}: SwipeableProps) {
  const [isSwiping, setIsSwiping] = useState(false)
  const [deltaX, setDeltaX] = useState(0)
  const [activeArea, setActiveArea] = useState<"left" | "right" | null>(null)

  const handlers = useSwipeable({
    onSwipeStart: (e) => {
      // ignore vertical swipes (they are not cancelable once they trigger scroll anyway)
      if (e.dir === "Left" || e.dir === "Right") {
        setIsSwiping(true)
        setDeltaX(0)
      }
    },
    onSwiping: (e) => {
      // ignore vertical swipes
      if (!isSwiping) return
      const deltaX = e.deltaX
      const nextActiveArea =
        deltaX >= threshold ? "left" : deltaX <= -threshold ? "right" : null

      setActiveArea(nextActiveArea)
      setDeltaX(deltaX)
    },
    onSwiped: async () => {
      if (!isSwiping) return
      setIsSwiping(false)
      setDeltaX(0)
      if (activeArea && onSwipe) {
        // wait for the animation to finish
        await new Promise((r) => setTimeout(r, transitionDuration))
        onSwipe(activeArea)
        setActiveArea(null)
      }
    },
    ...config,
  })

  return (
    <SwipeContainer>
      {left && left(deltaX, activeArea === "left")}
      {right && right(deltaX, activeArea === "right")}
      <SwipeContent {...handlers} $isSwiping={isSwiping} $deltaX={deltaX}>
        {children}
      </SwipeContent>
    </SwipeContainer>
  )
}
