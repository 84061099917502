import { useIntl } from "react-intl"

import Tooltip from "ds/Tooltip"

import StaleWarningIcon from "./StaleWarningIcon"

export default function StaleWarning() {
  const intl = useIntl()
  const label = intl.formatMessage({
    id: "arguments.staleState",
    defaultMessage: "This talking point has not been updated in 3 months.",
  })
  return (
    <Tooltip title={label}>
      <StaleWarningIcon aria-label={label} sx={{ fontSize: "12px" }} />
    </Tooltip>
  )
}
