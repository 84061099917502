import axios from "./axios"
import {
  IArgumentGroupCreateParams,
  IArgumentGroupPasteParams,
  IArgumentGroupPasteResponse,
  IArgumentGroupResponse,
  IArgumentGroupUpdateParams,
} from "./types/argumentGroups"

export const apiCreateArgumentGroup = (
  params: IArgumentGroupCreateParams
): Promise<IArgumentGroupResponse> =>
  axios.post(`api/argument_groups`, params).then((res) => res.data)

export const apiUpdateArgumentGroup = (
  params: IArgumentGroupUpdateParams
): Promise<IArgumentGroupResponse> =>
  axios
    .patch(`api/argument_groups/${params.id}`, params)
    .then((res) => res.data)

export const apiUpdateArgumentGroupPosition = ({
  id,
  relativeId,
}: {
  id: string
  relativeId: string
}): Promise<undefined> =>
  axios
    .patch(`api/argument_groups/${id}/update_position`, {
      relativeId,
    })
    .then((res) => res.data)

export const apiDeleteArgumentGroup = (id: string): Promise<undefined> =>
  axios.delete(`api/argument_groups/${id}`).then((res) => res.data)

export const apiPasteArgumentGroups = (
  params: IArgumentGroupPasteParams
): Promise<IArgumentGroupPasteResponse> =>
  axios.post("api/argument_groups/paste", params).then((res) => res.data)
