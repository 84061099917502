import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDebounce } from "use-debounce"

import { IOrganization } from "api/types/organizations"

import Breadcrumbs from "ds/Breadcrumbs"
import SearchInput from "ds/SearchInput"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import OrganizationInvitationsIndex from "../OrganizationInvitationsIndex"
import OrganizationMembershipsIndex from "../OrganizationMembershipsIndex"
import InviteUser from "./InviteUser"

interface Props {
  organization: IOrganization
}

const debounceWaitMs = 250

export default function OrganizationMemberships({ organization }: Props) {
  const intl = useIntl()
  const [searchQuery, setSearchQuery] = useState("")
  const [debouncedSearchQuery] = useDebounce(searchQuery, debounceWaitMs)

  return (
    <Stack spacing={2} p={2} maxWidth={900}>
      <Breadcrumbs>
        <Typography variant="smMed">{organization.name}</Typography>
        <Typography variant="smMed">
          <FormattedMessage id="organizations.users.title" />
        </Typography>
      </Breadcrumbs>

      <Stack spacing={2} direction="row" alignSelf="flex-start">
        <Box flexGrow={1}>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={intl.formatMessage({
              id: "organizations.memberships.search.title",
            })}
            clear={() => setSearchQuery("")}
          />
        </Box>

        <InviteUser />
      </Stack>

      <OrganizationMembershipsIndex query={debouncedSearchQuery} />

      <OrganizationInvitationsIndex query={debouncedSearchQuery} />
    </Stack>
  )
}
