import { useLocation } from "react-router-dom"
import styled from "styled-components"

import { IArgument } from "api/types/arguments"

import Badge from "ds/Badge"
import Typography from "ds/Typography"
import { UnstyledRouterLink } from "ds/UnstyledLink"

import DefaultTitle from "components/App/Playbook/Argument/DefaultTitle"
import StaleWarning from "components/App/Playbook/Argument/Staleness/StaleWarning"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { getRawTitle } from "services/argumentFields"
import { isArgumentStale } from "services/arguments"

import { replaceSearchParam } from "utils/hooks/useSearchParam"

import EditableArgumentKindIndicator from "../../Argument/ArgumentCard/ArgumentKindIndicator/EditableArgumentKindIndicator"

const Container = styled.div`
  position: sticky;
  left: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`

const KindIndicatorContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: ${({ theme }) => theme.spacing(2.5)};
`

interface ArgumentContainerProps {
  $opacityReduced: boolean
  component: React.ElementType // https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop
}

const ArgumentContainer = styled(Typography)<ArgumentContainerProps>`
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(4.5)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: white;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${({ $opacityReduced }) => ($opacityReduced ? 0.6 : 1)};
  border: solid 1px ${({ theme }) => theme.palette.grey[300]};
`

interface Props {
  argument: IArgument
}

export default function ArgumentCellContent({ argument }: Props) {
  const title = getRawTitle(argument)
  const location = useLocation()
  const { viewSettings } = usePlaybook()
  const showStaleCue = viewSettings.showStaleness && isArgumentStale(argument)

  const argumentModalLocation = replaceSearchParam({
    location,
    key: "argumentId",
    value: argument.id,
  })

  return (
    <Container>
      <UnstyledRouterLink
        to={argumentModalLocation}
        style={{ cursor: "pointer", width: "100%" }}
      >
        <Badge
          component="div"
          color="error"
          badgeContent={argument.notVisitedNotificationsCount}
          sx={{ width: "100%" }}
        >
          <ArgumentContainer
            variant="smNormal"
            component="div"
            title={title || ""}
            $opacityReduced={argument.archived}
            sx={{ width: "100%" }}
          >
            {showStaleCue && <StaleWarning />}
            &nbsp;
            <span>{title || <DefaultTitle />}</span>
          </ArgumentContainer>
        </Badge>
      </UnstyledRouterLink>

      <KindIndicatorContainer>
        <EditableArgumentKindIndicator argument={argument} />
      </KindIndicatorContainer>
    </Container>
  )
}
