import { Navigate, Route, Routes } from "react-router-dom"

import useMe from "authentication/useMe"

import { isOrganizationAdmin } from "services/organizationMemberships"

import BusinessUnitManagement from "./BusinessUnitManagament"
import OrganizationLayout from "./OrganizationLayout"
import OrganizationMemberships from "./OrganizationMemberships/OrganizationMemberships"
import { useOrganization } from "./OrganizationProvider"

export default function OrganizationManagement() {
  const user = useMe()
  const organization = useOrganization()
  if (!user.isSuperAdmin && !isOrganizationAdmin(user, organization.id)) {
    return <Navigate to="/" />
  }

  return (
    <OrganizationLayout organization={organization}>
      <Routes>
        <Route
          path="users/*"
          element={<OrganizationMemberships organization={organization} />}
        />
        <Route
          path="/bu/:businessUnitId/*"
          element={<BusinessUnitManagement />}
        />
        <Route path="*" element={<Navigate to="users" replace />} />
      </Routes>
    </OrganizationLayout>
  )
}
