// ⚠️ We are doing some CSS wizardry to have the first row and column sticky
//  while allowing drag and drop and absolute positioning of the floating actions
import { Draggable, DraggableProvided, Droppable } from "@hello-pangea/dnd"
import styled from "styled-components"

import { IArgumentGroup } from "api/types/argumentGroups"
import { IArgument } from "api/types/arguments"

import DragAndDropHandle from "ds/DragAndDropHandle"
import Stack from "ds/Stack"

import AddArgumentCTAs from "components/App/Playbook/PlaybookEdit/Argument/AddArgumentCTAs"

import { getArgumentGroupDOMId } from "../../getArgumentGroupDOMId"
import ArgumentGroupRow from "./ArgumentGroupRow"
import MatrixArgumentRow from "./MatrixArgumentRow"
import MatrixGrid from "./MatrixGrid"

const Container = styled.div`
  width: fit-content;
  border: solid 1px ${({ theme }) => theme.palette.grey[400]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: ${({ theme }) => theme.spacing(1)};
  position: relative; // To position the floating actions
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: ${({ theme }) => theme.shadows[6]};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};

  &:hover {
    .drag-handle {
      opacity: 1;
    }
  }
`

const DragHandleContainer = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.spacing(-4)};
  top: 0;
  opacity: 0.2;
  transition: ${({ theme }) => theme.transitions.create("opacity")};
`

const FloatingActionsStickyContainer = styled.div`
  position: sticky;
  left: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`

interface FloatingActionsContainerProps {
  $bottomOffset: boolean
}

const FloatingActionsContainer = styled.div<FloatingActionsContainerProps>`
  position: relative;
  bottom: ${({ theme, $bottomOffset }) =>
    $bottomOffset ? 0 : theme.spacing(1)};
  display: flex;
  justify-content: center;
`

interface MatrixArgumentGroupProps extends MatrixArgumentGroupWithDndProps {
  providedDraggable?: DraggableProvided
}

function MatrixArgumentGroup({
  argumentGroup,
  arguments: _arguments,
  providedDraggable,
  nameContainerRef,
  onBulkActionCheckboxClick,
  checkIfSelectedForBulkAction,
  nbColumns,
}: MatrixArgumentGroupProps) {
  const isGroupCheckboxSelectedForBulkAction =
    checkIfSelectedForBulkAction(_arguments)

  return (
    <Container
      id={getArgumentGroupDOMId(argumentGroup ? argumentGroup.id : null)}
      ref={providedDraggable?.innerRef}
      {...providedDraggable?.draggableProps}
    >
      {argumentGroup && (
        <ArgumentGroupRow
          argumentGroup={argumentGroup}
          arguments={_arguments}
          nbColumns={nbColumns}
          nameContainerRef={nameContainerRef}
          onBulkActionCheckboxClick={onBulkActionCheckboxClick}
          isSelectedForBulkAction={isGroupCheckboxSelectedForBulkAction}
        />
      )}

      <Droppable
        droppableId={argumentGroup?.id || "none"}
        type="droppableForArguments"
      >
        {(providedDroppable) => (
          <Stack
            spacing={1.5}
            sx={{ minHeight: "10px" }} // To be able to drop arguments even if empty
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
          >
            {_arguments.map((argument, index) => (
              <MatrixArgumentRow
                key={argument.id}
                argument={argument}
                index={index}
                onBulkActionCheckboxClick={onBulkActionCheckboxClick}
                isSelectedForBulkAction={checkIfSelectedForBulkAction([
                  argument,
                ])}
                nbColumns={nbColumns}
              />
            ))}

            {providedDroppable.placeholder}
          </Stack>
        )}
      </Droppable>

      {providedDraggable && (
        <DragHandleContainer className="drag-handle">
          <DragAndDropHandle
            dragHandleProps={providedDraggable.dragHandleProps}
          />
        </DragHandleContainer>
      )}

      <MatrixGrid $nbColumns={nbColumns} style={{ height: 0 }}>
        <FloatingActionsStickyContainer>
          <FloatingActionsContainer $bottomOffset={!argumentGroup}>
            <AddArgumentCTAs
              arguments={_arguments}
              argumentGroup={argumentGroup}
            />
          </FloatingActionsContainer>
        </FloatingActionsStickyContainer>
      </MatrixGrid>
    </Container>
  )
}

interface MatrixArgumentGroupWithDndProps {
  index?: number
  argumentGroup: IArgumentGroup | null
  arguments: IArgument[]
  nameContainerRef?: React.RefObject<HTMLDivElement> | null
  onBulkActionCheckboxClick: (_arguments: IArgument[]) => void
  checkIfSelectedForBulkAction: (_arguments: IArgument[]) => boolean
  nbColumns: number
}

export default function MatrixArgumentGroupWithDnd(
  props: MatrixArgumentGroupWithDndProps
) {
  if (props.argumentGroup && typeof props.index !== "undefined") {
    return (
      <Draggable
        draggableId={props.argumentGroup?.id || "none"}
        index={props.index}
      >
        {(providedDraggable) => (
          <MatrixArgumentGroup
            {...props}
            providedDraggable={providedDraggable}
          />
        )}
      </Draggable>
    )
  }

  return <MatrixArgumentGroup {...props} />
}
