import { useState } from "react"

import { IArgument } from "api/types/arguments"

import Stack from "ds/Stack"

import ArgumentAnswerForm from "./ArgumentAnswerForm"
import ArgumentAnswerFormCategorySelector from "./ArgumentAnswerFormCategorySelector"
import { CrmPropertyForm } from "./CrmPropertyForm"

interface Props {
  argument: IArgument
  onSuccess: () => void
}

export default function MeetingNoteConfigurationCRMForm({
  argument,
  onSuccess,
}: Props) {
  const hasCRMPropertyMeetingNoteConfiguration =
    !!argument.meetingNoteConfiguration &&
    !!argument.meetingNoteConfiguration.crmProperty

  const [syncToCRM, setSyncToCRM] = useState<boolean>(
    hasCRMPropertyMeetingNoteConfiguration
  )

  return (
    <Stack spacing={2}>
      <ArgumentAnswerFormCategorySelector
        onSelect={setSyncToCRM}
        value={syncToCRM}
      />

      {!syncToCRM && (
        <ArgumentAnswerForm argument={argument} onSuccess={onSuccess} />
      )}

      {syncToCRM && (
        <CrmPropertyForm argument={argument} onSuccess={onSuccess} />
      )}
    </Stack>
  )
}
