import { useState } from "react"
import { FormattedMessage } from "react-intl"

import useMe from "authentication/useMe"

import Button from "ds/Button"
import CenteringContainer from "ds/CenteringContainer"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { isOrganizationAdmin } from "services/organizationMemberships"

import CreateBusinessUnitModal from "../Organization/CreateBusinessUnitModal"
import { useOrganization } from "../Organization/OrganizationProvider"

interface Props {
  organizationId: string
}

export default function NoBusinessUnit({ organizationId }: Props) {
  const user = useMe()
  const [isOpen, setIsOpen] = useState(false)
  const organization = useOrganization()

  return (
    <CenteringContainer>
      {user.isSuperAdmin || isOrganizationAdmin(user, organizationId) ? (
        <Stack spacing={1}>
          <Typography variant="baseSemi">
            <FormattedMessage id="businessUnits.empty.title" />
          </Typography>
          <Button variant="contained" onClick={() => setIsOpen(true)}>
            <FormattedMessage id="organizations.businessUnits.actions.create" />
          </Button>
        </Stack>
      ) : (
        <Typography variant="baseNormal">
          <FormattedMessage id="appRoot.noBusinessUnit.explanationForNonAdmins" />
        </Typography>
      )}

      <CreateBusinessUnitModal
        organizationId={organization.id}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </CenteringContainer>
  )
}
