import MoreHoriz from "@mui/icons-material/MoreHoriz"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { IBarePlaybook } from "api/types/playbooks"

import Button from "ds/Button"
import Menu from "ds/Menu"
import MenuItem from "ds/MenuItem"

import { useOrganization } from "components/App/Organization/OrganizationProvider"

import useAnchorEl from "utils/hooks/useAnchorEl"

import EditPlaybook from "./EditPlaybook"
import ExportPlaybook from "./ExportPlaybook"
import {
  useArchivePlaybookMutation,
  useRestorePlaybookMutation,
} from "./businessUnitPlaybookQueries"

interface Props {
  playbook: IBarePlaybook
}

export default function PlaybookActions({ playbook }: Props) {
  const organization = useOrganization()
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isExportOpen, setIsExportOpen] = useState(false)
  const archiveMutation = useArchivePlaybookMutation(organization.id)
  const restoreMutation = useRestorePlaybookMutation(organization.id)

  return (
    <>
      <Button
        onClick={handleClick}
        aria-label={intl.formatMessage({
          id: "businessUnits.playbooks.actions",
          defaultMessage: "Playbooks actions",
        })}
      >
        <MoreHoriz />
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {!playbook.archived && (
          <MenuItem
            onClick={() => {
              archiveMutation.mutate(playbook.id)
              handleClose()
            }}
            disabled={archiveMutation.isLoading}
          >
            <FormattedMessage id="businessUnits.playbooks.actions.archive" />
          </MenuItem>
        )}

        {playbook.archived && (
          <MenuItem
            onClick={() => {
              restoreMutation.mutate(playbook.id)
              handleClose()
            }}
            disabled={restoreMutation.isLoading}
          >
            <FormattedMessage id="businessUnits.playbooks.actions.restore" />
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setIsEditOpen(true)
            handleClose()
          }}
        >
          <FormattedMessage
            id="businessUnits.playbooks.actions.edit"
            defaultMessage="Edit"
          />
        </MenuItem>

        <MenuItem
          onClick={() => {
            setIsExportOpen(true)
            handleClose()
          }}
        >
          <FormattedMessage
            id="businessUnits.playbooks.actions.export"
            defaultMessage="Export as CSV"
          />
        </MenuItem>
      </Menu>

      {isEditOpen && (
        <EditPlaybook
          playbook={playbook}
          onClose={() => setIsEditOpen(false)}
        />
      )}

      {isExportOpen && (
        <ExportPlaybook
          playbook={playbook}
          onClose={() => setIsExportOpen(false)}
        />
      )}
    </>
  )
}
