import { IOrganizationMembership } from "api/types/organizationMemberships"

import useMe from "authentication/useMe"

import OrganizationRoleSelect from "./OrganizationRoleSelect"
import { useOrganizationMembershipUpdateRoleMutation } from "./organizationMembershipQueries"

interface Props {
  organizationMembership: IOrganizationMembership
}

export default function OrganizationMembershipRoleForm({
  organizationMembership,
}: Props) {
  const currentUser = useMe()
  const updateQuery = useOrganizationMembershipUpdateRoleMutation(
    organizationMembership.organizationId
  )

  const isCurrentUser = currentUser.id === organizationMembership.user.id

  return (
    <OrganizationRoleSelect
      id={`organization-membership-role-${organizationMembership.id}`}
      value={organizationMembership.role}
      onChange={(e) => {
        const newRole = e.target.value
        updateQuery.mutate({
          id: organizationMembership.id,
          role: newRole,
        })
      }}
      disabled={isCurrentUser || updateQuery.isLoading}
      label={null}
    />
  )
}
