import ImportContactsIcon from "@mui/icons-material/ImportContacts"
import { useIntl } from "react-intl"

import Avatar from "ds/Avatar"

export default function ReferenceIndicator() {
  const intl = useIntl()
  return (
    <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.light }}>
      <ImportContactsIcon
        fontSize="small"
        color="secondary"
        aria-label={intl.formatMessage({
          id: "playbookEdit.argument.kind.REFERENCE",
        })}
      />
    </Avatar>
  )
}
