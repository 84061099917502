import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_TOGGLE,
  PlateEditor,
  openNextToggles,
} from "@udecode/plate"

import { MyAutoformatRule } from "../../types"

export const autoformatBlocks: MyAutoformatRule[] = [
  {
    mode: "block",
    type: ELEMENT_BLOCKQUOTE,
    match: "| ",
  },
  {
    mode: "block",
    type: ELEMENT_TOGGLE,
    match: "> ",
    preFormat: (editor) => openNextToggles(editor as PlateEditor),
  },
]
