import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import {
  IMeeting,
  isMeetingLinkedToExternalCRM,
  isMeetingLinkedToExternalCRMCall,
} from "api/types/meetings"

import Button from "ds/Button"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { MeetingInformationHeader } from "components/App/Playbook/Meeting/MeetingInformation"
import Avatar from "components/common/Avatar"
import IntegrationIcon from "components/common/IntegrationIcon"

import { getCRMMeetingCallUrl, getCRMMeetingUrl } from "utils/crmURLGetter"

interface Props {
  meeting: IMeeting
}

export default function MeetingCRMInformations({ meeting }: Props) {
  return (
    <Stack spacing={2}>
      <MeetingInformationHeader />

      <Box
        display="grid"
        gap={1}
        sx={(theme) => ({
          gridTemplateColumns: "1fr 1fr",
          [theme.breakpoints.down("md")]: { gridTemplateColumns: "1fr" },
        })}
      >
        <div>
          <div>
            <Typography
              variant="xsNormal"
              color={(theme) => theme.palette.text.secondary}
            >
              <FormattedMessage id="playbook.meeting.information.crmMeetingType.title" />
            </Typography>
          </div>

          <div>
            <Typography variant="smNormal">
              {meeting.crmMeetingType?.name || "-"}
            </Typography>
          </div>
        </div>

        <div>
          <div>
            <Typography
              variant="xsNormal"
              color={(theme) => theme.palette.text.secondary}
            >
              <FormattedMessage id="playbook.meeting.information.crmCompany.title" />
            </Typography>
          </div>

          <div>
            <Typography variant="smNormal">
              {meeting.crmCompany?.name || "-"}
            </Typography>
          </div>
        </div>

        <div>
          <div>
            <Typography
              variant="xsNormal"
              color={(theme) => theme.palette.text.secondary}
            >
              <FormattedMessage id="playbook.meeting.information.crmContacts.title" />
            </Typography>
          </div>
          <div>
            <Typography variant="smNormal">
              {meeting.crmContacts.map(({ name }) => name).join(", ") || "-"}
            </Typography>
          </div>
        </div>

        <div>
          <div>
            <Typography
              variant="xsNormal"
              color={(theme) => theme.palette.text.secondary}
            >
              <FormattedMessage id="meetings.index.meetingAuthor" />
            </Typography>
          </div>

          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              userName={meeting.user.name}
              userEmail={meeting.user.email}
              userDefaultAvatarColorCode={meeting.user.defaultAvatarColorCode}
              userAvatarUrl={meeting.user.thumbnailAvatarUrl}
              sx={{ height: 25, width: 25, fontSize: "12px" }}
            />

            <div>
              <Typography variant="smNormal">
                {meeting.user.name || meeting.user.email}
              </Typography>
            </div>
          </Stack>
        </div>

        {(isMeetingLinkedToExternalCRM(meeting) ||
          isMeetingLinkedToExternalCRMCall(meeting)) && (
          <div>
            <Typography
              variant="xsNormal"
              color={(theme) => theme.palette.text.secondary}
            >
              <FormattedMessage id="meetings.index.meetingCRMLink" />
            </Typography>

            <Box>
              <Button
                href={
                  isMeetingLinkedToExternalCRM(meeting)
                    ? getCRMMeetingUrl(meeting)
                    : getCRMMeetingCallUrl(meeting)
                }
                target="_blank"
              >
                <IntegrationIcon
                  integrationName={meeting.integrationName}
                  style={{ width: "25px" }}
                />
              </Button>
            </Box>
          </div>
        )}

        <div>
          <div>
            <Typography
              variant="xsNormal"
              color={(theme) => theme.palette.text.secondary}
            >
              <FormattedMessage id="playbook.meeting.information.crmDeal.title" />
            </Typography>
          </div>
          <div>
            <Typography variant="smNormal">
              {meeting.crmDeal?.name || "-"}
            </Typography>
          </div>
        </div>
      </Box>
    </Stack>
  )
}
