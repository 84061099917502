import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import { useIntl } from "react-intl"

import IconButton, { IconButtonProps } from "ds/IconButton"

interface Props extends IconButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default function Back({ onClick }: Props) {
  const intl = useIntl()

  return (
    <IconButton
      onClick={onClick}
      aria-label={intl.formatMessage({
        id: "common.back",
        defaultMessage: "Back",
      })}
    >
      <KeyboardArrowLeftIcon fontSize="small" />
    </IconButton>
  )
}
