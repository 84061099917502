import { useState } from "react"
import { useIntl } from "react-intl"
import { useDebouncedCallback } from "use-debounce"

import { IMeeting } from "api/types/meetings"

import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { MyEditor, MyValue } from "ds/RichTextNew/types"
import { BasicErrorSnack } from "ds/Snackbar"
import Typography from "ds/Typography"

import { useCurrentMeetingUpdateNoteMutation } from "components/App/CRM/Meetings/meetingQueries"

import { useMeeting } from "./MeetingProvider"

const debounceWaitMs = 1000

interface Props {
  editorRef: React.RefObject<MyEditor>
  ongoingMeeting: IMeeting
}

export default function MeetingGeneralNoteForm({
  ongoingMeeting,
  editorRef,
}: Props) {
  const intl = useIntl()
  const { generalNote, setGeneralNote, setRawGeneralNote } = useMeeting()

  const updateNoteMutation = useCurrentMeetingUpdateNoteMutation(
    ongoingMeeting.playbookId
  )
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const save = (newValue: MyValue, newRawValue: string) =>
    updateNoteMutation.mutate(
      {
        id: ongoingMeeting.id,
        value: newValue,
        rawValue: newRawValue,
      },
      { onError: () => setErrorSnackOpen(true) }
    )

  const onChange = (newValue: MyValue, newRawValue: string) => {
    setGeneralNote(newValue)
    setRawGeneralNote(newRawValue)
    save(newValue, newRawValue)
  }

  const debouncedOnChange = useDebouncedCallback(onChange, debounceWaitMs)

  const meetingGeneralNotePlaceholder = intl.formatMessage({
    id: "playbook.meeting.generalNote.form.placeholder",
    defaultMessage: "Write your notes here...",
  })

  return (
    <>
      <Typography
        variant="smNormal"
        sx={{
          p: 1,
          pb: 0,
          border: (theme) => `solid 1px ${theme.palette.grey[300]}`,
          borderRadius: 1,
        }}
      >
        <RichTextEditor
          value={generalNote}
          editorRef={editorRef}
          onChange={debouncedOnChange}
          placeholder={meetingGeneralNotePlaceholder}
          editableStyle={{ height: "250px", overflowY: "auto" }}
          toolbarProps={{ highlightSpeechToText: true }}
        />
      </Typography>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </>
  )
}
