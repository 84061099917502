import { FormattedMessage } from "react-intl"

import MenuItem from "ds/MenuItem"

import { argumentCardId } from "components/App/Playbook/Argument/ArgumentCard"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { getArgumentGroupDOMId } from "components/App/Playbook/getArgumentGroupDOMId"
import { usePasteArgumentGroupsMutation } from "components/App/Playbook/queries/argumentGroupQueries"
import { usePasteArgumentsMutation } from "components/App/Playbook/queries/argumentQueries"
import { usePasteArgumentTypesMutation } from "components/App/Playbook/queries/argumentTypeQueries"

import { buildSegmentRulesFromSegmentFilters } from "services/argumentSegmentRules"
import {
  getLS,
  isArgumentGroupLSItem,
  isArgumentLSItem,
  isArgumentTypeLSItem,
} from "services/copyPaste"

import { scrollToElementWithDelay } from "utils/scrollToElement"

interface Props {
  onSuccess: () => void
  onError: () => void
  onInconsistency: () => void
}

export default function PasteInArgumentTypes({
  onSuccess,
  onError,
  onInconsistency,
}: Props) {
  const { playbook, activeTypeId, segmentFilters, setActiveTypeId } =
    usePlaybook()
  const pasteArgumentsMutation = usePasteArgumentsMutation(activeTypeId)
  const pasteArgumentGroupsMutation = usePasteArgumentGroupsMutation(
    playbook.id,
    activeTypeId
  )
  const pasteArgumentTypesMutation = usePasteArgumentTypesMutation(
    playbook.id,
    activeTypeId
  )
  const LSItems = getLS()

  async function onClick() {
    if (!LSItems) {
      onInconsistency()
    } else if (LSItems.every((item) => isArgumentLSItem(item))) {
      const argumentIds = LSItems.map(({ id }) => id)
      pasteArgumentsMutation.mutate(
        {
          argumentIds,
          argumentGroupId: null,
          argumentTypeId: activeTypeId,
          segmentRules: buildSegmentRulesFromSegmentFilters(segmentFilters),
        },
        {
          onSuccess: (_arguments) => {
            const firstArgument = _arguments[0]
            scrollToElementWithDelay(argumentCardId(firstArgument.id))
            onSuccess()
          },
          onError: () => onError(),
        }
      )
    } else if (LSItems.every((item) => isArgumentGroupLSItem(item))) {
      const argumentGroupIds = LSItems.map(({ id }) => id)
      pasteArgumentGroupsMutation.mutate(
        {
          argumentGroupIds,
          argumentTypeId: activeTypeId,
          segmentRules: buildSegmentRulesFromSegmentFilters(segmentFilters),
        },
        {
          onSuccess: (argumentGroups) => {
            const firstGroup = argumentGroups[0]
            scrollToElementWithDelay(getArgumentGroupDOMId(firstGroup.id))
            onSuccess()
          },
          onError: () => onError(),
        }
      )
    } else if (LSItems.every((item) => isArgumentTypeLSItem(item))) {
      const argumentTypeIds = LSItems.map(({ id }) => id)
      pasteArgumentTypesMutation.mutate(
        {
          argumentTypeIds,
          relativeArgumentTypeId: activeTypeId,
        },
        {
          onSuccess: (argumentTypes) => {
            const firstArgumentType = argumentTypes[0]
            setTimeout(() => setActiveTypeId(firstArgumentType.id), 1000)
            onSuccess()
          },
          onError: () => onError(),
        }
      )
    } else {
      onInconsistency()
    }
  }

  return (
    <MenuItem
      onClick={onClick}
      disabled={
        pasteArgumentsMutation.isLoading ||
        pasteArgumentGroupsMutation.isLoading ||
        pasteArgumentTypesMutation.isLoading
      }
    >
      <FormattedMessage id="playbookEdit.argumentTypes.paste" />
    </MenuItem>
  )
}
