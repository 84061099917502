import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgumentField } from "api/types/argumentFields"
import { IArgument } from "api/types/arguments"

import Alert from "ds/Alert"
import Stack from "ds/Stack"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import { buildFieldValue, getFieldValue } from "services/argumentFields"
import {
  getArgumentType,
  isReferencesArgumentType,
} from "services/argumentTypes"

import ArgumentKindIndicator from "./ArgumentCard/ArgumentKindIndicator/ArgumentKindIndicator"
import EditableArgumentKindIndicator from "./ArgumentCard/ArgumentKindIndicator/EditableArgumentKindIndicator"
import ArgumentFieldValue from "./ArgumentFieldValues/ArgumentFieldValue"
import NewArgumentFieldValue from "./ArgumentFieldValues/NewArgumentFieldValue"
import EditableArgumentPersonalNote from "./ArgumentPersonalNote/EditableArgumentPersonalNote"
import StalenessStatus from "./Staleness/StalenessStatus"

function shouldDisplayField(
  argumentField: IArgumentField,
  filledFieldIds: string[],
  forcedFieldIds: string[],
  editMode: boolean
) {
  if (argumentField.archived) {
    return false
  }
  if (!editMode) {
    return (
      argumentField.type === "TITLE" ||
      filledFieldIds.includes(argumentField.id)
    )
  }

  return (
    argumentField.type !== "CUSTOM" ||
    filledFieldIds.includes(argumentField.id) ||
    forcedFieldIds.includes(argumentField.id)
  )
}

interface Props {
  argument: IArgument
}

export default function ArgumentShow({ argument }: Props) {
  const { playbook, editMode, activeTypeId } = usePlaybook()
  const [forcedFieldIds, setForcedFieldIds] = useState<string[]>([])
  const activeType = getArgumentType(playbook, activeTypeId)

  function onAddField(argumentField: IArgumentField) {
    setForcedFieldIds((prev) => [...prev, argumentField.id])
  }

  function onDeleteField(argumentField: IArgumentField) {
    setForcedFieldIds((prev) => prev.filter((x) => x !== argumentField.id))
  }

  const filledFieldIds = argument.argumentFieldValues
    .filter((argumentFieldValue) => !!argumentFieldValue.value)
    .map((x) => x.argumentFieldId)

  const visibleFields = playbook.argumentFields.filter((argumentField) =>
    shouldDisplayField(argumentField, filledFieldIds, forcedFieldIds, editMode)
  )

  function renderFieldValue(argumentField: IArgumentField) {
    const argumentFieldValue =
      getFieldValue(argument, argumentField) || buildFieldValue(argumentField)
    return (
      <ArgumentFieldValue
        key={argumentField.id}
        argumentField={argumentField}
        argumentFieldValue={argumentFieldValue}
        argument={argument}
        onDeleteField={() => onDeleteField(argumentField)}
      />
    )
  }

  return (
    <Stack spacing={2} mb={2}>
      {!editMode && (
        <Box display="flex" justifyContent="end" px={2}>
          <EditableArgumentPersonalNote argument={argument} />
        </Box>
      )}

      {editMode && (
        <Stack
          spacing={2}
          px={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <StalenessStatus argument={argument} />
          <NewArgumentFieldValue argument={argument} onAddField={onAddField} />
        </Stack>
      )}

      <Stack px={2} spacing={2}>
        <Box
          sx={{
            borderRadius: 1,
            boxShadow: 6,
            bgcolor: (theme) => theme.palette.common.white,
            p: 2,
          }}
        >
          <Stack direction="row" spacing={1.5} alignItems="start">
            {editMode ? (
              <EditableArgumentKindIndicator argument={argument} />
            ) : (
              <ArgumentKindIndicator argument={argument} />
            )}

            <Stack spacing={2} flexGrow={1} minWidth={0}>
              {visibleFields.map(renderFieldValue)}
            </Stack>
          </Stack>
        </Box>

        {editMode && activeType && isReferencesArgumentType(activeType) && (
          <Alert severity="info">
            <FormattedMessage id="playbookEdit.argumentTypes.references.richTextWarning" />
          </Alert>
        )}
      </Stack>
    </Stack>
  )
}
