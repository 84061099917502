import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IIntegration } from "api/types/integrations"

import Button from "ds/Button"
import Modal from "ds/Modal"
import { BasicErrorSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

import CRMPropertySelector from "components/App/CRM/properties/CRMPropertySelector"
import CrmPropertyRefresh from "components/App/CRM/properties/CrmPropertyRefresh"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentSegmentationLinkToCrmPropertyMutation } from "components/App/Playbook/queries/argumentSegmentationQueries"
import IntegrationIcon from "components/common/IntegrationIcon"

import { filterLinkableProperties } from "services/argumentSegmentations"

interface Props {
  argumentSegmentation: IArgumentSegmentation
  crmIntegration: IIntegration
}

export default function LinkArgumentSegmentationToCrmProperty(props: Props) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button size="small" onClick={() => setIsOpen(true)}>
        <Stack direction="row" spacing={1} alignItems="center">
          <IntegrationIcon integrationName={props.crmIntegration.name} />
          <div>
            <FormattedMessage id="playbookEdit.argumentSegmentations.linkToCrmProperty" />
          </div>
        </Stack>
      </Button>

      {isOpen && (
        <Modal isOpen onClose={() => setIsOpen(false)}>
          <Box sx={{ p: 2, minWidth: "min(90vh, 400px)" }}>
            <CrmPropertyForm
              argumentSegmentation={props.argumentSegmentation}
              onSuccess={() => setIsOpen(false)}
            />
          </Box>
        </Modal>
      )}
    </>
  )
}

interface FormProps {
  argumentSegmentation: IArgumentSegmentation
  onSuccess: () => void
}

function CrmPropertyForm(props: FormProps) {
  const { playbook } = usePlaybook()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)

  const [selectedCrmPropertyId, setSelectedPropertyId] = useState<
    string | null
  >(props.argumentSegmentation.crmProperty?.id || null)

  const linkMutation = useArgumentSegmentationLinkToCrmPropertyMutation(
    playbook.id
  )

  function submit(e: React.FormEvent) {
    e.preventDefault()
    if (selectedCrmPropertyId === null) return
    linkMutation.mutate(
      {
        id: props.argumentSegmentation.id,
        crmPropertyId: selectedCrmPropertyId,
      },
      {
        onSuccess: () => props.onSuccess(),
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <Box>
      <form onSubmit={submit}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center">
            <CRMPropertySelector
              businessUnitId={playbook.businessUnitId}
              fullWidth
              crmPropertyId={selectedCrmPropertyId}
              onChange={(_, newProperty) => {
                setSelectedPropertyId(newProperty?.id || null)
              }}
              renderInput={(params) => <TextField {...params} autoFocus />}
              restrictOptions={filterLinkableProperties}
            />

            <CrmPropertyRefresh />
          </Stack>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!selectedCrmPropertyId || linkMutation.isLoading}
          >
            <FormattedMessage id="common.submit" />
          </Button>
        </Stack>
      </form>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
    </Box>
  )
}
