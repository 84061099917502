import { Link as RouterLink } from "react-router-dom"
import styled, { css } from "styled-components"

const unstyled = css`
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: inherit;
    text-decoration: inherit;
  }
`

const UnstyledLink = styled.a`
  ${unstyled}
`

export default UnstyledLink

export const UnstyledRouterLink = styled(RouterLink)`
  ${unstyled}
`
