import { IMeeting } from "api/types/meetings"

import RichTextEditor from "ds/RichTextNew/RichTextEditor"
import { buildNodeFromText } from "ds/RichTextNew/helpers"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { MeetingGeneralNoteHeader } from "components/App/Playbook/Meeting/MeetingGeneralNote"

interface Props {
  meeting: IMeeting
}

export default function MeetingGeneralNote({ meeting }: Props) {
  return (
    <Stack spacing={2}>
      <MeetingGeneralNoteHeader />

      <Typography variant="smNormal" component="div">
        {meeting.rawGeneralNote ? (
          <RichTextEditor
            value={meeting.generalNote || buildNodeFromText("")}
            readOnly
          />
        ) : (
          "-"
        )}
      </Typography>
    </Stack>
  )
}
