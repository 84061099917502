// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
import { FormattedTime as ReactIntlFormattedTime } from "react-intl"

// mardi 15 février, 17:08
export const longTime = {
  weekday: "long",
  day: "numeric",
  month: "long",
  hour: "numeric",
  minute: "numeric",
} as const

// 15 fév, 17:08
export const shortTime = {
  day: "numeric",
  month: "short",
  hour: "numeric",
  minute: "numeric",
} as const

// Feb 15, 2022, 17:08 PM
export const shortTimeWithYear = {
  ...shortTime,
  year: "numeric",
} as const

// TODO add more
const formatsMapping = {
  longTime,
  shortTime,
  shortTimeWithYear,
}

type Formats = keyof typeof formatsMapping

interface FormattedTimeProps {
  value: string
  format: Formats
}

export default function FormattedTime({ value, format }: FormattedTimeProps) {
  const formatOptions = formatsMapping[format] || {}
  return <ReactIntlFormattedTime value={value} {...formatOptions} />
}
