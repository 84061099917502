import ForumIcon from "@mui/icons-material/Forum"
import { FormattedMessage } from "react-intl"

import ListItemIcon from "ds/ListItemIcon"
import ListItemText from "ds/ListItemText"
import MenuItem from "ds/MenuItem"

interface Props {
  onClick: React.MouseEventHandler
}

export default function ContactOrDemoMenuItem({ onClick }: Props) {
  return (
    <MenuItem
      component="a"
      target="_blank"
      rel="noreferrer"
      href="https://www.salespath.io?demoOpen=true"
      onClick={onClick}
    >
      <ListItemIcon>
        <ForumIcon color="info" />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="navigation.contactOrDemo" />
      </ListItemText>
    </MenuItem>
  )
}
