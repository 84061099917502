import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import Loader from "ds/Loader"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import BusinessUnitMembershipsTable from "./BusinessUnitMembershipsTable"
import { useBusinessUnit } from "./BusinessUnitProvider"
import { useBusinessUnitMembershipsInfiniteQuery } from "./businessUnitMembershipsQueries"

interface Props {
  query: string
}

export default function BusinessUnitMembershipsIndex({ query }: Props) {
  const businessUnit = useBusinessUnit()
  const membershipsQuery = useBusinessUnitMembershipsInfiniteQuery(
    businessUnit.id,
    query
  )

  if (membershipsQuery.isLoading) {
    return <Loader />
  }

  if (membershipsQuery.isError) {
    return <UncontrolledError error={membershipsQuery.error} />
  }

  const lastPage = membershipsQuery.data.pages.slice(-1)[0]

  const memberships = membershipsQuery.data.pages
    .map((page) => {
      return page.businessUnitMemberships
    })
    .flat()

  return (
    <>
      {memberships.length > 0 && (
        <>
          <Stack direction="row" spacing={1}>
            <Typography variant="smSemi">
              {lastPage.searchedMembershipsCount
                ? `${lastPage.searchedMembershipsCount} / ${lastPage.totalMembershipsCount}`
                : lastPage.totalMembershipsCount}
            </Typography>

            <Typography variant="smSemi">
              <FormattedMessage id="businessUnits.users.title" />
            </Typography>
          </Stack>

          <BusinessUnitMembershipsTable businessUnitMemberships={memberships} />

          {membershipsQuery.isFetchingNextPage && <Loader />}

          {membershipsQuery.hasNextPage &&
            !membershipsQuery.isFetchingNextPage && (
              <Box>
                <Button onClick={() => membershipsQuery.fetchNextPage()}>
                  <FormattedMessage id="businessUnits.memberships.index.loadMore" />
                </Button>
              </Box>
            )}
        </>
      )}
    </>
  )
}
