import { Box } from "@mui/material"

import { apiCreateLog } from "api/logs"
import { IPlaybook } from "api/types/playbooks"

import RoundTabs, { RoundTab } from "ds/RoundTabs"
import Stack from "ds/Stack"

import { filterNonArchived } from "services/archivable"
import { filterArgumentTypesHavingArguments } from "services/argumentTypes"

import ArgumentTypeActions from "./PlaybookEdit/ArgumentTypes/ArgumentTypeActions/ArgumentTypeActions"
import { usePlaybook } from "./PlaybookProvider"

export default function ArgumentTypes() {
  const { editMode, playbook, activeTypeId, setActiveTypeId } = usePlaybook()

  function filterTypesToShow(playbook: IPlaybook) {
    if (editMode) {
      return filterNonArchived(playbook.argumentTypes)
    } else {
      return filterNonArchived(filterArgumentTypesHavingArguments(playbook))
    }
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box flexGrow={1} minWidth={0}>
        <RoundTabs
          value={activeTypeId}
          onChange={(_event, newValue) => setActiveTypeId(newValue)}
        >
          {filterTypesToShow(playbook).map((argumentType) => (
            <RoundTab
              key={argumentType.id}
              value={argumentType.id}
              label={argumentType.name}
              onClick={() =>
                apiCreateLog({
                  action: "VISIT_ARGUMENT_TYPE",
                  countsAsActivity: true,
                  playbookId: playbook.id,
                })
              }
            />
          ))}
        </RoundTabs>
      </Box>

      {editMode && <ArgumentTypeActions />}
    </Stack>
  )
}
