import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"

import Badge from "ds/Badge"

interface Props {
  notSeenCount: number
}

export default function NotificationsIcon({ notSeenCount }: Props) {
  return (
    <Badge badgeContent={notSeenCount} color="error">
      <NotificationsNoneIcon fontSize="small" sx={{ fill: "white" }} />
    </Badge>
  )
}
