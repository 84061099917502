interface Archivable {
  discarded: boolean
}

export function isArchived<T extends Archivable>(entity: T): boolean {
  return entity.discarded
}

export function filterNonArchived<T extends Archivable>(entities: T[]): T[] {
  return entities.filter((entity) => !isArchived(entity))
}

export function filterArchived<T extends Archivable>(entities: T[]): T[] {
  return entities.filter((entity) => isArchived(entity))
}
