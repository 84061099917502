import { useEffect } from "react"
import ReactGA from "react-ga"
import { Location, useLocation } from "react-router-dom"

const googleAnalyticsId = "G-8CW9BDTTGP"

const logPageView = (location: Location): void => {
  const page = location.pathname || window.location.pathname
  ReactGA.set({ page: page })
  ReactGA.pageview(page)
}

export default function useGoogleAnalytics(enabled = true) {
  const location = useLocation()

  if (enabled) {
    ReactGA.initialize(googleAnalyticsId)
  }

  useEffect(() => {
    if (enabled) logPageView(location)
  }, [location, enabled])
}
