import { useIntl } from "react-intl"

import { organizationMembershipRoles } from "api/types/organizationMemberships"

import MenuItem from "ds/MenuItem"
import TextField from "ds/TextField"

import RoleLabel from "components/common/RoleLabel"

interface Props {
  id: string
  value: string
  onChange: React.ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  label?: string | null
}

const OrganizationRoleSelect = ({
  id,
  value,
  onChange,
  disabled,
  label,
}: Props) => {
  const intl = useIntl()

  const defaultLabel = intl.formatMessage({
    id: "organizations.users.field.role",
    defaultMessage: "Role",
  })

  return (
    <TextField
      variant="outlined"
      id={id}
      label={label === undefined ? defaultLabel : label}
      select
      size="small"
      SelectProps={{ inputProps: { "aria-label": defaultLabel } }}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {organizationMembershipRoles.map((role) => (
        <MenuItem key={role} value={role}>
          <RoleLabel role={role} />
        </MenuItem>
      ))}
    </TextField>
  )
}

export default OrganizationRoleSelect
