import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"

import { IBusinessUnit } from "api/types/businessUnits"

import Modal from "ds/Modal"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { useConfig } from "components/Config"

import {
  getBusinessUnitPath,
  getManageUsersBusinessUnitPath,
} from "services/businessUnits"

import CreateBusinessUnitForm from "./CreateBusinessUnitForm"

interface Props {
  organizationId: string
  isOpen: boolean
  onClose: () => void
  onSuccess?: (businessUnit: IBusinessUnit) => void
}

export default function CreateBusinessUnitModal({
  organizationId,
  isOpen,
  onClose,
  onSuccess,
}: Props) {
  const navigate = useNavigate()
  const config = useConfig()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Stack spacing={4}>
        <Typography variant="baseSemi">
          <FormattedMessage id="organizations.businessUnits.actions.create" />
        </Typography>
        <CreateBusinessUnitForm
          organizationId={organizationId}
          onSuccess={(businessUnit) => {
            onSuccess && onSuccess(businessUnit)
            if (IS_CHROME_EXTENSION) {
              window.open(
                `${config.appUrl}${getManageUsersBusinessUnitPath(
                  organizationId,
                  businessUnit.id
                )}`,
                "_blank"
              )
              navigate(getBusinessUnitPath(businessUnit.id))
            } else {
              navigate(
                getManageUsersBusinessUnitPath(organizationId, businessUnit.id)
              )
            }
          }}
        />
      </Stack>
    </Modal>
  )
}
