import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import { useIntl } from "react-intl"

import IconButton, { IconButtonProps } from "ds/IconButton"
import Tooltip from "ds/Tooltip"

interface Props {
  onClick: IconButtonProps["onClick"]
}

export default function ViewSettingsOpener({ onClick }: Props) {
  const intl = useIntl()
  const label = intl.formatMessage({
    id: "playbook.viewSettings.title",
    defaultMessage: "View settings",
  })

  return (
    <Tooltip title={label}>
      <IconButton onClick={onClick} aria-label={label}>
        <SettingsOutlinedIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}
