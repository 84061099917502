const maxSizeMBytes = 100
const maxSizeBytes = maxSizeMBytes * 1024 * 1024

export function belowSizeLimit(file: Blob) {
  return file.size <= maxSizeBytes
}

export function alertMaxSizeExceeded() {
  alert(`Max ${maxSizeMBytes}MB`)
}
