import MuiTableCell, { TableCellProps } from "@mui/material/TableCell"
import { forwardRef } from "react"

export type { TableCellProps }

export default forwardRef(function TableCell(
  props: TableCellProps,
  ref: React.Ref<HTMLTableCellElement>
) {
  return <MuiTableCell {...props} ref={ref} />
})
