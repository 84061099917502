// Controls the view settings for argument cards and groups
import useMe from "authentication/useMe"

import useLocalStorageState from "utils/hooks/useLocalStorageState"

function getExpandedGroupsLSKeys(playbookId: string) {
  return `salespath-expandedGroups-${playbookId}`
}

export default function useViewSettings(playbookId: string) {
  const user = useMe()
  const [showArchived, setShowArchived] = useLocalStorageState(
    "playbook.viewSettings.showArchived",
    false
  )
  const [showStaleness, setShowStaleness] = useLocalStorageState(
    "playbook.viewSettings.showStaleness",
    false
  )
  const [expandedGroupsByType, setExpandedGroupsByType] = useLocalStorageState<
    Record<string, string[]>
  >(getExpandedGroupsLSKeys(playbookId), {})

  function collapseGroups(argumentTypeId: string, argumentGroupIds: string[]) {
    setExpandedGroupsByType((prev) => ({
      ...prev,
      [argumentTypeId]: prev[argumentTypeId].filter(
        (x) => !argumentGroupIds.includes(x)
      ),
    }))
  }

  function expandGroups(argumentTypeId: string, argumentGroupIds: string[]) {
    if (user.prefersUniqueArgumentGroupOpen) {
      setExpandedGroupsByType((prev) => ({
        ...prev,
        [argumentTypeId]: argumentGroupIds,
      }))
    } else {
      setExpandedGroupsByType((prev) => ({
        ...prev,
        [argumentTypeId]: prev[argumentTypeId]
          ? prev[argumentTypeId].concat(argumentGroupIds)
          : argumentGroupIds,
      }))
    }
  }

  function isGroupExpanded(argumentTypeId: string, argumentGroupId: string) {
    return (
      expandedGroupsByType[argumentTypeId] &&
      expandedGroupsByType[argumentTypeId].includes(argumentGroupId)
    )
  }

  return {
    showArchived,
    setShowArchived,
    showStaleness,
    setShowStaleness,
    collapseGroups,
    expandGroups,
    isGroupExpanded,
  }
}

export type IViewSettings = ReturnType<typeof useViewSettings>
