import { Box, useTheme } from "@mui/material"
import { FormattedMessage } from "react-intl"

import { IOrganizationInvitation } from "api/types/organizationInvitations"

import Button from "ds/Button"
import Loader from "ds/Loader"
import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UncontrolledError from "ds/UncontrolledError"

import {
  useInvitationAcceptMutation,
  useOwnPendingInvitationsQuery,
} from "components/App/Organization/OrganizationMemberships/organizationInvitationQueries"

interface AcceptableInvitationProps {
  invitation: IOrganizationInvitation
}

function AcceptableInvitation({ invitation }: AcceptableInvitationProps) {
  const acceptInvitationMutation = useInvitationAcceptMutation()

  const onClick = () =>
    acceptInvitationMutation.mutate(invitation.token, {
      onSuccess: () => window.location.reload(),
    })

  return (
    <div>
      <Button
        onClick={onClick}
        variant="contained"
        disabled={acceptInvitationMutation.isLoading}
      >
        <FormattedMessage
          id="appRoot.acceptInvitation"
          defaultMessage="Accept invitation from {organizationName}"
          values={{ organizationName: invitation.organizationName }}
        />
      </Button>
    </div>
  )
}

export default function NoOrganization() {
  const ownPendingInvitationsQuery = useOwnPendingInvitationsQuery()
  const theme = useTheme()

  if (ownPendingInvitationsQuery.isLoading) return <Loader />

  if (ownPendingInvitationsQuery.isError)
    return <UncontrolledError error={ownPendingInvitationsQuery.error} />

  if (ownPendingInvitationsQuery.data.length > 0) {
    return (
      <Stack spacing={1} sx={{ m: 2, mt: 12 }} alignItems="center">
        {ownPendingInvitationsQuery.data.map((invitation) => (
          <AcceptableInvitation invitation={invitation} key={invitation.id} />
        ))}
      </Stack>
    )
  }

  return (
    <Box sx={{ p: 2, mt: 16, display: "flex", justifyContent: "center" }}>
      <Stack
        spacing={2}
        alignItems="center"
        sx={{
          p: 2,
          maxWidth: "600px",
          border: `1px solid ${theme.palette.grey[300]}`,
          borderColor: theme.palette.neutral[300],
          borderRadius: 1,
        }}
      >
        <Typography variant="lgSemi">
          <FormattedMessage id="appRoot.welcome" />
        </Typography>

        <Typography variant="baseNormal" sx={{ alignSelf: "start" }}>
          <FormattedMessage id="appRoot.welcome.bookCall" />
        </Typography>
        <BookCall />
      </Stack>
    </Box>
  )
}

function BookCall() {
  return (
    <Button
      component="a"
      href="https://www.salespath.io?demoOpen=true"
      target="_blank"
      variant="contained"
      rel="noreferrer"
    >
      <FormattedMessage id="appRoot.noOrganization.bookCall" />
    </Button>
  )
}
