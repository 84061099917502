import CloseIcon from "@mui/icons-material/Close"
import { Box } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"

import { IMeetingNoteConfigurationOption } from "api/types/meetingNoteConfigurations"

import Button from "ds/Button"
import IconButton from "ds/IconButton"
import Stack from "ds/Stack"
import TextField from "ds/TextField"

interface Props {
  options: IMeetingNoteConfigurationOption[]
  addOption: () => void
  changeOption: (
    newValue: IMeetingNoteConfigurationOption,
    index: number
  ) => void
  removeOption: (index: number) => void
  hasOptionOther: boolean
  addOptionOther: () => void
  removeOptionOther: () => void
  additionalInfoInput: React.ReactNode
  isMandatoryInput: React.ReactNode
  appearsInSummaryInput: React.ReactNode
}

export default function ArgumentAnswerSelectBuilder({
  options,
  addOption,
  changeOption,
  removeOption,
  hasOptionOther,
  addOptionOther,
  removeOptionOther,
  additionalInfoInput,
  isMandatoryInput,
  appearsInSummaryInput,
}: Props) {
  const intl = useIntl()

  const removeOptionLabel = intl.formatMessage({
    id: "playbookEdit.argumentAnswer.form.selectBuilder.removeOptionLabel",
  })

  const removeOptionOtherLabel = intl.formatMessage({
    id: "playbookEdit.argumentAnswer.form.selectBuilder.removeOptionOtherLabel",
  })

  const optionOtherValue = intl.formatMessage({
    id: "playbookEdit.argumentAnswer.form.selectBuilder.optionOther",
  })

  return (
    <Stack spacing={2}>
      {(options.length > 0 || hasOptionOther) && (
        <Stack
          component="ol"
          sx={{ listStyleType: "none", paddingInlineStart: 0, pl: 2 }}
          spacing={2}
        >
          {options.map((option, index) => (
            <li key={index}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box>{index + 1}.</Box>
                <Box flexGrow={1}>
                  <TextField
                    size="small"
                    fullWidth
                    value={option.label}
                    onChange={(e) => {
                      const label = e.target.value
                      changeOption({ label, value: label }, index)
                    }}
                    autoFocus={index === options.length - 1}
                    onFocus={(e) => e.target.select()}
                  />
                </Box>

                <IconButton
                  aria-label={removeOptionLabel}
                  onClick={() => removeOption(index)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
            </li>
          ))}

          {hasOptionOther && (
            <li>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Box>{options.length + 1}.</Box>
                <Box flexGrow={1}>
                  <TextField
                    size="small"
                    value={optionOtherValue}
                    disabled
                    fullWidth
                  />
                </Box>

                <IconButton
                  aria-label={removeOptionOtherLabel}
                  onClick={() => removeOptionOther()}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
            </li>
          )}
        </Stack>
      )}

      <Stack direction="row" spacing={1.5}>
        <Button variant="text" onClick={() => addOption()}>
          <FormattedMessage
            id="playbookEdit.argumentAnswer.form.selectBuilder.addOption"
            defaultMessage="Add option"
          />
        </Button>

        <Button
          variant="text"
          disabled={hasOptionOther}
          onClick={() => addOptionOther()}
        >
          <FormattedMessage
            id="playbookEdit.argumentAnswer.form.selectBuilder.addOptionOther"
            defaultMessage="add 'Other'"
          />
        </Button>
      </Stack>

      <Stack spacing={1}>
        <div>{additionalInfoInput}</div>
        <div>{isMandatoryInput}</div>
        <div>{appearsInSummaryInput}</div>
      </Stack>
    </Stack>
  )
}
