import { apiSyncReferences } from "api/referencesConfigurations"

import { useInvalidatingMutation } from "utils/hooks/mutations"

import { buildPlaybookKey } from "./playbookQueries"

export function useReferencesConfigurationSyncMutation(argumentTypeId: string) {
  return useInvalidatingMutation(
    buildPlaybookKey(argumentTypeId),
    apiSyncReferences
  )
}
