import { IntlShape } from "react-intl"

import {
  ICRMExternalProperty,
  ICrmPropertyObjectType,
} from "api/types/CRMProperties"
import {
  IArgument,
  IArgumentWithMeetingNoteConfiguration,
} from "api/types/arguments"
import { IMeetingArgumentNote } from "api/types/meetingArgumentNotes"
import { IMeetingNoteConfiguration } from "api/types/meetingNoteConfigurations"
import { IMeeting } from "api/types/meetings"

import { buildNodeFromText } from "ds/RichTextNew/helpers"

import { isDateValid } from "utils/DateValidation"
import { isUrlValid } from "utils/UrlValidation"
import { assertNever } from "utils/assert_never"
import { isEmailValid } from "utils/emailValidation"
import isSubset from "utils/isSubset"
import { isProbabilityValid } from "utils/numberValidation"

import { isCheckboxArgument } from "./arguments"
import { findExternalProperty } from "./meetings"

export function getNoteForArgument(meeting: IMeeting, argument: IArgument) {
  return meeting.meetingArgumentNotes.find(
    (note) => note.argument.id === argument.id
  )
}

export function getArgumentNoteFormKey(
  meeting: IMeeting | null,
  argument: IArgument
) {
  return `${argument.meetingNoteConfiguration?.fieldType}-deal-${
    meeting?.crmDeal?.id
  }-company-${meeting?.crmCompany?.id}-contacts-${meeting?.crmContacts
    ?.map((contact) => contact.id)
    .join("-")}`
}

export function getMeetingArgumentNote(
  meeting: IMeeting | null,
  argument: IArgumentWithMeetingNoteConfiguration
) {
  const existingNote = meeting ? getNoteForArgument(meeting, argument) : null
  if (
    existingNote &&
    noteHasValidValueForConfiguration(
      existingNote,
      argument.meetingNoteConfiguration
    )
  ) {
    return existingNote
  }

  const externalProperty =
    meeting && argument.meetingNoteConfiguration.crmProperty
      ? findExternalProperty(
          meeting,
          argument.meetingNoteConfiguration.crmProperty.externalId,
          argument.meetingNoteConfiguration.crmProperty.objectType
        )
      : null

  if (externalProperty) {
    const noteFromProperty = buildNoteFromProperty(externalProperty, argument)
    if (
      noteFromProperty &&
      noteHasValidValueForConfiguration(
        noteFromProperty,
        argument.meetingNoteConfiguration
      )
    )
      return noteFromProperty
  }

  const newNote = buildEmptyNote(argument)

  return newNote
}

export function noteHasValidValueForConfiguration(
  meetingNote: IMeetingArgumentNote,
  meetingNoteConfiguration: IMeetingNoteConfiguration
): boolean {
  switch (meetingNoteConfiguration.fieldType) {
    case "free_text":
      return meetingNote.fieldType === "free_text"
    case "single_select":
      if (meetingNote.fieldType !== "single_select") return false
      if (meetingNote.value === null) return true
      if (meetingNoteConfiguration.hasOptionOther) return true
      return meetingNoteConfiguration.options.some(
        (option) => option.value === meetingNote.value?.value
      )
    case "multi_select":
      if (meetingNote.fieldType !== "multi_select") return false
      if (!Array.isArray(meetingNote.value)) return false
      if (meetingNoteConfiguration.hasOptionOther) return true
      return isSubset(
        meetingNote.value.map((option) => option.value),
        meetingNoteConfiguration.options.map((option) => option.value)
      )
    case "boolean":
      return meetingNote.fieldType === "boolean"
    case "number":
      if (meetingNote.fieldType !== "number") return false
      if (meetingNote.value === null) return true
      return !isNaN(parseFloat(meetingNote.value))
    case "phone":
      return meetingNote.fieldType === "phone"
    case "email":
      return meetingNote.fieldType === "email"
    case "url":
      return meetingNote.fieldType === "url"
    case "date":
      return meetingNote.fieldType === "date"
    case "probability":
      return meetingNote.fieldType === "probability"
    default:
      return true
  }
}

export function noteHasValidValue(meetingNote: IMeetingArgumentNote) {
  switch (meetingNote.fieldType) {
    case "email":
      if (meetingNote.value === null || meetingNote.value === "") return true
      return isEmailValid(meetingNote.value)
    case "url":
      if (meetingNote.value === null || meetingNote.value === "") return true
      return isUrlValid(meetingNote.value)
    case "date":
      if (meetingNote.value === null || meetingNote.value === "") return true
      return isDateValid(meetingNote.value)
    case "probability":
      if (meetingNote.value === null) return true
      return isProbabilityValid(meetingNote.value)
    default:
      return true
  }
}

function buildNoteFromProperty(
  externalProperty: ICRMExternalProperty,
  argument: IArgumentWithMeetingNoteConfiguration
): IMeetingArgumentNote | null {
  const config = argument.meetingNoteConfiguration

  switch (config.fieldType) {
    case "boolean":
      if (typeof externalProperty.value !== "boolean") return null
      return {
        id: null,
        additionalInfo: null,
        argument,
        fieldType: "boolean",
        value: externalProperty.value,
        rawValue: null,
      }
    case "free_text":
      if (typeof externalProperty.value !== "string") return null
      return {
        id: null,
        additionalInfo: null,
        argument,
        fieldType: "free_text",
        value: buildNodeFromText(externalProperty.value),
        rawValue: externalProperty.value,
      }
    case "multi_select":
      if (!Array.isArray(externalProperty.value)) return null
      return {
        id: null,
        additionalInfo: null,
        argument,
        fieldType: "multi_select",
        value: config.options.filter((option) =>
          externalProperty.value.includes(option.value)
        ),
        rawValue: null,
      }
    case "number":
      if (typeof externalProperty.value !== "string") return null
      return {
        id: null,
        additionalInfo: null,
        argument,
        fieldType: "number",
        value: externalProperty.value,
        rawValue: null,
      }
    case "phone":
      if (typeof externalProperty.value !== "string") return null
      return {
        id: null,
        additionalInfo: null,
        argument,
        fieldType: "phone",
        value: externalProperty.value,
        rawValue: null,
      }
    case "single_select":
      if (typeof externalProperty.value !== "string") return null
      const option = config.options.find(
        (option) => externalProperty.value === option.value
      )
      if (!option) return null
      return {
        id: null,
        additionalInfo: null,
        argument,
        fieldType: "single_select",
        value: option,
        rawValue: null,
      }
    case "email":
      if (typeof externalProperty.value !== "string") return null
      return {
        id: null,
        additionalInfo: null,
        argument,
        fieldType: "email",
        value: externalProperty.value,
        rawValue: null,
      }
    case "url":
      if (typeof externalProperty.value !== "string") return null
      return {
        id: null,
        additionalInfo: null,
        argument,
        fieldType: "url",
        value: externalProperty.value,
        rawValue: null,
      }
    case "date":
      if (typeof externalProperty.value !== "string") return null
      return {
        id: null,
        additionalInfo: null,
        argument,
        fieldType: "date",
        value: externalProperty.value,
        rawValue: null,
      }
    case "probability":
      if (typeof externalProperty.value !== "number") return null
      return {
        id: null,
        additionalInfo: null,
        argument,
        fieldType: "probability",
        value: externalProperty.value,
        rawValue: null,
      }
    default:
      assertNever(config.fieldType)
      return null
  }
}

export function buildEmptyNote(
  argument: IArgumentWithMeetingNoteConfiguration
): IMeetingArgumentNote {
  const defaultAttributes = {
    id: null,
    // fieldType: argument.meetingNoteConfiguration.fieldType,
    additionalInfo: "",
    argument,
  }
  switch (argument.meetingNoteConfiguration.fieldType) {
    case "free_text":
      return {
        ...defaultAttributes,
        fieldType: "free_text",
        value: buildNodeFromText(""),
        rawValue: "",
      }
    case "single_select":
      return {
        ...defaultAttributes,
        fieldType: "single_select",
        value: null,
        rawValue: null,
      }
    case "multi_select":
      return {
        ...defaultAttributes,
        fieldType: "multi_select",
        value: [],
        rawValue: null,
      }
    case "boolean":
      return {
        ...defaultAttributes,
        fieldType: "boolean",
        value: null,
        rawValue: null,
      }
    case "number":
      return {
        ...defaultAttributes,
        fieldType: "number",
        value: null,
        rawValue: null,
      }
    case "phone":
      return {
        ...defaultAttributes,
        fieldType: "phone",
        value: null,
        rawValue: null,
      }
    case "email":
      return {
        ...defaultAttributes,
        fieldType: "email",
        value: null,
        rawValue: null,
      }
    case "url":
      return {
        ...defaultAttributes,
        fieldType: "url",
        value: null,
        rawValue: null,
      }
    case "date":
      return {
        ...defaultAttributes,
        fieldType: "date",
        value: null,
        rawValue: null,
      }
    case "probability":
      return {
        ...defaultAttributes,
        fieldType: "probability",
        value: null,
        rawValue: null,
      }
  }
}

export function formatMeetingArgumentNote(
  meetingArgumentNote: IMeetingArgumentNote,
  intl: IntlShape
): string {
  const trueTranslation = intl.formatMessage({
    id: "common.yes",
    defaultMessage: "Yes",
  })

  const falseTranslation = intl.formatMessage({
    id: "common.no",
    defaultMessage: "No",
  })

  const checkedTranslation = intl.formatMessage({
    id: "playbook.meeting.argumentNotes.checked",
  })

  const notCheckedTranslation = intl.formatMessage({
    id: "playbook.meeting.argumentNotes.notChecked",
  })

  switch (meetingArgumentNote.fieldType) {
    case "free_text":
      return meetingArgumentNote.rawValue || ""
    case "single_select":
      return meetingArgumentNote.value?.label || ""
    case "multi_select":
      return meetingArgumentNote.value.map((option) => option.label).join(", ")
    case "boolean":
      return meetingArgumentNote.value ? trueTranslation : falseTranslation
    case "number":
      return meetingArgumentNote.value || ""
    case "phone":
      return meetingArgumentNote.value || ""
    case "email":
      return meetingArgumentNote.value || ""
    case "url":
      return meetingArgumentNote.value || ""
    case "date":
      return meetingArgumentNote.value
        ? intl.formatDate(meetingArgumentNote.value)
        : ""
    case "probability":
      return meetingArgumentNote.value
        ? `${(meetingArgumentNote.value * 100).toFixed(2)}%`
        : ""
    case "checkbox":
      return meetingArgumentNote.value
        ? checkedTranslation
        : notCheckedTranslation
  }
}

export function findMeetingArgumentNoteFromArgument(
  meetingArgumentNotes: IMeetingArgumentNote[],
  argument: IArgument
) {
  return meetingArgumentNotes.find(
    (meetingArgumentNote) => meetingArgumentNote.argument.id === argument.id
  )
}

export function isMeetingArgumentNoteEmpty(
  meetingArgumentNote: IMeetingArgumentNote
) {
  if (meetingArgumentNote.fieldType === "multi_select") {
    return meetingArgumentNote.value.length === 0
  }
  if (meetingArgumentNote.fieldType === "boolean") {
    return meetingArgumentNote.value === null
  }
  if (meetingArgumentNote.fieldType === "free_text") {
    return (
      meetingArgumentNote.rawValue === null ||
      meetingArgumentNote.rawValue === ""
    )
  }
  if (meetingArgumentNote.fieldType === "number") {
    return (
      meetingArgumentNote.value === null || meetingArgumentNote.value === ""
    )
  }
  if (meetingArgumentNote.fieldType === "phone") {
    return (
      meetingArgumentNote.value === null || meetingArgumentNote.value === ""
    )
  }
  if (meetingArgumentNote.fieldType === "single_select") {
    return (
      meetingArgumentNote.value === null ||
      meetingArgumentNote.value.value === ""
    )
  }
  if (meetingArgumentNote.fieldType === "email") {
    return (
      meetingArgumentNote.value === null || meetingArgumentNote.value === ""
    )
  }
  if (meetingArgumentNote.fieldType === "url") {
    return (
      meetingArgumentNote.value === null || meetingArgumentNote.value === ""
    )
  }
  if (meetingArgumentNote.fieldType === "date") {
    return (
      meetingArgumentNote.value === null || meetingArgumentNote.value === ""
    )
  }
  return false
}

export function filterMandatoryAnswersNotFilled(
  _arguments: IArgument[],
  meeting: IMeeting
) {
  return _arguments.filter((argument) =>
    mandatoryAnswerNotFilled(argument, meeting)
  )
}

export function filterUncheckedCheckboxes(
  _arguments: IArgument[],
  meeting: IMeeting
) {
  return _arguments.filter(
    (argument) =>
      isCheckboxArgument(argument) && !isArgumentChecked(argument, meeting)
  )
}

export function argumentHasMeetingNoteConfiguration(
  argument: IArgument
): argument is IArgumentWithMeetingNoteConfiguration {
  return !!argument.meetingNoteConfiguration
}

export function argumentHasMandatoryMeetingNoteConfiguration(
  argument: IArgument
): argument is IArgumentWithMeetingNoteConfiguration {
  return !!argument.meetingNoteConfiguration?.isMandatory
}

export function mandatoryAnswerNotFilled(
  argument: IArgument,
  meeting: IMeeting | null
) {
  if (!argumentHasMandatoryMeetingNoteConfiguration(argument)) return false
  if (!meeting) return true // Consider missing answer if no meeting ongoing, so that users know it's mandatory

  const meetingArgumentNote = getMeetingArgumentNote(meeting, argument)

  return isMeetingArgumentNoteEmpty(meetingArgumentNote)
}

export function filterAnswersWithInvalidValue(
  meetingArgumentNotes: IMeetingArgumentNote[]
) {
  return meetingArgumentNotes.filter(
    (meetingArgumentNote) => !noteHasValidValue(meetingArgumentNote)
  )
}

export function getArgumentNotesCRMPropertyObjectTypes(
  meetingArgumentNotes: IMeetingArgumentNote[]
): ICrmPropertyObjectType[] {
  return meetingArgumentNotes
    .map((note) =>
      isMeetingArgumentNoteEmpty(note)
        ? undefined
        : note.argument.meetingNoteConfiguration?.crmProperty?.objectType
    )
    .filter(
      (objectType): objectType is ICrmPropertyObjectType =>
        objectType !== undefined
    )
}

export function isArgumentChecked(argument: IArgument, meeting: IMeeting) {
  const note = meeting ? getNoteForArgument(meeting, argument) : null
  return !!note?.value
}

export function getNotesAppearingInSummary(meeting: IMeeting) {
  return meeting.meetingArgumentNotes.filter(
    (note) => !!note.argument.meetingNoteConfiguration?.appearsInSummary
  )
}
