import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import Alert from "ds/Alert"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import MissingAnswer from "./MissingAnswer"

interface Props {
  argumentsWithMissingAnswer: IArgument[]
  onReplyClick: () => void
}

export default function MandatoryAnswersNotfilled({
  argumentsWithMissingAnswer,
  onReplyClick,
}: Props) {
  return (
    <Stack spacing={2}>
      <Alert severity="error">
        <Typography variant="smNormal">
          <FormattedMessage id="playbook.meeting.missingMandatoryAnswers" />
        </Typography>
      </Alert>

      {argumentsWithMissingAnswer.slice(0, 5).map((argument) => (
        <MissingAnswer
          key={argument.id}
          argument={argument}
          onReplyClick={onReplyClick}
        />
      ))}
    </Stack>
  )
}
