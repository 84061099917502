import { useQuery } from "@tanstack/react-query"

import { getFrontendConfig } from "api/frontendConfig"

import Loader from "ds/Loader"
import UncontrolledError from "ds/UncontrolledError"

const useFrontendConfigQuery = () =>
  useQuery(["frontendConfig"], getFrontendConfig, { staleTime: Infinity })

export const useConfig = () => {
  const { data } = useFrontendConfigQuery()
  if (!data) throw new Error("Config not loaded")
  return data
}

interface Props {
  children: JSX.Element
}

export function WithLoadedConfig({ children }: Props) {
  const { isLoading, error } = useFrontendConfigQuery()

  if (isLoading) return <Loader />
  if (error) return <UncontrolledError error={error} />
  return children
}
