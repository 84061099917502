// TODO TEST
import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

import { acceptOrganizationInvitation } from "api/organizationInvitations"

import ControlledError from "ds/ControlledError"
import Loader from "ds/Loader"
import UncontrolledError from "ds/UncontrolledError"

import { getOrganizationPath } from "services/organizations"

export default function AcceptOrganizationInvitation() {
  const params = useParams()
  const invitationToken = params.invitationToken!
  const [error, setError] = useState<any | null>(null)

  useEffect(() => {
    const tryAccept = async () => {
      try {
        const invitation = await acceptOrganizationInvitation(invitationToken)
        window.location.assign(getOrganizationPath(invitation.organizationId)) // Hard refresh to reload the user
      } catch (e) {
        setError(e)
        throw e
      }
    }
    tryAccept()
  }, [invitationToken])

  const responsePayload = error?.response?.data

  if (responsePayload?.error === "invitation_not_pending") {
    return (
      <ControlledError showNav>
        <FormattedMessage
          id="invitation.errors.invitation_not_pending"
          defaultMessage="This invitation has expired."
        />
      </ControlledError>
    )
  }
  if (responsePayload?.error === "email_not_matching") {
    return (
      <ControlledError showNav>
        <FormattedMessage
          id="invitation.errors.email_not_matching"
          defaultMessage="Log out and sign in with the email from the invitation."
          values={{
            b: (chunks) => (
              <Box component="span" sx={{ fontWeight: 500 }}>
                {chunks}
              </Box>
            ),
            invitationEmail: responsePayload.data.invitationEmail,
            userEmail: responsePayload.data.userEmail,
          }}
        />
      </ControlledError>
    )
  }
  // Unknown error
  if (error) return <UncontrolledError error={error} />

  return <Loader />
}
