// Div that takes the whole space available and centers its content vertically and horizontally
import { Box } from "@mui/material"
import styled from "styled-components"

const CenteringContainer = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default CenteringContainer
