import axios from "./axios"
import { IOrganizationInvitation } from "./types/organizationInvitations"

export const apiGetOwnPendingInvitations = ({
  signal,
}: {
  signal?: AbortSignal
}): Promise<IOrganizationInvitation[]> =>
  axios
    .get("/api/organization_invitations/pending", { signal })
    .then((res) => res.data)

export const acceptOrganizationInvitation = (
  invitationToken: string
): Promise<IOrganizationInvitation> =>
  axios
    .get("api/organization_invitations/accept", {
      params: { invitationToken },
    })
    .then((res) => res.data)

export const resendInvitation = (invitationId: string): Promise<undefined> =>
  axios
    .patch(`api/organization_invitations/${invitationId}/resend`)
    .then((res) => res.data)

export const revokeInvitation = (invitationId: string): Promise<undefined> =>
  axios
    .patch(`api/organization_invitations/${invitationId}/revoke`)
    .then((res) => res.data)
