import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { apiGetReferencesImportExampleCsvUrl } from "api/referencesImports"
import { ICreateReferencesImportResponse } from "api/types/referencesImports"

import Alert from "ds/Alert"
import Button from "ds/Button"
import Modal from "ds/Modal"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { usePlaybook } from "../PlaybookProvider"
import { useReferencesImportCreateMutation } from "../queries/referencesImportQueries"

interface Props {
  argumentTypeId: string
}

export default function ImportReferencesCSV(props: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const { playbook } = usePlaybook()
  // TODO pass argumentTypeId instead of playbookId once we load TPs by tab
  const createReferencesImportMutation = useReferencesImportCreateMutation(
    playbook.id
  )
  const intl = useIntl()

  const referencesImportResult = createReferencesImportMutation.data

  function onSubmit() {
    if (!file) return

    const formData = new FormData()
    formData.append("file", file)
    formData.append("argument_type_id", props.argumentTypeId)

    createReferencesImportMutation.mutate(formData, {
      onSuccess: (data) => {
        setFile(null)
        if (data.success) setIsModalOpen(false)
      },
    })
  }

  return (
    <>
      <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
        <FormattedMessage id="playbookEdit.argumentTypes.references.importCSV.title" />
      </Button>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Stack spacing={2}>
          <Typography variant="baseSemi">
            <FormattedMessage id="playbookEdit.argumentTypes.references.importCSV.title" />
          </Typography>

          <Alert severity="info">
            <Stack spacing={2}>
              <Box>
                <FormattedMessage
                  id="playbookEdit.argumentTypes.references.importCSV.explanation"
                  values={{
                    b: (chunks) => (
                      <Typography variant="smSemi">{chunks}</Typography>
                    ),
                  }}
                />
              </Box>

              <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    window.open(apiGetReferencesImportExampleCsvUrl, "_blank")
                  }}
                >
                  <FormattedMessage id="playbookEdit.argumentTypes.references.importCSV.downloadExample" />
                </Button>
              </Box>

              <Box>
                <iframe
                  width="560"
                  height="315"
                  // src="https://www.youtube.com/embed/w15oWDh02K4?si=COAQLY65dTSfufTF"
                  src={intl.formatMessage({
                    id: "playbookEdit.argumentTypes.references.importCSV.videoUrl",
                  })}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  style={{ border: "none", borderRadius: "4px" }}
                ></iframe>
              </Box>
            </Stack>
          </Alert>

          {referencesImportResult?.success === false && (
            <Alert severity="error">
              <ReferencesImportError result={referencesImportResult} />
            </Alert>
          )}

          <input
            type="file"
            accept=".csv"
            onChange={(event) => {
              setFile(event.target.files ? event.target.files[0] : null)
            }}
          />

          <Box>
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={!file || createReferencesImportMutation.isLoading}
            >
              {createReferencesImportMutation.isLoading ? (
                <FormattedMessage id="playbookEdit.argumentTypes.references.importCSV.loading" />
              ) : (
                <FormattedMessage id="playbookEdit.argumentTypes.references.importCSV.title" />
              )}
            </Button>
          </Box>
        </Stack>
      </Modal>
    </>
  )
}

function ReferencesImportError({
  result,
}: {
  result: ICreateReferencesImportResponse
}) {
  if (result.success) return <></>
  switch (result.reason) {
    case "cannot_parse_csv":
      return (
        <FormattedMessage id="playbookEdit.argumentTypes.references.importCSV.error.cannot_parse_csv" />
      )
    case "missing_headers":
      return (
        <>
          <FormattedMessage id="playbookEdit.argumentTypes.references.importCSV.error.missing_headers" />
          &nbsp;
          <Typography variant="smBold">
            {result.missingHeaders.join(", ")}
          </Typography>
        </>
      )
    case "unknown_headers":
      return (
        <>
          <FormattedMessage id="playbookEdit.argumentTypes.references.importCSV.error.unknown_headers" />
          &nbsp;
          <Typography variant="smBold">
            {result.unknownHeaders.join(", ")}
          </Typography>
        </>
      )
    case "unknown_segments":
      return (
        <>
          <FormattedMessage id="playbookEdit.argumentTypes.references.importCSV.error.unknown_segments" />
          &nbsp;
          <Typography variant="smBold">
            {result.unknownSegments.join(", ")}
          </Typography>
        </>
      )
  }
}
