import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import { ListStyleType } from "@udecode/plate-indent-list"
import { useIntl } from "react-intl"

import ListButton from "./ListButton"

interface Props {
  disabled: boolean
}

export default function OrderedListButton(props: Props) {
  const intl = useIntl()

  return (
    <ListButton
      nodeType={ListStyleType.Decimal}
      {...props}
      label={intl.formatMessage({
        id: "richtext.orderedList",
        defaultMessage: "Ordered list",
      })}
    >
      <FormatListNumberedIcon sx={{ height: "16px", width: "16px" }} />
    </ListButton>
  )
}
