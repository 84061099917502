import { Box } from "@mui/material"
import React, { HTMLAttributes, useEffect, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"

import { Upload, UploadProgress } from "../../plugins/cloud/index"

export function ProgressBar({
  upload,
  className,
  ...props
}: {
  upload: UploadProgress
} & HTMLAttributes<HTMLDivElement>) {
  const [width, setWidth] = useState<null | number>(null)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) setWidth(ref.current.offsetWidth)
  }, [])

  /**
   * This formula looks a little funny because we want the `0` value of the
   * progress bar to have a width that is still the height of the progress bar.
   *
   * This is for a few reasons:
   *
   * 1. We want the zero point to start with the progress bar being a circle
   * 2. If we want rounded edges, if the width is shorter than the height,
   *    we get an oval instead of a circle
   * 3. The halfway point looks visually wrong because of the circle progress
   *    bar when it is technically at the halfway point.
   */
  const progressWidth =
    width == null
      ? 0
      : (upload.sentBytes / upload.totalBytes) * (width - 16) + 16

  return (
    <Box
      ref={ref}
      sx={{
        height: 16,
        borderRadius: 1,
        bgcolor: (theme) => theme.palette.grey[100],
      }}
      {...props}
    >
      <Box
        sx={{
          height: 16,
          borderRadius: 1,
          bgcolor: (theme) => theme.palette.primary[200],
          width: progressWidth,
        }}
      />
    </Box>
  )
}

export function FailBar({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <Box
      sx={{
        height: 16,
        borderRadius: 1,
        border: (theme) => `solid 1px ${theme.palette.error.main}`,
        textAlign: "center",
        fontWeight: (theme) => theme.typography.smSemi.fontWeight,
        textTransform: "uppercase",
        color: "white",
      }}
      {...props}
    />
  )
}

export default function CloudAttachmentStatusBar(props: {
  upload: Upload
  children?: React.ReactNode
}) {
  const { upload, children } = props
  switch (upload.status) {
    case "progress": {
      return <ProgressBar upload={upload} />
    }
    case "error": {
      return (
        <FailBar>
          <FormattedMessage id="richtext.upload.error" />
        </FailBar>
      )
    }
    case "not-found": {
      return (
        <FailBar>
          <FormattedMessage id="richtext.upload.notFound" />
        </FailBar>
      )
    }
    case "success": {
      return <>{children}</> || null
    }
    default: {
      throw new Error(`Should be unreachable`)
    }
  }
}
