import { useQuery } from "@tanstack/react-query"

import {
  apiCRMGetCurrentIntegration,
  apiFindOrCreateIntegration,
  apiGetIntegration,
  apiRevokeIntegration,
} from "api/crm"

import { useUpdateMutation } from "utils/hooks/mutations"

function buildCurrentIntegrationKey(businessUnitId: string) {
  return ["currentIntegration", businessUnitId]
}

export function useApplicationIntegrationQuery(businessUnitId: string) {
  return useQuery(buildCurrentIntegrationKey(businessUnitId), ({ signal }) =>
    apiCRMGetCurrentIntegration({ businessUnitId }, { signal })
  )
}

export function useApplicationIntegrationFindOrCreateQuery(
  businessUnitId: string,
  name: string,
  authorizationCode: string,
  sandbox: boolean
) {
  return useQuery(buildCurrentIntegrationKey(businessUnitId), ({ signal }) =>
    apiFindOrCreateIntegration(
      { businessUnitId, sandbox, name, authentication: { authorizationCode } },
      { signal }
    )
  )
}

export function useApplicationIntegrationStatusQueryPoller(
  integrationId: string,
  businessUnitId: string
) {
  return useQuery(
    buildCurrentIntegrationKey(businessUnitId),
    () => apiGetIntegration(integrationId),
    {
      refetchInterval: (data) => {
        return !!data && ["success", "failure"].includes(data.status)
          ? false
          : 5000
      },
    }
  )
}

export function useApplicationIntegrationRevokeMutation(
  businessUnitId: string
) {
  return useUpdateMutation(
    buildCurrentIntegrationKey(businessUnitId),
    apiRevokeIntegration,
    () => null
  )
}
