import { INotification, NOTIFICATION_TYPE } from "api/types/notifications"

import ArgumentCommentNotification from "./NotificationTypes/ArgumentCommentNotification"
import ArgumentCommentResolvedNotification from "./NotificationTypes/ArgumentCommentResolvedNotification"
import CrmPropertyPushFailureNotification from "./NotificationTypes/CrmPropertiesPushFailureNotification"

interface Props {
  notification: INotification
}

export default function Notification({ notification }: Props) {
  switch (notification.notificationType) {
    case NOTIFICATION_TYPE.argumentComment:
      return <ArgumentCommentNotification notification={notification} />
    case NOTIFICATION_TYPE.crmPropertiesPushFailure:
      return <CrmPropertyPushFailureNotification notification={notification} />
    case NOTIFICATION_TYPE.argumentCommentResolved:
      return <ArgumentCommentResolvedNotification notification={notification} />
  }
}
