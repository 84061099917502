import Bowser from "bowser"

const isMobileDevice = (): boolean => {
  const {
    platform: { type: platformType },
  } = Bowser.parse(window.navigator.userAgent)

  return platformType === "mobile"
}

export default isMobileDevice
