import { useQuery } from "@tanstack/react-query"
import { useIntl } from "react-intl"

import { apiGetMe, apiUpdateMe } from "api/me"
import { ICurrentUser, ICurrentUserResponse } from "api/types/me"

import { useUpdateMutation } from "utils/hooks/mutations"

function buildMeKey() {
  return ["me"]
}

export function useMeQuery() {
  const intl = useIntl()
  return useQuery(buildMeKey(), () => apiGetMe(intl.locale), {
    staleTime: Infinity,
  })
}

export function useMeMutation() {
  return useUpdateMutation(
    buildMeKey(),
    apiUpdateMe,
    (result, prev: ICurrentUserResponse | undefined) =>
      prev && {
        ...prev,
        user: result.user,
      }
  )
}

export default function useMe(): ICurrentUser {
  const { data } = useMeQuery()
  if (!data) {
    throw new Error("The user has not been loaded yet")
  }
  return data.user
}
