import { Dictionary } from "lodash"
import { useState } from "react"

import { IPickedSegment } from "api/types/argumentSegments"

export default function useArgumentSearchSegmentFilters(
  initialSegmentFilters: Dictionary<string[]>
) {
  const [segmentFilters, setSegmentFilters] = useState(initialSegmentFilters)

  function pickSegments(pickedSegments: IPickedSegment[]) {
    let newSegments: Dictionary<string[]> = {}
    pickedSegments.forEach(({ argumentSegmentationId, argumentSegmentIds }) => {
      newSegments[argumentSegmentationId] = argumentSegmentIds
    })
    setSegmentFilters({ ...segmentFilters, ...newSegments })
  }

  return [segmentFilters, pickSegments] as const
}
