import MuiTab, { TabProps } from "@mui/material/Tab"
import MuiTabs, { TabsProps } from "@mui/material/Tabs"
import { forwardRef } from "react"

export type { TabsProps }

export default forwardRef(function RoundTabs(
  { sx, ...props }: TabsProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <MuiTabs
      sx={{
        p: 0.5,
        width: "100%",
        borderRadius: 1,
        bgcolor: (theme) => theme.palette.grey[100],
        boxShadow: 1,
        minHeight: 0,
        "& .MuiTabScrollButton-root svg": {
          fill: (theme) => theme.palette.grey[700],
        },
        "& .MuiTabs-indicator": {
          display: "none",
        },
        ...sx,
      }}
      ref={ref}
      variant="scrollable"
      allowScrollButtonsMobile
      TabScrollButtonProps={{ sx: { width: "20px" } }} // default is 40px
      {...props}
    />
  )
})

export const RoundTab = forwardRef(function RoundTab(
  { sx, ...props }: TabProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <MuiTab
      sx={{
        fontSize: (theme) => theme.typography.smMed.fontSize,
        lineHeight: (theme) => theme.typography.smMed.lineHeight,
        fontWeight: (theme) => theme.typography.smMed.fontWeight,
        textTransform: "none",
        color: (theme) => theme.palette.text.primary,
        bgcolor: "transparent",
        // bgcolor: "transparent",
        "&.Mui-selected": {
          color: (theme) => theme.palette.primary.main,
          bgcolor: (theme) => theme.palette.common.white,
        },
        py: 0.75,
        px: 1.5,
        minHeight: 0,
        borderRadius: 1,
      }}
      ref={ref}
      {...props}
    />
  )
})
