import { Draggable } from "@hello-pangea/dnd"
import { Box } from "@mui/material"
import styled from "styled-components"

import { IArgumentField } from "api/types/argumentFields"

import DragAndDropHandle from "ds/DragAndDropHandle"
import MenuItem from "ds/MenuItem"

import ArgumentFieldName from "../ArgumentFieldName"
import ArgumentFieldActions from "./ArgumentFieldActions"

const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.background.paper};

  &:hover {
    .argument-field-actions,
    .drag-handle {
      opacity: 1;
    }
  }
`

const DragAndDropHandleContainer = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.2;
  transition: ${({ theme }) => theme.transitions.create("opacity")};
`

const FloatingActionsContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.2;
  transition: ${({ theme }) => theme.transitions.create("opacity")};
`

interface Props {
  argumentField: IArgumentField
  onClick: () => void
  index: number
}

export default function ArgumentFieldResult({
  argumentField,
  onClick,
  index,
}: Props) {
  return (
    <Draggable
      draggableId={argumentField.id}
      index={index}
      isDragDisabled={argumentField.archived}
    >
      {(providedDraggable) => (
        <Container
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
        >
          <MenuItem
            onClick={onClick}
            sx={{ pr: 5, pl: 4 }}
            disabled={argumentField.archived}
          >
            <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <ArgumentFieldName argumentField={argumentField} />
            </Box>
          </MenuItem>

          <DragAndDropHandleContainer className="drag-handle">
            <DragAndDropHandle
              dragHandleProps={providedDraggable.dragHandleProps}
              disabled={argumentField.archived}
            />
          </DragAndDropHandleContainer>

          <FloatingActionsContainer className="argument-field-actions">
            <ArgumentFieldActions argumentField={argumentField} />
          </FloatingActionsContainer>
        </Container>
      )}
    </Draggable>
  )
}
