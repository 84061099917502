import { useState } from "react"
import { FormattedMessage } from "react-intl"

import useMe, { useMeMutation } from "authentication/useMe"

import Checkbox from "ds/Checkbox"
import FormControl from "ds/FormControl"
import FormControlLabel from "ds/FormControlLabel"
import { BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"

export default function DisplayPreferences() {
  const user = useMe()
  const meMutation = useMeMutation()
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)

  const submit = (newPreference: boolean) =>
    meMutation.mutate(
      { prefersUniqueArgumentGroupOpen: newPreference },
      {
        onSuccess: () => setSuccessSnackOpen(true),
        onError: () => setErrorSnackOpen(true),
      }
    )

  return (
    <div>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={user.prefersUniqueArgumentGroupOpen}
              onChange={() => submit(!user.prefersUniqueArgumentGroupOpen)}
            />
          }
          label={
            <FormattedMessage id="settings.displayPreferences.prefersUniqueArgumentGroupOpen.label" />
          }
          disabled={meMutation.isLoading}
        ></FormControlLabel>
      </FormControl>
      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />
      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
    </div>
  )
}
