import { FormattedMessage, useIntl } from "react-intl"

import { IArgumentSegmentRuleOperator } from "api/types/arguments"

import MenuItem from "ds/MenuItem"
import Select, { SelectProps } from "ds/Select"

import SegmentRuleOperator from "./SegmentRuleOperator"

const operators = ["ALL", "ONLY", "EXCEPT"] as const

interface Props {
  value: IArgumentSegmentRuleOperator | ""
  onChange: SelectProps<IArgumentSegmentRuleOperator | "">["onChange"]
}

export default function SegmentRuleOperatorSelect({ value, onChange }: Props) {
  const intl = useIntl()

  const label = intl.formatMessage({
    id: "playbookEdit.argument.segmentRules.operator.label",
    defaultMessage: "Operator for this rule",
  })

  return (
    <Select<IArgumentSegmentRuleOperator | "">
      value={value}
      onChange={onChange}
      sx={{ fontSize: "0.8rem" }}
      aria-label={label}
      displayEmpty
      renderValue={(value) => {
        if (value === "") {
          return (
            <FormattedMessage
              id={"playbookEdit.argument.segmentRules.operator.MULTIPLE"}
              defaultMessage={"Multiple"}
            />
          )
        } else {
          return (
            <FormattedMessage
              id={`playbookEdit.argument.segmentRules.operator.${value}`}
              defaultMessage={value}
            />
          )
        }
      }}
    >
      {operators.map((operator) => (
        <MenuItem key={operator} value={operator} sx={{ fontSize: "0.8rem" }}>
          <SegmentRuleOperator operator={operator} />
        </MenuItem>
      ))}
    </Select>
  )
}
