import { ICRMCompany, ICRMCompanySearchResult } from "api/types/CRMCompanies"
import { CRM_PROPERTY_COMPANY_OBJECT_TYPE } from "api/types/CRMProperties"
import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IPickedSegment } from "api/types/argumentSegments"

import { filterNonArchived } from "./archivable"
import { findArgumentSegmentsByValue } from "./argumentSegmentations"

export function buildCrmCompanySearchResult(
  crmCompany: ICRMCompany
): ICRMCompanySearchResult {
  return {
    origin: "DB",
    id: crmCompany.id,
    website: crmCompany.website,
    integrationName: crmCompany.integrationName,
    name: crmCompany.name,
    crmCompany,
  }
}

export function findCrmCompanyProperty(
  crmCompany: ICRMCompany,
  propertyId: string
) {
  return crmCompany.properties.find((property) => property.id === propertyId)
}

export function selectSegmentIdsMatchingCompanyProperties(
  crmCompany: ICRMCompany,
  argumentSegmentations: IArgumentSegmentation[]
): IPickedSegment[] {
  return argumentSegmentations.flatMap((segmentation) => {
    if (!segmentation.crmProperty) return []
    if (
      segmentation.crmProperty.objectType !== CRM_PROPERTY_COMPANY_OBJECT_TYPE
    )
      return []
    const crmCompanyProperty = findCrmCompanyProperty(
      crmCompany,
      segmentation.crmProperty.externalId
    )
    if (!crmCompanyProperty) return []
    const segments = findArgumentSegmentsByValue(
      filterNonArchived(segmentation.argumentSegments),
      [crmCompanyProperty.value].flat()
    )

    return [
      {
        argumentSegmentationId: segmentation.id,
        argumentSegmentIds: segments.map((segment) => segment.id),
      },
    ]
  })
}
