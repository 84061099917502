import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import TableCell from "ds/TableCell"
import TableHead from "ds/TableHead"
import TableRow from "ds/TableRow"

const TableHeadCell = styled(TableCell)`
  font-size: 0.9rem;
`

export default function MeetingTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableHeadCell>
          <FormattedMessage id="meetings.company" />
        </TableHeadCell>
        <TableHeadCell>
          <FormattedMessage id="meetings.contact" />
        </TableHeadCell>
        <TableHeadCell>
          <FormattedMessage id="meetings.deal" />
        </TableHeadCell>
        <TableHeadCell>
          <FormattedMessage
            id="meetings.meetingType"
            defaultMessage="Meeting type"
          />
        </TableHeadCell>
        <TableHeadCell>
          <FormattedMessage
            id="meetings.index.startTime"
            defaultMessage="Start time"
          />
        </TableHeadCell>
        <TableHeadCell>
          <FormattedMessage
            id="meetings.index.meetingCRMLink"
            defaultMessage="CRM link"
          />
        </TableHeadCell>
        <TableHeadCell>
          <FormattedMessage
            id="meetings.index.meetingAuthor"
            defaultMessage="Held by"
          />
        </TableHeadCell>
      </TableRow>
    </TableHead>
  )
}
