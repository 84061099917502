import { IArgumentType } from "api/types/argumentTypes"

import { ensureValidArgumentTypeId } from "services/argumentTypes"

import useSearchParam from "utils/hooks/useSearchParam"

export const activeTypeFiltersKey = "typeId"

export function useRawTypeIdParam() {
  return useSearchParam<string>(activeTypeFiltersKey)
}

export default function useTypeIdParam(argumentTypes: IArgumentType[]) {
  const [typeIdParam, setTypeIdParam] = useRawTypeIdParam()
  const validTypeId = ensureValidArgumentTypeId(argumentTypes, typeIdParam)
  return [validTypeId, setTypeIdParam] as const
}
