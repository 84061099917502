import { ICRMCompanySearchResult } from "api/types/CRMCompanies"
import { ICRMContactSearchResult } from "api/types/CRMContacts"
import { ICRMMeetingType } from "api/types/CRMMeetingTypes"
import { IUser } from "api/types/users"

import FiltersContainer from "ds/FiltersContainer"

import MeetingUsersSelector from "components/App/BusinessUnit/BusinessUnitUsers/MeetingUsersSelector"
import CRMMeetingTypeSelector from "components/App/CRM/MeetingTypes/CRMMeetingTypeSelector"
import CRMCompanySelector from "components/App/CRM/companies/CRMCompanySelector"
import CRMContactSelector from "components/App/CRM/contacts/CRMContactSelector"

export interface IMeetingsFilters {
  companyFilter?: ICRMCompanySearchResult | null
  contactFilter?: ICRMContactSearchResult | null
  meetingTypeFilter?: ICRMMeetingType | null
  userFilter?: IUser | null
}

interface Props {
  businessUnitId: string
  filters: IMeetingsFilters
  onChange: (newFilters: IMeetingsFilters) => void
  disabled: boolean
}

export default function MeetingsFilters({
  businessUnitId,
  filters,
  onChange,
  disabled = false,
}: Props) {
  return (
    <FiltersContainer>
      <CRMCompanySelector<false, false, never> // Weird that the compiler doesn't infer automatically
        businessUnitId={businessUnitId}
        externalSearchDisabled={true}
        value={filters.companyFilter || null}
        multiple={false}
        onChange={(_event, newCompanyFilter) =>
          onChange({
            ...filters,
            companyFilter: newCompanyFilter,
          })
        }
        disabled={disabled}
      />

      <CRMContactSelector<false, false, never> // Weird that the compiler doesn't infer automatically
        businessUnitId={businessUnitId}
        externalSearchDisabled={true}
        value={filters.contactFilter || null}
        onChange={(_event, newContactFilter) =>
          onChange({
            ...filters,
            contactFilter: newContactFilter,
          })
        }
        disabled={disabled}
        companyId={filters.companyFilter?.id || null}
      />

      <CRMMeetingTypeSelector<false> // Weird that the compiler doesn't infer automatically
        businessUnitId={businessUnitId}
        value={filters.meetingTypeFilter || null}
        onChange={(_event, newMeetingTypeFilter) =>
          onChange({ ...filters, meetingTypeFilter: newMeetingTypeFilter })
        }
        disabled={disabled}
      />

      <MeetingUsersSelector
        businessUnitId={businessUnitId}
        multiple={false}
        value={filters.userFilter || null}
        onChange={(_event, newUserFilter) =>
          onChange({ ...filters, userFilter: newUserFilter })
        }
      />
    </FiltersContainer>
  )
}
