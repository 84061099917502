import { Dictionary, isEmpty } from "lodash"
import { createContext, useContext } from "react"

import { IArgumentPersonalNote } from "api/types/argumentPersonalNotes"
import { IPickedSegment } from "api/types/argumentSegments"
import { IPlaybook } from "api/types/playbooks"
import { IUser } from "api/types/users"

import { filterNonArchived } from "services/archivable"

import useLocalStorageState from "utils/hooks/useLocalStorageState"

import useViewSettings, { IViewSettings } from "./ViewSettings/useViewSettings"
import useSegmentsParam from "./useSegmentsParam"
import useTypeIdParam from "./useTypeIdParam"

interface ProvidedValue {
  playbook: IPlaybook
  editMode: boolean
  users: IUser[]
  argumentPersonalNotes: IArgumentPersonalNote[]
  activeTypeId: string
  setActiveTypeId: (typeId: string) => void
  segmentFilters: Dictionary<string[]>
  pickSegments: (pickedSegments: IPickedSegment[]) => void
  canEdit: boolean
  viewSettings: IViewSettings
  readOnly: boolean
  storePreMeetingSegments: () => void
  restorePreMeetingSegments: () => void
}

const PlaybookContext = createContext<ProvidedValue | null>(null)

export function useUnsafePlaybook() {
  return useContext(PlaybookContext)
}

export function usePlaybook() {
  const providedValue = useUnsafePlaybook()
  if (providedValue) return providedValue
  throw new Error(
    "usePlaybok was called without being wrapped in a PlaybookProvider"
  )
}

interface Props {
  playbook: IPlaybook
  argumentPersonalNotes?: IArgumentPersonalNote[]
  canEdit: boolean
  users: IUser[]
  editMode?: boolean
  children: React.ReactNode
  readOnly?: boolean
}

export default function PlaybookProvider({
  playbook,
  canEdit,
  users,
  argumentPersonalNotes = [],
  editMode = false,
  children,
  readOnly = false,
}: Props) {
  const viewSettings = useViewSettings(playbook.id)

  const [activeTypeId, setActiveTypeId] = useTypeIdParam(playbook.argumentTypes)

  const [segmentFilters, pickSegments, setSegmentsParam] = useSegmentsParam(
    filterNonArchived(playbook.argumentSegmentations)
  )

  const [preMeetingSegments, setPreMeetingSegments] = useLocalStorageState(
    `preMeetingSegments-${playbook.id}`,
    {}
  )

  function storePreMeetingSegments() {
    setPreMeetingSegments(segmentFilters)
  }

  function restorePreMeetingSegments() {
    // For some weird reason, segments cannot be reset to an empty object, so we set them to none instead
    setSegmentsParam(isEmpty(preMeetingSegments) ? "none" : preMeetingSegments)
  }

  return (
    <PlaybookContext.Provider
      value={{
        playbook,
        editMode,
        users,
        argumentPersonalNotes,
        activeTypeId,
        setActiveTypeId,
        segmentFilters,
        pickSegments,
        canEdit,
        viewSettings,
        readOnly,
        storePreMeetingSegments,
        restorePreMeetingSegments,
      }}
    >
      {children}
    </PlaybookContext.Provider>
  )
}
