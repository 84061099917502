import axios from "./axios"
import {
  IArgumentFieldCreateParams,
  IArgumentFieldCreateResponse,
  IArgumentFieldUpdateParams,
  IArgumentFieldUpdateResponse,
} from "./types/argumentFields"

export const apiUpdateArgumentField = (
  params: IArgumentFieldUpdateParams
): Promise<IArgumentFieldUpdateResponse> =>
  axios
    .patch(`api/argument_fields/${params.id}`, params)
    .then((res) => res.data)

export const apiCreateArgumentField = (
  params: IArgumentFieldCreateParams
): Promise<IArgumentFieldCreateResponse> =>
  axios.post("api/argument_fields", params).then((res) => res.data)

export const apiArchiveArgumentField = (
  argumentFieldId: string
): Promise<IArgumentFieldUpdateResponse> =>
  axios
    .patch(`api/argument_fields/${argumentFieldId}/archive`)
    .then((res) => res.data)

export const apiRestoreArgumentField = (
  argumentFieldId: string
): Promise<IArgumentFieldUpdateResponse> =>
  axios
    .patch(`api/argument_fields/${argumentFieldId}/restore`)
    .then((res) => res.data)

export const apiUpdateArgumentFieldPosition = ({
  id,
  relativeId,
}: {
  id: string
  relativeId: string
}): Promise<undefined> =>
  axios
    .patch(`api/argument_fields/${id}/update_position`, { relativeId })
    .then((res) => res.data)
