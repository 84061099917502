import { FormattedMessage } from "react-intl"

import Divider from "ds/Divider"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import DisplayPreferences from "./Settings/DisplayPreferences"
import EmailNotifications from "./Settings/EmailNotifications"

export default function Settings() {
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1}>
        <Typography variant="lgSemi">
          <FormattedMessage
            id="navigation.settings"
            defaultMessage="Settings"
          />
        </Typography>
      </Stack>

      <Divider>
        <Typography variant="smMed">
          <FormattedMessage id="emailNotifications.title" />
        </Typography>
      </Divider>

      <EmailNotifications />

      <Divider>
        <Typography variant="smMed">
          <FormattedMessage id="settings.displayPreferences.title" />
        </Typography>
      </Divider>

      <DisplayPreferences />
    </Stack>
  )
}
