import EditNoteIcon from "@mui/icons-material/EditNote"
import { useIntl } from "react-intl"

import Avatar from "ds/Avatar"

export default function AnswerIndicator({
  showMandatoryAnswerIndicator = false,
}: {
  showMandatoryAnswerIndicator?: boolean
}) {
  const intl = useIntl()
  return (
    <Avatar
      sx={{
        bgcolor: (theme) => theme.palette.warning.light,
        border: showMandatoryAnswerIndicator
          ? (theme) => `solid 2px ${theme.palette.error.main}`
          : "transparent",
        transition: (theme) => theme.transitions.create("border-color"),
      }}
    >
      <EditNoteIcon
        fontSize="small"
        color="warning"
        aria-label={intl.formatMessage({
          id: "playbookEdit.argument.kind.ANSWER",
        })}
      />
    </Avatar>
  )
}
