// In an array, move an element from one position to another
const changeArrayPosition = <TElement>(
  array: TElement[],
  fromIndex: number,
  toIndex: number
): TElement[] => {
  const copy = [...array]
  const element = copy[fromIndex]
  copy.splice(fromIndex, 1)
  copy.splice(toIndex, 0, element)
  return copy
}

export default changeArrayPosition
