import { FormattedMessage } from "react-intl"

import BasicNav from "ds/BasicNav"

export default function PlaybookEditNav() {
  const playbookTabName = (
    <FormattedMessage
      id="playbookEdit.tabs.playbook"
      defaultMessage="Playbook"
    />
  )

  const matrixTabName = (
    <FormattedMessage id="playbookEdit.tabs.matrix" defaultMessage="Matrix" />
  )

  return (
    <BasicNav
      entries={[
        { href: "view", title: playbookTabName },
        { href: "matrix", title: matrixTabName },
      ]}
    />
  )
}
