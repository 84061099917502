import { apiCreateReferencesImport } from "api/referencesImports"

import { useInvalidatingMutation } from "utils/hooks/mutations"

import { buildPlaybookKey } from "./playbookQueries"

// TODO change for argumentTypeId once we lload TPs by tab
export function useReferencesImportCreateMutation(playbookId: string) {
  return useInvalidatingMutation(
    buildPlaybookKey(playbookId),
    apiCreateReferencesImport
  )
}
