import { Box } from "@mui/material"
import { ReactNode, useRef } from "react"

import { MyEditor } from "ds/RichTextNew/types"
import Stack from "ds/Stack"

import MeetingArgumentNotes from "components/App/CRM/Meetings/MeetingsIndex/MeetingModal/MeetingArgumentNotes"

import { getNotesAppearingInSummary } from "services/meetingArgumentNotes"
import { getDownvotes, getUpvotes } from "services/votes"

import MeetingGeneralNote from "./MeetingGeneralNote"
import MeetingGeneralNoteForm from "./MeetingGeneralNoteForm"
import MeetingInformation from "./MeetingInformation"
import Downvotes from "./MeetingNotes/Votes/Downvotes"
import Upvotes from "./MeetingNotes/Votes/Upvotes"
import { useMeeting } from "./MeetingProvider"

function Section({ children }: { children: ReactNode }) {
  return (
    <Box
      p={2}
      bgcolor={(theme) => theme.palette.common.white}
      boxShadow={6}
      borderRadius={1}
    >
      {children}
    </Box>
  )
}

export default function MeetingForm() {
  const { ongoingMeeting } = useMeeting()
  const editorRef = useRef<MyEditor>(null)
  const upvotes = getUpvotes(ongoingMeeting?.votes || [])
  const downvotes = getDownvotes(ongoingMeeting?.votes || [])

  if (!ongoingMeeting) return null

  const notesAppearingInSummary = getNotesAppearingInSummary(ongoingMeeting)

  return (
    <Stack spacing={2} sx={{ mb: 12 }}>
      <Section>
        <MeetingInformation ongoingMeeting={ongoingMeeting} />
      </Section>

      <Section>
        <MeetingGeneralNote
          editorRef={editorRef}
          form={
            <MeetingGeneralNoteForm
              ongoingMeeting={ongoingMeeting}
              editorRef={editorRef}
            />
          }
        />
      </Section>

      {notesAppearingInSummary.length > 0 && (
        <Section>
          <MeetingArgumentNotes
            meeting={ongoingMeeting}
            meetingArgumentNotes={notesAppearingInSummary}
          />
        </Section>
      )}

      {upvotes.length > 0 && (
        <Section>
          <Upvotes votes={upvotes} ongoingMeeting={ongoingMeeting} />
        </Section>
      )}

      {downvotes.length > 0 && (
        <Section>
          <Downvotes votes={downvotes} ongoingMeeting={ongoingMeeting} />
        </Section>
      )}
    </Stack>
  )
}
