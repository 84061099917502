// Logic related to opening and closing the argument modal
//
// ==== WARNING ====
// For now, the modal does not have its own URL.
// It is opened by a background with the use of a URL param.
// The active tab on the argument page is also a URL param.
// We might want to change this.
import { useLocation } from "react-router-dom"

import { getPlaybookPath } from "services/playbooks"

import useSearchParam, { replaceSearchParam } from "utils/hooks/useSearchParam"

export const paramKey = "argumentId"
export const tabParamKey = "activeArgumentTab"

export function useArgumentModalLocation(
  argumentId: string,
  { activeTab }: { activeTab?: "comments" | "share" } = {}
) {
  const location = useLocation()
  let argumentModalLocation = replaceSearchParam({
    location,
    key: paramKey,
    value: argumentId,
  })
  argumentModalLocation = replaceSearchParam({
    location: argumentModalLocation,
    key: tabParamKey,
    value: activeTab,
  })
  return argumentModalLocation
}

export function useArgumentIdParam() {
  return useSearchParam(paramKey)
}

export function getArgumentPath(
  businessUnitId: string,
  playbookId: string,
  argumentId: string,
  tab?: string
) {
  const tabParam = tab ? `&${tabParamKey}=${tab}` : ""
  return `${getPlaybookPath(
    businessUnitId,
    playbookId
  )}?${paramKey}=${argumentId}${tabParam}`
}
