import { useTheme } from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from "@mui/x-date-pickers/DateTimePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { parseISO } from "date-fns"

import { textFieldCustomizations } from "ds/TextField"

import { useDateFnsLocale } from "translations/TranslationsProvider"

import mergeSx from "utils/mergeSx"

interface DateTimePickerProps<TDate>
  extends Omit<
    MuiDateTimePickerProps<TDate>,
    "value" | "onChange" | "renderInput"
  > {
  value: string | null
  onChange: (newValue: string | null) => void
  fullWidth?: boolean
}

// The value is a string, though under the hood, we manipulate date-fns objects
export default function DateTimePicker({
  value,
  onChange,
  fullWidth,
  ...props
}: DateTimePickerProps<Date>) {
  const dateFnsLocale = useDateFnsLocale()
  const theme = useTheme()
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dateFnsLocale}
    >
      <MuiDateTimePicker
        value={value ? parseISO(value) : null}
        onChange={(dateFnsValue) =>
          onChange(dateFnsValue ? dateFnsValue.toJSON() : null)
        }
        slotProps={{
          textField: { fullWidth },
        }}
        {...props}
        sx={mergeSx(textFieldCustomizations(theme), props.sx)}
      />
    </LocalizationProvider>
  )
}
