import { IArgumentFieldValue } from "api/types/argumentFieldValues"
import { IArgumentField } from "api/types/argumentFields"
import { IArgument } from "api/types/arguments"
import { IUser } from "api/types/users"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import UserMention from "components/App/User/UserMention"

import ArgumentFieldValueDisplay from "./ArgumentFieldValueDisplay"
import EditableArgumentFieldValue from "./EditableArgumentFieldValue"

interface Props {
  argumentField: IArgumentField
  argumentFieldValue: IArgumentFieldValue
  argument: IArgument
  onDeleteField: () => void
}

export default function ArgumentFieldValue({
  argumentField,
  argumentFieldValue,
  argument,
  onDeleteField,
}: Props) {
  const { editMode, users } = usePlaybook()

  const richTextProps = {
    wrapFileInLink: !editMode,
    mentionees: users,
    renderMention(user: IUser, props: React.HTMLAttributes<HTMLSpanElement>) {
      return <UserMention user={user} {...props} />
    },
  }

  if (editMode) {
    return (
      <EditableArgumentFieldValue
        argument={argument}
        argumentField={argumentField}
        argumentFieldValue={argumentFieldValue}
        onDeleteField={onDeleteField}
        richTextProps={richTextProps}
      />
    )
  }

  return (
    <ArgumentFieldValueDisplay
      argument={argument}
      argumentField={argumentField}
      argumentFieldValue={argumentFieldValue}
      onDeleteField={onDeleteField}
      richTextProps={richTextProps}
    />
  )
}
