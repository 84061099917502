import { Blob } from "@rails/activestorage"

import apiHost from "./apiHost"
import axios from "./axios"

export const directUploadUrl = `${apiHost}/api/direct_uploads`

export interface ActiveStorageBlob extends Blob {
  key: string
}

export const apiGetPresignedUrl = (
  url: string,
  { signal }: { signal?: AbortSignal } = {}
): Promise<string> =>
  axios
    .get("api/direct_uploads/presigned_url", { params: { url }, signal })
    .then((res) => res.data)
