import axios from "./axios"
import {
  ICRMMeetingType,
  ICRMMeetingTypeCreateParams,
  ICRMMeetingTypeResponse,
  ICRMMeetingTypeUpdateParams,
} from "./types/CRMMeetingTypes"

export const apiIndexCRMMeetingTypes = (
  businessUnitId: string,
  { signal }: { signal?: AbortSignal }
): Promise<ICRMMeetingType[]> =>
  axios
    .get("api/crm/meeting_types", { params: { businessUnitId }, signal })
    .then((res) => res.data)

export const apiUpdateCRMMeetingType = (
  params: ICRMMeetingTypeUpdateParams
): Promise<ICRMMeetingTypeResponse> =>
  axios
    .patch(`api/crm/meeting_types/${params.id}`, params)
    .then((res) => res.data)

export const apiCreateCRMMeetingType = (
  params: ICRMMeetingTypeCreateParams
): Promise<ICRMMeetingTypeResponse> =>
  axios.post("api/crm/meeting_types", params).then((res) => res.data)

export const apiDeleteCRMMeetingType = (id: string): Promise<undefined> =>
  axios.delete(`api/crm/meeting_types/${id}`).then((res) => res.data)
