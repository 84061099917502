import { Navigate } from "react-router-dom"

import SimpleLayout from "components/layouts/SimpleLayout"

import { getPlaybookPath } from "services/playbooks"

import { getValidLastVisitedPlaybookId } from "../Playbook/lastVisit"
import { useBusinessUnit } from "./BusinessUnitProvider"
import NoPlaybook from "./NoPlaybook"

export default function BusinessUnitRoot() {
  const businessUnit = useBusinessUnit()
  const lastVisitedPlaybookId = getValidLastVisitedPlaybookId(businessUnit)

  const nonArchivedPlaybooks = businessUnit.playbooks.filter(
    (playbook) => !playbook.archived
  )

  if (nonArchivedPlaybooks.length === 0) {
    return (
      <SimpleLayout>
        <NoPlaybook businessUnit={businessUnit} />
      </SimpleLayout>
    )
  }

  if (
    lastVisitedPlaybookId &&
    nonArchivedPlaybooks.map((x) => x.id).includes(lastVisitedPlaybookId)
  ) {
    return (
      <Navigate
        to={getPlaybookPath(businessUnit.id, lastVisitedPlaybookId)}
        replace
      />
    )
  }

  return (
    <Navigate
      to={getPlaybookPath(businessUnit.id, nonArchivedPlaybooks[0].id)}
      replace
    />
  )
}
