import AnalyticsIcon from "@mui/icons-material/Analytics"
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions"
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom"
import MenuBookIcon from "@mui/icons-material/MenuBook"
import PersonIcon from "@mui/icons-material/Person"
import SettingsIcon from "@mui/icons-material/Settings"
import { Box } from "@mui/material"
import { concat } from "lodash"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"

import NavEntry from "ds/BasicNav/NavEntry"

interface Props {
  activeTab: string | undefined
  businessUnitId: string
  showUsersTab: boolean
}

export default function BusinessUnitNav({
  businessUnitId,
  activeTab,
  showUsersTab,
}: Props) {
  const navigate = useNavigate()

  let entries = concat(
    showUsersTab
      ? [
          {
            title: <FormattedMessage id="businessUnits.users.title" />,
            href: `bu/${businessUnitId}/users`,
            icon: PersonIcon,
          },
        ]
      : [],
    [
      {
        title: <FormattedMessage id="businessUnits.playbooks.title" />,
        href: `bu/${businessUnitId}/playbooks`,
        icon: MenuBookIcon,
      },
      {
        title: <FormattedMessage id="businessUnits.dashboard.title" />,
        href: `bu/${businessUnitId}/dashboard`,
        icon: AnalyticsIcon,
      },
      {
        title: <FormattedMessage id="businessUnits.meetings.title" />,
        href: `bu/${businessUnitId}/meetings`,
        icon: MeetingRoomIcon,
      },
      {
        title: <FormattedMessage id="businessUnits.meetingTypes.title" />,
        href: `bu/${businessUnitId}/meeting_types`,
        icon: AssignmentOutlinedIcon,
      },
      {
        title: <FormattedMessage id="businessUnits.integrations.title" />,
        href: `bu/${businessUnitId}/integrations`,
        icon: IntegrationInstructionsIcon,
      },
      {
        title: <FormattedMessage id="businessUnits.settings.title" />,
        href: `bu/${businessUnitId}/settings`,
        icon: SettingsIcon,
      },
    ]
  )

  return (
    <Box>
      {entries.map(({ href, title, icon }) => (
        <NavEntry
          key={href}
          onClick={() => navigate(href)}
          icon={icon}
          title={title}
          selected={activeTab === href}
        />
      ))}
    </Box>
  )
}
