import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { useIntl } from "react-intl"

import { IArgumentPersonalNote } from "api/types/argumentPersonalNotes"

import IconButton from "ds/IconButton"

import useConfirm from "utils/hooks/useConfirm"

import { usePlaybook } from "../../PlaybookProvider"
import { useArgumentPersonalNoteRemoveMutation } from "./argumentPersonalNoteQueries"

interface Props {
  argumentPersonalNote: IArgumentPersonalNote
}

export default function RemoveArgumentPersonalNote({
  argumentPersonalNote,
}: Props) {
  const intl = useIntl()
  const { playbook } = usePlaybook()
  const removeMutation = useArgumentPersonalNoteRemoveMutation(playbook.id)

  const confirm = useConfirm()

  function remove() {
    removeMutation.mutate({ argumentId: argumentPersonalNote.argumentId })
  }

  return (
    <IconButton
      aria-label={intl.formatMessage({
        id: "playbook.argumentPersonalNote.remove",
      })}
      onClick={(e) => {
        e.stopPropagation()
        if (confirm()) remove()
      }}
      color="error"
      opacityTransitionOnHover
    >
      <DeleteOutlineIcon fontSize="small" />
    </IconButton>
  )
}
