import MuiOutlinedInput, {
  OutlinedInputProps,
} from "@mui/material/OutlinedInput"
import { forwardRef } from "react"

export type { OutlinedInputProps }

export default forwardRef(function OutlinedInput(
  props: OutlinedInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <MuiOutlinedInput
      {...props}
      sx={{
        fontSize: (theme) => theme.typography.smNormal.fontSize,
        lineHeight: (theme) => theme.typography.smNormal.lineHeight,
        ...props.sx,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: (theme) => theme.palette.grey[300],
        },
        "& legend": {
          fontSize: (theme) => theme.typography.xsMed,
        },
      }}
      ref={ref}
    />
  )
})
