import { FormattedMessage } from "react-intl"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"

import Button from "ds/Button"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentSegmentationUnlinkFromCrmPropertyMutation } from "components/App/Playbook/queries/argumentSegmentationQueries"

interface Props {
  argumentSegmentation: IArgumentSegmentation
}

export default function UnlinkArgumentSegmentationFromCrmProperty(
  props: Props
) {
  const { playbook } = usePlaybook()

  const unlinkMutation = useArgumentSegmentationUnlinkFromCrmPropertyMutation(
    playbook.id
  )

  function unlink() {
    unlinkMutation.mutate({ id: props.argumentSegmentation.id })
  }

  return (
    <Button
      fullWidth
      size="small"
      disabled={unlinkMutation.isLoading}
      color="error"
      onClick={unlink}
    >
      <FormattedMessage id="playbookEdit.argumentSegmentations.unlinkFromCrmProperty" />
    </Button>
  )
}
