import { Box } from "@mui/material"
import { useState } from "react"
import { FormattedMessage } from "react-intl"

import { IBarePlaybook } from "api/types/playbooks"

import Button from "ds/Button"
import Popover from "ds/Popover"
import { AlertSnackbar, BasicErrorSnack } from "ds/Snackbar"

import useAnchorEl from "utils/hooks/useAnchorEl"

import { useBusinessUnit } from "../../BusinessUnitProvider"
import { useImportPlaybookMutation } from "../businessUnitPlaybookQueries"
import ImportablePlaybooksSelector from "./ImportablePlaybooksSelector"

interface Props {
  onPlaybookCreation: (playbook: IBarePlaybook) => void
}

export default function ImportPlaybook({ onPlaybookCreation }: Props) {
  const businessUnit = useBusinessUnit()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const id = open ? `import-playbook-${businessUnit.id}` : undefined
  const importMutation = useImportPlaybookMutation(businessUnit.organizationId)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [warningSnackOpen, setWarningSnackOpen] = useState(false)

  const onSelect = (selectedPlaybookId: string) => {
    handleClose()
    setWarningSnackOpen(true)
    importMutation.mutate(
      {
        playbookId: selectedPlaybookId,
        businessUnitId: businessUnit.id,
      },
      {
        onSuccess: ({ playbook }) => {
          setWarningSnackOpen(false)
          onPlaybookCreation(playbook)
        },
        onError: () => setErrorSnackOpen(true),
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        size="small"
        variant="contained"
        disabled={importMutation.isLoading}
      >
        {importMutation.isLoading ? (
          <FormattedMessage id="businessUnits.playbooks.actions.import.inProgress" />
        ) : (
          <FormattedMessage id="businessUnits.playbooks.actions.import" />
        )}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "center", vertical: "center" }}
        transformOrigin={{ horizontal: "center", vertical: "center" }}
      >
        <Box sx={{ width: "300px", p: 1 }}>
          <ImportablePlaybooksSelector
            onSelect={onSelect}
            businessUnitId={businessUnit.id}
          />
        </Box>
      </Popover>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <AlertSnackbar
        open={warningSnackOpen}
        onClose={() => setWarningSnackOpen(false)}
        severity="warning"
      >
        <FormattedMessage id="businessUnits.playbooks.actions.import.inProgressWarning" />
      </AlertSnackbar>
    </>
  )
}
