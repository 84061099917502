import CloseIcon from "@mui/icons-material/Close"
import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import IconButton from "ds/IconButton"
import Tooltip from "ds/Tooltip"

interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default function DeselectAllArguments({ onClick }: Props) {
  return (
    <Tooltip
      title={<FormattedMessage id="common.cancel" defaultMessage="Cancel" />}
    >
      <Box display="flex" alignItems="center">
        <IconButton onClick={onClick}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Tooltip>
  )
}
