import { Box } from "@mui/material"

interface Props {
  children: React.ReactNode
}

export default function HeaderContainer({ children }: Props) {
  return (
    <Box bgcolor={(theme) => theme.palette.primary.main}>
      <Box p={2} display="flex" gap={2} alignItems="center" position="relative">
        {children}
      </Box>
    </Box>
  )
}
