import CreateIcon from "@mui/icons-material/Create"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { ICRMMeetingType } from "api/types/CRMMeetingTypes"

import Autocomplete, { CustomAutocompleteProps } from "ds/Autocomplete"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { getCRMMeetingTypesPath } from "services/CRMMeetingTypes"

import { useMeetingTypesQuery } from "./meetingTypeQueries"

function InputLabel() {
  return (
    <FormattedMessage id="meetings.meetingType" defaultMessage="Meeting type" />
  )
}

export interface CRMMeetingTypeSelectorProps<
  DisableClearable extends boolean | undefined,
  TOptionAlternative = never
> extends Omit<
    CustomAutocompleteProps<
      ICRMMeetingType | TOptionAlternative,
      false,
      DisableClearable,
      false
    >,
    "getOptionLabel" | "options" | "loading" | "multiple" | "inputLabel"
  > {
  businessUnitId: string
  inputLabel?: React.ReactNode
  includeManageOption?: boolean
}

const MANAGE_MEETING_TYPES_OPTION_ID = "manageMeetingTypes"

export default function CRMMeetingTypeSelector<
  DisableClearable extends boolean | undefined,
  TOptionAlternative extends {
    id: string
    name: string
  } = never
>({
  businessUnitId,
  inputLabel = <InputLabel />,
  includeManageOption = false,
  ...props
}: CRMMeetingTypeSelectorProps<DisableClearable, TOptionAlternative>) {
  const [openedOnce, setOpenedOnce] = useState(false)
  const { data: meetingTypes, isLoading } = useMeetingTypesQuery(
    businessUnitId,
    { enabled: openedOnce }
  )
  const intl = useIntl()
  const navigate = useNavigate()

  function buildManageOption() {
    return {
      id: MANAGE_MEETING_TYPES_OPTION_ID,
      name: intl.formatMessage({
        id: "playbook.meeting.information.manageMeetingTypes",
      }),
    } as TOptionAlternative
  }

  function getOptions(meetingTypes: (TOptionAlternative | ICRMMeetingType)[]) {
    return includeManageOption
      ? [...meetingTypes, buildManageOption()]
      : meetingTypes
  }

  function filterMeetingTypes(
    inputValue: string,
    options: (TOptionAlternative | ICRMMeetingType)[]
  ) {
    return options.filter((option) =>
      option.name.toLowerCase().startsWith(inputValue.toLowerCase())
    )
  }

  return (
    <Autocomplete
      multiple={false}
      options={meetingTypes ? meetingTypes : []}
      inputLabel={inputLabel}
      getOptionLabel={(option) => option.name || ""}
      loading={isLoading}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.id === MANAGE_MEETING_TYPES_OPTION_ID ? (
            <Stack
              spacing={1}
              direction="row"
              alignItems="center"
              color={(theme) => theme.palette.primary[500]}
            >
              <CreateIcon
                fontSize="small"
                sx={{ fill: (theme) => theme.palette.primary[500] }}
              />
              <Typography variant="smNormal">{option.name}</Typography>
            </Stack>
          ) : (
            option.name
          )}
        </li>
      )}
      filterOptions={(options, { inputValue }) => {
        return getOptions(filterMeetingTypes(inputValue, options))
      }}
      onOpen={() => setOpenedOnce(true)}
      {...props}
      onChange={(event, value, reason, details) => {
        if (value && value.id === MANAGE_MEETING_TYPES_OPTION_ID) {
          event.stopPropagation()
          event.preventDefault()
          navigate(getCRMMeetingTypesPath(businessUnitId))
        } else {
          props.onChange?.(event, value, reason, details)
        }
      }}
    />
  )
}
