import { IntlShape, useIntl } from "react-intl"
import styled from "styled-components"

import { IArgumentCommentReaction } from "api/types/argumentComments"
import { IUser } from "api/types/users"

import useMe from "authentication/useMe"

import { getEmojiById } from "ds/EmojiPicker"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.text.secondary};
  align-items: center;
  color: ${({ theme }) => theme.palette.grey["200"]};
`

const Reactors = styled.span`
  color: white;
  font-weight: bolder;
`

function getReactionVerb(
  intl: IntlShape,
  alreadyReacted: boolean,
  otherPeopleCount: number
) {
  if (alreadyReacted)
    if (otherPeopleCount)
      return intl.formatMessage({
        id: "argumentComments.reaction.meWithOthersVerb",
        defaultMessage: "reacted",
      })
    else
      return intl.formatMessage({
        id: "argumentComments.reaction.onlyMeVerb",
        defaultMessage: "reacted",
      })
  else if (otherPeopleCount === 1)
    return intl.formatMessage({
      id: "argumentComments.reaction.onlyOneOtherVerb",
      defaultMessage: "reacted",
    })
  else
    return intl.formatMessage({
      id: "argumentComments.reaction.onlyOtherVerb",
      defaultMessage: "reacted",
    })
}

// TODO handle the current user
const getReactors = (intl: IntlShape, reactors: IUser[]) =>
  reactors.reduce(
    (result, reactor, index) =>
      index === 0
        ? reactor.handle
        : index === reactors.length - 1
        ? `${result} ${intl
            .formatMessage({ id: "common.and", defaultMessage: "and" })
            .toLowerCase()} ${reactor.handle}`
        : `${result}, ${reactor.handle}`,
    ""
  )

interface Props {
  reaction: IArgumentCommentReaction
}

export default function ReactionTooltip({ reaction }: Props) {
  const intl = useIntl()
  const user = useMe()
  const { users } = usePlaybook()

  const alreadyReacted = reaction.reactorsIds.some(
    (reactorId) => reactorId === user.id
  )
  const reactors = users.filter((user) =>
    reaction.reactorsIds.includes(user.id)
  )

  const reactorsNameList = getReactors(intl, reactors)
  const reacted = getReactionVerb(
    intl,
    alreadyReacted,
    reactors.length - +alreadyReacted
  )

  const keywordWith = intl
    .formatMessage({
      id: "common.with",
    })
    .toLowerCase()

  return (
    <Container>
      <span>
        <Reactors>{reactorsNameList}</Reactors> {reacted} {keywordWith}
      </span>
      {getEmojiById(reaction.emojiName)}
    </Container>
  )
}
