import { Box } from "@mui/material"
import { FormattedMessage } from "react-intl"

import { IMeetingNotification } from "api/types/notifications"

import Stack from "ds/Stack"
import Typography from "ds/Typography"
import { UnstyledRouterLink } from "ds/UnstyledLink"

import { ReactComponent as Logo } from "images/logo.svg"

import { getMeetingPath } from "services/meetings"

import NotificationContainer from "../NotificationContainer"
import NotificationTimestamp from "../NotificationTimestamp"

interface Props {
  notification: IMeetingNotification
}

export default function CrmPropertyPushFailureNotification({
  notification,
}: Props) {
  const meetingURL = getMeetingPath(notification.meeting)

  return (
    <UnstyledRouterLink to={meetingURL}>
      <NotificationContainer notification={notification}>
        <Stack direction="row" spacing={2} alignItems="start">
          <Box
            sx={{
              "& svg": { width: "32px", flexShrink: 0, borderRadius: "50%" },
            }}
          >
            <Logo />
          </Box>

          <Stack spacing={1}>
            <Typography variant="smNormal">
              <FormattedMessage id="notifications.notificationTypes.crmPropertiesPushFailure.title" />
            </Typography>
            <NotificationTimestamp notification={notification} />
          </Stack>
        </Stack>
      </NotificationContainer>
    </UnstyledRouterLink>
  )
}
