import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { ComponentProps, forwardRef } from "react"

type Props = ComponentProps<typeof AccessTimeIcon>

const StaleWarningIcon = forwardRef<SVGSVGElement, Props>(
  ({ fontSize, ...props }, ref) => {
    return (
      <AccessTimeIcon {...props} ref={ref} fontSize={fontSize} color="error" />
    )
  }
)

export default StaleWarningIcon
