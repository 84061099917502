import MuiSwitch, { SwitchProps } from "@mui/material/Switch"
import { forwardRef } from "react"

export type { SwitchProps }

export default forwardRef(function Switch(
  props: SwitchProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <MuiSwitch
      {...props}
      sx={{
        opacity: props.disabled ? 0.5 : 1,
        ...props.sx,
        "&.MuiSwitch-root": {
          height: "32px",
          py: "8px",
        },
        "& .MuiSwitch-thumb": {
          bgcolor: (theme) =>
            props.checked ? theme.palette.primary.main : theme.palette.grey[50],
          boxShadow: 1,
        },
        "& .MuiSwitch-track": {
          bgcolor: (theme) =>
            props.checked
              ? theme.palette.primary.main
              : theme.palette.grey[300],
          opacity: props.checked ? 0.5 : 1,
        },
        "& .MuiSwitch-switchBase": {
          py: "6px",
          "&.Mui-disabled": {
            "& + .MuiSwitch-track": {
              opacity: props.checked ? 0.5 : 1,
            },
          },
        },
      }}
      ref={ref}
    />
  )
})
