import MuiDrawer, { DrawerProps } from "@mui/material/Drawer"
import { forwardRef } from "react"

export type { DrawerProps }

export default forwardRef(function Drawer(
  props: DrawerProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiDrawer {...props} ref={ref} />
})
