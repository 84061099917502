import { FormattedMessage } from "react-intl"

import Alert from "ds/Alert"

export default function NoArgumentsInSegments() {
  return (
    <Alert severity="warning">
      <FormattedMessage
        id="playbook.noArgumentsInSegments"
        defaultMessage="No talking point for selected segments, please change your selection to see available talking points."
      />
    </Alert>
  )
}
