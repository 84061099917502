import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined"

import { IArgument } from "api/types/arguments"

import Badge from "ds/Badge"

interface Props {
  argument: IArgument
}

export default function NotificationsCue({ argument }: Props) {
  return (
    <Badge
      badgeContent={argument.notVisitedNotificationsCount}
      color="error"
      componentsProps={{
        badge: {
          style: {
            fontSize: "8px",
            minWidth: "10px",
            height: "10px",
            padding: "0 3px",
            top: 2,
            right: 2,
          },
        },
      }}
    >
      <NotificationsOutlinedIcon sx={{ fontSize: "16px" }} />
    </Badge>
  )
}
