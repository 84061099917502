import { Box } from "@mui/material"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { IBusinessUnit } from "api/types/businessUnits"

import Button from "ds/Button"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { getManageOrganizationPath } from "services/organizations"

import useConfirm from "utils/hooks/useConfirm"

import { useBusinessUnitArchiveMutation } from "./businessUnitQueries"

interface Props {
  businessUnit: IBusinessUnit
}

export default function ArchiveBusinessUnit({ businessUnit }: Props) {
  const intl = useIntl()
  const navigate = useNavigate()

  const archiveMutation = useBusinessUnitArchiveMutation(
    businessUnit.organizationId,
    businessUnit.id
  )

  const confirmArchive = useConfirm({
    message: intl.formatMessage({
      id: "businessUnits.settings.archive.confirm",
    }),
  })

  const onClick = () => {
    if (!confirmArchive()) return
    archiveMutation.mutate(businessUnit.id, {
      onSuccess: () => {
        navigate(getManageOrganizationPath(businessUnit.organizationId))
      },
    })
  }

  return (
    <Stack spacing={2}>
      <Typography variant="smSemi">
        <FormattedMessage id="businessUnits.settings.archiveTitle" />
      </Typography>

      <Box>
        <Button
          onClick={onClick}
          variant="contained"
          color="error"
          disabled={archiveMutation.isLoading}
        >
          <FormattedMessage id="businessUnits.settings.archive" />
        </Button>
      </Box>
    </Stack>
  )
}
