import { useState } from "react"
import { FormattedMessage } from "react-intl"

import Button from "ds/Button"
import Modal from "ds/Modal"
import { BasicErrorSnack, BasicSuccessSnack } from "ds/Snackbar"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import { useOrganization } from "../OrganizationProvider"
import InviteUserForm from "./InviteUserForm"

export default function InviteUser() {
  const organization = useOrganization()
  const [isOpen, setIsOpen] = useState(false)
  const [errorSnackOpen, setErrorSnackOpen] = useState(false)
  const [successSnackOpen, setSuccessSnackOpen] = useState(false)

  const cta = <FormattedMessage id="organizations.users.invite.cta" />

  return (
    <>
      <Button
        color="primary"
        onClick={() => setIsOpen(true)}
        variant="outlined"
      >
        {cta}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        data-testid="invite-user-modal"
      >
        <Stack spacing={2}>
          <Typography variant="baseSemi">{cta}</Typography>
          <InviteUserForm
            organizationId={organization.id}
            businessUnits={organization.businessUnits}
            onSuccess={() => {
              setSuccessSnackOpen(true)
              setIsOpen(false)
            }}
            onError={() => setErrorSnackOpen(true)}
          />
        </Stack>
      </Modal>

      <BasicErrorSnack
        open={errorSnackOpen}
        onClose={() => setErrorSnackOpen(false)}
      />

      <BasicSuccessSnack
        open={successSnackOpen}
        onClose={() => setSuccessSnackOpen(false)}
      />
    </>
  )
}
