import { Consumer, Subscription } from "@rails/actioncable"
import { useEffect } from "react"

import { createConsumer } from "sockets/consumer"

//TODO test
export default function useSubscription(
  channel: string,
  handler: (message: any) => void,
  params: { [key: string]: any } = {}
) {
  useEffect(() => {
    let subscription: Subscription<Consumer> | null = null
    let consumer: Consumer | null = null

    async function subscribe() {
      consumer = await createConsumer()
      const newSubscription = consumer.subscriptions.create(
        { channel: channel, ...params },
        {
          received: handler,
          rejected: () => {
            throw new Error("websocket connection failed")
          },
        }
      )
      return newSubscription
    }

    subscribe().then((newSubscription) => (subscription = newSubscription))

    return () => {
      subscription?.unsubscribe()
      consumer?.disconnect()
    }
  }, [channel, params, handler])
}
