import MuiFormLabel, { FormLabelProps } from "@mui/material/FormLabel"
import { forwardRef } from "react"

import Typography from "./Typography"

export type { FormLabelProps }

export default forwardRef(function FormLabel(
  props: FormLabelProps,
  ref: React.Ref<HTMLLabelElement>
) {
  return (
    <MuiFormLabel {...props} ref={ref}>
      <Typography
        variant="smSemi"
        color={(theme) => theme.palette.text.primary}
      >
        {props.children}
      </Typography>
    </MuiFormLabel>
  )
})
