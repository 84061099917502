import { FormattedMessage } from "react-intl"

import { IArgumentField } from "api/types/argumentFields"

import MenuItem from "ds/MenuItem"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentFieldCreateMutation } from "components/App/Playbook/queries/argumentFieldQueries"

interface Props {
  name: string
  onAddField: (argumentField: IArgumentField) => void
}

export default function CreateArgumentField({ name, onAddField }: Props) {
  const { playbook } = usePlaybook()
  const createMutation = useArgumentFieldCreateMutation(playbook.id)

  const submit = () =>
    createMutation.mutate(
      {
        playbookId: playbook.id,
        name,
      },
      { onSuccess: ({ argumentField }) => onAddField(argumentField) }
    )

  return (
    <MenuItem onClick={submit} disabled={createMutation.isLoading}>
      <FormattedMessage
        id="playbookEdit.argumentFields.create"
        defaultMessage={`Create "${name}"`}
        values={{ name }}
      />
    </MenuItem>
  )
}
