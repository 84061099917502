import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from "@hello-pangea/dnd"
import difference from "lodash/difference"
import get from "lodash/get"
import { Fragment, useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

import { IArgument } from "api/types/arguments"

import Checkbox from "ds/Checkbox"
import Loader from "ds/Loader"
import Stack from "ds/Stack"
import UncontrolledError from "ds/UncontrolledError"

import EditArgumentSegmentations from "components/App/Playbook/PlaybookEdit/EditArgumentSegmentations"
import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentGroupUpdatePositionMutation } from "components/App/Playbook/queries/argumentGroupQueries"
import {
  useArgumentUpdatePositionMutation,
  useArgumentsQuery,
} from "components/App/Playbook/queries/argumentQueries"

import { filterNonArchived } from "services/archivable"
import { filterArgumentGroupsInArgumentType } from "services/argumentGroups"
import { filterArguments, groupArguments } from "services/arguments"

import { usePersistLatestTypeInLS } from "../../PlaybookLoaded"
import ArgumentBulkActions from "./ArgumentBulkActions"
import ArgumentSegmentationHeader from "./ArgumentSegmentationHeader"
import MatrixArgumentGroup from "./MatrixArgumentGroup"
import MatrixGrid from "./MatrixGrid"
import StickyCheckboxContainer from "./StickyCheckboxContainer"

const MatrixHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper2};
  padding: ${({ theme }) => theme.spacing(1)};
  position: sticky;
  top: 0;
  box-shadow: 0 3px 2px -2px ${({ theme }) => theme.palette.grey[500]};
  z-index: 1;
`

const FirstHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: sticky;
  left: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.background.paper2};
`

interface Props {
  arguments: IArgument[]
  lastCreatedArgumentGroupId: string | null
  lastCreatedArgumentGroupRef: React.RefObject<HTMLDivElement>
}

function PlaybookMatrixTable({
  arguments: _arguments,
  lastCreatedArgumentGroupId,
  lastCreatedArgumentGroupRef,
}: Props) {
  const intl = useIntl()
  const { playbook, activeTypeId, viewSettings } = usePlaybook()
  usePersistLatestTypeInLS(playbook.id, activeTypeId)
  const argumentUpdatePositionMutation =
    useArgumentUpdatePositionMutation(activeTypeId)
  const argumentGroupUpdatePositionMutation =
    useArgumentGroupUpdatePositionMutation(playbook.id)
  const activeArgumentSegmentations = filterNonArchived(
    playbook.argumentSegmentations
  )
  const nbColumns = Math.max(activeArgumentSegmentations.length, 1)

  const argumentGroupsInThisType = filterArgumentGroupsInArgumentType(
    playbook.argumentGroups,
    activeTypeId
  )

  const filteredArguments = filterArguments(
    _arguments,
    playbook.argumentSegmentations,
    {
      showArchived: viewSettings.showArchived,
      typeId: activeTypeId,
    }
  )

  const argumentsByGroupId = groupArguments(filteredArguments)

  const onDragEnd: OnDragEndResponder = (result) => {
    if (!result.destination) {
      return
    }
    if (result.type === "droppableForArguments") {
      const argumentId = result.draggableId
      let newArgumentGroupId =
        result.destination.droppableId === "none"
          ? null
          : result.destination.droppableId
      const newPosition = result.destination.index
      const relativeArgument = get(argumentsByGroupId, [
        newArgumentGroupId || "none",
        newPosition,
      ])
      argumentUpdatePositionMutation.mutate({
        id: argumentId,
        relativeId: relativeArgument?.id,
        argumentGroupId: newArgumentGroupId,
      })
    } else if (result.type === "droppableForArgumentGroups") {
      const argumentGroupId = result.draggableId
      const oldPosition = result.source.index
      const newPosition = result.destination.index
      if (oldPosition === newPosition) {
        return
      }
      const relativeArgumentGroup = argumentGroupsInThisType[newPosition]
      argumentGroupUpdatePositionMutation.mutate({
        id: argumentGroupId,
        relativeId: relativeArgumentGroup.id,
      })
    }
  }

  const [selectedArgumentsForBulkAction, setSelectedArgumentsForBulkAction] =
    useState<IArgument[]>([])

  // if not all target arguments are selected, then add all of the non selected ones to selection
  // otherwise (i.e. all target IDs are selected), we unselected all target arguments.
  function toggleSelectedArgumentsForBulkActions(targetArguments: IArgument[]) {
    const notSelectedTargetArguments = targetArguments.filter(
      ({ id: targetArgumentId }) =>
        !selectedArgumentsForBulkAction.some(
          ({ id: selectedArgumentsForBulkActionsId }) =>
            selectedArgumentsForBulkActionsId === targetArgumentId
        )
    )

    if (notSelectedTargetArguments.length > 0) {
      setSelectedArgumentsForBulkAction((prevArguments) => [
        ...prevArguments,
        ...notSelectedTargetArguments,
      ])
    } else {
      const selectedArgumentsWithoutTarget =
        selectedArgumentsForBulkAction.filter(
          ({ id: selectedArgumentsForBulkActionsId }) =>
            !targetArguments.some(
              ({ id: targetArgumentId }) =>
                selectedArgumentsForBulkActionsId === targetArgumentId
            )
        )

      setSelectedArgumentsForBulkAction(selectedArgumentsWithoutTarget)
    }
  }

  function checkIfSelectedForBulkAction(targetArguments: IArgument[]) {
    if (targetArguments.length === 0) return false
    const targetArgumentIds = targetArguments.map(({ id }) => id)
    const selectedArgumentIdsForBulkAction = selectedArgumentsForBulkAction.map(
      ({ id }) => id
    )

    return (
      difference(targetArgumentIds, selectedArgumentIdsForBulkAction).length ===
      0
    )
  }

  const selectAllArgumentsLabel = intl.formatMessage({
    id: "playbookEdit.argument.selectAll",
    defaultMessage: "Select all arguments",
  })

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={`argumentGroups-${playbook.id}`}
        type="droppableForArgumentGroups"
      >
        {(providedDroppable) => (
          <Stack spacing={2}>
            <MatrixHeader>
              <MatrixGrid $nbColumns={nbColumns}>
                <FirstHeaderContainer>
                  <ArgumentBulkActions
                    selectedArguments={selectedArgumentsForBulkAction}
                    clearSelection={() => setSelectedArgumentsForBulkAction([])}
                  />
                  <EditArgumentSegmentations />
                </FirstHeaderContainer>

                <div />

                <StickyCheckboxContainer>
                  <Checkbox
                    onChange={() =>
                      toggleSelectedArgumentsForBulkActions(filteredArguments)
                    }
                    checked={checkIfSelectedForBulkAction(filteredArguments)}
                    inputProps={{
                      "aria-label": selectAllArgumentsLabel,
                    }}
                  />
                </StickyCheckboxContainer>

                {activeArgumentSegmentations.map((argumentSegmentation) => (
                  <ArgumentSegmentationHeader
                    key={argumentSegmentation.id}
                    argumentSegmentation={argumentSegmentation}
                  />
                ))}
              </MatrixGrid>
            </MatrixHeader>

            <Stack
              spacing={4}
              sx={{ pb: 12 }} // Leave some space for CTAs
              ref={providedDroppable.innerRef}
              {...providedDroppable.droppableProps}
            >
              {argumentGroupsInThisType.map((argumentGroup, index) => (
                <Fragment key={argumentGroup.id}>
                  <MatrixArgumentGroup
                    argumentGroup={argumentGroup}
                    arguments={argumentsByGroupId[argumentGroup.id] || []}
                    onBulkActionCheckboxClick={
                      toggleSelectedArgumentsForBulkActions
                    }
                    checkIfSelectedForBulkAction={checkIfSelectedForBulkAction}
                    index={index}
                    nameContainerRef={
                      lastCreatedArgumentGroupId === argumentGroup.id
                        ? lastCreatedArgumentGroupRef
                        : null
                    }
                    nbColumns={nbColumns}
                  />
                </Fragment>
              ))}

              {providedDroppable.placeholder}

              <MatrixArgumentGroup
                argumentGroup={null}
                arguments={argumentsByGroupId["none"] || []}
                onBulkActionCheckboxClick={
                  toggleSelectedArgumentsForBulkActions
                }
                checkIfSelectedForBulkAction={checkIfSelectedForBulkAction}
                nbColumns={nbColumns}
              />
            </Stack>
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  )
}

interface LoadedPlaybookMatrixTableProps {
  lastCreatedArgumentGroupId: string | null
  lastCreatedArgumentGroupRef: React.RefObject<HTMLDivElement>
}

export default function LoadedPlaybookMatrixTable(
  props: LoadedPlaybookMatrixTableProps
) {
  const { activeTypeId } = usePlaybook()
  const argumentsQuery = useArgumentsQuery(activeTypeId)

  if (argumentsQuery.isLoading) return <Loader />
  if (argumentsQuery.isError)
    return <UncontrolledError error={argumentsQuery.error} />

  return <PlaybookMatrixTable arguments={argumentsQuery.data} {...props} />
}
