import { Box } from "@mui/material"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

import { useIsSmallerThanSM } from "ds/Media"
import Stack from "ds/Stack"
import { UnstyledRouterLink } from "ds/UnstyledLink"

import AvatarMenu from "components/common/AvatarMenu"
import Notifications from "components/common/Notifications"

import { ReactComponent as HorizontalLogo } from "images/horizontal_logo.svg"
import { ReactComponent as Logo } from "images/logo.svg"

import HeaderContainer from "./Header/HeaderContainer"
import HeaderTitle from "./Header/HeaderTitle"

const MiddleContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  min-width: 0;
`

interface Props {
  left?: React.ReactNode
  middle?: React.ReactNode
  right?: React.ReactNode
  title?: React.ReactNode
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}

// By default:
//   - Left: Logo
//   - Middle: App name
//   - Right: Notifications and avatar menu
// To replace the title, instead of replacing the middle, use `title`
// To add content to the left side, next to the logo, instead of replacing them, use `leftContent`
// To add content to the right side, next to notifications and the menu, instead of replacing them, use `rightContent`
export default function Header({
  left,
  middle,
  right,
  title,
  leftContent,
  rightContent,
}: Props) {
  const isSmallerThanSM = useIsSmallerThanSM()

  return (
    <HeaderContainer>
      <div>
        {left || (
          <Stack
            direction="row"
            spacing={isSmallerThanSM ? 0.5 : 2}
            alignItems="center"
          >
            <UnstyledRouterLink to="/">
              <Box
                sx={{
                  "> svg": {
                    display: "block",
                    height:
                      isSmallerThanSM && !IS_CHROME_EXTENSION ? "32px" : "20px",
                    "&:hover": {
                      opacity: 0.5,
                    },
                    transition: (theme) => theme.transitions.create("opacity"),
                  },
                }}
              >
                {isSmallerThanSM && !IS_CHROME_EXTENSION ? (
                  <Logo />
                ) : (
                  <HorizontalLogo />
                )}
              </Box>
            </UnstyledRouterLink>
            {leftContent}
          </Stack>
        )}
      </div>

      <MiddleContainer>
        {middle || (
          <HeaderTitle variant={isSmallerThanSM ? "baseSemi" : "xlSemi"}>
            {title || (
              <FormattedMessage
                id="common.appName"
                defaultMessage="SalesPath"
              />
            )}
          </HeaderTitle>
        )}
      </MiddleContainer>

      <div>
        {right || (
          <Stack
            direction="row"
            spacing={isSmallerThanSM ? 0.5 : 2}
            alignItems="center"
          >
            {rightContent}
            <Notifications />
            <AvatarMenu />
          </Stack>
        )}
      </div>
    </HeaderContainer>
  )
}
