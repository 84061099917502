import { Dictionary, mapValues, pickBy } from "lodash"
import Qs from "qs"

import { ICRMProperty } from "api/types/CRMProperties"
import { IArgumentSegmentation } from "api/types/argumentSegmentations"
import { IArgumentSegment } from "api/types/argumentSegments"
import { IArgument } from "api/types/arguments"

import { filterNonArchived } from "services/archivable"

import changeArrayPosition from "utils/changeArrayPosition"
import isStringArray from "utils/isStringArray"

import { argumentsHaveAnySegmentRuleActiveForSegmentation } from "./arguments"

export const SEGMENT_ALL = "ALL"
export const SEGMENT_DEFAULT = "DEFAULT" // This value will be filtered out and be replaced by the default, if any

// Validate the segmentsParams based on the available argumentSegmentations
export function ensureValidSegments(
  argumentSegmentations: IArgumentSegmentation[],
  segmentsParam: Qs.ParsedQs["param"]
): Dictionary<string[]> {
  if (typeof segmentsParam !== "object") return {}
  if (Array.isArray(segmentsParam)) return {}

  const segmentationsIndex = Object.fromEntries(
    filterNonArchived(argumentSegmentations).map((x) => [
      x.id,
      filterNonArchived(x.argumentSegments).map((x) => x.id),
    ])
  )

  return mapValues(
    pickBy(
      segmentsParam,
      (value, key) => segmentationsIndex[key] && isStringArray(value)
    ) as Dictionary<string[]>,
    (segmentIds, argumentSegmentationId) =>
      segmentIds.filter(
        (segmentId) =>
          segmentationsIndex[argumentSegmentationId].includes(segmentId) ||
          segmentId === SEGMENT_ALL
      )
  )
}

export default function addDefaultSegments(
  argumentSegmentations: IArgumentSegmentation[],
  segmentFilters: Dictionary<string[]>
): Dictionary<string[]> {
  let filtersWithDefault = { ...segmentFilters }
  argumentSegmentations.forEach((argumentSegmentation) => {
    if (
      (filtersWithDefault[argumentSegmentation.id] === undefined ||
        filtersWithDefault[argumentSegmentation.id].length === 0) &&
      hasActiveDefaultSegment(argumentSegmentation)
    ) {
      filtersWithDefault[argumentSegmentation.id] =
        getActiveDefaultSegmentIds(argumentSegmentation)
    }
  })
  return filtersWithDefault
}

// TODO Test the behaviour of segmentations with a default segment
function hasActiveDefaultSegment(
  argumentSegmentation: IArgumentSegmentation
): boolean {
  if (!argumentSegmentation.defaultSegmentId) return false
  return filterNonArchived(argumentSegmentation.argumentSegments).some(
    (segment) => segment.id === argumentSegmentation.defaultSegmentId
  )
}

function getActiveDefaultSegmentIds(
  argumentSegmentation: IArgumentSegmentation
): string[] {
  return hasActiveDefaultSegment(argumentSegmentation)
    ? [argumentSegmentation.defaultSegmentId!]
    : []
}

export function moveArgumentSegmentation(
  argumentSegmentations: IArgumentSegmentation[],
  argumentSegmentationId: string,
  relativeArgumentSegmentationId: string
) {
  const oldPosition = argumentSegmentations.findIndex(
    (x) => x.id === argumentSegmentationId
  )
  let newPosition = argumentSegmentations.findIndex(
    (x) => x.id === relativeArgumentSegmentationId
  )
  return changeArrayPosition(argumentSegmentations, oldPosition, newPosition)
}

export const moveArgumentSegment = (
  argumentSegments: IArgumentSegment[],
  argumentSegmentId: string,
  relativeArgumentSegmentId: string
) => {
  const oldPosition = argumentSegments.findIndex(
    (segment) => segment.id === argumentSegmentId
  )

  const newPosition = argumentSegments.findIndex(
    (segment) => segment.id === relativeArgumentSegmentId
  )

  const newArgumentSegments = changeArrayPosition(
    argumentSegments,
    oldPosition,
    newPosition
  )
  return newArgumentSegments
}

function filterSegmentationsWithArgumentsHavingAnySegmentRuleActive(
  argumentSegmentations: IArgumentSegmentation[],
  _arguments: IArgument[]
) {
  return argumentSegmentations.filter((argumentSegmentation) =>
    argumentsHaveAnySegmentRuleActiveForSegmentation(
      _arguments,
      argumentSegmentation
    )
  )
}

export function selectSegmentationsWithNoArgumentsHavingActiveSegmentRule(
  argumentSegmentations: IArgumentSegmentation[],
  _arguments: IArgument[]
) {
  return argumentSegmentations.filter(
    (argumentSegmentation) =>
      !argumentsHaveAnySegmentRuleActiveForSegmentation(
        _arguments,
        argumentSegmentation
      )
  )
}

export function filterUnactiveSegmentationsWithArgumentsHavingAnySegmentRuleActive(
  arumentSegmentations: IArgumentSegmentation[],
  _arguments: IArgument[],
  segmentFilters: Dictionary<String[]>
) {
  return filterSegmentationsWithArgumentsHavingAnySegmentRuleActive(
    arumentSegmentations,
    _arguments
  ).filter(
    (argumentSegmentation) =>
      segmentFilters[argumentSegmentation.id] === undefined ||
      segmentFilters[argumentSegmentation.id].length === 0 ||
      segmentFilters[argumentSegmentation.id].includes(SEGMENT_ALL)
  )
}

export function filterLinkableProperties(
  properties: ICRMProperty[]
): ICRMProperty[] {
  return properties.filter(isPropertyLinkable)
}

// This logic is duplicated in the backend in `supports_crm_property?`
// TODO allow multi_select for allow_multiple_selection
function isPropertyLinkable(property: ICRMProperty): boolean {
  return (
    property.dataType === "single_select" ||
    property.dataType === "multi_select" ||
    (property.dataType === "free_text" && property.readOnly)
  )
}

export function findArgumentSegmentsByValue(
  argumentSegments: IArgumentSegment[],
  values: string[]
) {
  return argumentSegments.filter(
    (segment) => segment.value && values.includes(segment.value)
  )
}

export function findArgumentSegmentsById(
  argumentSegments: IArgumentSegment[],
  ids: string[]
) {
  return argumentSegments.filter((segment) => ids.includes(segment.id))
}
