import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { FormattedMessage } from "react-intl"

import Tooltip from "ds/Tooltip"

export default function AdditionalDetailsCue() {
  return (
    <Tooltip
      title={
        <FormattedMessage
          id="arguments.hasFieldsOutsideTitleAndDescription"
          defaultMessage="Additional details available"
        />
      }
    >
      <InfoOutlinedIcon
        sx={{ fontSize: "20px", "&:not(:hover)": { opacity: 0.5 } }}
      />
    </Tooltip>
  )
}
