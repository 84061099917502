import { PlateElement, getHandler, useElement } from "@udecode/plate-common"
import { forwardRef } from "react"
import { useFocused, useSelected } from "slate-react"
import styled from "styled-components"

import { MyMentionElement, MyPlateElementProps } from "../types"

const MENTION_PREFIX = "@"

export default forwardRef<HTMLSpanElement, MyPlateElementProps>(function Link(
  { children, prefix, onClick, ...props },
  ref
) {
  const element = useElement<MyMentionElement>()
  const selected = useSelected()
  const focused = useFocused()

  return (
    <StyledPlateElement
      $selected={selected}
      $focused={focused}
      ref={ref}
      data-slate-value={element.value}
      contentEditable={false}
      // @ts-ignore This is copied from plate-ui mention-element.tsx, not sure why it doesn't pass TS
      onClick={getHandler(props.onClick, element)}
      {...props}
    >
      {prefix}
      {MENTION_PREFIX}
      {element.value}
      {children}
    </StyledPlateElement>
  )
})

interface StyleProps {
  $selected: boolean
  $focused: boolean
}

const StyledPlateElement = styled(PlateElement)<StyleProps>`
  display: inline-block;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme, $selected }) =>
    $selected ? `${theme.palette.primary.main}3f` : theme.palette.grey[100]};
  padding: 0 ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  font-weight: ${({ theme }) => theme.typography.smSemi.fontWeight};
`
