// We create our own axios instance with custom interceptors.
// Pure axios is still accessible via `import axios from 'axios'`
// Our custom instance is accessible via `import axios from 'api/axios'`
// We send the Json Web Token in the Authorization header. To NOT send it, pass the same header with any value, like "none"
import axios, { AxiosRequestConfig, AxiosResponse } from "axios"
import { IS_CHROME_EXTENSION } from "chrome_extension/utils"
import Qs from "qs"

import { getGetToken } from "authentication/globalGetToken"

import deepCamelCaseKeys from "utils/deepCamelCaseKeys"
import deepSnakeCaseKeys from "utils/deepSnakeCaseKeys"

import apiHost from "./apiHost"

const instance = axios.create({
  baseURL: apiHost,
})

// For GET requests, format params correctly for Rails strong parameters and deep snake case the keys
export const strongParamsSerializer = (params: object): string =>
  Qs.stringify(params, { arrayFormat: "brackets" })

const strongParamsInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => ({
  ...config,
  paramsSerializer: strongParamsSerializer,
})

// For POST requests, just deep snake case the data, strong parameters work out of the box
const snakeCaseInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => ({
  ...config,
  data: config.data && deepSnakeCaseKeys(config.data),
  params: config.params && deepSnakeCaseKeys(config.params),
})

// Set the header Authorization, if not already set, with the JWT return by a global function
// In the chrome extension, he authorization token is set by the background script
// WARNING: This means we don't set the authorization token in the options page of the chrome extension.
//          If we need it, we need to adapt this function.
async function authorizationTokenInterceptor(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  config.headers ||= {}
  if (config.headers.Authorization) return config
  const getToken = getGetToken()
  config.headers.Authorization = await getToken()
  return config
}

const CLIENT_HEADER = "X-SalesPath-Client"
const CHROME_EXTENSION_CLIENT = "CHROME_EXTENSION"
export function addClientHeader(config: AxiosRequestConfig) {
  if (!IS_CHROME_EXTENSION) return config
  config.headers ||= {}
  config.headers[CLIENT_HEADER] = CHROME_EXTENSION_CLIENT
  return config
}

// Compose the request interceptors
async function requestInterceptor(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  let newConfig = config
  newConfig = snakeCaseInterceptor(newConfig)
  newConfig = strongParamsInterceptor(newConfig)
  newConfig = addClientHeader(newConfig)
  newConfig = await authorizationTokenInterceptor(newConfig)
  return newConfig
}

// Camelize the response data from the server
const responseInterceptor = (response: AxiosResponse): AxiosResponse => {
  return {
    ...response,
    data: response.data && deepCamelCaseKeys(response.data),
  }
}

const errorResponseInterceptor = <T extends { response?: AxiosResponse }>(
  error: T
): Promise<never> => {
  if (!(typeof error === "object")) {
    return Promise.reject(error)
  }
  const camelizedError = {
    ...error,
    response: error.response && responseInterceptor(error.response),
  }
  return Promise.reject(camelizedError)
}

instance.interceptors.request.use(requestInterceptor)
instance.interceptors.response.use(
  responseInterceptor,
  errorResponseInterceptor
)

export default instance
