// Open the "Save as" window from the browser, providing data and a filename
const triggerSaveAs = (blob: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", fileName)
  link.click()
}

export default triggerSaveAs
