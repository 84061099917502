import { Box } from "@mui/material"
import { truncate } from "lodash"
import { FormattedMessage, useIntl } from "react-intl"

import { IMeetingArgumentNote } from "api/types/meetingArgumentNotes"
import { IMeeting } from "api/types/meetings"

import Stack from "ds/Stack"
import Typography from "ds/Typography"
import UnstyledLink from "ds/UnstyledLink"

import AnswerIndicator from "components/App/Playbook/Argument/ArgumentCard/ArgumentKindIndicator/AnswerIndicator"
import { getArgumentPath } from "components/App/Playbook/Argument/routing"
import { useConfig } from "components/Config"

import { getRawTitle } from "services/argumentFields"

import ArgumentNoteDisplay from "./MeetingArgumentNotes/ArgumentNoteDisplay"
import ReadonlyAdditionalInfo from "./MeetingArgumentNotes/ReadonlyAdditionalInfo"

interface Props {
  meeting: IMeeting
  meetingArgumentNotes: IMeetingArgumentNote[]
}

export default function MeetingArgumentNotes({
  meeting,
  meetingArgumentNotes,
}: Props) {
  const intl = useIntl()
  const config = useConfig()

  return (
    <Stack spacing={2}>
      <MeetingArgumentNotesHeader />

      <Typography component="div" variant="smNormal">
        <Stack spacing={1}>
          {meetingArgumentNotes.map((argumentNote) => {
            const argumentTitle =
              getRawTitle(argumentNote.argument) ||
              intl.formatMessage({
                id: "playbookEdit.argument.defaultTitle",
                defaultMessage: "Title",
              })

            return (
              <div key={argumentNote.id}>
                <div>
                  <UnstyledLink
                    target="_blank"
                    href={`${config.appUrl}${getArgumentPath(
                      meeting.businessUnitId,
                      meeting.playbookId,
                      argumentNote.argument.id
                    )}`}
                    rel="noreferrer"
                  >
                    <span>❓&nbsp;</span>
                    <Typography
                      component="span"
                      variant="smSemi"
                      title={argumentTitle}
                    >
                      {truncate(argumentTitle, { length: 140 })}
                    </Typography>
                  </UnstyledLink>
                </div>

                <Box sx={{ ml: 2 }}>
                  <ArgumentNoteDisplay argumentNote={argumentNote} />
                </Box>

                {argumentNote.additionalInfo && (
                  <Box sx={{ ml: 2 }}>
                    <ReadonlyAdditionalInfo argumentNote={argumentNote} />
                  </Box>
                )}
              </div>
            )
          })}
        </Stack>
      </Typography>
    </Stack>
  )
}

export function MeetingArgumentNotesHeader() {
  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <AnswerIndicator />

      <Typography variant="lgSemi">
        <FormattedMessage id="playbook.meeting.argumentNotes" />
      </Typography>
    </Stack>
  )
}
