import { IIntegration } from "api/types/integrations"

export function isSuccessfulIntegration(integration: IIntegration) {
  return integration.status === "success"
}

export function isIntegrationInProgress(integration: IIntegration) {
  return integration.status === "in_progress"
}

export function isFailedIntegration(integration: IIntegration) {
  return integration.status === "failure"
}
