import styled from "styled-components"

import { IArgumentSegmentation } from "api/types/argumentSegmentations"

import ArgumentSegmentationName from "components/App/Playbook/ArgumentSegmentation/ArgumentSegmentationName"

const Container = styled.div`
  width: 100%; // All the space inside the matrix cell
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

interface Props {
  argumentSegmentation: IArgumentSegmentation
}

export default function ArgumentSegmentationHeader({
  argumentSegmentation,
}: Props) {
  return (
    <Container title={argumentSegmentation.name || ""}>
      <ArgumentSegmentationName argumentSegmentation={argumentSegmentation} />
    </Container>
  )
}
