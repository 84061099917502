import { Dictionary } from "lodash"
import { useEffect } from "react"
import { Helmet } from "react-helmet-async"

import { useIsSmallerThanMD } from "ds/Media"
import Stack from "ds/Stack"

import ArgumentModal from "./Argument/ArgumentModal"
import { useArgumentIdParam } from "./Argument/routing"
import MeetingControls from "./Meeting/MeetingControls"
import MeetingPanel from "./Meeting/MeetingPanel"
import { useMeeting } from "./Meeting/MeetingProvider"
import PlaybookContent from "./PlaybookContent"
import { usePlaybook } from "./PlaybookProvider"
import { setLastAppliedSegments, setLastVisitedTypeId } from "./lastVisit"

export function usePersistLatestTypeInLS(playbookId: string, typeId: string) {
  useEffect(() => {
    setLastVisitedTypeId(playbookId, typeId)
  }, [playbookId, typeId])
}

function usePersistLatestAppliedSegmentsInLS(
  playbookId: string,
  appliedSegments: Dictionary<string[]>
) {
  useEffect(() => {
    setLastAppliedSegments(playbookId, appliedSegments)
  }, [playbookId, appliedSegments])
}

export default function PlaybookLoaded() {
  const { editMode, readOnly, playbook, activeTypeId, segmentFilters } =
    usePlaybook()
  const [argumentIdParam, setArgumentIdParam] = useArgumentIdParam()
  const { ongoingMeeting } = useMeeting()
  const isSmallerThanMD = useIsSmallerThanMD()
  usePersistLatestTypeInLS(playbook.id, activeTypeId)
  usePersistLatestAppliedSegmentsInLS(playbook.id, segmentFilters)

  const shouldDisplayMeetingPanel =
    !!ongoingMeeting && !editMode && !isSmallerThanMD

  return (
    <>
      <Helmet>
        <title>{playbook.name || "SalesPath"}</title>
      </Helmet>

      {shouldDisplayMeetingPanel ? (
        <Stack direction="row" sx={{ height: "100%" }} spacing={0}>
          <PlaybookContent />
          <MeetingPanel />
        </Stack>
      ) : (
        <PlaybookContent />
      )}

      {argumentIdParam && (
        <ArgumentModal
          argumentId={argumentIdParam.toString()}
          onClose={() => setArgumentIdParam(undefined)}
          forceSingleColumnLayout={!editMode}
        />
      )}

      {!editMode && !readOnly && (
        <MeetingControls ongoingMeeting={ongoingMeeting} />
      )}
    </>
  )
}
