import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import { FormattedMessage } from "react-intl"

import { IMeeting } from "api/types/meetings"
import { IVote } from "api/types/votes"

import Avatar from "ds/Avatar"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import Vote from "./Vote"

interface Props {
  ongoingMeeting: IMeeting
  votes: IVote[]
}

export default function Upvotes({ votes, ongoingMeeting }: Props) {
  return (
    <Stack spacing={2}>
      <UpvotesHeader />

      <Stack spacing={2}>
        {votes.map((vote) => (
          <Vote
            key={vote.id}
            vote={vote}
            playbookId={ongoingMeeting.playbookId}
            businessUnitId={ongoingMeeting.businessUnitId}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export function UpvotesHeader() {
  return (
    <Stack direction="row" spacing={1.5} alignItems="center">
      <Avatar sx={{ bgcolor: (theme) => theme.palette.success.light }}>
        <ArrowUpwardIcon fontSize="small" color="success" />
      </Avatar>

      <Typography variant="lgSemi">
        <FormattedMessage id="playbook.meeting.upvotedArguments" />
      </Typography>
    </Stack>
  )
}
