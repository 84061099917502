import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import Alert from "ds/Alert"
import Stack from "ds/Stack"
import Typography from "ds/Typography"

import UncheckedCheckboxArgument from "./UncheckedCheckboxArgument"

interface Props {
  uncheckedCheckboxArguments: IArgument[]
}

export default function UncheckedCheckboxArguments({
  uncheckedCheckboxArguments,
}: Props) {
  return (
    <Stack spacing={2}>
      <Alert severity="error">
        <Typography variant="smNormal">
          <FormattedMessage id="playbook.meeting.uncheckedCheckboxes" />
        </Typography>
      </Alert>

      {uncheckedCheckboxArguments.slice(0, 5).map((argument) => (
        <UncheckedCheckboxArgument key={argument.id} argument={argument} />
      ))}
    </Stack>
  )
}
