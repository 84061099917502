import AttachmentIcon from "@mui/icons-material/Attachment"
import DownloadIcon from "@mui/icons-material/Download"
import { Box } from "@mui/material"
import { PlateElement } from "@udecode/plate-common"

import IconButton from "ds/IconButton"
import UnstyledLink from "ds/UnstyledLink"

import { useCloudAttachmentElementState } from "../../plugins/cloud/index"
import { CloudAttachmentProps } from "./CloudAttachmentProps"
import CloudAttachmentStatusBar from "./CloudAttachmentStatusBar"

export default function CloudGenericAttachment(props: CloudAttachmentProps) {
  const { children, element } = props

  const { focused, selected, upload } = useCloudAttachmentElementState({
    element,
  })

  return (
    <Box
      component={PlateElement}
      sx={{
        position: "relative",
        display: "flex",
        height: 32,
        maxWidth: 300,
        alignItems: "center",
        gap: 1,
        py: 2,
        px: 0.5,
        boxSizing: "border-box",
        borderRadius: 1,
        outline: (theme) =>
          `2px solid ${
            focused && selected ? theme.palette.primary[300] : undefined
          }`,
        "&:hover": {
          bgcolor: (theme) => theme.palette.grey[100],
        },
        bgcolor: (theme) =>
          focused && selected ? theme.palette.grey[100] : undefined,
      }}
      draggable
      {...props}
    >
      <Box
        sx={{ flexShrink: 0, display: "flex", alignItems: "center" }}
        contentEditable={false}
      >
        <AttachmentIcon fontSize="small" color="action" />
      </Box>

      <Box sx={{ flexGrow: 1, minWidth: 0 }} contentEditable={false}>
        <CloudAttachmentStatusBar upload={upload}>
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            title={element.filename}
          >
            <UnstyledLink
              href={element.url}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {element.filename}
            </UnstyledLink>
          </Box>
        </CloudAttachmentStatusBar>
      </Box>

      <Box
        sx={{
          ml: 2,
          height: 32,
          width: 32,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
        }}
        contentEditable={false}
      >
        {upload.status === "success" && (
          <a
            href={element.url}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <IconButton>
              <DownloadIcon fontSize="small" />
            </IconButton>
          </a>
        )}
      </Box>
      {children}
    </Box>
  )
}
