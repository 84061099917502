import MuiTooltip, { TooltipProps } from "@mui/material/Tooltip"
import { forwardRef } from "react"

export type { TooltipProps }

export default forwardRef(function Tooltip(
  props: TooltipProps,
  ref: React.Ref<HTMLDivElement>
) {
  return <MuiTooltip {...props} ref={ref} />
})
