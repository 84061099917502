import { FormattedMessage } from "react-intl"

import { IArgument } from "api/types/arguments"

import MenuItem from "ds/MenuItem"
import { UnstyledRouterLink } from "ds/UnstyledLink"

import { useArgumentModalLocation } from "components/App/Playbook/Argument/routing"

interface Props {
  argument: IArgument
  onClick: React.MouseEventHandler<HTMLLIElement>
}

export default function ArgumentViewComments({ argument, onClick }: Props) {
  const argumentModalLocation = useArgumentModalLocation(argument.id, {
    activeTab: "comments",
  })
  return (
    <MenuItem
      component={UnstyledRouterLink}
      to={argumentModalLocation}
      onClick={onClick}
    >
      <FormattedMessage
        id="playbookEdit.argument.viewComments"
        defaultMessage="View comments"
      />
    </MenuItem>
  )
}
