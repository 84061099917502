import MuiTableBody, { TableBodyProps } from "@mui/material/TableBody"
import { forwardRef } from "react"

export type { TableBodyProps }

export default forwardRef(function TableBody(
  props: TableBodyProps,
  ref: React.Ref<HTMLTableSectionElement>
) {
  return <MuiTableBody {...props} ref={ref} />
})
