import MoreHoriz from "@mui/icons-material/MoreHoriz"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { IArgumentField } from "api/types/argumentFields"

import IconButton from "ds/IconButton"
import Menu from "ds/Menu"
import MenuItem from "ds/MenuItem"

import useAnchorEl from "utils/hooks/useAnchorEl"

import ArchiveArgumentField from "./ArchiveArgumentField"
import RenameArgumentField from "./RenameArgumentField"
import RestoreArgumentField from "./RestoreArgumentField"

interface Props {
  argumentField: IArgumentField
}

export default function ArgumentFieldActions({ argumentField }: Props) {
  const intl = useIntl()
  const [anchorEl, open, handleClick, handleClose] = useAnchorEl()
  const [isEditOpen, setIsEditOpen] = useState(false)

  const label = intl.formatMessage({
    id: "playbookEdit.argumentFields.moreActions",
    defaultMessage: "More talking point field actions",
  })

  return (
    <>
      <IconButton onClick={handleClick} aria-label={label}>
        <MoreHoriz fontSize="small" />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setIsEditOpen(true)
            handleClose()
          }}
        >
          <FormattedMessage
            id="playbookEdit.argumentFields.rename"
            defaultMessage="Rename"
          />
        </MenuItem>

        {!argumentField.archived && (
          <ArchiveArgumentField argumentField={argumentField} />
        )}
        {argumentField.archived && (
          <RestoreArgumentField argumentField={argumentField} />
        )}
      </Menu>

      {isEditOpen && (
        <RenameArgumentField
          argumentField={argumentField}
          onClose={() => setIsEditOpen(false)}
        />
      )}
    </>
  )
}
