import { FormattedMessage, IntlShape, useIntl } from "react-intl"

import { IArgumentField } from "api/types/argumentFields"

import MenuItem from "ds/MenuItem"
import UncontrolledError from "ds/UncontrolledError"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"
import { useArgumentFieldDeleteMutation } from "components/App/Playbook/queries/argumentFieldQueries"
import { useArgumentsUsingFieldQuery } from "components/App/Playbook/queries/argumentQueries"

const buildConfirmMessage = (intl: IntlShape, numberOfArguments: number) =>
  intl.formatMessage(
    {
      id: "playbookEdit.argumentFields.archiveWarning",
      defaultMessage: "Are you sure?",
    },
    { numberOfArguments }
  )

interface Props {
  argumentField: IArgumentField
}

export default function ArchiveArgumentField({ argumentField }: Props) {
  const { playbook } = usePlaybook()
  const archiveMutation = useArgumentFieldDeleteMutation(playbook.id)
  const argumentsUsingFieldQuery = useArgumentsUsingFieldQuery(argumentField.id)
  const intl = useIntl()

  const onClick = async () => {
    if (!argumentsUsingFieldQuery.data) return
    if (
      argumentsUsingFieldQuery.data.length === 0 ||
      window.confirm(
        buildConfirmMessage(intl, argumentsUsingFieldQuery.data.length)
      )
    )
      archiveMutation.mutate(argumentField.id)
  }

  if (argumentsUsingFieldQuery.isError)
    return <UncontrolledError error={argumentsUsingFieldQuery.error} />

  return (
    <MenuItem
      onClick={onClick}
      disabled={archiveMutation.isLoading || argumentsUsingFieldQuery.isLoading}
    >
      <FormattedMessage id="playbookEdit.argumentFields.archive" />
    </MenuItem>
  )
}
