import MuiDivider, { DividerProps } from "@mui/material/Divider"
import { forwardRef } from "react"

export type { DividerProps }

export default forwardRef(function Divider(
  props: DividerProps,
  ref: React.Ref<HTMLHRElement>
) {
  return <MuiDivider {...props} ref={ref} />
})
