import { Box } from "@mui/material"
import styled from "styled-components"

import { IArgumentComment } from "api/types/argumentComments"

import AddReaction from "./CommentReactions/AddReaction"
import ReactionButton from "./CommentReactions/ReactionButton"
import {
  useArgumentCommentReactionAddMutation,
  useArgumentCommentReactionDeleteMutation,
} from "./argumentCommentQueries"

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: ${({ theme }) => theme.spacing(1)};
`

interface Props {
  comment: IArgumentComment
}

export default function CommentReactions({ comment }: Props) {
  const addReactionMutation = useArgumentCommentReactionAddMutation(
    comment.argumentId
  )
  const deleteReactionMutation = useArgumentCommentReactionDeleteMutation(
    comment.argumentId
  )
  function toggleReaction(
    alreadyReacted: boolean,
    commentId: string,
    emojiName: string
  ) {
    alreadyReacted
      ? deleteReactionMutation.mutate({ commentId, emojiName })
      : addReactionMutation.mutate({ commentId, emojiName })
  }

  return (
    <Container>
      <Box>
        <AddReaction comment={comment} toggleReaction={toggleReaction} />
      </Box>

      {comment.reactions.map((reaction) => (
        <ReactionButton
          key={comment.id + "-" + reaction.emojiName}
          reaction={reaction}
          onToggle={(alreadyReacted) =>
            toggleReaction(alreadyReacted, comment.id, reaction.emojiName)
          }
        />
      ))}
    </Container>
  )
}
