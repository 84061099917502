import styled from "styled-components"

import { IArgumentGroup } from "api/types/argumentGroups"
import { IArgument } from "api/types/arguments"

import { usePlaybook } from "components/App/Playbook/PlaybookProvider"

import noArgumentsInTab from "services/argumentTypes"

import useViewSettings from "../../ViewSettings/useViewSettings"
import AddExistingArgument from "./AddExistingArgument"
import CreateArgument from "./CreateArgument"

const Container = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
`

interface AddArgumentCTAsProps {
  arguments: IArgument[]
  argumentGroup: IArgumentGroup | null
}

export default function AddArgumentCTAs({
  arguments: _arguments,
  argumentGroup,
}: AddArgumentCTAsProps) {
  const { playbook } = usePlaybook()
  const viewSettings = useViewSettings(playbook.id)

  const noArgumentsInCurrentTab = noArgumentsInTab(
    _arguments,
    playbook.argumentSegmentations,
    viewSettings.showArchived
  )

  return (
    <Container>
      <CreateArgument argumentGroup={argumentGroup} />
      {!noArgumentsInCurrentTab && (
        <AddExistingArgument argumentGroup={argumentGroup} />
      )}
    </Container>
  )
}
